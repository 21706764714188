import {EmployeeApplication} from "../../models/interfaces";

const unregisteredEmployeesMock: EmployeeApplication[] = [
  {
    id: 238246466,
    status: { code: 1, value: 'אין ת"ז' },
    file: null
  },
  {
    id: 238246464,
    status: { code: 1, value: 'אין ת"ז' },
    file: null
  }
];
const rejectedEmployeesMock: EmployeeApplication[] = [
  {
    id: 323896258,
    status: { code: 5, value: "היתר עבודה מונע העסקה" },
    file: null
  },
  {
    id: 323896252,
    status: { code: 5, value: "היתר עבודה מונע העסקה" },
    file: null
  }
];

const approvedEmployeesMock: EmployeeApplication[] = [
  {
    id: 201070671,
    status: { code: 8, value: "כשיר להעסקה" },
    file: null
  },
  {
    id: 201070659,
    status: { code: 8, value: "כשיר להעסקה" },
    file: null
  },
  {
    id: 201070699,
    status: { code: 8, value: "כשיר להעסקה" },
    file: null
  },
  {
    id: 201070242,
    status: { code: 8, value: "כשיר להעסקה" },
    file: null
  },
  {
    id: 201078442,
    status: { code: 8, value: "כשיר להעסקה" },
    file: null
  },
  {
    id: 201071582,
    status: { code: 8, value: "כשיר להעסקה" },
    file: null
  },
  {
    id: 292071582,
    status: { code: 8, value: "כשיר להעסקה" },
    file: null
  },
  {
    id: 292062872,
    status: { code: 8, value: "כשיר להעסקה" },
    file: null
  },
  {
    id: 201070618,
    status: { code: 7, value: "כשיר להעסקה" },
    file: { code:31, name:'הצהרת מעסיק', hasDocumentToDownload:true, description:"statementDocument" , required:true}
  }
];
export {unregisteredEmployeesMock, rejectedEmployeesMock, approvedEmployeesMock};
