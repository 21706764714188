/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./header.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../navigation-tabs/navigation-tabs.component.ngfactory";
import * as i3 from "../navigation-tabs/navigation-tabs.component";
import * as i4 from "@angular/router";
import * as i5 from "@angular/cdk/layout";
import * as i6 from "../../services/user-data.service";
import * as i7 from "./main-menu/main-menu.component.ngfactory";
import * as i8 from "./main-menu/main-menu.component";
import * as i9 from "../../services/login.service";
import * as i10 from "@angular/forms";
import * as i11 from "@angular/common";
import * as i12 from "./banner/banner.component.ngfactory";
import * as i13 from "./banner/banner.component";
import * as i14 from "./header.component";
import * as i15 from "../../services/auth.service";
var styles_HeaderComponent = [i0.styles];
var RenderType_HeaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HeaderComponent, data: {} });
export { RenderType_HeaderComponent as RenderType_HeaderComponent };
function View_HeaderComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "hamburger"], ["type", "button"]], [[2, "active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleTabs() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "line "]], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isTabsActive; _ck(_v, 0, 0, currVal_0); }); }
function View_HeaderComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-navigation-tabs", [], null, [[null, "closeTabs"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("closeTabs" === en)) {
        var pd_0 = (_co.closeTabs() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_NavigationTabsComponent_0, i2.RenderType_NavigationTabsComponent)), i1.ɵdid(1, 114688, null, 0, i3.NavigationTabsComponent, [i4.Router, i5.BreakpointObserver, i6.UserDataService], null, { closeTabs: "closeTabs" })], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_HeaderComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-main-menu", [["class", "d-flex align-items-center "]], null, [[null, "toggleMainMenu"], [null, "closeMainMenu"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("toggleMainMenu" === en)) {
        var pd_0 = (_co.toggleMainMenu() !== false);
        ad = (pd_0 && ad);
    } if (("closeMainMenu" === en)) {
        var pd_1 = (_co.closeMainMenu() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i7.View_MainMenuComponent_0, i7.RenderType_MainMenuComponent)), i1.ɵdid(1, 114688, null, 0, i8.MainMenuComponent, [i6.UserDataService, i9.LoginService, i4.Router, i10.FormBuilder], { isMainMenuOpen: [0, "isMainMenuOpen"] }, { toggleMainMenu: "toggleMainMenu", closeMainMenu: "closeMainMenu" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isMainMenuOpen; _ck(_v, 1, 0, currVal_0); }, null); }
function View_HeaderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "section", [["class", "headerTop d-flex"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderComponent_2)), i1.ɵdid(2, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderComponent_3)), i1.ɵdid(4, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 5, "div", [["class", "d-flex mr-auto"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "accessibility"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "i", [["class", "icon-accessibility"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderComponent_4)), i1.ɵdid(9, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 0, "img", [["class", "navLogo align-self-baseline "], ["src", "./assets/imgs/mainLogo.png"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isUserAuth; _ck(_v, 2, 0, currVal_0); var currVal_1 = ((_co.isTabsActive || !_co.isMobile) && _co.isUserAuth); _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.isUserAuth; _ck(_v, 9, 0, currVal_2); }, null); }
function View_HeaderComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-banner", [], null, null, null, i12.View_BannerComponent_0, i12.RenderType_BannerComponent)), i1.ɵdid(1, 114688, null, 0, i13.BannerComponent, [], { title: [0, "title"], buttonString: [1, "buttonString"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; var currVal_1 = _co.buttonString; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_HeaderComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "backDrop"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeAll() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, null); }
export function View_HeaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "header", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "container-fluid width-main"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderComponent_1)), i1.ɵdid(3, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderComponent_5)), i1.ɵdid(5, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderComponent_6)), i1.ɵdid(7, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isNotLogin; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.isNotLogin; _ck(_v, 5, 0, currVal_1); var currVal_2 = ((_co.isMainMenuOpen || _co.isTabsActive) && _co.isMobile); _ck(_v, 7, 0, currVal_2); }, null); }
export function View_HeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-header", [], null, null, null, View_HeaderComponent_0, RenderType_HeaderComponent)), i1.ɵdid(1, 114688, null, 0, i14.HeaderComponent, [i4.Router, i15.AuthService, i5.BreakpointObserver], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HeaderComponentNgFactory = i1.ɵccf("app-header", i14.HeaderComponent, View_HeaderComponent_Host_0, {}, {}, []);
export { HeaderComponentNgFactory as HeaderComponentNgFactory };
