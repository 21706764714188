import { Component } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Parameters } from 'src/app/models/parameter';
import { ParameterService } from 'src/app/services/parameter.service';


@Component({
  selector: 'app-parameter-management',
  templateUrl: './parameter-management.component.html',
  styleUrls: ['./parameter-management.component.scss']
})
export class ParameterManagementComponent {

  form: FormGroup;
  parametersId: string;

  constructor(private parameterService: ParameterService) {

    this.form = new FormGroup({});

    for (let key in new Parameters()) {
      this.form.addControl(key, new FormControl(null, [this.minValidator(1), this.numberValidator, this.requiredValidator]))
    }

    this.getParameter()
  }

  getParameter() {
    this.parameterService.getParameters().subscribe(
      res => {

        let data = res.data[0];
        this.parametersId = data._id;

        for (let key in this.form.controls) {
          this.form.get(key).setValue(data[key]);
        }
      }
    )
  }

  onSave() {
    this.parameterService.updateParameter(this.parametersId, this.form.value).subscribe();
  }

  requiredValidator(control: AbstractControl): ValidationErrors | null {
    return Validators.required(control) ? { msg: "שדה חובה." } : null;
  }

  minValidator(min: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      return Validators.min(min)(control) ? { msg: `${min} :ערך מינימום הוא` } : null;
    }
  }

  numberValidator(control: AbstractControl): ValidationErrors | null {
    return Validators.pattern(/[0-9]/)(control) ? { msg: '.שדה מספרי' } : null;
  }


}
