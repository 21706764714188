import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  QueryList,
  ViewChildren,
} from '@angular/core';
import {
  EmployeesApplicationList,
  EmployeeApplication,
  UploadedFile,
  EmployeeApplicationExtended,
  Docs,
  FieldsRequest,
  ConfirmModalData,
} from 'src/app/models/interfaces';
import { EmployeeApplicationPanelComponent } from '../panel/employee-application-panel.component';
import { FormService } from 'src/app/wizard/service/form.service';
import { MatTableDataSource } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import employeeApplicationStatusColor from 'src/app/shared/util/employeeApplicationStatusColor';
import colorText from 'src/app/shared/util/colorText';
import { WizradUserDataService } from 'src/app/wizard/service/wizrad-user-data.service';
import {FieldService} from "../../../../service/field.service";
import {BuildingPermitService} from "../../../../service/building-permit.service";

@Component({
  selector: "employee-application-list",
  templateUrl: './employee-application-list.component.html'
})
export class EmployeeApplicationListComponent implements OnInit {
  @ViewChildren('employeePanel') employeePanelRef!: QueryList<EmployeeApplicationPanelComponent>;
  @Input() employeesApplicationList: EmployeesApplicationList;
  // @Input() selectedField: FieldsRequest;
  @Input()
  set selectedField (field:FieldsRequest){
    this._selectedField =field;
    if(this.employeesApplicationList.editable
      && (this.buildingPermitService.hasBuildingPermitUploaded() || this.buildingPermitService.allowBuildingPermitUpload(true)))
    this.initBeforeDeleteModalData()
  }
  @Input() licenseType:number;
  @Output() onGoBack: EventEmitter<any> = new EventEmitter();
  @Output() onResetStep: EventEmitter<any> = new EventEmitter();
  checkAllPercentage: number = 50;
  fileMap = new Map();
  displayedColumns: string[] = [];
  dataSource: MatTableDataSource<EmployeeApplicationExtended>;
  selection = new SelectionModel<EmployeeApplicationExtended>(true, []);
  buildingPermitDocument: Docs = null;
  beforeDeleteModalData:ConfirmModalData = null;
  _selectedField:FieldsRequest = null;
  confirmedDeleteOfBuildingPermit: boolean = false;

  constructor(
    private formService: FormService,
    private wizradUserDataService: WizradUserDataService,
    public fieldService: FieldService,
    public buildingPermitService: BuildingPermitService
  ) {

  }

  ngOnInit(): void {
    //TODO: SEND ALL FIELD DATA AS Inputs(Make The Component Not Bound To Thr Field).
    const detailedEmployees = this.prepareData(this.employeesApplicationList.employees);
    this.dataSource = new MatTableDataSource(detailedEmployees);
    this.initDisplayColumns();

    if(this.employeesApplicationList.editable){
      this.buildingPermitDocument = this.buildingPermitService.getBuildingPermitDownloadDocument();
      this.initAllObservables();
      this.setInitialAllChecked();
    }
  }

  get validEmployees(): EmployeeApplicationExtended[] {
    return this.dataSource.data.filter(e => e.isValid);
  }

  get unSelectedAndValid(): EmployeeApplicationExtended[] {
    return this.dataSource.data.filter(
      ds => !this.selection.isSelected(ds) && ds.isValid
    );
  }

  get unselected(): EmployeeApplicationExtended[] {
    return this.dataSource.data.filter(
      ds => !this.selection.isSelected(ds)
    );
  }

  initAllObservables(){
    //Check To Enable/Disable Per Selection;
    this.selection.changed.asObservable().subscribe(s=>{
      this.checkToEnableOrDisable();
    });
    this.buildingPermitService.getBuildingPermitRef().valueChanges.subscribe(bp=> {
      //Building Permit Was Deleted On Another Step - Reset Wanted Workers Step
      if(!bp && !this.confirmedDeleteOfBuildingPermit){
        this.onResetStep.emit();
      }

      this.checkToEnableOrDisable();
    });
  }

  initBeforeDeleteModalData() {
    this.beforeDeleteModalData = {
      header: "שים לב",
      message: this.fieldService.getHasZeroLimitationWithNoBuildingPermit()
        ? "במידה ותמחק את הקובץ, תועבר למילוי פרטי הבקשה, מאחר והמכסה לענף זה תתאפס."
        : "במידה ותמחק את הקובץ, בחירתך תתאפס"
    };
  }

  initDisplayColumns(): void {
    const columns: string[] = ['id', 'status', 'file'];

    if (this.employeesApplicationList.editable) {
      columns.splice(0, 0, 'select');
    }

    this.displayedColumns = columns;
  }

  prepareData(employees: EmployeeApplication[]): EmployeeApplicationExtended[] {
    return employees.map(e => {
      const isValid = this.isEmployeeWithFileValid(e);
      return {
        ...e,
        isValid: isValid,
        disabled: !isValid
      } as EmployeeApplicationExtended;
    });
  }

  setInitialAllChecked(): void {
    if (!this.employeesApplicationList.editable) {
      return;
    }

    if (
      this.fieldService.hasEmploymentLimitation() &&
      this.validEmployees.length < this.fieldService.getAvailableEmployeeSum()
    ) {
      this.masterToggle(true);
    } else {
      this.calculateToSelectAll();
    }
  }

  calculateToSelectAll(): void {
    if (!this.fieldService.hasEmploymentLimitation()) {
      this.masterToggle(true);
    } else {
      const availableEmployeePercentage: number =
        (this.fieldService.getAvailableEmployeeSum() * 100) /
        this.employeesApplicationList.employees.length;
      if (availableEmployeePercentage < this.checkAllPercentage) {
        return;
      }

      const employeesToSelect = this.dataSource.data
        .filter(ds => this.validEmployees.includes(ds))
        .slice(0, this.fieldService.getAvailableEmployeeSum());
      this.selectAndDisableFromList(employeesToSelect);
    }
  }

  selectAndDisableFromList(employeesToSelect: EmployeeApplicationExtended[]): void {
    const data = this.dataSource.data.reduce(
      (acc, row) => {
        if (employeesToSelect.includes(row))
          acc.toSelect.push(row);
        else
          acc.toDisable.push(row);
        return acc;
      }, {
        toSelect: [] as EmployeeApplicationExtended[],
        toDisable: [] as EmployeeApplicationExtended[]
      });

    this.selection.select(...data.toSelect);
    data.toDisable.forEach(row => row.disabled = true);
  }

  toggleCheckAll(e): void {
    this.masterToggle(e.checked);
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle(checked: boolean): void {
    if (!checked) {
      this.selection.clear();
    } else {
      this.dataSource.data.forEach(row => {
        if (row.isValid && this.isAllowToSelect()) {
          this.selection.select(row);
        }
      });
    }
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: EmployeeApplicationExtended): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${
      this.selection.isSelected(row) ? 'deselect' : 'select'
      } row ${row.id + 1}`;
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    return this.fieldService.hasEmploymentLimitation()
      ? numSelected === this.fieldService.getAvailableEmployeeSum() ||
      numSelected === this.validEmployees.length
      : numSelected === this.validEmployees.length;
  }

  allowUpload(){
    if(!this.fieldService.hasEmploymentLimitation()){
      return true;
    }

    const numSelected = this.selection.selected.length;
    return  numSelected !== this.fieldService.getAvailableEmployeeSum();
  }

  onEmployeeChecked(row: EmployeeApplicationExtended): void {
    this.selection.toggle(row);
  }

  checkToEnableOrDisable() {
    this.unSelectedAndValid.forEach(
      unselected => (unselected.disabled = this.isAllSelected())
    );
  }


  isEmployeeWithFileValid(emloyee) {
    return emloyee.file ? this.fileMap.has(emloyee.id) : true;
  }

  uploadFile(uploadedFile: UploadedFile, row: EmployeeApplicationExtended): void {
    this.fileMap.set(row.id, uploadedFile);

    if (this.isAllowToSelect()) {
      this.selection.select(row);
      row.disabled = false;
    }
    row.isValid = true;
  }

  deleteFile(row: EmployeeApplicationExtended): void {
    this.fileMap.delete(row.id);
    this.selection.deselect(row);
    row.isValid = false;
    row.disabled = true;
  }

  getCellColor(employee: EmployeeApplicationExtended): string {
    return employee.file && this.fileMap.has(employee.id)
      ? colorText.BLACK
      : employeeApplicationStatusColor[this.licenseType][employee.status.code.toString()];
  }

  isAllowToSelect():boolean {
    return (
      !this.fieldService.hasEmploymentLimitation() ||
      this.selection.selected.length < this.fieldService.getAvailableEmployeeSum()
    );
  }

  hasMoreSelectionThenLimitation(): boolean {
    return this.dataSource.data.length > this.fieldService.getAvailableEmployeeSum();// this.isAllSelected() && !!this.unselected.length;
  }

  showReachedMaxSelection():boolean{
    return this.fieldService.hasEmploymentLimitation() && this.selection.selected.length === this.fieldService.getAvailableEmployeeSum() && !!this.unselected.length;
  }
  showUploadBuildingPermit(): boolean {
    return this.buildingPermitService.hasBuildingPermitUploaded() || (this.buildingPermitService.allowBuildingPermitUpload(true) && this.hasMoreSelectionThenLimitation());
  }

  onConfirmDeleteBuildingPermit():void {
    console.log("onConfirmDeleteBuildingPermit start")
    if (this.fieldService.getHasZeroLimitationWithNoBuildingPermit()) {
      this.onGoBack.emit();
    }
    else {
      this.selection.clear();
      this.confirmedDeleteOfBuildingPermit = true;
    }

    setTimeout(()=>{
      this.confirmedDeleteOfBuildingPermit = false;
    }, 1000);
  }

}
