<footer *ngIf="isNotLogin"  class="appFooter ">
  <section class=" container-fluid d-flex align-items-center width-main h-100">
    <img src="./assets/imgs/LogoFooter.png">
    <h2 class="mb-0 mx-5">אתר התעסוקה של מתפ״ש</h2>

    <ul class="appFooter__list mr-auto d-flex">
      <li class="appFooter__item" [class.termsLogin]="!termsFooterLogin">
        <a class="appFooter__link d-flex align-items-center">
         <!-- <i class="icon-phone mx-2"></i>-->
         <!-- <span>זמינים לכל בעיה *4943</span>-->
        </a>
      </li>
      <li class="appFooter__item mx-5"><a href="https://www.gov.il/he/departments/civil_administration_in_judea_and_samaria"
          class="appFooter__link" target="_blank">אתר המנהל האזרחי</a></li>
      <li class="appFooter__item"><a routerLink="/terms" class="appFooter__link">תנאי שימוש</a></li>
      <!-- *ngIf="termsFooterLogin" -->
    </ul>
  </section>
</footer>