<div class="container-fluid container-box">
    <div class="tabsSection storing-day">
        <h1>ניהול יום מיונים</h1>

        <div class="stepHeadline">
            הגדר באילו ימים יבוצעו ימי מיונים ומכסת המתמיינים
        </div>

        <form [formGroup]="parameterSortingDay" (ngSubmit)="showSortingDays(p.profession)">

            <div class="d-flex flex-wrap mt-4 mx-n3 mx-md-auto">
                <div class="col-12 col-md-3 pr-md-0 d-flex flex-column w-100 mb-4 mb-md-0">
                    <label class="ml-3 mb-3">ענף</label>
                    <p-dropdown [options]="listBranch" placeholder="בחר ענף" optionLabel="name"
                        (onChange)="selectSubBranchParameter()" formControlName="branch" [showClear]="true">
                    </p-dropdown>
                </div>

                <div class="col-12 col-md-3  d-flex flex-column w-100 mb-4 mb-md-0 pr-md-0">
                    <label class="ml-3 mb-3">תת ענף</label>
                    <p-dropdown [options]="newSub" (onChange)="selectProfessionParameter()" placeholder="בחר תת ענף"
                        [disabled]="newSub.length==0" formControlName="subBranch" optionLabel="subName"
                        [showClear]="true">
                    </p-dropdown>
                </div>

                <div class="col-12 col-md-3  d-flex flex-column w-100 pr-md-0">
                    <label class="ml-3 mb-3">מקצוע</label>
                    <p-dropdown [options]="newProfession" placeholder="בחר מקצוע" optionLabel="profession"
                        formControlName="profession" (onChange)="selectProfession()"
                        [disabled]="newProfession.length==0" [showClear]="true">
                    </p-dropdown>
                </div>
            </div>

            <div class="d-flex flex-wrap mt-4 mx-n3 mx-md-auto">
                <div class="d-flex col-md-9 p-0">
                    <div class="col-12 col-md-4 pr-md-0 d-flex flex-column w-100 mb-4 mb-md-0">
                        <label class="ml-3 mb-3">מתאריך</label>
                        <p-calendar placeholder="בחר תאריך" (onSelect)="changeDate()" formControlName="fromDate"
                            dateFormat="dd/mm/yy" class="p-calendar-matpash"></p-calendar>
                    </div>

                    <div class="col-12 col-md-4 pr-md-0 d-flex flex-column w-100 mb-4 mb-md-0">
                        <label class="ml-3 mb-3">עד תאריך</label>
                        <p-calendar placeholder="בחר תאריך" (onSelect)="changeDate()" formControlName="toDate"
                            dateFormat="dd/mm/yy" class="p-calendar-matpash"></p-calendar>

                        <div *ngIf="invalidFromToDates===true" class="errorMsg">
                            תאריך יעד קטן מתאריך התחלה
                        </div>
                    </div>
                </div>

                <button type="submit" pButton
                    [disabled]="!parameterSortingDay.valid || invalidFromToDates|| showSortingDay===true"
                    class="mr-auto mt-5 ui-button-rounded  align-items-center button-matpash d-flex justify-content-center light-blue-fill end-btn">
                    <span class="mr-4">הצג</span>
                    <i class="icon-left-arrow ml-4 mr-2"></i>
                </button>
            </div>

        </form>

        <p-tabView class="p-tabview-matpash" *ngIf="showSortingDay===true">
            <p-tabPanel header="תצוגה שבועית" [selected]="true">
                <app-sorting-day-week [listSortingDay]="listSortingDay" [profession]="p.profession.value"
                    [fromDate]="p.fromDate.value" [toDate]="p.toDate.value"
                    (editSortingDay)="getStatusModalEdit($event)">
                </app-sorting-day-week>
            </p-tabPanel>

            <p-tabPanel header="תצוגה חודשית">
                <app-sorting-day-month [listSortingDay]="listSortingDay" [profession]="p.profession.value"
                    [fromDate]="p.fromDate.value" (editSortingDay)="getStatusModalEdit($event)">
                </app-sorting-day-month>
            </p-tabPanel>
        </p-tabView>

        <p-dialog header="הוספה/ עדכון יום מיונים" [(visible)]="displayModalEdit" [modal]="true"
            [style]="{width: '400px', minWidth: '200px'}" [minY]="70" [baseZIndex]="10000">
            <form [formGroup]="sortingDayForm" (ngSubmit)="continue()">

                <div>
                    <div class="col-12  w-100 mb-4">
                        <p-dropdown [options]="listBranch" [disabled]="newSortingDay==true || newSortingDay==false"
                            placeholder="בחר ענף" optionLabel="name" formControlName="branch" [showClear]="true">
                        </p-dropdown>
                    </div>

                    <div class="col-12  w-100 mb-4 ">
                        <p-dropdown [options]="listSubBranch" [disabled]="newSortingDay==true || newSortingDay==false"
                            formControlName="subBranch" dataKey="subBranch" placeholder="בחר תת ענף"
                            optionLabel="subName" [showClear]="true">
                        </p-dropdown>
                    </div>

                    <div class="col-12  w-100 mb-4">
                        <p-dropdown [options]="listProfession" [disabled]="newSortingDay==true || newSortingDay==false"
                            formControlName="professionId" dataKey="professionId" placeholder="בחר מקצוע"
                            optionLabel="profession" [showClear]="true"></p-dropdown>
                        <span class="validation-error mt-2 mb-2 d-flex align-items-center"
                            *ngIf="f['professionId'].errors?.required && f['professionId'].touched">שדה חובה</span>
                    </div>
                    <div class="col-12  w-100 mb-4">
                        <p-calendar placeholder="בחר תאריך" formControlName="examDate" dateFormat="dd/mm/yy"
                            [minDate]="showDateValid" class="p-calendar-matpash" appendTo="'body'"></p-calendar>
                        <span class="validation-error mt-2 mb-2 d-flex align-items-center"
                            *ngIf="f['examDate'].errors?.required && f['examDate'].touched">שדה חובה</span>
                    </div>
                    <div class="d-flex mb-4">
                        <div class="col-6 pl-2">
                            <p-calendar formControlName="fromHour" dateFormat="HH:mm" [timeOnly]="true"
                                placeholder="בחר שעה" class="p-calendar-matpash" appendTo="'body'">
                            </p-calendar>
                            <span class="validation-error mt-2 mb-2 d-flex align-items-center"
                                *ngIf="f['fromHour'].errors?.required && f['fromHour'].touched">שדה חובה</span>
                        </div>
                        <div class="col-6 pr-2">
                            <p-calendar formControlName="toHour" dateFormat="HH:mm" [timeOnly]="true"
                                placeholder="בחר שעה" class="p-calendar-matpash">
                            </p-calendar>
                            <span class="validation-error mt-2 mb-2 d-flex align-items-center"
                                *ngIf="f['toHour'].errors?.required && f['toHour'].touched">שדה חובה</span>
                            <span class="validation-error mt-2 mb-2 d-flex align-items-center" *ngIf="timeCorrect()">שעת
                                סיום קטנה משעת התחלה </span>

                        </div>
                    </div>
                    <div class="col-12 mb-5">
                        <input pInputText type="number" type="text" class="input-matpash w-100"
                            formControlName="allocation" placeholder="הקצאה">

                        <span class="validation-error mt-2 mb-2 d-flex align-items-center"
                            *ngIf="f['allocation'].errors?.pattern">מספרים בלבד</span>
                        <span class="validation-error mt-2 mb-2 d-flex align-items-center"
                            *ngIf="f['allocation'].errors?.required && f['allocation'].touched">שדה חובה</span>

                    </div>
                </div>

                <div class="d-flex mb-4 pt-5">
                    <button type="button" pButton (click)="displayModalEdit=false" label="ביטול"
                        class="ui-button-rounded button-matpash no-fill-btn col-3"></button>

                    <button type="button" pButton (click)="delete()" [disabled]="!sortingDay._id"
                        class=" col-4 mx-3 ui-button-rounded  align-items-center button-matpash d-flex justify-content-center blue-border  end-btn">
                        <span class="ml-2">מחק</span>
                        <i class="icon-delete"></i>
                    </button>

                    <button type="submit" pButton [disabled]="!sortingDayForm.valid"
                        class="col-4 ui-button-rounded  align-items-center button-matpash d-flex justify-content-center light-blue-fill end-btn">
                        <span class="ml-2">המשך</span>
                        <i class="icon-left-arrow"></i>
                    </button>
                </div>
            </form>
        </p-dialog>

        <p-dialog header="מחיקת יום מיונים קיים" [(visible)]="displayModalDelete" [modal]="true"
            [style]="{width: '600px', minWidth: '200px'}" [minY]="70" [baseZIndex]="10000">
            <div>האם ברצונך למחוק את יום המיונים הנבחר?</div>
            <div class="d-flex mb-4 pt-5">
                <button type="button" pButton label="אישור" (click)="deleteSortingDay()"
                    class="col-4 ui-button-rounded  align-items-center button-matpash d-flex justify-content-center light-blue-fill end-btn">
                </button>
                <button type="button" pButton label="ביטול" (click)="displayModalDelete=false"
                    class="ui-button-rounded button-matpash no-fill-btn col-3"></button>
            </div>
        </p-dialog>
    </div>
</div>

<app-message-modal *ngIf='displayModal' [title]='title' [displayModal]='displayModal' [showContact]='showContact'
    [content]='content' [img]='img' (statusToEmit)="getStatusModal($event)"></app-message-modal>