import * as tslib_1 from "tslib";
import { OnInit, EventEmitter, OnDestroy } from "@angular/core";
import { takeUntil } from "rxjs/operators";
import { BaseComponent } from "../../../shared/components/base-component/base-component";
var StepComponentBase = /** @class */ (function (_super) {
    tslib_1.__extends(StepComponentBase, _super);
    function StepComponentBase(stepsPagesService, dialog) {
        var _this = _super.call(this) || this;
        _this.stepsPagesService = stepsPagesService;
        _this.dialog = dialog;
        _this.onStepSubmit = new EventEmitter();
        _this.onStepBack = new EventEmitter();
        _this.onFormReset = new EventEmitter();
        return _this;
    }
    StepComponentBase.prototype.ngOnInit = function () {
        var _this = this;
        this.stepsPagesService.getSteps().pipe(takeUntil(this.componentDestroyed)).subscribe(function (steps) {
            _this.steps = steps;
            _this.setCurrentStepIndex(_this.currentStep);
        });
    };
    StepComponentBase.prototype.setCurrentStepIndex = function (step) {
        this.currentStepIndex = this.stepsPagesService.getStepIndex(step);
    };
    StepComponentBase.prototype.submit = function () {
        this.goToNextStep();
    };
    StepComponentBase.prototype.openModal = function (component, modalData, options) {
        return this.dialog.open(component, {
            disableClose: options.disableClose || false,
            backdropClass: '',
            panelClass: options.panelClass || 'modalPanel',
            role: 'dialog',
            ariaDescribedBy: '',
            ariaLabel: '',
            direction: 'rtl',
            autoFocus: true,
            maxWidth: options.maxWidth || 640,
            restoreFocus: true,
            data: modalData
        });
    };
    StepComponentBase.prototype.goToNextStep = function () {
        this.onStepSubmit.emit();
    };
    StepComponentBase.prototype.goToBackStep = function () {
        this.onStepBack.emit();
    };
    StepComponentBase.prototype.resetForm = function () {
        this.onFormReset.emit();
    };
    StepComponentBase.prototype.hasNext = function () {
        if (this.steps && this.steps.length) {
            return this.currentStepIndex !== this.steps.length - 1;
        }
        return false;
    };
    StepComponentBase.prototype.hasBack = function () {
        return this.currentStepIndex !== 0;
    };
    return StepComponentBase;
}(BaseComponent));
export { StepComponentBase };
