<div class="d-flex justify-content-center mb-4">
    <button  [disabled]="disableButtonPrevious===true"  label="" (click)="previous()" class="btn-arrow"><i class="fc-icon fc-icon-chevron-right"></i></button>
    <button [disabled]="disableButtonNext===true" label="" (click)="next()" class="btn-arrow"><i class="fc-icon fc-icon-chevron-left"></i></button><br>
</div>
<div class="d-flex flex-wrap">
    <p-card *ngFor="let i of allDays" class="mx-2  mb-3 mb-md-0 flex-grow-1">
        <header>{{i.day}}</header>
        <header>{{i.date | date:'dd/MM/yyyy'}}</header>
        <div class="h-content">
        <div *ngFor="let item of i.details" class="card-content">
            <div class="d-flex align-items-center days border-bottom" (click)="openModalStoringDay(item, 0)">
                <span>{{item?.professionId?item?.professionId?.profession:'&nbsp;'}}</span>
                <span class="mr-auto"><span *ngIf="item.fromHour">{{item.fromHour | date: "HH:mm"}}</span></span>
            </div>
        </div>
    </div>
    </p-card>
</div>
<div class="d-flex ml-4 mt-5">
    <button type="button" pButton
        class="col-3 col-md-1 mr-auto ui-button-rounded  button-matpash light-blue-fill d-flex align-items-center  justify-content-center  end-btn"
        label="הוספה" (click)="modalAddSortingDay()">
    </button>
</div>