import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { CookieService } from 'ngx-cookie-service';


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(public jwtHelper: JwtHelperService , private cookieService: CookieService) {}

  public isAuthenticated(): boolean {
     const token = sessionStorage.getItem('token');
     return !!token;
  }

  // public hasOtpSuccess(): boolean {
  //   return this.cookieService.check('otp');
  // }
}
