import { Component, OnInit } from '@angular/core';
import { UserDataService } from 'src/app/services/user-data.service';
import { CurrentEmployee, CurrentEmployeesData } from 'src/app/models/interfaces';
import colorText from 'src/app/shared/util/colorText';
import { EmployeeService } from 'src/app/services/employee-service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-current-employee-status',
  templateUrl: './current-employee-status.component.html',
  styleUrls: ['./current-employee-status.component.scss']
})
export class CurrentEmployeeStatusComponent implements OnInit {
  date: Date;
  loading: boolean;
  currentEmployeesData: CurrentEmployeesData

  constructor(private userDataService: UserDataService, private employeeService: EmployeeService, private router: Router) {
    this.date = new Date();
  }

  ngOnInit() {
    this.userDataService.getCurrentEmployeesStatus().subscribe(currentEmployeesData => {
      this.currentEmployeesData = currentEmployeesData;
    });
  }

  get allowRenewDays (){
    return this.employeeService.getAllowRenewDays();
  }
  renewLicense(employee:CurrentEmployee){
    this.employeeService.setWantedWorkersEmployees([employee]);
    // this.router.navigateByUrl("/employmentapplication");
    this.employeeService.renewEmployee.next(employee.licenseId);
    this.router.navigateByUrl("/requestsandlicenses/licences");
  }

  getWarningClass(employee: CurrentEmployee): string {
    return employee.daysLeftToExpire <= this.allowRenewDays ? `${colorText.RED} tableDataInside--bold` : "";
  }
}
