import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { StepComponentBase } from '../wizard-step-component-base/step-component-base';
import { MessagesModalComponent } from '../../shared/messages-modal/messages-modal.component';
import regionsTypes from 'src/app/shared/util/regionTypes';
var ApplicationSummaryStepComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ApplicationSummaryStepComponent, _super);
    function ApplicationSummaryStepComponent(stepsPagesService, dialog, formService) {
        var _this = _super.call(this, stepsPagesService, dialog) || this;
        _this.formService = formService;
        _this.FormGroupRef = null;
        _this.isIsraelRegion = false;
        return _this;
    }
    ApplicationSummaryStepComponent.prototype.ngOnInit = function () {
        var _this = this;
        _super.prototype.ngOnInit.call(this);
        this.FormGroupRef = this.formService.getForm();
        this.isIsraelRegion = this.formService.getFormGroupRefByName('transactionArea').get('region').value === regionsTypes.ISRAEL;
        this.formService.getFormGroupRefByName('transactionArea').get('region').valueChanges.subscribe(function (controlValue) {
            _this.isIsraelRegion = controlValue === regionsTypes.ISRAEL;
        });
    };
    // .pipe(takeUntil(this.componentDestroyed))
    ApplicationSummaryStepComponent.prototype.submit = function () {
        var _this = this;
        this.formService.submitForm().subscribe(function (applicationId) {
            console.log(applicationId),
                _this.openSubmitModal(true, "\u05E0\u05D0 \u05DC\u05E9\u05D9\u05DD \u05DC\u05D1, \u05EA\u05D4\u05DC\u05D9\u05DA \u05D6\u05D4 \u05E6\u05E4\u05D5\u05D9 \u05DC\u05E7\u05D7\u05EA \u05E2\u05D3 7 \u05D9\u05DE\u05D9 \u05E2\u05D1\u05D5\u05D3\u05D4,\n      \u05D0\u05E0\u05D5 \u05DE\u05DE\u05DC\u05D9\u05E6\u05D9\u05DD \u05DC\u05E9\u05DE\u05D5\u05E8 \u05D0\u05EA \u05DE\u05E1\u05E4\u05E8 \u05D4\u05E4\u05E0\u05D9\u05D9\u05D4 \u05DC\u05E6\u05D5\u05E8\u05DA \u05DE\u05E2\u05E7\u05D1", applicationId);
        }, function (fail) {
            if (fail.status === 400) {
                _this.openSubmitModal(false, fail.error);
                return;
            }
            _this.openSubmitModal(false, 'עקב תקלה זמנית אין באפשרותנו לבצע את הפעולה שביקשת. אנו ממליצים לנסות שוב בעוד מספר רגעים');
        });
    };
    ApplicationSummaryStepComponent.prototype.openSubmitModal = function (success, msg, request_id) {
        this.openModal(MessagesModalComponent, {
            submitSuccess: success,
            message: msg,
            requestId: request_id
        }, { disableClose: true });
    };
    return ApplicationSummaryStepComponent;
}(StepComponentBase));
export { ApplicationSummaryStepComponent };
