import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'matpash-carrer';
  isLoginPage: boolean = false;

  constructor(public translate: TranslateService,private router: Router) {
    translate.addLangs(['en', 'he']);
    translate.setDefaultLang('he');
    translate.use('he');
  }

  ngOnInit(){
    this.router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        this.isLoginPage = e.url === '/login';
      }
    });
  }
}
