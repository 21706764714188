import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UserDataService } from 'src/app/services/user-data.service';
import { User } from 'src/app/models/interfaces';
import { LoginService } from 'src/app/services/login.service';
import { Router } from '@angular/router';
import { FormGroup, Validators, FormBuilder, FormControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { ValidatePhone } from 'src/app/shared/validators/phone.validator';
import { compareValidator } from 'src/app/shared/validators/compare.validator';

@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss']
})
export class MainMenuComponent implements OnInit {
  user: User
  displayModal:boolean=false
  editInfoFormGroup: FormGroup;
  editMode: boolean = false;
  isErrorShow: boolean = false;


  constructor(
    private userDataService: UserDataService,
    private loginService: LoginService,
    private router: Router,
    private formBuilder: FormBuilder
  ) {}
  @Input() isMainMenuOpen: boolean;
  @Output() toggleMainMenu = new EventEmitter();
  @Output() closeMainMenu = new EventEmitter();

  ngOnInit() {
    this.userDataService.getUser().subscribe(user => {
      this.user = user;
    });

    this.createForm();

  }

  logOut() {
    this.loginService.logout().subscribe((success) => {
      sessionStorage.clear();
      this.router.navigate(['login'])
    });
  }
  showModal(){
    this.displayModal=true
  }

  createForm() {
    this.editInfoFormGroup = this.formBuilder.group({
      phone: ['', [Validators.minLength(10), Validators.maxLength(10), ValidatePhone,]],
      email: ['', [Validators.email]],
      confirmEmail: ['', [Validators.email, compareValidator('email')]],

    }, { validators: this.atLeastOneValidator });
    if (this.user) {
      this.editInfoFormGroup.patchValue(this.user)
    }
  }

  cancelForm() {
    this.editMode = false
    this.createForm();
  }

  onSubmit() {
    this.userDataService.personalInfo(this.editInfoFormGroup.value).subscribe(res => {
      if (res) {
        this.isErrorShow = false;
        this.editMode = false;
      }
    }, (err) => {
      this.isErrorShow = true;
    })
  }

  public atLeastOneValidator: ValidatorFn = (control: FormGroup): ValidationErrors | null => {

    let controls = control.controls;
    if (controls) {

      if (controls['email'].value) {
        if (!controls['confirmEmail'].value) {
          return { 'compare': false };
        }
      }
      if (controls['phone'].value) {
        return null;
      }
      return (controls['phone'].value || (controls['email'].value && controls['confirmEmail'].value)) ? null : { 'compare': true }

    }

  }

}
