<div *ngIf="currentEmployeesData" class="EmployeesStatus">
  <ng-container
    *ngIf=" currentEmployeesData && currentEmployeesData.employees.length;then employeesTable; else noReuslt">
  </ng-container>

  <ng-template #employeesTable>
    <div class="requestStatusWrapper requestStatusWrapper--withTablePanel">
      <div class="requestSummary textContent">
        <span *ngIf="selection.selected.length" class="marked">
          {{amountOfSelectdEmployees()}}
        </span>
        <span class="sum">סה"כ
          <span>{{ currentEmployeesData.totalEmployees }}</span>
          עובדים נוכחיים</span>
      </div>

      <span class="requestCurrentDate ">
        <span class="mr-2">עדכון אחרון:</span>
        {{ date | date: "d/M/y" }}
        | {{ date | date: "HH:mm" }}
      </span>
    </div>

    <div class="tablePanel">
      <div class="d-flex">
        <!-- חידוש רישיון -->
        <button class="panelBtn textContent mx-3" type="button" (click)="renewLicenses()"
          [disabled]="isPanelBtnDisabled()">
          <!-- REMOVE TRUE -->
          <!--[disabled]="isPanelBtnDisabled() || true" -->
          <span>חידוש רישיון</span>
          <i class="icon-License-Renewal mr-3"></i>
        </button>

        <!-- ביטול רישיון -->
        <button class="panelBtn textContent mx-3" type="button" (click)="openCancelLicensesModal()"
          [disabled]="isPanelBtnDisabled()">
          <span>ביטול רישיון</span>
          <i class="icon-Revocation-License mr-3"></i>
        </button>

        <!-- ביטול בחירת עובדים -->
        <!-- <button *ngIf="selection.selected.length > 1" class="panelBtn textContent "
        type="button" (click)="clear()">
        ביטול בחירת עובדים
      </button> -->

        <!-- צפייה ברישיון -->
        <button [disabled]="isPanelBtnDisabled() || this.selection.selected.length>1" class="panelBtn textContent mx-3"
          (click)="getLicenseFile($event)" type="button" target="blank">
          <span> צפייה ברישיון</span>
          <i class="icon-look mr-3"></i>
        </button>

        <!-- <button type="button" pButton icon="pi pi-file-pdf" iconPos="left" label="PDF" (click)="exportPdf()" class="ui-button-warning"></button> -->

        <!-- היסטוריית מעברים -->
        <button class="panelBtn textContent mx-3" (click)="transitionsHistory()" type="button"
          [disabled]="isPanelBtnDisabled() || this.selection.selected.length>1">
          <span>היסטוריית מעברים</span>
          <i class="icon-Historical-transitions mr-3"></i>
        </button>
      </div>
    </div>
    <div id="content" #content>

      <perfect-scrollbar class="perfectScrollBar tableScrollBar" [config]="config">
        <div class="mat-table-current">

          <p-table id="content" #content [columns]="displayedColumns" [value]="dataSource"
            styleClass="p-datatable-responsive-demo" [responsive]="true" [(selection)]="selectedEmployee"
            [scrollable]="true" scrollHeight="300px" class="generalAngularTable" id="employeesTable">

            <!-- applicationId Column -->
            <ng-template pTemplate="header" let-columns>
              <tr>
                <th>
                  <mat-checkbox (change)="$event ? masterToggle() : null"
                    [checked]="selection.hasValue() && isAllSelected()"
                    [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()"
                    class="checkboxSelect">
                  </mat-checkbox>

                </th>
                <th *ngFor="let col of displayedColumns" [pSortableColumn]="col.field">

                  <p-sortIcon [field]="col.field"></p-sortIcon>
                  {{col.header}}
                </th>
              </tr>
            </ng-template>



            <ng-template pTemplate="body" let-rowData let-columns>
              <tr>
                <td>
                  <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(rowData) : null"
                    [checked]="selection.isSelected(rowData)" [aria-label]="checkboxLabel(rowData)"
                    class="checkboxSelect">
                  </mat-checkbox>
                </td>

                <td *ngFor="let col of displayedColumns">
                  <span class="ui-column-title">{{col.header}}</span>
                  <span *ngIf="col.field=='fullName'"> {{rowData[col.field]}}</span>
                  <span *ngIf="col.field=='id'"> {{rowData[col.field]}}</span>
                  <span *ngIf="col.field=='licenseId'"> {{rowData[col.field]}}</span>
                  <span *ngIf="col.field=='field.name'">{{ rowData.field.name }} - {{ rowData.field.subName}}</span>
                  <span *ngIf="rowData.isLicenseInIsrael==false">
                    <span *ngIf="col.field=='licenseExpiredDate'">

                      <div class="d-flex align-items-center" [ngClass]="getWarningClass(rowData)">
                        <i class="ml-2 icon-Expiration color-status"> </i>
                        <span> {{ rowData.daysLeftToExpire | stringDayOrDate: rowData.licenseExpiredDate }}</span>
                      </div>
                    </span>
                  </span>
                  <span *ngIf="col.field=='settlements'">
                    <div *ngIf="rowData.isLicenseInIsrael; else settlementsContainer">
                      <span>ישראל</span>
                    </div>
                    <ng-template #settlementsContainer>
                      <span *ngFor="let settlement of rowData.settlements" class="tdText">
                        {{ settlement.name }}
                      </span>
                    </ng-template>
                  </span>
                  <span *ngIf="rowData.isLicenseInIsrael==true">
                    <span *ngIf="col.field=='isLodgingInIsrael'">
                      {{ rowData.isLodgingInIsrael | yesNo }}
                    </span>
                  </span>
                </td>
              </tr>
            </ng-template>


            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns" [ngClass]="getRowColor(row)"
              class="textContent textContent--semiBold shadow bgWhite"></tr>
          </p-table>




          <!-- 
        <table mat-table matSort [dataSource]="dataSource" class="generalAngularTable" id="employeesTable">
          <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef class="checkBoxCell">
              <mat-checkbox (change)="$event ? masterToggle() : null"
                [checked]="selection.hasValue() && isAllSelected()"
                [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()"
                class="checkboxSelect">
              </mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let row" class="checkBoxCell">
              <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)" class="checkboxSelect">
              </mat-checkbox>
            </td>
          </ng-container>

          <ng-container matColumnDef="fullName">
            <th mat-header-cell *matHeaderCellDef>
              <div class="theader">
                <div class="" mat-sort-header [class.active]="">שם העובד</div>

                <div class="filterBtnWrapper" [matMenuTriggerFor]="nameFilter">
                  <button (click)="toggleDropdownFilter($event, filterNameBtn)" #filterNameBtn mat-button
                    class="dropDownFilterBtn"></button>
                </div>
                <mat-menu #nameFilter="matMenu" backdropClass="dropDownBackDrop">
                  <div class="dropDownFilterBox dropDownFilterBox--first">
                    <mat-form-field class="filterInputField">
                      <input (click)="$event.stopPropagation()" matInput (keyup)="applyFilter($event.target.value)" />
                    </mat-form-field>
                    <perfect-scrollbar class="perfectScrollBar filterScrollBar "  [config]="config">
                      <ul class="filterList">
                        <li class="filterList__item selectAll">
                          <mat-checkbox (click)="$event.stopPropagation()" class="checkboxSelect  checkboxSelectNoTable">
                            הכל
                          </mat-checkbox>
                        </li>
                        <li class="filterList__item">
                          <mat-checkbox (click)="$event.stopPropagation()" class="checkboxSelect checkboxSelectNoTable">
                            וואליד באדיר
                          </mat-checkbox>
                        </li>
                        <li class="filterList__item">
                          <mat-checkbox (click)="$event.stopPropagation()" class="checkboxSelect checkboxSelectNoTable">
                            סאלים טועמה
                          </mat-checkbox>
                        </li>
                        <li class="filterList__item">
                          <mat-checkbox (click)="$event.stopPropagation()" class="checkboxSelect checkboxSelectNoTable">
                            מוחמד סלאח
                          </mat-checkbox>
                        </li>
                        <li class="filterList__item">
                          <mat-checkbox (click)="$event.stopPropagation()" class="checkboxSelect checkboxSelectNoTable">
                            איימן עודה
                          </mat-checkbox>
                        </li>
                      </ul>
                    </perfect-scrollbar>

                    <div class="filterActions">
                      <button mat-button class="btn btn--semiBold btn--regularBtn colorTextBlue">
                        אישור
                      </button>
                      <button mat-button class="btn btn--semiBold colorTextGray btn--regularBtn">
                        ביטול
                      </button>
                    </div>
                  </div>
                </mat-menu>
              </div>
            </th>
            <td mat-cell *matCellDef="let element">
              <div class="contentWrapper">
                <div class="th colorTextGray">שם העובד</div>
                <div class="td" [ngClass]="getElementColor(element)">
                  {{ element.fullName }}
                  <span *ngIf="element.isRenew && isRequestSent" class="withContent colorTextLightGray">
                    נשלחה בקשה לחידוש רשיון - מס' פניה: {{element.renewalApplicationId}}
                  </span>
                  
                  <span *ngIf="element.isRenew && element.workerRenewalAppStatus && !isRequestSent" class="withContent colorTextLightGray">
                    {{ populateRenewLicenseText(element) }}
                  </span>
                </div>
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef>
              <div class="theader">
                <div class="" mat-sort-header [class.active]="">מספר זהות</div>
                <div class="filterBtnWrapper" [matMenuTriggerFor]="idFilter">
                  <button (click)="toggleDropdownFilter($event, filterIdBtn)" #filterIdBtn mat-button
                    class="dropDownFilterBtn"></button>
                </div>
                <mat-menu #idFilter="matMenu" backdropClass="dropDownBackDrop">
                  <div class="dropDownFilterBox">
                    <mat-form-field class="filterInputField">
                      <input (click)="$event.stopPropagation()" matInput (keyup)="applyFilter($event.target.value)" />
                    </mat-form-field>
                    <perfect-scrollbar class="perfectScrollBar filterScrollBar "  [config]="config">
                      <ul class="filterList">
                        <li class="filterList__item selectAll">
                          <mat-checkbox (click)="$event.stopPropagation()" class="checkboxSelect checkboxSelectNoTable">
                            הכל
                          </mat-checkbox>
                        </li>
                        <li class="filterList__item">
                          <mat-checkbox (click)="$event.stopPropagation()" class="checkboxSelect checkboxSelectNoTable">
                            201512598
                          </mat-checkbox>
                        </li>
                        <li class="filterList__item">
                          <mat-checkbox (click)="$event.stopPropagation()" class="checkboxSelect checkboxSelectNoTable">
                            201512598
                          </mat-checkbox>
                        </li>
                        <li class="filterList__item">
                          <mat-checkbox (click)="$event.stopPropagation()" class="checkboxSelect checkboxSelectNoTable">
                            201512598
                          </mat-checkbox>
                        </li>
                        <li class="filterList__item">
                          <mat-checkbox (click)="$event.stopPropagation()" class="checkboxSelect checkboxSelectNoTable">
                            037877665
                          </mat-checkbox>
                        </li>
                        <li class="filterList__item">
                          <mat-checkbox (click)="$event.stopPropagation()" class="checkboxSelect checkboxSelectNoTable">
                            300988723
                          </mat-checkbox>
                        </li>
                      </ul>
                    </perfect-scrollbar>

                    <div class="filterActions">
                      <button mat-button class="btn btn--semiBold btn--regularBtn colorTextBlue">
                        אישור
                      </button>
                      <button mat-button class="btn btn--semiBold colorTextGray btn--regularBtn">
                        ביטול
                      </button>
                    </div>
                  </div>
                </mat-menu>
              </div>
            </th>
            <td mat-cell *matCellDef="let element">
              <div class="th colorTextGray">מספר זהות</div>
              <div class="td" [ngClass]="getElementColor(element)">
                {{ element.id }}
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="licenseId">
            <th mat-header-cell *matHeaderCellDef>
              <div class="theader">
                <div class="" mat-sort-header [class.active]="">מס' רישיון</div>
                <div class="filterBtnWrapper" [matMenuTriggerFor]="licenseFilter">
                  <button (click)="toggleDropdownFilter($event, filterLicenseBtn)" #filterLicenseBtn mat-button
                    class="dropDownFilterBtn"></button>
                </div>
                <mat-menu #licenseFilter="matMenu" backdropClass="dropDownBackDrop">
                  <div class="dropDownFilterBox">
                    <mat-form-field class="filterInputField">
                      <input (click)="$event.stopPropagation()" matInput (keyup)="applyFilter($event.target.value)" />
                    </mat-form-field>
                    <perfect-scrollbar class="perfectScrollBar filterScrollBar "  [config]="config">
                      <ul class="filterList">
                        <li class="filterList__item selectAll">
                          <mat-checkbox (click)="$event.stopPropagation()" class="checkboxSelect checkboxSelectNoTable">
                            הכל
                          </mat-checkbox>
                        </li>
                        <li class="filterList__item">
                          <mat-checkbox (click)="$event.stopPropagation()" class="checkboxSelect checkboxSelectNoTable">
                            123456
                          </mat-checkbox>
                        </li>
                        <li class="filterList__item">
                          <mat-checkbox (click)="$event.stopPropagation()" class="checkboxSelect checkboxSelectNoTable">
                            321654
                          </mat-checkbox>
                        </li>
                        <li class="filterList__item">
                          <mat-checkbox (click)="$event.stopPropagation()" class="checkboxSelect checkboxSelectNoTable">
                            897654
                          </mat-checkbox>
                        </li>
                        <li class="filterList__item">
                          <mat-checkbox (click)="$event.stopPropagation()" class="checkboxSelect checkboxSelectNoTable">
                            7895213
                          </mat-checkbox>
                        </li>
                        <li class="filterList__item">
                          <mat-checkbox (click)="$event.stopPropagation()" class="checkboxSelect checkboxSelectNoTable">
                            986321
                          </mat-checkbox>
                        </li>
                      </ul>
                    </perfect-scrollbar>

                    <div class="filterActions">
                      <button mat-button class="btn btn--semiBold btn--regularBtn colorTextBlue">
                        אישור
                      </button>
                      <button mat-button class="btn btn--semiBold colorTextGray btn--regularBtn">
                        ביטול
                      </button>
                    </div>
                  </div>
                </mat-menu>
              </div>
            </th>
            <td mat-cell *matCellDef="let element">
              <div class="th colorTextGray">מס' רישיון</div>
              <div class="td" [ngClass]="getElementColor(element)">
                {{ element.licenseId }}
              
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="field.name">
            <th mat-header-cell *matHeaderCellDef>
              <div class="theader">
                <div class="" mat-sort-header [class.active]="">ענף</div>
                <div class="filterBtnWrapper" [matMenuTriggerFor]="branchFilter">
                  <button (click)="toggleDropdownFilter($event, filterBranchBtn)" #filterBranchBtn mat-button
                    class="dropDownFilterBtn"></button>
                </div>
                <mat-menu #branchFilter="matMenu" backdropClass="dropDownBackDrop">
                  <div class="dropDownFilterBox">
                    <mat-form-field class="filterInputField">
                      <input matInput (click)="$event.stopPropagation()" (keyup)="applyFilter($event.target.value)" />
                    </mat-form-field>
                    <perfect-scrollbar class="perfectScrollBar filterScrollBar " [config]="config">
                      <ul class="filterList">
                        <li class="filterList__item selectAll">
                          <mat-checkbox (click)="$event.stopPropagation()" class="checkboxSelect checkboxSelectNoTable">
                            הכל
                          </mat-checkbox>
                        </li>
                        <li class="filterList__item">
                          <mat-checkbox (click)="$event.stopPropagation()" class="checkboxSelect checkboxSelectNoTable">
                            נקיון
                          </mat-checkbox>
                        </li>
                        <li class="filterList__item">
                          <mat-checkbox (click)="$event.stopPropagation()" class="checkboxSelect checkboxSelectNoTable">
                            בניין
                          </mat-checkbox>
                        </li>
                        <li class="filterList__item">
                          <mat-checkbox (click)="$event.stopPropagation()" class="checkboxSelect checkboxSelectNoTable">
                            חקלאות
                          </mat-checkbox>
                        </li>
                      </ul>
                    </perfect-scrollbar>

                    <div class="filterActions">
                      <button mat-button class="btn btn--semiBold btn--regularBtn colorTextBlue">
                        אישור
                      </button>
                      <button mat-button class="btn btn--semiBold colorTextGray btn--regularBtn">
                        ביטול
                      </button>
                    </div>
                  </div>
                </mat-menu>
              </div>
            </th>
            <td mat-cell *matCellDef="let element">
              <div class="th colorTextGray">ענף</div>
              <div class="td" [ngClass]="getElementColor(element)">
                {{ element.field.name }} - {{ element.field.subName}}
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="licenseExpiredDate">
            <th mat-header-cell *matHeaderCellDef>
              <div class="theader">
                <div class="" mat-sort-header [class.active]="">תום תוקף</div>
                <div class="filterBtnWrapper" [matMenuTriggerFor]="dateFilter">
                  <button (click)="toggleDropdownFilter($event, filterDateBtn)" #filterDateBtn mat-button
                    class="dropDownFilterBtn"></button>
                </div>
                <mat-menu #dateFilter="matMenu" backdropClass="dropDownBackDrop">
                  <div class="dropDownFilterBox">
                    <mat-form-field class="filterInputField">
                      <input matInput (click)="$event.stopPropagation()" (keyup)="applyFilter($event.target.value)" />
                    </mat-form-field>
                    <perfect-scrollbar class="perfectScrollBar filterScrollBar "  [config]="config">
                      <ul class="filterList">
                        <li class="filterList__item selectAll">
                          <mat-checkbox (click)="$event.stopPropagation()" class="checkboxSelect checkboxSelectNoTable">
                            הכל
                          </mat-checkbox>
                        </li>
                        <li class="filterList__item">
                          <mat-checkbox (click)="$event.stopPropagation()" class="checkboxSelect checkboxSelectNoTable">
                            03/02/2020
                          </mat-checkbox>
                        </li>
                        <li class="filterList__item">
                          <mat-checkbox (click)="$event.stopPropagation()" class="checkboxSelect checkboxSelectNoTable">
                            02/01/2020
                          </mat-checkbox>
                        </li>
                        <li class="filterList__item">
                          <mat-checkbox (click)="$event.stopPropagation()" class="checkboxSelect checkboxSelectNoTable">
                            מחר
                          </mat-checkbox>
                        </li>
                      </ul>
                    </perfect-scrollbar>

                    <div class="filterActions">
                      <button mat-button class="btn btn--semiBold btn--regularBtn colorTextBlue">
                        אישור
                      </button>
                      <button mat-button class="btn btn--semiBold colorTextGray btn--regularBtn">
                        ביטול
                      </button>
                    </div>
                  </div>
                </mat-menu>
              </div>
            </th>
            <td mat-cell *matCellDef="let element">
              <div class="th colorTextGray">תום תוקף</div>
              <div class="td" [ngClass]="getElementColor(element)">
                {{ element.daysLeftToExpire | stringDayOrDate: element.licenseExpiredDate }}
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="settlements">
            <th mat-header-cell *matHeaderCellDef>
              <div class="theader textContent ">
                <div class="" [class.active]="">מיקום</div>
                <div class="filterBtnWrapper" [matMenuTriggerFor]="locationFilter">
                  <button (click)="toggleDropdownFilter($event, filterLocationBtn)" #filterLocationBtn mat-button
                    class="dropDownFilterBtn"></button>
                </div>
                <mat-menu #locationFilter="matMenu" backdropClass="dropDownBackDrop">
                  <div class="dropDownFilterBox dropDownFilterBox--last">
                    <mat-form-field class="filterInputField">
                      <input (click)="$event.stopPropagation()" matInput (keyup)="applyFilter($event.target.value)" />
                    </mat-form-field>
                    <perfect-scrollbar class="perfectScrollBar filterScrollBar "  [config]="config">
                      <ul class="filterList">
                        <li class="filterList__item selectAll">
                          <mat-checkbox (click)="$event.stopPropagation()" class="checkboxSelect checkboxSelectNoTable">
                            הכל
                          </mat-checkbox>
                        </li>
                        <li class="filterList__item">
                          <mat-checkbox (click)="$event.stopPropagation()" class="checkboxSelect checkboxSelectNoTable">
                            ישראל
                          </mat-checkbox>
                        </li>
                        <li class="filterList__item">
                          <mat-checkbox (click)="$event.stopPropagation()" class="checkboxSelect checkboxSelectNoTable">
                            בית אריה
                          </mat-checkbox>
                        </li>
                        <li class="filterList__item">
                          <mat-checkbox (click)="$event.stopPropagation()" class="checkboxSelect checkboxSelectNoTable">
                            עופרים
                          </mat-checkbox>
                        </li>
                      </ul>
                    </perfect-scrollbar>

                    <div class="filterActions">
                      <button mat-button class="btn btn--semiBold btn--regularBtn colorTextBlue">
                        אישור
                      </button>
                      <button mat-button class="btn btn--semiBold colorTextGray btn--regularBtn">
                        ביטול
                      </button>
                    </div>
                  </div>
                </mat-menu>
              </div>
            </th>
            <td mat-cell *matCellDef="let element">
              <div class="th colorTextGray">מיקום</div>
              <div class="td" [ngClass]="getElementColor(element)">
                <div *ngIf="element.isLicenseInIsrael; else settlementsContainer">
                    <span>ישראל</span>
                </div>
                
                <ng-template #settlementsContainer>
                  <span *ngFor="let settlement of element.settlements" class="tdText">
                    {{ settlement.name }}
                  </span>
                </ng-template>

              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="isLodgingInIsrael">
            <th mat-header-cell *matHeaderCellDef>
              <div class="theader">
                <div class="" mat-sort-header [class.active]="">לינה בישראל</div>
                <div class="filterBtnWrapper" [matMenuTriggerFor]="israelFilter">
                  <button (click)="toggleDropdownFilter($event, filterisraelAreaBtn)" #filterisraelAreaBtn mat-button
                    class="dropDownFilterBtn"></button>
                </div>
                <mat-menu #israelFilter="matMenu" backdropClass="dropDownBackDrop">
                  <div class="dropDownFilterBox dropDownFilterBox--last">
                    <mat-form-field class="filterInputField">
                      <input (click)="$event.stopPropagation()" matInput (keyup)="applyFilter($event.target.value)" />
                    </mat-form-field>
                    <perfect-scrollbar class="perfectScrollBar filterScrollBar "  [config]="config">
                      <ul class="filterList">
                        <li class="filterList__item selectAll">
                          <mat-checkbox (click)="$event.stopPropagation()" class="checkboxSelect checkboxSelectNoTable">
                            הכל
                          </mat-checkbox>
                        </li>
                        <li class="filterList__item">
                          <mat-checkbox (click)="$event.stopPropagation()" class="checkboxSelect checkboxSelectNoTable">
                            כן
                          </mat-checkbox>
                        </li>
                        <li class="filterList__item">
                          <mat-checkbox (click)="$event.stopPropagation()" class="checkboxSelect checkboxSelectNoTable">
                            לא
                          </mat-checkbox>
                        </li>
                      </ul>
                    </perfect-scrollbar>

                    <div class="filterActions">
                      <button mat-button class="btn btn--semiBold btn--regularBtn colorTextBlue">
                        אישור
                      </button>
                      <button mat-button class="btn btn--semiBold colorTextGray btn--regularBtn">
                        ביטול
                      </button>
                    </div>
                  </div>
                </mat-menu>
              </div>
            </th>
            <td mat-cell *matCellDef="let element">
              <div class="th colorTextGray">לינה בישראל</div>
              <div class="td" [ngClass]="getElementColor(element)">
                {{ element.isLodgingInIsrael | yesNo }}
              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns" [class.checked]="selection.isSelected(row)"
            class="textContent  shadow bgWhite">
          </tr>
        </table> -->

        </div>
      </perfect-scrollbar>
    </div>

  </ng-template>

  <ng-template #noReuslt>
    <div class="wrapperInner bgWhite noReusult">
      <h2 class="subTitle ">
        לא נמצאו נתונים
      </h2>
    </div>
  </ng-template>
</div>

<app-licenses-cancel-modal *ngIf="cancelLicence==true" [cancelLicence]="cancelLicence" [dialogData]="dialogData" (statusToEmit)="getStatusModal($event)"
></app-licenses-cancel-modal>

<p-dialog  [(visible)]="showModal" 
[style]="{width: '750px', minWidth: '200px'}"
 [minY]="70" [modal]="true" [responsive]="true" [autoZIndex]="true"
 [draggable]="false" appendTo="body" 
  [baseZIndex]="10000">
  <div class="dilog-matpash small-dialog h-100 licenses-dialog">

  <div class="row m-0 w-100 flex-column-reverse d-flex flex-column flex-md-row h-100 flex-md-wrap flex-nowrap">
    <div class=" col-12 col-md-8 left-side-dialog d-flex h-100 flex-column">
      <h1>{{title}}</h1>
      <h3>{{text}}</h3>

      <div *ngIf="!!errorMessage" class="d-flex colorTextRed align-items-center my-3">
        <i class="icon-16 ml-3"></i>
        <p class="textContent">{{errorMessage}}</p>
      </div>

      <div class="d-flex mt-5">
        <button type="button" pButton
          class="ui-button-rounded  button-matpash light-blue-fill d-flex align-items-center  flex-row-reverse justify-content-center"
          (click)="showModal=false" label="סגירה"></button>
      </div>
    </div>

    <div
      class="co-12 col-md-4 right-side-dialog d-flex flex-md-column justify-content-center justify-content-md-between flex-row-reverse">
      <img alt="" class="mb-0 mb-md-5 mr-n5 mt-5 img-header-mobile" src="../../../../../assets/imgs/LicenseRenewal.png" />

    </div>
  
  </div>
</div>
</p-dialog>