import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { SortingDay } from 'src/app/models/sortingDay';
import { Profession } from 'src/app/models/profession';

@Component({
  selector: 'app-sorting-day-month',
  templateUrl: './sorting-day-month.component.html',
  styleUrls: ['./sorting-day-month.component.scss']
})
export class SortingDayMonthComponent implements OnInit {

  @Input() listSortingDay: Array<SortingDay>;
  @Input() profession:Profession;
  @Input() fromDate: Date;
  @Output() editSortingDay = new EventEmitter<any>();

  options: any;
  event: any
  events: any[] = []
  sortingDay: SortingDay = new SortingDay();
  displayModal: boolean = false;
  showAddButton:boolean=false;

  constructor() { }

  ngOnInit() {
    this.init();
    this.fullMotherboardTest();
  }

  init() {
    for (let i = 0; i < this.listSortingDay.length; i++) {
      var date: Date = new Date(this.listSortingDay[i].examDate)

      this.event = {
        title: this.listSortingDay[i].professionId.profession + '\n ' + new Date(this.listSortingDay[i].fromHour).getHours() + ':' + new Date(this.listSortingDay[i].fromHour).getMinutes(),
        evt: this.listSortingDay[i],
        start: new Date(date)
      }
      this.events.push(this.event)
    }

    var base = this;

    this.options = {
      plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],

      eventClick: function (info) {
        const data = info.event._def.extendedProps.evt
        base.sendStatusModalToParent(data, 1)
      },

      defaultDate: new Date(this.fromDate),
      header: {
        left: 'prev,next',
        center: 'title',
        right: 'month,agendaWeek,agendaDay'
      },
      editable: true
    };
  }

  fullMotherboardTest() {
    this.listSortingDay.map(() => {
      !!this.profession._id ? this.showAddButton = true : this.showAddButton = false;
    })
  }

  modalAddSortingDay() {
    this.sortingDay = {}
    this.sendStatusModalToParent(this.sortingDay, 0)
  }

  sendStatusModalToParent(info, number: number) {
    this.sortingDay = (number == 1) ? info : {};
    let add: boolean = (number == 1) ? false : true;
    this.displayModal = true
    this.editSortingDay.emit({ display: this.displayModal, data: this.sortingDay, add: add })
  }
}
