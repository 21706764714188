import * as i0 from "@angular/core";
import * as i1 from "./auth.service";
import * as i2 from "@angular/router";
var LoginRedirectGuardService = /** @class */ (function () {
    function LoginRedirectGuardService(auth, router) {
        this.auth = auth;
        this.router = router;
    }
    LoginRedirectGuardService.prototype.canActivate = function () {
        if (this.auth.isAuthenticated()) {
            this.router.navigate(['home']);
        }
        else {
            return true;
        }
    };
    LoginRedirectGuardService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LoginRedirectGuardService_Factory() { return new LoginRedirectGuardService(i0.ɵɵinject(i1.AuthService), i0.ɵɵinject(i2.Router)); }, token: LoginRedirectGuardService, providedIn: "root" });
    return LoginRedirectGuardService;
}());
export { LoginRedirectGuardService };
