import { Injectable } from "@angular/core";
import { Observable, BehaviorSubject } from "rxjs";
import {
  EmployeeApplicationResponse,
  UnavailableField,
  Docs,
  RequestData,
  Settlement,
  EmployeeApplicationRequest
} from "src/app/models/interfaces";
import { filter } from "rxjs/operators";
import { ApplicationHttpClient } from 'src/app/shared/service/ApplicationHttpClient';
import {requestDetailMockData} from "../mockData/requestDetailMockData"

@Injectable({
  providedIn: "root"
})
export class WizradUserDataService {
  baseUrl: string;
  employeeApplicationRequest:EmployeeApplicationRequest
  // licenseAplicationsSubject = new BehaviorSubject<any>(null);
  private initSubject = new BehaviorSubject<boolean>(undefined);

  private downloadDocuments: any;
  private _requestData:BehaviorSubject<RequestData> = new BehaviorSubject<RequestData>(null);
  readonly useMock:boolean;
  readonly requestData = this._requestData.asObservable();
  initialHasPermit = false;

  constructor(
    private http:ApplicationHttpClient
  ) {
    this.useMock = false;
  }

  private _getRequestDetailsStep() {
    this.useMock ? this.setData(requestDetailMockData) : this.http
      .Get<RequestData>("api/request/getRequestData")
      .subscribe(res => {
        this.setData(res);
      });
  }

  private setData(res){
    this._requestData.next(res);
    this.initialHasPermit = res.hasPermit;
    this.downloadDocuments = res.downloadDocuments;
    this.initSubject.next(true);
  }

  getEmployees(requestData: EmployeeApplicationRequest) {
    this.employeeApplicationRequest=requestData
    return this.http.Post<EmployeeApplicationResponse>(
      `api/request/runAdministrativeCheck`, requestData)
    
  }

  getSettlements(value: string): Observable<Settlement[]> {
    return this.http.Get<Settlement[]>( `api/settlements/get/${value}`,{preventSpinner:true});
  }

  findDisableIsrael():Observable<any>{
    return this.http.Get<any>(`api/internalOption/`);
  }

  init() {
    this._getRequestDetailsStep();
  }

  isInit(): Observable<boolean> {
    return this.initSubject.asObservable().pipe(filter(val => !!val));
  }


  getDownloadDocumentByName(documentName: string = ""): Docs {
    return documentName ? this.downloadDocuments[documentName] : this.downloadDocuments;
  }

  dispose(): void {
  this._requestData.next(null);
    this.initSubject.next(false);
  }
}
