/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./wanted-workers-step.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./applications/employee-list/employee-application-list.component.ngfactory";
import * as i3 from "./applications/employee-list/employee-application-list.component";
import * as i4 from "../../service/form.service";
import * as i5 from "../../service/wizrad-user-data.service";
import * as i6 from "../../service/field.service";
import * as i7 from "../../service/building-permit.service";
import * as i8 from "@angular/common";
import * as i9 from "./id-numbers-form/id-numbers-form.component.ngfactory";
import * as i10 from "./id-numbers-form/id-numbers-form.component";
import * as i11 from "../../wizard-stepper/stepper-buttons/stepper-buttons-component.ngfactory";
import * as i12 from "../../wizard-stepper/stepper-buttons/stepper-buttons-component";
import * as i13 from "./wanted-workers-step.component";
import * as i14 from "../../service/step-pages.service";
import * as i15 from "@angular/material/dialog";
import * as i16 from "../../../services/employee-service";
import * as i17 from "../../../shared/service/scrollToService";
var styles_WantedWorkersStepComponent = [i0.styles];
var RenderType_WantedWorkersStepComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_WantedWorkersStepComponent, data: {} });
export { RenderType_WantedWorkersStepComponent as RenderType_WantedWorkersStepComponent };
function View_WantedWorkersStepComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "employee-application-list", [], null, [[null, "onGoBack"], [null, "onResetStep"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onGoBack" === en)) {
        var pd_0 = (_co.goBack() !== false);
        ad = (pd_0 && ad);
    } if (("onResetStep" === en)) {
        var pd_1 = (_co.resetAll() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_EmployeeApplicationListComponent_0, i2.RenderType_EmployeeApplicationListComponent)), i1.ɵdid(1, 114688, [[1, 4]], 0, i3.EmployeeApplicationListComponent, [i4.FormService, i5.WizradUserDataService, i6.FieldService, i7.BuildingPermitService], { employeesApplicationList: [0, "employeesApplicationList"], selectedField: [1, "selectedField"], licenseType: [2, "licenseType"] }, { onGoBack: "onGoBack", onResetStep: "onResetStep" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.parent.context.$implicit; var currVal_1 = _co.selectedField; var currVal_2 = _co.licenseType; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_WantedWorkersStepComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WantedWorkersStepComponent_2)), i1.ɵdid(2, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _v.context.$implicit.employees.length; _ck(_v, 2, 0, currVal_0); }, null); }
function View_WantedWorkersStepComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "textContent textContent--semiBold colorTextRed validationError"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["* \u05D7\u05D5\u05D1\u05D4 \u05DC\u05D4\u05D5\u05E1\u05D9\u05E3 \u05E2\u05D5\u05D1\u05D3\u05D9\u05DD \u05DC\u05E8\u05E9\u05D9\u05DE\u05EA \u05E2\u05D5\u05D1\u05D3\u05D9\u05DD \u05D4\u05DE\u05D1\u05D5\u05E7\u05E9\u05D9\u05DD"]))], null, null); }
export function View_WantedWorkersStepComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { employeeListRef: 1 }), (_l()(), i1.ɵeld(1, 0, null, null, 12, "div", [["class", "wizardStep"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "wrapperInner bgWhite shdaow"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "stepTitle subTitle  colorTextBlack"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u05E2\u05D5\u05D1\u05D3\u05D9\u05DD \u05DE\u05D1\u05D5\u05E7\u05E9\u05D9\u05DD"])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "id-numbers-form", [["redirectUrl", "/requestsandlicenses/licences"]], null, [[null, "onSubmitIdNumbers"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onSubmitIdNumbers" === en)) {
        var pd_0 = (_co.submitIdNumbers($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i9.View_IdNumberForComponent_0, i9.RenderType_IdNumberForComponent)), i1.ɵdid(6, 114688, null, 0, i10.IdNumberForComponent, [i4.FormService, i6.FieldService], { initialData: [0, "initialData"], redirectUrl: [1, "redirectUrl"], selectedField: [2, "selectedField"], generalError: [3, "generalError"] }, { onSubmitIdNumbers: "onSubmitIdNumbers" }), (_l()(), i1.ɵeld(7, 0, null, null, 4, "section", [["class", "employeeListSection"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WantedWorkersStepComponent_1)), i1.ɵdid(9, 278528, null, 0, i8.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WantedWorkersStepComponent_3)), i1.ɵdid(11, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(12, 0, null, null, 1, "stepper-buttons-component", [], null, [[null, "onStepGoBack"], [null, "onStepGoNext"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onStepGoBack" === en)) {
        var pd_0 = (_co.goToBackStep() !== false);
        ad = (pd_0 && ad);
    } if (("onStepGoNext" === en)) {
        var pd_1 = (_co.submit() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i11.View_StepperButtonsComponent_0, i11.RenderType_StepperButtonsComponent)), i1.ɵdid(13, 114688, null, 0, i12.StepperButtonsComponent, [], { hasNextStep: [0, "hasNextStep"], hasBackStep: [1, "hasBackStep"] }, { onStepGoNext: "onStepGoNext", onStepGoBack: "onStepGoBack" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.initialEmployeesStr; var currVal_1 = "/requestsandlicenses/licences"; var currVal_2 = _co.selectedField; var currVal_3 = _co.generalError; _ck(_v, 6, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_4 = _co.allEmployeesResponse; _ck(_v, 9, 0, currVal_4); var currVal_5 = (_co.fullList == false); _ck(_v, 11, 0, currVal_5); var currVal_6 = _co.hasNext(); var currVal_7 = _co.hasBack(); _ck(_v, 13, 0, currVal_6, currVal_7); }, null); }
export function View_WantedWorkersStepComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-wanted-workers-step", [], null, null, null, View_WantedWorkersStepComponent_0, RenderType_WantedWorkersStepComponent)), i1.ɵdid(1, 245760, null, 0, i13.WantedWorkersStepComponent, [i14.StepPagesService, i5.WizradUserDataService, i15.MatDialog, i4.FormService, i16.EmployeeService, i17.ScrollToService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var WantedWorkersStepComponentNgFactory = i1.ɵccf("app-wanted-workers-step", i13.WantedWorkersStepComponent, View_WantedWorkersStepComponent_Host_0, { currentStep: "currentStep" }, { onStepSubmit: "onStepSubmit", onStepBack: "onStepBack", onFormReset: "onFormReset" }, []);
export { WantedWorkersStepComponentNgFactory as WantedWorkersStepComponentNgFactory };
