import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { User } from 'src/app/models/interfaces';
import { UserDataService } from 'src/app/services/user-data.service';
import { ValidatePhone } from 'src/app/shared/validators/phone.validator';
import { compareValidator } from 'src/app/shared/validators/compare.validator';

@Component({
  selector: 'app-personal-info',
  templateUrl: './personal-info.component.html',
  styleUrls: ['./personal-info.component.scss']
})
export class PersonalInfoComponent implements OnInit {

  editInfoFormGroup: FormGroup;
  editMode: boolean = false;
  user: User;
  isErrorShow: boolean = false;

  constructor(private formBuilder: FormBuilder, private userDataService: UserDataService) { }

  ngOnInit() {

    this.userDataService.getUser()
      .subscribe(user => {
        this.user = user;
        this.createForm();
      });
  }

  createForm() {
    this.editInfoFormGroup = this.formBuilder.group({
      phone: ['', [Validators.minLength(10), Validators.maxLength(10), ValidatePhone,]],
      email: ['', [Validators.email]],
      confirmEmail: ['', [Validators.email, compareValidator('email')]],

    }, { validators: this.atLeastOneValidator });
    if (this.user) {
      this.editInfoFormGroup.patchValue(this.user)
    }
  }

  cancelForm() {
    this.editMode = false
    this.createForm();
  }

  onSubmit() {
    this.userDataService.personalInfo(this.editInfoFormGroup.value).subscribe(res => {
      if (res) {
        this.isErrorShow = false;
        this.editMode = false;
      }
    }, (err) => {
      this.isErrorShow = true;
    })
  }

  public atLeastOneValidator: ValidatorFn = (control: FormGroup): ValidationErrors | null => {

    let controls = control.controls;
    if (controls) {

      if (controls['email'].value) {
        if (!controls['confirmEmail'].value) {
          return { 'compare': false };
        }
      }
      if (controls['phone'].value) {
        return null;
      }
      return (controls['phone'].value || (controls['email'].value && controls['confirmEmail'].value)) ? null : { 'compare': true }

    }

  }
}
