import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { WantAdStatusEnumModel } from 'src/app/models/WantAdStatusEnumModel';
import { WantAdStatusEnum } from 'src/app/models/WantAdStatusEnum';
var WantAdsComponent = /** @class */ (function () {
    function WantAdsComponent(wantAdsService, userDataService) {
        this.wantAdsService = wantAdsService;
        this.userDataService = userDataService;
        this.wantAdsList = new Array();
        this.displayedColumns = [
            { header: 'תאריך עדכון', field: 'dateUpdated', width: '100px' },
            { header: 'כותרת', field: 'title' },
            { header: 'משרה חמה', field: 'hotJob', width: '87px' },
            { header: 'פירוט', field: 'content', width: '60px' },
            { header: 'ענף', field: 'branch' },
            { header: 'תת ענף', field: 'subBranch' },
            { header: 'מקצוע', field: 'profession' },
            { header: 'מתאריך', field: 'fromDate', width: '100px' },
            { header: 'עד תאריך', field: 'toDate', width: '100px' },
            { header: 'ססטוס', field: 'status', width: '70px' }
        ];
        this.statuses = [
            { id: 0, desc: "All", hebrewDesc: "הכל" },
            { id: WantAdStatusEnum.Active, desc: "Active", hebrewDesc: "פעיל" },
            { id: WantAdStatusEnum.Inactive, desc: "InActive", hebrewDesc: "לא פעיל" },
            { id: WantAdStatusEnum.Canceled, desc: "Canceled", hebrewDesc: "מבוטל" }
        ];
        this.selectedValuesFilter = new WantAdStatusEnumModel();
        this.bool = false;
    }
    WantAdsComponent.prototype.ngOnInit = function () {
        this.getUserData();
        this.getAllWantAds();
        this.initStatuses();
    };
    WantAdsComponent.prototype.getUserData = function () {
        var _this = this;
        this.userDataService.getUser().subscribe(function (data) {
            _this.user = data;
        });
    };
    WantAdsComponent.prototype.getAllWantAds = function () {
        // this.wantAdsList= [{
        var _this = this;
        //   dateUpdated: new Date(),
        //   fromDate: new Date(),
        //   toDate:  new Date(),
        //   branchId: 
        //   { "_id" :"60d44cf22f961e811fbff711",
        //   "code" : "4",
        //   "name" : "בנין"},
        //   subBranchId:{
        //     "_id" : "60f966cce2d8c77ea805426b",
        //     "allowedLicenseTypes" : [ 
        //         1, 
        //         2
        //     ],
        //     "branch" :  { "_id" :"60d44cf22f961e811fbff711",
        //     "code" : "4",
        //     "name" : "בנין"},
        //     "subCode" : "1",
        //     "subName" : "בנייה מגורים",
        // },
        //   professionId: {
        //     "_id" : "60f9218013980f377816cc6a",
        //     "professionId" : "1",
        //     "profession" : "טפסנות",
        //     "professionArb" : "طوبار",
        //     "subBranch" : {
        //       "_id" : "60f966cce2d8c77ea805426b",
        //       "allowedLicenseTypes" : [ 
        //           1, 
        //           2
        //       ],
        //       "branch" :  { "_id" :"60d44cf22f961e811fbff711",
        //       "code" : "4",
        //       "name" : "בנין",},
        //       "subCode" : "1",
        //       "subName" : "בנייה מגורים",
        //   },
        // },
        //   title: 'string',
        //   content: 'string',
        //   hotJob: true,
        //   status: 1,
        //   userId: '100000777'
        //  },
        //  {
        //   dateUpdated: new Date(),
        //   fromDate: new Date(),
        //   toDate:  new Date(),
        //   branchId: 
        //   { "_id" :"60d44cf22f961e811fbff711",
        //   "code" : "4",
        //   "name" : "בנין"},
        //   subBranchId:{
        //     "_id" : "60f966cce2d8c77ea805426b",
        //     "allowedLicenseTypes" : [ 
        //         1, 
        //         2
        //     ],
        //     "branch" :  { "_id" :"60d44cf22f961e811fbff711",
        //     "code" : "4",
        //     "name" : "בנין"},
        //     "subCode" : "1",
        //     "subName" : "בנייה מגורים",
        // },
        //   professionId: {
        //     "_id" : "60f9218013980f377816cc6a",
        //     "professionId" : "1",
        //     "profession" : "טפסנות",
        //     "professionArb" : "طوبار",
        //     "subBranch" : {
        //       "_id" : "60f966cce2d8c77ea805426b",
        //       "allowedLicenseTypes" : [ 
        //           1, 
        //           2
        //       ],
        //       "branch" :  { "_id" :"60d44cf22f961e811fbff711",
        //       "code" : "4",
        //       "name" : "בנין",},
        //       "subCode" : "1",
        //       "subName" : "בנייה מגורים",
        //   },
        // },
        //   title: 'string',
        //   content: 'string',
        //   hotJob: true,
        //   status: 2,
        //   userId:  '100000777'
        //  },
        //  {
        //   dateUpdated: new Date(),
        //   fromDate: new Date(),
        //   toDate:  new Date(),
        //   branchId: 
        //   { "_id" :"60d44cf22f961e811fbff711",
        //   "code" : "4",
        //   "name" : "בנין"},
        //   subBranchId:{
        //     "_id" : "60f966cce2d8c77ea805426b",
        //     "allowedLicenseTypes" : [ 
        //         1, 
        //         2
        //     ],
        //     "branch" :  { "_id" :"60d44cf22f961e811fbff711",
        //     "code" : "4",
        //     "name" : "בנין"},
        //     "subCode" : "1",
        //     "subName" : "בנייה מגורים",
        // },
        //   professionId: {
        //     "_id" : "60f9218013980f377816cc6a",
        //     "professionId" : "1",
        //     "profession" : "טפסנות",
        //     "professionArb" : "طوبار",
        //     "subBranch" : {
        //       "_id" : "60f966cce2d8c77ea805426b",
        //       "allowedLicenseTypes" : [ 
        //           1, 
        //           2
        //       ],
        //       "branch" :  { "_id" :"60d44cf22f961e811fbff711",
        //       "code" : "4",
        //       "name" : "בנין",},
        //       "subCode" : "1",
        //       "subName" : "בנייה מגורים",
        //   },
        // },
        //   title: 'string',
        //   content: 'string',
        //   hotJob: true,
        //   status: 3,
        //   userId: '100000777'
        //  }]
        if (!this.user)
            return;
        this.wantAdsService.getAllWantAds(this.user.user.identity).subscribe(function (data) {
            _this.wantAdsList = data.data;
            _this.updateAlertStatus();
        });
    };
    WantAdsComponent.prototype.initStatuses = function () {
        this.selectedValuesFilter = this.statuses[0];
    };
    WantAdsComponent.prototype.updateAlertStatus = function () {
        var today = new Date();
        this.wantAdsList.forEach(function (wantAd) {
            var messageToDate = new Date(wantAd.toDate);
            if (messageToDate < today && wantAd.status == WantAdStatusEnum.Active) {
                wantAd.status = WantAdStatusEnum.Inactive;
            }
        });
    };
    WantAdsComponent.prototype.addWantAd = function () {
        this.selectedWantAd = null;
        this.updateMode = false;
        this.displayDialog = true;
    };
    WantAdsComponent.prototype.editItem = function (wantAd) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.updateOK_Employer(wantAd)];
                    case 1:
                        _a.sent();
                        this.selectedWantAd = wantAd;
                        this.displayDialog = true;
                        this.updateMode = true;
                        return [2 /*return*/];
                }
            });
        });
    };
    WantAdsComponent.prototype.updateOK_Employer = function (wantAd) {
        for (var i = 0; i < wantAd.interestedEmployees.length; i++) {
            wantAd.interestedEmployees[i].employerRead = true;
        }
        this.wantAdsService.updateWantAd(wantAd).subscribe();
    };
    WantAdsComponent.prototype.updateWantAdEvent = function (dataToSave) {
        var _this = this;
        if (dataToSave.delete == false) {
            this.displayDialog = false;
            return;
        }
        this.displayDialog = false;
        dataToSave.wantAd.status = dataToSave.status;
        dataToSave.wantAd.dateUpdated = new Date();
        if (!!dataToSave.wantAd._id) {
            this.wantAdsService.updateWantAd(dataToSave.wantAd).subscribe(function (data) {
                _this.wantAdsList = data.data;
                _this.updateAlertStatus();
            });
            return;
        }
        dataToSave.wantAd.userId = this.user.user.identity;
        this.wantAdsService.createWantAd(dataToSave.wantAd).subscribe(function (data) {
            _this.wantAdsList = data.data;
            _this.updateAlertStatus();
        });
    };
    WantAdsComponent.prototype.deleteAlertMessageEvent = function (event) {
        var _this = this;
        if (event._id == null)
            return;
        if (!!event._id) {
            this.wantAdsService.deleteWantAd(event._id).subscribe(function (data) {
                _this.wantAdsList = data.data;
                _this.updateAlertStatus();
            });
            this.displayDialog = false;
        }
    };
    WantAdsComponent.prototype.showRowData = function (wantAd) {
        if (this.selectedValuesFilter.id === wantAd.status || this.selectedValuesFilter.id === 0) {
            return true;
        }
    };
    WantAdsComponent.prototype.filter = function () {
        // if(this.selectedValuesFilter.length === 0 ){
        //   this.wantAdsList = this.allWantAds;
        //   return;
        // } 
        // if(this.selectedValuesFilter.map(m=>m.id).includes(0)){
        //   this.wantAdsList = this.allWantAds;
        //   this.selectedValuesFilter = this.statuses;
        //   return;
        // }
        // this.wantAdsList = this.allWantAds.filter(m=> this.selectedValuesFilter.map(m=>m.id).includes(m.status))
        // this.wantAdsList = this.allWantAds.filter(m=> this.selectedValuesFilter.map(m=>m.id).includes(m.status))
    };
    return WantAdsComponent;
}());
export { WantAdsComponent };
