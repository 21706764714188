import { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { UserDataService } from 'src/app/services/user-data.service';
import { NotificationsAndUpdate } from 'src/app/models/interfaces';
import { MatDialog } from '@angular/material';
import { ReadMoreModalComponent } from '../read-more-modal/read-more-modal.component';
import { AlertMessages } from 'src/app/models/alertMessages';

@Component({
  selector: "app-updatesSlider",
  templateUrl: './updatesSlider.component.html',
  styleUrls: ['./updatesSlider.component.scss']

})
export class UpdatesSliderComponent implements OnInit {
  notificationsAndUpdates: NotificationsAndUpdate[] = [];

  listMessages:Array<AlertMessages>=new Array<AlertMessages>()

  VisibleDialog;
  display: boolean;
  data: string;
  slideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    mobileFirst: true,
    infinite: false,
    rtl: true,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3
        }
      }
    ]
  };

  mobilemessageBoxContentLength: number = 30;
  messageBoxContentLength: number = 30;

  constructor(private userDataService: UserDataService , private dialog: MatDialog) { }

  ngOnInit(): void {
    this.getAllMessages()
  }

  openReadMoreModal(alertMessages: AlertMessages) {
    this.VisibleDialog = true;
     this.data = alertMessages.content;
  }

  getAllMessages(){
    this.userDataService.getAllMessages().subscribe(data=>{
      this.listMessages=data.data
    })
  }
}
