import { Candidate } from "./candidate";
import { Profession } from "./profession";

export class SortingDay {
    constructor(
        public _id?:string,
        public professionId?: Profession,
        public examDate?:Date,
        public fromHour?:Date,
        public toHour?:Date,
        public allocation?:number,
        public candidates?:Candidate[]
    ) { }
}

export enum CandidateStatusEnum {
    NotExecuted = 0,
    Register = 1,
    Embedded = 2,
    NotSucceeded = 3,
    Succeeded = 4,
    Deleted = 5
}