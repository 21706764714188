import * as tslib_1 from "tslib";
import { OnInit, EventEmitter } from '@angular/core';
import isIsraeliIdValid from 'israeli-id-validator';
var IdNumberForComponent = /** @class */ (function () {
    function IdNumberForComponent(formService, fieldService) {
        this.formService = formService;
        this.fieldService = fieldService;
        this.onSubmitIdNumbers = new EventEmitter();
        this.errorMessage = "";
        this.numberRegex = new RegExp("^[0-9\\s]*$");
        this.keyMap = [];
        this.idListStr = "";
        this.invalidInputError = "";
        this.invalidIdNumbers = [];
        this._selectedField = null;
        this.config = {
            wheelSpeed: 0.2
        };
        /* #endregion */
        //TODO: MOVE TO UTILS
        this.chainValidate = function () {
            var e_1, _a;
            var validators = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                validators[_i] = arguments[_i];
            }
            var success = true;
            var validator = null;
            try {
                for (var validators_1 = tslib_1.__values(validators), validators_1_1 = validators_1.next(); !validators_1_1.done; validators_1_1 = validators_1.next()) {
                    validator = validators_1_1.value;
                    if (typeof validator !== 'function') {
                        continue;
                    }
                    success = validator();
                    if (!success) {
                        return false;
                    }
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (validators_1_1 && !validators_1_1.done && (_a = validators_1.return)) _a.call(validators_1);
                }
                finally { if (e_1) throw e_1.error; }
            }
            return true;
        };
    }
    Object.defineProperty(IdNumberForComponent.prototype, "selectedField", {
        set: function (field) {
            this._selectedField = field;
            // this.resetAll();
        },
        enumerable: true,
        configurable: true
    });
    IdNumberForComponent.prototype.ngOnInit = function () {
        this.idListStr = this.initialData || "";
    };
    IdNumberForComponent.prototype.isSubmitButtonDisabled = function () {
        return !this.idListStr.trim();
    };
    IdNumberForComponent.prototype.onInputChange = function (e) {
        this.resetAll();
    };
    /* #region  Submit And Validations */
    IdNumberForComponent.prototype.submitIdNumbers = function () {
        this.resetAll();
        if (!this.chainValidate(this.validateIdNumbersInput.bind(this), this.validateIdNumbers.bind(this))) {
            return;
        }
        this.onSubmitIdNumbers.emit(this.validIdNumbers);
    };
    IdNumberForComponent.prototype.validateIdNumbersInput = function () {
        var res = this.numberRegex.test(this.idListStr);
        if (!res) {
            this.errorMessage = "קלט לא תקין";
        }
        return res;
    };
    IdNumberForComponent.prototype.validateIdNumbers = function () {
        var idListStrTrimmed = this.idListStr.replace(/ /g, '');
        var idNumberList = idListStrTrimmed.split(/\r?\n/g).filter(function (i) { return i !== ""; });
        var invalidIds = idNumberList.filter(function (i) { return !isIsraeliIdValid(i) || i.length !== 9; });
        if (invalidIds.length) {
            this.errorMessage = "ישנם מספרי תעודת זהות אשר לא הוזנו בצורה תקינה.";
            this.invalidIdNumbers = invalidIds;
        }
        else {
            this.validIdNumbers = tslib_1.__spread(new Set(idNumberList));
        }
        return !invalidIds.length;
    };
    /* #endregion */
    IdNumberForComponent.prototype.resetAll = function () {
        this.invalidIdNumbers = [];
        this.errorMessage = "";
        this.validIdNumbers = [];
        this.invalidIdNumbers = [];
    };
    /* #region Key Event Handlers  */
    IdNumberForComponent.prototype.onKeyDown = function (e) {
        var _this = this;
        var keyCode = e.keyCode;
        if (!this.keyMap.includes(keyCode)) {
            this.keyMap.push(keyCode);
        }
        if (e.keyCode > 31 && !this.isSpaceOrEnterKey(keyCode) && !this.isNumberKey(keyCode)
            && !this.isArrowsKey(keyCode) && !this.isDeltetKey(keyCode)
            && !this.isCopyOrPasteKey() && !this.isSelectAllKeys()) {
            event.preventDefault();
            this.invalidInputError = "הוכנס תו לא תקין";
            setTimeout(function () {
                _this.invalidInputError = "";
            }, 650);
        }
    };
    IdNumberForComponent.prototype.onKeyUp = function () {
        // Reset the keymap for the next operation
        this.keyMap = [];
    };
    IdNumberForComponent.prototype.onPaste = function (e) {
        var clipboardData = e.clipboardData || window.clipboardData;
        var pastedData = clipboardData.getData('Text');
        if (!pastedData || !this.numberRegex.test(pastedData)) {
            e.preventDefault();
        }
    };
    IdNumberForComponent.prototype.isNumberKey = function (keyCode) {
        return (keyCode >= 48 && keyCode <= 57) || (keyCode >= 96 && keyCode <= 105);
    };
    IdNumberForComponent.prototype.isCopyOrPasteKey = function () {
        // ctrlKey-17, cmdKey-91, vKey-86, cKey-67
        return (this.keyMap.includes(17) || this.keyMap.includes(91))
            && (this.keyMap.includes(86) || this.keyMap.includes(67));
    };
    IdNumberForComponent.prototype.isSelectAllKeys = function () {
        //ctrlKey-17, a-65
        return this.keyMap.includes(17) && this.keyMap.includes(65);
    };
    IdNumberForComponent.prototype.isArrowsKey = function (keyCode) {
        return keyCode >= 35 && keyCode <= 40;
    };
    IdNumberForComponent.prototype.isDeltetKey = function (keyCode) {
        //Delete-46, Backspace-8
        return keyCode === 46 || keyCode === 8;
    };
    IdNumberForComponent.prototype.isSpaceOrEnterKey = function (keyCode) {
        //Enter-13, Space-32
        return keyCode === 32 || keyCode === 13;
    };
    return IdNumberForComponent;
}());
export { IdNumberForComponent };
