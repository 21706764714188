import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../shared/service/ApplicationHttpClient";
var QueriesService = /** @class */ (function () {
    function QueriesService(http) {
        this.http = http;
        this.endPoint = "api/queries";
    }
    QueriesService.prototype.getQueries = function () {
        return this.http.Get(this.endPoint).pipe(map(function (res) { return res.data; })).toPromise();
    };
    QueriesService.prototype.sendQuery = function (queryId, inputValues) {
        return this.http.Post(this.endPoint, { queryId: queryId, inputValues: inputValues });
    };
    QueriesService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function QueriesService_Factory() { return new QueriesService(i0.ɵɵinject(i1.ApplicationHttpClient)); }, token: QueriesService, providedIn: "root" });
    return QueriesService;
}());
export { QueriesService };
