import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { MatSidenav } from '@angular/material';
import { PrintService } from 'src/app/shared/service/print.service';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { ActivatedRoute } from '@angular/router';
import { EmployeeService } from 'src/app/services/employee-service';
import { LicenseService } from 'src/app/services/license-service';
import { UserDataService } from 'src/app/services/user-data.service';
import { User } from 'src/app/models/interfaces';




@Component({
  selector: 'app-requests-licenses-tabs',
  templateUrl: './requests-licenses-tabs.component.html',
  styleUrls: ['./requests-licenses-tabs.component.scss']
})
export class RequestsLicensesTabsComponent implements OnInit, OnDestroy {
  @ViewChild('sidenav', { static: false }) sidenav: MatSidenav;

  public config: PerfectScrollbarConfigInterface = {
    wheelSpeed: 0.2
  };

  reason = '';
  isMobile = false;
  navLinks: any[];
  user: User;
  close(reason: string) {
    this.reason = reason;
    this.sidenav.close();
  }

  constructor(private route: ActivatedRoute, private printService: PrintService, private employeeService: EmployeeService,
    private licenseService: LicenseService, private activatedRoute: ActivatedRoute, private userDataService: UserDataService) {


  }

  ngOnInit() {
    this.isMobile = window.innerWidth < 1024;
    // this.printService.setPrintConfig({object: {} , type: 'json' , properties: []});
    this.printService.setPrintConfig({ object: null, type: 'html', targetStyles: ['*'] });
    this.userDataService.getUser().subscribe(user => {
      this.user = user
      this.buildTheNavBar()
    })
  }

  buildTheNavBar() {
    //Get All The 'requestsandlicenses' Children In Order To Build The Nav Bar Dynamically
    const children = this.activatedRoute.routeConfig.children;
    if (children && children.length) {
      const sortedChildren = children.filter(c => c.component).sort(rc => rc.data.index);

      this.navLinks = sortedChildren.map(mc => {
        return {
          label: mc.data.label,
          path: `./${mc.path}`,
          index: mc.data.index,
          isDisabled: this.user.hasBranchOnlyInIsrael && mc.data.printDataName === 'requestStatus'
        }
      })
    }
  }

  ngOnDestroy() {
    this.employeeService.clearData();
    this.licenseService.resetRequestStatusData();
  }
}




