import { Injectable } from '@angular/core';
import { Observable } from 'rxjs-compat/Observable';
import { Parameters } from '../models/parameter';
import { ApplicationHttpClient } from '../shared/service/ApplicationHttpClient';

@Injectable({
  providedIn: 'root'
})
export class ParameterService {

  endPoint = "api/parameter";

  constructor(private http: ApplicationHttpClient) { }

  getParameters(): Observable<any> {
    return this.http.Get<any>(this.endPoint)
  }

  updateParameter(_id: string, params: Parameters): Observable<any> {
    return this.http.Put(this.endPoint + "/" + _id, params);
  }
}
