<div class="container-fluid container-box ">
<section class="tabsSection">
  
    <div class="headLineWrapper headLineWrapper__requestLicense">
      <!-- <h2 class="subTitle subTitle--blueTextColor ">
        צפייה בבקשות/רשיונות
      </h2> -->

<!--      <mat-sidenav-container-->
<!--        class="sidenavContainer"-->
<!--        (backdropClick)="close('backdrop')"-->
<!--      >-->
<!--        <mat-sidenav #sidenav (keydown.escape)="close('escape')" disableClose>-->
<!--          <div class="navHeadlineWrapper">-->
<!--            <h3 class="textContent textContent&#45;&#45;semiBold colorTextGray">-->
<!--              סינון ומיון עובדים-->
<!--            </h3>-->
<!--            <button-->
<!--              mat-button-->
<!--              (click)="close('toggle button')"-->
<!--              class="closeFilterBtn"-->
<!--            ></button>-->
<!--          </div>-->

<!--          &lt;!&ndash; Category Select &ndash;&gt;-->
<!--          <div class="categorySelect">-->
<!--            <h4 class="textContent textContent&#45;&#45;semiBold colorTextGray">-->
<!--              לפי:-->
<!--            </h4>-->
<!--            <mat-form-field>-->
<!--              <mat-label>בחירת קטגוריה</mat-label>-->
<!--              <mat-select-->
<!--                disableOptionCentering-->
<!--                placeholder="בחירת קטגוריה"-->
<!--                panelClass="selectCategoryPanelClass"-->
<!--              >-->
<!--                &lt;!&ndash; // TODO: ngFor of all th of table &ndash;&gt;-->
<!--                &lt;!&ndash; <mat-option *ngFor="let food of foods" [value]="">  </mat-option> &ndash;&gt;-->
<!--                <mat-option [value]="1">שמות עובדים</mat-option>-->
<!--                <mat-option [value]="2">מספרי רישיון</mat-option>-->
<!--                <mat-option [value]="3">ענף</mat-option>-->
<!--                <mat-option [value]="4">תוקף רישיון</mat-option>-->
<!--                <mat-option [value]="5">מיקום עבודה</mat-option>-->
<!--                <mat-option [value]="6">מספרי תעודת זהות</mat-option>-->
<!--                <mat-option [value]="7">לינה</mat-option>-->
<!--              </mat-select>-->
<!--            </mat-form-field>-->
<!--          </div>-->
<!--          &lt;!&ndash; /Category Select &ndash;&gt;-->
<!--          <div class="dropDownFilterBox">-->
<!--            &lt;!&ndash; filter input &ndash;&gt;-->
<!--            <mat-form-field class="filterInputField">-->
<!--              <input matInput />-->
<!--            </mat-form-field>-->
<!--            &lt;!&ndash; /filter input &ndash;&gt;-->
<!--            <perfect-scrollbar class="perfectScrollBar filterScrollBar"  [config]="config">-->
<!--              &lt;!&ndash; checkbox list &ndash;&gt;-->
<!--              <ul class="filterList">-->
<!--                <li class="filterList__item selectAll">-->
<!--                  <mat-checkbox-->
<!--                    (click)="$event.stopPropagation()"-->
<!--                    class="checkboxSelect "-->
<!--                  >-->
<!--                    הכל-->
<!--                  </mat-checkbox>-->
<!--                </li>-->
<!--                <li class="filterList__item">-->
<!--                  <mat-checkbox-->
<!--                    (click)="$event.stopPropagation()"-->
<!--                    class="checkboxSelect "-->
<!--                  >-->
<!--                    201512598-->
<!--                  </mat-checkbox>-->
<!--                </li>-->
<!--                <li class="filterList__item">-->
<!--                  <mat-checkbox-->
<!--                    (click)="$event.stopPropagation()"-->
<!--                    class="checkboxSelect "-->
<!--                  >-->
<!--                    201512598-->
<!--                  </mat-checkbox>-->
<!--                </li>-->
<!--                <li class="filterList__item">-->
<!--                  <mat-checkbox-->
<!--                    (click)="$event.stopPropagation()"-->
<!--                    class="checkboxSelect "-->
<!--                  >-->
<!--                    201512598-->
<!--                  </mat-checkbox>-->
<!--                </li>-->
<!--                <li class="filterList__item">-->
<!--                  <mat-checkbox-->
<!--                    (click)="$event.stopPropagation()"-->
<!--                    class="checkboxSelect "-->
<!--                  >-->
<!--                    037877665-->
<!--                  </mat-checkbox>-->
<!--                </li>-->
<!--                <li class="filterList__item">-->
<!--                  <mat-checkbox-->
<!--                    (click)="$event.stopPropagation()"-->
<!--                    class="checkboxSelect "-->
<!--                  >-->
<!--                    300988723-->
<!--                  </mat-checkbox>-->
<!--                </li>-->
<!--              </ul>-->
<!--              &lt;!&ndash; /checkbox list &ndash;&gt;-->
<!--            </perfect-scrollbar>-->

<!--            <div class="filterActions">-->
<!--              <button-->
<!--                mat-button-->
<!--                (click)="close('toggle button')"-->
<!--                class="btn btn&#45;&#45;semiBold btn&#45;&#45;regularBtn colorTextBlue filterConfirmBtn"-->
<!--              >-->
<!--                <img src="assets/imgs/filterMobBtn.svg" alt="" />-->
<!--                אישור-->
<!--              </button>-->
<!--              <button-->
<!--                mat-button-->
<!--                (click)="close('toggle button')"-->
<!--                class="btn btn&#45;&#45;semiBold colorTextGray btn&#45;&#45;regularBtn"-->
<!--              >-->
<!--                ביטול-->
<!--              </button>-->
<!--            </div>-->
<!--          </div>-->
<!--        </mat-sidenav>-->

<!--        <mat-sidenav-content>-->
<!--          <button class="filterMobBtn" mat-button (click)="sidenav.open()">-->
<!--            <img src="assets/imgs/filterMobBtn.svg" alt="" />-->
<!--          </button>-->
<!--        </mat-sidenav-content>-->
<!--      </mat-sidenav-container>-->
    </div>
    <div class="tabWrapper">
      <div class="tabNavigation d-md-flex">
        <nav mat-tab-nav-bar>
          <a mat-tab-link
             *ngFor="let link of navLinks"
             [routerLink]="link.path"
             routerLinkActive #rla="routerLinkActive"
             [active]="rla.isActive"
             [disabled]="link.isDisabled">
        
            {{link.label}}
          </a>
        </nav>
        <div class="btnsWrapper btnsWrapper--absolute">
          <app-print></app-print>
        </div>
      </div>
      <router-outlet></router-outlet>
    </div>
 
</section> 
</div>
