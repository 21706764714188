import {
  Component,
  OnInit,
  ViewChild,
  OnDestroy
} from "@angular/core";
import { MatStepper } from "@angular/material";
import { StepPagesService } from "../service/step-pages.service";
import {FormGroup,} from "@angular/forms";
import { FormService } from "../service/form.service";
import { WizradUserDataService } from "../service/wizrad-user-data.service";
import { EmployeeService } from "src/app/services/employee-service";
import { AddingDocumentsStepComponent } from '../steps/adding-documents-step/adding-documents-step.component';
import {
  filter,
  takeUntil
} from 'rxjs/operators';
import {FieldService} from "../service/field.service";
import {BuildingPermitService} from "../service/building-permit.service";
import {BaseComponent} from "../../shared/components/base-component/base-component";
import {
  FieldsRequest,
  StepPageObject
} from "../../models/interfaces";
import { TransactionAreaStepComponent } from '../steps/transaction-area-step/transaction-area-step.component';
import { RequestDetailsStepComponent } from '../steps/request-details-step/request-details-step.component';
import { WantedWorkersStepComponent } from '../steps/wanted-workers-step/wanted-workers-step.component';
import { ApplicationSummaryStepComponent } from '../steps/application-summary-step/application-summary-step.component';

@Component({
  selector: "app-wizard-stepper",
  templateUrl: "./wizard-stepper.component.html",
  styleUrls: ["./wizard-stepper.component.scss"]
})
export class WizardStepperComponent extends BaseComponent implements OnInit, OnDestroy{
  @ViewChild("stepper", { static: false }) private stepper: MatStepper;
  isFirstRequest: boolean = true;
  initWizrad: boolean = false;
  userData: any;
  title: string;
  disposeArr= [];
  public steps: StepPageObject[];

  private initalSteps: Array<StepPageObject> = [
    { component: TransactionAreaStepComponent, label: 'איזור העסקה', icon:"icon-Employment-area"},
    { component: RequestDetailsStepComponent, label: 'פרטי בקשה', icon:"icon-request-details"},
    { component: WantedWorkersStepComponent, label: 'עובדים מבוקשים', icon:"icon-Wanted-employees"},
    { component: ApplicationSummaryStepComponent, label: 'סיכום בקשה', icon:"icon-Summary-of-request"},
  ];

  constructor(
    private stepsPagesService: StepPagesService,
    private formService: FormService,
    private wizradUserDataService: WizradUserDataService,
    private employeeService: EmployeeService,
    private fieldService: FieldService,
    private buildingPermitService:BuildingPermitService
  ) {
    super();
    this.stepsPagesService.buildSteps(this.initalSteps);
    this.wizradUserDataService.init();
    this.stepsPagesService.getSteps().subscribe(stepPages =>{
        this.steps = stepPages
      });
    this.stepsPagesService.getTitle().pipe(takeUntil(this.componentDestroyed)).subscribe(title => (this.title = title));
 this.createDisposables();
  }

  createDisposables(): void {
    this.disposeArr.push(
      this.formService.deleteForm.bind(this.formService),
      this.wizradUserDataService.dispose.bind(this.wizradUserDataService),
      this.buildingPermitService.dispose.bind(this.buildingPermitService),
      this.fieldService.dispose.bind(this.fieldService),
      this.employeeService.resetWantedWorkersEmployees.bind(this.employeeService),
      this.stepsPagesService.resetSteps.bind(this.stepsPagesService)
    )
  }
  ngOnInit() {
    this.formService.createForm();
    this.buildingPermitService.init();
    this.fieldService.init();
    this.checkForDocumetsStepByField();

    this.wizradUserDataService.isInit().pipe(takeUntil(this.componentDestroyed)).subscribe(init => {
      this.initWizrad = init;
    })
  }

  checkForDocumetsStepByField() {
    this.formService.getFormGroupRefByName("requestDetails").get('fields')
      .valueChanges.pipe(takeUntil(this.componentDestroyed)).pipe(filter(field => !!field)).subscribe(fields => {
      const fieldsRequestData = fields as FieldsRequest;
      const alreadyExist = this.stepsPagesService.stepExisitsByName({ component: AddingDocumentsStepComponent });


      if (!alreadyExist && fieldsRequestData.documents.length) {
        this.stepsPagesService.setStepAtIndex(
          { component: AddingDocumentsStepComponent, label: 'הוספת מסמכים',icon:"addDocumentsStep" },
          3
        );
      }
      else if(alreadyExist && !fieldsRequestData.documents.length ) {
        this.stepsPagesService.removeStepByComponentName({ component: AddingDocumentsStepComponent });
      }

    });
  }

  ngOnDestroy(): void {
    this.initDestroy();

    this.disposeArr.forEach(dispose => {
      if (dispose && typeof dispose === "function") {
        dispose();
      }
    })
  }

  // public ngAfterViewInit(): void {
  //   this.totalStepsCount = this.stepper._steps.length;
  // }


  public goBack(stepper: MatStepper): void {
    this.stepper.previous();
    // stepper.previous();
  }
  public goForward(stepper: MatStepper): void {
    this.stepper.next();
    // stepper.selectedIndex = 0;
  }

  resetForm(){
    this.formService.resetForm();
    this.stepsPagesService.resetSteps();
    this.stepper.reset();
    this.stepsPagesService.buildSteps(this.initalSteps);
  }
  saveDraft() {
    // console.log(this.stepper.selectedIndex);
    // console.log(this.formService.getForm());
  }

  stepControl(index): FormGroup {
    return this.formService.getFormGroupRefByIndex(index);
  }

  startRequest() {
    this.isFirstRequest = false;
  }
}
