import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Subject } from 'rxjs';
var SystemConstitutionComponent = /** @class */ (function () {
    function SystemConstitutionComponent(systemConstitutionService) {
        this.systemConstitutionService = systemConstitutionService;
        this.unsavedChanges = false;
        this.displayDialog = false;
        this.canDeactivate = new Subject();
        this.filters = {
            area: { value: null, options: [] },
            subArea: { value: null, options: [] },
            branch: { value: null, options: [] },
            subBranch: { value: null, options: [] },
            employerType: { value: null, options: [] }
        };
        this.sections = {
            area: {
                name: "area",
                pageHeader: 'רשימת איזורים ראשיים',
                showFilters: {},
                columns: [
                    { header: 'קוד', fieldName: 'areaId' },
                    { header: 'תיאור', fieldName: 'areaName' },
                    { header: 'פעיל', fieldName: 'active', fromSC: true, selectButton: true }
                ]
            },
            subArea: {
                name: "subArea",
                pageHeader: 'רשימת אזורי משנה',
                showFilters: {
                    area: true
                },
                columns: [
                    { header: 'קוד', fieldName: 'subAreaId' },
                    { header: 'תיאור', fieldName: 'subAreaName' },
                    { header: 'פעיל', fieldName: 'active', fromSC: true, selectButton: true }
                ]
            },
            branch: {
                name: "branch",
                pageHeader: 'ענפים',
                showFilters: {
                    area: true,
                    subArea: true
                },
                columns: [
                    { header: 'קוד', fieldName: 'code' },
                    { header: 'תיאור', fieldName: 'name' },
                    { header: 'נדרש מיון', fieldName: "NeedClassification", fromSC: true, selectButton: true },
                    { header: 'פעיל', fieldName: 'active', fromSC: true, selectButton: true }
                ]
            },
            subBranch: {
                name: "subBranch",
                pageHeader: 'תתי ענפים',
                showFilters: {
                    area: true,
                    subArea: true,
                    branch: true
                },
                columns: [
                    { header: 'קוד', fieldName: 'subCode' },
                    { header: 'תיאור', fieldName: 'subName' },
                    { header: 'ברירת מחדל', fieldName: "DefaultSubBranch", fromSC: true, selectButton: true },
                    { header: 'פעיל', fieldName: 'active', fromSC: true, selectButton: true }
                ]
            },
            employerType: {
                name: "employerType",
                pageHeader: 'סוגי מעסיקים',
                showFilters: {
                    area: true,
                    subArea: true,
                    branch: true,
                    subBranch: true
                },
                columns: [
                    { header: 'קוד', fieldName: 'employerTypeId' },
                    { header: 'תיאור', fieldName: 'employerTypeDesc' },
                    { header: 'הגבלת כמות', fieldName: "employeesAmount", fromSC: true, editable: true },
                    { header: 'פעיל', fieldName: 'active', fromSC: true, selectButton: true }
                ]
            },
            documentType: {
                name: "documentType",
                pageHeader: 'מסמכים',
                showFilters: {
                    area: true,
                    subArea: true,
                    branch: true,
                    subBranch: true,
                    employerType: true
                },
                columns: [
                    { header: 'קוד', fieldName: 'documentTypeId' },
                    { header: 'תיאור', fieldName: 'documentTypeDesc' },
                    { header: 'פעיל', fieldName: 'active', fromSC: true, selectButton: true }
                ]
            },
            hoursRange: {
                name: "hoursRange",
                pageHeader: 'שעות עבודה',
                showFilters: {
                    area: true,
                    subArea: true,
                    branch: true,
                    subBranch: true
                },
                columns: [
                    { header: 'קוד', fieldName: 'hourRangeId' },
                    { header: 'תיאור', fieldName: 'hourRange' },
                    { header: 'פעיל', fieldName: 'active', fromSC: true, selectButton: true }
                ]
            },
        };
    }
    SystemConstitutionComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, _b;
            return tslib_1.__generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.systemConstitutionService.getSystemConstitution().toPromise()];
                    case 1:
                        _a.systemConstitution = _c.sent();
                        _b = this;
                        return [4 /*yield*/, this.systemConstitutionService.getMetadata().toPromise()];
                    case 2:
                        _b.metadata = _c.sent();
                        this.selectedSection(this.sections.area.name)();
                        return [2 /*return*/];
                }
            });
        });
    };
    SystemConstitutionComponent.prototype.selectedSection = function (sectionName) {
        var _this = this;
        return function () {
            _this.currentSection = _this.sections[sectionName];
            if (sectionName != _this.sections.area.name) {
                return _this.setOptionsToArea();
            }
            _this.currentSectionMD = _this.metadata.areas;
            _this.currentSectionSC = _this.systemConstitution.areas = _this.systemConstitution.areas || [];
        };
    };
    SystemConstitutionComponent.prototype.setOptionsToArea = function () {
        var currentFilter = this.filters.area;
        if (!currentFilter.value) {
            currentFilter.options = this.systemConstitution.areas = this.systemConstitution.areas || [];
        }
        if (currentFilter.options.length) {
            this.selectedFilterArea({ value: currentFilter.value || currentFilter.options[0] });
        }
    };
    SystemConstitutionComponent.prototype.setOptionsToSubArea = function () {
        var currentFilter = this.filters.subArea;
        if (!currentFilter.value) {
            currentFilter.options = this.filters.area.value.subAreas = this.filters.area.value.subAreas || [];
        }
        if (currentFilter.options.length) {
            this.selectedFilterSubArea({ value: currentFilter.value || currentFilter.options[0] });
        }
    };
    SystemConstitutionComponent.prototype.setOptionsToBranch = function () {
        var currentFilter = this.filters.branch;
        if (!currentFilter.value) {
            currentFilter.options = this.filters.subArea.value.branches = this.filters.subArea.value.branches || [];
        }
        if (currentFilter.options.length) {
            this.selectedFilterBranch({ value: currentFilter.value || currentFilter.options[0] });
        }
    };
    SystemConstitutionComponent.prototype.setOptionsToSubBranch = function () {
        var currentFilter = this.filters.subBranch;
        if (!currentFilter.value) {
            currentFilter.options = this.filters.branch.value.subBranches = this.filters.branch.value.subBranches || [];
        }
        if (currentFilter.options.length) {
            this.selectedFilterSubBranch({ value: currentFilter.value || currentFilter.options[0] });
        }
    };
    SystemConstitutionComponent.prototype.setOptionsToEmployerType = function () {
        var currentFilter = this.filters.employerType;
        if (!currentFilter.value) {
            currentFilter.options = this.filters.subBranch.value.employerTypes = this.filters.subBranch.value.employerTypes || [];
        }
        if (currentFilter.options.length) {
            this.selectedFilterEmployerType({ value: currentFilter.value || currentFilter.options[0] });
        }
    };
    SystemConstitutionComponent.prototype.selectedFilterArea = function (event) {
        var _this = this;
        if (this.filters.area.value != event.value) {
            this.filters.area.value = event.value;
            [
                this.filters.subArea,
                this.filters.branch,
                this.filters.subBranch,
                this.filters.employerType
            ].forEach(this.resetFilter);
        }
        if (this.currentSection.name == this.sections.subArea.name) {
            this.currentSectionMD = this.metadata.subAreas.filter(function (sa) { return sa.area._id == _this.filters.area.value.area._id; });
            this.currentSectionSC = this.filters.area.value.subAreas = this.filters.area.value.subAreas || [];
            return;
        }
        this.setOptionsToSubArea();
    };
    SystemConstitutionComponent.prototype.selectedFilterSubArea = function (event) {
        if (this.filters.subArea.value != event.value) {
            this.filters.subArea.value = event.value;
            [
                this.filters.branch,
                this.filters.subBranch,
                this.filters.employerType
            ].forEach(this.resetFilter);
        }
        if (this.currentSection.name == this.sections.branch.name) {
            this.currentSectionMD = this.metadata.branches;
            this.currentSectionSC = this.filters.subArea.value.branches = this.filters.subArea.value.branches || [];
            return;
        }
        this.setOptionsToBranch();
    };
    SystemConstitutionComponent.prototype.selectedFilterBranch = function (event) {
        var _this = this;
        if (this.filters.branch.value != event.value) {
            this.filters.branch.value = event.value;
            [
                this.filters.subBranch,
                this.filters.employerType
            ].forEach(this.resetFilter);
        }
        if (this.currentSection.name == this.sections.subBranch.name) {
            this.currentSectionMD = this.metadata.subBranches.filter(function (sb) { return sb.branch._id == _this.filters.branch.value.branch._id; });
            this.currentSectionSC = this.filters.branch.value.subBranches = this.filters.branch.value.subBranches || [];
            return;
        }
        this.setOptionsToSubBranch();
    };
    SystemConstitutionComponent.prototype.selectedFilterSubBranch = function (event) {
        if (this.filters.subBranch.value != event.value) {
            this.filters.subBranch.value = event.value;
            this.resetFilter(this.filters.employerType);
        }
        if (this.currentSection.name == this.sections.employerType.name) {
            this.currentSectionMD = this.metadata.employerTypes;
            this.currentSectionSC = this.filters.subBranch.value.employerTypes = this.filters.subBranch.value.employerTypes || [];
            return;
        }
        if (this.currentSection.name == this.sections.hoursRange.name) {
            this.currentSectionMD = this.metadata.hoursRanges;
            this.currentSectionSC = this.filters.subBranch.value.hoursRanges = this.filters.subBranch.value.hoursRanges || [];
            return;
        }
        this.setOptionsToEmployerType();
    };
    SystemConstitutionComponent.prototype.selectedFilterEmployerType = function (event) {
        this.filters.employerType.value = event.value;
        this.currentSectionMD = this.metadata.documentTypes;
        this.currentSectionSC = this.filters.employerType.value.documentTypes = this.filters.employerType.value.documentTypes || [];
    };
    SystemConstitutionComponent.prototype.resetFilter = function (filter) {
        filter.options = [];
        filter.value = null;
    };
    Object.defineProperty(SystemConstitutionComponent.prototype, "showTable", {
        get: function () {
            if (!this.currentSectionMD.length) {
                return false;
            }
            switch (this.currentSection.name) {
                case this.sections.area.name:
                    return true;
                case this.sections.subArea.name:
                    return !!this.filters.area.value;
                case this.sections.branch.name:
                    return !!this.filters.subArea.value;
                case this.sections.subBranch.name:
                    return !!this.filters.branch.value;
                case this.sections.employerType.name:
                    return !!this.filters.subBranch.value;
                case this.sections.documentType.name:
                    return !!this.filters.employerType.value;
                case this.sections.hoursRange.name:
                    return !!this.filters.subBranch.value;
            }
        },
        enumerable: true,
        configurable: true
    });
    SystemConstitutionComponent.prototype.getClassBySection = function (sectionName) {
        return this.currentSection.name == sectionName ? 'active' : '';
    };
    SystemConstitutionComponent.prototype.getDataFromCurrentSectionSC = function (docId, fieldName) {
        var _this = this;
        var doc = this.currentSectionSC.find(function (d) { return d[_this.currentSection.name]._id == docId; });
        if (fieldName == "active") {
            return !!doc;
        }
        return doc ? doc[fieldName] : null;
    };
    SystemConstitutionComponent.prototype.setDataToCurrentSectionSC = function (docId, fieldName, value) {
        this.unsavedChanges = true;
        if (this.currentSection.name == this.sections.subBranch.name) {
            return this.setDataToSubBranch(docId, fieldName, value);
        }
        if (fieldName != "active") {
            return this.writeInCurrentSectionSC(docId, fieldName, value);
        }
        if (value) {
            return this.addDocToCurrentSectionSC(docId);
        }
        this.removeDocFromCurrentSectionSC(docId);
    };
    SystemConstitutionComponent.prototype.addDocToCurrentSectionSC = function (docId) {
        var _a;
        var _this = this;
        if (!this.currentSectionSC.find(function (d) { return d[_this.currentSection.name]._id == docId; })) {
            this.currentSectionSC.push((_a = {}, _a[this.currentSection.name] = this.currentSectionMD.find(function (d) { return d._id == docId; }), _a));
        }
    };
    SystemConstitutionComponent.prototype.removeDocFromCurrentSectionSC = function (docId) {
        var _this = this;
        var indexDoc = this.currentSectionSC.findIndex(function (d) { return d[_this.currentSection.name]._id == docId; });
        if (indexDoc == -1) {
            return;
        }
        this.currentSectionSC.splice(indexDoc, 1);
        if (!this.filters[this.currentSection.name]
            || !this.filters[this.currentSection.name].value
            || this.filters[this.currentSection.name].value[this.currentSection.name]._id != docId) {
            return;
        }
        switch (this.currentSection.name) {
            case this.sections.area.name:
                this.resetFilter(this.filters.area);
            case this.sections.subArea.name:
                this.resetFilter(this.filters.subArea);
            case this.sections.branch.name:
                this.resetFilter(this.filters.branch);
            case this.sections.subBranch.name:
                this.resetFilter(this.filters.subBranch);
            case this.sections.employerType.name:
                this.resetFilter(this.filters.employerType);
        }
    };
    SystemConstitutionComponent.prototype.writeInCurrentSectionSC = function (docId, fieldName, value) {
        var _this = this;
        this.currentSectionSC.find(function (d) { return d[_this.currentSection.name]._id == docId; })[fieldName] = value;
    };
    SystemConstitutionComponent.prototype.setDataToSubBranch = function (docId, fieldName, value) {
        switch (fieldName) {
            case "active":
                if (!value) {
                    return this.removeDocFromCurrentSectionSC(docId);
                }
                this.addDocToCurrentSectionSC(docId);
                if (this.currentSectionSC.length == 1) {
                    this.writeInCurrentSectionSC(docId, "DefaultSubBranch", true);
                }
                break;
            case "DefaultSubBranch":
                this.currentSectionSC.forEach(function (sb) { return sb.DefaultSubBranch = false; });
                this.writeInCurrentSectionSC(docId, fieldName, value);
                break;
        }
    };
    SystemConstitutionComponent.prototype.isCellDisabled = function (docId, fieldName) {
        if (this.currentSection.name == this.sections.subBranch.name) {
            return this.isCellDisabledSubBranch(docId, fieldName);
        }
        return fieldName != "active" && !this.getDataFromCurrentSectionSC(docId, "active");
    };
    SystemConstitutionComponent.prototype.isCellDisabledSubBranch = function (docId, fieldName) {
        var _this = this;
        var subBranch = this.currentSectionSC.find(function (sb) { return sb[_this.sections.subBranch.name]._id == docId; });
        switch (fieldName) {
            case "active":
                return subBranch && subBranch.DefaultSubBranch;
            case "DefaultSubBranch":
                return !subBranch || subBranch.DefaultSubBranch;
        }
    };
    SystemConstitutionComponent.prototype.showMessega = function () {
        this.displayDialog = true;
    };
    SystemConstitutionComponent.prototype.deactivate = function (bool) {
        // when navigating from here to a router with children (for example: requestsandlicenses/requests), 
        // the function canDeactivate ran, if it returns true, then it runs again, 
        // then it runs the showMessage function again, 
        // so: the variable unsavedChanches becomes false in the first run of the canDeactivate
        // as a result, the showMessage function does not run in the second run of canDeactivate.
        this.unsavedChanges = !bool;
        this.canDeactivate.next(bool);
        this.displayDialog = false;
    };
    SystemConstitutionComponent.prototype.updateOrCreateSystemConstitution = function () {
        var _this = this;
        this.systemConstitutionService.updateOrCreateSystemConstitution(this.systemConstitution._id, this.systemConstitution).subscribe(function (res) {
            _this.systemConstitution._id = res.data._id;
            _this.unsavedChanges = false;
        });
    };
    return SystemConstitutionComponent;
}());
export { SystemConstitutionComponent };
