import { Pipe, PipeTransform } from '@angular/core';
import { getDateData } from "../util/date-helper"
@Pipe({
  name: 'stringDayOrDate'
})
export class StringDayOrDatePipe implements PipeTransform {
  transform(dayNumber: number, date: any, seperator: string = '/'): string {
    let output = "";
    switch (dayNumber) {
      case 0:
        output = 'היום';
        break;
      case 1:
        output = 'מחר';
        break;
      case 2:
        output = '2 ימים';
        break;
      case 3:
        output = '3 ימים';
        break;
      case 4:
        output = '4 ימים';
        break;
      case 5:
        output = '5 ימים';
        break;
      default:
        const dateData = getDateData(new Date(date));
        output = `${dateData.day}${seperator}${dateData.month}${seperator}${dateData.year}`;
    }

    return output;
  }
}
