/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./banner.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./banner.component";
var styles_BannerComponent = [i0.styles];
var RenderType_BannerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BannerComponent, data: {} });
export { RenderType_BannerComponent as RenderType_BannerComponent };
function View_BannerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "d-flex align-items-center get-back"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["class", "icon-left-arrow ml-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "button", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.buttonString; _ck(_v, 4, 0, currVal_0); }); }
export function View_BannerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "section", [["class", "bannerSection w-100"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "bannerText width-main"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BannerComponent_1)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 0, "h2", [["class", "header-banner"]], [[8, "innerHTML", 1]], null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.buttonString; _ck(_v, 3, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.title; _ck(_v, 4, 0, currVal_1); }); }
export function View_BannerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-banner", [], null, null, null, View_BannerComponent_0, RenderType_BannerComponent)), i1.ɵdid(1, 114688, null, 0, i3.BannerComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BannerComponentNgFactory = i1.ɵccf("app-banner", i3.BannerComponent, View_BannerComponent_Host_0, { title: "title", buttonString: "buttonString" }, {}, []);
export { BannerComponentNgFactory as BannerComponentNgFactory };
