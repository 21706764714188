<section class="requestSection">
  <div class="requestContainer">
    <div class="titleContainer titleContainer--textRight">
      <h2 class="subTitle subTitle--bold colorTextBlue">{{employeesApplicationList?.title}}</h2>
      <div class="textContent textContent--semiBold colorTextBlack">
        {{employeesApplicationList?.subtitle}}
      </div>
    </div>
  </div>

  <table mat-table [dataSource]="dataSource" class="generalAngularTable wantedWorkersTable">

    <!-- select Column -->
    <ng-container *ngIf="employeesApplicationList.editable" matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef class="checkBoxCell">
        <mat-checkbox [aria-label]="checkboxLabel()" (change)="$event ? toggleCheckAll($event) : null"
          [checked]="selection.hasValue() && isAllSelected()" class="checkboxSelect">
        </mat-checkbox>
      </th>


      <td mat-cell *matCellDef="let element" class="checkBoxCell">
        <mat-checkbox [disabled]="element.disabled"
          (click)="$event.stopPropagation()" (change)="onEmployeeChecked(element)"
          [checked]="selection.isSelected(element)" [aria-label]="checkboxLabel(element)" class="checkboxSelect">
        </mat-checkbox>
      </td>
    </ng-container>

    <!-- id Column -->
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef class="colorTextGray"> תעודת זהות </th>
      <td mat-cell *matCellDef="let element" [ngClass]="getCellColor(element)" class="idData">
        {{ element.id }}
      </td>
    </ng-container>

    <!-- status Column -->
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef class="colorTextGray"> הערה </th>
      <td mat-cell *matCellDef="let element" [ngClass]="getCellColor(element)" class="notes">
        {{element.status.value}}
      </td>
    </ng-container>

    <!-- file Column -->
    <ng-container matColumnDef="file">
      <th mat-header-cell *matHeaderCellDef> </th>
      <td mat-cell *matCellDef="let element" class="fileUploadedData">
        <div *ngIf="employeesApplicationList.editable && element.file">
          <app-upload-file (onFileUpload)="uploadFile($event, element)" (onFileDelete)="deleteFile(element)"
            [downloadDocument]="element.file"
            [disabled]="!allowUpload() && element.disabled"></app-upload-file>
        </div>
      </td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="displayedColumns" class=""></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="bgWhite shadow"
      [class.checked]="selection.isSelected(row)">
    </tr>
  </table>

  <div *ngIf="employeesApplicationList.editable" class="bottomEmployeeTable">
    <div class="textContent textContent--semiBold colorTextBlack">סה"כ בקשות שנבחרו
      <span>({{selection.selected.length}})</span>
    </div>
    <div class="bottomEmployeeTable__error" *ngIf="showReachedMaxSelection()">
      <span class="errornote colorTextRed textContent textContent--semiBold">
        <ng-container *ngIf="fieldService.getAvailableEmployeeSum() != 0">
          שים לב, אינך יכול להעסיק יותר מ {{fieldService.getAvailableEmployeeSum()}} עובדים בענף שבחרת 
        </ng-container>
        <ng-container *ngIf="fieldService.getAvailableEmployeeSum() == 0">
         שים לב, אינך יכול להעסיק יותר עובדים בענף שבחרת
        </ng-container>
        <br>
        ניתן להסיר סימון עובדים על מנת להוריד את הכמות ולהמשיך בבקשה לאחר מכן.
      </span>
    </div>
    <div class="bottomEmployeeTable__error" *ngIf="showUploadBuildingPermit()">
      <div class="buildingPermitTitleContainer">
        <span *ngIf="buildingPermitService.hasBuildingPermitUploaded(); else noBuildingPermit" class="textContent textContent--semiBold colorTextBlack">
          היתר בניה:
        </span>
        <ng-template #noBuildingPermit>
                  <span>
        בנוסף, באפשרותך להעלות מסמך היתר בנייה ולהוסיף
                    {{_selectedField?.licenseExclusion}}
                    עובדים נוספים למאגר העובדים שלך.
        </span>
        </ng-template>

      </div>

      <app-upload-file
        [formControlRef]="buildingPermitService.getBuildingPermitRef()"
        [downloadDocument]="buildingPermitDocument"
        [beforeDeleteInfo]="beforeDeleteModalData"
        (onDeleteConfirm)="onConfirmDeleteBuildingPermit()">
      </app-upload-file>
    </div>
  </div>
</section>

<span *ngIf="employeesApplicationList.editable && selection.selected.length === 0"
class="textContent textContent--semiBold colorTextRed validationError">יש לבחור עובד אחד לפחות</span>
