<section class="pageNotFoundSection">
  <div class="container-fluid">
    <h2 class="subTitle subTitle--blueTextColor">404</h2>
    <div class="pageContent bgWhite shadow">
      <img src="./assets/imgs/pageNotFoundPic.png" class="" />
      <div class="subTitle subTitle--grayTextColor">
        העמוד שחיפשת אינו קיים
      </div>

      <a routerLink="/home" class="btn btn--regularBtn btn--blue btn--semiBold next">
        חזרה לעמוד הבית
      </a>
    </div>
  </div>
</section>
