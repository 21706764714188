<div class="mainMenu position-relative" (clickOutside)="closeMainMenu.emit()" *ngIf="user">
  <button type="button" class="mainMenu__btn" (click)="toggleMainMenu.emit()" [class.active]="isMainMenuOpen">
    <!-- <app-svg [svgType]="'profile'" class="icon"></app-svg> -->
    <div class="profile-img">
      <i class="icon-userimage"></i>
    </div>
    <span class="text">שלום, {{user.user.name}}</span>
    <div class="arrow"></div>
  </button>
  <ul class="mainMenu__list" *ngIf="isMainMenuOpen">
    <li class="mainMenu__item">
      <a class="mainMenu__link link link--underLineHover link--semiBold" (click)="showModal()">

        <app-svg [svgType]="'setting'" class="mainMenu__icon"></app-svg>
        <span class="mainMenu__text">פרטים אישיים</span>
      </a>
    </li>
    <!-- <li class="mainMenu__item">
      <a class="mainMenu__link link link--underLineHover link--semiBold" routerLink="/savedAds"
        [routerLinkActive]="'active'">
        <app-svg [svgType]="'star'" class="mainMenu__icon"></app-svg>
        <span class="mainMenu__text">מודעות שמורות</span>
      </a>
    </li> -->
    
    <li class="mainMenu__item">
      <a class="mainMenu__link link link--underLineHover link--semiBold" routerLink="/c" [routerLinkActive]="'active'"
        (click)="logOut()">
        <app-svg [svgType]="'logout'" class="mainMenu__icon"></app-svg>
        <span class="mainMenu__text">התנתקות</span>
      </a>
    </li>
    
  </ul>
</div>


<!-- =====modal personal information -->
<p-dialog [(visible)]="displayModal" class="dilog-matpash" [modal]="true" [responsive]="true"
[style]="{width: '600px', minWidth: '600px'}" [minY]="70" [baseZIndex]="10000">
  <div>
    <h1 >פרטים אישיים</h1>
    <form class="personalInfoForm mb-5" [formGroup]="editInfoFormGroup" [class.active]="editMode" (ngSubmit)="onSubmit()">
      <div class="personalInfoContainer">
          <div class="d-md-flex mb-4">
            <div class="fw-bold ml-3">ת.ז / ח.פ</div>
            <div>{{user.identity}}</div>
          </div>
      
          <div  class="d-md-flex mb-4">
            <div class="fw-bold ml-3">שם</div>
            <div>{{user.user.name}}</div>
          </div>

          <div class="d-md-flex mb-4">
            <div class="fw-bold ml-3">מספר טלפון</div>
            <div>{{user.user.phoneNo}}</div>
          </div>

          <div class="d-md-flex mb-4">
            <div class="fw-bold ml-3">כתובת אימייל</div>
            <div>{{user.user.email}}</div>
          </div>
         
        <div class="mt-3">
          לשינוי טלפון או כתובת אימייל יש לפנות למוקד הטלפוני 4943*
        </div>
      </div>
    </form>
<div class="d-flex my-4">
    <button type="button" pButton icon="pi pi-check" (click)="displayModal=false" label="אישור"
    class="ui-button-rounded  button-matpash light-blue-fill col-md-4 mr-auto"></button>
   </div>
  </div>
</p-dialog>

