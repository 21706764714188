import {OnDestroy, OnInit} from '@angular/core';
import {Subject} from "rxjs";


export class BaseComponent implements OnInit, OnDestroy {
  componentDestroyed: Subject<any> = new Subject();

  constructor() {
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    this.initDestroy();
  }

  //Use Method Due To The Fact That Extends Doesn't Have super() Nn ngOnDestroy If Overridden
  protected initDestroy() {
    this.componentDestroyed.next();
    this.componentDestroyed.unsubscribe();
  }

}
