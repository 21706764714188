const requestDetailMockData = {
  "fields": [{
    "code": 3,
    "name": "מלונאות",
    "subCode": 148,
    "subName": "ניקיון וחדרנים",
    "employmentLimitation": 0,
    "currentEmployed": 17,
    "licenseLimitation": 6,
    "documents": [],
    "licenseExclusion": 0
  }, {
    "code": 2,
    "name": "שרותים",
    "subCode": 128,
    "subName": "איכות הסביבה - מחזור",
    "employmentLimitation": 0,
    "currentEmployed": 0,
    "licenseLimitation": 6,
    "documents": [],
    "licenseExclusion": 0
  }, {
    "code": 4,
    "name": "בנין",
    "subCode": 172,
    "subName": "בנייה מגורים",
    "employmentLimitation": 6,
    "currentEmployed": 2,
    "licenseLimitation": 3,
    "documents": [{
      "code": 31,
      "name": "הצהרת מעסיק.pdf",
      "hasDocumentToDownload": true,
      "description":"statementDocument",
      "required":true

    }, {
      "code": 3,
      "name": "היתר בנייה.pdf",
      "hasDocumentToDownload": false,
      "description":"buildingPermit",
      "required":false
    }],
    "licenseExclusion": 4
  }],
  "workingHour": [{
    "code": 1,
    "min": "05:00",
    "max": "22:00",
    "isDefault": false
  }, {
    "code": 2,
    "min": "05:00",
    "max": "02:00",
    "isDefault": false
  }, {
    "code": 3,
    "min": "24:00",
    "max": "24:00",
    "isDefault": false
  }, {
    "code": 4,
    "min": "05:00",
    "max": "21:00",
    "isDefault": true
  }, {
    "code": 5,
    "min": "05:00",
    "max": "20:00",
    "isDefault": false
  }, {
    "code": 6,
    "min": "05:00",
    "max": "19:00",
    "isDefault": false
  }, {
    "code": 7,
    "min": "05:00",
    "max": "00:00",
    "isDefault": false
  }, {
    "code": 10,
    "min": "04:00",
    "max": "19:00",
    "isDefault": false
  }, {
    "code": 11,
    "min": "03:00",
    "max": "19:00",
    "isDefault": false
  }],
  "hasPermit": false,
  "downloadDocuments": {
    "statementDocument": {
      "code": 31,
      "name": "הצהרת מעסיק.pdf",
      "hasDocumentToDownload": true
    },
    "buildingPermit": {
      "code": 3,
      "name": "היתר בנייה.pdf",
      "hasDocumentToDownload": false
    },
    "contractDocument": {
      "code": 4,
      "name": "חוזה עבודה - הסכם עבודה.jpg",
      "hasDocumentToDownload": false
    },
    "workingHours": {
      "code": 42,
      "name": "טופס שינוי שעות.pdf",
      "hasDocumentToDownload": true
    }
  }
};

export {requestDetailMockData}
