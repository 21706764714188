<div *ngIf="currentEmployeesData" class="employeesStatuses d-flex flex-column">

  <div class="header-status flex-md-row flex-column d-flex justify-content-between border-bottom-0">

    <h4 class="d-flex align-items-center"><i class="ml-3 icon-Current-employee-status"></i>סטטוס עובדים נוכחיים</h4>
    <div *ngIf="currentEmployeesData.employees.length" class="gray-div-status d-flex align-items-center">
      <i class="icon-Number-of-employees ml-3"></i>
      <label> סה"כ {{ currentEmployeesData.totalEmployees }} עובדים נוכחיים</label>
    </div>
  </div>

  <div class="d-flex flex-column flex-grow-1">
    <ng-container *ngIf="currentEmployeesData.employees.length; then hasEmployees; else noEmployees">
    </ng-container>
    <ng-template #hasEmployees>
      <p-table [value]="currentEmployeesData.employees" dataKey="id" styleClass="p-datatable-customers"
        [rowHover]="true" [loading]="loading" [filterDelay]="0"
        [globalFilterFields]="['employee.fullName','employee.daysLeftToExpire']" class="table-home-page mt-3 m-md-0">
        <ng-template pTemplate="header">
          <tr>
            <th class="textContent" style="width: 10%">#</th>
            <th pSortableColumn="fullName" style="width: 40%;">
              שם מלא
              <p-sortIcon field="fullName"></p-sortIcon>
            </th>
            <th pSortableColumn="daysLeftToExpire" style="width: 50%">
              תאריך סיום
              <p-sortIcon field="fullName"></p-sortIcon>
            </th>


          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-employee let-i="rowIndex">
          <tr>
            <td style="width: 10%;">
              {{i+1}}
            </td>
            <td style="width: 40%;">
              <div class="tableDataInside tableDataInside--tablePadding">
                {{ employee.fullName }}
              </div>
            </td>
            <td style="width: 50%;">
              <div class="tableDataInside tableDataInside--tablePadding d-flex flex-md-row flex-column">
                <div *ngIf="employee.isLicenseInIsrael==false">
                <div class="d-flex align-items-center" [ngClass]="getWarningClass(employee)">
                  
                  <i class="ml-2 icon-Expiration color-status"> </i>
                  <span> {{ employee.daysLeftToExpire | stringDayOrDate: employee.licenseExpiredDate }}</span>
                </div>
              </div>
                <button type="button"
                  *ngIf="employee.daysLeftToExpire <= allowRenewDays && employee.daysLeftToExpire >= 0 && !employee.isRenew"
                  (click)="renewLicense(employee)" pButton
                  class="ui-button-rounded  button-matpash blue-fill d-flex align-items-center  justify-content-center mr-md-auto mr-0 mt-3 m-md-0 mb-2 m-md-0"
                  label="חידוש בקשה">
                  <i class="icon-send mr-3"></i>
                </button>
              </div>

            </td>

          </tr>
        </ng-template>
      </p-table>


    </ng-template>

    <ng-template #noEmployees>
      <div class="noLicenseRequests">
        <div class="noLicenseRequests__box">
          <img src="./assets/imgs/emptyBox.png" class="emptyImg" />
          <div class="subTitle subTitle--grayTextColor">
            לא קיימים עובדים
          </div>
          <div class="noLicenseRequests__noteText">
            ניתן ליצור קשר עם נציג שירות במספר 4943*
          </div>
        </div>
      </div>
    </ng-template>
    <div *ngIf="currentEmployeesData.amountOfExpiryInTimeFrame"
      class="textContent  textContent--fontRegular bottomTable">
      ו- {{ currentEmployeesData.amountOfExpiryInTimeFrame }} עובדים נוספים שרשיונם עומד להסתיים
      בשבועיים הקרובים
    </div>
  </div>

  <div class="row mt-auto">
    <div class="col-12 col-md-7 mt-5 m-md-0">
      <div class="fs-16 color-blue d-flex align-items-center">
        <i class="icon-clock-2 gray-icon ml-2"></i>
        מעודכן נכון לתאריך {{ date | date: "d/M/y" }}

      </div>
    </div>

    <div class="col-7 mr-auto col-md-5 justify-content-end d-flex mt-4 m-md-0">
      <button type="button" *ngIf="currentEmployeesData.employees.length" pButton
        class="ui-button-rounded  button-matpash blue-fill d-flex align-items-center  justify-content-center  end-btn"
        routerLink="/requestsandlicenses/licences" label="צפה בהכל">
        <i class="icon-view mr-3"></i>
      </button>

    </div>
  </div>

</div>