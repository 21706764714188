import * as i0 from "@angular/core";
import * as i1 from "./ApplicationHttpClient";
var UploadService = /** @class */ (function () {
    function UploadService(http) {
        this.http = http;
    }
    UploadService.prototype.UploadFile = function (uplaodFile, documentCode) {
        var formData = new FormData();
        formData.append('document', uplaodFile);
        formData.append('code', documentCode.toString());
        return this.http.Post("api/documents/upload", formData);
    };
    UploadService.prototype.deleteFile = function (fileId) {
        var token = sessionStorage.getItem('token');
        if (!!token) {
            return this.http.Delete("api/documents/delete/" + token + "/" + fileId);
        }
    };
    UploadService.prototype.generateDownloadFileOfUser = function (fileId) {
        var token = sessionStorage.getItem('token');
        if (!!token) {
            return this.http.getAPI() + "api/documents/downloadByUser/" + token + "/" + fileId;
        }
    };
    UploadService.prototype.generateDownloadFile = function (documentCode) {
        return this.http.getAPI() + "api/documents/download/" + documentCode;
    };
    UploadService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UploadService_Factory() { return new UploadService(i0.ɵɵinject(i1.ApplicationHttpClient)); }, token: UploadService, providedIn: "root" });
    return UploadService;
}());
export { UploadService };
