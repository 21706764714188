import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phoneMasking'
})
export class PhoneMaskingPipe implements PipeTransform {
  transform = (value: string, ...args: any[]): string => {
    if(!value) return;
    return `${value.slice(-3)}****${value.slice(0, 3)}`;
  };
}
