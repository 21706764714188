<div *ngIf="showPrintBtn | async" class="d-flex">

  <button class="printBtn actionBtn " (click)="print()">
    <i class="icon-print mx-2"></i>
    <span>הדפסה</span>
  </button>

  <button class="mailBtn actionBtn " (click)="sendEmail()">
    <i class="icon-mail mx-2"></i>
    <span>שליחה למייל</span>
  </button>

  <button class="mailBtn actionBtn " (click)="exportExcel()">
    <i class="icon-Version mx-2"></i>
    <span>יצוא לאקסל</span>
  </button>
</div>