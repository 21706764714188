import { Component, OnInit, EventEmitter, SimpleChanges } from "@angular/core";
import { FormService } from "../../service/form.service";
import {FormArray, Validators} from "@angular/forms";
import { filter } from "rxjs/operators";
import { StepComponentBase } from '../wizard-step-component-base/step-component-base';
import { StepPagesService } from '../../service/step-pages.service';
import { MatDialog } from '@angular/material';
import {
  Docs,
  FieldsRequest,
  UploadedFile
} from 'src/app/models/interfaces';
import {WizradUserDataService} from "../../service/wizrad-user-data.service";
import {BuildingPermitService} from "../../service/building-permit.service";
import {FieldService} from "../../service/field.service";

@Component({
  selector: "app-adding-documents-step",
  templateUrl: "./adding-documents-step.component.html",
  styleUrls: ["./adding-documents-step.component.scss"]
})
export class AddingDocumentsStepComponent extends StepComponentBase implements OnInit {
  hasUploaded: boolean;
  file: File;
  FormArrayRef: FormArray = null;
  field: FieldsRequest = null;
  isToCheckToShowError = false;
  buildingPermitDocumentDetails:Docs;
  submitted:boolean=false;
  constructor(
    stepsPagesService: StepPagesService,
    private formService: FormService,
    dialog: MatDialog,
    private wizradUserDataService: WizradUserDataService,
    private buildingPermitService: BuildingPermitService,
    private fieldService: FieldService
  ) {
    super(stepsPagesService, dialog);
    this.FormArrayRef = this.formService
      .getForm()
      .get("documents") as FormArray;
    this.hasUploaded = true;
  }

  ngOnInit() {
    super.ngOnInit();
    this.buildingPermitDocumentDetails = this.buildingPermitService.getBuildingPermitDownloadDocument();
    //this methods is needed dut to the fact that this is a dynamic component, and the fields data has already changed before the render of the component
    this.setInitialCurrentDocuments();
  }

  setInitialCurrentDocuments() {
    const currentField = this.formService
      .getFormGroupRefByName("requestDetails")
      .get("fields").value;

    if(currentField){
      this.field = currentField;
      this.createDocuments(currentField.documents)
    }

    this.formService
      .getFormGroupRefByName("requestDetails")
      .get("fields")
      .valueChanges.pipe(filter(field => !!field))
      .subscribe(currentField => {
        this.field = currentField;
        this.createDocuments(currentField.documents)
      });
  }


  createDocuments(docs:Docs[]) {
    this.FormArrayRef.clear();

    docs.forEach((doc, index) => {
      if(doc.code === this.buildingPermitDocumentDetails.code){
        let ref = this.buildingPermitService.getBuildingPermitRef();
        this.FormArrayRef.push(ref)
      }
      else {
        let control = this.formService.createControl(null);
        if(doc.required){
          control.setValidators([Validators.required]);
          control.updateValueAndValidity();
        }
        this.FormArrayRef.push(control);
      }
    });
  }

  hideType(name){
    return name.substring(0, name.lastIndexOf('.'))
  }


  preventFileDelete(control): boolean {
    //TODO: MAKE A MORE GENERIC SOLUTION - MAYBE ADD TO INTERFACE
    return !!(control && control.value && ((control.value) as UploadedFile).fileTypeId === this.buildingPermitDocumentDetails.code
      && this.fieldService.hasEmploymentLimitation() && this.fieldService.usedBuildingPermitLimitation());
  }


  submit() {
    this.submitted = true;
    super.submit();
  }
}
