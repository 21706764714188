import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { livingCity } from 'src/app/models/Employee';
import { Area, Branch, EmployerType, Region, SubArea, SubBranch } from 'src/app/models/metadataModels';
import { ReportsService } from 'src/app/services/reports.service';

interface Report {
  name: string;
  reportId: number;
  headerReport: string;
  controls: Control[];
}

interface Control<T = any> {
  controlName: string;
  formControl: FormControl;
  data?: T[];
  options?: T[];
  initOptiens?: () => void;
}

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {

  areaControl: Control<Area> = {
    options: [],
    controlName: "area",
    formControl: new FormControl(),
    initOptiens: () => {
      if (this.areaControl.options.length) {
        return;
      }
      this.reportsService.getAreaes().subscribe(res => this.areaControl.options = res.data);
    }
  }
  subAreaControl: Control<SubArea> = {
    data: [],
    options: [],
    controlName: "subArea",
    formControl: new FormControl({ value: null, disabled: true }),
    initOptiens: () => {
      if (this.subAreaControl.data.length) {
        return;
      }
      this.reportsService.getSubAreaes().subscribe(res => this.subAreaControl.data = res.data);
    }
  }
  branchControl: Control<Branch> = {
    options: [],
    controlName: "branch",
    formControl: new FormControl(),
    initOptiens: () => {
      if (this.branchControl.options.length) {
        return;
      }
      this.reportsService.getBranches().subscribe(res => this.branchControl.options = res.data);
    }
  }
  subBranchControl: Control<SubBranch> = {
    data: [],
    options: [],
    controlName: "subBranch",
    formControl: new FormControl({ value: null, disabled: true }),
    initOptiens: () => {
      if (this.subBranchControl.data.length) {
        return;
      }
      this.reportsService.getSubBranches().subscribe(res => this.subBranchControl.data = res.data);
    }
  }
  employerTypeControl: Control<EmployerType> = {
    options: [],
    controlName: "employerType",
    formControl: new FormControl(),
    initOptiens: () => {
      if (this.employerTypeControl.options.length) {
        return;
      }
      this.reportsService.getEmployerTypes().subscribe(res => this.employerTypeControl.options = res.data);
    }
  }
  regionControl: Control<Region> = {
    options: [],
    controlName: "region",
    formControl: new FormControl(),
    initOptiens: () => {
      if (this.regionControl.options.length) {
        return;
      }
      this.reportsService.getRegions().subscribe(res => this.regionControl.options = res.data);
    }
  }
  livingCityControl: Control<livingCity> = {
    data: [],
    options: [],
    controlName: "livingCity",
    formControl: new FormControl({ value: null, disabled: true }),
    initOptiens: () => {
      if (this.livingCityControl.options.length) {
        return;
      }
      this.reportsService.getLivingCity().subscribe(res => this.livingCityControl.data = res.data);
    }
  }
  fromDateControl: Control = {
    controlName: "fromDate",
    formControl: new FormControl(new Date(), [
      this.requiredValidator,
      this.checkOtherControl("toDate")
    ])
  }
  toDateControl: Control = {
    controlName: "toDate",
    formControl: new FormControl(new Date(), [
      this.requiredValidator,
      this.minByOtherControlValidator(this.fromDateControl.formControl, "שדה 'עד תאריך' חייב להיות גדול מערך השדה 'מתאריך'")
    ])
  }
  fromAgeControl: Control = {
    controlName: "fromAge",
    formControl: new FormControl(null, this.checkOtherControl("toAge"))
  }
  toAgeControl: Control = {
    controlName: "toAge",
    formControl: new FormControl(null,
      this.minByOtherControlValidator(this.fromAgeControl.formControl, "ערך השדה 'עד גיל' חייב להיות גדול או שווה לשדה 'מגיל'")
    )
  }

  readonly reports: Report[] = [
    {
      reportId: 1,
      name: 'משתמשים פעילים',
      headerReport: 'כמות משתמשים פעילים בפורטל העסקה.',
      controls: [],
    }, {
      reportId: 2,
      name: 'שימושים במערכת',
      headerReport: '.כמות פעולות בפורטל העסקה',
      controls: [this.fromDateControl, this.toDateControl]
    }, {
      reportId: 3,
      name: 'רישיונות שנופקו',
      headerReport: '.כמות רישיונות שנופקו',
      controls: [this.fromDateControl, this.toDateControl, this.branchControl, this.subBranchControl]
    }, {
      reportId: 4,
      name: 'בקשות שטרם הושלמו',
      headerReport: '.כמות בקשות שטרם הושלמו',
      controls: [this.branchControl, this.subBranchControl]
    }, {
      reportId: 5,
      name: 'רשימת מעסיקים',
      headerReport: 'רשימת מעסיקים פעילים',
      controls: [this.branchControl, this.subBranchControl, this.employerTypeControl]
    }, {
      reportId: 6,
      name: 'רישיונות פעילים',
      headerReport: '.כמות רישיונות שנופקו',
      controls: [
        this.areaControl,
        this.subAreaControl,
        this.branchControl,
        this.subBranchControl,
        this.regionControl,
        this.livingCityControl,
        this.fromAgeControl,
        this.toAgeControl,
        this.fromDateControl,
        this.toDateControl
      ]
    }, {
      reportId: 7,
      name: 'מכסות שמורות',
      headerReport: '.מכסות שמורות',
      controls: [this.areaControl, this.subAreaControl, this.branchControl, this.subBranchControl]
    }, {
      reportId: 8,
      name: 'בקשות להפסקת עבודה',
      headerReport: '.כמות בקשות להפסקת העסקה',
      controls: [this.branchControl, this.subBranchControl, this.fromDateControl, this.toDateControl]
    }, {
      reportId: 9,
      name: 'בקשות לחידוש העסקה',
      headerReport: '.כמות בקשות לחידוש רישיונות',
      controls: [this.branchControl, this.subBranchControl, this.fromDateControl, this.toDateControl]
    }, {
      reportId: 10,
      name: 'עובדים במאגר',
      headerReport: '.כמות עובדים במאגר',
      controls: [this.branchControl, this.subBranchControl, this.fromDateControl, this.toDateControl]
    }
  ];

  reportControl = new FormControl(this.reports[0]);

  parametersForm: FormGroup = new FormGroup({
    report: this.reportControl
  });

  constructor(private reportsService: ReportsService) { }

  ngOnInit() {
    this.onSelectReport();
  }

  onSelectReport() {
    this.resetParametersForm();
    this.addControls(this.reportControl.value.controls);
  }
  resetParametersForm() {
    for (let controlName in this.parametersForm.controls) {

      if (controlName == 'report') {
        continue;
      }
      this.parametersForm.removeControl(controlName);
    }
  }

  addControls(controls: Control[]) {
    controls.forEach(
      control => {
        this.parametersForm.addControl(control.controlName, control.formControl)
        if (control.initOptiens) {
          control.initOptiens();
        }
      }
    );
  }


  onSelectParameterArea() {

    this.subAreaControl.options = this.subAreaControl.data.filter(
      sa => sa.area._id == this.areaControl.formControl.value._id
    );

    if (this.subAreaControl.options.length) {
      return this.subAreaControl.formControl.enable();
    }
    this.subAreaControl.formControl.disable();
  }
  onSelectParameterBranch() {

    this.subBranchControl.options = this.subBranchControl.data.filter(
      sb => sb.branch._id == this.branchControl.formControl.value._id
    );

    if (this.subBranchControl.options.length) {
      return this.subBranchControl.formControl.enable();
    }
    this.subBranchControl.formControl.disable();
  }
  onSelectParameterRegion() {

    this.livingCityControl.options = this.livingCityControl.data.filter(
      lc => lc.regionId._id == this.regionControl.formControl.value._id
    );

    if (this.livingCityControl.options.length) {
      return this.livingCityControl.formControl.enable();
    }
    this.livingCityControl.formControl.disable();
  }

  requiredValidator(control: AbstractControl): ValidationErrors | null {
    return Validators.required(control) ? { msg: "שדה חובה." } : null;
  }
  minByOtherControlValidator(other: AbstractControl, MsgErr: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      return control.value && other.value > control.value ? { msg: MsgErr } : null;
    }
  }
  checkOtherControl(otherControlName: string): ValidatorFn {
    return () => {
      let otherControl :AbstractControl;

      if (this.parametersForm && (otherControl = this.parametersForm.get(otherControlName))) {
        otherControl.setValue(otherControl.value);
      }
      return null;
    }
  }

  createReport() {
    console.log(this.parametersForm.value);
  }
}