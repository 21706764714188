import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  OnDestroy
} from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { LicenseService } from 'src/app/services/license-service';
import {
  RequestStatusData,
  RequestStatus,
  CurrentEmployee,
  CurrentEmployeeExtended,
  GeneralModalData,
  User
} from 'src/app/models/interfaces';

import applicationtStatusTypes from 'src/app/shared/util/applicationtStatusTypes';
import { MatDialog, MatDialogConfig, MatMenuModule } from '@angular/material';
import { GeneralModalComponent } from 'src/app/shared/components/general-modal/general-modal.component';
import { PrintService } from 'src/app/shared/service/print.service';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import 'jspdf-autotable';
import { jsPDF } from 'jspdf';
// import { EmailService } from 'src/app/shared/service/email.service';
import { Pdf } from 'src/app/models/pdf';
import { UserDataService } from 'src/app/services/user-data.service';
import {  ExcelRequestStatus } from 'src/app/models/excel';


@Component({
  selector: 'app-status-request',
  templateUrl: './status-request.component.html',
  styleUrls: ['./status-request.component.scss'],
  styles: [`
    :host ::ng-deep {    
      .p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
          display: none;
      }
  }
  
  @media screen and (max-width: 40rem) {
      :host ::ng-deep {
          .p-datatable {
              &.p-datatable-responsive-demo {
                  .p-datatable-thead > tr > th,
                  .p-datatable-tfoot > tr > td {
                      display: none !important;
                  }
  
                  .p-datatable-tbody > tr > td {
                      text-align: left;
                      display: block;
                      width: 100%;
                      float: left;
                      clear: left;
                      border: 0 none;
  
                      .p-column-title {
                          padding: .4rem;
                          min-width: 30%;
                          display: inline-block;
                          margin: -.4em 1em -.4em -.4rem;
                          font-weight: bold;
                      }
  
                      &:last-child {
                          border-bottom: 1px solid var(--surface-d);
                      }
                  }
              }
          }
      }
  }`
  ]
})



export class StatusRequestComponent implements OnInit, OnDestroy {
  
  displayedColumns = [
    { header: 'מספר פנייה', field: 'applicationID' },
    { header: 'שם העובד', field: 'fullName' },
    { header: 'מספר זהות', field: 'id' },
    { header: 'סטטוס', field: 'licenseStatus.name' },
    { header: 'בקשה לאזור', field: 'isLicenseInIsrael' },
    { header: 'הערות', field: 'declineReason' },
    { icon:'icon-warning', field:'status'}
  ]

  dataSource: CurrentEmployeeExtended[];
  // dataSource: MatTableDataSource<CurrentEmployeeExtended>;

  requestStatusData: RequestStatusData;
  date: Date;
  displayModal: boolean = false
  title: string
  text: string
  option =[ {"name":"שלי בלבד","id" : 1},{"name":"כלל הבקשות","id" : 2}];
  textToShow : any = {"name":"כלל הבקשות","id" : 2} ;
  

  listExcel:Array<ExcelRequestStatus>=new Array<ExcelRequestStatus>()
  excel:any[]
  // textToShow : string;
  pdf:Pdf=new Pdf()
  user1:User
alredyOpen:boolean=false



  public config: PerfectScrollbarConfigInterface = {
    wheelSpeed: 0.2
  };

  // Init Sort - This Is Here Because The Mat-Table Is Surrounded With ngIf.
  sort;
  @ViewChild('content', { static: false }) content: ElementRef;

  @ViewChild(MatSort, { static: false }) set content1(content: ElementRef) {
    this.sort = content;
    if (this.sort) {
      this.dataSource.sort = this.sort;
    }
  }

  constructor(
    private licenseService: LicenseService,
    private printService: PrintService,
    private dialog: MatDialog,
    // public emailService:EmailService,
    public userDataService:UserDataService
  ) {
    this.date = new Date();
  }

  get applicationtStatusTypes() {
    return applicationtStatusTypes;
  }


  
  ngOnInit() {
    this.userDataService.getUser1().subscribe(u=>{
      this.user1=u.user
    })
    this.initAll();

  }

  ngAfterViewInit() {
    this.initAll();
  }
  

  initAll(hardReload = false) {
    this.licenseService.getLicensesData().subscribe(requestStatusData => {

          const manipulatedData = this.populateCurrentEmployeeExtended(
            requestStatusData
          );
          this.requestStatusData = requestStatusData;
         
          if (manipulatedData.length > 0) {
            this.printService.showPrintButton();
          }
          
          this.dataSource = manipulatedData;
          this.dataSource=this.dataSource.sort((a1, a2)=>{
          return  Number(a2.applicationID)-Number(a1.applicationID)
          })

          this.excel=this.exportExcel()
          this.printService.insertHTMLdata('requestStatus', 'applicationTable', this.content, this.excel);
          this.printService.setCurrentRawData('requestStatus');
        })
  }
  
  populateCurrentEmployeeExtended(
    requestStatusData: RequestStatusData
  ): CurrentEmployeeExtended[] {
    const flattendData = [];
    requestStatusData.requestsStatus.forEach(r => {
      r.employees.forEach(employee => {
        flattendData.push({
          applicationID: r.applicationID,
          createdTime: r.createdTime,
          applicationType: r.applicationType,
          ...employee
        });
      });
    });
    return flattendData;
  }

  getPropertyByPath(data: any, pathString: string) {
    const value = pathString.split('.').reduce((o, i) => o[i], data);

    // if (Array.isArray(value)) {
    //   return value[0].name;
    // }
    return value;
  }

  getRowColor(row: CurrentEmployeeExtended): string {
    return this.licenseService.getStatusColor(row.applicationStatus ? row.applicationStatus.code : null);
  }

  openDocumentsErrorModal(row: CurrentEmployeeExtended) {
    this.title = this.populateCommentTitle(row)
    this.text = row.comment
    this.displayModal = true
  }

  filterTable(){
    if (this.textToShow.id==1){
      this.dataSource= this.dataSource.filter((user)=>{
        return user.id==this.user1.identity
      })
      this.excel=this.exportExcel()
      this.printService.insertHTMLdata('requestStatus', 'applicationTable', this.content, this.excel);
      this.printService.setCurrentRawData('requestStatus');   
     }
    else{
      this.initAll()
    }
  }

  populateCommentTitle(row: CurrentEmployeeExtended) {
    let title = '';
    const applicationStatus = row.applicationStatus ? row.applicationStatus.code : null;
    switch (applicationStatus) {
      case applicationtStatusTypes.APPROVED:
        title = row.restriction ? row.restriction.name : '';
        break;
      case applicationtStatusTypes.DECLINED:
        title = row.declineReason ? row.declineReason.name : '';
        break;
    }

    return title;
  }

  ngOnDestroy(): void {
    this.printService.hidePrintButton();
  }


  exportExcel(){
    this.listExcel=[]
    if (this.dataSource){
      this.dataSource.forEach((a)=>{
        // this.listExcel.push({id:a.fullName, name:a.applicationID})
        this.listExcel.push({apllicationId:a.applicationID,createTime:a.createdTime , fullName:a.fullName, identity:a.id, 
          status:a.applicationStatus.name, area:a.isLicenseInIsrael ? 'ישראל' : 'התיישבות', comment:a.comment})
      })
      this.alredyOpen=true
      return this.listExcel
    }
   
  }
}
