import { AbstractControl } from "@angular/forms";

export const approvedFilesType = file => {
  const approvedFiles = [".gif", ".jpg", ".png", ".jpeg", ".pdf"];
  const index = file.name.lastIndexOf(".");
  const typeFile = file.name.slice(index).toLowerCase();
  return approvedFiles.includes(typeFile);
};

export const checkForValidFiles = (...arrayOfFiles) => {
  let invalidFlag = false;
  const maxFilesSize = 10000000;
  let filesSizes = 0;
  const files = arrayOfFiles;
  if (files.length > 20) {
    return true;
  }
  files.forEach(element => {
    filesSizes += element.size;
    if (!approvedFilesType(element) || filesSizes > maxFilesSize) {
      invalidFlag = true;
    }
  });
  return invalidFlag;
};

export const checkForValidFile = file => {
  let invalidFlag = false;
  const maxFileSize = 12000000;
  let fileSize = 0;
  const files = file;
  if (files.length > 20) {
    return true;
  }
  fileSize += files.size;
  if (!approvedFilesType(files) || fileSize > maxFileSize) {
    invalidFlag = true;
  }
  return invalidFlag;
};

export function ValidateFiles(control: AbstractControl) {
  if (!control.value) {
    return null;
  }
  if (checkForValidFiles(control.value)) {
    return { ValidateFiles: true };
  }
  return null;
}
