/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./messages-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../shared/components/svg/svg.component.ngfactory";
import * as i3 from "../../../shared/components/svg/svg.component";
import * as i4 from "@angular/common";
import * as i5 from "./messages-modal.component";
import * as i6 from "@angular/material/dialog";
import * as i7 from "@angular/router";
var styles_MessagesModalComponent = [i0.styles];
var RenderType_MessagesModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MessagesModalComponent, data: {} });
export { RenderType_MessagesModalComponent as RenderType_MessagesModalComponent };
function View_MessagesModalComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "modalMsgContent colorTextBlue"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" \u05EA\u05D5\u05D3\u05D4 \u05E8\u05D1\u05D4, \u05D1\u05E7\u05E9\u05EA\u05DA \u05D4\u05EA\u05E7\u05D1\u05DC\u05D4! \u05D0\u05E0\u05D5 \u05DE\u05EA\u05D7\u05D9\u05DC\u05D9\u05DD \u05D0\u05EA \u05EA\u05D4\u05DC\u05D9\u05DA \u05D4\u05D0\u05D9\u05E9\u05D5\u05E8 "]))], null, null); }
function View_MessagesModalComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "modalMsgContent colorTextRed"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" \u05DE\u05E6\u05D8\u05E2\u05E8\u05D9\u05DD, \u05D4\u05D1\u05E7\u05E9\u05D4 \u05DC\u05D0 \u05E0\u05E9\u05DC\u05D7\u05D4 "]))], null, null); }
function View_MessagesModalComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "modalRequestNumber colorTextBlue"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" \u05DE\u05E1\u05E4\u05E8 \u05D4\u05E4\u05E0\u05D9\u05D9\u05D4 \u05DC\u05E6\u05D5\u05E8\u05DA \u05DE\u05E2\u05E7\u05D1 \u05DE\u05D5\u05DC \u05DE\u05D5\u05E7\u05D3 \u05D4\u05E9\u05D9\u05E8\u05D5\u05EA: "])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.modalData == null) ? null : _co.modalData.requestId); _ck(_v, 3, 0, currVal_0); }); }
function View_MessagesModalComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "a", [["class", "btn btn--regularBtn btn--blue btn--semiBold next"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goTo("requestsandlicenses") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" \u05DE\u05E2\u05D1\u05E8 \u05DC\u05E1\u05D8\u05D0\u05D8\u05D5\u05E1 \u05D1\u05E7\u05E9\u05D5\u05EA "]))], null, null); }
function View_MessagesModalComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "a", [["class", "btn btn--regularBtn btn--blue btn--semiBold next"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goTo("employmentapplication") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" \u05DC\u05E0\u05E1\u05D5\u05EA \u05E9\u05D5\u05D1 "]))], null, null); }
export function View_MessagesModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 18, "div", [["class", "bgWhite"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-svg", [], null, null, null, i2.View_SvgComponent_0, i2.RenderType_SvgComponent)), i1.ɵdid(2, 114688, null, 0, i3.SvgComponent, [], { svgType: [0, "svgType"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 8, "div", [["class", "modalMsgWrapper"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MessagesModalComponent_1)), i1.ɵdid(5, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MessagesModalComponent_2)), i1.ɵdid(7, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 1, "div", [["class", "innerMsg colorTextGray textContent textContent--semiBold"]], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, [" ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MessagesModalComponent_3)), i1.ɵdid(11, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(12, 0, null, null, 6, "div", [["class", "stepsActions"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MessagesModalComponent_4)), i1.ɵdid(14, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MessagesModalComponent_5)), i1.ɵdid(16, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(17, 0, null, null, 1, "a", [["class", "btn btn--regularBtn btn--blueTextColor btn--semiBold prev"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goTo("home") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" \u05D7\u05D6\u05E8\u05D4 \u05DC\u05D3\u05E3 \u05D4\u05D1\u05D9\u05EA "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.modalData.submitSuccess ? "successIco" : "errorIco"); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.modalData.submitSuccess; _ck(_v, 5, 0, currVal_1); var currVal_2 = !_co.modalData.submitSuccess; _ck(_v, 7, 0, currVal_2); var currVal_4 = _co.modalData.submitSuccess; _ck(_v, 11, 0, currVal_4); var currVal_5 = _co.modalData.submitSuccess; _ck(_v, 14, 0, currVal_5); var currVal_6 = !_co.modalData.submitSuccess; _ck(_v, 16, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = ((_co.modalData == null) ? null : _co.modalData.message); _ck(_v, 9, 0, currVal_3); }); }
export function View_MessagesModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-messages-modal", [], null, null, null, View_MessagesModalComponent_0, RenderType_MessagesModalComponent)), i1.ɵdid(1, 114688, null, 0, i5.MessagesModalComponent, [i6.MatDialogRef, i7.Router, i6.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MessagesModalComponentNgFactory = i1.ɵccf("app-messages-modal", i5.MessagesModalComponent, View_MessagesModalComponent_Host_0, {}, {}, []);
export { MessagesModalComponentNgFactory as MessagesModalComponentNgFactory };
