<p-dialog [rtl]="true" [(visible)]="displayModal" [modal]="true" [responsive]="true" (onHide)="sendStatusModalToParent()"
  [style]="{width: '767px', minWidth: '767px'}" [minY]="70" [baseZIndex]="10000" class="small-dialog">
  <div class="row m-0 w-100 d-flex d-column d-md-row">
    <div class="col-md-4 right-side-dialog d-flex align-items-center justify-content-center">

      <img alt="" [src]='img' />

    </div>
    <div class="col-md-8 left-side-dialog">
      <h2>
        {{title}}
      </h2>

      <p class="text-center text-md-right" [innerHTML]="content"></p>

      <div *ngIf='showContact' class="d-flex flex-column flex-md-row align-items-center mb-5 pb-4">
       <div class="d-flex align-items-center mb-5 m-md-0"> <i class="icon-phone  ml-3 color-gray"></i> <a href="tel:074-7642929" class="color-blue">074-7642929</a></div>

      <span class="d-none d-md-flex">  &nbsp;&nbsp;&nbsp;&nbsp;<span class="color-gray">|</span>&nbsp;&nbsp;&nbsp;&nbsp;</span>

       <div class="d-flex align-items-center"><i class="icon-mail ml-3 color-gray"></i><a href="mailto:taasuka@matpash.gov.il"
          class="color-blue">taasuka@matpash.gov.il</a></div> 

      </div>
      <div class="row justify-content-end">
        <div class="col-md-5 col-6">
          <button type="button" pButton class="ui-button-rounded w-100 button-matpash light-blue-fill d-flex align-items-center  flex-row-reverse justify-content-center" (click)="sendStatusModalToParent()" label="סגור">
            <i class="icon-left-arrow mr-3"></i>
          </button>
        </div>
      </div>
    </div>
  </div>



</p-dialog>