import { Component, OnInit } from '@angular/core';
import { slideInOut } from 'src/app/shared/animations/app.animation';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  animations: [slideInOut()]
})
export class LoginComponent implements OnInit {
  showStep = true;
  constructor() { }

  ngOnInit() {
  }

  moveToStep(event: any) {
    this.showStep = !this.showStep;
  }
}
