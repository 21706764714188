import { Injectable } from '@angular/core';
import { Observable } from 'rxjs-compat/Observable';
import { map } from 'rxjs/operators';
import { MetadataForSystemConstitution } from '../models/metadataForSystemConstitutionModel';
import { SystemConstitutionModel } from '../models/systemConstitutionModel';
import { ApplicationHttpClient } from '../shared/service/ApplicationHttpClient';

@Injectable({
  providedIn: 'root'
})
export class SystemConstitutionService {

  endPoint: string;

  constructor(private http: ApplicationHttpClient) {
    this.endPoint = "api/system_constitution/";
  }

  getSystemConstitution(): Observable<SystemConstitutionModel> {
    return this.http.Get<{ data: SystemConstitutionModel[] }>(this.endPoint).pipe(
      map(res => {
        if (res.data && res.data.length) {
          return res.data[0];
        }
        return {} as SystemConstitutionModel;
      })
    );
  }

  getMetadata(): Observable<MetadataForSystemConstitution> {
    return this.http.Get<{ data: MetadataForSystemConstitution }>(`${this.endPoint}getMetadata`).pipe(map(res => res.data));
  }

  updateOrCreateSystemConstitution(id: string, systenConstitution: SystemConstitutionModel): Observable<{ data: SystemConstitutionModel }> {
    let endPoint = this.endPoint + (id || '');
    return this.http.Put<{ data: SystemConstitutionModel }>(endPoint, systenConstitution);
  }

}
