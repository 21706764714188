import colorText from './colorText'

const employeeApplicationStatusColorByLicenseType = {
  1:{//Israel
    '-1':colorText.RED,
    1: colorText.ORANGE,
    2: colorText.RED,
    3: colorText.RED,
    4: colorText.RED,
    5: colorText.RED,
    6: colorText.RED,
    7: colorText.RED,
    8: colorText.BLACK,
    9: colorText.RED,
    10: colorText.RED
  },
  2:{//Settlement
    '-1':colorText.RED,
    1: colorText.ORANGE,
    2: colorText.RED,
    3: colorText.RED,
    4: colorText.RED,
    5: colorText.RED,
    6: colorText.RED,
    7: colorText.ORANGE,
    8: colorText.BLACK,
    9: colorText.RED,
    10: colorText.RED
  }


}

export default employeeApplicationStatusColorByLicenseType;
