import * as tslib_1 from "tslib";
import { OnInit, } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { Observable, of } from "rxjs";
import { EmptyObservable } from "rxjs/observable/EmptyObservable";
import { debounceTime, filter, map, tap, switchMap, takeUntil } from "rxjs/operators";
import { StepComponentBase } from "../wizard-step-component-base/step-component-base";
import { MatAutocompleteTrigger } from "@angular/material";
import { WizardModalComponent } from "../../shared/wizard-modal/wizard-modal.component";
import { EmploymentBureau, FieldsRequest, GeneralModalData, RequestData, Settlement, WorkingHour } from "../../../models/interfaces";
import { GeneralModalComponent } from "../../../shared/components/general-modal/general-modal.component";
import regionsTypes from "src/app/shared/util/regionTypes";
var RequestDetailsStepComponent = /** @class */ (function (_super) {
    tslib_1.__extends(RequestDetailsStepComponent, _super);
    function RequestDetailsStepComponent(stepsPagesService, dialog, formService, wizradUserDataService, http, buildingPermitService, fieldService) {
        var _this = _super.call(this, stepsPagesService, dialog) || this;
        _this.formService = formService;
        _this.wizradUserDataService = wizradUserDataService;
        _this.http = http;
        _this.buildingPermitService = buildingPermitService;
        _this.fieldService = fieldService;
        _this.FormGroupRef = null;
        _this.transactionGroupRef = null;
        _this.settlementsArrayRef = null;
        _this.isLoading = false;
        _this.wantedWorkplaceSearch = new FormControl();
        _this.fields = [];
        _this.workingHours = [];
        _this.employmentBureaus = [];
        _this.downloadDocuments = null;
        _this.showNotSelectedWarning = false;
        _this.showNoResultError = false;
        _this.showBuildingPermitError = false;
        _this.showWorkingHoursFileWarning = false;
        _this.submitted = false;
        _this.isIsraelRegion = true;
        return _this;
    }
    RequestDetailsStepComponent.prototype.ngOnInit = function () {
        var _this = this;
        _super.prototype.ngOnInit.call(this);
        this.FormGroupRef = this.formService.getFormGroupRefByName("requestDetails");
        this.transactionGroupRef = this.formService.getFormGroupRefByName('transactionArea');
        this.wizradUserDataService.requestData.pipe(takeUntil(this.componentDestroyed)).subscribe(function (requestData) {
            // on initializing the app
            _this.isIsraelRegion = _this.transactionGroupRef.get('region').value == regionsTypes.ISRAEL;
            // call func for all initialization
            _this.initUpdate(requestData);
            // listen to region valie changes - 1 for israel, 2 for settelments
            _this.transactionGroupRef.get('region').valueChanges.subscribe(function (controlValue) {
                // remove error for unselected settelemts autocomplete
                _this.showNotSelectedWarning = false;
                // reset submit
                _this.submitted = false;
                // bool for israel selected or settelments
                _this.isIsraelRegion = controlValue == regionsTypes.ISRAEL;
                // call func for all initialization
                _this.initUpdate(requestData);
            });
            _this.downloadDocuments = requestData.downloadDocuments;
            //reset steps  -> build steps
        });
        this.wantedWorkplaceSearch.valueChanges
            .pipe(debounceTime(500), filter(function (val) { return !!val; }), map(function (value) { return (typeof value === "string" ? value : ""); }), filter(function (value) { return value.length > 1; }), 
        // tap((value) => this.deletePrevValuesAndClosePanel(value)),
        tap(function () { return (_this.isLoading = true); }), switchMap(function (value) { return _this.wizradUserDataService.getSettlements(value); }), takeUntil(this.componentDestroyed))
            .subscribe(function (filteredSettlements) {
            var filteredSettlementsAsObservable = of(filteredSettlements);
            _this.wantedWorkPlacesOptions = filteredSettlementsAsObservable;
            _this.showNoResultError =
                filteredSettlements.length === 0;
            _this.showNotSelectedWarning = false;
            _this.isLoading = false;
        }, function (err) {
            _this.wantedWorkPlacesOptions = null;
            _this.isLoading = false;
        });
    };
    // initalization and on region update
    RequestDetailsStepComponent.prototype.initUpdate = function (requestData) {
        if (this.isIsraelRegion) {
            // disable field if israel region
            this.FormGroupRef.get("working_hours").disable();
        }
        // in case of selected other hour in the working_hours DD and required to upload files -> remove the control for every change
        this.FormGroupRef.removeControl("files");
        this.workingHours = requestData.workingHour;
        //call for filterd fields;
        this.fields = this.getFilterdFields(requestData.fields);
        //sort working hours by isDefault value
        this.workingHours.sort(function (a, b) { return (b.isDefault ? 1 : -1); });
        if (this.isIsraelRegion) {
            if (this.FormGroupRef.get("fields").value != null) {
                this.FormGroupRef.get("working_hours").setValue(this.workingHours[0]);
            }
            else {
                this.FormGroupRef.get("working_hours").setValue(null);
            }
        }
        else if (!this.isIsraelRegion) {
            // if settelments region
            this.FormGroupRef.get("working_hours").setValue(this.workingHours[0]);
        }
        this.settlementsArrayRef = this.FormGroupRef.get("settlements");
    };
    RequestDetailsStepComponent.prototype.getFilterdFields = function (fields) {
        var filterFields = [];
        // filter fields by region
        if (this.isIsraelRegion) {
            filterFields = fields.filter(function (field) { return field.licenseType == regionsTypes.ISRAEL; });
            // remove valiators for settelments fields
            this.requestDetailsFormValidators();
        }
        else {
            filterFields = fields.filter(function (field) { return field.licenseType == regionsTypes.SETTELMENTS; });
            // add valiators for settelments fields
            this.resetRequestGroupValidators();
        }
        if (filterFields.length === 1) {
            this.setDefaultField(filterFields[0]);
        }
        return filterFields;
    };
    RequestDetailsStepComponent.prototype.getFilteredHours = function (workingHours) {
        var _this = this;
        // get filtered working hours by secondary type
        this.workingHours = workingHours.filter(function (workHour) { return workHour.secondaryType.includes(_this.FormGroupRef.get('secondaryType').value.secondryType); });
        this.FormGroupRef.get("working_hours").setValue(this.workingHours[0]);
    };
    RequestDetailsStepComponent.prototype.requestDetailsFormValidators = function () {
        var _this = this;
        if (this.isIsraelRegion) {
            Object.keys(this.FormGroupRef.controls).forEach(function (key) {
                var control = _this.FormGroupRef.controls[key];
                if (key != 'fields' &&
                    key != 'working_hours' &&
                    key != 'loging') {
                    control.clearValidators();
                }
                else {
                    control.setValidators([Validators.required]);
                }
                control.updateValueAndValidity({
                    onlySelf: true
                });
            });
        }
    };
    RequestDetailsStepComponent.prototype.resetRequestGroupValidators = function () {
        var _this = this;
        if (this.settlementsArrayRef) {
            this.settlementsArrayRef.setValidators([Validators.required, Validators.maxLength(4)]);
            this.settlementsArrayRef.controls.forEach(function (settelment, index) {
                _this.settlementsArrayRef.removeAt(index);
            });
            this.settlementsArrayRef.updateValueAndValidity({ onlySelf: true });
        }
        this.FormGroupRef.get("loging").clearValidators();
        this.FormGroupRef.get("loging").updateValueAndValidity({ onlySelf: true });
    };
    RequestDetailsStepComponent.prototype.setDefaultField = function (field) {
        this.selectedField = field;
        this.FormGroupRef.get("fields").setValue(field);
        this.setLicenseDurationValidations();
        this.checkForRenovation();
        if (this.isIsraelRegion) {
            this.FormGroupRef.get('secondaryType').setValue(field.secondaryType);
            this.getFilteredHours(this.workingHours);
            this.enableHoursField();
        }
        // this.checkForBuildingPermitModal();
    };
    RequestDetailsStepComponent.prototype.displayFn = function (value) {
        return value ? value.name : undefined;
    };
    RequestDetailsStepComponent.prototype.addWorkPlaces = function (evt) {
        var selectedSettlement = evt.option.value;
        var newSettlementsControl = this.formService.createControl();
        newSettlementsControl.setValue(selectedSettlement);
        if (!this.settlementsArrayRef.controls.find(function (workPlaceControl) {
            var cureentSettlement = workPlaceControl.value;
            return cureentSettlement.name === selectedSettlement.name;
        })) {
            this.settlementsArrayRef.push(newSettlementsControl);
            this.addEmploymentBureaus(selectedSettlement);
        }
        if (this.employmentBureaus.length === 1) {
            this.FormGroupRef.get("employment_bureau").setValue(this.employmentBureaus[0]);
        }
        this.clearWantedWorkplaceSearchData();
        this.wantedWorkPlacesOptions = null;
        this.wantedWorkplaceSearch.setValue("");
    };
    RequestDetailsStepComponent.prototype.clearWantedWorkplaceSearchData = function () {
        this.wantedWorkplaceSearch.setValue("");
        this.wantedWorkPlacesOptions = new EmptyObservable();
    };
    RequestDetailsStepComponent.prototype.addEmploymentBureaus = function (selectedsettlement) {
        if (!this.employmentBureaus.find(function (currentBureau) {
            return currentBureau.name === selectedsettlement.employmentBureau.name;
        })) {
            this.employmentBureaus.push(selectedsettlement.employmentBureau);
        }
    };
    RequestDetailsStepComponent.prototype.deleteEmploymentBureaus = function (selectedsettlement) {
        var indexOfBureau = this.employmentBureaus.findIndex(function (currentBureau, index) {
            return currentBureau.name === selectedsettlement.employmentBureau.name;
        });
        var countedBureau = this.settlementsArrayRef.controls.reduce(function (acc, currentSettlement) {
            var curSettlement = currentSettlement.value;
            if (curSettlement.employmentBureau.name ===
                selectedsettlement.employmentBureau.name) {
                acc++;
            }
            return acc;
        }, 0);
        if (indexOfBureau > -1 && countedBureau < 1) {
            this.employmentBureaus.splice(indexOfBureau, 1);
        }
    };
    RequestDetailsStepComponent.prototype.checkField = function (evt) {
        this.selectedField = evt.value;
        this.showBuildingPermitError = false;
        // if israel region - calculate secondary type by selected field
        if (this.isIsraelRegion) {
            this.FormGroupRef.get('secondaryType').setValue(this.selectedField.secondaryType);
            this.getFilteredHours(this.workingHours);
        }
        this.setLicenseDurationValidations();
        this.setEmploymentBureauValidation();
        this.enableHoursField();
        this.checkForRenovation();
    };
    RequestDetailsStepComponent.prototype.enableHoursField = function () {
        this.FormGroupRef.get('working_hours').enable();
    };
    RequestDetailsStepComponent.prototype.checkForRenovation = function () {
        var _this = this;
        if (this.selectedField.code === 4 && !this.isIsraelRegion) {
            this.FormGroupRef.addControl("renovating", this.formService.createControl(null, [Validators.required]));
            if (!this.selectedField.employmentLimitation) {
                return;
            }
            this.FormGroupRef.get("renovating").valueChanges.pipe(takeUntil(this.componentDestroyed)).subscribe(function (isRenovating) {
                if (!isRenovating) {
                    _this.checkForBuildingPermitModal();
                }
                else {
                    //The Order Of This Code Is Important, The Reset, Makes The Field Service To Update The Sum!
                    if (_this.buildingPermitService.hasBuildingPermitUploaded()) {
                        _this.buildingPermitService.getBuildingPermitRef().reset();
                    }
                    _this.showBuildingPermitError = false;
                    _this.validateZeroLimitation();
                    // this.checkForOpenConfirmRenovatingChange();
                }
            });
        }
        else {
            this.FormGroupRef.removeControl("renovating");
        }
    };
    RequestDetailsStepComponent.prototype.checkForBuildingPermitModal = function () {
        if (this.selectedField.code === 4 && this.buildingPermitService.allowBuildingPermitUpload()) {
            this.openBuildingPermitModal();
        }
    };
    //Keep This Code In Comment In Case That They Want The Confirm Modal Again
    // checkForOpenConfirmRenovatingChange() {
    //   if(this.buildingPermitService.hasBuildingPermitUploaded()){
    //   const data:ConfirmModalData ={
    //     header:"שים לב",
    //     message:"שינוי זה יגרום לאיפוס המידע שמילאת עד כה בכל שלבי הבקשה. "
    //   };
    //   const dialogRef = this.openModal(ConfirmModalComponent,data,{panelClass: "generalModal"});
    //   dialogRef.afterClosed().subscribe(confirm => {
    //     if (confirm) {
    //       this.showBuildingPermitError = false;
    //       this.validateZeroLimitation()
    //       this.buildingPermitService.getBuildingPermitRef().reset();
    //     }
    //   });
    //   }else {
    //     this.showBuildingPermitError = false;
    //     this.validateZeroLimitation()
    //   }
    // }
    RequestDetailsStepComponent.prototype.setLicenseDurationValidations = function () {
        var licenseDurationRef = this.FormGroupRef.get("license_duration");
        licenseDurationRef.clearValidators();
        if (!this.isIsraelRegion) {
            licenseDurationRef.setValidators([
                Validators.required,
                Validators.min(1),
                Validators.pattern("^[0-9]*$"),
                Validators.max(+this.selectedField.licenseLimitation)
            ]);
            licenseDurationRef.updateValueAndValidity({
                onlySelf: true
            });
        }
    };
    RequestDetailsStepComponent.prototype.setEmploymentBureauValidation = function () {
        var employmentBureauRef = this.FormGroupRef.get("employment_bureau");
        employmentBureauRef.clearValidators();
        if (!this.isIsraelRegion) {
            employmentBureauRef.setValidators([
                Validators.required
            ]);
            employmentBureauRef.updateValueAndValidity({
                onlySelf: true
            });
        }
    };
    RequestDetailsStepComponent.prototype.openBuildingPermitModal = function () {
        var _this = this;
        var filedDownloadDocuments = this.buildingPermitService.getBuildingPermitDownloadDocument();
        var data = {
            selectedField: this.selectedField,
            downloadDocument: filedDownloadDocuments,
            documentRef: this.buildingPermitService.getBuildingPermitRef()
        };
        var dialogRef = this.openModal(WizardModalComponent, data, { disableClose: false });
        dialogRef.afterClosed().subscribe(function (result) {
            var hasUploaded = _this.buildingPermitService.hasBuildingPermitUploaded();
            _this.showBuildingPermitError = !hasUploaded;
        });
    };
    RequestDetailsStepComponent.prototype.openZeroLimitationModal = function () {
        var data = {
            title: "שים לב",
            errorMessage: "לא ניתן להמשיך בתהליך מכיוון שלא קיימת מכסה פנויה להעסקה"
        };
        this.openModal(GeneralModalComponent, data, { panelClass: "generalModal" });
    };
    RequestDetailsStepComponent.prototype.checkWorkingHoures = function (evt) {
        this.showWorkingHoursFileWarning = false;
        var selectedWorkingHour = evt.value;
        if (this.isIsraelRegion) {
            return;
        }
        if (!selectedWorkingHour.isDefault) {
            this.FormGroupRef.addControl("files", this.formService.createControl(null, [Validators.required]));
        }
        else {
            this.FormGroupRef.removeControl("files");
        }
    };
    RequestDetailsStepComponent.prototype.setRenovatingValue = function (evt) {
        this.FormGroupRef.get("renovating").setValue(evt.value);
    };
    RequestDetailsStepComponent.prototype.setLogingValue = function (evt) {
        this.FormGroupRef.get("loging").setValue(+evt.value);
    };
    RequestDetailsStepComponent.prototype.deleteSettlement = function (index, settlement) {
        this.settlementsArrayRef.removeAt(index);
        this.deleteEmploymentBureaus(settlement);
        if (this.employmentBureaus.length === 1) {
            this.FormGroupRef.get("employment_bureau").setValue(this.employmentBureaus[0]);
        }
    };
    RequestDetailsStepComponent.prototype.popOverClicked = function (evt, popBtn) {
        popBtn.clicked = !popBtn.clicked;
    };
    RequestDetailsStepComponent.prototype.panelClosed = function (evt) {
        this.validateWantedWorkplace();
    };
    RequestDetailsStepComponent.prototype.validateWantedWorkplace = function () {
        this.showNotSelectedWarning = !this.settlementsArrayRef.controls.length;
    };
    RequestDetailsStepComponent.prototype.uploadFileWorkingHoursFile = function () {
        this.showWorkingHoursFileWarning = false;
    };
    RequestDetailsStepComponent.prototype.validateWorkingHoursUploadFile = function () {
        var working_hours_file_ref = this.FormGroupRef.get('files');
        var isValid = working_hours_file_ref ? working_hours_file_ref.valid : true;
        this.showWorkingHoursFileWarning = !isValid;
        return isValid;
    };
    RequestDetailsStepComponent.prototype.isBuildingPermitNeeded = function () {
        if (this.wizradUserDataService.initialHasPermit || !this.selectedField) {
            return false;
        }
        return this.selectedField.code === 4 && this.selectedField.employmentLimitation
            && (!this.FormGroupRef.get("renovating") || this.FormGroupRef.get("renovating").value === false)
            && this.selectedField.currentEmployed >= this.selectedField.employmentLimitation;
    };
    RequestDetailsStepComponent.prototype.validatePermit = function () {
        //Has Uploaded BuildingPermit
        var hasUploaded = this.buildingPermitService.hasBuildingPermitUploaded();
        this.showBuildingPermitError = !hasUploaded;
        return hasUploaded;
    };
    RequestDetailsStepComponent.prototype.validateZeroLimitation = function () {
        if (!this.fieldService.hasEmploymentLimitation()) {
            return true;
        }
        if (this.fieldService.getAvailableEmployeeSum() > 0) {
            return true;
        }
        this.openZeroLimitationModal();
        return false;
    };
    RequestDetailsStepComponent.prototype.submit = function () {
        //Ugly Code Due To The Fact The Form Validation Is Made By The Mat-Stepper
        if (!this.FormGroupRef.valid) {
            this.validateWantedWorkplace();
            this.validateWorkingHoursUploadFile();
            this.validateZeroLimitation();
            if (this.isBuildingPermitNeeded()) {
                this.validatePermit();
            }
            _super.prototype.submit.call(this); // To Trigger The Mat-Stepper Validation
        }
        else {
            if ((!this.isBuildingPermitNeeded() || (this.isBuildingPermitNeeded() && this.validatePermit())) && this.validateZeroLimitation()) {
                _super.prototype.submit.call(this);
            }
        }
        this.submitted = true;
    };
    return RequestDetailsStepComponent;
}(StepComponentBase));
export { RequestDetailsStepComponent };
