/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./general-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/dialog";
import * as i3 from "./general-modal.component";
var styles_GeneralModalComponent = [i0.styles];
var RenderType_GeneralModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GeneralModalComponent, data: {} });
export { RenderType_GeneralModalComponent as RenderType_GeneralModalComponent };
export function View_GeneralModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "statusDocumentsModalWrapper generalModalWrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "button", [["class", "closeBtn"], ["mat-button", ""], ["mat-dialog-close", ""]], [[1, "aria-label", 0], [1, "type", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).dialogRef.close(i1.ɵnov(_v, 2).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 606208, null, 0, i2.MatDialogClose, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], { dialogResult: [0, "dialogResult"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h2", [["class", "subTitle subTitle--semiBold subTitle--blueTextColor"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 0, "h3", [["class", "textContent textContent--bold colorTextRed"]], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "p", [["class", "textContent textContent--regular colorTextGray"]], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 4, "mat-dialog-actions", [["class", "modalConfirm stepsBtns mat-dialog-actions"]], null, null, null, null, null)), i1.ɵdid(8, 16384, null, 0, i2.MatDialogActions, [], null, null), (_l()(), i1.ɵeld(9, 0, null, null, 2, "button", [["class", "btn btn--blue btn--mobileWidthBtn btn--desktopWidthBtn "], ["mat-button", ""], ["mat-dialog-close", ""]], [[1, "aria-label", 0], [1, "type", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 10).dialogRef.close(i1.ɵnov(_v, 10).dialogResult) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.closeModal() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(10, 606208, null, 0, i2.MatDialogClose, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], { dialogResult: [0, "dialogResult"] }, null), (_l()(), i1.ɵted(-1, null, ["\u05E1\u05D2\u05D9\u05E8\u05D4 "]))], function (_ck, _v) { var currVal_2 = ""; _ck(_v, 2, 0, currVal_2); var currVal_8 = ""; _ck(_v, 10, 0, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (i1.ɵnov(_v, 2).ariaLabel || null); var currVal_1 = i1.ɵnov(_v, 2).type; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_3 = _co.title; _ck(_v, 4, 0, currVal_3); var currVal_4 = _co.errorMessage; _ck(_v, 5, 0, currVal_4); var currVal_5 = _co.text; _ck(_v, 6, 0, currVal_5); var currVal_6 = (i1.ɵnov(_v, 10).ariaLabel || null); var currVal_7 = i1.ɵnov(_v, 10).type; _ck(_v, 9, 0, currVal_6, currVal_7); }); }
export function View_GeneralModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-general-modal", [], null, null, null, View_GeneralModalComponent_0, RenderType_GeneralModalComponent)), i1.ɵdid(1, 114688, null, 0, i3.GeneralModalComponent, [i2.MatDialogRef, i2.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GeneralModalComponentNgFactory = i1.ɵccf("app-general-modal", i3.GeneralModalComponent, View_GeneralModalComponent_Host_0, {}, {}, []);
export { GeneralModalComponentNgFactory as GeneralModalComponentNgFactory };
