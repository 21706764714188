import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ComponentFactoryResolver,
  Output,
  EventEmitter
} from '@angular/core';
import { PageDirective } from '../page-directive/page.directive';
import { StepPagesService } from '../service/step-pages.service';
import { StepComponentBase } from '../steps/wizard-step-component-base/step-component-base';

@Component({
  selector: 'app-step-page-wrapper',
  template: `
    <ng-template pageDirective></ng-template>
  `,
  styles: []
})
export class StepPageWrapperComponent implements OnInit {
  @ViewChild(PageDirective, {static: true}) placeholder: PageDirective;
  @Output() onGoForward: EventEmitter<any> = new EventEmitter<any>();
  @Output() onGoBack: EventEmitter<any> = new EventEmitter<any>();
  @Output() onFormReset: EventEmitter<any> = new EventEmitter<any>();
  @Input() item?: any;
  instance: StepComponentBase;

  constructor(private componentFactoryResolver: ComponentFactoryResolver, private stepsPagesService: StepPagesService) {}

  ngOnInit() {
    if (this.item === undefined) {
      console.error('Item undefined');
      return;
    }
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
      this.item
    );

    const viewContainerRef = this.placeholder.viewContainerRef;
    viewContainerRef.clear();
    const componentRef = viewContainerRef.createComponent(componentFactory);
    this.instance = componentRef.instance as StepComponentBase;

    this.instance.currentStep = this.item;
    this.instance.onStepSubmit.subscribe(
      data => {
        this.onGoForward.emit(this.item);
      }
    );

    this.instance.onStepBack.subscribe(
      data => {
        this.onGoBack.emit(this.item);
      }
    );

    this.instance.onFormReset.subscribe(
      data =>{
      this.onFormReset.emit()
    })

  }
}
