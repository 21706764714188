import { PipeTransform } from '@angular/core';
var EmailMaskingPipe = /** @class */ (function () {
    function EmailMaskingPipe() {
    }
    EmailMaskingPipe.prototype.transform = function (value) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        return value.replace(/^(.)(.*)(.@.*)$/, function (_, a, b, c) { return a + b.replace(/./g, '*') + c; });
    };
    return EmailMaskingPipe;
}());
export { EmailMaskingPipe };
