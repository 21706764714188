
<div class="wrap-home-page">

    <app-updatesSlider></app-updatesSlider>
 
    <div class="row w-100 m-0">
      <app-license-application-status class="appStatus col-md-6"></app-license-application-status>
      <app-current-employee-status class="appStatus col-md-6"></app-current-employee-status>
    </div>
 </div>
