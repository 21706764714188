import {
  OnInit,
  Input,
  EventEmitter,
  Output,
  OnDestroy
} from "@angular/core";
import { StepPagesService } from "../../service/step-pages.service";
import { MatDialog, MatDialogConfig } from '@angular/material';
import {takeUntil} from "rxjs/operators";
import {BaseComponent} from "../../../shared/components/base-component/base-component";
import {StepPageObject} from "../../../models/interfaces";

export class StepComponentBase extends BaseComponent implements OnInit, OnDestroy {
  @Input() currentStep: StepPageObject; //Consider doing currentStepIndex as Observable due to the option of dynamic steps
  @Output() onStepSubmit: EventEmitter<any> = new EventEmitter();
  @Output() onStepBack: EventEmitter<any> = new EventEmitter();
  @Output() onFormReset:EventEmitter<any> = new EventEmitter();
  steps: StepPageObject[];
  currentStepIndex: number;

  constructor(protected stepsPagesService: StepPagesService, protected dialog: MatDialog) {
    super();
  }

  ngOnInit(): void {
    this.stepsPagesService.getSteps().pipe(takeUntil(this.componentDestroyed)).subscribe(steps => {
      this.steps = steps;
      this.setCurrentStepIndex(this.currentStep);
    });
  }

  setCurrentStepIndex(step) {
    this.currentStepIndex = this.stepsPagesService.getStepIndex(step);
  }

  submit() {
    this.goToNextStep();
  }

  openModal(component: any , modalData?: any , options?: MatDialogConfig) {
   return this.dialog.open(component, {
      disableClose: options.disableClose || false,
      backdropClass: '',
      panelClass: options.panelClass || 'modalPanel',
      role: 'dialog',
      ariaDescribedBy: '',
      ariaLabel: '',
      direction: 'rtl',
      autoFocus: true,
      maxWidth: options.maxWidth || 640,
      restoreFocus: true,
      data: modalData
    });
  }

  goToNextStep() {
    this.onStepSubmit.emit();
  }
  goToBackStep() {
    this.onStepBack.emit();
  }

  resetForm(){
    this.onFormReset.emit();
  }
  hasNext(): boolean {
    if (this.steps && this.steps.length) {
      return this.currentStepIndex !== this.steps.length - 1;
    }

    return false;
  }

  hasBack(): boolean {
    return this.currentStepIndex !== 0;
  }
}
