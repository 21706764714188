import * as tslib_1 from "tslib";
import { OnInit, ElementRef, OnDestroy } from "@angular/core";
import { SelectionModel } from "@angular/cdk/collections";
import 'jspdf-autotable';
import { Pdf } from 'src/app/models/pdf';
import { CurrentEmployee, CurrentEmployeesData, CurrentEmployeeExtended, CancelReason, CancelLicenseResponse, GeneralModalData, User, InvalidRenewLicense, RenewLicense, RenewLicenseModel } from "../../../../models/interfaces";
import colorText from "../../../../shared/util/colorText";
import { MatDialogConfig } from "@angular/material";
import { Router, NavigationExtras } from "@angular/router";
import applicationtStatusTypes from "src/app/shared/util/applicationtStatusTypes";
import LicenseCancelStatusTypes from "../../../../shared/util/LicenseCancelStatusTypes";
import { LicensesRenewModalComponent } from '../../modals/emoloyee-actions/licenses-renew-modal.component';
import { MessagesModalComponent } from 'src/app/wizard/shared/messages-modal/messages-modal.component';
// import { EmailService } from "src/app/shared/service/email.service";
var AllCurrentEmployeesStatusComponent = /** @class */ (function () {
    // @ViewChild(MatSort, { static: false }) set content(content: ElementRef) {
    //   this.sort = content;
    //   if (this.sort) {
    //     this.dataSource.sort = this.sort;
    //   }
    // }
    function AllCurrentEmployeesStatusComponent(employeeService, printService, dialogService, datePipe, userDataService, router, emailService, _ngZone, licenseService) {
        this.employeeService = employeeService;
        this.printService = printService;
        this.dialogService = dialogService;
        this.datePipe = datePipe;
        this.userDataService = userDataService;
        this.router = router;
        this.emailService = emailService;
        this._ngZone = _ngZone;
        this.licenseService = licenseService;
        this.isMainDropdownOpen = false;
        this.selection = new SelectionModel(true, []);
        this.dialogConfig = { panelClass: "generalModal", };
        this.isRequestSent = false;
        this.pdf = new Pdf();
        this.config = {
            wheelSpeed: 0.2
        };
        this.displayedColumns = [
            { header: 'שם העובד', field: 'fullName' },
            { header: 'מספר זהות', field: 'id' },
            { header: 'מספר רשיון', field: 'licenseId' },
            { header: 'ענף', field: 'field.name' },
            { header: 'תום תוקף', field: 'licenseExpiredDate' },
            { header: 'מיקום', field: 'settlements' },
            { header: 'לינה בישראל', field: 'isLodgingInIsrael' }
        ];
        this.cancelReasons = [];
        this.newLicenseArr = [];
        this.checked = false;
        this.selectedCities = [];
        this.showModal = false;
        this.listExcel = new Array();
        this.alredyOpen = false;
        this.cancelLicence = false;
        this.dialogData = {};
        /** filter box */
        this.toggleDropdownFilter = function (evt, filterBtn) {
            filterBtn.isMainDropdownOpen = !filterBtn.isMainDropdownOpen;
        };
        this.date = new Date();
    }
    AllCurrentEmployeesStatusComponent.prototype.ngOnInit = function () {
        var _this = this;
        // InitData
        this.initAll();
        this.employeeService.getCancelReasons().subscribe(function (reasons) {
            _this.cancelReasons = reasons;
        });
        this.userDataService.getUser().subscribe(function (user) {
            _this.user = user;
        });
        this.userDataService.getUser1().subscribe(function (user) {
            _this.user1 = user.user;
        });
        //The Order In The Array Is Important
        this.invalidRenewFunctions = [this.populateRenewInvalidDatesEmployees, this.populateRenewEmployees, this.populatInvalidMagneticCard];
    };
    AllCurrentEmployeesStatusComponent.prototype.ngAfterViewInit = function () {
        this.initAll();
    };
    AllCurrentEmployeesStatusComponent.prototype.initAll = function (hardReload) {
        var _this = this;
        if (hardReload === void 0) { hardReload = false; }
        this.employeeService
            .getCurrentEmployeesData(hardReload)
            .subscribe(function (currentEmployeesData) {
            _this.currentEmployeesData = currentEmployeesData;
            _this.initTableData();
            _this.initPrintData();
            _this.subscribeToRenew();
        });
    };
    AllCurrentEmployeesStatusComponent.prototype.getRowColor = function (row) {
        return this.licenseService.getStatusColor(row.applicationStatus ? row.applicationStatus.code : null);
    };
    AllCurrentEmployeesStatusComponent.prototype.subscribeToRenew = function () {
        var _this = this;
        this.employeeService.renewLicenseEmployees.asObservable().subscribe(function (data) {
            if (data) {
                _this.currentEmployeesData.employees.filter(function (employee) {
                    if (data.licenseIds.includes(employee.licenseId) && !employee.isRenew) {
                        _this.newLicenseArr.push(employee);
                    }
                });
                _this.newLicenseArr.forEach(function (newLicense) {
                    newLicense.isRenew = true;
                    newLicense.renewalApplicationId = data.applicationID;
                    _this.selection.deselect(newLicense);
                });
                _this.isRequestSent = true;
            }
        });
    };
    Object.defineProperty(AllCurrentEmployeesStatusComponent.prototype, "allowRenewDays", {
        get: function () {
            return this.employeeService.getAllowRenewDays();
        },
        enumerable: true,
        configurable: true
    });
    AllCurrentEmployeesStatusComponent.prototype.initTableData = function () {
        var _this = this;
        this.dataSource = this.currentEmployeesData.employees;
        this.employeeService.renewEmployee.subscribe(function (licenseId) {
            if (licenseId) {
                _this.selection.select(_this.currentEmployeesData.employees.filter(function (employee) { return employee.licenseId == licenseId; })[0]);
            }
        });
        if (this.currentEmployeesData.employees.length) {
            this.printService.showPrintButton();
            // this.emailService.showEmailButton()
        }
        // Init Sort Functions
        // this.dataSource.sortingDataAccessor = this.getPropertyByPath;
    };
    AllCurrentEmployeesStatusComponent.prototype.getWarningClass = function (employee) {
        return employee.daysLeftToExpire <= this.allowRenewDays ? colorText.RED + " tableDataInside" : "";
    };
    AllCurrentEmployeesStatusComponent.prototype.initPrintData = function () {
        if (this.dataSource) {
            var table = [];
            for (var i = 1; i < this.dataSource.length; i++) {
                for (var j = 0; j < this.displayedColumns.length; j++)
                    table[i] = this.dataSource[j];
            }
        }
        this.excel = this.exportExcel();
        this.printService.insertHTMLdata("currentEmployees", "employeesTable", this.content, this.excel);
        this.printService.setCurrentRawData("currentEmployees");
        // if (this.currentEmployeesData.employees.length) {
        //   this.printService.showPrintButton();
        //   this.emailService.showEmailButton()
        // }
    };
    AllCurrentEmployeesStatusComponent.prototype.getPropertyByPath = function (data, pathString) {
        var value = pathString.split(".").reduce(function (o, i) { return o[i]; }, data);
        if (Array.isArray(value)) {
            return value[0].name;
        }
        return value;
    };
    AllCurrentEmployeesStatusComponent.prototype.getLicenseFile = function (e) {
        var _this = this;
        if (!this.selection.selected.length || this.selection.selected.length !== 1) {
            return;
        }
        var employee = this.selection.selected[0];
        this.employeeService.getEmployeeLicenseUrl(employee.licenseId)
            .subscribe(function (fileURL) {
            // fileURL.save('table.pdf');
            window.open(fileURL, "_blank");
        }, function (error) {
            if (error.status === 401) {
                return;
            }
            var title = "תקלה...";
            var text = "לא ניתן להציג את הרשיון הנדרש";
            if (error.status === 404) {
                title = "שים לב";
                text = "לא ניתן לצפות ברשיון זה מכיוון שהוא איננו דיגיטלי";
            }
            _this.title = title;
            _this.text = text;
            _this.errorMessage = '';
            _this.showModal = true;
        });
    };
    /** The label for the checkbox on the passed row */
    AllCurrentEmployeesStatusComponent.prototype.checkboxLabel = function (row) {
        if (!row) {
            return (this.isAllSelected() ? "select" : "deselect") + " all";
        }
        return (this.selection.isSelected(row) ? "deselect" : "select") + " row " + (row.id + 1);
    };
    /** Selects all rows if they are not all selected; otherwise clear selection. */
    AllCurrentEmployeesStatusComponent.prototype.masterToggle = function () {
        var _this = this;
        this.isAllSelected()
            ? this.selection.clear()
            : this.dataSource.forEach(function (row) { return _this.selection.select(row); });
    };
    /** Whether the number of selected elements matches the total number of rows. */
    AllCurrentEmployeesStatusComponent.prototype.isAllSelected = function () {
        var numSelected = this.selection.selected.length;
        var numRows = this.dataSource.length;
        return numSelected === numRows;
    };
    AllCurrentEmployeesStatusComponent.prototype.othetEmployee = function () { };
    AllCurrentEmployeesStatusComponent.prototype.getElementColor = function (element) {
        return element.daysLeftToExpire <= this.allowRenewDays
            ? colorText.RED
            : colorText.GREY;
    };
    AllCurrentEmployeesStatusComponent.prototype.amountOfSelectdEmployees = function () {
        if (this.selection.selected.length === 1) {
            return "1 עובד מסומן ";
        }
        else {
            return this.selection.selected.length + " \u05E2\u05D5\u05D1\u05D3\u05D9\u05DD \u05DE\u05E1\u05D5\u05DE\u05E0\u05D9\u05DD";
        }
    };
    AllCurrentEmployeesStatusComponent.prototype.clear = function () {
        this.selectedEmployee = [];
    };
    AllCurrentEmployeesStatusComponent.prototype.openCancelLicensesModal = function () {
        if (!this.selection.selected) {
            return;
        }
        var dialogData = {
            cancelReasons: this.cancelReasons,
            validEmployees: this.generateEmployeesData()
        };
        this.dialogData = dialogData;
        this.cancelLicence = true;
    };
    AllCurrentEmployeesStatusComponent.prototype.getStatusModal = function (status) {
        this.cancelLicence = status.cancelLicence;
        if (status.close == 0) {
            var users = this.selection.selected.map(function (user) {
                return user;
            });
            var data = {
                employees: users,
                cancelLicenseReason: status.data
            };
            this.handleCancel(data);
        }
    };
    AllCurrentEmployeesStatusComponent.prototype.handleCancel = function (users) {
        var _this = this;
        this.employeeService.cancelEmployeesLicense(users).subscribe(function (cancelLicenseResponse) {
            _this.clearData();
            _this.initAll(true);
            if (cancelLicenseResponse.some(function (clr) { return clr.cancelStatus.code === LicenseCancelStatusTypes.FAILURE; })) {
                _this.openFailedLicenseCancelModal(cancelLicenseResponse);
            }
        });
    };
    AllCurrentEmployeesStatusComponent.prototype.openFailedLicenseCancelModal = function (cancelLicenseResponse) {
        var modalData = {
            title: "ביטול רשיון נכשל",
            text: this.buildFailedCancelLicenseHtml(cancelLicenseResponse),
            errorMessage: "ביטול הרשיונות הנ\"ל נכשל:"
        };
        this.title = modalData.title;
        this.text = modalData.text;
        this.errorMessage = modalData.errorMessage;
        this.showModal = true;
        // this.dialogService.openModal(GeneralModalComponent, modalData, this.dialogConfig)
    };
    AllCurrentEmployeesStatusComponent.prototype.buildFailedCancelLicenseHtml = function (failedCancelLicenses) {
        var rawHtml = "<p>";
        failedCancelLicenses.forEach(function (fl) {
            rawHtml += "<div class=\"textContent textContent--bold\"> " + fl.licenseId + " </div>";
        });
        rawHtml += "</p>";
        return rawHtml;
    };
    AllCurrentEmployeesStatusComponent.prototype.renewLicenses = function () {
        if (!this.selection.selected) {
            return;
        }
        var hasIsLicenseInIsraelForRenew = this.selection.selected.some(function (e) { return e.isLicenseInIsrael; });
        var hasMixedSubFields = this.selection.selected.some(function (val, i, arr) { return val.field.subCode !== arr[0].field.subCode; });
        if (hasMixedSubFields || hasIsLicenseInIsraelForRenew) {
            var modalData = {
                title: "חידוש רשיון",
                text: hasMixedSubFields ? "ניתן לחדש רישיון לעובדים רק כאשר העובדים שנבחרו עובדים באותו סקטור" : "",
                errorMessage: hasMixedSubFields
                    ? "נא לשים לב, לא ניתן לחדש רישיון לעובדים מסקטור שונים"
                    : "נא לשים לב, לעובדים הנ\"ל יש רשיון לישראל אשר אינו מאפשר חידוש רשיון"
            };
            this.title = modalData.title;
            this.text = modalData.text;
            this.errorMessage = modalData.errorMessage;
            this.showModal = true;
            // this.dialogService.openModal(GeneralModalComponent, modalData, this.dialogConfig)
        }
        else {
            // const validEmployees  = this.selection.selected.filter(e => !e.isLicenseInIsrael && (e.daysLeftToExpire >= 0 && e.daysLeftToExpire <= this.allowRenewDays) && !e.isRenew);
            var _a = this.builDataForRenew(this.selection.selected), valid = _a.valid, invalid = _a.invalid;
            this.handleRenewLicense(valid, invalid);
        }
    };
    AllCurrentEmployeesStatusComponent.prototype.builDataForRenew = function (emplyees) {
        var _this = this;
        var invalidRenewLicense = [];
        var validEmployees = tslib_1.__spread(emplyees);
        //Important!! - The Order of te functions is important, due to the fact that the list of emplyees is being redcued with the result.
        this.invalidRenewFunctions.forEach(function (func) {
            var res = func.call(_this, validEmployees);
            if (res) {
                invalidRenewLicense.push(res);
                validEmployees = validEmployees.filter(function (tEmp) { return !res.employees.includes(tEmp); });
            }
        });
        var result = { invalid: invalidRenewLicense, valid: validEmployees };
        return result;
    };
    AllCurrentEmployeesStatusComponent.prototype.populateRenewEmployees = function (emplyees) {
        var renewEmplyees = emplyees.filter(function (e) { return e.isRenew; });
        return renewEmplyees.length ? { errorMessage: "לא ניתן להגיש בקשה לחידוש רישיונות עבור העובדים הנ\"ל, כיוון שנשלחה בעבר בקשה לחידוש רישיונות אלו", employees: renewEmplyees } : null;
        "";
    };
    ;
    AllCurrentEmployeesStatusComponent.prototype.populateRenewInvalidDatesEmployees = function (emplyees) {
        var _this = this;
        var invalidDates = emplyees.filter(function (e) { return (e.daysLeftToExpire > _this.allowRenewDays || e.daysLeftToExpire < 0); });
        return invalidDates.length ? { errorMessage: "ניתן להגיש בקשה לחידוש עבור רישיון שיפוג בעוד פחות מ-14 יום", employees: invalidDates } : null;
    };
    ;
    AllCurrentEmployeesStatusComponent.prototype.populatInvalidMagneticCard = function (emplyees) {
        var noMagneticCardEmployees = emplyees.filter(function (e) { return !e.hasValidCard; });
        return noMagneticCardEmployees.length ? { errorMessage: "לא קיים כרטיס מגנטי", employees: noMagneticCardEmployees } : null;
    };
    AllCurrentEmployeesStatusComponent.prototype.handleRenewLicense = function (validEmployees, invalidEmployees) {
        var _this = this;
        // this.employeeService.setWantedWorkersEmployees(employees);
        // this.router.navigateByUrl("/employmentapplication");
        var dialogData = {
            validEmployees: validEmployees,
            invalidEmployees: invalidEmployees
        };
        var licenseToRenew = { duration: null, licenseIds: validEmployees.map(function (employee) { return employee.licenseId; }) };
        var dialogRef = this.dialogService.openModal(LicensesRenewModalComponent, dialogData, this.dialogConfig);
        dialogRef.afterClosed().subscribe(function (result) {
            if (result) {
                licenseToRenew.duration = +result;
                _this.handleRenew(licenseToRenew);
            }
        });
    };
    AllCurrentEmployeesStatusComponent.prototype.handleRenew = function (result) {
        this.employeeService.renewEmployeesLicense(result.duration, result.licenseIds)
            .subscribe({ next: this.onSuccess.bind(this), error: this.onError.bind(this) });
    };
    AllCurrentEmployeesStatusComponent.prototype.openSubmitModal = function (success, msg, request_id) {
        this.dialogService.openModal(MessagesModalComponent, {
            submitSuccess: success,
            message: msg,
            requestId: request_id
        }, { disableClose: true });
    };
    AllCurrentEmployeesStatusComponent.prototype.onSuccess = function (response) {
        this.openSubmitModal(true, "\u05E0\u05D0 \u05DC\u05E9\u05D9\u05DD \u05DC\u05D1, \u05EA\u05D4\u05DC\u05D9\u05DA \u05D6\u05D4 \u05E6\u05E4\u05D5\u05D9 \u05DC\u05E7\u05D7\u05EA \u05E2\u05D3 7 \u05D9\u05DE\u05D9 \u05E2\u05D1\u05D5\u05D3\u05D4,\n    \u05D0\u05E0\u05D5 \u05DE\u05DE\u05DC\u05D9\u05E6\u05D9\u05DD \u05DC\u05E9\u05DE\u05D5\u05E8 \u05D0\u05EA \u05DE\u05E1\u05E4\u05E8 \u05D4\u05E4\u05E0\u05D9\u05D9\u05D4 \u05DC\u05E6\u05D5\u05E8\u05DA \u05DE\u05E2\u05E7\u05D1", response);
    };
    AllCurrentEmployeesStatusComponent.prototype.onError = function (response) {
        this.openSubmitModal(false, 'תקלה...');
    };
    AllCurrentEmployeesStatusComponent.prototype.generateEmployeesData = function () {
        var validEmployees;
        validEmployees = this.selection.selected.map(function (emp) {
            return emp;
        });
        return validEmployees;
    };
    AllCurrentEmployeesStatusComponent.prototype.applyFilter = function (filterValue) {
        // this.dataSource.filter = filterValue.trim().toLowerCase();
    };
    AllCurrentEmployeesStatusComponent.prototype.populateRenewLicenseText = function (renew) {
        var renewLicenseText = "";
        // renew.applicationStatus.code
        switch (+renew.workerRenewalAppStatus) {
            case applicationtStatusTypes.APPROVED:
                // const parsedDate = this.datePipe.transform(
                //   renew.createdTime,
                //   "dd-MM-yyyy"
                // );
                // renewLicenseText = `בקשה לחידוש רשיון אושרה. נכנס לתוקף ${parsedDate}`;
                renewLicenseText = "\u05D1\u05E7\u05E9\u05D4 " + renew.renewalApplicationId + " \u05DC\u05D7\u05D9\u05D3\u05D5\u05E9 \u05E8\u05E9\u05D9\u05D5\u05DF \u05D0\u05D5\u05E9\u05E8\u05D4.";
                break;
            case applicationtStatusTypes.PENDING:
                // renewLicenseText = `נשלחה בקשה לחידוש רשיון - מס' פניה ${renew.applicationID}`;
                renewLicenseText = "\u05E0\u05E9\u05DC\u05D7\u05D4 \u05D1\u05E7\u05E9\u05D4 \u05DC\u05D7\u05D9\u05D3\u05D5\u05E9 \u05E8\u05E9\u05D9\u05D5\u05DF - \u05DE\u05E1' \u05E4\u05E0\u05D9\u05D4: " + renew.renewalApplicationId;
                break;
            case applicationtStatusTypes.DECLINED:
                renewLicenseText = " \u05D1\u05E7\u05E9\u05D4 \u05DC\u05D7\u05D9\u05D3\u05D5\u05E9 \u05E8\u05E9\u05D9\u05D5\u05DF \u05E1\u05D5\u05E8\u05D1\u05D4, \u05E0\u05D9\u05EA\u05DF \u05DC\u05E8\u05D0\u05D5\u05EA \u05D0\u05EA \u05E1\u05D9\u05D1\u05EA \u05D4\u05E1\u05D9\u05E8\u05D5\u05D1 \u05D1\"\u05E1\u05D8\u05D0\u05D8\u05D5\u05E1 \u05D1\u05E7\u05E9\u05D5\u05EA\"";
                break;
        }
        return renewLicenseText;
    };
    AllCurrentEmployeesStatusComponent.prototype.clearData = function () {
        this.selection.clear();
        // this.dataSource.data = [];
    };
    AllCurrentEmployeesStatusComponent.prototype.isPanelBtnDisabled = function () {
        // return this.selectedEmployee.length>0
        return !this.selection.selected.length || this.user.hasBranchOnlyInIsrael;
    };
    AllCurrentEmployeesStatusComponent.prototype.ngOnDestroy = function () {
        this.printService.hidePrintButton();
        this.newLicenseArr = [];
        this.employeeService.renewEmployee.next(null);
    };
    AllCurrentEmployeesStatusComponent.prototype.transitionsHistory = function () {
        var tzEmployees = {
            state: {
                idEmploeeys: this.selection.selected
            }
        };
        this.router.navigate(['/transitionsHistory'], tzEmployees);
    };
    AllCurrentEmployeesStatusComponent.prototype.exportExcel = function () {
        var _this = this;
        this.listExcel = [];
        if (this.dataSource) {
            this.dataSource.forEach(function (a) {
                _this.listExcel.push({
                    fullName: a.fullName, identity: a.id, licenceNumber: a.licenseId, branch: a.field.name, subBranch: a.field.subName,
                    expires: a.licenseExpiredDate
                });
            });
            this.alredyOpen = true;
            return this.listExcel;
        }
    };
    return AllCurrentEmployeesStatusComponent;
}());
export { AllCurrentEmployeesStatusComponent };
