import { Injectable } from '@angular/core';
import { ApplicationHttpClient } from '../shared/service/ApplicationHttpClient';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { AlertMessages } from '../models/alertMessages';

@Injectable({
  providedIn: 'root'
})
export class AlertMessagesService {

  constructor(private http:ApplicationHttpClient) { }

  getAllMessages():Observable<any>{
    return this.http.Get<any>(`api/alert_messages/`)
  }

  updateAlertMessage(alertMessage : AlertMessages){
    return this.http.Put<any>(`api/alert_messages/${alertMessage._id}`,alertMessage);
  }

  createAlertMessages(alertMessage : AlertMessages){
    return this.http.Post<any>(`api/alert_messages/`,alertMessage);
  }

  deleteAlertMessage(id: string){
    return this.http.Delete<any>(`api/alert_messages/${id}`);
  }
}
