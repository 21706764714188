<div class="container-fluid container-box h-100">
  <div class="tabsSection screen-managment d-flex flex-column h-100">

    <h1 class="mb-5">רשימת הודעות</h1>


<p-table id="content" #content [columns]="displayedColumns" [value]="listMessages"
styleClass="p-datatable-responsive-demo" [responsive]="true" [scrollable]="true" scrollHeight="300px"
class="generalAngularTable d-print-inline-flex" id="applicationTable" >

<!-- applicationId Column -->
<ng-template pTemplate="header" let-columns>
  <tr>
    <th *ngFor="let col of displayedColumns" class="w-th">
      <span *ngIf="col.header&&col.header!='ססטוס'"> {{col.header}} </span>
      <p-multiSelect class="position-absolute multiSelect-drop-down" *ngIf="col.field=='statusId'" optionLabel="hebrewDesc" selectedItemsLabel="סטטוס" 
      maxSelectedLabels="0" [options]="statuses" defaultLabel="סטטוס"  [(ngModel)]="selectedValuesFilter" (onPanelHide)="filter()" >
    </p-multiSelect>
    </th>
  </tr>
</ng-template>

<ng-template pTemplate="body" let-rowData let-columns>
  <tr [pSelectableRow]="rowData" class="flex-mobile">

    <td *ngFor="let col of displayedColumns" >
      <span class="ui-column-title ">{{col.header}}</span>
      <span *ngIf="col.field=='dateUpdated'">{{rowData.dateUpdated | date:'dd/MM/yyyy'}}</span>
      <span *ngIf="col.field=='title'" (click)="editItem(rowData)" class="color-light-blue cursor-pointer text-overflow"
      pTooltip={{getToolTip(rowData.title)}} tooltipPosition="bottom">{{rowData.title}}</span>
      <!-- <span *ngIf="col.field=='content'" [innerHtml]="rowData.content"></span> -->
     <span class="d-block d-md-flex" *ngIf="col.field=='content'">
      <span  class="m-auto">
        <i id="tool"  class="icon-information color-light-blue" pTooltip={{rowData.contentAsText}} tooltipPosition="bottom"></i>
      </span></span>
      <span *ngIf="col.field=='fromDate'">{{rowData.fromDate | date:'dd/MM/yyyy'}}</span>
      <span *ngIf="col.field=='toDate'">{{rowData.toDate | date:'dd/MM/yyyy'}}</span>
      <span *ngIf="col.field=='statusId'">
        <span *ngIf="rowData.statusId==1">פעיל</span>
        <span *ngIf="rowData.statusId==2">מבוטל</span>
        <span *ngIf="rowData.statusId==3">לא פעיל</span>
      </span>
    </td>
  </tr>
</ng-template>

<tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
<tr mat-row *matRowDef="let row; columns: displayedColumns"
  class="textContent shadow bgWhite"></tr>
</p-table>

<div class=" d-flex flex-grow-1">
    <button type="button" pButton (click)="addMessageAlert()"  label="הוספה"
        class="mr-auto mt-auto  ui-button-rounded  align-items-center button-matpash d-flex justify-content-center button-matpash light-blue-fill">
        <i class="icon-Add-to-request mr-3"></i>
    </button>
</div>

  </div>
  </div>

<p-dialog [(visible)]="displayDialog" [modal]="true" [responsive]="true" [modal]="true"
    [style]="{width: '900px', minWidth: '900px', maxHeight:'590px'}" [minY]="70" [baseZIndex]="10000" class="dilog-matpash h-100">
    <app-update-alert-message *ngIf="displayDialog==true" (updateAlertMessageEvent)="updateAlertMessageEvent($event)"
    [alertMessage]="selectedAlertMessage" [updateMode]="updateMode" ></app-update-alert-message>
</p-dialog>