import {Injectable, Inject} from '@angular/core';
import {RequestStatusData} from '../models/interfaces';
import { Observable, of} from 'rxjs';
import applicationtStatusTypes from 'src/app/shared/util/applicationtStatusTypes';
import colorText from '../shared/util/colorText';
import { map } from 'rxjs/operators';
import { ApplicationHttpClient } from '../shared/service/ApplicationHttpClient';
import {TermsConditions} from '../models/TermsConditions';

@Injectable({
  providedIn: 'root'
})
export class LicenseService {
  private requestStatusData: RequestStatusData;

  constructor(private http:ApplicationHttpClient) {
  }

  getLicensesData(): Observable<RequestStatusData> {
    if (this.requestStatusData) {
      return of(this.requestStatusData);
    }

    return this.http.Get<RequestStatusData>(`api/statuses/getEmployerApplicationsStatus`).pipe(map((res) => {
      this.requestStatusData = res;
      return res;
    }))
  }

  resetRequestStatusData() {
    this.requestStatusData = null;
  }

  getStatusColor(applicationStatusCode: number): string {
    let color: string;
    switch (applicationStatusCode) {
      case applicationtStatusTypes.APPROVED:
        color = colorText.BLUE;
        break;
      case applicationtStatusTypes.PENDING:
        color = colorText.GREY;
        break;
      case applicationtStatusTypes.DECLINED:
        color = colorText.RED;
        break;
      default:
        color = colorText.GREY;
    }
    return color;
  }

  getLicense(): Observable<any> {
    return this.http.Get<any>(`api/terms_and_conditions/active`);
}

}

