import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'emailMasking'
})
export class EmailMaskingPipe implements PipeTransform {
  transform(value: string, ...args: any[]): string {
    return value.replace(
      /^(.)(.*)(.@.*)$/,
      (_, a, b, c) => a + b.replace(/./g, '*') + c
    );
  }
}
