import { BrowserModule } from "@angular/platform-browser";
import { APP_INITIALIZER, NgModule } from "@angular/core";
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
  HttpHandler
} from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { LayoutModule } from '@angular/cdk/layout';

// ngx translate
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";

// 3rd Party Modules
import { SlickCarouselModule } from "ngx-slick-carousel";
import { JwtModule } from "@auth0/angular-jwt";
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { PERFECT_SCROLLBAR_CONFIG } from "ngx-perfect-scrollbar";
import { PerfectScrollbarConfigInterface } from "ngx-perfect-scrollbar";
import { CookieService } from "ngx-cookie-service";
import { Ng5SliderModule } from 'ng5-slider';
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { DatePipe } from '@angular/common'

// Angular Material Modules
import { MatCheckboxModule } from "@angular/material";
import { MatButtonModule } from "@angular/material";
import { MatInputModule } from "@angular/material/input";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatSliderModule } from "@angular/material/slider";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatMenuModule } from "@angular/material/menu";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatListModule } from "@angular/material/list";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatCardModule } from "@angular/material/card";
import { MatStepperModule } from "@angular/material/stepper";
import { MatTabsModule } from "@angular/material/tabs";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatChipsModule } from "@angular/material/chips";
import { MatIconModule } from "@angular/material/icon";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatDialogModule } from "@angular/material/dialog";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatTableModule } from "@angular/material/table";
import { MatSortModule } from "@angular/material/sort";
import { MatPaginatorModule } from "@angular/material/paginator";
import { CdkTableModule } from "@angular/cdk/table";
import { EditorModule } from 'primeng/editor';
// import { NgxSpinnerModule } from "ngx-spinner";

// Config
import { AppConfig } from "./app-config/app.config";
import { environment } from "src/environments/environment";
import { HttpRequestInterceptor } from "./shared/interceptor/HttpRequestInterceptor";

// Services
import { AuthService } from "./services/auth.service";
import { AuthGuardService } from "./services/auth-guard.service";
import { UserDataService } from "./services/user-data.service";
import { LoginService } from "./services/login.service";
import { ResourceGuardService } from "./services/resource-guard.service";
import { UploadService } from "./shared/service/UploadService";
import { LicenseService } from "./services/license-service";
import { EmployeeService } from "./services/employee-service";
import { PrintService } from './shared/service/print.service';
import { ScrollToService } from './shared/service/scrollToService';
import { FileService } from './shared/service/file.service'
import { DialogService } from './shared/service/dialog.service'



// Pipes
import { EmailMaskingPipe } from "./shared/pipes/email-masking.pipe";
import { PhoneMaskingPipe } from "./shared/pipes/phone-masking.pipe";
import { StringShortenPipe } from "./shared/pipes/string.shorten.pipe";
import { DayMonthYearPipe } from "./shared/pipes/day-month-year.pipe";
import { StringDayOrDatePipe } from "./shared/pipes/string-day-or-date-pipe";
import { TimePipe } from "./shared/pipes/time-pipe";
import { YesNoPipe } from "./shared/pipes/yes-no-pipe";

// Components
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./routes/app-routing.module";
import { FooterComponent } from "./core/footer/footer.component";
import { LoginComponent } from "./pages/login/login.component";
import { LoginFormModalComponent } from "./pages/login/components/login-form-modal/login-form-modal.component";
import { AuthStepOneComponent } from "./pages/login/components/auth-step-one/auth-step-one.component";
import { AuthStepTwoComponent } from "./pages/login/components/auth-step-two/auth-step-two.component";
import { HpComponent } from "./pages/hp/container/hp.component";
import { UpdatesSliderComponent } from "./pages/hp/components/updatesSlider/updatesSlider.component";
import { LayoutComponent } from "./core/layout/layout.component";
import { HeaderComponent } from "./core/header/header.component";
import { BannerComponent } from "./core/header/banner/banner.component";
import { NavigationTabsComponent } from "./core/navigation-tabs/navigation-tabs.component";
import { CurrentEmployeeStatusComponent } from "./pages/hp/components/current-employee-status/current-employee-status.component";
import { PersonalInfoComponent } from "./pages/personalInfo/personal-info.component";
import { TermsAndConditionsComponent } from "./pages/terms-and-conditions/terms-and-conditions.component";
import { LicenseApplicationStatusComponent } from "./pages/hp/components/license-application-status/license-application-status.component";
import { ClickOutsideDirective } from "./shared/directives/clickoutside.directive";
import { SvgComponent } from "./shared/components/svg/svg.component";
import { MainMenuComponent } from "./core/header/main-menu/main-menu.component";
import { ReadMoreModalComponent } from "./pages/hp/components/read-more-modal/read-more-modal.component";
import { RequestsLicensesTabsComponent } from "./pages/requests_and_licenses/container/requests-licenses-tabs.component";
import { AllCurrentEmployeesStatusComponent } from "./pages/requests_and_licenses/components/all-current-employees-status/all-current-employees-status.component";
import { IdNumberForComponent } from "./wizard/steps/wanted-workers-step/id-numbers-form/id-numbers-form.component";
import { EmployeeApplicationListComponent } from "./wizard/steps/wanted-workers-step/applications/employee-list/employee-application-list.component";
import { EmployeeApplicationPanelComponent } from "./wizard/steps/wanted-workers-step/applications/panel/employee-application-panel.component";
import { UploadFileComponent } from "./shared/components/upload-file/upload-file.component";
import { UploadFileArrayComponent } from "./shared/components/upload-file-array/upload-file-array.component";
import { LicensesCancelModalComponent } from "./pages/requests_and_licenses/modals/emoloyee-actions/licenses-cancel-modal.component";
import { PoolEmployeesComponent } from './pages/pool-employees/pool-employees.component';
import { SavedAdsComponent } from './pages/saved-ads/saved-ads.component';
import { WorkersAdsComponent } from './shared/components/workers-ads/workers-ads.component';
import { PrintComponent } from './shared/components/print/print.component';
import { LoaderComponent } from './shared/components/loader/loader.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
// steper
import { WizardStepperComponent } from "./wizard/wizard-stepper/wizard-stepper.component";
import { StepPageWrapperComponent } from "./wizard/step-page-wrapper/step-page-wrapper.component";
import { PageDirective } from "./wizard/page-directive/page.directive";
import { TransactionAreaStepComponent } from "./wizard/steps/transaction-area-step/transaction-area-step.component";
import { RequestDetailsStepComponent } from "./wizard/steps/request-details-step/request-details-step.component";
import { FormService } from "./wizard/service/form.service";
import { FieldService } from './wizard/service/field.service';
import { BuildingPermitService } from './wizard/service/building-permit.service';
import { StepPagesService } from "./wizard/service/step-pages.service";
import { WantedWorkersStepComponent } from "./wizard/steps/wanted-workers-step/wanted-workers-step.component";
import { WizradUserDataService } from "./wizard/service/wizrad-user-data.service";
import { AddingDocumentsStepComponent } from "./wizard/steps/adding-documents-step/adding-documents-step.component";
import { StatusRequestComponent } from "./pages/requests_and_licenses/components/status-request/status-request.component";
import { ApplicationSummaryStepComponent } from "./wizard/steps/application-summary-step/application-summary-step.component";
import { StepperButtonsComponent } from "./wizard/wizard-stepper/stepper-buttons/stepper-buttons-component";
import { WizardModalComponent } from "./wizard/shared/wizard-modal/wizard-modal.component";
import { GeneralModalComponent } from "./shared/components/general-modal/general-modal.component";
import { MessagesModalComponent } from "./wizard/shared/messages-modal/messages-modal.component";
import { WelcomeModalComponent } from "./wizard/shared/welcome-modal/welcome-modal.component";
import { ConfirmModalComponent } from "./shared/components/confirm-modal/confirm-modal-component"
import { LoaderService } from './shared/components/loader/loader.service';
import { ApplicationHttpClient, applicationHttpClientCreator } from './shared/service/ApplicationHttpClient';
import { LicensesRenewModalComponent } from './pages/requests_and_licenses/modals/emoloyee-actions/licenses-renew-modal.component';
import { SharedModule } from './shared/shared.module';
import { EmployeeComponent } from './pages/employee/employee.component';
import { MessageModalComponent } from "./shared/components/message-modal/message-modal.component";
import { TransitionsHistoryComponent } from './pages/transitions-history/transitions-history.component';
import { MaintenanceComponent } from './pages/maintenance/maintenance.component';
import { InputDayStoringComponent } from './pages/input-day-storing/input-day-storing.component';
import { ManagementComponent } from './pages/management/management.component';
import { UserManagementComponent } from './pages/user-management/user-management.component';
import { ParameterManagementComponent } from './pages/management/parameter-management/parameter-management.component';
import { UpdateAlertMessageComponent } from './pages/managements/management-nav/pages/update-alert-message/update-alert-message.component';
import { AlertMessagesComponent } from './pages/managements/management-nav/pages/alert-messages/alert-messages.component';
import { SortingDayComponent } from './pages/sorting-day/sorting-day.component';
import { SortingDayMonthComponent } from './pages/sorting-day-month/sorting-day-month.component';
import { SortingDayWeekComponent } from './pages/sorting-day-week/sorting-day-week.component';
import { RadioButtonModule } from 'primeng/radiobutton';
import { WantAdsComponent } from './pages/want-ads/want-ads.component';
import { UpdateWantAdsComponent } from './pages/update-want-ads/update-want-ads.component';
import { SystemConstitutionComponent } from "./pages/managements/management-nav/pages/system-constitution/system-constitution.component";
import { MessageService } from 'primeng/api';
import { ReportsComponent } from './pages/managements/management-nav/pages/reports/reports.component';
import { QueriesComponent } from './pages/queries/queries.component';

export function initializeApp(appConfig: AppConfig) {
  return () => appConfig.load();
}

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

export function tokenGetter() {
  return localStorage.getItem("access_token");
}

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    LoginComponent,
    LoginFormModalComponent,
    AuthStepOneComponent,
    AuthStepTwoComponent,
    HpComponent,
    UpdatesSliderComponent,
    LayoutComponent,
    HeaderComponent,
    EmailMaskingPipe,
    PhoneMaskingPipe,
    StringShortenPipe,
    DayMonthYearPipe,
    StringDayOrDatePipe,
    TimePipe,
    YesNoPipe,
    BannerComponent,
    NavigationTabsComponent,
    CurrentEmployeeStatusComponent,
    PersonalInfoComponent,
    TermsAndConditionsComponent,
    LicenseApplicationStatusComponent,
    ClickOutsideDirective,
    SvgComponent,
    MainMenuComponent,
    ReadMoreModalComponent,
    RequestsLicensesTabsComponent,
    AllCurrentEmployeesStatusComponent,
    IdNumberForComponent,
    EmployeeApplicationListComponent,
    EmployeeApplicationPanelComponent,
    LicensesCancelModalComponent,
    GeneralModalComponent,
    LicensesRenewModalComponent,
    MessageModalComponent,

    // stepper
    PageDirective,
    WizardStepperComponent,
    TransactionAreaStepComponent,
    StepPageWrapperComponent,
    RequestDetailsStepComponent,
    WantedWorkersStepComponent,
    AddingDocumentsStepComponent,
    UploadFileComponent,
    ApplicationSummaryStepComponent,
    UploadFileArrayComponent,
    StepperButtonsComponent,
    StatusRequestComponent,
    WizardModalComponent,
    MessagesModalComponent,
    WelcomeModalComponent,
    ConfirmModalComponent,
    PoolEmployeesComponent,
    SavedAdsComponent,
    WorkersAdsComponent,
    PrintComponent,
    LoaderComponent,
    PageNotFoundComponent,
    EmployeeComponent,
    TransitionsHistoryComponent,
    MaintenanceComponent,
    InputDayStoringComponent,
    ManagementComponent,
    UserManagementComponent,
    SystemConstitutionComponent,
    ParameterManagementComponent,
    UpdateAlertMessageComponent,
    AlertMessagesComponent,

    SortingDayComponent,
    SortingDayMonthComponent,
    SortingDayWeekComponent,
    WantAdsComponent,
    UpdateWantAdsComponent,
    ReportsComponent,
    QueriesComponent,
  ],
  entryComponents: [
    LoginFormModalComponent,
    WizardModalComponent,
    ReadMoreModalComponent,
    StepPageWrapperComponent,
    TransactionAreaStepComponent,
    RequestDetailsStepComponent,
    WantedWorkersStepComponent,
    AddingDocumentsStepComponent,
    ApplicationSummaryStepComponent,
    LicensesCancelModalComponent,
    GeneralModalComponent,
    MessagesModalComponent,
    WelcomeModalComponent,
    ConfirmModalComponent,
    LicensesRenewModalComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    // ngx-translate and the loader module
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    RadioButtonModule,
    // Material Modules
    MatFormFieldModule,
    MatDialogModule,
    MatButtonToggleModule,
    MatInputModule,
    MatTabsModule,
    MatRadioModule,
    MatStepperModule,
    MatSelectModule,
    MatCheckboxModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
    CdkTableModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatMenuModule,
    MatSidenavModule,
    // 3rd Party Modules
    SlickCarouselModule,
    Ng5SliderModule,
    PerfectScrollbarModule,
    NgbModule,
    LayoutModule,
    SharedModule,
    MatIconModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        whitelistedDomains: ["localhost:4200"],
        blacklistedRoutes: ["example.com/examplebadroute/"]
      }
    })
  ],
  exports: [
    // TransactionAreaStepComponent
  ],
  providers: [
    AppConfig,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AppConfig], multi: true
    },
    {
      provide: ApplicationHttpClient,
      useFactory: applicationHttpClientCreator,
      deps: [HttpHandler, AppConfig, LoaderService]
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpRequestInterceptor,
      multi: true,
    },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    AuthService,
    AuthGuardService,
    CookieService,
    FormService,
    FieldService,
    BuildingPermitService,
    LoginService,
    UserDataService,
    StepPagesService,
    WizradUserDataService,
    ResourceGuardService,
    UploadService,
    EmployeeService,
    LicenseService,
    PrintService,
    ScrollToService,
    FileService,
    DialogService,
    DatePipe,
    LoaderService,
    MessageService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() { }
}
