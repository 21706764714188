import { map, tap } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "./user-data.service";
import * as i2 from "@angular/router";
var IsraelRedirectGuardService = /** @class */ (function () {
    function IsraelRedirectGuardService(userDataService, router) {
        this.userDataService = userDataService;
        this.router = router;
    }
    IsraelRedirectGuardService.prototype.canActivate = function () {
        var _this = this;
        return this.userDataService.getUser().pipe(map(function (user) { return !user.hasBranchOnlyInIsrael; }), tap(function (hasBranchOnlyInIsrael) {
            if (!hasBranchOnlyInIsrael) {
                _this.router.navigate(['requestsandlicenses/licences']);
            }
            else {
                _this.router.navigate(['requestsandlicenses/requests']);
            }
        }));
    };
    IsraelRedirectGuardService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function IsraelRedirectGuardService_Factory() { return new IsraelRedirectGuardService(i0.ɵɵinject(i1.UserDataService), i0.ɵɵinject(i2.Router)); }, token: IsraelRedirectGuardService, providedIn: "root" });
    return IsraelRedirectGuardService;
}());
export { IsraelRedirectGuardService };
