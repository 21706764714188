/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/dialog";
import * as i2 from "./confirm-modal-component";
var styles_ConfirmModalComponent = [];
var RenderType_ConfirmModalComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ConfirmModalComponent, data: {} });
export { RenderType_ConfirmModalComponent as RenderType_ConfirmModalComponent };
export function View_ConfirmModalComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 11, "div", [["class", "statusDocumentsModalWrapper generalModalWrapper"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "button", [["class", "closeBtn"], ["mat-button", ""], ["mat-dialog-close", ""]], [[1, "aria-label", 0], [1, "type", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).dialogRef.close(i0.ɵnov(_v, 2).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(2, 606208, null, 0, i1.MatDialogClose, [[2, i1.MatDialogRef], i0.ElementRef, i1.MatDialog], { dialogResult: [0, "dialogResult"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 1, "h2", [["class", "subTitle subTitle--semiBold subTitle--blueTextColor"]], null, null, null, null, null)), (_l()(), i0.ɵted(4, null, ["", ""])), (_l()(), i0.ɵeld(5, 0, null, null, 0, "p", [["class", "textContent textContent--regular colorTextGray"]], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 5, "mat-dialog-actions", [["class", "modalConfirm stepsBtns mat-dialog-actions"]], null, null, null, null, null)), i0.ɵdid(7, 16384, null, 0, i1.MatDialogActions, [], null, null), (_l()(), i0.ɵeld(8, 0, null, null, 1, "button", [["class", "btn btn--blue btn--mobileWidthBtn btn--desktopWidthBtn "], ["mat-button", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.confirm() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(9, null, ["", ""])), (_l()(), i0.ɵeld(10, 0, null, null, 1, "button", [["class", "btn colorTextBlue btn--regularBtn"], ["mat-button", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(11, null, ["", ""]))], function (_ck, _v) { var currVal_2 = ""; _ck(_v, 2, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (i0.ɵnov(_v, 2).ariaLabel || null); var currVal_1 = i0.ɵnov(_v, 2).type; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_3 = _co.header; _ck(_v, 4, 0, currVal_3); var currVal_4 = _co.message; _ck(_v, 5, 0, currVal_4); var currVal_5 = _co.ok; _ck(_v, 9, 0, currVal_5); var currVal_6 = _co.cancel; _ck(_v, 11, 0, currVal_6); }); }
export function View_ConfirmModalComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-confirm-modal", [], null, null, null, View_ConfirmModalComponent_0, RenderType_ConfirmModalComponent)), i0.ɵdid(1, 114688, null, 0, i2.ConfirmModalComponent, [i1.MatDialogRef, i1.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ConfirmModalComponentNgFactory = i0.ɵccf("app-confirm-modal", i2.ConfirmModalComponent, View_ConfirmModalComponent_Host_0, {}, {}, []);
export { ConfirmModalComponentNgFactory as ConfirmModalComponentNgFactory };
