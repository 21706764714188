import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { LoaderService } from './loader.service';
import { delay } from 'rxjs/operators';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {
  // color = 'primary';
  // mode = 'indeterminate';
  // value = 50;
  // isLoading: Subject<boolean> = this.loaderService.isLoading;
  isLoading = false;

  constructor(private loaderService: LoaderService) {}

  ngOnInit() {
    this.loaderService
      .getIsLoading()
      .pipe(delay(0))
      .subscribe(val => (this.isLoading = val));
  }
}
