import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import * as printJS from 'print-js';
import { PrintService } from '../../service/print.service';
import { Subject } from 'rxjs';
import { Pdf } from 'src/app/models/pdf';
import { User } from 'src/app/models/interfaces';
import { UserDataService } from 'src/app/services/user-data.service';

@Component({
  selector: 'app-print',
  templateUrl: './print.component.html',
  styleUrls: ['./print.component.scss']
})
export class PrintComponent implements OnInit {
  @ViewChild('content', { static: false }) content: ElementRef;
  @Input() printConfig?: { object: any; type: any; properties?: any[], targetStyles?: any[], css?: any };
  showPrintBtn: Subject<boolean> = this.printService.showPrintBtn;
  pdf: Pdf = new Pdf()
  user1: User


  constructor(private printService: PrintService, public userDataService: UserDataService) { }

  ngOnInit() {
    this.userDataService.getUser1().subscribe(user => {
      this.user1 = user.user
    })

  }

  sendEmail() {
    this.printService.sendEmail()
  }

  print() {
    this.printConfig = this.printService.getPrintConfig();
    printJS({ printable: this.printConfig.object, type: this.printConfig.type, targetStyles: this.printConfig.targetStyles, css: "assets/styles/print.css" });
  }

  exportExcel(){
    this.printService.exportAsExcelFile('samle')
  }
}
