import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stringShorten'
})
export class StringShortenPipe implements PipeTransform {

  transform(value: string, ...args: any[]): any {
    var substringLength = args[0];
    var mobileSubString = args[1]
    var dots = '...';

    if(mobileSubString != null) {
      if(window.innerWidth < 768) {
        substringLength = mobileSubString;
      }
    }
    
    if (value.length > substringLength) {
      return value.substring(0, substringLength - dots.length) + dots;
    } else {
      return value;
    }
  }
}
