<div class="container-fluid container-box h-100">
  <div class="tabsSection screen-managment d-flex flex-column h-100">

    <h1 class="mb-5">רשימת הודעות</h1>
    <div class="d-flex align-items-center mb-4">
      <span class="fw-bold ml-2"> סינון לפי</span>
      <p-dropdown optionLabel="hebrewDesc" selectedItemsLabel="סטטוס" maxSelectedLabels="0" [options]="statuses"
        defaultLabel="סטטוס" [(ngModel)]="selectedValuesFilter" class="multiSelect-small">
      </p-dropdown>
    </div>



    <p-table id="content" #content [columns]="displayedColumns" [value]="wantAdsList"
      styleClass="p-datatable-responsive-demo" [responsive]="true" [scrollable]="true" scrollHeight="300px"
      class="generalAngularTable d-print-inline-flex" id="applicationTable">

      <!-- applicationId Column -->
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th *ngFor="let col of displayedColumns" [pSortableColumn]="col.field" [style.width]="col.width" >
            <!-- <p-sortIcon *ngIf="col.field=='dateUpdated'" [field]="col.field" ></p-sortIcon> -->
            {{col.header}}
          </th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-rowData let-columns>
        <tr *ngIf="showRowData(rowData)" [pSelectableRow]="rowData" (click)="editItem(rowData)" class="flex-mobile">

          <td *ngFor="let col of displayedColumns" [style.width]="col.width" >
            <span class="ui-column-title ">{{col.header}}</span>
            <span *ngIf="col.field=='dateUpdated'&& !!rowData.dateUpdated">{{rowData.dateUpdated |
              date:'dd/MM/yyyy'}}</span>
            <span *ngIf="col.field=='title'" (click)="editItem(rowData)" class="text-overflow"
              pTooltip={{rowData.title}} tooltipPosition="bottom">{{rowData.title}}</span>
            <!-- <span *ngIf="col.field=='content'" [innerHtml]="rowData.content"></span> -->
            <span class="d-block d-md-flex" *ngIf="col.field=='content'">
              <span class="m-auto">
                <i id="tool" class="icon-information color-light-blue" pTooltip={{rowData.content}}
                  tooltipPosition="bottom"></i>
              </span></span>
            <span *ngIf="col.field=='fromDate'">{{rowData.fromDate | date:'dd/MM/yyyy'}}</span>
            <span *ngIf="col.field=='toDate'">{{rowData.toDate | date:'dd/MM/yyyy'}}</span>
            <span *ngIf="col.field=='branch'&&!!rowData.branchId ">{{rowData.branchId.name}}</span>
            <span *ngIf="col.field=='subBranch'&&!!rowData.subBranchId ">{{rowData.subBranchId.subName}}</span>
            <span *ngIf="col.field=='profession'&&!!rowData.professionId ">{{rowData.professionId.profession}}</span>
            <i id="hotJob" class="Hot-job" *ngIf="col.field=='hotJob'&&!!rowData.hotJob && rowData.hotJob===true"></i>
            <span *ngIf="col.field=='status'">
              <span *ngIf="rowData.status==1" class="green-status">פעיל</span>
              <span *ngIf="rowData.status==2" class="red-status">לא פעיל</span>
              <span *ngIf="rowData.status==3" class="color-blue">מבוטל</span>
            </span>
          </td>
        </tr>
      </ng-template>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"
        class="textContent shadow bgWhite"></tr>
    </p-table>

    <div class=" d-flex flex-grow-1">
      <button type="button" pButton (click)="addWantAd()" label="הוספה"
        class="mr-auto mt-auto  ui-button-rounded  align-items-center button-matpash d-flex justify-content-center button-matpash light-blue-fill">
        <i class="icon-Add-to-request mr-3"></i>
      </button>
    </div>

  </div>
</div>

<p-dialog [(visible)]="displayDialog" [modal]="true" [responsive]="true" [modal]="true"
  [style]="{width: '900px', minWidth: '900px', maxHeight:'590px'}" [minY]="70" [baseZIndex]="10000"
  class="dilog-matpash h-100">
  <app-update-want-ads *ngIf="displayDialog==true" (updateWantAdEvent)="updateWantAdEvent($event)"
    [WantAd]="selectedWantAd" [updateMode]="updateMode" [user]="user"></app-update-want-ads>
</p-dialog>