import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-workers-ads',
  templateUrl: './workers-ads.component.html',
  styleUrls: ['./workers-ads.component.scss']
})
export class WorkersAdsComponent implements OnInit {
  @Input() deleteBtn;
  @Input() addFavBtn;
  @Output() addToFavorite: EventEmitter<any> = new EventEmitter();
  isMobile: boolean;
  isFavorite: boolean = false;
  mailText: string = '';
  imgExist: boolean = true;
  employeeNotExist: boolean = false;
  isDeleted: boolean = false;
  isdropdownrOpenSubjects: boolean;
  isdropdownrOpenAreas: boolean;
  resultNumber: number;

  constructor() { }

  ngOnInit() {
   
    this.resultNumber = 2;

    if(window.innerWidth < 1024) {
      this.isdropdownrOpenSubjects = false;
      this.isdropdownrOpenAreas = false;
      this.isMobile = true;
      this.mailText = 'שליחת מייל';
    } else {
      this.mailText = 'karim@benzema@realmadrid.com';
      this.isdropdownrOpenSubjects = true;
    this.isdropdownrOpenAreas = true;
    }
  }

  toggleDropDownSubjects() {
    if (window.innerWidth < 1024) {
     this.isdropdownrOpenSubjects = !this.isdropdownrOpenSubjects;
     this.isdropdownrOpenAreas = false;
    }
  }

  toggleDropDownAreas() {
    if (window.innerWidth < 1024) {
     this.isdropdownrOpenAreas = !this.isdropdownrOpenAreas;
     this.isdropdownrOpenSubjects = false;
    }
  }

  toggleFavorite() {
    this.isFavorite = !this.isFavorite;
  }

  
  removeEmployee() {
    this.isDeleted = true;

  }

}
