<div class="tabsSection pt-0">
    <h1 *ngIf="updateMode===true">עדכון הודעות</h1>
    <h1 *ngIf="updateMode===false"> הוספת הודעה</h1>

    <form class="" [formGroup]="wantAdFG" (ngSubmit)="submit()">
        <div>
            <div class="d-flex flex-wrap my-4 align-items-start px-4 row">
                <div class="col-12 col-md-4 mb-4 mb-md-0 ml-0 pl-md-4 p-0">
                    <input class="input-matpash w-100 mt-5" formControlName="title" [disabled]="isCanceled()"
                        placeholder=" כותרת" pInputText />
                    <div *ngIf="submitted===true && wantAdFG.controls.title.errors?.required"
                        class="validation-error mt-2 "><i class="icon-16 ml-3"></i>שדה
                        חובה
                    </div>
                </div>

                <div class="d-flex flex-md-column align-items-center align-items-md-start">
                    <label class="ml-3 text-nowrap mb-0 mb-md-3">משרה חמה</label>
                    <p-selectButton class="selectButton-matpash w-100" [options]="hotJobOptions"
                        formControlName="hotJob" dataKey="value">
                        <ng-template let-item>
                            <div style="padding: .5em 2.5em">
                                <span>{{item.desc}}</span>
                            </div>
                        </ng-template>
                    </p-selectButton>
                </div>
            </div>
            <!-- <div class="textarea-container"> -->
            <textarea pInputTextarea formControlName="content" rows="5" placeholder=" תוכן" class="w-100"
                [readonly]="isCanceled()">
            </textarea>
            <!-- </div> -->
            <div *ngIf="submitted && wantAdFG.controls.content.errors?.required" class="validation-error mt-2 "><i
                    class="icon-16 ml-3"></i>שדה חובה
            </div>

            <div class="d-flex flex-wrap mt-md-4 my-4 row">
                <label class="col-12 col-md-2 color-blue mb-2 mb-md-0 mt-md-4"> תאריכים</label>

                <div class=" col-12 col-md-3 pl-md-0 mb-4 mb-md-0">
                    <p-calendar placeholder="מתאריך" dateFormat="dd/mm/yy" formControlName="fromDate"
                        (onSelect)="updateStatusesOptions()" class="p-calendar-matpash" [minDate]="today"
                        [disabled]="isCanceled()" appendTo="'body'"></p-calendar>

                    <div *ngIf="submitted && wantAdFG.controls.fromDate.errors?.required"
                        class="validation-error mt-2 "><i class="icon-16 ml-3"></i>שדה
                        חובה
                    </div>
                </div>
                <div class=" col-12 col-md-3 pl-md-0">
                    <p-calendar placeholder="עד תאריך" dateFormat="dd/mm/yy" formControlName="toDate" appendTo="'body'"
                        (onSelect)="updateStatusesOptions()" class="p-calendar-matpash"
                        [minDate]="wantAdFG.controls.fromDate.value" [disabled]="isCanceled()"></p-calendar>

                    <div *ngIf="submitted && wantAdFG.controls.toDate.errors?.required" class="validation-error mt-2 ">
                        <i class="icon-16 ml-3"></i>שדה
                        חובה
                    </div>

                </div>
            </div>


            <div class="d-flex flex-wrap  row">
                <label class="col-12 col-md-2 color-blue mb-2 mb-md-0 mt-md-4">ענפים</label>

                <div class="col-12 col-md-3 pl-md-0 mb-4 mb-md-0">
                    <p-dropdown showClear="true" class="height-53 w-100 " [options]="branches" placeholder="בחר ענף"
                        optionLabel="name" formControlName="branchId" dataKey="name" (onClick)="branchChanged()"
                        (onChange)="BrunchesSelectionChange()" [disabled]="isCanceled()">
                    </p-dropdown>


                    <div *ngIf="submitted && wantAdFG.controls.branchId.errors?.required"
                        class="validation-error mt-2 "><i class="icon-16 ml-3"></i>שדה
                        חובה
                    </div>
                </div>

                <div class="col-12 col-md-3 pl-md-0  mb-4 mb-md-0">
                    <p-dropdown class="height-53 w-100" showClear="true" [options]="subBranches"
                        placeholder="בחר תת ענף" optionLabel="subName" formControlName="subBranchId" dataKey="subName"
                        (onClick)="subBranchChanged()" [disabled]="!wantAdFG.controls.branchId.value || isCanceled()"
                        [filter]="subBranchFilter()">
                    </p-dropdown>
                </div>

                <div class="col-12 col-md-3 pl-md-0 mb-4 mb-md-0 ">
                    <p-dropdown class="height-53 w-100" showClear="true" [options]="professions" placeholder="בחר מקצוע"
                        optionLabel="profession" formControlName="professionId" dataKey="profession"
                        [disabled]="!wantAdFG.controls.subBranchId.value || isCanceled()">
                    </p-dropdown>
                </div>
            </div>

            <div class="d-flex flex-wrap mt-md-4  row">
                <label class="col-12 col-md-2 color-blue mb-2 mb-md-0 mt-md-4">איש קשר</label>
                <div class="col-12 col-md-3 pl-md-0 mb-4 mb-md-0">
                    <input class="input-matpash w-100" formControlName="firstName" placeholder="שם פרטי" pInputText
                        disabled="true" />
                </div>

                <div class="col-12 col-md-3 pl-md-0 mb-4 mb-md-0">
                    <input class="input-matpash w-100" formControlName="lastName" placeholder="שם משפחה" pInputText
                        disabled="true" />
                </div>
            </div>

            <div class="d-flex flex-wrap mt-md-4  row">
                <label class="col-12 col-md-2 color-blue mb-2 mb-md-0 mt-md-4"> פרטי התקשרות</label>
                <div class="col-12 col-md-3 pl-md-0 mb-4 mb-md-0">
                    <input class="input-matpash w-100" formControlName="telephone" placeholder="טלפון" pInputText
                        disabled="true" />
                </div>

                <div class="col-12 col-md-3 pl-md-0 mb-4 mb-md-0">
                    <input class="input-matpash w-100" formControlName="email" placeholder='דוא"ל' type="tel" pInputText
                        disabled="true" />
                </div>
            </div>

            <div class="d-flex  align-items-center mt-4 mb-5 color-blue">
                <span class="fw-bold ml-2"> סטטוס</span>
                <label> {{currentStatus.hebrewDesc}}</label>
            </div>

            <div *ngIf="showInterestedEmployees===true">
                <div class="my-3 dashed-line"></div>
                <h3 class="mb-3 mr-md-4 color-light-blue fw-bold">התעניינו במודעה</h3>
                <div  class="d-flex flex-wrap align-items-center showInterested">
                    <div class=" mb-3 ml-3 d-flex align-items-center" *ngFor="let item of WantAd.interestedEmployees">
                    <span class="fw-bold color-blue"  
                    pTooltip="{{item?.employeeId?.firstName}} {{item?.employeeId?.lastName}}">
                        {{item?.employeeId?.firstName}} {{item?.employeeId?.lastName}}</span>
                    <span class="mx-2 color-blue">|</span>
                    <span class="color-blue">ת"ז {{item?.employeeId?.employeeIdentity}}</span></div>
                </div>
            </div>

            <div class="d-flex align-items-center mt-5 mb-4">
                <label *ngIf="updateMode===true" class="ml-5 color-light-blue">
                    <span class="fw-bold ml-2"> תאריך עדכון</span>
                    {{WantAd.dateUpdated |date:'dd/MM/yyyy'}}</label>



                <div class=" d-flex col-md-6 mr-auto">
                    <button [disabled]="updateMode===false||isCanceled()"
                        class="ui-button-rounded  button-matpash no-fill-btn ml-4 col-md-4 mr-auto"
                        (click)="deleteWantAd()">מחק</button>

                    <button type="submit" [disabled]="isCanceled()" pButton
                        label="{{(updateMode===true ? 'עדכן' : 'שמור')}}"
                        class=" ui-button-rounded  button-matpash light-blue-fill col-md-4">
                    </button>
                </div>

            </div>
        </div>

    </form>
</div>