/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../components/updatesSlider/updatesSlider.component.ngfactory";
import * as i2 from "../components/updatesSlider/updatesSlider.component";
import * as i3 from "../../../services/user-data.service";
import * as i4 from "@angular/material/dialog";
import * as i5 from "../components/license-application-status/license-application-status.component.ngfactory";
import * as i6 from "../components/license-application-status/license-application-status.component";
import * as i7 from "@angular/router";
import * as i8 from "../components/current-employee-status/current-employee-status.component.ngfactory";
import * as i9 from "../components/current-employee-status/current-employee-status.component";
import * as i10 from "../../../services/employee-service";
import * as i11 from "./hp.component";
var styles_HpComponent = [];
var RenderType_HpComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_HpComponent, data: {} });
export { RenderType_HpComponent as RenderType_HpComponent };
export function View_HpComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "div", [["class", "wrap-home-page"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "app-updatesSlider", [], null, null, null, i1.View_UpdatesSliderComponent_0, i1.RenderType_UpdatesSliderComponent)), i0.ɵdid(2, 114688, null, 0, i2.UpdatesSliderComponent, [i3.UserDataService, i4.MatDialog], null, null), (_l()(), i0.ɵeld(3, 0, null, null, 4, "div", [["class", "row w-100 m-0"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "app-license-application-status", [["class", "appStatus col-md-6"]], null, null, null, i5.View_LicenseApplicationStatusComponent_0, i5.RenderType_LicenseApplicationStatusComponent)), i0.ɵdid(5, 114688, null, 0, i6.LicenseApplicationStatusComponent, [i3.UserDataService, i7.Router], null, null), (_l()(), i0.ɵeld(6, 0, null, null, 1, "app-current-employee-status", [["class", "appStatus col-md-6"]], null, null, null, i8.View_CurrentEmployeeStatusComponent_0, i8.RenderType_CurrentEmployeeStatusComponent)), i0.ɵdid(7, 114688, null, 0, i9.CurrentEmployeeStatusComponent, [i3.UserDataService, i10.EmployeeService, i7.Router], null, null)], function (_ck, _v) { _ck(_v, 2, 0); _ck(_v, 5, 0); _ck(_v, 7, 0); }, null); }
export function View_HpComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-hp", [], null, null, null, View_HpComponent_0, RenderType_HpComponent)), i0.ɵdid(1, 49152, null, 0, i11.HpComponent, [i3.UserDataService], null, null)], null, null); }
var HpComponentNgFactory = i0.ɵccf("app-hp", i11.HpComponent, View_HpComponent_Host_0, {}, {}, []);
export { HpComponentNgFactory as HpComponentNgFactory };
