import { Injectable } from '@angular/core';
import { Observable } from 'rxjs-compat/Observable';
import { map } from 'rxjs/operators';
import { Query } from '../models/queries';
import { ApplicationHttpClient } from '../shared/service/ApplicationHttpClient';

@Injectable({
  providedIn: 'root'
})
export class QueriesService {
  endPoint = "api/queries";
  constructor(private http: ApplicationHttpClient) { }

  getQueries(): Promise<Query[]> {
    return this.http.Get<{ data: Query[] }>(this.endPoint).pipe(map(res => res.data)).toPromise();
  }

  sendQuery(queryId: string, inputValues: { [name: string]: string }): Observable<{ data: any }> {
    return this.http.Post<{ data: any }>(this.endPoint, { queryId, inputValues });
  }
}
