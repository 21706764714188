import * as i0 from "@angular/core";
import * as i1 from "../shared/service/ApplicationHttpClient";
var WantAdsService = /** @class */ (function () {
    function WantAdsService(http) {
        this.http = http;
    }
    WantAdsService.prototype.getAllWantAds = function (userId) {
        return this.http.Get("api/want_ads/getByUser/" + userId);
    };
    WantAdsService.prototype.updateWantAd = function (wantAdModel) {
        return this.http.Put("api/want_ads/" + wantAdModel._id, wantAdModel);
    };
    WantAdsService.prototype.createWantAd = function (wantAdModel) {
        return this.http.Post("api/want_ads/", wantAdModel);
    };
    WantAdsService.prototype.deleteWantAd = function (id) {
        return this.http.Delete("api/alert_messages/" + id);
    };
    WantAdsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function WantAdsService_Factory() { return new WantAdsService(i0.ɵɵinject(i1.ApplicationHttpClient)); }, token: WantAdsService, providedIn: "root" });
    return WantAdsService;
}());
export { WantAdsService };
