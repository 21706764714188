
                <div class="cubeItem shadow-cube" [class.removed]="isDeleted">
                    <div class="rightCube">

                        <div class="imgWrapper" [class.noPic]="!imgExist">
                            <img *ngIf="imgExist" src="assets/imgs/personImg.png" alt="">
                            <img *ngIf="!imgExist" src="assets/imgs/person-icon.svg" alt="">
                        </div>
                        <div class="detailBox">
                            <div class="detailBox__title "> <h3>וואליד באדיר</h3></div>
                            <ul class="detailBox__list">
                                <li class="detailBox__item ">
                                    <div class="picWrapper">
                                     <i class="icon-district"></i>
                                    <!-- <img class="pic" src="assets/imgs/location.svg" alt=""> -->
                                    </div>
                                        מחוז יהודה ושומרון
                                    </li>
                                <li class="detailBox__item  ">
                                    <div class="picWrapper">
                                    <!-- <img class="pic" src="assets/imgs/contact-blue-icon.svg" alt=""> -->
                                    <i class="icon-Employed-in-Israel"></i>
                                    </div>
                                        הועסק בעבר ע"י ישראלי
                                </li>
                                <li class="detailBox__item  ">
                                    <div class="picWrapper">
                                    <!-- <img class="pic" src="assets/imgs/speak-icon.svg" alt=""> -->
                                    <i class="icon-Speaks-Hebrew"></i>
                                    </div>
                                    דובר עברית
                                </li>
                                <li class="detailBox__item textContent ">
                                    <a href="tel:0528948109" class="detailBox__link">
                                        <div class="picWrapper">
                                        <!-- <img class="pic" src="assets/imgs/phone-blue-icon.svg" alt=""> -->
                                        <i class="icon-Phone-Number"></i>
                                        </div>
                                        <span class="detailBox__linkText">
                                        052-8948109
                                        </span>
                                    </a>
                                    </li>
                                <li class="detailBox__item textContent ">
                                    <a href="mailto:karim@benzema@realmadrid.com" class="detailBox__link">
                                        <div class="picWrapper">
                                        <!-- <img class="pic" src="assets/imgs/mail.svg" alt=""> -->
                                        <i class="icon-mail-adress"></i>
                                        </div>
                                        <span class="detailBox__linkText">
                                        {{mailText}}
                                        </span>
                                    </a>
                                    </li>
                                <li *ngIf="isMobile" class="detailBox__item textContent ">
                                    <a href="tel:0528948109" class="detailBox__link">
                                        <div class="picWrapper">
                                        <!-- <img class="pic" src="assets/imgs/whatsapp-blue-icon.svg" alt=""> -->
                                        <i class="icon-ID"></i>
                                        </div>
                                        <span class="detailBox__linkText">
                                        שליחת וואטסאפ
                                    </span>
                                    </a>
                                </li>
                               
                            </ul>
                        </div>
                    </div>
                    <div class="leftCube">
                        <div class="detailBox">
                            <div class="detailBox__rightInner">
                                <div class="contentWrapper">
                                    <div class="detailBox__title "> <h3>תחומים ומקצועות</h3></div>
                                    <!-- <span class="textContent  dropDown" (click)="toggleDropDownSubjects()" [class.active]="isdropdownrOpenSubjects">תחומים ומקצועות</span> -->
                                    <ul class="employeeDetailsList" *ngIf="isdropdownrOpenSubjects">
                                        <li class="employeeDetailsList__item">
                                            <div class="inputBubble textContent ">
                                                ניקיון
                                            </div>
                                            <span class=" ">10 שנות נסיון</span>
                                        </li>
                                        <li class="employeeDetailsList__item">
                                            <div class="inputBubble textContent ">
                                                סבלות
                                            </div>
                                            <span class=" ">מויין</span>
                                        </li>
                                        <li class="employeeDetailsList__item">
                                            <div class="inputBubble textContent ">
                                                קטיף
                                            </div>
                                            <span class=" ">בניין</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="detailBox__leftInner">
                                <div class="contentWrapper">
                                    <!-- <span class="textContent  dropDown"  (click)="toggleDropDownAreas()"
                                    [class.active]="isdropdownrOpenAreas">מבחן בטיחות</span>
                                    <ul class="employeeDetailsList employeeDetailsList--row" *ngIf="isdropdownrOpenAreas">
                                        <li class="employeeDetailsList__item"> -->
                                            <div class="detailBox__title "> <h3>מבחן בטיחות</h3></div>
                                            <div class="inputBubble textContent ">
                                                עבר בתאריך
                                            </div>
                                        <!-- </li>
                                       
                                    </ul> -->
                                </div>
                            </div>
                        </div>
                    </div>
                    <button *ngIf="addFavBtn" class="fovoriteBtn" [class.active]="isFavorite" (click)="toggleFavorite()">
                    
                        <svg class="fovoriteBtnIcon" width="28px" height="27px" viewBox="0 0 28 27" version="1.1"
                            xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                            <g id="איתור-עובדים" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <g id="3.1-menu-regular" transform="translate(-55.000000, -1031.000000)"
                                    stroke="#056FF2" stroke-width="2">
                                    <path
                                        d="M69,1051.56676 L61.2593712,1056.65406 L63.7057004,1047.72022 L56.4753995,1041.93051 L65.7279429,1041.4964 L69,1032.83086 L72.2720571,1041.4964 L81.5246005,1041.93051 L74.2942996,1047.72022 L76.7406288,1056.65406 L69,1051.56676 Z"
                                        id="Star-Copy-8"></path>
                                </g>
                            </g>
                        </svg>
                    </button>
                    <div class="border-top w-100 position-relative">
                    </div>
                    <div>
                        <ul>
                            <li class="detailBox__item   detailBox__item--flexDirection">
                                <div class="uploadLink">
                                    <a href="#" class="" target="_blank">
                                        <div class="picWrapper">
                                        <img class="" src="assets/imgs/pdf-blue-icon.png" alt="" />
                                        </div>
                                        <span class="uploadLink__text link  link--underLine link--semiBold">קורות חיים1.pdf</span>
                                        <img class="" src="assets/imgs/download.svg" alt="" />
                                    </a>
                                </div>
                                <div class="uploadLink">
                                    <a href="#" class="" target="_blank">
                                        <div class="picWrapper">
                                        <img class="" src="assets/imgs/pdf-blue-icon.png" alt="" />
                                        </div>
                                        <span class="uploadLink__text link  link--underLine link--semiBold">קורות חיים1.pdf</span>
                                        <img class="" src="assets/imgs/download.svg" alt="" />
                                    </a>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <!-- For favorites page -->
                    <button  *ngIf="deleteBtn" class="fovoriteBtn" (click)="removeEmployee()">
                        <img class="iconImg" src="assets/imgs/trash-can-icon.svg" alt="pdf icon">
                        <span class="deleteFileBtn__text">
                           הסר
                        </span>
                    </button>
                    <span class="datePublished">תאריך פרסום המודעה: 13/05/19</span>
                    <div  *ngIf="isDeleted" class="deletedItem">
                        <span class="deletedItem__text textContent textContent--semiBold colorTextWhite">עובד זה ירד ממאגר העובדים</span>
                    </div>
                </div>
           
       