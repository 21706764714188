import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { WelcomwDialogProps } from 'src/app/models/interfaces';
import { Router } from '@angular/router';
import { UserDataService } from 'src/app/services/user-data.service';

@Component({
  selector: 'app-welcome-modal',
  templateUrl: './welcome-modal.component.html',
  styleUrls: ['./welcome-modal.component.scss']
})
export class WelcomeModalComponent implements OnInit {
  modalData: WelcomwDialogProps;
  // unavailableFieldsData: UnavailableField;
  showUnavailableData:boolean = false;
  display:boolean=false

  constructor(
    private dialogRef: MatDialogRef<WelcomeModalComponent>,
    public userDataService:UserDataService,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.modalData = data;
  }

  ngOnInit() {
    this.display=true
  }

  get showUnavailableFields(){
    return this.modalData.unavailableField && this.modalData.unavailableField.setllementsFields.length;
  }

  checkEmployerFields() {
    if(this.modalData.unavailableField && this.modalData.unavailableField.setllementsFields.length){
      this.showUnavailableData = true;
    }else{
      this.closeModal();
    }
  }

  continueAfterFieldCheck(){
    this.closeModal();

    if(!this.modalData.unavailableField.allowIsrael && !this.modalData.unavailableField.allowSettlement){
      this.router.navigateByUrl('/home');
    }
  }

  closeModal() {
    this.dialogRef.close();
  }
}
