import { CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "../wizard/service/wizrad-user-data.service";
var ResourceGuardService = /** @class */ (function () {
    function ResourceGuardService(wizardService) {
        this.wizardService = wizardService;
    }
    ResourceGuardService.prototype.canActivate = function () {
        return this.wizardService.isInit();
    };
    ResourceGuardService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ResourceGuardService_Factory() { return new ResourceGuardService(i0.ɵɵinject(i1.WizradUserDataService)); }, token: ResourceGuardService, providedIn: "root" });
    return ResourceGuardService;
}());
export { ResourceGuardService };
