import { BranchModel,SubBrancModel, language, livingCity, regionId, professionModel } from './Employee';
import { Area,Branch,SubBranch,Region,City,Gender,Language,EmploymentStatus,Profession} from './metadataModels'
export class EmployeesFilter{
    EmployerCode : number;
    Branches : BranchModel[];
    SubBranches : SubBrancModel[];
    Professions : professionModel[];
    IsSafetyTraining : boolean;
    Regions : regionId[];
    Areas : Area[];
    Cities : livingCity[];
    Genders : Gender[];
    Languages : language[];
    PreviouslyEmployedByIsraeli : boolean;
    IsFilteredByAge : boolean;
    MinAge : number;
    MaxAge : number;
    EmploymentStatus : EmploymentStatus[];
}


