import { Injectable } from '@angular/core';
import { UploadedFile } from 'src/app/models/interfaces';
import { ApplicationHttpClient } from './ApplicationHttpClient';

@Injectable({
  providedIn: 'root'
})
export class UploadService {
  baseUrl: string;

  constructor(private http: ApplicationHttpClient) {
  }

  UploadFile(uplaodFile: File, documentCode: number) {
    const formData: FormData = new FormData();
    formData.append('document', uplaodFile);
    formData.append('code', documentCode.toString());

    return this.http.Post<UploadedFile>(`api/documents/upload`, formData);
  }

  deleteFile(fileId) {
    const token = sessionStorage.getItem('token');
    if (!!token) {
      return this.http.Delete<any>(`api/documents/delete/${token}/${fileId}`);
    }
  }

  generateDownloadFileOfUser(fileId: string) {
    const token = sessionStorage.getItem('token');
    if (!!token) {
      return `${this.http.getAPI()}api/documents/downloadByUser/${token}/${fileId}`;
    }
  }

  generateDownloadFile(documentCode: number) {
    return `${this.http.getAPI()}api/documents/download/${documentCode}`;
  }
}
