import { Area, Branch, EmployerType, SubArea, SubBranch } from "./metadataModels";

export enum AlertsMessagesEnum {
    Active = 1,
    Draft = 2,
    Inactive = 3,
}
export class AlertMessages{
        _id:string;
        title:string;
        content:string;
        contentAsText: string;
        fromDate:Date;
        toDate:Date;
        subAreaId:SubArea;
        subBranchId:SubBranch;
        employerTypeId:EmployerType;
        hotJob:boolean;
        statusId:AlertsMessagesEnum;
        dateUpdated:Date;
        userUpdated:string;
}