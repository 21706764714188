import * as tslib_1 from "tslib";
import { OnInit } from "@angular/core";
import { Validators } from "@angular/forms";
import { filter } from "rxjs/operators";
import { StepComponentBase } from '../wizard-step-component-base/step-component-base';
var AddingDocumentsStepComponent = /** @class */ (function (_super) {
    tslib_1.__extends(AddingDocumentsStepComponent, _super);
    function AddingDocumentsStepComponent(stepsPagesService, formService, dialog, wizradUserDataService, buildingPermitService, fieldService) {
        var _this = _super.call(this, stepsPagesService, dialog) || this;
        _this.formService = formService;
        _this.wizradUserDataService = wizradUserDataService;
        _this.buildingPermitService = buildingPermitService;
        _this.fieldService = fieldService;
        _this.FormArrayRef = null;
        _this.field = null;
        _this.isToCheckToShowError = false;
        _this.submitted = false;
        _this.FormArrayRef = _this.formService
            .getForm()
            .get("documents");
        _this.hasUploaded = true;
        return _this;
    }
    AddingDocumentsStepComponent.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
        this.buildingPermitDocumentDetails = this.buildingPermitService.getBuildingPermitDownloadDocument();
        //this methods is needed dut to the fact that this is a dynamic component, and the fields data has already changed before the render of the component
        this.setInitialCurrentDocuments();
    };
    AddingDocumentsStepComponent.prototype.setInitialCurrentDocuments = function () {
        var _this = this;
        var currentField = this.formService
            .getFormGroupRefByName("requestDetails")
            .get("fields").value;
        if (currentField) {
            this.field = currentField;
            this.createDocuments(currentField.documents);
        }
        this.formService
            .getFormGroupRefByName("requestDetails")
            .get("fields")
            .valueChanges.pipe(filter(function (field) { return !!field; }))
            .subscribe(function (currentField) {
            _this.field = currentField;
            _this.createDocuments(currentField.documents);
        });
    };
    AddingDocumentsStepComponent.prototype.createDocuments = function (docs) {
        var _this = this;
        this.FormArrayRef.clear();
        docs.forEach(function (doc, index) {
            if (doc.code === _this.buildingPermitDocumentDetails.code) {
                var ref = _this.buildingPermitService.getBuildingPermitRef();
                _this.FormArrayRef.push(ref);
            }
            else {
                var control = _this.formService.createControl(null);
                if (doc.required) {
                    control.setValidators([Validators.required]);
                    control.updateValueAndValidity();
                }
                _this.FormArrayRef.push(control);
            }
        });
    };
    AddingDocumentsStepComponent.prototype.hideType = function (name) {
        return name.substring(0, name.lastIndexOf('.'));
    };
    AddingDocumentsStepComponent.prototype.preventFileDelete = function (control) {
        //TODO: MAKE A MORE GENERIC SOLUTION - MAYBE ADD TO INTERFACE
        return !!(control && control.value && (control.value).fileTypeId === this.buildingPermitDocumentDetails.code
            && this.fieldService.hasEmploymentLimitation() && this.fieldService.usedBuildingPermitLimitation());
    };
    AddingDocumentsStepComponent.prototype.submit = function () {
        this.submitted = true;
        _super.prototype.submit.call(this);
    };
    return AddingDocumentsStepComponent;
}(StepComponentBase));
export { AddingDocumentsStepComponent };
