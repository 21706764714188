import * as tslib_1 from "tslib";
import { OnInit, QueryList } from '@angular/core';
import { Branch } from 'src/app/models/branch';
import { Candidate } from 'src/app/models/candidate';
import { Employee } from 'src/app/models/Employee';
import { Profession } from 'src/app/models/profession';
import { CandidateStatusEnum, SortingDay } from 'src/app/models/sortingDay';
import { SubBranch } from 'src/app/models/subBranch';
var InputDayStoringComponent = /** @class */ (function () {
    function InputDayStoringComponent(sortingDayService, employeeService) {
        this.sortingDayService = sortingDayService;
        this.employeeService = employeeService;
        this.listBranch = new Array();
        this.listSubBranch = new Array();
        this.listProfession = new Array();
        this.arrayBranch = new Array();
        this.arraySubBranch = new Array();
        this.arrayProfession = new Array();
        this.listSortingDay = new Array();
        this.listEmployee = new Array();
        this.listEmployeeChecked = new Array();
        this.listRemoveCandidate = new Array();
        this.sortingDaySelected = new SortingDay();
        this.branch = new Branch();
        this.subBranch = new SubBranch();
        this.profession = new Profession();
        this.displayModal = false;
        this.showContact = false;
        this.employee = new Employee();
        this.sortingDay = new SortingDay();
        this.candidate = new Candidate();
        this.selectedSortingDay = null;
    }
    InputDayStoringComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.sortingDayService.getAllBranches().subscribe(function (data) {
            _this.listBranch = data.data;
        });
        this.sortingDayService.getAllSubBranches().subscribe(function (data) {
            _this.listSubBranch = data.data;
        });
        this.sortingDayService.getAllProfession().subscribe(function (data) {
            _this.listProfession = data.data;
        });
        this.initialTables();
    };
    InputDayStoringComponent.prototype.initialTables = function () {
        this.fillTable(this.sortingDay, this.listSortingDay);
        this.fillTable(this.employee, this.listEmployee);
        var listCandidate = [];
        this.sortingDaySelected.candidates = this.fillTable(this.candidate, listCandidate);
    };
    InputDayStoringComponent.prototype.refreshTables = function () {
        var _this = this;
        this.sortingDayService.searchEmployees(this.profession._id).subscribe(function (data) {
            _this.listEmployee = data.data;
            _this.fillTable(_this.employee, _this.listEmployee);
        });
        this.sortingDayService.getCandidate(this.profession._id).subscribe(function (data) {
            _this.listSortingDay = data.data;
            _this.listSortingDay = _this.sortListSortingDayByDate(data.data);
            _this.fillTable(_this.sortingDay, _this.listSortingDay);
            _this.sortingDaySelected = data.data[0];
            _this.selectedSortingDay = _this.listSortingDay[0];
            if (!!_this.sortingDaySelected.allocation) {
                _this.fillTable(_this.candidate, _this.sortingDaySelected.candidates);
                return;
            }
            var listCandidate = [];
            _this.sortingDaySelected.candidates = _this.fillTable(_this.candidate, listCandidate);
        });
    };
    InputDayStoringComponent.prototype.sortListSortingDayByDate = function (array) {
        array.sort(function (a, b) {
            return Number(new Date(b.examDate)) - Number(new Date(a.examDate));
        });
        return array;
    };
    InputDayStoringComponent.prototype.fillTable = function (object, list) {
        for (var i = 0; i <= 13; i++) {
            if (list.length <= i) {
                list.push(object);
            }
        }
        return list;
    };
    InputDayStoringComponent.prototype.amountCandidate = function (item) {
        if (!item.allocation)
            return;
        var countCandidate = item.candidates.filter(function (candidate) {
            return !!candidate.employeeId;
        });
        return countCandidate.length;
    };
    InputDayStoringComponent.prototype.checkedEmployee = function (event, employee) {
        if (event == true) {
            return this.listEmployeeChecked.push(employee);
        }
        return this.listEmployeeChecked = this.listEmployeeChecked.filter(function (emp) {
            return emp !== employee;
        });
    };
    InputDayStoringComponent.prototype.checkedRemoveCandidate = function (check, candidate) {
        if (check.checked == true) {
            return this.listRemoveCandidate.push(candidate);
        }
        return this.listRemoveCandidate = this.listRemoveCandidate.filter(function (candidates) {
            return candidates !== candidate;
        });
    };
    InputDayStoringComponent.prototype.removeCandidate = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var employees, candidateRemove;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (new Date(this.sortingDaySelected.examDate) < new Date()) {
                            this.displayModal = true;
                            this.title = "מחיקת עובדים מיום מיונים שהתקיים";
                            this.content = "לא ניתן למחוק עובדים מיום מיונים שהגיעו תוצאות לגביו";
                            this.img;
                            return [2 /*return*/];
                        }
                        employees = this.listRemoveCandidate.map(function (candidate) {
                            if (candidate && candidate.employeeId) {
                                return candidate.employeeId._id;
                            }
                        });
                        return [4 /*yield*/, this.updateStatusEmployeeProfession(CandidateStatusEnum.Deleted, employees)];
                    case 1:
                        _a.sent();
                        candidateRemove = this.sortingDaySelected.candidates.filter(function (ar) { return !_this.listRemoveCandidate.find(function (rm) { return (rm._id === ar._id); }); });
                        this.listRemoveCandidate = new Array();
                        this.updateStatusCandidate(candidateRemove);
                        return [2 /*return*/];
                }
            });
        });
    };
    InputDayStoringComponent.prototype.selectSortingDay = function (item) {
        this.sortingDaySelected = item;
        this.fillTable(this.candidate, this.sortingDaySelected.candidates);
    };
    InputDayStoringComponent.prototype.setSubBranchesArray = function () {
        var _this = this;
        this.listEmployee = [];
        this.listSortingDay = [];
        this.sortingDaySelected = {};
        this.initialTables();
        if (this.branch == null) {
            this.branch = {};
            this.subBranch = {};
            this.profession = {};
            this.arrayProfession = [];
            this.arraySubBranch = [];
            return;
        }
        this.arraySubBranch = this.listSubBranch.filter(function (sub) {
            return sub.branch._id == _this.branch._id;
        });
    };
    InputDayStoringComponent.prototype.setProfessionsArray = function () {
        var _this = this;
        this.listEmployee = [];
        this.listSortingDay = [];
        this.sortingDaySelected = {};
        this.initialTables();
        if (this.subBranch == null) {
            this.profession = {};
            this.arrayProfession = [];
            return;
        }
        this.arrayProfession = this.listProfession.filter(function (profession) {
            return profession.subBranch._id == _this.subBranch._id;
        });
    };
    InputDayStoringComponent.prototype.selectAllEmployee = function () {
        this.check.forEach(function (element) {
            element.checked = true;
        });
        this.listRemoveCandidate = this.sortingDaySelected.candidates;
    };
    InputDayStoringComponent.prototype.clear = function () {
        this.employees.forEach(function (element) {
            element.checked = false;
        });
        this.listEmployeeChecked = [];
    };
    InputDayStoringComponent.prototype.updateStatusEmployeeProfession = function (status, list) {
        var data = {
            employee: list,
            status: status
        };
        this.sortingDayService.updateStatusEmployeeProfession(data, this.profession._id).subscribe(function (data) {
        });
    };
    InputDayStoringComponent.prototype.embedEmployees = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var countCandidate, employees_1, data, employees;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                if (new Date(this.sortingDaySelected.examDate) < new Date()) {
                    this.displayModal = true;
                    this.title = "הוספת עובדים ליום מיונים שהתקיים";
                    this.content = "לא ניתן להוסיף עובדים ליום מיונים שהגיעו תוצאות לגביו";
                    this.img;
                    return [2 /*return*/];
                }
                countCandidate = this.sortingDaySelected.candidates.filter(function (candidate) {
                    return !!candidate.employeeId;
                });
                if (this.sortingDaySelected.allocation < countCandidate.length + this.listEmployeeChecked.length) {
                    employees_1 = this.sortingDaySelected.allocation - this.amountCandidate(this.sortingDaySelected);
                    this.displayModal = true;
                    this.title = "אין באפשרותך להוסיף עובדים לרשימה";
                    this.content = "\u05DE\u05D0\u05D7\u05E8 \u05D5\u05D1\u05D7\u05E8\u05EA \u05DC\u05E9\u05D1\u05E5 \u05D9\u05D5\u05EA\u05E8 \u05E2\u05D5\u05D1\u05D3\u05D9\u05DD \u05DE\u05DB\u05DE\u05D5\u05EA \u05D4\u05D4\u05E7\u05E6\u05D0\u05D4 \u05D0\u05D9\u05E0\u05DA \u05D9\u05DB\u05D5\u05DC \u05DC\u05E9\u05D1\u05E5 \u05E2\u05D5\u05D1\u05D3\u05D9\u05DD \u05D0\u05DC\u05D5. \n      \u05D9\u05E9 \u05D1\u05D0\u05E4\u05E9\u05E8\u05D5\u05EA\u05DA \u05DC\u05E9\u05D1\u05E5 " + employees_1 + " \u05E2\u05D5\u05D1\u05D3\u05D9\u05DD ";
                    this.img;
                    return [2 /*return*/];
                }
                data = {
                    employee: this.listEmployeeChecked,
                    status: CandidateStatusEnum.Embedded
                };
                employees = this.listEmployeeChecked.map(function (emp) {
                    return emp._id;
                });
                this.listEmployeeChecked = new Array();
                this.sortingDayService.createCandidates(data).subscribe(function (data) {
                    _this.updateStatusEmployeeProfession(CandidateStatusEnum.Embedded, employees);
                    var dataCandidate = data.data;
                    dataCandidate.map(function (candidate) {
                        _this.sortingDaySelected.candidates.push(candidate);
                    });
                    _this.updateStatusCandidate(_this.sortingDaySelected.candidates);
                });
                return [2 /*return*/];
            });
        });
    };
    InputDayStoringComponent.prototype.updateStatusCandidate = function (candidates) {
        var _this = this;
        var currentCandidate = candidates.filter(function (candidate) {
            return !!candidate.employeeId;
        });
        this.sortingDayService.embedEmployees(this.sortingDaySelected._id, currentCandidate).subscribe(function (data) {
            var index = _this.listSortingDay.findIndex(function (sortingDay) {
                return sortingDay._id === data.data._id;
            });
            _this.listSortingDay[index] = data.data;
            _this.sortingDaySelected = data.data;
            _this.fillTable(_this.candidate, _this.sortingDaySelected.candidates);
            _this.sortingDayService.searchEmployees(_this.profession._id).subscribe(function (data) {
                _this.listEmployee = data.data;
                _this.fillTable(_this.employee, _this.listEmployee);
            });
        });
    };
    InputDayStoringComponent.prototype.getStatusModal = function (status) {
        this.displayModal = status;
    };
    return InputDayStoringComponent;
}());
export { InputDayStoringComponent };
