import { PipeTransform } from '@angular/core';
var PhoneMaskingPipe = /** @class */ (function () {
    function PhoneMaskingPipe() {
        this.transform = function (value) {
            var args = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args[_i - 1] = arguments[_i];
            }
            if (!value)
                return;
            return value.slice(-3) + "****" + value.slice(0, 3);
        };
    }
    return PhoneMaskingPipe;
}());
export { PhoneMaskingPipe };
