<div class="container-fluid container-box">
    <div class="tabsSection">
        <h1>איתור עובדים</h1>
        <div>
            <div class="">
                <div class="filterBoxTopContent">
                    <div class="filterBoxTopContent__inner d-flex my-4 wrap-btn-info">
                        <div class="filterBoxTopContent__rightItem">
                            <i class="icon-filter ml-3"></i>
                            <span class="color-blue">סינון עובדים</span>
                        </div>

                        <button type="button" pButton
                            class="ui-button-rounded  button-matpash light-blue-border d-flex align-items-center justify-content-center fs-16  mr-auto"
                            label="איפוס סינון"
                            (click)="reset()">
                            <i class="icon-Revocation-License mr-md-3"></i>
                        </button>

                        <!-- <button class="dropDownBtn textContent  colorTextGray" (click)="toggleFilter()"
                        [class.active]="isFilterOpen">iii</button> -->
                    </div>



                    <div class="filterGridRow" *ngIf="isFilterOpen">


                        <p-multiSelect (onChange)="BrunchesSelectionChange()" defaultLabel="בחר ענף"
                            [options]="filterObject.Branches" [(ngModel)]="selectedUserFilter.Branches"
                            optionLabel="name" optionValue="code" class="multiSelect-matpash " selectedItemsLabel="{0} ערכים נבחרו">
                        </p-multiSelect>

                        <p-multiSelect (onChange)="SubBrunchesSelectionChange()" defaultLabel="בחר תת ענף"
                            [options]="filterObject.SubBranches" [disabled]=!selectedUserFilter.Branches[0]
                            [(ngModel)]="selectedUserFilter.SubBranches" optionLabel="subName" selectedItemsLabel="{0} ערכים נבחרו"
                            optionValue="subCode" [filter]="SubBranchFilter()" class="multiSelect-matpash mx-md-3">
                        </p-multiSelect>

                        <p-multiSelect defaultLabel="בחר מקצוע" [options]="filterObject.Professions"
                            [disabled]=!selectedUserFilter.SubBranches[0] [(ngModel)]="selectedUserFilter.Professions"
                            optionLabel="profession" optionValue="professionId" [filter]="professionsFilter()"
                            class="multiSelect-matpash" selectedItemsLabel="{0} ערכים נבחרו"></p-multiSelect>

                        <p-multiSelect defaultLabel="בחר נפה" [options]="filterObject.Regions" (onChange)="changeRegion()"
                            [(ngModel)]="selectedUserFilter.Regions" optionLabel="regionName" optionValue="regionId"
                            class="multiSelect-matpash mx-md-3" selectedItemsLabel="{0} ערכים נבחרו"></p-multiSelect>

                        <p-multiSelect defaultLabel=" בחר עיר" [options]="filterObject.Cities"
                            [disabled]=!selectedUserFilter.Regions[0] [(ngModel)]="selectedUserFilter.Cities"
                            optionLabel="cityName" optionValue="cityId" [filter]="cityFilter()" class="multiSelect-matpash" selectedItemsLabel="{0} ערכים נבחרו">
                        </p-multiSelect>

                    </div>

                    <div class="AdvancedFilter my-5">
                        <p-accordion>
                            <p-accordionTab #TabFilter>

                                <p-header class="d-flex align-items-center" (click)="toggleAdvancedFilter()"
                                    [class.active]="isAdvancedFilter">
                                    <i class="icon-Advanced-filtering ml-3"></i>
                                    <span>סינון מתקדם</span>

                                    <div *ngIf="!isAdvancedFilter" class="Filter-results mr-4 d-flex">

                                        <div *ngIf="selectedUserFilter.Languages[0]">
                                            <span >
                                                דובר
                                            </span>

                                            <span *ngFor="let language of selectedUserFilter.Languages">
                                                {{language.language}}
                                            </span>
                                        </div>

                                        <div *ngIf="selectedUserFilter.EmploymentStatus[0]">
                                            <span >
                                                סטטוס
                                            </span>
                                            <span *ngFor="let employmentStatus of selectedUserFilter.EmploymentStatus">
                                                {{employmentStatus.EmploymentStatus}}
                                            </span>
                                        </div>
                                        <div *ngIf="selectedUserFilter.Genders[0]">
                                            <span >מין</span>
                                            <span *ngFor="let gender of selectedUserFilter.Genders">
                                                {{gender.Gender}}
                                            </span>
                                        </div>
                                        <div *ngIf="selectedUserFilter.IsFilteredByAge">
                                            <span>
                                                טווח גילאים {{minValue}}-{{maxValue}}
                                            </span>

                                        </div>

                                        <div *ngIf="selectedUserFilter.PreviouslyEmployedByIsraeli">
                                            <span>
                                                הועסק בעבר ע"י ישראלי
                                            </span>
                                        </div>




                                    </div>
                                </p-header>

                                <div class="filterBoxBottomContent__filterBoxAdvanced">
                                    <!-- *ngIf="isAdvancedFilter" -->
                                    <div class="filterSelect">
                                        <div class="filterGridRow mb-4 my-md-5" *ngIf="isFilterOpen">

                                            <p-multiSelect defaultLabel="בחר שפה" [options]="filterObject.Languages"
                                                [(ngModel)]="selectedUserFilter.Languages" optionLabel="language"
                                                optionValue="languageId" class="multiSelect-matpash ml-md-3" selectedItemsLabel="{0} ערכים נבחרו">
                                            </p-multiSelect>

                                            <p-multiSelect defaultLabel="בחר סטטוס "
                                                [options]="filterObject.EmploymentStatus"
                                                [(ngModel)]="selectedUserFilter.EmploymentStatus"
                                                optionLabel="EmploymentStatus" optionValue="EmploymentStatusId"
                                                class="multiSelect-matpash mx-md-3" selectedItemsLabel="{0} ערכים נבחרו">
                                            </p-multiSelect>

                                            <p-multiSelect defaultLabel="בחר מגדר" [options]="filterObject.Genders"
                                                [(ngModel)]="selectedUserFilter.Genders" optionLabel="Gender"
                                                optionValue="GenderId" class="multiSelect-matpash mx-md-3" selectedItemsLabel="{0} ערכים נבחרו">
                                            </p-multiSelect>

                                            <p-checkbox name="IsSafetyTraining" [binary]="true" label="עבר הכשרת בטיחות"
                                                [(ngModel)]="selectedUserFilter.IsSafetyTraining" class="mt-3 mt-md-0 position-relative"></p-checkbox>
                                        </div>
                                        <div class="d-flex flex-column flex-md-row">
                                            <p-checkbox class="ml-4 position-relative" name="IsSafetyTraining" [binary]="true"
                                                label='הועסק בעבר ע"י ישראלי'
                                                [(ngModel)]="selectedUserFilter.PreviouslyEmployedByIsraeli">
                                            </p-checkbox>

                                            <p-checkbox class="ml-5 my-4 my-md-0 position-relative" name="IsFilteredByAge" [binary]="true"
                                                label='סימון טווח גילאים' [(ngModel)]=selectedUserFilter.IsFilteredByAge
                                                #ageRange></p-checkbox>

                                            <ng5-slider class="ng5-slider ageSlider flex-grow-1 mt-3 mt-md-0" [(value)]="minValue"
                                                [(highValue)]="maxValue" [options]="options"
                                                [class.active]="ageRange.checked">
                                            </ng5-slider>
                                        </div>
                                    </div>
                                </div>

                            </p-accordionTab>

                        </p-accordion>
                    </div>

                    <div class="filterBoxBottomContent__leftBox">
                        <button pButton type="button" 
                            class="ui-button-rounded button-matpash light-blue-fill d-flex align-items-center  mr-auto justify-content-center"
                            (click)="submitFilter(); TabFilter.selected=false">
                            <span class="mx-5">חיפוש</span>
                            <i class="icon-Locating-employees "></i>
                           
                        </button>

                        <!-- <span class="validationError textContent  colorTextRed">יש לבחור לפחות
                            שדה
                            חיפוש אחד</span> -->
                    </div>
                </div>
            </div>

            <div class="cubesSection p-0 mt-3 border-top">
                <div class="topContent d-flex">
                    <!-- <div class="result textContent  colorTextBlue">נמצאו {{resultNumber}} תוצאות</div> -->
                    <div class="filterAction d-flex align-items-center my-4">
                        <span class="inputTitle textContent ml-3">מיון לפי:</span>
                        <p-multiSelect class="" defaultLabel="תאריך פרסום" disableOptionCentering
                            panelClass="filterPanelClass" class="multiSelect-matpash multiSelect-small">
                            <!-- [defaultLabel]="defaultValueFilter" -->
                            <option> תאריך פרסום</option>
                            <option> תאריך פרסום</option>
                        </p-multiSelect>
                    </div>


                    <div class="filterBoxSummery" *ngIf="!isAdvancedFilter">

                        <div class="btnsWrapper btnsWrapper--static">
                            <button class="printBtn actionBtn colorTextBlue textContent ">
                                <img src="assets/imgs/printer.svg" alt="" />
                                הדפסה
                            </button>
                            <button class="mailBtn actionBtn colorTextBlue textContent ">
                                <img src="assets/imgs/mail.svg" alt="" />
                                שליחה במייל
                            </button>
                        </div>
                    </div>

                </div>

                <div class="noItem shadow-cube " *ngIf="!listEmployees || listEmployees.length==0">
                    <div class="noItem__inner">
                        <div class="noItem__picWrapper">
                            <img src="assets/imgs/noWorkersFound.svg" alt="">
                        </div>
                       <div class="noItem__text">
                           <div class="subTitle subTitle--semiBold colorTextGray">לא נמצאו עובדים</div>
                              <!--<div class="textContent textContent--regular colorTextGray">במידה והעובדים שחיפשת לא מופיעים
                                כאן,
                                ניתן ליצור קשר עם נציג מתפ"ש במספר 4943*</div>-->
                            <div class="btnsWrapper btnsWrapper--static ">
                                <button class="printBtn actionBtn colorTextBlue textContent ">
                                    <img src="assets/imgs/printer.svg" alt="" />
                                    הדפסה
                                </button>
                                <button class="mailBtn actionBtn colorTextBlue textContent ">
                                    <img src="assets/imgs/mail.svg" alt="" />
                                    שליחה במייל
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

<div *ngFor="let emp of listEmployees">
            <app-employee [employeeData]="emp"></app-employee>
        </div>
        </div>
    </div>
</div>