<div class="modalWrapper">
  <button mat-button mat-dialog-close class="closeBtn"></button>
  <h2
    mat-dialog-title
    class="subTitle subTitle--semiBold subTitle--blueTextColor"
  >
    {{ notificationsAndUpdate.title }}
  </h2>
  <perfect-scrollbar
    style="position: relative; max-width: 600px; max-height: 200px;"
  >
    <p class="textContent">
      {{ notificationsAndUpdate.message }}
    </p>
  </perfect-scrollbar>
  <mat-dialog-actions class="modalConfirm">
    <button
      mat-button
      mat-dialog-close
      class="btn btn--blue btn--mobileWidthBtn btn--desktopWidthBtn"
    >
      אישור
    </button>
  </mat-dialog-actions>
</div>
