import {Injectable} from '@angular/core';
import {WizradUserDataService} from "./wizrad-user-data.service";
import {FieldsRequest,} from "../../models/interfaces";
import {FormService} from "./form.service";
import {BuildingPermitService} from "./building-permit.service";
import {
  BehaviorSubject,
  Subscription
} from "rxjs";


@Injectable({
  providedIn: 'root'
})
export class FieldService {

  private selectedField:FieldsRequest = null;
  private _employmentLimitation = new BehaviorSubject<number>(0);
  readonly employmentLimitation = this._employmentLimitation.asObservable();
  private buildingPermitRefubscription: Subscription = null;

  constructor(private formService: FormService,
              private wizradUserDataService: WizradUserDataService,
              private buildingPermitService: BuildingPermitService) {
  }

  getHasZeroLimitationWithNoBuildingPermit(){
    return (this.selectedField.employmentLimitation - this.selectedField.currentEmployed) <=0;
  }

  getAvailableEmployeeSum(): number {
    return this.selectedField
      ? this._employmentLimitation.value -
      this.selectedField.currentEmployed
      : 0;
  }

  getOriginalAvailableEmployeeSum(): number {
    return this.selectedField
      ? this.selectedField.employmentLimitation -
      this.selectedField.currentEmployed
      : 0;
  }



  private setEmploymentLimitation(employmentLimitation: number): void {
    this._employmentLimitation.next(employmentLimitation);
  }

  private setField(field: FieldsRequest): void {
    this.selectedField = field;
  }

  private calcEmploymentLimitation():number{
    if(!this.selectedField){
      return 0;
    }
    const hasBuildingPermit = this.buildingPermitService.hasBuildingPermitUploaded();

    return hasBuildingPermit
      ? this._employmentLimitation.value + this.selectedField.licenseExclusion
      : this.selectedField.employmentLimitation;
  }


  hasEmploymentLimitation(): boolean {
    return this.selectedField ? !!this.selectedField.employmentLimitation : false
  }



  usedBuildingPermitLimitation(){
    const wantedWorkersValue = this.formService.getForm().get("wantedWorkers").value;
    const selectedWorkers = wantedWorkersValue ? wantedWorkersValue.employees.length  : 0;
    return selectedWorkers > this.getOriginalAvailableEmployeeSum();
  }


  init() {
    this.formService.getFormGroupRefByName("requestDetails").get('fields').valueChanges.subscribe(field => {
      this.setField(field);
      this.buildingPermitService.getBuildingPermitRef().reset();
    });

    this.buildingPermitRefubscription = this.buildingPermitService.getBuildingPermitRef().valueChanges.subscribe(bp=>{
      const employmentLimitation = this.calcEmploymentLimitation();
      this.setEmploymentLimitation(employmentLimitation);
    })
  }

  dispose() {
    this.buildingPermitRefubscription.unsubscribe();
  }
}
