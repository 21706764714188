import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root"
})

export class ScrollToService {

  constructor() {}

  scrollTo(element) {
    if(document.querySelector(element)) {
        document.querySelector(element).scrollIntoView({behavior: 'smooth'});  ;
    }
  }
}
