<div class="statusDocumentsModalWrapper generalModalWrapper">
    <button mat-button mat-dialog-close class="closeBtn"></button>
    <h2 class="subTitle subTitle--semiBold subTitle--blueTextColor">{{title}}</h2>
    <h3 class="textContent textContent--bold colorTextRed" [innerHTML]="errorMessage"></h3>
    <p class="textContent textContent--regular colorTextGray" [innerHTML]="text"></p>
 
  

  <!--<div class="contentDetails">
     <span class="textContent textContent--bold colorTextGray" *ngFor="let document of data.validDocuments">
      {{document.name}}
    </span> 
  </div>-->
    
    <mat-dialog-actions class="modalConfirm stepsBtns">
      <button mat-button mat-dialog-close (click)="closeModal()" class="btn btn--blue btn--mobileWidthBtn btn--desktopWidthBtn ">סגירה
  </button>
     
    </mat-dialog-actions>
  </div>
