<div class="container-fluid container-box">
    <div class="tabsSection">

        <div class="d-flex position-relative">
            <div class="col-md-8 p-0">
                <h1 class="mb-5">הצגת הסטוריית מעברים</h1>

                <form [formGroup]="identityFrom" (ngSubmit)="showTransitionHistory()">

                    <div class="p-inputgroup col-12 col-md-8 position-relative p-0 mt-3 mb-4">
                        <input type="text" class="input-matpash w-100" [(ngModel)]="identity" formControlName="identity"
                            pInputText placeholder="הכנס ת.ז">
                        <button type="submit" [disabled]="!identityFrom.valid" pButton label="הצג"
                            class="BtnInnerInput"></button>
                    </div>

                    <div *ngIf="identityFrom.controls.identity.errors?.required && identityFrom.controls.identity.touched"
                        class="validation-error mt-2 mb-2 d-flex align-items-center"><i class="icon-16 ml-3"></i>שדה
                        חובה</div>
                    <div *ngIf="identityFrom.controls.identity.errors?.ValidateId"
                        class="validation-error mt-2 mb-2 d-flex align-items-center"><i class="icon-16 ml-3"></i>תעודת
                        זהות/ח"פ
                        שהוזן לא תקין. אנא נסו שנית
                    </div>
                </form>

                <div class="d-md-flex align-items-center">
                    <div class="mb-4 mb-md-0 ml-5">מציג מתאריכים {{fromDate | date: "d/M/y" }} עד {{toDate
                        | date:"d/M/y" }}
                    </div>

                    <button type="button" pButton (click)="showModalDates()"
                        class="m-auto mr-md-1 ui-button-rounded  button-matpash blue-border btn-small-height d-flex align-items-center justify-content-center fs-16"
                        label="שינוי תאריך">
                        <i class="icon-calendar mr-3"></i>
                    </button>
                </div>

                <div class="color-orange d-flex align-items-center mt-3">
                    <i class="icon-16 ml-3"></i>
                    <h5>התוצאות שמוצגות תקפות רק לתקופה שבה העובד הועסק אצלך</h5>
                </div>
            </div>
            <div class="col-md-4 d-flex align-items-center mt-3 absolute-img">
                <img class="mr-auto" src="./../../../assets/imgs/TransitionHistory.png" />
            </div>
        </div>

        <div *ngIf="isPassage===true" class="d-flex align-items-center justify-content-center my-5">
            <img src="../../../assets/imgs/NOResults.png" />
            <div class="mr-4">
                <p class="color-light-blue fs-22">לא נמצאו תוצאות בתאריכים אלו</p>
                <p>ניתן ליצור קשר עם נציג מתפ"ש במספר<span class="color-blue mr-1">2909*</span></p>
            </div>
        </div>

        <p-table *ngIf="listTransitionHistory.length>0 && isPassage===false" 
            [columns]="cols" 
            [responsive]="true"
            [scrollable]="true" scrollHeight="300px" 
            [value]="listTransitionHistory"
            styleClass="p-datatable-responsive-demo" 
            class="generalAngularTable d-print-inline-flex">
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th *ngFor="let col of columns">
                     <span> {{col.header}}</span>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="columns">
                <tr class="flex-mobile">
                    <td *ngFor="let col of columns">
                        <span class="ui-column-title">{{col.header}}</span>
                        <span *ngIf="col.field=='Station'">{{rowData.Station.checkPointName}}</span>
                        <span *ngIf="col.field=='PassDate'">{{rowData.PassDate | date:'dd/MM/yyyy'}}</span>
                        <span *ngIf="col.field=='PassHour'">{{rowData.PassDate | date: "HH:mm"}}</span>
                        <span *ngIf="col.field=='IsPassageAproved'">
                            <!-- <span>{{rowData.IsPassageAproved==='false'?'סורב':'-'}} -->


                            <span *ngIf="rowData.IsPassageAproved==='false'" class="d-flex align-items-center">
                                <span class="color-red ml-2">סורב</span>
                                <span class="status not-pass mr-auto"></span>
                            </span>

                            <span *ngIf="rowData.IsPassageAproved==='true'" class="d-flex align-items-center">
                                <span class="ml-2">-</span>
                                <span class="status pass mr-auto"></span>
                            </span>


                        </span>
                </tr>
            </ng-template>
        </p-table>
    </div>

    <p-dialog [(visible)]="showModalDate" 
    class="dilog-matpash dilog-transitions-history" 
    [modal]="true"
    [responsive]="true" 
    [style]="{width: '800px', minWidth: '800px'}" 
    [minY]="70" [baseZIndex]="10000">
        <div>
            <div class="">
                <div class="d-flex flex-wrap justify-content-between mb-3 mt-4">
                    <div class="d-flex flex-column ml-md-5">
                        <span class="mb-3 text-center">מתאריך</span>
                        <p-calendar [(ngModel)]="fromDateModal" placeholder="מתאריך" dateFormat="dd/mm/yy"
                            [inline]="true" class="" [maxDate]="maxDate" [minDate]="minDate"
                            (onSelect)="validateDate()">
                        </p-calendar>
                    </div>

                    <div class="d-flex flex-column">
                        <span class="mb-3 text-center">עד תאריך</span>
                        <p-calendar [(ngModel)]="toDateModal" placeholder="עד תאריך" dateFormat="dd/mm/yy"
                            [inline]="true" class="" [maxDate]="maxDate" [minDate]="minDate"
                            (onSelect)="validateDate()">
                        </p-calendar>
                    </div>
                </div>
                <div *ngIf="showValidateDate===true">
                    תאריך יעד קטן מתאריך התחלה
                </div>
            </div>

            <div class="d-flex m-4">
                <span class="fw-bold"> {{fromDateModal | date:"dd/MM/yyyy"}} עד {{toDateModal |
                    date:"dd/MM/yyyy"}}</span>
                <div class="d-flex mr-auto ">
                    <button type="button" pButton (click)="closeModal()" [disabled]="showValidateDate===true"
                        label="המשך" class=" ui-button-rounded   button-matpash  light-blue-fill ml-4">
                    </button>
                    <button type="button" pButton (click)="showModalDate=false" label="ביטול"
                        class="ui-button-rounded button-matpash no-fill-btn"></button>
                </div>
            </div>
        </div>
    </p-dialog>
</div>