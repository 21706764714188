<div class="bgWhite">
  <app-svg
    [svgType]="modalData.submitSuccess ? 'successIco' : 'errorIco'"
  ></app-svg>
  <div class="modalMsgWrapper">
    <div
      *ngIf="modalData.submitSuccess"
      class="modalMsgContent colorTextBlue"
    >
      תודה רבה, בקשתך התקבלה! אנו מתחילים את תהליך האישור
    </div>
    <div
      *ngIf="!modalData.submitSuccess"
      class="modalMsgContent colorTextRed"
    >
      מצטערים, הבקשה לא נשלחה
    </div>
    <div class="innerMsg colorTextGray textContent textContent--semiBold">
      {{ modalData?.message }}
    </div>
    <div
      *ngIf="modalData.submitSuccess"
      class="modalRequestNumber colorTextBlue"
    >
    מספר הפנייה לצורך מעקב מול מוקד השירות: <span>{{ modalData?.requestId }}</span>
    </div>
    <!-- <p *ngIf="modalData.submitSuccess" class="textContent textContent--bold colorTextRed warning">
      ניתן לאסוף את הרישיונות רק לאחר שסטאטוס הבקשה אושרה
    </p> -->
  </div>
  <div class="stepsActions">
    <a
      (click)="goTo('requestsandlicenses')"
      *ngIf="modalData.submitSuccess"
      class="btn btn--regularBtn btn--blue btn--semiBold next"
    >
      מעבר לסטאטוס בקשות
    </a>
    <a
      (click)="goTo('employmentapplication')"
      *ngIf="!modalData.submitSuccess"
      class="btn btn--regularBtn btn--blue btn--semiBold next"
    >
      לנסות שוב
    </a>
    <a
      (click)="goTo('home')"
      class="btn btn--regularBtn btn--blueTextColor btn--semiBold prev"
    >
      חזרה לדף הבית
    </a>
  </div>
</div>
