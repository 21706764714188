<div *ngIf="requestStatusData" class="requestStatus">
  <ng-container *ngIf="requestStatusData.requestsStatus.length; then applicationTable; else noReuslt">
  </ng-container>

  <ng-template #applicationTable>
    <div class="requestStatusWrapper">

      <div class="d-md-flex align-items-center w-100">
        <!-- <div class="col-md-4 p-0">
          <label class="ml-3">סינון לפי</label>
          <p-dropdown [options]="example" placeholder='הכל' optionLabel="header" class="multiSelect-small"></p-dropdown>
        </div> -->

        <div class="col-md-4 p-0 p-md-3 my-3 my-md-0">
          <div class="d-flex align-items-center">
            <label class="ml-3">בקשות:</label>
            <p-selectButton class="selectButton-matpash w-100" [(ngModel)]="textToShow" 
              [options]="option" optionLabel="name" (onChange)="filterTable()"></p-selectButton>
          </div>
        </div>

        <div class="col-md-4 p-0 p-md-3">
          <span class="requestCurrentDate colorTextBlack textContent textContent--regular">
            עדכון אחרון: {{ date | date: "d/M/y" }}
            | {{date | date: "HH:mm"}}
          </span>
        </div>

      </div>

    </div>



    <br>
    <div id="content" #content>

      <perfect-scrollbar class="perfectScrollBar tableScrollBar" [config]="config">

        <p-table id="content" #content [columns]="displayedColumns" [value]="dataSource"
          styleClass="p-datatable-responsive-demo" [responsive]="true" [scrollable]="true" scrollHeight="300px"
          class="generalAngularTable d-print-inline-flex" id="applicationTable">

          <!-- applicationId Column -->
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th *ngFor="let col of displayedColumns" [pSortableColumn]="col.field">
                <p-sortIcon [field]="col.field"></p-sortIcon>
                <span *ngIf="col.header"> {{col.header}}
                </span>
                <span *ngIf="col.icon">
                  <i class="{{col.icon}}"></i> </span>
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData let-columns>
            <tr>

              <td *ngFor="let col of displayedColumns">
                <span class="ui-column-title">{{col.header}}</span>
                <!-- <span *ngIf="col.field!=='isLicenseInIsrael'"> {{rowData[col.field]}}</span> -->
                <span *ngIf="col.field=='applicationID'">{{rowData.applicationID}}</span>
                <span *ngIf="col.field=='fullName'">{{rowData.fullName}}</span>
                <span *ngIf="col.field=='id'">{{rowData.id}}</span>


                <span *ngIf="col.field=='licenseStatus.name'" class="statusText"
                  [class.confirm]="applicationtStatusTypes.APPROVED === rowData.applicationStatus?.code">{{
                  rowData.applicationStatus?.name }}</span>
                <span *ngIf="col.field=='isLicenseInIsrael'">
                  {{ rowData.isLicenseInIsrael ? 'ישראל' : 'התיישבות' }}
                </span>
                <span *ngIf="col.field=='declineReason'">
                    <i  *ngIf="rowData.declineReason?.name" class="icon-information" pTooltip="{{rowData?.declineReason?.name+ '\n' + rowData?.comment}}" tooltipPosition="bottom"></i>
                </span>
                <span *ngIf="col.field=='status'">
                  <span *ngIf="rowData.applicationStatus?.name=='ממתין'"pTooltip="{{rowData.applicationStatus?.name}}" tooltipPosition="bottom" tabsSection class="status Awaiting"></span>
                  <span *ngIf="rowData.applicationStatus?.name=='אושר'" pTooltip="{{rowData.applicationStatus?.name}}" tooltipPosition="bottom" tabsSection class="status Approval"></span>
                  <span *ngIf="rowData.applicationStatus?.name=='סורב'" pTooltip="{{rowData.applicationStatus?.name}}" tooltipPosition="bottom" tabsSection class="status Declined"></span>
                 </span>
              </td>
            </tr>
          </ng-template>

          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns" [ngClass]="getRowColor(row)"
            class="textContent  shadow bgWhite"></tr>
        </p-table>
        <!-- </div> -->
      </perfect-scrollbar>
    </div>
  </ng-template>

  <ng-template #noReuslt>
    <div class="wrapperInner bgWhite noReusult">
      <h2 class="subTitle subTitle--blueTextColor ">
        לא נמצאו נתונים
      </h2>
    </div>
  </ng-template>


  <div class="d-md-flex mt-4 p-requestStatusDetails">
    <span class="requestSummary textContent ml-5"> סה"כ בקשות
      <span> {{dataSource.length }}</span>
    </span>

    <div class="requestStatusDetails mb-5">
      <span class="detailItem  textContent textContent--regular">
        סה"כ אושר
        <span> {{requestStatusData.aggregatedRequestsData.aggregatedApprovalWorkersAmount }}</span>
      </span>
      <span class="detailItem  textContent textContent--regular">
        סה"כ בתהליך
        <span>{{requestStatusData.aggregatedRequestsData.aggregatedAwaitingWorkersAmount }}</span>
      </span>
      <span class="detailItem  textContent textContent--regular">
        סה"כ נדחו
        <span>{{requestStatusData.aggregatedRequestsData.aggregatedDeclinedWorkersAmount }}</span>
      </span>
    </div>
  </div>


  <p-dialog header="" [(visible)]="displayModal" [style]="{width: '350px', minWidth: '200px'}" [minY]="70" [modal]="true" [responsive]="true"
    [baseZIndex]="10000">
    {{title}}
    {{text}}
    <p-footer>
      <button type="button" pButton (click)="displayModal=false" label="סגור"></button>
    </p-footer>
  </p-dialog>