import { Injectable } from '@angular/core';
import { Observable } from 'rxjs-compat/Observable';
import { ApplicationHttpClient } from '../shared/service/ApplicationHttpClient';

@Injectable({
  providedIn: 'root'
})
export class TransitionHistoryService {

  constructor(public http:ApplicationHttpClient) { }

  getTransitionByEmployer(data:any):Observable<any>{
    data.token= sessionStorage.getItem('token');
    return this.http.Post<any>(`api/check_point/`, data)
  }
}
