import { OnInit } from '@angular/core';
import { SubBranch } from 'src/app/models/subBranch';
import { Profession } from 'src/app/models/profession';
import { CandidateStatusEnum, SortingDay } from 'src/app/models/sortingDay';
import { Validators } from '@angular/forms';
var SortingDayComponent = /** @class */ (function () {
    function SortingDayComponent(fb, storingDayService, datepipe, router) {
        this.fb = fb;
        this.storingDayService = storingDayService;
        this.datepipe = datepipe;
        this.router = router;
        this.listBranch = new Array();
        this.listSubBranch = new Array();
        this.newSub = new Array();
        this.listProfession = new Array();
        this.newProfession = new Array();
        this.listSortingDay = new Array();
        this.displayModal = false;
        this.displayModalEdit = false;
        this.displayModalDelete = false;
        this.showDateValid = new Date();
        this.fromDate = new Date();
        this.toDate = new Date();
        this.sortingDay = new SortingDay();
        this.showSortingDay = false;
        this.newSortingDay = false;
        this.invalidFromToDates = false;
        this.showContact = false;
    }
    SortingDayComponent.prototype.ngOnInit = function () {
        this.createForm();
        this.initFormControls();
        this.getAllBranches();
        this.getAllSubBranches();
        this.getAllProfessions();
    };
    SortingDayComponent.prototype.getAllBranches = function () {
        var _this = this;
        this.storingDayService.getAllBranches().subscribe(function (data) {
            _this.listBranch = data.data;
        });
    };
    SortingDayComponent.prototype.getAllSubBranches = function () {
        var _this = this;
        this.storingDayService.getAllSubBranches().subscribe(function (data) {
            _this.listSubBranch = data.data;
        });
    };
    SortingDayComponent.prototype.getAllProfessions = function () {
        var _this = this;
        this.storingDayService.getAllProfession().subscribe(function (data) {
            _this.listProfession = data.data;
        });
    };
    SortingDayComponent.prototype.createForm = function () {
        this.parameterSortingDay = this.fb.group({
            branch: [null, [Validators.required]],
            subBranch: [null, [Validators.required]],
            profession: [null, [Validators.required]],
            fromDate: [new Date(), Validators.required],
            toDate: [new Date(), Validators.required]
        });
        this.sortingDayForm = this.fb.group({
            branch: [null, [Validators.required]],
            subBranch: [null, [Validators.required]],
            professionId: [null, [Validators.required]],
            examDate: [null, [Validators.required]],
            fromHour: [null, [Validators.required]],
            toHour: [null, [Validators.required]],
            allocation: [null, [Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)]]
        });
    };
    SortingDayComponent.prototype.initFormControls = function () {
        var day = new Date();
        day.setDate(day.getDate() + 7);
        this.p.fromDate.setValue(new Date());
        this.p.toDate.setValue(day);
    };
    SortingDayComponent.prototype.changeDate = function () {
        this.showSortingDay = false;
        this.invalidFromToDates = new Date(this.p.fromDate.value).getTime() > new Date(this.p.toDate.value).getTime();
    };
    SortingDayComponent.prototype.modalAddSortingDay = function () {
        this.displayModalEdit = true;
    };
    SortingDayComponent.prototype.timeCorrect = function () {
        if (this.sortingDayForm.controls.toHour.value < this.sortingDayForm.controls.fromHour.value)
            return true;
    };
    SortingDayComponent.prototype.selectProfession = function () {
        this.showSortingDay = false;
    };
    SortingDayComponent.prototype.selectSubBranchParameter = function () {
        var _this = this;
        this.showSortingDay = false;
        if (!this.p.branch.value) {
            this.p.subBranch.setValue(new SubBranch());
            this.p.profession.setValue(new Profession());
            this.newSub = [];
            this.newProfession = [];
            this.listSortingDay = [];
            return;
        }
        this.newSub = this.listSubBranch.filter(function (sub) {
            return sub.branch._id === _this.p.branch.value._id;
        });
    };
    SortingDayComponent.prototype.selectProfessionParameter = function () {
        var _this = this;
        this.showSortingDay = false;
        if (!this.p.subBranch.value) {
            this.p.profession.setValue(new Profession());
            this.listSortingDay = [];
            this.newProfession = [];
            return;
        }
        this.newProfession = this.listProfession.filter(function (profession) {
            return profession.subBranch._id === _this.p.subBranch.value._id;
        });
    };
    SortingDayComponent.prototype.showSortingDays = function (item) {
        var _this = this;
        this.listSortingDay = [];
        var query = !!item.value ? item.value._id : this.sortingDayForm.controls.professionId.value._id;
        this.storingDayService.getSortingDays(query).subscribe(function (data) {
            _this.listSortingDay = data.data;
            _this.showSortingDay = true;
        });
        this.showSortingDay = false;
    };
    SortingDayComponent.prototype.getStatusModalEdit = function (status) {
        this.sortingDay = status.data;
        this.resetFormSortingDay();
        this.sortingDayForm.controls.branch.setValue(this.p.branch.value);
        this.sortingDayForm.controls.subBranch.setValue(this.p.subBranch.value);
        this.sortingDayForm.controls.professionId.setValue(this.p.profession.value);
        if (status.add == true) {
            var fromHour = new Date();
            var toHour = new Date();
            this.sortingDayForm.get("fromHour").setValue(new Date(fromHour.setHours(9, 0, 0, 0)));
            this.sortingDayForm.get("toHour").setValue(new Date(toHour.setHours(16, 0, 0, 0)));
            this.newSortingDay = true;
            this.displayModalEdit = true;
            return;
        }
        this.newSortingDay = false;
        var examDate = this.datepipe.transform(this.sortingDay.examDate, 'yyyy-MM-dd');
        this.sortingDay.examDate = new Date(examDate);
        this.sortingDayForm.controls.examDate.setValue(this.sortingDay.examDate);
        this.sortingDayForm.controls.fromHour.setValue(new Date(this.sortingDay.fromHour));
        this.sortingDayForm.controls.toHour.setValue(new Date(this.sortingDay.toHour));
        this.sortingDayForm.controls.allocation.setValue(this.sortingDay.allocation);
        this.displayModalEdit = status.display;
    };
    SortingDayComponent.prototype.delete = function () {
        if (new Date(this.sortingDay.examDate) < new Date()) {
            this.title = "מחיקת יום מיונים";
            this.content = "לא ניתן למחוק יום מיונים שהגיעו תוצאות לגביו";
            this.displayModal = true;
            this.img;
            return;
        }
        ;
        this.displayModalDelete = true;
    };
    SortingDayComponent.prototype.deleteSortingDay = function () {
        var _this = this;
        if (this.sortingDay.candidates) {
            var employees = this.sortingDay.candidates.map(function (emp) {
                return emp.employeeId._id;
            });
            var data = {
                employee: employees,
                status: CandidateStatusEnum.Register
            };
            this.storingDayService.updateStatusEmployeeProfession(data, this.sortingDayForm.controls.professionId.value._id).subscribe(function (data) {
            });
        }
        this.showSortingDay = false;
        this.storingDayService.deleteSortingDay(this.sortingDay._id, this.sortingDay.professionId._id).subscribe(function (data) {
            _this.displayModalDelete = false;
            _this.showSortingDay = true;
            _this.listSortingDay = data.data;
            _this.displayModalEdit = false;
        });
    };
    SortingDayComponent.prototype.resetFormSortingDay = function () {
        this.sortingDayForm.reset();
    };
    Object.defineProperty(SortingDayComponent.prototype, "f", {
        get: function () { return this.sortingDayForm.controls; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SortingDayComponent.prototype, "p", {
        get: function () { return this.parameterSortingDay.controls; },
        enumerable: true,
        configurable: true
    });
    SortingDayComponent.prototype.continue = function () {
        var _this = this;
        this.showSortingDay = false;
        if (this.newSortingDay == true) {
            this.storingDayService.create(this.sortingDayForm.value).subscribe(function (data) {
                _this.showSortingDay = true;
                _this.listSortingDay = data.data;
                _this.displayModalEdit = false;
            });
            return;
        }
        this.sortingDayForm.value._id = this.sortingDay._id;
        this.storingDayService.updateOrCreate(this.sortingDayForm.value).subscribe(function (data) {
            _this.showSortingDay = true;
            _this.listSortingDay = data.data;
            _this.displayModalEdit = false;
        });
    };
    SortingDayComponent.prototype.closeModal = function () {
        this.displayModal = false;
    };
    SortingDayComponent.prototype.getStatusModal = function (status) {
        this.displayModal = status;
    };
    return SortingDayComponent;
}());
export { SortingDayComponent };
