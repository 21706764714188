<form novalidate [formGroup]="loginForm" #loginform="ngForm" (ngSubmit)="onSubmit()" class="stepOne h-100">

  <div class="d-flex flex-column h-100">
    <div class="d-flex flex-column flex-grow-1 h-100">
      <h3 class="sub-header-login text-center text-md-right d-flex align-items-center"><i
          class="icon-identification ml-md-3"></i>הזדהות מעסיק</h3>

      <p class=" textContent--blackTextColor text-center text-md-right">
        לכניסה יש להזדהות באמצעות ת.ז / ח.פ, ולקבל קוד זיהוי חד פעמי לנייד ו/או
        לכתובת המייל
      </p>

      <button class="explanatory-link text-center text-md-right d-flex align-items-center"
        (click)="openLoginFormModal()" type="button">
        <i class="icon-Explain-how-to-open-new ml-2"></i> הסבר כיצד לפתוח תיק תעסוקה
      </button>


      <input type="tel" class="input-matpash " formControlName="identity" placeholder="נא להזין מספר ת.ז. או מספר ח.פ"
        pInputText [(ngModel)]="identity" />

      <div *ngIf="loginForm.controls.identity.errors?.required"
        class="validation-error mt-2 mb-2 d-flex align-items-center"><i class="icon-16 ml-3"></i>שדה חובה</div>
      <div *ngIf="loginForm.controls.identity.errors?.ValidateId"
        class="validation-error mt-2 mb-2 d-flex align-items-center"><i class="icon-16 ml-3"></i>תעודת זהות/ח"פ
        שהוזן לא תקין. אנא נסו שנית
      </div>
      <div *ngIf="loginForm.controls.identity.errors?.incorrect" class="validation-error mt-2 mb-2"><i
          class="icon-16"></i>
        {{ errorFromServer }}</div>

      
      <!-- <div class="row mt-3">
        <div class="col-6 d-flex align-items-center">
          <span> הקש Pin Code</span>
        </div>
        <div class="col-6">

          <div class="ui-inputgroup icon-input-matpash">
            <span class="ui-inputgroup-addon"><i class="icon-lock-code" style="line-height: 1.25;"></i></span>
            <input type="text" class="input-matpash w-100" pInputText />
          </div>



        </div>
      </div> -->


      <div class="row mb-3  mt-3">
        <div class="col-6 d-flex align-items-center">
          <span> לאן לשלוח את קוד ההזדהות?</span>
        </div>
        <div class="col-6 ui-rtl" dir="rtl">

          <p-selectButton class="selectButton-matpash w-100" [options]="options" [(ngModel)]="authType" formControlName="authType">
            <ng-template let-item>
              <div style="padding: .5em 1em">   
                  <i class="{{item.icon}}"></i>
                  <span>{{item.title}}</span> 
              </div>
          </ng-template>
          </p-selectButton>
        </div>
      </div>


    </div>
    <div class="row justify-content-end">
      <div class="col-6 ">
        <button pButton type="button" label="קבלת קוד הזדהות"
          class="ui-button-rounded w-100 button-matpash light-blue-fill d-flex align-items-center  flex-row-reverse justify-content-center"
          (click)="onSubmit()" [disabled]="!loginform.valid"><i class="icon-left-arrow mr-3"></i> </button>

      </div>
    </div>
  </div>
  <p-dialog [(visible)]="display" [modal]="true" [responsive]="true"
    [style]="{width: '900px', minWidth: '900px', maxHeight:'590px'}" [minY]="70" [baseZIndex]="10000"
    [(visible)]="display" class="small-dialog h-100 employment-dialog">


    <div class="row m-0 w-100 d-flex flex-column flex-md-row h-100 flex-md-wrap flex-nowrap">
      <div
        class="col-md-4 right-side-dialog d-flex flex-md-column justify-content-center justify-content-md-between flex-row-reverse">

        <div *ngIf="instruction" class="d-flex flex-column pt-md-5 mt-md-5  pr-md-5 pr-0 pl-md-5 pl-4 ">
          <div class="d-flex align-items-center border-bottom-gray pb-3">
            <i class="icon-phone  ml-3 color-gray"></i>
            {{instruction.phoneNo}}
          </div>
          <div class="d-flex align-items-center border-bottom-gray pb-3 pt-3">
            <i class="icon-mail ml-3 color-gray"></i>{{instruction.email}}
          </div>
          <div class="d-flex align-items-center border-bottom-gray pb-3 pt-3">
            <i class="icon-clock ml-3 color-gray"></i>{{instruction.officeHours}}
          </div>
        </div>

        <img alt="" class="mb-md-5 mb-0 px-5" src="assets/imgs/folder-img.png" />

      </div>
      <div class="col-md-8 left-side-dialog d-flex h-100 flex-column">
        <h2>
          כיצד לפתוח תיק תעסוקה
        </h2>
        <div class="row mb-5">
          <div class="col-md-6 col-7">
            <p-selectButton class="selectButton-matpash w-100" [options]="areas" optionLabel="name" (click)="change()"
              [(ngModel)]="textToShow" formControlName="text">
            </p-selectButton>
          </div>
        </div>


        <p *ngIf="instruction" class="text-right d-flex flex-grow-1 overflow-auto"> 
          <span [innerHTML]="instruction.instruction"></span>
        <div class="row justify-content-end">
          <div class="col-md-5 col-6">
            <button type="button" pButton
              class="ui-button-rounded w-100 button-matpash light-blue-fill d-flex align-items-center  flex-row-reverse justify-content-center"
              (click)="display=false" label="סגור">
              <i class="icon-left-arrow mr-3"></i>
            </button>


          </div>
        </div>
      </div>
    </div>








    <!-- 

    <p-header>כיצד לפתוח תיק תעסוקה</p-header>
    <td>
      <tr>{{listInstructionEmployeement[0].PhoneNo}}</tr>
      <tr>{{listInstructionEmployeement[0].Email}}</tr>
      <tr>{{listInstructionEmployeement[0].OfficeHours}}</tr>
    </td>

    <td>
      <span *ngIf="textToShow.ID==1" [innerHTML]="listInstructionEmployeement[0].Instruction"> </span><span
        *ngIf="textToShow.ID==2" [innerHTML]="listInstructionEmployeement[1].Instruction"></span>
    </td>
    <td>
      <p-selectButton class="selectButton-matpash w-100" [options]="areas" optionLabel="name" [(ngModel)]="textToShow" formControlName="text">
      </p-selectButton>
    </td>
    <p-footer>
      <button pButton type="button" label="אישור"
      class="ui-button-rounded w-100 button-matpash light-blue-fill" (click)="display=false" ></button>
    </p-footer> -->
  </p-dialog>
</form>
<app-message-modal *ngIf='displayModal' [title]='title' [displayModal]='displayModal' [showContact]='showContact'
  [content]='content' [img]='img' (statusToEmit)="getStatusModal($event)"></app-message-modal>















<!--
<form
  novalidate
  [formGroup]="loginForm"
  #loginform="ngForm"
  (ngSubmit)="onSubmit()"
  class="stepOne"
>
  <p class="textContent textContent--semiBold textContent--blackTextColor">
    
    לכניסה יש להזדהות באמצעות מספר ת.ז / ח.פ, ולקבל קוד זיהוי חד פעמי לנייד ו/או
    לכתובת המייל
  </p>
  <button
  class="link link--blueTextColor link--semiBold link--underLineEffect"  (click)="openLoginFormModal()"  type="button" >
  הסבר כיצד לפתוח תיק תעסוקה
</button>
<p-dialog [(visible)]="display" [modal]="true" [responsive]="true" [style]="{width: '350px', minWidth: '200px', maxHeight:'300px'}" [minY]="70" 
[maximizable]="true" [baseZIndex]="10000" 
 [(visible)]="display" [closable]="false" >
 <p-header>כיצד לפתוח תיק תעסוקה</p-header>
 <td>
  <tr>{{listInstructionEmployeement[0].PhoneNo}}</tr> 
  <tr>{{listInstructionEmployeement[0].Email}}</tr>
  <tr>{{listInstructionEmployeement[0].OfficeHours}}</tr>
 </td>
 
   <td>
    <span *ngIf="textToShow.ID==1" [innerHTML]="listInstructionEmployeement[0].Instruction" > </span><span *ngIf="textToShow.ID==2" [innerHTML]="listInstructionEmployeement[1].Instruction" ></span> 
   </td>
 <td>
 <p-selectButton [options]="areas" optionLabel="name" [(ngModel)]="textToShow" formControlName="text"></p-selectButton>
</td> 
<p-footer>
<button type="button"  (click)="display=false" label="Yes">אישור</button>
</p-footer>
</p-dialog>

  <mat-form-field class="appMatOverride" floatLabel="never">
    <input
      matInput
      type="tel"
      formControlName="identity"
      placeholder="נא להזין מספר ת.ז. או מספר ח.פ"
    />
    <mat-error *ngIf="loginForm.controls.identity.errors?.required"
      >שדה חובה</mat-error
    >
    <mat-error *ngIf="loginForm.controls.identity.errors?.ValidateId"
      >תעודת זהות/ח"פ שהוזן לא תקין. אנא נסו שנית</mat-error
    >
    <mat-error *ngIf="loginForm.controls.identity.errors?.incorrect">{{
      errorFromServer
    }}</mat-error>
  </mat-form-field>
  <div class="textContent textContent--bold textContent--blueTextColor">
    לאן לשלוח את קוד ההזדהות?
  </div>
  <mat-button-toggle-group
    #otpOption="matButtonToggleGroup"
    class="appMatOverride authSelect"
    (change)="setAuthMethod($event)"
  >
    <mat-button-toggle value="0" checked="true">
      לנייד
    </mat-button-toggle>
    <mat-button-toggle value="1">
      לכתובת המייל
    </mat-button-toggle>
  </mat-button-toggle-group>
  <button
    class="btn btn--blue btn--mobileWidthBtn"
    type="button"
    (click)="onSubmit()"
    [disabled]="!loginform.valid"
  >
    קבלת קוד הזדהות
  </button>
</form>-->