import { CurrentEmployee, CurrentEmployeesData, CancelReason } from '../models/interfaces';
import { of, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { EmployeesFilter } from '../models/EmployeesFilter';
import { Language } from '../models/metadataModels';
import * as i0 from "@angular/core";
import * as i1 from "../shared/service/ApplicationHttpClient";
import * as i2 from "../shared/service/file.service";
var EmployeeService = /** @class */ (function () {
    function EmployeeService(http, fileService) {
        this.http = http;
        this.fileService = fileService;
        this.wantedWorkersEmployeesId = [];
        this.allowRenewDays = 14;
        this.cancellationReasons = [];
        this.applicationsUrlMap = new Map();
        this.renewLicenseEmployees = new BehaviorSubject(null);
        this.renewEmployee = new BehaviorSubject(null);
        this.employeesFilter = new EmployeesFilter();
        this.employeeList = [];
        /*employeesFilter  ={
          "Branches" : [{"Active":true, "Branch" : "בניה","BranchId" : 1},{"Active":true, "Branch" : "ניקיון","BranchId" : 2},{"Active":true, "Branch" : "נהיגה","BranchId" : 3}],
          "SubBranches":[{"Active" : true,"BranchID":1,"SubBranch":"רצף","SubBranchId":1},{"Active" : true,"BranchID":1,"SubBranch":"סייד","SubBranchId":2},{"Active" : true,"BranchID":2,"SubBranch":"מנקה","SubBranchId":3},{"Active" : true,"BranchID":2,"SubBranch":"מנהל ניקיון","SubBranchId":4},{"Active" : true,"BranchID":3,"SubBranch":"נהג","SubBranchId":5},{"Active" : true,"BranchID":3,"SubBranch":"מפקח תחבורה","SubBranchId":6}],
          "Professions" : [{"ProfessionId":1,"ProfessionName":"רצף מומחה","SubBranchId" : 1},{"ProfessionId":2,"ProfessionName":"סייד מומחה","SubBranchId" : 2},{"ProfessionId":3,"ProfessionName":"נהג משאית","SubBranchId" : 5},{"ProfessionId":4,"ProfessionName":"נהג אוטובוס","SubBranchId" : 5}],
          "Areas" : [{"Active" : true,"AreaId" : 1, "AreaName" : "התיישבות"},{"Active" : true,"AreaId" : 2, "AreaName" : "ישראל"}],
          "Regions" : [{"RegionId":1,"RegionName":"שומרון"},{"RegionId":2,"RegionName":"גליל"},{"RegionId":3,"RegionName":"ירושלים"}],
          "Cities" : [{"City" : "בית אל","CityId" : 1,"RegionId" : 1},{"City" : "אפרת","CityId" : 2,"RegionId" : 1},{"City" : "חיפה","CityId" : 3,"RegionId" : 2},{"City" : "ירושלים","CityId" :4,"RegionId" : 3}],
          "EmployerCode" : 208624403,
          "EmploymentStatus":[{"EmploymentStatus":"מועסק","EmploymentStatusID":3},{"EmploymentStatus":"לא מועסק","EmploymentStatusID":2},{"EmploymentStatus":"הכל","EmploymentStatusID":1}],
          "Genders" : [{"Gender":"נקבה", "GenderID": 2},{"Gender":"זכר", "GenderID": 1}],
          "IsFikteredByAge" : false,
          "IsSafetyTraining" : false,
          "Languages" : [{"Language" : "ערבית","LanguageID" : 1},{"Language" : "עברית","LanguageID" : 2}],
          "MaxAge" : 90,
          "MinAge" : 20,
          "PreviouslyEmployedByIsraeli":  false,
        
         
      }*/
        this.EmployeesByFilter = [
            {
                "employeeId": 123456,
                "AuthorityTypes": [{ "AreaID": 2, "AreaName": "שומרון" }],
                "Residences": [{ RegionId: 1, Region: "גליל", "CityId": 1, City: "ירושלים" }],
                "Languages": [{ LanguageId: 2, Language: "עברית", "Speak": 3, "Read": 5, "Write": 7 }],
                "SafteyExam": false,
                "ExamDate": "11/10/2016, 11:49:36 AM",
                "Professions": [{ "BranchId": 2, "Branch": "בניה", "SubBranchId": 3, "SubBranch": "רצף", "ProfessionId": 1, "Profession": "טייח", "Authorized": 2, "DateAuthorization": "11/10/2016, 11:49:36 AM" }],
                "Recommendations": [2, 3, 4]
            }
        ];
    }
    EmployeeService.prototype.getCurrentEmployeesData = function (hardRelaod) {
        var _this = this;
        if (hardRelaod === void 0) { hardRelaod = false; }
        if (!hardRelaod && this.currentEmployeesData) {
            return of(this.currentEmployeesData);
        }
        return this.http
            .Get("api/statuses/getAllCurrentWorkers")
            .pipe(map(function (res) {
            _this.currentEmployeesData = res;
            return res;
        }));
    };
    EmployeeService.prototype.cancelEmployeesLicense = function (cancelLicense) {
        return this.http.Post("api/license/cancelDigitalLicence", cancelLicense);
    };
    EmployeeService.prototype.renewEmployeesLicense = function (days, renewLicense) {
        return this.http.Post("api/license/renew", { days: days, licensesArr: renewLicense });
    };
    EmployeeService.prototype.getCancelReasons = function () {
        var _this = this;
        if (this.cancellationReasons.length) {
            return of(this.cancellationReasons);
        }
        return this.http.Get("api/license/cancelReasones").pipe(map(function (reasons) {
            _this.cancellationReasons = reasons;
            return reasons;
        }));
    };
    EmployeeService.prototype.setWantedWorkersEmployees = function (employees) {
        this.wantedWorkersEmployeesId = employees.map(function (e) { return e.id; });
    };
    EmployeeService.prototype.getWantedWorkersEmployees = function () {
        return this.wantedWorkersEmployeesId;
    };
    EmployeeService.prototype.resetWantedWorkersEmployees = function () {
        this.wantedWorkersEmployeesId = [];
    };
    EmployeeService.prototype.getEmployeeLicenseUrl = function (applicationNumber) {
        var _this = this;
        if (this.applicationsUrlMap.has(applicationNumber)) {
            return of(this.applicationsUrlMap.get(applicationNumber));
        }
        return this.http.Post("api/license/", { employeeLicenceId: applicationNumber }, { responseType: 'arraybuffer', observe: 'response' }).pipe(map(function (arrayBuffer) {
            var castedBufferedFile = arrayBuffer;
            var blob = _this.fileService.ArrayBufferToBlob(castedBufferedFile);
            return URL.createObjectURL(blob);
        }));
    };
    EmployeeService.prototype.clearEmplyees = function () {
        this.currentEmployeesData = null;
    };
    EmployeeService.prototype.getAllowRenewDays = function () {
        return this.allowRenewDays;
    };
    EmployeeService.prototype.clearData = function () {
        this.clearEmplyees();
        this.cancellationReasons = [];
        this.applicationsUrlMap.clear();
    };
    //get details for employee-pool Filter
    EmployeeService.prototype.getEmployeePoolFilter = function () {
        var _this = this;
        return this.http.Get("api/search_employees/").pipe(map(function (data) {
            _this.employeesFilter = data.data;
            /*if(params)
            {
              if(params.Languages)
              {
                this.employeesFilter.Languages = params.Languages.map(a=>
                {return {Language : a.language, LanguageID : a.languageId}});
              }
              if(params.Branches)
              {
                this.employeesFilter.Branches = params.Branches.map(a=>
                {return {BranchId : a.code, Branch : a.name}});
              }
              if(params.SubBranches)
              {
                this.employeesFilter.SubBranches = params.SubBranches.map(a=>
                {return {BranchId : a.branch.code, SubBranch : a.subName, SubBranchId : a.subCode}});
              }
              if(params.Professions)
              {
                this.employeesFilter.Professions = params.Professions.map(a=>
                {return {ProfessionId : a.professionId,
                  ProfessionName : a.profession,
                  SubBranchId : a.subBranch.subCode}});
              }
              if(params.Regions)
              {
                this.employeesFilter.Regions = params.Regions.map(a=>
                {return {RegionId : a.regionId, RegionName : a.regionName}});
              }
              if(params.Cities)
              {
                this.employeesFilter.Cities = params.Cities.map(a=>
                {return { CityId : a.cityId,
                  City : a.cityName,
                  RegionId : a.regionId.regionId}});
              }
              this.employeesFilter.EmploymentStatus = params.EmploymentStatus;
              this.employeesFilter.Genders = params.Genders;
          }*/
            return _this.employeesFilter;
        }));
    };
    EmployeeService.prototype.searchEmployees = function (filter) {
        var _this = this;
        return this.http.Post("api/search_employees/search/", filter).pipe(map(function (data) {
            _this.employeeList = data.data;
            return _this.employeeList;
        }));
    };
    //getEmployeesByFilter() : Observable<Employee[]>{
    //return of(this.EmployeesByFilter) ;
    //}
    EmployeeService.prototype.LanguagesConvert = function (lang) {
        var l = new Language();
        if (lang != null) {
            l.Language = lang.language;
            l.LanguageID = lang.languageId;
        }
        return l;
    };
    EmployeeService.prototype.getAreas = function () {
        return this.http.Get("api/area/");
    };
    EmployeeService.prototype.getSubAreas = function () {
        return this.http.Get("api/sub_areas/");
    };
    EmployeeService.prototype.getEmployerTypes = function () {
        return this.http.Get("api/employer_type/");
    };
    EmployeeService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function EmployeeService_Factory() { return new EmployeeService(i0.ɵɵinject(i1.ApplicationHttpClient), i0.ɵɵinject(i2.FileService)); }, token: EmployeeService, providedIn: "root" });
    return EmployeeService;
}());
export { EmployeeService };
