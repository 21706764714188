import { Component, OnInit } from '@angular/core';
import { AlertMessages, AlertsMessagesEnum } from 'src/app/models/alertMessages';
import { AlertsMessagesEnumModel } from 'src/app/models/AlertsMessagesEnumModel';
import { AlertMessagesService } from 'src/app/services/alert-messages.service';

@Component({
  selector: 'app-alert-messages',
  templateUrl: './alert-messages.component.html',
  styleUrls: ['./alert-messages.component.scss']
})
export class AlertMessagesComponent implements OnInit {
  listMessages:Array<AlertMessages>=new Array<AlertMessages>()
  allListMessages : AlertMessages[] = []
  displayedColumns = [
    { header: 'תאריך', field: 'dateUpdated' },
    { header: 'כותרת', field: 'title' },
    { header: 'פירוט', field: 'content' },
    { header: 'מתאריך', field: 'fromDate' },
    { header: 'עד תאריך', field: 'toDate' },
    { header: 'ססטוס', field: 'statusId' }
  ]
  displayDialog:boolean;
  selectedAlertMessage : AlertMessages;
  updateMode : boolean;
  statuses : AlertsMessagesEnumModel[]=[]
  selectedValuesFilter : AlertsMessagesEnumModel[]=[]
  constructor(public alertMessagesService: AlertMessagesService ) { }

  ngOnInit() {
    this.getAllMessages();
    this.initStatuses()
  }

  getAllMessages(){
    this.alertMessagesService.getAllMessages().subscribe(data=>{
      this.listMessages=data.data;
      this.updateAlertStatus()
      this.allListMessages = this.listMessages
    })
  }

  initStatuses(){
    this.statuses = [
                     {id:AlertsMessagesEnum.Active, desc : "Active",hebrewDesc: "פעיל"},
                     {id:AlertsMessagesEnum.Draft,desc : "Draft",hebrewDesc: "מבוטל"},
                     {id:AlertsMessagesEnum.Inactive ,desc : "InActive",hebrewDesc: "לא פעיל"}
                    ]
  }

  updateAlertStatus(){
    let today= new Date();
    this.listMessages.forEach(message => {
    let messageToDate = new Date(message.toDate);
      if(messageToDate < today && message.statusId == AlertsMessagesEnum.Active) {
          message.statusId =  AlertsMessagesEnum.Inactive
        }
      });
    }
    
  addMessageAlert(){
    this.updateMode = false;
    this.displayDialog = true;
  }

  editItem(selectedAlertMessage:AlertMessages){
    this.selectedAlertMessage = selectedAlertMessage;
    this.displayDialog = true;
    this.updateMode = true;
  }

  updateAlertMessageEvent(dataToSave:any){
    if(dataToSave.delete == false){
      this.displayDialog = false;
      return;
    }
    this.displayDialog = false;
    dataToSave.alertMessage.statusId = dataToSave.status.id
    dataToSave.alertMessage.dateUpdated = new Date();
    if (!!dataToSave.alertMessage._id){
      this.alertMessagesService.updateAlertMessage(dataToSave.alertMessage).subscribe(data=>{
        this.listMessages=data.data;
        this.updateAlertStatus()
        this.allListMessages = this.listMessages
      });
      return;
    }
    this.alertMessagesService.createAlertMessages(dataToSave.alertMessage).subscribe(data=>{
      this.listMessages=data.data;
      this.updateAlertStatus()
      this.allListMessages = this.listMessages
    })
  }

  deleteAlertMessageEvent(event:AlertMessages){
    if (event._id==null) return;
    if(!!event._id){
        this.alertMessagesService.deleteAlertMessage(event._id).subscribe( data=> {
          this.listMessages=data.data;
          this.updateAlertStatus()
          this.allListMessages = this.listMessages
        })
        this.displayDialog = false;
    }
  }


  filter(){ 
    if(!this.allListMessages || !this.selectedValuesFilter) return;
    if(this.selectedValuesFilter.map(m=>m.id).includes(0)){
      this.listMessages = this.allListMessages;
      this.selectedValuesFilter = this.statuses;
      return;
    }
    this.listMessages = this.allListMessages.filter(m=> this.selectedValuesFilter.map(m=>m.id).includes(m.statusId))
  }

  getToolTip(alertMessage : any){
    var toolTipContent = new DOMParser().parseFromString(alertMessage, 'text/html');
    return toolTipContent.body.textContent || "";
  }
  

}
