import { Injectable } from '@angular/core';
import { Observable } from 'rxjs-compat/Observable';
import { InstructionEmployeement } from '../models/instructionEmployeement';
import { ApplicationHttpClient } from '../shared/service/ApplicationHttpClient';

@Injectable({
  providedIn: 'root'
})
export class AuthStepOneService {

  constructor (private http:ApplicationHttpClient){}

  readOpeningFile(areaId:number):Observable<any>{
    return this.http.Get<any>(`api/employment_instructions/active/${areaId}`);
  }
}
