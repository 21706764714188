<!-- <div class="wizardStep" [formGroup]="FormGroupRef" *ngIf="FormGroupRef"> -->
  <div class="wizardStep">
    <div class="wrapperInner bgWhite shdaow">
      <div class="stepTitle subTitle  colorTextBlack">עובדים מבוקשים</div>
      <id-numbers-form [initialData]="initialEmployeesStr"
                       redirectUrl="/requestsandlicenses/licences"
                       [selectedField]="selectedField"
                       [generalError]="generalError"
                       (onSubmitIdNumbers)="submitIdNumbers($event)">
      </id-numbers-form>
    </div>
    <section class="employeeListSection">
    <ng-container *ngFor="let employeesObj of allEmployeesResponse">
      <employee-application-list
        *ngIf="employeesObj.employees.length"
        [employeesApplicationList]="employeesObj"
        [selectedField]="selectedField"
        [licenseType]="licenseType"
        (onGoBack)="goBack()"
        (onResetStep)="resetAll()">
      </employee-application-list>
    </ng-container>
  
    <span *ngIf="fullList==false"
          class="textContent textContent--semiBold colorTextRed validationError">* חובה להוסיף עובדים לרשימת עובדים המבוקשים</span>
  </section>
  
    <stepper-buttons-component [hasBackStep]="hasBack()" [hasNextStep]="hasNext()" (onStepGoBack)="goToBackStep()"
      (onStepGoNext)="submit()"></stepper-buttons-component>
  </div>
  
