<ng-container *ngIf="showUnavailableData; then dataChecked; else dataNotChecked">
</ng-container>

<!-- DATA NOT CEHCKED -- FIRST STEP MODAL -->
<ng-template #dataNotChecked>
  <p-dialog header="" [(visible)]="display" [modal]="true" [responsive]="true" [style]="{width: '750px', minWidth: '200px'}" [minY]="70"
    [baseZIndex]="10000" class="regular-dialog welcome-modal" [showHeader]="false">
    <div class="row">
      <div class="col-7 col-md-6">
        <h2 class="fs-40">היי {{ modalData.user.user.name }}</h2>
        <div class="fs-18">
          הגשת בקשה להעסקה מתבצעת דרך האתר בתהליך אונליין.
         <span class="fw-bold"> בכל עת ניתן לשמור את התהליך ולהמשיך מאיפה שהפסקת בזמן אחר.</span>
          נא לשים לב כי בתהליך הבקשה להעסקה יש לצרף מסמכים שונים.
        </div>
      
      </div>
      <div class="col-md-6 col-5 d-flex justify-content-center">
       <img class="w-img-man" src="../../../../assets/imgs/man-pen.svg">
      </div>
    </div>
<div class="row justify-content-center justify-content-md-start mt-md-0 mt-4 mb-3 mb-md-0">
      <div class="col-md-5 col-9 ">
        <button pButton type="button" label="התחלת תהליך בקשה" type="button"
          class="ui-button-rounded w-100 button-matpash light-blue-fill d-flex align-items-center m-auto  flex-row-reverse justify-content-center max-w-180"
          (click)="checkEmployerFields()"> <i class="icon-left-arrow mr-3"></i> </button>
      </div>
</div>
  </p-dialog>
</ng-template>


<!-- DATA CEHCKED -- SECOND STEP MODAL -->
<ng-template #dataChecked>
  <p-dialog header="" [(visible)]="display" [style]="{width: '750px', minWidth: '200px'}" [minY]="70"
    [baseZIndex]="10000" [modal]="true" [responsive]="true">
    <div class="bgWhite generalModalWrapper welcomeModal">
      <div class=" contentAuto">
        <div class="subTitle subTitle--semiBold colorTextBlue">לידיעתך</div>
        <div class="textContent textContent--semiBold innerMsg">
          <p class="textContent textContent--semiBold">
            לא ניתן להגיש בקשה על הענפים הבאים:
          </p>
          <div class="contentResult">
            <span class="textContent textContent--bold colorTextRed"
              *ngFor="let unavaiableField of modalData.unavailableField.setllementsFields">
              {{unavaiableField.name}} - {{ unavaiableField.subName}}
            </span>

          </div>
          <span *ngIf="!modalData.unavailableField.allowSettlement" class="textContent textContent--bold colorTextRed">
            *אינך יכול להגיש בקשה עבור התיישבות עקב מחסור במכסת עובדים
          </span>
        </div>

        <button (click)="continueAfterFieldCheck()" type="button" class="btn btn--regularBtn btn--blue btn--semiBold">
          {{!modalData.unavailableField.allowIsrael && !modalData.unavailableField.allowSettlement ? 'חזור לדף הבית' :
          'התחלת תהליך בקשה'}}
        </button>
      </div>
    </div>
  </p-dialog>
</ng-template>