import { Component, Input, OnInit } from '@angular/core';
import jsPDF from 'jspdf';
import { DocumentType } from 'src/app/models/documentType';
import { Docs, UploadedFile } from 'src/app/models/interfaces';
import { UploadService } from 'src/app/shared/service/UploadService';
import{Employee,Residence,ProfessionsDetails, EmployeeFromSearch, EmployeeFile} from '../../models/Employee';
import FileSaver from 'file-saver'

@Component({
  selector: 'app-employee',
  templateUrl: './employee.component.html',
  styleUrls: ['./employee.component.scss']
})
export class EmployeeComponent implements OnInit {

  @Input() employeeData: EmployeeFromSearch;
  employee : Employee;
  professions : string[] = [];
  imgExist: boolean = false;
  regionName: string;
  uploadedFile: DocumentType = null;
  uploadErrorMessage = "";
  disabled? = false;
  downloadDocument?: Docs;
  date:Date=new Date();

  constructor(public uploadService:UploadService) { }

  ngOnInit() {
    this.employee = new Employee();
    //this.employee.EmployeeId = this.employeeData.lastName;
    //this.employee.EmployeeName = "חמדן איברהים"
    //this.employee.TelephoneNumber = '0527887777';
    
    //this.employee.Residence.CityId = 1;
    //this.employee.Residence.City = "אפרת";
    //this.employee.Residence.Region = "שומרון";
    //this.employee.Residence.RegionId = 2;
    //this.employee.workedInIsrael = false;//true;
    //this.employee.Languages = [{"Language":"עברית","LanguageId" : 1,"Read" : 5,"Speak" : 2,"Write": 3}]
    //this.employee.Email = "ChamdanIbrahim@gmail.com"
    /*this.employee.Professions =
    [
      {
    "Authorized" : true,
    "Branch" : "בניה",
    "BranchId" : 1,
    "DateAuthorization" : new Date('Tue Feb 05 2019 12:05:22 GMT+0530'),
    "Professions" : [{"ProfessionId" : 1,"ProfessionName": "בתים פרטיים","SubBranchId" : 1},{"ProfessionId" : 2,"ProfessionName": "בתי כנסת","SubBranchId" : 1}],
    "SubBranch" : "רצפות",
    "SubBranchId" : 3

      },
      {
        "Authorized" : false,
        "Branch" : "ניקיון",
        "BranchId" : 2,
        "DateAuthorization" : new Date('Tue Feb 05 2019 12:05:22 GMT+0530'),
        "Professions" : [{"ProfessionId" : 1,"ProfessionName": "בתים","SubBranchId" : 1},{"ProfessionId" : 2,"ProfessionName": "מוסדות","SubBranchId" : 1}],
        "SubBranch" : "כללי",
        "SubBranchId" : 3
    
      }
    ];*/
    //this.employee.Recommendations =  [ 4,2,3,4];
    //this.employee.SafteyExam = true;
    //this.employee.ExamDate = new Date('Tue Feb 05 2019 12:05:22 GMT+0530');
  }

   getToolTip( profession : ProfessionsDetails){
     if(profession && profession.Professions)
     {
        return profession.Professions.map(p=>p.ProfessionName);
     }
     else
     {
       return '';
     }
   }

   getDownloadUserFileUrl(item): string {
    var blob = new Blob([item], {type: "application/pdf"});
    FileSaver.saveAs(blob, "filename");

    this.uploadErrorMessage = "";
    return this.uploadedFile ? this.uploadService.generateDownloadFileOfUser(this.uploadedFile._id) : "javascript:void(0)"
  }

  getDownloadFileUrl(doc:EmployeeFile): string {
    this.uploadErrorMessage = "";
    return doc && !this.disabled ? this.uploadService.generateDownloadFile(Number(doc.fileTypeId.documentTypeId)) : "javascript:void(0)"
  }

}
