<svg
  class="svgIcon"
  width="13"
  height="16"
  viewBox="0 0 13 16"
  *ngIf="svgType === 'profile'"
>
  <defs>
    <path id="a" d="M0 8.51h12.439V16H0z" />
    <path id="c" d="M3.01.357h5.998V7.49H3.01z" />
  </defs>
  <g fill="none" fill-rule="evenodd" transform="translate(.496)">
    <mask id="b" fill="#fff">
      <use xlink:href="#a" />
    </mask>
    <path
      class="path"
      d="M12.439 15.198c0 .445-.341.802-.767.802H.767C.34 16 0 15.643 0 15.198v-2.185c0-1.427.81-2.764 2.087-3.344l1.193-.58a6.72 6.72 0 0 0 .937-.579c.597.357 1.278.58 2.002.58.725 0 1.406-.223 2.003-.58.298.223.596.401.937.58l1.192.58c1.236.579 2.088 1.916 2.088 3.343v2.185z"
      mask="url(#b)"
    />
    <mask id="d" fill="#fff">
      <use xlink:href="#c" />
    </mask>
    <path
      class="path"
      d="M6.01.357c1.635 0 2.998 1.605 2.998 3.566 0 1.962-1.363 3.567-2.999 3.567-1.636 0-2.999-1.605-2.999-3.567 0-1.961 1.363-3.566 3-3.566"
      mask="url(#d)"
    />
  </g>
</svg>

<svg
  class="svgIcon"
  width="15"
  height="15"
  viewBox="0 0 15 15"
  *ngIf="svgType === 'setting'"
>
  <path
    class="path"
    fill-rule="nonzero"
    d="M6.167.06a.551.551 0 0 0-.49.401l-.27 1.469a5.967 5.967 0 0 0-.386.16l-1.25-.85a.52.52 0 0 0-.636.061L1.25 3.166a.506.506 0 0 0-.057.628l.86 1.226a5.87 5.87 0 0 0-.157.381L.41 5.67A.512.512 0 0 0 0 6.16v2.637a.513.513 0 0 0 .411.484l1.485.268c.05.13.103.258.161.381l-.864 1.226a.506.506 0 0 0 .057.629l1.885 1.864a.52.52 0 0 0 .641.062l1.245-.85c.127.057.254.112.385.16l.271 1.468a.515.515 0 0 0 .49.407h2.666a.515.515 0 0 0 .49-.407l.27-1.468c.132-.049.26-.102.386-.16l1.24.85a.52.52 0 0 0 .64-.062l1.886-1.864a.506.506 0 0 0 .057-.629l-.86-1.226c.058-.122.108-.249.157-.376l1.49-.273A.512.512 0 0 0 15 8.796V6.159a.512.512 0 0 0-.411-.49l-1.485-.268a5.878 5.878 0 0 0-.161-.376l.86-1.23a.506.506 0 0 0-.058-.63l-1.886-1.864a.52.52 0 0 0-.64-.057l-1.24.845a5.936 5.936 0 0 0-.385-.16L9.323.462a.515.515 0 0 0-.49-.401H6.167zm.416.989h1.834l.244 1.324a.507.507 0 0 0 .339.38c.281.088.554.202.812.336.16.083.367.07.516-.031l1.12-.768 1.297 1.283-.776 1.112a.5.5 0 0 0-.032.51c.133.252.24.515.329.794a.51.51 0 0 0 .39.34L14 6.57v1.813l-1.344.242a.51.51 0 0 0-.39.34 4.834 4.834 0 0 1-.334.793.5.5 0 0 0 .032.51l.78 1.108-1.296 1.282-1.12-.762a.516.516 0 0 0-.516-.036 5.036 5.036 0 0 1-.812.335.507.507 0 0 0-.339.38l-.244 1.33H6.583l-.244-1.33a.507.507 0 0 0-.339-.38 5.062 5.062 0 0 1-.818-.33.516.516 0 0 0-.515.03l-1.12.763-1.292-1.277.781-1.108a.5.5 0 0 0 .032-.515 4.843 4.843 0 0 1-.339-.798.51.51 0 0 0-.39-.34L1 8.379V6.57l1.339-.242a.51.51 0 0 0 .39-.34c.088-.278.199-.543.333-.799a.5.5 0 0 0-.03-.51L2.25 3.573 3.547 2.29l1.13.768c.148.1.351.112.51.03.258-.132.533-.247.813-.334a.507.507 0 0 0 .339-.381l.244-1.324zM7.5 4.016C5.573 4.016 4 5.57 4 7.477s1.573 3.462 3.5 3.462S11 9.383 11 7.477c0-1.906-1.573-3.461-3.5-3.461zm0 .989c1.387 0 2.5 1.1 2.5 2.472A2.479 2.479 0 0 1 7.5 9.95 2.479 2.479 0 0 1 5 7.477a2.479 2.479 0 0 1 2.5-2.472z"
  />
</svg>

<svg
  class="svgIcon"
  width="16"
  height="15"
  viewBox="0 0 16 15"
  *ngIf="svgType === 'star'"
>
  <path
    class="path"
    fill-rule="nonzero"
    d="M12.598 15a.552.552 0 0 1-.256-.065l-4.358-2.244-4.348 2.244a.553.553 0 0 1-.572-.04.532.532 0 0 1-.218-.522l.829-4.762L.166 6.242a.53.53 0 0 1-.139-.552.542.542 0 0 1 .444-.364l4.86-.696L7.51.302A.546.546 0 0 1 8 0c.209 0 .4.117.49.302l2.18 4.328 4.859.696c.206.028.379.17.444.364a.53.53 0 0 1-.14.552l-3.518 3.37.828 4.76a.531.531 0 0 1-.213.536.552.552 0 0 1-.332.091zm-4.603-3.45c.087 0 .173.02.25.059l3.628 1.875-.692-3.97a.53.53 0 0 1 .158-.47L14.27 6.23l-4.053-.578a.544.544 0 0 1-.408-.295l-1.814-3.61-1.815 3.61a.544.544 0 0 1-.408.295l-4.053.578 2.93 2.812a.53.53 0 0 1 .159.472l-.692 3.969 3.628-1.875a.552.552 0 0 1 .25-.059z"
  />
</svg>

<svg
  class="svgIcon"
  width="14"
  height="18"
  viewBox="0 0 14 18"
  *ngIf="svgType === 'licence'"
>
  <path
    class="path"
    fill-rule="nonzero"
    stroke="#FFF"
    stroke-width=".3"
    d="M1.32 1.065a.357.357 0 0 0-.32.36v15.1c0 .2.158.36.353.36h11.294a.356.356 0 0 0 .353-.36V3.943a.363.363 0 0 0-.105-.253l-2.47-2.517a.35.35 0 0 0-.249-.107H1.32zm.386.72h8.118v2.157c0 .188.168.359.352.36h2.118v11.864H1.706V1.784zm8.823.505l1.269 1.292h-1.269V2.29z"
  />
</svg>

<svg
  class="svgIcon"
  width="12"
  height="13"
  viewBox="0 0 12 13"
  *ngIf="svgType === 'logout'"
>
  <g class="path" fill-rule="nonzero">
    <path
      d="M6.002 5.886a.586.586 0 0 0 .595-.578V.578A.586.586 0 0 0 6.002 0a.586.586 0 0 0-.594.578v4.73c0 .32.266.578.594.578z"
    />
    <path
      d="M9.447 2.42a.605.605 0 0 0-.828.14.568.568 0 0 0 .143.804c1.279.876 2.042 2.301 2.042 3.814 0 2.573-2.154 4.666-4.802 4.666-2.647 0-4.8-2.093-4.8-4.666 0-1.513.762-2.938 2.04-3.814a.567.567 0 0 0 .144-.805.605.605 0 0 0-.828-.14C.964 3.512.013 5.29.013 7.179.013 10.388 2.7 13 6.003 13c3.302 0 5.99-2.612 5.99-5.822 0-1.888-.952-3.667-2.546-4.758z"
    />
  </g>
</svg>
<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  width="19"
  height="18"
  viewBox="0 0 19 18"
  *ngIf="svgType === 'home'"
>
  <defs>
    <path id="a" d="M0 0h19v18H0z" />
  </defs>
  <g fill="none" fill-rule="evenodd">
    <mask id="b" fill="#fff">
      <use xlink:href="#a" />
    </mask>
    <path
      fill="#0072FF"
      stroke="#056FF2"
      stroke-width=".3"
      d="M8.129 17.048h2.743v-4.37H8.129v4.37zm9.689-8.35l-7.88-7.564a.654.654 0 0 0-.876 0L1.182 8.7a.527.527 0 0 0 0 .784c.292.28.642.28.875.056L9.18 2.704c.175-.169.467-.169.7 0l7.063 6.835c.233.224.584.224.876-.056a.527.527 0 0 0 0-.784zm-1.168 1.794V16.6c0 .785-.642 1.401-1.459 1.401H3.809c-.818 0-1.46-.616-1.46-1.4v-6.108a1.56 1.56 0 0 1-1.867-.336c-.643-.617-.643-1.513 0-2.13L8.362.462c.642-.616 1.693-.616 2.276 0l7.88 7.564c.643.617.643 1.513 0 2.13-.525.504-1.226.672-1.868.336zm-9.514 6.556v-4.875c0-.224.233-.449.525-.449h3.678c.292 0 .525.225.525.505v4.819h3.327c.234 0 .467-.225.467-.449V9.652L9.53 3.712l-6.187 5.94v6.947c0 .224.234.449.467.449h3.327z"
      mask="url(#b)"
    />
  </g>
</svg>
<svg
  xmlns="http://www.w3.org/2000/svg"
  width="17"
  height="14"
  viewBox="0 0 17 14"
  *ngIf="svgType === 'briefcase'"
>
  <path
    fill="#056FF2"
    fill-rule="nonzero"
    d="M14.72 14H2.28C1.022 14 0 12.956 0 11.672V4.859C0 3.575 1.022 2.53 2.28 2.53h2.6v-.295C4.88 1.003 5.863 0 7.07 0H9.93c1.207 0 2.188 1.003 2.188 2.235v.295h2.602C15.977 2.53 17 3.575 17 4.86v6.813C17 12.956 15.978 14 14.72 14zM10.764 2.235a.842.842 0 0 0-.832-.85H7.07a.842.842 0 0 0-.832.85v.295h4.526v-.295zm4.88 2.624a.934.934 0 0 0-.922-.943H2.28a.934.934 0 0 0-.924.943v6.813c0 .52.414.943.923.943H14.72a.934.934 0 0 0 .924-.943V4.859z"
  />
</svg>
<svg
  xmlns="http://www.w3.org/2000/svg"
  width="18"
  height="21"
  viewBox="0 0 18 21"
  *ngIf="svgType === 'pdf'"
>
  <path
    fill="#056FF2"
    fill-rule="nonzero"
    stroke="#056FF2"
    stroke-width=".6"
    d="M13.856 13.396c-.244.068-.602.076-.987.023a4.902 4.902 0 0 1-1.246-.352c.737-.102 1.31-.07 1.798.094.116.039.307.143.435.235m-4.113-.641l-.09.022c-.198.052-.39.101-.577.146l-.25.06c-.505.121-1.021.245-1.53.392.193-.443.373-.89.55-1.328.13-.324.263-.655.4-.982.07.11.143.219.22.329.345.5.78.961 1.277 1.36M8.459 7.762c.033.546-.092 1.072-.274 1.576-.224-.624-.33-1.312-.048-1.868a1.07 1.07 0 0 1 .169-.259c.059.087.137.28.153.55m-2.634 6.923a9.332 9.332 0 0 1-.387.604c-.319.455-.84.943-1.108.943-.026 0-.058-.004-.104-.05-.03-.03-.035-.052-.034-.081.01-.168.243-.466.582-.742a5.752 5.752 0 0 1 1.051-.674m8.884-1.263c-.04-.558-1.031-.916-1.04-.92a3.937 3.937 0 0 0-1.272-.19c-.506 0-1.05.069-1.751.224a5.935 5.935 0 0 1-1.564-1.524 9.23 9.23 0 0 1-.476-.763c.34-.77.645-1.599.59-2.527-.045-.744-.399-1.243-.88-1.243-.33 0-.614.231-.845.69-.412.815-.304 1.859.322 3.104a41.917 41.917 0 0 0-.638 1.526c-.252.627-.512 1.273-.804 1.888-.822.308-1.496.682-2.058 1.14-.369.3-.812.759-.838 1.238a.768.768 0 0 0 .235.598.89.89 0 0 0 .64.268c.803 0 1.576-1.046 1.722-1.256.296-.422.572-.892.842-1.435.682-.233 1.408-.408 2.112-.576l.252-.061c.19-.046.387-.096.589-.149.214-.054.434-.111.657-.165.723.436 1.5.72 2.259.824.638.089 1.206.037 1.59-.152.345-.17.364-.434.356-.54m1.555 4.796c0 1.022-.95 1.085-1.14 1.087H2.875c-1.073 0-1.138-.906-1.14-1.087V2.785c0-1.023.95-1.085 1.14-1.087h8.273l.005.004v3.062c0 .615.391 1.778 1.875 1.778h3.208l.027.026v11.647zm-.76-12.371h-2.475c-1.073 0-1.138-.902-1.139-1.08V2.403l3.614 3.441zM17 18.215V6.28l-5.11-4.866V1.39h-.024L11.456 1h-8.58C2.226 1 1 1.373 1 2.785v15.43C1 18.834 1.392 20 2.876 20h12.248c.65 0 1.876-.373 1.876-1.785"
  />
</svg>
<svg
  xmlns="http://www.w3.org/2000/svg"
  width="19"
  height="21"
  viewBox="0 0 19 21"
  *ngIf="svgType === 'personal'"
>
  <g fill="#056FF2" fill-rule="nonzero" stroke="#056FF2" stroke-width=".7">
    <path
      d="M9.345 10.35c2.659 0 4.791-2.082 4.791-4.675C14.136 3.08 12.004 1 9.346 1 6.686 1 4.554 3.111 4.554 5.705c0 2.563 2.132 4.644 4.79 4.644zm0-8.717c2.288 0 4.173 1.84 4.173 4.072s-1.885 4.041-4.173 4.041c-2.287 0-4.172-1.81-4.172-4.041 0-2.232 1.885-4.072 4.172-4.072zM1.68 19.789h15.578c.402 0 .711-.362.65-.754-1.02-4.464-4.39-7.72-8.439-7.72s-7.418 3.256-8.407 7.72c-.093.392.216.754.618.754zm7.79-7.872c3.646 0 6.86 2.986 7.82 7.239v.03H1.68h-.03v-.03c.957-4.253 4.172-7.239 7.82-7.239z"
    />
  </g>
</svg>
<svg
  xmlns="http://www.w3.org/2000/svg"
  width="18"
  height="25"
  viewBox="0 0 18 25"
  *ngIf="svgType === 'passcard'"
>
  <g fill="#056FF2" fill-rule="nonzero" stroke="#056FF2" stroke-width=".2">
    <path
      d="M16.964 6.161c0-1.378-1.101-2.5-2.454-2.5h-2.7V1.5c0-.276-.22-.5-.49-.5H6.68a.495.495 0 0 0-.49.5v2.161h-2.7c-1.353 0-2.454 1.122-2.454 2.5V21.5c0 1.378 1.101 2.5 2.454 2.5h11.02c1.353 0 2.454-1.122 2.454-2.5V6.161zM7.17 2h3.658v2.161c0 1.028-.82 1.863-1.829 1.863-1.008 0-1.829-.835-1.829-1.863V2zm8.811 19.5c0 .827-.66 1.5-1.472 1.5H3.49c-.811 0-1.472-.673-1.472-1.5V6.161c0-.827.66-1.5 1.472-1.5h2.744C6.467 6.002 7.618 7.024 9 7.024c1.382 0 2.533-1.022 2.766-2.363h2.744c.811 0 1.472.673 1.472 1.5V21.5z"
    />
    <path
      d="M11.727 7.984H6.273a.495.495 0 0 0-.49.5v6.606c0 .276.219.5.49.5h5.454c.271 0 .49-.224.49-.5V8.484c0-.276-.219-.5-.49-.5zm-.49 6.606H6.763V8.984h4.472v5.606zM11.596 19.976H6.404a.495.495 0 0 0-.491.5c0 .276.22.5.49.5h5.194c.27 0 .49-.224.49-.5s-.22-.5-.49-.5zM10.37 18.282c.27 0 .49-.224.49-.5s-.22-.5-.49-.5H7.63a.495.495 0 0 0-.49.5c0 .276.22.5.49.5h2.74z"
    />
  </g>
</svg>
<svg
  xmlns="http://www.w3.org/2000/svg"
  width="21"
  height="20"
  viewBox="0 0 21 20"
  *ngIf="svgType === 'clock'"
>
  <g fill="none" fill-rule="nonzero">
    <path
      fill="#056FF2"
      d="M10.5 20C4.701 20 0 15.523 0 10S4.701 0 10.5 0 21 4.477 21 10s-4.701 10-10.5 10z"
    />
    <path
      fill="#FFF"
      d="M14.039 12.084a.879.879 0 0 1 0 1.286.989.989 0 0 1-1.35 0l-2.864-2.727a.888.888 0 0 1-.28-.643V4.545c0-.502.428-.909.955-.909.527 0 .955.407.955.91v5.077l2.584 2.461z"
    />
  </g>
</svg>
<svg
  xmlns="http://www.w3.org/2000/svg"
  width="18"
  height="20"
  viewBox="0 0 18 20"
  *ngIf="svgType === 'trash'"
>
  <g fill="none" fill-rule="evenodd">
    <path
      fill="#056FF2"
      d="M1.059 3h15.882v3H1.059zM2.118 6h12.706v13H2.118z"
    />
    <path
      fill="#FFF"
      fill-rule="nonzero"
      d="M5.727 17.143c.452 0 .818-.32.818-.714V8.57c0-.394-.366-.714-.818-.714-.452 0-.818.32-.818.714v7.858c0 .394.366.714.818.714zM9 17.143c.452 0 .818-.32.818-.714V8.57c0-.394-.366-.714-.818-.714-.452 0-.818.32-.818.714v7.858c0 .394.366.714.818.714zM12.273 17.143c.452 0 .818-.32.818-.714V8.57c0-.394-.366-.714-.818-.714-.452 0-.818.32-.818.714v7.858c0 .394.366.714.818.714z"
    />
    <path
      fill="#056FF2"
      fill-rule="nonzero"
      d="M17.182 2.143h-4.91v-.714C12.273.639 11.54 0 10.637 0H7.364C6.46 0 5.727.64 5.727 1.429v.714H.818c-.452 0-.818.32-.818.714v2.857c0 .395.366.715.818.715h.818v11.428c0 1.184 1.1 2.143 2.455 2.143h9.818c1.356 0 2.455-.96 2.455-2.143V6.43h.818c.452 0 .818-.32.818-.715V2.857c0-.394-.366-.714-.818-.714zm-9.818-.714h3.272v.714H7.364v-.714zm7.363 16.428c0 .395-.366.714-.818.714H4.091c-.452 0-.818-.32-.818-.714V6.43h11.454v11.428zM16.364 5H1.636V3.571h14.728V5z"
    />
  </g>
</svg>
<svg
  xmlns="http://www.w3.org/2000/svg"
  width="16"
  height="16"
  viewBox="0 0 18 18"
  *ngIf="svgType === 'popover'"
>
  <g fill="none" fill-rule="nonzero">
    <path stroke="#979797" d="M9 1C4.58 1 1 4.579 1 9a8 8 0 1 0 8-8z" />
    <g fill="#7F7F7F">
      <path
        d="M9 6a1 1 0 1 0 0-2 1 1 0 0 0 0 2zM10.6 13.04c-.313-.088-.696-.346-.696-1.082V7.131a.123.123 0 0 0-.043-.093A.16.16 0 0 0 9.755 7H7.56a.602.602 0 0 0-.396.144A.462.462 0 0 0 7 7.49c.002.216.164.406.4.47.313.087.696.345.696 1.08v2.917c0 .736-.383.994-.696 1.082-.236.063-.398.253-.4.47 0 .13.059.254.164.346A.602.602 0 0 0 7.56 14h2.88c.149 0 .291-.052.396-.144A.462.462 0 0 0 11 13.51c-.002-.216-.164-.406-.4-.47z"
      />
    </g>
  </g>
</svg>
<svg
  xmlns="http://www.w3.org/2000/svg"
  width="49"
  height="49"
  viewBox="0 0 49 49"
  *ngIf="svgType === 'successIco'"
>
  <g
    fill="none"
    fill-rule="evenodd"
    stroke="#056FF2"
    stroke-linecap="round"
    stroke-linejoin="round"
    stroke-width="2"
    transform="translate(1 1)"
  >
    <circle cx="23.5" cy="23.5" r="23.5" fill="#FFF" />
    <path d="M14 22.364L21.412 30m0 0L35 16" />
  </g>
</svg>
<svg
  xmlns="http://www.w3.org/2000/svg"
  width="49"
  height="49"
  viewBox="0 0 49 49"
  *ngIf="svgType === 'errorIco'"
>
  <g fill="none" fill-rule="evenodd" transform="translate(1 1)">
    <circle
      cx="23.5"
      cy="23.5"
      r="23.5"
      fill="#FFF"
      stroke="#FF0048"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
    />
    <path
      stroke="#FF0048"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
      d="M15.375 31l16-15M31.375 31l-16-15"
    />
  </g>
</svg>
