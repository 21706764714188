<section class="personalInfoSection blueBG" *ngIf="user">
    <div class="container-fluid">
        <h2 class="">פרטים אישיים</h2>
        <form class="personalInfoForm" [formGroup]="editInfoFormGroup" [class.active]="editMode" (ngSubmit)="onSubmit()">
            <div class="personalInfoContainer">
                <div class="personalInfo">
                    <div class="personalInfo__formGroup personalInfo__name">
                        <div class="personalInfo__title">ת.ז / ח.פ</div>
                        <div class="personalInfo__text">{{user.identity}}</div>
                    </div>
                    <div class="personalInfo__formGroup personalInfo__phone">
                        <div class="personalInfo__title">מספר טלפון</div>
                        <input matInput class="personalInfo__formControl" type="tel" formControlName="phone" [readonly]="!editMode" autocomplete="off"/>
                        <span class="errorMsg" *ngIf="editInfoFormGroup.controls['phone'].errors?.required && editInfoFormGroup.controls['phone'].touched">שדה חובה</span>
                        <span class="errorMsg" *ngIf="editInfoFormGroup.controls.phone.errors?.ValidatePhone">מספר לא חוקי</span>
    
                    </div>
                    <div class="personalInfo__formGroup personalInfo__mail">
                        <div class="personalInfo__title">כתובת אימייל</div>
                        <input matInput class="personalInfo__formControl" type="email" formControlName="email" [readonly]="!editMode" />
                        <span class="errorMsg" *ngIf="editInfoFormGroup.controls['email'].errors?.required && editInfoFormGroup.controls['email'].touched">שדה חובה</span>
                        <span class="errorMsg" *ngIf="editInfoFormGroup.controls['email'].errors?.email">אמייל לא חוקי</span>
                        <div *ngIf="editMode">
                            <div class="personalInfo__title">אימות כתובת אימייל</div>
                            <input matInput class="personalInfo__formControl" type="email" formControlName="confirmEmail"
                                [readonly]="!editMode" />
                            <span class="errorMsg" *ngIf="editInfoFormGroup.controls['confirmEmail'].errors?.required && editInfoFormGroup.controls['confirmEmail'].touched">שדה חובה</span>
                            <span class="errorMsg" *ngIf="editInfoFormGroup.controls['confirmEmail'].errors?.compare">אימייל לא תואם</span>
                        </div>
    
                    </div>
                    <!--<div class="personalInfo__editBtnContainer">
                         <button type="button" class="personalInfo__editBtn" (click)="editMode=true" *ngIf="!editMode">
                            <img src="./assets/imgs/edit.png" alt="" />
                            <span>לעריכת פרטים</span>
                        </button> 
                    </div>-->
                    <div class="personalInfo__notificationMsg textContent--grayTextColor textContent--regular">
                        לשינוי טלפון או כתובת אימייל יש לפנות למוקד הטלפוני 4943*
                    </div>
                </div>
                <div class="errorMsg" *ngIf="isErrorShow">
                    עקב תקלה זמנית אין באפשרותינו לבצע את הפעולה
                </div>
            </div>
           
            <div class="btnsContainer" *ngIf="editMode">
                <button type="submit" class="btn btn--semiBold btn--blue submitBtn" [disabled]="!editInfoFormGroup.valid">שמירת פרטים</button>
                <button type="button" class="btn btn--regularBtn btn--blueTextColor btn--semiBold cancelBtn" (click)="cancelForm()">ביטול</button>
            </div>
        </form>
    </div>
</section>