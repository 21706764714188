import { BehaviorSubject } from "rxjs";
import * as i0 from "@angular/core";
import * as i1 from "./form.service";
import * as i2 from "./wizrad-user-data.service";
import * as i3 from "./building-permit.service";
var FieldService = /** @class */ (function () {
    function FieldService(formService, wizradUserDataService, buildingPermitService) {
        this.formService = formService;
        this.wizradUserDataService = wizradUserDataService;
        this.buildingPermitService = buildingPermitService;
        this.selectedField = null;
        this._employmentLimitation = new BehaviorSubject(0);
        this.employmentLimitation = this._employmentLimitation.asObservable();
        this.buildingPermitRefubscription = null;
    }
    FieldService.prototype.getHasZeroLimitationWithNoBuildingPermit = function () {
        return (this.selectedField.employmentLimitation - this.selectedField.currentEmployed) <= 0;
    };
    FieldService.prototype.getAvailableEmployeeSum = function () {
        return this.selectedField
            ? this._employmentLimitation.value -
                this.selectedField.currentEmployed
            : 0;
    };
    FieldService.prototype.getOriginalAvailableEmployeeSum = function () {
        return this.selectedField
            ? this.selectedField.employmentLimitation -
                this.selectedField.currentEmployed
            : 0;
    };
    FieldService.prototype.setEmploymentLimitation = function (employmentLimitation) {
        this._employmentLimitation.next(employmentLimitation);
    };
    FieldService.prototype.setField = function (field) {
        this.selectedField = field;
    };
    FieldService.prototype.calcEmploymentLimitation = function () {
        if (!this.selectedField) {
            return 0;
        }
        var hasBuildingPermit = this.buildingPermitService.hasBuildingPermitUploaded();
        return hasBuildingPermit
            ? this._employmentLimitation.value + this.selectedField.licenseExclusion
            : this.selectedField.employmentLimitation;
    };
    FieldService.prototype.hasEmploymentLimitation = function () {
        return this.selectedField ? !!this.selectedField.employmentLimitation : false;
    };
    FieldService.prototype.usedBuildingPermitLimitation = function () {
        var wantedWorkersValue = this.formService.getForm().get("wantedWorkers").value;
        var selectedWorkers = wantedWorkersValue ? wantedWorkersValue.employees.length : 0;
        return selectedWorkers > this.getOriginalAvailableEmployeeSum();
    };
    FieldService.prototype.init = function () {
        var _this = this;
        this.formService.getFormGroupRefByName("requestDetails").get('fields').valueChanges.subscribe(function (field) {
            _this.setField(field);
            _this.buildingPermitService.getBuildingPermitRef().reset();
        });
        this.buildingPermitRefubscription = this.buildingPermitService.getBuildingPermitRef().valueChanges.subscribe(function (bp) {
            var employmentLimitation = _this.calcEmploymentLimitation();
            _this.setEmploymentLimitation(employmentLimitation);
        });
    };
    FieldService.prototype.dispose = function () {
        this.buildingPermitRefubscription.unsubscribe();
    };
    FieldService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FieldService_Factory() { return new FieldService(i0.ɵɵinject(i1.FormService), i0.ɵɵinject(i2.WizradUserDataService), i0.ɵɵinject(i3.BuildingPermitService)); }, token: FieldService, providedIn: "root" });
    return FieldService;
}());
export { FieldService };
