import * as i0 from "@angular/core";
import * as i1 from "@auth0/angular-jwt/src/jwthelper.service";
import * as i2 from "ngx-cookie-service";
var AuthService = /** @class */ (function () {
    function AuthService(jwtHelper, cookieService) {
        this.jwtHelper = jwtHelper;
        this.cookieService = cookieService;
    }
    AuthService.prototype.isAuthenticated = function () {
        var token = sessionStorage.getItem('token');
        return !!token;
    };
    AuthService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.ɵɵinject(i1.JwtHelperService), i0.ɵɵinject(i2.CookieService)); }, token: AuthService, providedIn: "root" });
    return AuthService;
}());
export { AuthService };
