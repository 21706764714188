import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { SystemConstitutionComponent } from '../pages/managements/management-nav/pages/system-constitution/system-constitution.component';

@Injectable({
  providedIn: 'root'
})
export class UnsavedChangesGuard implements CanDeactivate<SystemConstitutionComponent> {
  canDeactivate(component: SystemConstitutionComponent, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    if (!component.unsavedChanges) {
      return true;
    }
    component.showMessega();
    return component.canDeactivate.pipe(take(1));
  }
  
  
}
