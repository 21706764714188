
<p-dialog header="" [(visible)]="showModal" 
[style]="{width: '750px', minWidth: '200px'}" [minY]="70"
[modal]="true" [autoZIndex]="true"
[responsive]="false" [blockScroll]="true"
[draggable]="false" appendTo="body" 
[baseZIndex]="10000"
class="appendTo-body">

<div class="dilog-matpash small-dialog h-100 licenses-dialog">
  <div class="row m-0 w-100 flex-column-reverse d-flex flex-column flex-md-row h-100 flex-md-wrap flex-nowrap">
   <div class="col-12 col-md-8 left-side-dialog d-flex h-100 flex-column" [formGroup]="renewLicense">
    <h1 class="">חידוש רשיון תעסוקה</h1>
    <div *ngIf="licenseLimitation">

      <p *ngIf="data.validEmployees && data.validEmployees.length" class="textContent ">
        בקשתך לחידוש רישיון עבודה עבור העובדים הבאים תישלח להמשך טיפול:
      </p>
      <div class="employeeDetails" *ngFor="let employee of data.validEmployees; let i = index; ">
        <div class="appMatOverride">
          <div class="textContent ">
            {{employee.fullName}} - {{ employee.id}}
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="data.invalidEmployees && data.invalidEmployees.length" class="employeeDetails licensesRenewWrap">
      <p class="textContent ">
        בקשתך לחידוש רישיון עבודה עבור העובדים הבאים לא תישלח להמשך טיפול:
      </p>
      <div class="content" *ngFor="let invalidEmplyeesGroup of data.invalidEmployees">
        <p *ngIf="invalidEmplyeesGroup.errorMessage" class="textContent  warning">
          {{ invalidEmplyeesGroup.errorMessage}}
        </p>
        <span class="textContent "
          *ngFor="let employee of invalidEmplyeesGroup.employees">
          {{employee.fullName}} - {{ employee.id}}
        </span>
      </div>

    </div>

    <div *ngIf="data.validEmployees && data.validEmployees.length && licenseLimitation" class="formFieldWrapper">
     <div class="d-flex align-items-center">
      <div class="textContent ml-3">
        נא להזין מס׳ ימים מבוקשים
      </div>
      <div *ngIf="data.validEmployees && data.validEmployees.length" class=" small-input appMatOverride d-flex flex-column"
        [ngClass]="{ inputRed: renewLicense.controls.license_duration.errors?.max}">

        <input pInputText formControlName="license_duration" placeholder="" type="tel" />
        <span *ngIf="renewLicense.controls.license_duration.errors?.max">
          לא ניתן לבקש יותר מ {{ licenseLimitation }} ימים
        </span>
        <span *ngIf="renewLicense.controls.license_duration.errors?.pattern">
          ניתן להזין רק מספרים
        </span>
        <span *ngIf="renewLicense.controls.license_duration.errors?.min">
          נדרש להזין 1 ימים או יותר
        </span>
      </div>
</div>
      <div class="d-flex colorTextRed align-items-center my-3">
      <i class="icon-16 ml-3"></i>
      <p class="textContent">
        שים לב, לא יהיה ניתן לשחזר פעולה זו
      </p>
    </div>

    </div>
   <div class="d-flex mt-5">
      <button type="button" pButton *ngIf="data.validEmployees && data.validEmployees.length" type="submit"
      class="ui-button-rounded  button-matpash light-blue-fill d-flex align-items-center  flex-row-reverse justify-content-center" [disabled]="renewLicense.invalid"
        [mat-dialog-close]="renewLicense.controls.license_duration.value">
        
        אישור
      </button>
      <button type="button" pButton *ngIf="!data.validEmployees || !data.validEmployees.length" type="button"
      class="ui-button-rounded  button-matpash light-blue-fill d-flex align-items-center  flex-row-reverse justify-content-center" (click)="closeModal()">
        סגירה
      </button>
      <button type="button" pButton *ngIf="data.validEmployees && data.validEmployees.length" (click)="closeModal()"
      class="ui-button-rounded button-matpash no-fill-btn ml-4" label="ביטול">
        
      </button>
    </div>
    
  </div>

  <div class="col-12 col-md-4 right-side-dialog d-flex flex-md-column justify-content-center justify-content-md-between flex-row-reverse">
    <img alt="" class="mb-0 mb-md-5 mr-n5 mt-5 img-header-mobile" src="../../../../../assets/imgs/LicenseRenewal.png" />
    

  </div>

</div>
</div>
</p-dialog>