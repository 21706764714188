import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { StepPageObject } from "../../models/interfaces";


@Injectable({
  providedIn: 'root'
})
export class StepPagesService {
  constructor() { }

  private arrayOfStepsSubject = new BehaviorSubject<StepPageObject[]>([]);

  private wizardTitleSubject = new BehaviorSubject<string>('הגשת בקשה לרישיונות תעסוקה');

  public buildSteps(steps: StepPageObject[]): void {
    this.arrayOfStepsSubject.next(steps)
  }

  public getSteps(): Observable<StepPageObject[]> {
    return this.arrayOfStepsSubject.asObservable();
  }

  public getTitle(): Observable<string> {
    return this.wizardTitleSubject.asObservable();
  }

  public setStep(newStep: StepPageObject) {
    const arrayOfSteps = [ ...this.arrayOfStepsSubject.value ];
    arrayOfSteps.push(newStep);
    this.arrayOfStepsSubject.next(arrayOfSteps);
  }
  public setStepAtIndex(newStep: StepPageObject, index, title?: string) {
    const arrayOfSteps = [ ...this.arrayOfStepsSubject.value ];
    arrayOfSteps.splice(index, 0, newStep);
    this.arrayOfStepsSubject.next(arrayOfSteps);
    if (title) {
      this.wizardTitleSubject.next(title);
    }

  }

  public getStepIndex(step: StepPageObject) {
    return this.arrayOfStepsSubject.value.findIndex(s => s.component === step);
  }

  public stepExisitsByName(step: StepPageObject) {
    return this.arrayOfStepsSubject.value.some(s => s.component === step.component);
  }

  public removeStepAtIndex(stepIndex: number, title?: string) {
    const arrayOfSteps = [ ...this.arrayOfStepsSubject.value ];
    arrayOfSteps.splice(stepIndex, 1);
    this.arrayOfStepsSubject.next(arrayOfSteps);
    if (title) {
      this.wizardTitleSubject.next(title);
    }

  }

  public removeStepByComponentName(stepComponent: StepPageObject) {
    const stepIndex = this.arrayOfStepsSubject.value.findIndex(step => step.component === stepComponent.component);
    if (stepIndex === -1) {
      return;
    }
    const arrayOfSteps = [ ...this.arrayOfStepsSubject.value];

    arrayOfSteps.splice(stepIndex, 1);
    this.arrayOfStepsSubject.next(arrayOfSteps);
  }

  resetSteps() {
    this.arrayOfStepsSubject.next([]);
  }
}
