/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./read-more-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/dialog";
import * as i3 from "../../../../../../node_modules/ngx-perfect-scrollbar/dist/ngx-perfect-scrollbar.ngfactory";
import * as i4 from "ngx-perfect-scrollbar";
import * as i5 from "./read-more-modal.component";
var styles_ReadMoreModalComponent = [i0.styles];
var RenderType_ReadMoreModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ReadMoreModalComponent, data: {} });
export { RenderType_ReadMoreModalComponent as RenderType_ReadMoreModalComponent };
export function View_ReadMoreModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [["class", "modalWrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "button", [["class", "closeBtn"], ["mat-button", ""], ["mat-dialog-close", ""]], [[1, "aria-label", 0], [1, "type", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).dialogRef.close(i1.ɵnov(_v, 2).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 606208, null, 0, i2.MatDialogClose, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], { dialogResult: [0, "dialogResult"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 2, "h2", [["class", "subTitle subTitle--semiBold subTitle--blueTextColor mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i1.ɵdid(4, 81920, null, 0, i2.MatDialogTitle, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], null, null), (_l()(), i1.ɵted(5, null, [" ", " "])), (_l()(), i1.ɵeld(6, 0, null, null, 3, "perfect-scrollbar", [["style", "position: relative; max-width: 600px; max-height: 200px;"]], [[2, "ps-show-limits", null], [2, "ps-show-active", null]], null, null, i3.View_PerfectScrollbarComponent_0, i3.RenderType_PerfectScrollbarComponent)), i1.ɵdid(7, 507904, null, 0, i4.PerfectScrollbarComponent, [i1.NgZone, i1.ChangeDetectorRef, i1.PLATFORM_ID], null, null), (_l()(), i1.ɵeld(8, 0, null, 0, 1, "p", [["class", "textContent"]], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, [" ", " "])), (_l()(), i1.ɵeld(10, 0, null, null, 4, "mat-dialog-actions", [["class", "modalConfirm mat-dialog-actions"]], null, null, null, null, null)), i1.ɵdid(11, 16384, null, 0, i2.MatDialogActions, [], null, null), (_l()(), i1.ɵeld(12, 0, null, null, 2, "button", [["class", "btn btn--blue btn--mobileWidthBtn btn--desktopWidthBtn"], ["mat-button", ""], ["mat-dialog-close", ""]], [[1, "aria-label", 0], [1, "type", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 13).dialogRef.close(i1.ɵnov(_v, 13).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(13, 606208, null, 0, i2.MatDialogClose, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], { dialogResult: [0, "dialogResult"] }, null), (_l()(), i1.ɵted(-1, null, [" \u05D0\u05D9\u05E9\u05D5\u05E8 "]))], function (_ck, _v) { var currVal_2 = ""; _ck(_v, 2, 0, currVal_2); _ck(_v, 4, 0); _ck(_v, 7, 0); var currVal_10 = ""; _ck(_v, 13, 0, currVal_10); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (i1.ɵnov(_v, 2).ariaLabel || null); var currVal_1 = i1.ɵnov(_v, 2).type; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_3 = i1.ɵnov(_v, 4).id; _ck(_v, 3, 0, currVal_3); var currVal_4 = _co.notificationsAndUpdate.title; _ck(_v, 5, 0, currVal_4); var currVal_5 = i1.ɵnov(_v, 7).autoPropagation; var currVal_6 = i1.ɵnov(_v, 7).scrollIndicators; _ck(_v, 6, 0, currVal_5, currVal_6); var currVal_7 = _co.notificationsAndUpdate.message; _ck(_v, 9, 0, currVal_7); var currVal_8 = (i1.ɵnov(_v, 13).ariaLabel || null); var currVal_9 = i1.ɵnov(_v, 13).type; _ck(_v, 12, 0, currVal_8, currVal_9); }); }
export function View_ReadMoreModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-read-more-modal", [], null, null, null, View_ReadMoreModalComponent_0, RenderType_ReadMoreModalComponent)), i1.ɵdid(1, 114688, null, 0, i5.ReadMoreModalComponent, [i2.MatDialogRef, i2.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ReadMoreModalComponentNgFactory = i1.ɵccf("app-read-more-modal", i5.ReadMoreModalComponent, View_ReadMoreModalComponent_Host_0, {}, {}, []);
export { ReadMoreModalComponentNgFactory as ReadMoreModalComponentNgFactory };
