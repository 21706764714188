import { Component, OnInit, ViewChild, } from "@angular/core";
import { FormGroup, FormControl, FormArray, Validators } from "@angular/forms";
import { FormService } from "../../service/form.service";
import {
  Observable,
  of,
  pipe
} from "rxjs";
import { EmptyObservable } from "rxjs/observable/EmptyObservable";
import {
  debounceTime,
  filter,
  map,
  tap,
  switchMap,
  takeUntil
} from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { StepComponentBase } from "../wizard-step-component-base/step-component-base";
import { StepPagesService } from "../../service/step-pages.service";
import { MatDialog, MatAutocompleteTrigger } from "@angular/material";
import { WizardModalComponent } from "../../shared/wizard-modal/wizard-modal.component";
import { WizradUserDataService } from "../../service/wizrad-user-data.service";
import { BuildingPermitService } from "../../service/building-permit.service";
import {
  EmploymentBureau,
  Field,
  FieldsRequest,
  GeneralModalData,
  RequestData,
  Settlement,
  WorkingHour
} from "../../../models/interfaces";
import { FieldService } from "../../service/field.service";
import { GeneralModalComponent } from "../../../shared/components/general-modal/general-modal.component";
import regionsTypes from "src/app/shared/util/regionTypes";

@Component({
  selector: "app-request-details-step",
  templateUrl: "./request-details-step.component.html",
  styleUrls: ["./request-details-step.component.scss"]
})
export class RequestDetailsStepComponent extends StepComponentBase
  implements OnInit {
  FormGroupRef: FormGroup = null;
  transactionGroupRef: FormGroup = null;
  settlementsArrayRef: FormArray = null;
  isLoading = false;
  wantedWorkplaceSearch = new FormControl();
  wantedWorkPlacesOptions: Observable<Settlement[]>;
  fields: FieldsRequest[] = [];
  workingHours: WorkingHour[] = [];
  employmentBureaus: EmploymentBureau[] = [];
  downloadDocuments: any = null;
  filteredOptions: Observable<Settlement[]>;
  showNotSelectedWarning = false;
  showNoResultError = false;
  selectedField: FieldsRequest;
  showBuildingPermitError = false;
  showWorkingHoursFileWarning = false;
  @ViewChild(MatAutocompleteTrigger, { static: false })
  autocomplete: MatAutocompleteTrigger;
  submitted: boolean = false;
  isIsraelRegion: boolean = true;

  constructor(
    stepsPagesService: StepPagesService,
    dialog: MatDialog,
    private formService: FormService,
    private wizradUserDataService: WizradUserDataService,
    private http: HttpClient,
    public buildingPermitService: BuildingPermitService,
    private fieldService: FieldService
  ) {
    super(stepsPagesService, dialog);
  }

  ngOnInit() {
    super.ngOnInit();
    this.FormGroupRef = this.formService.getFormGroupRefByName(
      "requestDetails"
    );
    this.transactionGroupRef = this.formService.getFormGroupRefByName(
      'transactionArea'
    );

    this.wizradUserDataService.requestData.pipe(takeUntil(this.componentDestroyed)).subscribe((requestData: RequestData) => {
      // on initializing the app
      this.isIsraelRegion = this.transactionGroupRef.get('region').value == regionsTypes.ISRAEL;
      // call func for all initialization
      this.initUpdate(requestData);

      // listen to region valie changes - 1 for israel, 2 for settelments
      this.transactionGroupRef.get('region').valueChanges.subscribe((controlValue) => {
        // remove error for unselected settelemts autocomplete
        this.showNotSelectedWarning = false;
        // reset submit
        this.submitted = false;
        // bool for israel selected or settelments
        this.isIsraelRegion = controlValue == regionsTypes.ISRAEL;
        // call func for all initialization
        this.initUpdate(requestData);
      });

      this.downloadDocuments = requestData.downloadDocuments;
      //reset steps  -> build steps
    });

    this.wantedWorkplaceSearch.valueChanges
      .pipe(
        debounceTime(500),
        filter(val => !!val),
        map(value => (typeof value === "string" ? value : "")),
        filter(value => value.length > 1),
        // tap((value) => this.deletePrevValuesAndClosePanel(value)),
        tap(() => (this.isLoading = true)),
        switchMap(value => this.wizradUserDataService.getSettlements(value)),
        takeUntil(this.componentDestroyed)
      )
      .subscribe(
        filteredSettlements => {
          const filteredSettlementsAsObservable = of(
            filteredSettlements
          );
          this.wantedWorkPlacesOptions = filteredSettlementsAsObservable;
          this.showNoResultError =
            filteredSettlements.length === 0;
          this.showNotSelectedWarning = false;
          this.isLoading = false;
        },
        err => {
          this.wantedWorkPlacesOptions = null;
          this.isLoading = false;
        }
      );
  }

  // initalization and on region update
  initUpdate(requestData: RequestData) {
    if (this.isIsraelRegion) {
      // disable field if israel region
      this.FormGroupRef.get("working_hours").disable();
    }

    // in case of selected other hour in the working_hours DD and required to upload files -> remove the control for every change
    this.FormGroupRef.removeControl("files");

    this.workingHours = requestData.workingHour;

    //call for filterd fields;
    this.fields = this.getFilterdFields(requestData.fields);

    //sort working hours by isDefault value

    this.workingHours.sort((a, b) => (b.isDefault ? 1 : -1));
    if (this.isIsraelRegion) {
      if (this.FormGroupRef.get("fields").value != null) {
        this.FormGroupRef.get("working_hours").setValue(this.workingHours[0]);
      } else {
        this.FormGroupRef.get("working_hours").setValue(null);
      }
    }
    else if (!this.isIsraelRegion) {
      // if settelments region
      this.FormGroupRef.get("working_hours").setValue(this.workingHours[0]);
    }

    this.settlementsArrayRef = this.FormGroupRef.get("settlements") as FormArray;
  }

  getFilterdFields(fields: FieldsRequest[]) {
    let filterFields: FieldsRequest[] = [];
    // filter fields by region
    if (this.isIsraelRegion) {
      filterFields = fields.filter((field: FieldsRequest) => field.licenseType == regionsTypes.ISRAEL);
      // remove valiators for settelments fields
      this.requestDetailsFormValidators();
    } else {
      filterFields = fields.filter((field: FieldsRequest) => field.licenseType == regionsTypes.SETTELMENTS);
      // add valiators for settelments fields
      this.resetRequestGroupValidators();
    }

    if (filterFields.length === 1) {
      this.setDefaultField(filterFields[0]);
    }

    return filterFields;
  }

  getFilteredHours(workingHours: WorkingHour[]) {
    // get filtered working hours by secondary type
    this.workingHours = workingHours.filter(workHour => workHour.secondaryType.includes(this.FormGroupRef.get('secondaryType').value.secondryType))
    this.FormGroupRef.get("working_hours").setValue(this.workingHours[0]);
  }

  requestDetailsFormValidators() {
    if (this.isIsraelRegion) {
      Object.keys(this.FormGroupRef.controls).forEach(key => {
        const control = this.FormGroupRef.controls[key];
        if (key != 'fields' &&
          key != 'working_hours' &&
          key != 'loging') {
          control.clearValidators();
        } else {
          control.setValidators([Validators.required])
        }
        control.updateValueAndValidity({
          onlySelf: true
        });
      })
    }
  }

  resetRequestGroupValidators() {
    if (this.settlementsArrayRef) {
      this.settlementsArrayRef.setValidators([Validators.required, Validators.maxLength(4)]);
      this.settlementsArrayRef.controls.forEach((settelment, index) => {
        this.settlementsArrayRef.removeAt(index);
      })
      this.settlementsArrayRef.updateValueAndValidity({ onlySelf: true });
    }


    this.FormGroupRef.get("loging").clearValidators();
    this.FormGroupRef.get("loging").updateValueAndValidity({ onlySelf: true });
  }

  setDefaultField(field: FieldsRequest) {
    this.selectedField = field;
    this.FormGroupRef.get("fields").setValue(field);
    this.setLicenseDurationValidations();
    this.checkForRenovation();
    if (this.isIsraelRegion) {
      this.FormGroupRef.get('secondaryType').setValue(field.secondaryType)
      this.getFilteredHours(this.workingHours);
      this.enableHoursField();
    }
    // this.checkForBuildingPermitModal();
  }
  displayFn(value?: Settlement): string | undefined {
    return value ? value.name : undefined;
  }

  addWorkPlaces(evt: any) {
    const selectedSettlement = evt.option.value as Settlement;
    const newSettlementsControl = this.formService.createControl();
    newSettlementsControl.setValue(selectedSettlement);
    if (
      !this.settlementsArrayRef.controls.find(workPlaceControl => {
        const cureentSettlement = workPlaceControl.value as Settlement;
        return cureentSettlement.name === selectedSettlement.name;
      })
    ) {
      this.settlementsArrayRef.push(newSettlementsControl);
      this.addEmploymentBureaus(selectedSettlement);
    }

    if (this.employmentBureaus.length === 1) {
      this.FormGroupRef.get("employment_bureau").setValue(
        this.employmentBureaus[0]
      );
    }

    this.clearWantedWorkplaceSearchData();
    this.wantedWorkPlacesOptions = null;
    this.wantedWorkplaceSearch.setValue("");
  }

  clearWantedWorkplaceSearchData() {
    this.wantedWorkplaceSearch.setValue("");
    this.wantedWorkPlacesOptions = new EmptyObservable<Settlement[]>();
  }
  addEmploymentBureaus(selectedsettlement: Settlement) {
    if (
      !this.employmentBureaus.find(
        currentBureau =>
          currentBureau.name === selectedsettlement.employmentBureau.name
      )
    ) {
      this.employmentBureaus.push(selectedsettlement.employmentBureau);
    }
  }

  deleteEmploymentBureaus(selectedsettlement: Settlement) {
    const indexOfBureau = this.employmentBureaus.findIndex(
      (currentBureau, index) =>
        currentBureau.name === selectedsettlement.employmentBureau.name
    );
    const countedBureau = this.settlementsArrayRef.controls.reduce(
      (acc, currentSettlement) => {
        const curSettlement = currentSettlement.value as Settlement;
        if (
          curSettlement.employmentBureau.name ===
          selectedsettlement.employmentBureau.name
        ) {
          acc++;
        }
        return acc;
      },
      0
    );
    if (indexOfBureau > -1 && countedBureau < 1) {
      this.employmentBureaus.splice(indexOfBureau, 1);
    }
  }

  checkField(evt: any) {
    this.selectedField = evt.value as FieldsRequest;
    this.showBuildingPermitError = false;

    // if israel region - calculate secondary type by selected field
    if (this.isIsraelRegion) {
      this.FormGroupRef.get('secondaryType').setValue(this.selectedField.secondaryType)
      this.getFilteredHours(this.workingHours);
    }
    this.setLicenseDurationValidations();
    this.setEmploymentBureauValidation();
    this.enableHoursField();
    this.checkForRenovation();
  }

  enableHoursField() {
    this.FormGroupRef.get('working_hours').enable();
  }

  checkForRenovation() {
    if (this.selectedField.code === 4 && !this.isIsraelRegion) {
      this.FormGroupRef.addControl(
        "renovating",
        this.formService.createControl(null, [Validators.required])
      );

      if (!this.selectedField.employmentLimitation) {
        return;
      }

      this.FormGroupRef.get("renovating").valueChanges.pipe(takeUntil(this.componentDestroyed)).subscribe(isRenovating => {
        if (!isRenovating) {
          this.checkForBuildingPermitModal();
        } else {
          //The Order Of This Code Is Important, The Reset, Makes The Field Service To Update The Sum!
          if (this.buildingPermitService.hasBuildingPermitUploaded()) {
            this.buildingPermitService.getBuildingPermitRef().reset();
          }

          this.showBuildingPermitError = false;
          this.validateZeroLimitation();
          // this.checkForOpenConfirmRenovatingChange();
        }
      })
    } else {
      this.FormGroupRef.removeControl("renovating");
    }

  }

  checkForBuildingPermitModal() {
    if (this.selectedField.code === 4 && this.buildingPermitService.allowBuildingPermitUpload()) {
      this.openBuildingPermitModal()
    }
  }

  //Keep This Code In Comment In Case That They Want The Confirm Modal Again

  // checkForOpenConfirmRenovatingChange() {
  //   if(this.buildingPermitService.hasBuildingPermitUploaded()){
  //   const data:ConfirmModalData ={
  //     header:"שים לב",
  //     message:"שינוי זה יגרום לאיפוס המידע שמילאת עד כה בכל שלבי הבקשה. "
  //   };
  //   const dialogRef = this.openModal(ConfirmModalComponent,data,{panelClass: "generalModal"});
  //   dialogRef.afterClosed().subscribe(confirm => {
  //     if (confirm) {
  //       this.showBuildingPermitError = false;
  //       this.validateZeroLimitation()
  //       this.buildingPermitService.getBuildingPermitRef().reset();
  //     }
  //   });
  //   }else {
  //     this.showBuildingPermitError = false;
  //     this.validateZeroLimitation()
  //   }
  // }

  setLicenseDurationValidations() {
    const licenseDurationRef = this.FormGroupRef.get("license_duration");
    licenseDurationRef.clearValidators();
    if (!this.isIsraelRegion) {
      licenseDurationRef.setValidators([
        Validators.required,
        Validators.min(1),
        Validators.pattern("^[0-9]*$"),
        Validators.max(+this.selectedField.licenseLimitation)
      ]);
      licenseDurationRef.updateValueAndValidity({
        onlySelf: true
      });
    }
  }

  setEmploymentBureauValidation() {
    const employmentBureauRef = this.FormGroupRef.get("employment_bureau");
    employmentBureauRef.clearValidators();
    if (!this.isIsraelRegion) {
      employmentBureauRef.setValidators([
        Validators.required
      ]);
      employmentBureauRef.updateValueAndValidity({
        onlySelf: true
      });
    }
  }

  openBuildingPermitModal() {
    const filedDownloadDocuments = this.buildingPermitService.getBuildingPermitDownloadDocument();
    const data = {
      selectedField: this.selectedField,
      downloadDocument: filedDownloadDocuments,
      documentRef: this.buildingPermitService.getBuildingPermitRef() as FormControl
    };
    const dialogRef = this.openModal(WizardModalComponent, data, { disableClose: false });
    dialogRef.afterClosed().subscribe(result => {
      const hasUploaded = this.buildingPermitService.hasBuildingPermitUploaded();
      this.showBuildingPermitError = !hasUploaded;
    });
  }

  openZeroLimitationModal() {
    const data: GeneralModalData = {
      title: "שים לב",
      errorMessage: "לא ניתן להמשיך בתהליך מכיוון שלא קיימת מכסה פנויה להעסקה"
    };

    this.openModal(GeneralModalComponent, data, { panelClass: "generalModal" });
  }

  checkWorkingHoures(evt: any) {
    this.showWorkingHoursFileWarning = false;
    const selectedWorkingHour = evt.value as WorkingHour;
    if (this.isIsraelRegion) {
      return;
    }

    if (!selectedWorkingHour.isDefault) {
      this.FormGroupRef.addControl(
        "files",
        this.formService.createControl(null, [Validators.required])
      );
    } else {
      this.FormGroupRef.removeControl("files");
    }
  }

  setRenovatingValue(evt: any) {
    this.FormGroupRef.get("renovating").setValue(evt.value)
  }

  setLogingValue(evt: any) {
    this.FormGroupRef.get("loging").setValue(+evt.value)
  }

  deleteSettlement(index: number, settlement: Settlement) {
    this.settlementsArrayRef.removeAt(index);
    this.deleteEmploymentBureaus(settlement);
    if (this.employmentBureaus.length === 1) {
      this.FormGroupRef.get("employment_bureau").setValue(
        this.employmentBureaus[0]
      );
    }
  }

  popOverClicked(evt: any, popBtn) {
    popBtn.clicked = !popBtn.clicked;
  }

  panelClosed(evt: any) {
    this.validateWantedWorkplace();
  }

  validateWantedWorkplace() {
    this.showNotSelectedWarning = !this.settlementsArrayRef.controls.length;
  }
  uploadFileWorkingHoursFile() {
    this.showWorkingHoursFileWarning = false;
  }

  validateWorkingHoursUploadFile() {
    const working_hours_file_ref = this.FormGroupRef.get('files');
    const isValid = working_hours_file_ref ? working_hours_file_ref.valid : true;
    this.showWorkingHoursFileWarning = !isValid;
    return isValid;
  }

  isBuildingPermitNeeded() {
    if (this.wizradUserDataService.initialHasPermit || !this.selectedField) {
      return false;
    }

    return this.selectedField.code === 4 && this.selectedField.employmentLimitation
      && (!this.FormGroupRef.get("renovating") || this.FormGroupRef.get("renovating").value === false)
      && this.selectedField.currentEmployed >= this.selectedField.employmentLimitation;
  }

  validatePermit() {
    //Has Uploaded BuildingPermit
    const hasUploaded = this.buildingPermitService.hasBuildingPermitUploaded();
    this.showBuildingPermitError = !hasUploaded;
    return hasUploaded;
  }

  validateZeroLimitation() {
    if (!this.fieldService.hasEmploymentLimitation()) {
      return true;
    }

    if (this.fieldService.getAvailableEmployeeSum() > 0) {
      return true;
    }

    this.openZeroLimitationModal();
    return false;
  }


  submit() {
    //Ugly Code Due To The Fact The Form Validation Is Made By The Mat-Stepper
    if (!this.FormGroupRef.valid) {
      this.validateWantedWorkplace();
      this.validateWorkingHoursUploadFile();
      this.validateZeroLimitation();
      if (this.isBuildingPermitNeeded()) {
        this.validatePermit();
      }
      super.submit(); // To Trigger The Mat-Stepper Validation
    }
    else {
      if ((!this.isBuildingPermitNeeded() || (this.isBuildingPermitNeeded() && this.validatePermit())) && this.validateZeroLimitation()) {
        super.submit();
      }
    }

    this.submitted = true;
  }
}
