import { OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
var WelcomeModalComponent = /** @class */ (function () {
    function WelcomeModalComponent(dialogRef, userDataService, router, data) {
        this.dialogRef = dialogRef;
        this.userDataService = userDataService;
        this.router = router;
        // unavailableFieldsData: UnavailableField;
        this.showUnavailableData = false;
        this.display = false;
        this.modalData = data;
    }
    WelcomeModalComponent.prototype.ngOnInit = function () {
        this.display = true;
    };
    Object.defineProperty(WelcomeModalComponent.prototype, "showUnavailableFields", {
        get: function () {
            return this.modalData.unavailableField && this.modalData.unavailableField.setllementsFields.length;
        },
        enumerable: true,
        configurable: true
    });
    WelcomeModalComponent.prototype.checkEmployerFields = function () {
        if (this.modalData.unavailableField && this.modalData.unavailableField.setllementsFields.length) {
            this.showUnavailableData = true;
        }
        else {
            this.closeModal();
        }
    };
    WelcomeModalComponent.prototype.continueAfterFieldCheck = function () {
        this.closeModal();
        if (!this.modalData.unavailableField.allowIsrael && !this.modalData.unavailableField.allowSettlement) {
            this.router.navigateByUrl('/home');
        }
    };
    WelcomeModalComponent.prototype.closeModal = function () {
        this.dialogRef.close();
    };
    return WelcomeModalComponent;
}());
export { WelcomeModalComponent };
