import { Component, OnInit, ViewChild, Input, ElementRef, OnDestroy } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { SelectionModel } from "@angular/cdk/collections";
import { MatSort } from "@angular/material/sort";
import 'jspdf-autotable';
import { jsPDF } from 'jspdf';
import { Pdf } from 'src/app/models/pdf';
import { NgZone } from '@angular/core';
import { EmailService } from "src/app/shared/service/email.service";

import {
  CurrentEmployee,
  CurrentEmployeesData,
  CurrentEmployeeExtended,
  CancelReason,
  CancelLicense,
  CancelLicenseResponse,
  GeneralModalData,
  User, InvalidRenewLicense, RenewLicense, RenewLicenseModel
} from "../../../../models/interfaces";
import { EmployeeService } from "../../../../services/employee-service";
import colorText from "../../../../shared/util/colorText";
import { LicensesCancelModalComponent } from "../../modals/emoloyee-actions/licenses-cancel-modal.component";
import { MatDialog, MatDialogConfig } from "@angular/material";
import { Router, ActivatedRoute, NavigationExtras } from "@angular/router";
import applicationtStatusTypes from "src/app/shared/util/applicationtStatusTypes";
import { DatePipe } from "@angular/common";
import { PrintService } from "src/app/shared/service/print.service";
import { PerfectScrollbarConfigInterface } from "ngx-perfect-scrollbar";
import { DialogService } from "../../../../shared/service/dialog.service";
import { GeneralModalComponent } from "../../../../shared/components/general-modal/general-modal.component";
import LicenseCancelStatusTypes
  from "../../../../shared/util/LicenseCancelStatusTypes";
import { UserDataService } from 'src/app/services/user-data.service';
import { LicensesRenewModalComponent } from '../../modals/emoloyee-actions/licenses-renew-modal.component';
import { MessagesModalComponent } from 'src/app/wizard/shared/messages-modal/messages-modal.component';
import { ExcelCurrentEmployee } from "src/app/models/excel";
import { LicenseService } from "src/app/services/license-service";
// import { EmailService } from "src/app/shared/service/email.service";
@Component({
  selector: "app-all-current-employees-status",
  templateUrl: "./all-current-employees-status.component.html",
  styleUrls: ["./all-current-employees-status.component.scss"]
})
export class AllCurrentEmployeesStatusComponent implements OnInit, OnDestroy {
  @Input() isMainDropdownOpen: boolean = false;
  @ViewChild('content', { static: false }) content: ElementRef;

  dataSource: CurrentEmployee[];
  selection = new SelectionModel<CurrentEmployee>(true, []);
  dialogConfig: MatDialogConfig = { panelClass: "generalModal", };
  isRequestSent: boolean = false;
  pdf: Pdf = new Pdf()
  user1: User

  public config: PerfectScrollbarConfigInterface = {
    wheelSpeed: 0.2
  };

  displayedColumns = [
    { header: 'שם העובד', field: 'fullName' },
    { header: 'מספר זהות', field: 'id' },
    { header: 'מספר רשיון', field: 'licenseId' },
    { header: 'ענף', field: 'field.name' },
    { header: 'תום תוקף', field: 'licenseExpiredDate' },
    { header: 'מיקום', field: 'settlements' },
    { header: 'לינה בישראל', field: 'isLodgingInIsrael' }
  ];

  currentEmployeesData: CurrentEmployeesData;
  date: Date;
  user: User;
  invalidRenewFunctions: { (emplyees: CurrentEmployee[]): InvalidRenewLicense; }[]
  // Init Sort - This Is Here Because The Mat-Table Is Surrounded With ngIf.
  sort;
  cancelReasons: CancelReason[] = [];
  newLicenseArr = [];
  checked: boolean = false
  selectedEmployee: CurrentEmployee[]
  selectedCities: string[] = [];
  showModal: boolean = false
  title: string
  text: string
  errorMessage: string
  listExcel:Array<ExcelCurrentEmployee>=new Array<ExcelCurrentEmployee>()
  excel:any[]
  alredyOpen:boolean=false;

  cancelLicence:boolean=false;
  dialogData={};
  // @ViewChild(MatSort, { static: false }) set content(content: ElementRef) {
  //   this.sort = content;
  //   if (this.sort) {
  //     this.dataSource.sort = this.sort;
  //   }
  // }


  constructor(
    private employeeService: EmployeeService,
    private printService: PrintService,
    private dialogService: DialogService,
    public datePipe: DatePipe,
    private userDataService: UserDataService,
    public router:Router,
    public emailService: EmailService,
    private _ngZone: NgZone,
    private licenseService: LicenseService
  ) {
    this.date = new Date();
  }

  ngOnInit() {
    // InitData
    this.initAll();
    this.employeeService.getCancelReasons().subscribe(reasons => {
      this.cancelReasons = reasons;
    });

    this.userDataService.getUser().subscribe(user => {
      this.user = user;
    });

    this.userDataService.getUser1().subscribe(user => {
      this.user1 = user.user;
    });

    //The Order In The Array Is Important
    this.invalidRenewFunctions = [this.populateRenewInvalidDatesEmployees, this.populateRenewEmployees, this.populatInvalidMagneticCard];

  }

  ngAfterViewInit() {
    this.initAll();
  }

  initAll(hardReload = false) {
    this.employeeService
      .getCurrentEmployeesData(hardReload)
      .subscribe(currentEmployeesData => {
        this.currentEmployeesData = currentEmployeesData;

        this.initTableData();
        this.initPrintData();
        this.subscribeToRenew();
      });
  }

  getRowColor(row: CurrentEmployeeExtended): string {
    return this.licenseService.getStatusColor(row.applicationStatus ? row.applicationStatus.code : null);
  }

  subscribeToRenew() {
    this.employeeService.renewLicenseEmployees.asObservable().subscribe(data => {
      if (data) {
        this.currentEmployeesData.employees.filter((employee: CurrentEmployee) => {
          if (data.licenseIds.includes(employee.licenseId) && !employee.isRenew) {
            this.newLicenseArr.push(employee);
          }
        });

        this.newLicenseArr.forEach((newLicense: CurrentEmployee) => {
          newLicense.isRenew = true;
          newLicense.renewalApplicationId = data.applicationID;
          this.selection.deselect(newLicense);
        });
        this.isRequestSent = true;
      }
    });
  }

  get allowRenewDays() {
    return this.employeeService.getAllowRenewDays();
  }

  initTableData() {
    this.dataSource = this.currentEmployeesData.employees
    this.employeeService.renewEmployee.subscribe(licenseId => {
      if (licenseId) {
        this.selection.select(this.currentEmployeesData.employees.filter(employee => employee.licenseId == licenseId)[0]);
      }
    });

    if (this.currentEmployeesData.employees.length) {
      this.printService.showPrintButton();
      // this.emailService.showEmailButton()
    }

    // Init Sort Functions
    // this.dataSource.sortingDataAccessor = this.getPropertyByPath;
  }


  getWarningClass(employee: CurrentEmployee): string {
    return employee.daysLeftToExpire <= this.allowRenewDays ? `${colorText.RED} tableDataInside` : "";
  }

  initPrintData() {
    if (this.dataSource){
      let table=[];
      for (let i=1; i<this.dataSource.length; i++){
        for (let j=0; j<this.displayedColumns.length; j++)
        table[i]=this.dataSource[j]
      }
    }
    this.excel=this.exportExcel()
    this.printService.insertHTMLdata("currentEmployees", "employeesTable", this.content, this.excel);
    this.printService.setCurrentRawData("currentEmployees");
    // if (this.currentEmployeesData.employees.length) {
    //   this.printService.showPrintButton();
    //   this.emailService.showEmailButton()
    // }
  }

  /** filter box */
  toggleDropdownFilter = (evt: any, filterBtn) => {
    filterBtn.isMainDropdownOpen = !filterBtn.isMainDropdownOpen;
  };

  getPropertyByPath(data: any, pathString: string) {
    const value = pathString.split(".").reduce((o, i) => o[i], data);

    if (Array.isArray(value)) {
      return value[0].name;
    }
    return value;
  }

  getLicenseFile(e: Event): void {
    
    if (!this.selection.selected.length || this.selection.selected.length !== 1) {
      return;
    }
    const employee = this.selection.selected[0];
    this.employeeService.getEmployeeLicenseUrl(employee.licenseId)
      .subscribe(fileURL => {
        // fileURL.save('table.pdf');

        window.open(fileURL, "_blank");

      },
        error => {
          if (error.status === 401) {
            return;
          }
          let title = "תקלה...";
          let text = "לא ניתן להציג את הרשיון הנדרש";
          if (error.status === 404) {
            title = "שים לב";
            text = "לא ניתן לצפות ברשיון זה מכיוון שהוא איננו דיגיטלי"
          }
          this.title = title
          this.text = text
          this.errorMessage = ''
          this.showModal = true
        }
      );
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: CurrentEmployee): string {
    if (!row) {
      return `${this.isAllSelected() ? "select" : "deselect"} all`;
    }

    return `${this.selection.isSelected(row) ? "deselect" : "select"
      } row ${row.id + 1}`;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle(): void {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.forEach(row => this.selection.select(row));
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.length;
    return numSelected === numRows;
  }

  othetEmployee() { }

  getElementColor(element: CurrentEmployee): string {
    return element.daysLeftToExpire <= this.allowRenewDays
      ? colorText.RED
      : colorText.GREY;
  }


  amountOfSelectdEmployees(): string {
    if (this.selection.selected.length === 1) {
      return "1 עובד מסומן ";
    } else {
      return `${this.selection.selected.length} עובדים מסומנים`;
    }
  }

  clear() {
    this.selectedEmployee = []
  }

  openCancelLicensesModal() {
    if (!this.selection.selected) {
      return;
    }
    const dialogData = {
      cancelReasons: this.cancelReasons,
      validEmployees: this.generateEmployeesData()
    };
    this.dialogData=dialogData
    this.cancelLicence=true;
  }

  getStatusModal(status: any) {
    this.cancelLicence = status.cancelLicence;
    if (status.close==0){
  
      const users=this.selection.selected.map((user)=>{
        return user;
      })

      const data={
        employees:users,
        cancelLicenseReason:status.data
      }

      this.handleCancel(data)
    }
  }

  handleCancel(users): void {
    this.employeeService.cancelEmployeesLicense(users).subscribe(
      cancelLicenseResponse => {
        this.clearData();
        this.initAll(true);

        if (cancelLicenseResponse.some(clr => clr.cancelStatus.code === LicenseCancelStatusTypes.FAILURE)) {
          this.openFailedLicenseCancelModal(cancelLicenseResponse);
        }
      }
    );
  }

  openFailedLicenseCancelModal(cancelLicenseResponse: CancelLicenseResponse[]) {
    const modalData: GeneralModalData = {
      title: "ביטול רשיון נכשל",
      text: this.buildFailedCancelLicenseHtml(cancelLicenseResponse),
      errorMessage: "ביטול הרשיונות הנ\"ל נכשל:"

    };
    this.title = modalData.title
    this.text = modalData.text
    this.errorMessage = modalData.errorMessage
    this.showModal = true
    // this.dialogService.openModal(GeneralModalComponent, modalData, this.dialogConfig)
  }

  buildFailedCancelLicenseHtml(failedCancelLicenses: CancelLicenseResponse[]) {
    let rawHtml = "<p>";
    failedCancelLicenses.forEach(fl => {
      rawHtml += `<div class="textContent textContent--bold"> ${fl.licenseId} </div>`
    });
    rawHtml += "</p>";

    return rawHtml;
  }

  renewLicenses(): void {
    if (!this.selection.selected) {
      return;
    }

    const hasIsLicenseInIsraelForRenew = this.selection.selected.some(e => e.isLicenseInIsrael);
    const hasMixedSubFields = this.selection.selected.some((val, i, arr) => val.field.subCode !== arr[0].field.subCode);

    if (hasMixedSubFields || hasIsLicenseInIsraelForRenew) {
      const modalData: GeneralModalData = {
        title: "חידוש רשיון",
        text: hasMixedSubFields ? "ניתן לחדש רישיון לעובדים רק כאשר העובדים שנבחרו עובדים באותו סקטור" : "",
        errorMessage: hasMixedSubFields
          ? "נא לשים לב, לא ניתן לחדש רישיון לעובדים מסקטור שונים"
          : "נא לשים לב, לעובדים הנ\"ל יש רשיון לישראל אשר אינו מאפשר חידוש רשיון"
      };
      this.title = modalData.title
      this.text = modalData.text
      this.errorMessage = modalData.errorMessage
      this.showModal = true
      // this.dialogService.openModal(GeneralModalComponent, modalData, this.dialogConfig)
    }
    else {
      // const validEmployees  = this.selection.selected.filter(e => !e.isLicenseInIsrael && (e.daysLeftToExpire >= 0 && e.daysLeftToExpire <= this.allowRenewDays) && !e.isRenew);
      const { valid, invalid } = this.builDataForRenew(this.selection.selected);
      this.handleRenewLicense(valid, invalid);
    }
  }

  builDataForRenew(emplyees: CurrentEmployee[]): RenewLicenseModel {
    const invalidRenewLicense: InvalidRenewLicense[] = [];
    let validEmployees = [...emplyees];
    //Important!! - The Order of te functions is important, due to the fact that the list of emplyees is being redcued with the result.
    this.invalidRenewFunctions.forEach(func => {
      const res: InvalidRenewLicense = func.call(this, validEmployees);
      if (res) {
        invalidRenewLicense.push(res);
        validEmployees = validEmployees.filter(tEmp => !res.employees.includes(tEmp))
      }
    })
    const result: RenewLicenseModel = { invalid: invalidRenewLicense, valid: validEmployees }
    return result;
  }

  populateRenewEmployees(emplyees: CurrentEmployee[]): InvalidRenewLicense {
    const renewEmplyees = emplyees.filter(e => e.isRenew);
    return renewEmplyees.length ? { errorMessage: "לא ניתן להגיש בקשה לחידוש רישיונות עבור העובדים הנ\"ל, כיוון שנשלחה בעבר בקשה לחידוש רישיונות אלו", employees: renewEmplyees } : null;
    ""
  };

  populateRenewInvalidDatesEmployees(emplyees: CurrentEmployee[]): InvalidRenewLicense {
    const invalidDates = emplyees.filter(e => (e.daysLeftToExpire > this.allowRenewDays || e.daysLeftToExpire < 0));
    return invalidDates.length ? { errorMessage: "ניתן להגיש בקשה לחידוש עבור רישיון שיפוג בעוד פחות מ-14 יום", employees: invalidDates } : null;
  };

  populatInvalidMagneticCard(emplyees: CurrentEmployee[]): InvalidRenewLicense {
    const noMagneticCardEmployees = emplyees.filter(e => !e.hasValidCard);
    return noMagneticCardEmployees.length ? { errorMessage: "לא קיים כרטיס מגנטי", employees: noMagneticCardEmployees } : null;

  }


  handleRenewLicense(validEmployees: CurrentEmployee[], invalidEmployees: InvalidRenewLicense[]): void {
    // this.employeeService.setWantedWorkersEmployees(employees);
    // this.router.navigateByUrl("/employmentapplication");
    const dialogData = {
      validEmployees: validEmployees,
      invalidEmployees: invalidEmployees
    };
    let licenseToRenew: RenewLicense = { duration: null, licenseIds: validEmployees.map(employee => employee.licenseId) };
    const dialogRef = this.dialogService.openModal(LicensesRenewModalComponent, dialogData, this.dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        licenseToRenew.duration = +result;
        this.handleRenew(licenseToRenew);
      }
    });
  }

  handleRenew(result: RenewLicense) {
    this.employeeService.renewEmployeesLicense(result.duration, result.licenseIds)
      .subscribe({ next: this.onSuccess.bind(this), error: this.onError.bind(this) })
  }

  openSubmitModal(success: boolean, msg: string, request_id?: string) {
    this.dialogService.openModal(
      MessagesModalComponent,
      {
        submitSuccess: success,
        message: msg,
        requestId: request_id
      },
      { disableClose: true }
    );
  }

  onSuccess(response: string) {
    this.openSubmitModal(true, `נא לשים לב, תהליך זה צפוי לקחת עד 7 ימי עבודה,
    אנו ממליצים לשמור את מספר הפנייה לצורך מעקב`, response)
  }

  onError(response) {
    this.openSubmitModal(false, 'תקלה...');
  }

  generateEmployeesData() {
    
   let validEmployees: CurrentEmployee[];
    validEmployees=this.selection.selected.map((emp)=>{
      return emp;
    })

    return validEmployees

  }


  applyFilter(filterValue: string) {
    // this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  populateRenewLicenseText(renew: CurrentEmployeeExtended): string {
    let renewLicenseText = "";
    // renew.applicationStatus.code
    switch (+renew.workerRenewalAppStatus) {
      case applicationtStatusTypes.APPROVED:
        // const parsedDate = this.datePipe.transform(
        //   renew.createdTime,
        //   "dd-MM-yyyy"
        // );
        // renewLicenseText = `בקשה לחידוש רשיון אושרה. נכנס לתוקף ${parsedDate}`;
        renewLicenseText = `בקשה ${renew.renewalApplicationId} לחידוש רשיון אושרה.`;
        break;
      case applicationtStatusTypes.PENDING:
        // renewLicenseText = `נשלחה בקשה לחידוש רשיון - מס' פניה ${renew.applicationID}`;
        renewLicenseText = `נשלחה בקשה לחידוש רשיון - מס' פניה: ${renew.renewalApplicationId}`;
        break;
      case applicationtStatusTypes.DECLINED:
        renewLicenseText = ` בקשה לחידוש רשיון סורבה, ניתן לראות את סיבת הסירוב ב"סטאטוס בקשות"`;
        break;
    }
    return renewLicenseText;
  }

  clearData() {
    this.selection.clear();
    // this.dataSource.data = [];
  }

  isPanelBtnDisabled(): boolean {
    // return this.selectedEmployee.length>0
    return !this.selection.selected.length || this.user.hasBranchOnlyInIsrael
  }

  ngOnDestroy(): void {
    this.printService.hidePrintButton();
    this.newLicenseArr = [];
    this.employeeService.renewEmployee.next(null);
  }

  transitionsHistory(){
    const tzEmployees:NavigationExtras={
      state:{
        idEmploeeys:this.selection.selected
      }
    }
    
    this.router.navigate(['/transitionsHistory'], tzEmployees)
  }

  exportExcel(){
    this.listExcel=[]
    if (this.dataSource){
      this.dataSource.forEach((a)=>{
        this.listExcel.push({
          fullName:a.fullName, identity:a.id, licenceNumber:a.licenseId, branch:a.field.name, subBranch:a.field.subName,
          expires:a.licenseExpiredDate
        })
      })
      this.alredyOpen=true
      return this.listExcel
    }
   
  }


}
