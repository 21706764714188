import { ElementRef } from "@angular/core";
import { Subject } from "rxjs";
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import 'jspdf-autotable';
import { Pdf } from "src/app/models/pdf";
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import * as i0 from "@angular/core";
import * as i1 from "./email.service";
import * as i2 from "../../services/user-data.service";
var PrintService = /** @class */ (function () {
    function PrintService(emailService, userDataService) {
        this.emailService = emailService;
        this.userDataService = userDataService;
        this.showPrintBtn = new Subject();
        this.pdf = new Pdf();
        this.success = false;
        this.EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        this.EXCEL_EXTENSION = '.xlsx';
    }
    PrintService.prototype.setCurrentRawData = function (propName) {
        this.currentData = propName;
    };
    PrintService.prototype.setPrintConfig = function (printConfig, rawData) {
        this.printConfig = printConfig;
        this.printConfig.object = rawData || null;
    };
    PrintService.prototype.getPrintConfig = function () {
        this.printConfig.object = this.dataSources[this.currentData].rawData;
        this.dataSources[this.currentData].properties
            ? (this.printConfig.properties = this.dataSources[this.currentData].properties)
            : null;
        return this.printConfig;
    };
    PrintService.prototype.insertRawData = function (propName, data, model) {
        if (!this.dataSources) {
            this.dataSources = {};
        }
        if (!this.dataSources[propName]) {
            this.dataSources[propName] = new Object();
            this.dataSources[propName].rawData = data;
            this.dataSources[propName].properties = Object.keys(data[0]).reduce(function (acc, curr) {
                acc.push(curr);
                return acc;
            }, []);
        }
    };
    PrintService.prototype.insertHTMLdata = function (propName, elementID, con, list) {
        this.dataSource = list;
        this.content = con;
        if (!this.dataSources) {
            this.dataSources = {};
        }
        if (!this.dataSources[propName]) {
            this.dataSources[propName] = new Object();
            this.dataSources[propName].rawData = elementID;
        }
        return this.dataSources;
    };
    PrintService.prototype.hidePrintButton = function () {
        this.showPrintBtn.next(false);
    };
    PrintService.prototype.showPrintButton = function () {
        this.showPrintBtn.next(true);
    };
    PrintService.prototype.sendEmail = function () {
        var _this = this;
        this.userDataService.getUser1().subscribe(function (user) {
            _this.user1 = user.user;
        });
        var content = this.content.nativeElement;
        var doc = new jsPDF('p', 'mm', 'A4', true);
        document.getElementById('content').style.letterSpacing = '0.1px';
        html2canvas(document.getElementById('content')).then(function (a) {
            doc.addImage(a.toDataURL(), "pdf", 0, 0, 208, 201);
            var data = doc.output("datauristring");
            var base64Pdf = data.split(';base64,').pop();
            _this.pdf.sendTo = _this.user1.email;
            _this.pdf.base64 = base64Pdf;
            _this.emailService.sendEmail(_this.pdf).subscribe(function (d) {
                _this.success = d;
            });
        });
        var _elementHandlers = {
            '#editor': function (element, renderer) {
                return true;
            }
        };
        // doc.html(content.innerHTML);  
        // doc.html(content, {
        //   callback: (doc) => {
        //     const data = doc.output("datauristring");
        //     doc.output('dataurlnewwindow');
        //     let base64Pdf = data.split(';base64,').pop();
        //     console.log('bassssse', base64Pdf)
        //     this.pdf.sendTo = this.user1.email
        //     console.log(this.pdf)
        //     this.pdf.base64 = base64Pdf
        //     this.emailService.sendEmail(this.pdf).subscribe(d => {
        //       alert('mail send!!')
        //     })
        // this.emailService.sendEmail(this.pdf)
        // }
        // });
    };
    PrintService.prototype.exportAsExcelFile = function (excelFileName) {
        var worksheet = XLSX.utils.json_to_sheet(this.dataSource);
        var workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        var excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, excelFileName);
    };
    PrintService.prototype.saveAsExcelFile = function (buffer, fileName) {
        var data = new Blob([buffer], { type: this.EXCEL_TYPE });
        FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + this.EXCEL_EXTENSION);
    };
    PrintService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PrintService_Factory() { return new PrintService(i0.ɵɵinject(i1.EmailService), i0.ɵɵinject(i2.UserDataService)); }, token: PrintService, providedIn: "root" });
    return PrintService;
}());
export { PrintService };
