import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpHeaders,
  HttpResponse
} from '@angular/common/http';

import { NEVER, Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LoaderService } from '../components/loader/loader.service';
import { catchError, finalize, tap, } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {

  constructor(private router: Router, private loader: LoaderService) { }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let headers: HttpHeaders;
    let token;
    let clone;
    token = sessionStorage.getItem("token");
    if (!!token) {
      clone = req.clone({
        setHeaders: {
          'token':token,
        }
      });
    }
    else{
    clone = req;
  }

    return next.handle(clone).pipe(
    tap((event: HttpEvent<any>) => {
      let token: string;
      if (event instanceof HttpResponse) {
        token = event.headers ? event.headers.get('token') : null;
        if (token) {
          sessionStorage.setItem('token', token)
        }
      }
      //return event;
    }),
    catchError(err => {
      if (err.status === 401) {
        //sessionStorage.removeItem('token');
        sessionStorage.clear();
        this.router.navigate(['login'])
        this.loader.hide();
      }

      return throwError(err);
    }));
  }
}
