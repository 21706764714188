<div class="container-fluid container-box">
    <div class="tabsSection storing-day px-md-0">
        <h1>שיבוץ ליום מיונים</h1>

        <div class="stepHeadline">
            הגדר באילו ימים יבוצעו ימי מיונים ומכסת המתמיינים
        </div>

        <div class="d-flex flex-wrap mt-4 mx-n3 mx-md-auto">

            <div class="col-12 col-md-3 pr-md-0 d-flex flex-column w-100 mb-4 mb-md-0">
                <label class="ml-3 mb-3">ענף</label>
                <p-dropdown [options]="listBranch" placeholder="בחר ענף" [(ngModel)]="branch"
                    (onChange)="setSubBranchesArray()" optionLabel="name" [showClear]="true"></p-dropdown>
            </div>


            <div class="col-12 col-md-3  d-flex flex-column w-100 mb-4 mb-md-0">
                <label class="ml-3 mb-3">תת ענף</label>
                <p-dropdown [options]="arraySubBranch" [disabled]="arraySubBranch.length==0" placeholder="בחר תת ענף"
                    [(ngModel)]="subBranch" (onChange)="setProfessionsArray()" optionLabel="subName" [showClear]="true">
                </p-dropdown>
            </div>


            <div class="col-12 col-md-3  d-flex flex-column w-100 ">
                <label class="ml-3 mb-3">מקצוע</label>
                <p-dropdown [(ngModel)]="profession" [disabled]="arrayProfession.length==0" [options]="arrayProfession"
                    (onChange)="refreshTables()" placeholder="בחר מקצוע" optionLabel="profession" [showClear]="true">
                </p-dropdown>
            </div>
        </div>

        <div class="box-card p-4 mt-5 my-4">
            <div class="d-flex align-items-center">
                <div class="d-flex color-blue">
                    <div class="ml-4">
                        <i class="ml-2 icon-calendar"></i>
                        <span> תאריך:</span>
                        <span>{{sortingDaySelected.examDate | date:'dd/MM/yyyy'}}</span>
                    </div>

                    <div class="ml-4">
                        <i class="ml-2 icon-Number-of-employees"></i>
                        <span>הקצאה:</span>
                        <span>{{sortingDaySelected.allocation}}</span>
                    </div>

                    <div class="ml-4">
                        <i class="ml-2 icon-View-transitions"></i>
                        <span>תפוסה:</span>
                        <span>{{amountCandidate(sortingDaySelected)==0?' ':amountCandidate(sortingDaySelected)}}</span>
                    </div>
                </div>
            </div>
            <div class="d-flex flex-column flex-md-row mt-4">
                <div class="col-12 col-md-3 p-0 pr-md-0 pl-md-4 mb-3 mb-md-0 first-table box-shadow">
                        <div class="grid-header d-flex">
                            <span></span>
                            <span>תאריך</span>
                            <span>הקצאה</span>
                            <span>תפוסה</span>
                        </div>

                        <div class="container-grid-row">

                            <div class="grid-row d-flex" *ngFor="let item of listSortingDay">
                                <span>
                                        &nbsp;<p-radioButton [inputId]="item?._id" name="item" [value]="item" [(ngModel)]="selectedSortingDay" *ngIf="item.examDate!==undefined"  (click)="selectSortingDay(item)">
                                        </p-radioButton>
                                </span>
                                <span>
                                    &nbsp;{{item?.examDate | date:'dd/MM/yyyy'}}
                                </span>
                                <span pTooltip={{item?.allocation}} tooltipPosition="bottom">
                                    &nbsp; {{item?.allocation}}
                                </span>
                                <span>
                                    <span *ngIf="item.examDate!==undefined">&nbsp;{{amountCandidate(item)}}</span>
                                </span>
                            </div>
                        </div>
                </div>

                <div class="col-12 col-md-7 p-0 pr-md-0 pl-md-4 mb-3 mb-md-0 box-shadow">
                    <div>
                        <div class="grid-header d-flex">
                            <span class="w-25 text-center">ת.ז</span>
                            <span class="w-25 text-center">שם פרטי</span>
                            <span class="w-25 text-center">שם משפחה</span>
                            <span class="w-25 text-center">מקצוע</span>
                        </div>
                        <div class="container-grid-row">
                            <div *ngFor="let item of sortingDaySelected.candidates; let i=index">
                                <div *ngIf="item.employeeId!==null">
                                    <div class="grid-row d-flex">
                                        <span class="d-flex align-items-center">&nbsp;
                                            <div *ngIf="item.employeeId!==undefined">
                                                <p-checkbox #check [binary]="true"
                                                    (click)="checkedRemoveCandidate(check ,item)"
                                                    class="position-relative"></p-checkbox>
                                            </div>
                                            &nbsp;{{item?.employeeId?.employeeIdentity}}
                                        </span>
                                        <span>
                                            &nbsp;{{item?.employeeId?.firstName}}
                                        </span>
                                        <span>
                                            &nbsp;{{item?.employeeId?.lastName}}
                                        </span>
                                        <span>
                                            <span *ngIf="item.employeeId!==undefined">
                                                &nbsp;{{sortingDaySelected?.professionId?.profession}}
                                            </span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-12 col-md-2 px-0 box-shadow">
                    <div>
                        <div class="grid-header d-flex">
                            <span>ת.ז</span>
                        </div>
                        <div class="container-grid-row">
                            <div class="grid-row d-flex" *ngFor="let item of listEmployee">
                                <span class="d-flex text-center w-100">&nbsp;
                                    <div *ngIf="item.employeeIdentity!==undefined" class="d-flex">
                                        <p-checkbox #employees (onChange)="checkedEmployee($event, item)"
                                            class="position-relative" label=''></p-checkbox>
                                    </div>
                                    {{item.employeeIdentity}}
                                </span>
                            </div>
                        </div>
                    </div>
                    <button (click)="clear()" class="button-matpash mt-3 no-fill-btn ui-button-rounded w-100 color-light-blue">נקה</button>
                </div>
            </div>

            <div class="d-flex  ml-4 mt-5">
                <div class=" d-flex flex-column flex-md-row mr-auto">
                    <button type="button" pButton (click)="selectAllEmployee()"
                        class=" ui-button-rounded  button-matpash blue-fill d-flex align-items-center  justify-content-center  end-btn"
                        [disabled]="!sortingDaySelected.candidates[0].employeeId"
                        label="בחר הכל">
                    </button>

                    <button type="button" pButton (click)="removeCandidate()" 
                        class=" ml-5 mr-3 ui-button-rounded  button-matpash blue-border d-flex align-items-center  justify-content-center  end-btn"
                        [disabled]="listRemoveCandidate.length==0"
                        label="">
                        <span class="ml-2">מחק</span>
                        <i class="icon-delete"></i>
                    </button>

                    <button type="button" pButton (click)="embedEmployees()"
                        class=" mr-5 ui-button-rounded  button-matpash light-blue-fill d-flex align-items-center  justify-content-center  end-btn"
                        [disabled]="listEmployeeChecked.length==0"
                        label="שבץ">
                    </button>
                </div>
            </div>
        </div>

    </div>
</div>
<app-message-modal *ngIf='displayModal' [title]='title' [displayModal]='displayModal' [showContact]='showContact'
    [content]='content' [img]='img' (statusToEmit)="getStatusModal($event)"></app-message-modal>