import * as i0 from "@angular/core";
var ScrollToService = /** @class */ (function () {
    function ScrollToService() {
    }
    ScrollToService.prototype.scrollTo = function (element) {
        if (document.querySelector(element)) {
            document.querySelector(element).scrollIntoView({ behavior: 'smooth' });
            ;
        }
    };
    ScrollToService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ScrollToService_Factory() { return new ScrollToService(); }, token: ScrollToService, providedIn: "root" });
    return ScrollToService;
}());
export { ScrollToService };
