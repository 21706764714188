import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-management',
  templateUrl: './management.component.html',
  styleUrls: ['./management.component.scss']
})
export class ManagementComponent implements OnInit {

  constructor(public router:Router) { }

  ngOnInit() {
  }

  navigate(rout:string){
    this.router.navigate([rout])
  }

  parameterManagement(){
    this.router.navigate(["/parameterManagement"])
  }

  inputDaySorting(){
    this.router.navigate(['/inputDaySorting']);
  }

  sortingDay(){
    this.router.navigate(['/sortingDay'])
  }

}
