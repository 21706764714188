import * as i0 from "@angular/core";
import * as i1 from "../shared/service/ApplicationHttpClient";
var StoringDayService = /** @class */ (function () {
    function StoringDayService(http) {
        this.http = http;
    }
    StoringDayService.prototype.getAllBranches = function () {
        return this.http.Get("api/branches/");
    };
    StoringDayService.prototype.getAllSubBranches = function () {
        return this.http.Get("api/sub_branches/");
    };
    StoringDayService.prototype.selectSubBranch = function (branchId) {
        return this.http.Get("api/sub_branches/" + branchId);
    };
    StoringDayService.prototype.selectProfession = function (subBranchId) {
        return this.http.Get("api/profession/" + subBranchId);
    };
    StoringDayService.prototype.getAllProfession = function () {
        return this.http.Get("api/profession/");
    };
    StoringDayService.prototype.getSortingDays = function (professionId) {
        return this.http.Get("api/sorting_day/" + professionId);
    };
    StoringDayService.prototype.updateOrCreate = function (sortingDay) {
        return this.http.Post("api/sorting_day/update/", sortingDay);
    };
    StoringDayService.prototype.deleteSortingDay = function (sortingDayId, professionId) {
        return this.http.Delete("api/sorting_day/" + sortingDayId + "/" + professionId);
    };
    StoringDayService.prototype.create = function (sortingDay) {
        return this.http.Post("api/sorting_day/create/", sortingDay);
    };
    StoringDayService.prototype.searchEmployees = function (professionId) {
        return this.http.Get("api/input_day_sorting/employee/" + professionId);
    };
    StoringDayService.prototype.getCandidate = function (professionId) {
        return this.http.Get("api/input_day_sorting/sortingDayByProfession/" + professionId);
    };
    StoringDayService.prototype.embedEmployees = function (sortingDayId, listCandidate) {
        return this.http.Post("api/input_day_sorting/" + sortingDayId, listCandidate);
    };
    StoringDayService.prototype.getAllStatus = function () {
        return this.http.Get("api/input_day_sorting");
    };
    StoringDayService.prototype.createCandidates = function (listCandidates) {
        return this.http.Post("api/candidate/", listCandidates);
    };
    StoringDayService.prototype.updateStatusEmployeeProfession = function (employees, professionId) {
        return this.http.Post("api/employees/embedEmployees/" + professionId, employees);
    };
    StoringDayService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function StoringDayService_Factory() { return new StoringDayService(i0.ɵɵinject(i1.ApplicationHttpClient)); }, token: StoringDayService, providedIn: "root" });
    return StoringDayService;
}());
export { StoringDayService };
