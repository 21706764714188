import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { WantAdModel } from 'src/app/models/WantAd';
import { WantAdsService } from 'src/app/services/want-ads.service';
import { WantAdStatusEnumModel } from 'src/app/models/WantAdStatusEnumModel';
import { WantAdStatusEnum } from 'src/app/models/WantAdStatusEnum';
import { BranchModel, professionModel, SubBrancModel } from 'src/app/models/Employee';
import { EmployeeService } from 'src/app/services/employee-service';
import { UserDataService } from 'src/app/services/user-data.service';
import { User } from 'src/app/models/interfaces';


@Component({
  selector: 'app-update-want-ads',
  templateUrl: './update-want-ads.component.html',
  styleUrls: ['./update-want-ads.component.scss']
})
export class UpdateWantAdsComponent implements OnInit {


  @Input() WantAd: WantAdModel;
  @Input() updateMode: boolean;
  @Input() user: User;
  @Output()
  updateWantAdEvent: EventEmitter<WantAdModel> = new EventEmitter<WantAdModel>();
  @Output()
  deleteWantAdEvent: EventEmitter<WantAdModel> = new EventEmitter<WantAdModel>();
  wantAdFG: FormGroup;
  branches: BranchModel[];
  subBranches: SubBrancModel[];
  professions: professionModel[];
  allProfessions: professionModel[];
  allSubBranches: SubBrancModel[];
  showInterestedEmployees:boolean=false
  statuses: WantAdStatusEnumModel[] = [
    { id: WantAdStatusEnum.Active, desc: "Active", hebrewDesc: "פעיל" },
    { id: WantAdStatusEnum.Canceled, desc: "Canceled", hebrewDesc: "מבוטל" },
    { id: WantAdStatusEnum.Inactive, desc: "InActive", hebrewDesc: "לא פעיל" }
  ];
  allStatuses: WantAdStatusEnumModel[] = [];
  currentStatus: WantAdStatusEnumModel = new WantAdStatusEnumModel();
  hotJobOptions: any[] = [{ value: true, desc: "כן" }, { value: false, desc: "לא" }]
  today = new Date();

  sendData: any = {
    wantAd: null,
    status: null,
    delete: null
  };
  submitted: boolean = false;



  constructor(private fb: FormBuilder, public datePipe: DatePipe, private employeeService: EmployeeService) { }

  ngOnInit() {
    this.createForm();
    this.getOptionsData();
    this.initFormControls();
    this.initStatuses();
  }




  createForm(): void {
    this.wantAdFG = this.fb.group({
      _id: [null],
      title: [null, [Validators.required]],
      hotJob: [null],
      content: [null, [Validators.required]],
      fromDate: [null, [Validators.required]],
      toDate: [null, [Validators.required]],
      branchId: [null, [Validators.required]],
      subBranchId: [null],
      professionId: [null],
      firstName: [null],
      lastName: [null],
      telephone: [null],
      email: [null],
      status: [null]
    });

  }

  initStatuses() {
    this.allStatuses = this.statuses;
  }

  getOptionsData() {
    this.employeeService.getEmployeePoolFilter().subscribe(res => {
      this.branches = res.Branches;
      this.subBranches = res.SubBranches;
      this.allSubBranches = this.subBranches;
      this.professions = res.Professions;
      this.allProfessions = this.professions;
    })

  }


  subBranchFilter() {
    if (!this.wantAdFG.controls.branchId.value || !this.allSubBranches) return;
    var branchID = this.wantAdFG.controls.branchId.value._id;
    this.subBranches = this.allSubBranches.filter((subBranch: SubBrancModel) => subBranch.branch._id == branchID);
  }

  professionFilter() {
    if (!this.wantAdFG.controls.subBranchId.value || !this.allProfessions) return;
    var subBranchID = this.wantAdFG.controls.subBranchId.value._id;
    this.professions = this.allProfessions.filter((profession: professionModel) => profession.subBranch._id == subBranchID);
  }

  BrunchesSelectionChange() {
    this.subBranchFilter();
  }

  deleteWantAd() {
    if (!!this.wantAdFG.controls._id.value) {
      this.sendData.delete = true;
      this.currentStatus.desc = 'Canceled'
      this.currentStatus.hebrewDesc = 'מבוטל'
      this.currentStatus.id = 3
      this.wantAdFG.get("status").setValue(this.currentStatus);
    } else {
      this.sendData.delete = false;
    }
    this.submit()
  }

  initFormControls() {
    this.initStatusControl();
    this.initUserControls();
    if (this.updateMode == false || !this.WantAd) {

      //this.initDatesControls();
      return;
    }
    let fromDate = this.datePipe.transform(this.WantAd.fromDate, 'yyyy-MM-dd')
    this.WantAd.fromDate = new Date(fromDate)
    let toDate = this.datePipe.transform(this.WantAd.toDate, 'yyyy-MM-dd')
    this.WantAd.toDate = new Date(toDate)
    this.wantAdFG.get("_id").setValue(this.WantAd._id);
    this.wantAdFG.get("title").setValue(this.WantAd.title);
    this.wantAdFG.get("content").setValue(this.WantAd.content);
    this.wantAdFG.get("fromDate").setValue(this.WantAd.fromDate);
    this.wantAdFG.get("toDate").setValue(this.WantAd.toDate);
    this.wantAdFG.get("branchId").setValue(this.WantAd.branchId);
    this.wantAdFG.get("subBranchId").setValue(this.WantAd.subBranchId);
    this.wantAdFG.get("professionId").setValue(this.WantAd.professionId);
    this.wantAdFG.get("firstName").setValue(this.user.user.name);
    this.wantAdFG.get("hotJob").setValue((!!this.WantAd.hotJob && this.WantAd.hotJob == true) ? this.hotJobOptions[0].value : this.hotJobOptions[1].value);
    if (this.WantAd.interestedEmployees.length)
      this.showInterestedEmployees = true;
  }

  initUserControls() {
    this.wantAdFG.get("firstName").setValue(this.user.user.name);
    // this.wantAdFG.get("lastName").setValue(this.WantAd.userId.lastName);
    this.wantAdFG.get("telephone").setValue(this.user.user.phoneNo);
    this.wantAdFG.get("email").setValue(this.user.user.email);
  }



  initStatusControl() {
    this.currentStatus.id = WantAdStatusEnum.Active
    if ((!!this.wantAdFG.controls.toDate.value && this.wantAdFG.controls.toDate.value < this.today) || (!!this.wantAdFG.controls.fromDate.value && this.today < this.wantAdFG.controls.fromDate.value)) {
      this.currentStatus.id = WantAdStatusEnum.Inactive
    }
    if (!!this.WantAd && this.WantAd.status === WantAdStatusEnum.Canceled) {
      this.currentStatus.id = WantAdStatusEnum.Canceled
    }
    let statusDesc = [{ desc: "Active", hebrewDesc: "פעיל" }, { desc: "InActive", hebrewDesc: "לא פעיל" }, { desc: "Canceled", hebrewDesc: "מבוטל" }]
    this.currentStatus.desc = statusDesc[this.currentStatus.id - 1].desc;
    this.currentStatus.hebrewDesc = statusDesc[this.currentStatus.id - 1].hebrewDesc;
  }

  isCanceled() {
    if (!this.WantAd) return;
    if (this.WantAd.status === WantAdStatusEnum.Canceled) {
      this.wantAdFG.disable();
    }
    return this.WantAd.status === WantAdStatusEnum.Canceled;
  }

  updateStatusesOptions() {
    this.initStatusControl();
    if (this.wantAdFG.get("fromDate").value > this.wantAdFG.get("toDate").value) {
      this.wantAdFG.get("toDate").setValue(null)
    }
    //  if(!this.wantAdFG.get("toDate").touched) return;
    //  let toDate = new Date(this.wantAdFG.controls.toDate.value);
    //    if( toDate > this.today){
    //    this.statuses = this.allStatuses
    //    return;
    //  }
    //   this.statuses= this.allStatuses.filter(s=>s.id !== WantAdStatusEnum.Active)
  }

  branchChanged() {
    if (!this.wantAdFG.get("branchId").value) {
      this.wantAdFG.get("subBranchId").setValue(null);
      this.wantAdFG.get("professionId").setValue(null);
    }
    this.subBranchFilter();

  }

  subBranchChanged() {
    if (!this.wantAdFG.get("subBranchId").value) {
      this.wantAdFG.get("professionId").setValue(null);
    }
  }

  submit() {
    this.submitted = true;
    if (!this.wantAdFG.valid) return;
    this.sendData.wantAd = this.wantAdFG.value,
      this.sendData.wantAd.userId = this.user.user.identity;
    this.sendData.status = this.currentStatus.id;
    this.updateWantAdEvent.emit(this.sendData);
    this.submitted = false;
  }



}
