import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Profession } from 'src/app/models/profession';
import { SortingDay } from 'src/app/models/sortingDay';

@Component({
  selector: 'app-sorting-day-week',
  templateUrl: './sorting-day-week.component.html',
  styleUrls: ['./sorting-day-week.component.scss']
})
export class SortingDayWeekComponent implements OnInit {

  @Input() listSortingDay: Array<SortingDay>;
  @Input() profession: Profession;
  @Input() fromDate: Date = new Date();
  @Input() toDate: Date;
  @Output() editSortingDay = new EventEmitter<any>();

  displayModal: boolean = false;
  allDays: any;
  listDays = ["יום א'", "יום ב'", "יום ג'", "יום ד'", "יום ה'", "יום ו'"];

  sunDay: Date=new Date();
  disableButtonNext: boolean = false;
  disableButtonPrevious: boolean = false;

  constructor() { }

  ngOnInit() {
    this.initObjDays();
  }

  initObjDays() {
    const startWeek = this.fromDate.getDate() - this.fromDate.getDay();
    this.sunDay.setDate(startWeek)
    this.sunDay.setHours(0, 0, 0, 0)
    this.sunDay = new Date(this.sunDay.toString())
    this.initWeekly();
  }

  disabledButton() {
    this.disableButtonPrevious=this.fromDate.getTime() >= this.sunDay.getTime()
    let day=new Date(this.sunDay) 
    this.disableButtonNext=this.toDate.getTime()<=new Date(day.setDate(this.sunDay.getDate()+6)) .getTime()
  }

  initWeekly() {
    this.disabledButton()

    let objDays: any = {};

    let day=new Date(this.sunDay)

    for (let i = 0; i < 6; i++, day.setDate(day.getDate() + 1)) {
      objDays[day.toString()] = { day: this.listDays[i], date: new Date(day), details: [] };
    }

    for (let i = 0; i < this.listSortingDay.length; i++) {
      let index = new Date(this.listSortingDay[i].examDate);
      index.setHours(0, 0, 0, 0);
      if (String(index) in objDays) {
        objDays[String(index)].details.push(this.listSortingDay[i]);
      }
    }

    this.allDays = Object.keys(objDays).map((key) => objDays[key] as any);

    for (let i = 0; i < this.allDays.length; i++) {
      for (let j = this.allDays[i].details.length ? this.allDays[i].details.length : 0; j <= 10; j++) {
        this.allDays[i].details.push(new SortingDay())
      }
    }
    this.allDays = this.sortListSortingDayByDate(this.allDays);
  }

  sortListSortingDayByDate(array) {
    for (let i = 0; i < array.length; i++) {
      array[i].details.sort(function (a, b) {
        return new Date(a.fromHour).getTime() - new Date(b.fromHour).getTime();
      });
    }
    return array;
  }

  next() {
    this.sunDay = new Date(this.sunDay.setDate(this.sunDay.getDate() + 7))
    this.initWeekly()
  }

  previous() {
    this.sunDay = new Date(this.sunDay.setDate(this.sunDay.getDate() -7))
    this.initWeekly()
  }

  openModalStoringDay(sortingDay, num: number) {
    if (!sortingDay.professionId) { return }
    this.sendStatusModalToParent(sortingDay, 1)
  }

  modalAddSortingDay() {
    const sorting_day: SortingDay = new SortingDay();
    this.sendStatusModalToParent(sorting_day, 0);
  }

  sendStatusModalToParent(info, number: number) {
    let sorting_day: SortingDay = new SortingDay();

    sorting_day = (number == 1) ? info : {};
    let add: boolean = (number == 1) ? false : true;
    this.displayModal = true
    this.editSortingDay.emit({ display: this.displayModal, data: sorting_day, add: add })
  }
}
