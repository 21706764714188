import { Component, OnInit } from '@angular/core';
import { WantAdsService } from 'src/app/services/want-ads.service';
import { WantAdModel } from 'src/app/models/WantAd';
import { WantAdStatusEnumModel } from 'src/app/models/WantAdStatusEnumModel';
import { WantAdStatusEnum } from 'src/app/models/WantAdStatusEnum';
import { User } from 'src/app/models/interfaces';
import { UserDataService } from 'src/app/services/user-data.service';

@Component({
  selector: 'app-want-ads',
  templateUrl: './want-ads.component.html',
  styleUrls: ['./want-ads.component.scss']
})
export class WantAdsComponent implements OnInit {
  wantAdsList: Array<WantAdModel> = new Array<WantAdModel>()
  displayedColumns = [
    { header: 'תאריך עדכון', field: 'dateUpdated', width: '100px' },
    { header: 'כותרת', field: 'title' },
    { header: 'משרה חמה', field: 'hotJob', width: '87px' },
    { header: 'פירוט', field: 'content', width: '60px' },
    { header: 'ענף', field: 'branch' },
    { header: 'תת ענף', field: 'subBranch' },
    { header: 'מקצוע', field: 'profession' },
    { header: 'מתאריך', field: 'fromDate', width: '100px' },
    { header: 'עד תאריך', field: 'toDate', width: '100px' },
    { header: 'ססטוס', field: 'status', width: '70px' }
  ]
  displayDialog: boolean;
  selectedWantAd: WantAdModel;
  updateMode: boolean;
  statuses: WantAdStatusEnumModel[] = [
    { id: 0, desc: "All", hebrewDesc: "הכל" },
    { id: WantAdStatusEnum.Active, desc: "Active", hebrewDesc: "פעיל" },
    { id: WantAdStatusEnum.Inactive, desc: "InActive", hebrewDesc: "לא פעיל" },
    { id: WantAdStatusEnum.Canceled, desc: "Canceled", hebrewDesc: "מבוטל" }
  ]
  selectedValuesFilter: WantAdStatusEnumModel = new WantAdStatusEnumModel()
  user: User;
  bool: boolean = false

  constructor(public wantAdsService: WantAdsService, public userDataService: UserDataService) { }

  ngOnInit() {
    this.getUserData();
    this.getAllWantAds();
    this.initStatuses()
  }

  getUserData() {
    this.userDataService.getUser().subscribe(data => {
      this.user = data
    })
  }

  getAllWantAds() {
    // this.wantAdsList= [{

    //   dateUpdated: new Date(),

    //   fromDate: new Date(),

    //   toDate:  new Date(),

    //   branchId: 
    //   { "_id" :"60d44cf22f961e811fbff711",
    //   "code" : "4",
    //   "name" : "בנין"},

    //   subBranchId:{
    //     "_id" : "60f966cce2d8c77ea805426b",
    //     "allowedLicenseTypes" : [ 
    //         1, 
    //         2
    //     ],

    //     "branch" :  { "_id" :"60d44cf22f961e811fbff711",
    //     "code" : "4",
    //     "name" : "בנין"},
    //     "subCode" : "1",
    //     "subName" : "בנייה מגורים",

    // },
    //   professionId: {
    //     "_id" : "60f9218013980f377816cc6a",
    //     "professionId" : "1",
    //     "profession" : "טפסנות",
    //     "professionArb" : "طوبار",
    //     "subBranch" : {
    //       "_id" : "60f966cce2d8c77ea805426b",
    //       "allowedLicenseTypes" : [ 
    //           1, 
    //           2
    //       ],

    //       "branch" :  { "_id" :"60d44cf22f961e811fbff711",
    //       "code" : "4",
    //       "name" : "בנין",},
    //       "subCode" : "1",
    //       "subName" : "בנייה מגורים",

    //   },
    // },

    //   title: 'string',

    //   content: 'string',

    //   hotJob: true,

    //   status: 1,

    //   userId: '100000777'
    //  },
    //  {


    //   dateUpdated: new Date(),

    //   fromDate: new Date(),

    //   toDate:  new Date(),

    //   branchId: 
    //   { "_id" :"60d44cf22f961e811fbff711",
    //   "code" : "4",
    //   "name" : "בנין"},

    //   subBranchId:{
    //     "_id" : "60f966cce2d8c77ea805426b",
    //     "allowedLicenseTypes" : [ 
    //         1, 
    //         2
    //     ],

    //     "branch" :  { "_id" :"60d44cf22f961e811fbff711",
    //     "code" : "4",
    //     "name" : "בנין"},
    //     "subCode" : "1",
    //     "subName" : "בנייה מגורים",

    // },
    //   professionId: {
    //     "_id" : "60f9218013980f377816cc6a",
    //     "professionId" : "1",
    //     "profession" : "טפסנות",
    //     "professionArb" : "طوبار",
    //     "subBranch" : {
    //       "_id" : "60f966cce2d8c77ea805426b",
    //       "allowedLicenseTypes" : [ 
    //           1, 
    //           2
    //       ],

    //       "branch" :  { "_id" :"60d44cf22f961e811fbff711",
    //       "code" : "4",
    //       "name" : "בנין",},
    //       "subCode" : "1",
    //       "subName" : "בנייה מגורים",

    //   },
    // },

    //   title: 'string',

    //   content: 'string',

    //   hotJob: true,

    //   status: 2,

    //   userId:  '100000777'
    //  },
    //  {


    //   dateUpdated: new Date(),

    //   fromDate: new Date(),

    //   toDate:  new Date(),

    //   branchId: 
    //   { "_id" :"60d44cf22f961e811fbff711",
    //   "code" : "4",
    //   "name" : "בנין"},

    //   subBranchId:{
    //     "_id" : "60f966cce2d8c77ea805426b",
    //     "allowedLicenseTypes" : [ 
    //         1, 
    //         2
    //     ],

    //     "branch" :  { "_id" :"60d44cf22f961e811fbff711",
    //     "code" : "4",
    //     "name" : "בנין"},
    //     "subCode" : "1",
    //     "subName" : "בנייה מגורים",

    // },
    //   professionId: {
    //     "_id" : "60f9218013980f377816cc6a",
    //     "professionId" : "1",
    //     "profession" : "טפסנות",
    //     "professionArb" : "طوبار",
    //     "subBranch" : {
    //       "_id" : "60f966cce2d8c77ea805426b",
    //       "allowedLicenseTypes" : [ 
    //           1, 
    //           2
    //       ],

    //       "branch" :  { "_id" :"60d44cf22f961e811fbff711",
    //       "code" : "4",
    //       "name" : "בנין",},
    //       "subCode" : "1",
    //       "subName" : "בנייה מגורים",

    //   },
    // },

    //   title: 'string',

    //   content: 'string',

    //   hotJob: true,

    //   status: 3,

    //   userId: '100000777'
    //  }]

    if (!this.user) return;
    this.wantAdsService.getAllWantAds(this.user.user.identity).subscribe(data => {
      this.wantAdsList = data.data;
      this.updateAlertStatus()
    })
  }

  initStatuses() {
    this.selectedValuesFilter = this.statuses[0];
  }

  updateAlertStatus() {
    let today = new Date();
    this.wantAdsList.forEach(wantAd => {
      let messageToDate = new Date(wantAd.toDate);
      if (messageToDate < today && wantAd.status == WantAdStatusEnum.Active) {
        wantAd.status = WantAdStatusEnum.Inactive
      }
    });
  }

  addWantAd() {
    this.selectedWantAd = null;
    this.updateMode = false;
    this.displayDialog = true;
  }

  async editItem(wantAd: WantAdModel) {
    await this.updateOK_Employer(wantAd);
    this.selectedWantAd = wantAd;
    this.displayDialog = true;
    this.updateMode = true;
  }

  updateOK_Employer(wantAd: WantAdModel) {
    for (let i = 0; i < wantAd.interestedEmployees.length; i++) {
      wantAd.interestedEmployees[i].employerRead = true
    }
    this.wantAdsService.updateWantAd(wantAd).subscribe();
  }

  updateWantAdEvent(dataToSave: any) {
    if (dataToSave.delete == false) {
      this.displayDialog = false;
      return;
    }
    this.displayDialog = false;
    dataToSave.wantAd.status = dataToSave.status
    dataToSave.wantAd.dateUpdated = new Date();
    if (!!dataToSave.wantAd._id) {
      this.wantAdsService.updateWantAd(dataToSave.wantAd).subscribe(data => {
        this.wantAdsList = data.data;
        this.updateAlertStatus();
      });
      return;
    }
    dataToSave.wantAd.userId = this.user.user.identity
    this.wantAdsService.createWantAd(dataToSave.wantAd).subscribe(data => {
      this.wantAdsList = data.data;
      this.updateAlertStatus();
    })
  }

  deleteAlertMessageEvent(event: WantAdModel) {
    if (event._id == null) return;
    if (!!event._id) {
      this.wantAdsService.deleteWantAd(event._id).subscribe(data => {
        this.wantAdsList = data.data;
        this.updateAlertStatus();
      })
      this.displayDialog = false;
    }
  }

  showRowData(wantAd: WantAdModel) {

    if (this.selectedValuesFilter.id === wantAd.status || this.selectedValuesFilter.id === 0) {
      return true;
    }
  }

  filter() {
    // if(this.selectedValuesFilter.length === 0 ){
    //   this.wantAdsList = this.allWantAds;
    //   return;
    // } 
    // if(this.selectedValuesFilter.map(m=>m.id).includes(0)){
    //   this.wantAdsList = this.allWantAds;
    //   this.selectedValuesFilter = this.statuses;
    //   return;
    // }
    // this.wantAdsList = this.allWantAds.filter(m=> this.selectedValuesFilter.map(m=>m.id).includes(m.status))
    // this.wantAdsList = this.allWantAds.filter(m=> this.selectedValuesFilter.map(m=>m.id).includes(m.status))
  }


}
