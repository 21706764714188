import * as tslib_1 from "tslib";
import { OnInit, EventEmitter, QueryList, } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import employeeApplicationStatusColor from 'src/app/shared/util/employeeApplicationStatusColor';
import colorText from 'src/app/shared/util/colorText';
var EmployeeApplicationListComponent = /** @class */ (function () {
    function EmployeeApplicationListComponent(formService, wizradUserDataService, fieldService, buildingPermitService) {
        this.formService = formService;
        this.wizradUserDataService = wizradUserDataService;
        this.fieldService = fieldService;
        this.buildingPermitService = buildingPermitService;
        this.onGoBack = new EventEmitter();
        this.onResetStep = new EventEmitter();
        this.checkAllPercentage = 50;
        this.fileMap = new Map();
        this.displayedColumns = [];
        this.selection = new SelectionModel(true, []);
        this.buildingPermitDocument = null;
        this.beforeDeleteModalData = null;
        this._selectedField = null;
        this.confirmedDeleteOfBuildingPermit = false;
    }
    Object.defineProperty(EmployeeApplicationListComponent.prototype, "selectedField", {
        // @Input() selectedField: FieldsRequest;
        set: function (field) {
            this._selectedField = field;
            if (this.employeesApplicationList.editable
                && (this.buildingPermitService.hasBuildingPermitUploaded() || this.buildingPermitService.allowBuildingPermitUpload(true)))
                this.initBeforeDeleteModalData();
        },
        enumerable: true,
        configurable: true
    });
    EmployeeApplicationListComponent.prototype.ngOnInit = function () {
        //TODO: SEND ALL FIELD DATA AS Inputs(Make The Component Not Bound To Thr Field).
        var detailedEmployees = this.prepareData(this.employeesApplicationList.employees);
        this.dataSource = new MatTableDataSource(detailedEmployees);
        this.initDisplayColumns();
        if (this.employeesApplicationList.editable) {
            this.buildingPermitDocument = this.buildingPermitService.getBuildingPermitDownloadDocument();
            this.initAllObservables();
            this.setInitialAllChecked();
        }
    };
    Object.defineProperty(EmployeeApplicationListComponent.prototype, "validEmployees", {
        get: function () {
            return this.dataSource.data.filter(function (e) { return e.isValid; });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeApplicationListComponent.prototype, "unSelectedAndValid", {
        get: function () {
            var _this = this;
            return this.dataSource.data.filter(function (ds) { return !_this.selection.isSelected(ds) && ds.isValid; });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeApplicationListComponent.prototype, "unselected", {
        get: function () {
            var _this = this;
            return this.dataSource.data.filter(function (ds) { return !_this.selection.isSelected(ds); });
        },
        enumerable: true,
        configurable: true
    });
    EmployeeApplicationListComponent.prototype.initAllObservables = function () {
        var _this = this;
        //Check To Enable/Disable Per Selection;
        this.selection.changed.asObservable().subscribe(function (s) {
            _this.checkToEnableOrDisable();
        });
        this.buildingPermitService.getBuildingPermitRef().valueChanges.subscribe(function (bp) {
            //Building Permit Was Deleted On Another Step - Reset Wanted Workers Step
            if (!bp && !_this.confirmedDeleteOfBuildingPermit) {
                _this.onResetStep.emit();
            }
            _this.checkToEnableOrDisable();
        });
    };
    EmployeeApplicationListComponent.prototype.initBeforeDeleteModalData = function () {
        this.beforeDeleteModalData = {
            header: "שים לב",
            message: this.fieldService.getHasZeroLimitationWithNoBuildingPermit()
                ? "במידה ותמחק את הקובץ, תועבר למילוי פרטי הבקשה, מאחר והמכסה לענף זה תתאפס."
                : "במידה ותמחק את הקובץ, בחירתך תתאפס"
        };
    };
    EmployeeApplicationListComponent.prototype.initDisplayColumns = function () {
        var columns = ['id', 'status', 'file'];
        if (this.employeesApplicationList.editable) {
            columns.splice(0, 0, 'select');
        }
        this.displayedColumns = columns;
    };
    EmployeeApplicationListComponent.prototype.prepareData = function (employees) {
        var _this = this;
        return employees.map(function (e) {
            var isValid = _this.isEmployeeWithFileValid(e);
            return tslib_1.__assign({}, e, { isValid: isValid, disabled: !isValid });
        });
    };
    EmployeeApplicationListComponent.prototype.setInitialAllChecked = function () {
        if (!this.employeesApplicationList.editable) {
            return;
        }
        if (this.fieldService.hasEmploymentLimitation() &&
            this.validEmployees.length < this.fieldService.getAvailableEmployeeSum()) {
            this.masterToggle(true);
        }
        else {
            this.calculateToSelectAll();
        }
    };
    EmployeeApplicationListComponent.prototype.calculateToSelectAll = function () {
        var _this = this;
        if (!this.fieldService.hasEmploymentLimitation()) {
            this.masterToggle(true);
        }
        else {
            var availableEmployeePercentage = (this.fieldService.getAvailableEmployeeSum() * 100) /
                this.employeesApplicationList.employees.length;
            if (availableEmployeePercentage < this.checkAllPercentage) {
                return;
            }
            var employeesToSelect = this.dataSource.data
                .filter(function (ds) { return _this.validEmployees.includes(ds); })
                .slice(0, this.fieldService.getAvailableEmployeeSum());
            this.selectAndDisableFromList(employeesToSelect);
        }
    };
    EmployeeApplicationListComponent.prototype.selectAndDisableFromList = function (employeesToSelect) {
        var _a;
        var data = this.dataSource.data.reduce(function (acc, row) {
            if (employeesToSelect.includes(row))
                acc.toSelect.push(row);
            else
                acc.toDisable.push(row);
            return acc;
        }, {
            toSelect: [],
            toDisable: []
        });
        (_a = this.selection).select.apply(_a, tslib_1.__spread(data.toSelect));
        data.toDisable.forEach(function (row) { return row.disabled = true; });
    };
    EmployeeApplicationListComponent.prototype.toggleCheckAll = function (e) {
        this.masterToggle(e.checked);
    };
    /** Selects all rows if they are not all selected; otherwise clear selection. */
    EmployeeApplicationListComponent.prototype.masterToggle = function (checked) {
        var _this = this;
        if (!checked) {
            this.selection.clear();
        }
        else {
            this.dataSource.data.forEach(function (row) {
                if (row.isValid && _this.isAllowToSelect()) {
                    _this.selection.select(row);
                }
            });
        }
    };
    /** The label for the checkbox on the passed row */
    EmployeeApplicationListComponent.prototype.checkboxLabel = function (row) {
        if (!row) {
            return (this.isAllSelected() ? 'select' : 'deselect') + " all";
        }
        return (this.selection.isSelected(row) ? 'deselect' : 'select') + " row " + (row.id + 1);
    };
    /** Whether the number of selected elements matches the total number of rows. */
    EmployeeApplicationListComponent.prototype.isAllSelected = function () {
        var numSelected = this.selection.selected.length;
        return this.fieldService.hasEmploymentLimitation()
            ? numSelected === this.fieldService.getAvailableEmployeeSum() ||
                numSelected === this.validEmployees.length
            : numSelected === this.validEmployees.length;
    };
    EmployeeApplicationListComponent.prototype.allowUpload = function () {
        if (!this.fieldService.hasEmploymentLimitation()) {
            return true;
        }
        var numSelected = this.selection.selected.length;
        return numSelected !== this.fieldService.getAvailableEmployeeSum();
    };
    EmployeeApplicationListComponent.prototype.onEmployeeChecked = function (row) {
        this.selection.toggle(row);
    };
    EmployeeApplicationListComponent.prototype.checkToEnableOrDisable = function () {
        var _this = this;
        this.unSelectedAndValid.forEach(function (unselected) { return (unselected.disabled = _this.isAllSelected()); });
    };
    EmployeeApplicationListComponent.prototype.isEmployeeWithFileValid = function (emloyee) {
        return emloyee.file ? this.fileMap.has(emloyee.id) : true;
    };
    EmployeeApplicationListComponent.prototype.uploadFile = function (uploadedFile, row) {
        this.fileMap.set(row.id, uploadedFile);
        if (this.isAllowToSelect()) {
            this.selection.select(row);
            row.disabled = false;
        }
        row.isValid = true;
    };
    EmployeeApplicationListComponent.prototype.deleteFile = function (row) {
        this.fileMap.delete(row.id);
        this.selection.deselect(row);
        row.isValid = false;
        row.disabled = true;
    };
    EmployeeApplicationListComponent.prototype.getCellColor = function (employee) {
        return employee.file && this.fileMap.has(employee.id)
            ? colorText.BLACK
            : employeeApplicationStatusColor[this.licenseType][employee.status.code.toString()];
    };
    EmployeeApplicationListComponent.prototype.isAllowToSelect = function () {
        return (!this.fieldService.hasEmploymentLimitation() ||
            this.selection.selected.length < this.fieldService.getAvailableEmployeeSum());
    };
    EmployeeApplicationListComponent.prototype.hasMoreSelectionThenLimitation = function () {
        return this.dataSource.data.length > this.fieldService.getAvailableEmployeeSum(); // this.isAllSelected() && !!this.unselected.length;
    };
    EmployeeApplicationListComponent.prototype.showReachedMaxSelection = function () {
        return this.fieldService.hasEmploymentLimitation() && this.selection.selected.length === this.fieldService.getAvailableEmployeeSum() && !!this.unselected.length;
    };
    EmployeeApplicationListComponent.prototype.showUploadBuildingPermit = function () {
        return this.buildingPermitService.hasBuildingPermitUploaded() || (this.buildingPermitService.allowBuildingPermitUpload(true) && this.hasMoreSelectionThenLimitation());
    };
    EmployeeApplicationListComponent.prototype.onConfirmDeleteBuildingPermit = function () {
        var _this = this;
        console.log("onConfirmDeleteBuildingPermit start");
        if (this.fieldService.getHasZeroLimitationWithNoBuildingPermit()) {
            this.onGoBack.emit();
        }
        else {
            this.selection.clear();
            this.confirmedDeleteOfBuildingPermit = true;
        }
        setTimeout(function () {
            _this.confirmedDeleteOfBuildingPermit = false;
        }, 1000);
    };
    return EmployeeApplicationListComponent;
}());
export { EmployeeApplicationListComponent };
