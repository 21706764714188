import * as tslib_1 from "tslib";
import { OnInit, EventEmitter } from "@angular/core";
import { FormControl } from "@angular/forms";
// import { FileInputComponent } from "ngx-material-file-input";
import { checkForValidFiles } from "../../validators/files.validator";
import { ConfirmModalComponent } from "../confirm-modal/confirm-modal-component";
var UploadFileComponent = /** @class */ (function () {
    function UploadFileComponent(uploadService, dialogService) {
        this.uploadService = uploadService;
        this.dialogService = dialogService;
        this.onFileUpload = new EventEmitter();
        this.onFileDelete = new EventEmitter();
        this.onDeleteConfirm = new EventEmitter();
        this.requiredFileUpload = false;
        this.preventFileDelete = false;
        this.beforeDeleteInfo = null;
        this.deleteFileTooltipText = 'לא ניתן למחוק את הקובץ המצורף';
        this.disabled = false;
        this.uploadedFile = null;
        this.validFile = true;
        this.uploadSucces = true;
        this.errorMessage = "*קובץ לא תקין";
        this.uploadErrorMessage = "";
    }
    UploadFileComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.componentUid = 'file_' + Math.random().toString(36).substr(2, 9);
        if (this.formControlRef) {
            this.formControlRef.valueChanges.subscribe(function (uploadedFile) {
                _this.uploadedFile = uploadedFile;
            });
            if (this.formControlRef.value && this.formControlRef.value.id) {
                this.uploadedFile = this.formControlRef.value;
            }
        }
        this.setDialogConfig();
    };
    UploadFileComponent.prototype.setDialogConfig = function () {
        this.dialogConfig = {
            panelClass: "generalModal",
        };
    };
    UploadFileComponent.prototype.addFile = function (e) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var file;
            return tslib_1.__generator(this, function (_a) {
                file = e.target.files[0];
                if (!this.isFileInvalid(file)) {
                    this.uploadFileToServer(file);
                }
                return [2 /*return*/];
            });
        });
    };
    UploadFileComponent.prototype.setFile = function (payload) {
        if (this.formControlRef) {
            this.formControlRef.setValue(payload);
        }
        this.onFileUpload.emit(payload);
    };
    UploadFileComponent.prototype.uploadFileToServer = function (uploadFile) {
        if (uploadFile) {
            this.uploadErrorMessage = "";
            this.uploadService.UploadFile(uploadFile, this.downloadDocument.code)
                .subscribe({ next: this.onUploadSuccess.bind(this), error: this.onFail.bind(this) });
        }
    };
    UploadFileComponent.prototype.onUploadSuccess = function (payload) {
        var payloadData = tslib_1.__assign({}, payload);
        payloadData.fileTypeId = this.downloadDocument.code;
        this.uploadedFile = payloadData;
        this.setFile(payloadData);
    };
    UploadFileComponent.prototype.removeFile = function () {
        var _this = this;
        if (!this.uploadedFile) {
            return;
        }
        if (this.beforeDeleteInfo) {
            var modalData = {
                header: this.beforeDeleteInfo.header,
                message: this.beforeDeleteInfo.message
            };
            var dialogRef = this.dialogService.openModal(ConfirmModalComponent, modalData, this.dialogConfig);
            dialogRef.afterClosed().subscribe(function (confirm) {
                if (confirm) {
                    _this.deleteFile();
                    _this.onDeleteConfirm.emit();
                }
            });
        }
        else {
            this.deleteFile();
        }
    };
    UploadFileComponent.prototype.deleteFile = function () {
        this.uploadErrorMessage = "";
        this.uploadService.deleteFile(this.uploadedFile.id).subscribe({ next: this.onDeleteSuccess.bind(this), error: this.onFail.bind(this) });
    };
    UploadFileComponent.prototype.onDeleteSuccess = function () {
        this.uploadedFile = null;
        this.deleteRefFile();
    };
    UploadFileComponent.prototype.getDownloadUserFileUrl = function () {
        this.uploadErrorMessage = "";
        return this.uploadedFile ? this.uploadService.generateDownloadFileOfUser(this.uploadedFile.id) : "javascript:void(0)";
    };
    UploadFileComponent.prototype.getDownloadFileUrl = function () {
        this.uploadErrorMessage = "";
        return this.downloadDocument.hasDocumentToDownload && !this.disabled ? this.uploadService.generateDownloadFile(this.downloadDocument.code) : "javascript:void(0)";
    };
    UploadFileComponent.prototype.onFail = function (payload) {
        this.uploadErrorMessage = payload.error;
    };
    UploadFileComponent.prototype.isFileInvalid = function (file) {
        if (!file) {
            return;
        }
        var isInvalid = checkForValidFiles(file);
        if (isInvalid) {
            this.setErrors({ InvalidFiles: true });
            this.validFile = false;
        }
        else {
            this.setErrors(null);
            this.validFile = true;
        }
        return isInvalid;
    };
    UploadFileComponent.prototype.deleteRefFile = function () {
        if (this.formControlRef) {
            this.formControlRef.setValue(null);
        }
        this.onFileDelete.emit();
    };
    UploadFileComponent.prototype.setErrors = function (val) {
        if (this.formControlRef) {
            if (val) {
                this.formControlRef.setErrors(val);
            }
            else {
                this.formControlRef.setErrors(null);
            }
        }
    };
    UploadFileComponent.prototype.showError = function () {
        return !this.formControlRef && !this.validFile;
    };
    UploadFileComponent.prototype.getFileName = function () {
        return this.formControlRef && this.formControlRef.value
            ? this.formControlRef.value.name
            : this.uploadedFile ? this.uploadedFile.name : "";
    };
    UploadFileComponent.prototype.showUpload = function () {
        return (!this.formControlRef || (this.formControlRef && !this.formControlRef.value)) && !this.uploadedFile;
    };
    return UploadFileComponent;
}());
export { UploadFileComponent };
