import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { title } from 'process';
import { AuthStepTwoComponent } from 'src/app/pages/login/components/auth-step-two/auth-step-two.component';

@Component({
  selector: 'app-message-modal',
  templateUrl: './message-modal.component.html',
  styleUrls: ['./message-modal.component.scss']
})
export class MessageModalComponent implements OnInit {
  @Input() title: string
  @Input() displayModal: boolean
  @Input() showContact: boolean
  @Input() content: string
  @Input() img: string
  @Output() statusToEmit = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit() {
  }

  sendStatusModalToParent() {
    this.displayModal = false
    this.statusToEmit.emit(this.displayModal)
  }
}
