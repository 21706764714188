<section class="generalSection savedAdsSection">
    <div class="container-fluid">
        <div class="topSection">
            <h2 class="subTitle subTitle--blueTextColor ">
                מודעות שמורות
            </h2>
            <div class="btnsWrapper btnsWrapper--static">
                <button class="printBtn actionBtn colorTextBlue textContent textContent--semiBold">
                    <img src="assets/imgs/printer.svg" alt="" />
                    הדפסה
                </button>
                <button class="mailBtn actionBtn colorTextBlue textContent textContent--semiBold">
                    <img src="assets/imgs/mail.svg" alt="" />
                    שליחה במייל
                </button>
            </div>
            <div class="result textContent textContent--semiBold colorTextBlack">ישנן 4 מודעות שמורות</div>

        </div>

        <div class="cubesContainer">
            <div class="cubesList">
                <app-workers-ads [deleteBtn]="favoritePage"></app-workers-ads>
                <app-workers-ads [deleteBtn]="favoritePage"></app-workers-ads>

            </div>

            <!-- *ngIf="isWorkerListEmpty" -->
            <div class="noItem shadow bgWhite">
                <div class="noItem__inner">
                    <div class="noItem__picWrapper">
                        <img src="assets/imgs/noSavedAds.svg" alt="">
                    </div>
                    <div class="noItem__text">
                        <div class="subTitle subTitle--semiBold colorTextGray">אין מודעות שמורות</div>
                        <div class="textContent textContent--regular colorTextGray">במידה והמודעות ששמרת לא מופיעות כאן,
                            ניתן ליצור קשר עם נציג מתפ"ש במספר 4943*</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>