import { Component, OnInit, ViewChild, ElementRef, AfterViewInit,Output,EventEmitter } from '@angular/core';
// import PerfectScrollbar from 'perfect-scrollbar';
import { LoginService } from 'src/app/services/login.service';
import { Router } from '@angular/router';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { AuthService } from 'src/app/services/auth.service';
import { TermsConditions} from 'src/app/models/TermsConditions';
import { LicenseService } from 'src/app/services/license-service';

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss']
})
export class TermsAndConditionsComponent implements AfterViewInit, OnInit {
  @ViewChild('confirmBtn', { static: false }) confirmBtnRef: ElementRef;
  isDisabled: boolean;
  isUserAuth: boolean = false;
  text: string = '';
  isErrorShow: boolean = false;
  termsConditions: TermsConditions;
  approvedTerms:boolean


  public config: PerfectScrollbarConfigInterface = {
    wheelSpeed: 0.2
  };

  constructor(private loginService: LoginService, public router: Router, public auth: AuthService,private licenseService : LicenseService) {
  }

  ngOnInit() {
    // this.termsConditions = new TermsConditions()
    this.isUserAuth = this.auth.isAuthenticated()
    if (!this.isUserAuth){
      this.router.navigate(['login']);
    }
    
    this.loginService.getTerms().subscribe(res => {
      this.text = res.text;
      this.text += res.text;
      this.isDisabled = true;
    })

    
  
      this.licenseService.getLicense().subscribe(data=>{
        this.termsConditions=data.data;
      })
  }

  ngAfterViewInit() {
    if (!this.isUserAuth) {
      //this.confirmBtnRef.nativeElement.disabled = true;
    }
  }

  onScrollEvent(event) {
    if (!this.isUserAuth) {
      this.isDisabled = false;
    // this.confirmBtnRef.nativeElement.disabled = false;
    }
  }

  approved(){
    if(this.approvedTerms === true){
        this.TermsApproved.emit();
    }
  }


  @Output()
  TermsApproved: EventEmitter<number> = new EventEmitter<number>();

  
 // onScroll(event: any) {
      // visible height + pixel scrolled >= total height 
   //   if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
       // this.TermsApproved.emit();
     // }
//  }
  

}

