import * as tslib_1 from "tslib";
import { OnInit, QueryList, } from "@angular/core";
import { StepComponentBase } from "../wizard-step-component-base/step-component-base";
import { EmployeeApplicationListComponent } from "./applications/employee-list/employee-application-list.component";
import { unregisteredEmployeesMock, rejectedEmployeesMock, approvedEmployeesMock } from "../../mockData/wantedWorkersMockData";
import { filter, takeUntil } from "rxjs/operators";
import regionsTypes from "src/app/shared/util/regionTypes";
var WantedWorkersStepComponent = /** @class */ (function (_super) {
    tslib_1.__extends(WantedWorkersStepComponent, _super);
    function WantedWorkersStepComponent(stepsPagesService, wizradUserDataService, dialog, formService, employeeService, scrollToService) {
        var _this = _super.call(this, stepsPagesService, dialog) || this;
        _this.wizradUserDataService = wizradUserDataService;
        _this.formService = formService;
        _this.employeeService = employeeService;
        _this.scrollToService = scrollToService;
        _this.config = {
            wheelSpeed: 0.2
        };
        _this.isAdvancedFilter = false;
        _this.isFilterOpen = false;
        _this.generalError = "";
        _this.initialEmployeesStr = "";
        _this.hasNoApprovedEmployees = false;
        _this.fullList = false;
        _this.useMock = false;
        _this.allEmployeesResponse = [];
        _this.employeeArrayRef = _this.formService
            .getFormGroupRefByName("wantedWorkers")
            .get("employees");
        return _this;
    }
    WantedWorkersStepComponent.prototype.ngOnInit = function () {
        var _this = this;
        _super.prototype.ngOnInit.call(this);
        this.initInitialEmployees();
        //If the field was set before the component render.
        var fieldsRef = this.formService
            .getFormGroupRefByName("requestDetails")
            .get("fields");
        if (fieldsRef.value) {
            this.selectedField = fieldsRef.value;
        }
        fieldsRef.valueChanges.pipe(filter(function (field) { return !!field; }), takeUntil(this.componentDestroyed)).subscribe(function (field) {
            _this.resetAll();
            _this.selectedField = field;
        });
        var regionRef = this.formService.getFormGroupRefByName("transactionArea").get("region");
        if (regionRef.value) {
            this.licenseType = regionRef.value;
        }
        regionRef.valueChanges.pipe(filter(function (region) { return !!region; }), takeUntil(this.componentDestroyed)).subscribe(function (region) {
            _this.licenseType = region;
        });
    };
    WantedWorkersStepComponent.prototype.initInitialEmployees = function () {
        var employees = this.employeeService.getWantedWorkersEmployees();
        if (employees.length) {
            this.initialEmployeesStr = employees.toString().replace(/,/g, "\n");
        }
    };
    WantedWorkersStepComponent.prototype.geRequestDetailsData = function () {
        //const licenseType: number = this.formService.getFormGroupRefByName("transactionArea").get("region").value; // 1 for israel 2 for settelments
        var requestDetailsRef = this.formService.getFormGroupRefByName("requestDetails");
        var payload = {};
        if (this.licenseType == regionsTypes.SETTELMENTS) {
            var outsideLocality = requestDetailsRef.get("outside_locality").value;
            var settlementsCodes = requestDetailsRef.get("settlements").value.map(function (value) { return value.code; });
            var industrialArea = requestDetailsRef.get("industrial_area").value;
            payload = { outsideLocality: outsideLocality, settlementsCodes: settlementsCodes, industrialArea: industrialArea };
        }
        return payload;
    };
    WantedWorkersStepComponent.prototype.submitIdNumbers = function (idNumbers) {
        if (!this.useMock) {
            this.clearGeneralError();
            var requestData = tslib_1.__assign({ employees: idNumbers, fieldSubCode: this.selectedField.subCode, licenseLimitation: this.selectedField.licenseLimitation, licenseType: this.licenseType }, this.geRequestDetailsData());
            this.wizradUserDataService
                .getEmployees(requestData)
                .subscribe({ next: this.onSuccess.bind(this), error: this.onError.bind(this) });
        }
        else {
            this.onSuccess({});
        }
    };
    WantedWorkersStepComponent.prototype.onSuccess = function (response) {
        var _this = this;
        var unregisteredEmployees = {
            employees: this.useMock ? unregisteredEmployeesMock : response.unregisterdEmployees,
            editable: false,
            title: "תעודות זהות אשר לא קיימות במערכת",
            subtitle: (this.useMock ? unregisteredEmployeesMock.length : response.unregisterdEmployees.length) + " \u05EA\u05E2\u05D5\u05D3\u05D5\u05EA \u05D6\u05D4\u05D5\u05EA"
        };
        var rejectedEmployees = {
            employees: this.useMock ? rejectedEmployeesMock : response.rejectedEmployees,
            editable: false,
            title: "בקשות שנדחו",
            subtitle: (this.useMock ? rejectedEmployeesMock.length : response.rejectedEmployees.length) + " \u05D1\u05E7\u05E9\u05D5\u05EA \u05E9\u05E0\u05D3\u05D7\u05D5 - \u05E9\u05D9\u05DE\u05D5 \u05DC\u05D1, \u05D1\u05E7\u05E9\u05D5\u05EA \u05D0\u05DC\u05D5 \u05DC\u05D0 \u05D9\u05EA\u05D5\u05D5\u05E1\u05E4\u05D5 \u05DC\u05EA\u05D4\u05DC\u05D9\u05DA \u05D4\u05D2\u05E9\u05EA \u05D1\u05E7\u05E9\u05EA \u05D4\u05E8\u05D9\u05E9\u05D9\u05D5\u05DF"
        };
        var approvedEmployees = {
            employees: this.useMock ? approvedEmployeesMock : response.approvedEmployees,
            editable: true,
            title: "בקשות שהתקבלו",
            subtitle: (this.useMock ? approvedEmployeesMock.length : response.approvedEmployees.length) + " \u05D1\u05E7\u05E9\u05D5\u05EA \u05E9\u05D4\u05EA\u05E7\u05D1\u05DC\u05D5"
        };
        this.allEmployeesResponse = [
            unregisteredEmployees,
            rejectedEmployees,
            approvedEmployees
        ];
        this.allEmployeesResponse.map(function (res) {
            return res.employees.length == 0 ? _this.fullList = true : '';
        });
        this.hasNoApprovedEmployees = response.approvedEmployees.length === 0;
        this.scrollToService.scrollTo(".employeeListSection");
    };
    WantedWorkersStepComponent.prototype.clearGeneralError = function () {
        this.generalError = "";
    };
    WantedWorkersStepComponent.prototype.onError = function (response) {
        this.generalError = "קיימת תקלה זמנית,אנא נסה מאוחר יותר";
    };
    WantedWorkersStepComponent.prototype.goBack = function () {
        this.resetAll();
        if (this.hasBack()) {
            this.goToBackStep();
        }
    };
    WantedWorkersStepComponent.prototype.resetAll = function () {
        this.allEmployeesResponse = [];
        this.employeeArrayRef.clear();
    };
    WantedWorkersStepComponent.prototype.submit = function () {
        var _this = this;
        var employeesApplicationListRef = this.employeeListRef.find(function (l) { return l.employeesApplicationList.editable; });
        if (employeesApplicationListRef) {
            var selectedEmployees = employeesApplicationListRef.selection.selected;
            if (!selectedEmployees.length) {
                return;
            }
            this.employeeArrayRef.clear();
            selectedEmployees.forEach(function (employee) {
                _this.employeeArrayRef.push(_this.createEmployeeGroup(employee, employeesApplicationListRef));
            });
            _super.prototype.submit.call(this);
        }
    };
    WantedWorkersStepComponent.prototype.createEmployeeGroup = function (employee, employeesApplicationListRef) {
        var group = this.formService.createGroup();
        group.addControl("id", this.formService.createControl(employee.id));
        group.addControl("status", this.formService.createControl(employee.status));
        var file = employee.file &&
            employeesApplicationListRef.fileMap.has(employee.id)
            ? employeesApplicationListRef.fileMap.get(employee.id)
            : null;
        group.addControl("file", this.formService.createControl(file));
        return group;
    };
    WantedWorkersStepComponent.prototype.toggleAdvancedFilter = function () {
        this.isAdvancedFilter = !this.isAdvancedFilter;
    };
    WantedWorkersStepComponent.prototype.toggleFilter = function () {
        this.isFilterOpen = !this.isFilterOpen;
    };
    return WantedWorkersStepComponent;
}(StepComponentBase));
export { WantedWorkersStepComponent };
