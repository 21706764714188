import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { LoginFormModalComponent } from 'src/app/pages/login/components/login-form-modal/login-form-modal.component';
import { ValidateId } from 'src/app/shared/validators/id.validator';
import { LoginService } from 'src/app/services/login.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-auth-step-two',
  templateUrl: './auth-step-two.component.html',
  styleUrls: ['./auth-step-two.component.scss']
})
export class AuthStepTwoComponent implements OnInit {
  otpForm: FormGroup;
  userData: any;
  otpResend = false;
  errorFromServer = '';
  displayModal: boolean = false;
  title: string
  showContact: boolean
  content: string
  img: string
  update: boolean
  visibleTermsDialog: boolean = false
  disabledApproveTerms: boolean = true
  isErrorShow: boolean = false;

  @Output() goToLoginStep = new EventEmitter();

  constructor(
    private dialog: MatDialog,
    private fb: FormBuilder,
    private loginService: LoginService,
    public router: Router
  ) { }

  ngOnInit() {
    this.createForm();
    this.loginService.getUserData().subscribe(userData => {
      this.userData = userData;
      this.otpForm.get('identity').setValue(this.userData.identity);
    });
    // this.otpForm.valueChanges.subscribe(() => {
    //   console.log(this.otpForm);
    // });
  }

  getStatusModal(status: boolean) {
    this.displayModal = status;
    this.visibleTermsDialog = false
  }


  createForm(): void {
    this.otpForm = this.fb.group({
      identity: [null, [Validators.required, ValidateId]],
      otp: [
        null,
        [
          Validators.required,
          Validators.maxLength(6),
          Validators.minLength(6),
          Validators.pattern('^[0-9]*$')
        ]
      ]
    });
  }

  openLoginFormModal() {
    this.title = this.userData.authType == 0 ? `מספר נייד לא תקין` : `כתובת מייל לא תקינה`
    this.showContact = true

    if (this.title === 'מספר נייד לא תקין') {
      this.content = ' במידה ומספר הנייד שרשום אינו תקין ואינו בהכרות עם בעל הטלפון הרשום, יש לפנות לנציג התעסוקה'
      this.img = "./assets/imgs/email-icon.png"
    }
    else {
      this.content = ' במידה וכתובת המייל שרשום אינה תקינה ואינה בהכרות עם בעל המייל הרשום, יש לפנות לנציג התעסוקה'
      this.img = "./assets/imgs/email-icon.png"

    }
    this.displayModal = true;
    this.visibleTermsDialog = false
  }

  resendOtp() {
    this.loginService
      .getOtp({
        identity: this.userData.identity,
        authType: this.userData.authType
      })
      .subscribe((success: any) => {
        this.otpResend = true;
      }, (error: any) => { });
  }


  onSubmit() {
    this.loginService.login(this.otpForm.value).subscribe(
      res => {
        if (!!res.token) {
          sessionStorage.setItem('token', res.token);
        }
        if (!!res.user) {
          let user = { user: res.user }
          sessionStorage.setItem('userData', JSON.stringify(user))
        }
        res.isTermsValid ? this.router.navigate(['home']) : this.visibleTermsDialog = true;
        // this.visibleTermsDialog = true;
      },
      (error: any) => {
        this.otpForm.get('otp').setErrors({ 'incorrect': true })
        this.errorFromServer = error.error;
        if (error.status == 401) {
          setTimeout(() => {
            window.location.reload();
          }, 5000);
        }
      }
    );
  }

  termsApproved(event) {
    this.disabledApproveTerms = false;

  }

  approveTerms() {
    this.loginService.getUserData().subscribe(userData => {
      this.loginService.agreeTerms({ identity: userData.identity }).subscribe(res => {
        if (!!res && !!res.success) {
          sessionStorage.setItem('token', res.token);
          this.isErrorShow = false;
          this.displayModal = false;
          this.visibleTermsDialog = false;
          this.router.navigate(['home']);
        }
      }, (err) => {
        this.displayModal = true;
        this.visibleTermsDialog = false
        this.isErrorShow = true;
      })
    })
  }
}
