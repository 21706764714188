<ng-container *ngIf="showUpload(); then uploadFile; else deleteFile">
</ng-container>

<ng-template #uploadFile>
  <div class="uploadLinkWrapper">
    <div class="uploadLink" [class.disabledLink]="disabled">
      <input type="file" name="files" [id]="componentUid" class="inputfile sr-only" #filesInput
        (change)="addFile($event)" [disabled]="disabled ? '' : null" />
      <label [for]="componentUid" class="">
        <svg class="iconImg" width="10px" height="10px" viewBox="0 0 10 10" version="1.1"
          xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
          <g id="upload" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <path
              d="M9.37165775,10 L0.628342246,10 C0.280748663,10 0,9.73551637 0,9.40806045 L0,7.05289673 C0,6.72544081 0.280748663,6.46095718 0.628342246,6.46095718 C0.975935829,6.46095718 1.25668449,6.72544081 1.25668449,7.05289673 L1.25668449,8.81612091 L8.74331551,8.81612091 L8.74331551,7.05289673 C8.74331551,6.72544081 9.02406417,6.46095718 9.37165775,6.46095718 C9.71925134,6.46095718 10,6.72544081 10,7.05289673 L10,9.40806045 C9.98663102,9.73551637 9.70588235,10 9.37165775,10 Z M4.54545455,0.179471033 C4.79946524,-0.0598236776 5.21390374,-0.0598236776 5.46791444,0.179471033 L7.31283422,1.90491184 C7.56684492,2.14420655 7.56684492,2.53463476 7.31283422,2.77392947 C7.05882353,3.01322418 6.64438503,3.01322418 6.39037433,2.77392947 L5.62834225,2.05604534 L5.62834225,5.29282116 C5.62834225,5.62027708 5.34759358,5.88476071 5,5.88476071 C4.65240642,5.88476071 4.37165775,5.62027708 4.37165775,5.29282116 L4.37165775,2.0686398 L3.60962567,2.77392947 C3.35561497,3.01322418 2.94117647,3.01322418 2.68716578,2.77392947 C2.43315508,2.53463476 2.43315508,2.14420655 2.68716578,1.90491184 L4.54545455,0.179471033 Z"
              id="Shape" fill="#056FF2" fill-rule="nonzero"></path>
          </g>
        </svg>
        <span class="uploadLink__text link  link--underLine link--semiBold">להעלאת מסמך</span>
      </label>
      <div class="appMatOverride">
        <mat-error *ngIf="requiredFileUpload" class="uploadLink__validation">
          יש להעלות קובץ
        </mat-error>
        <mat-error *ngIf="!validFile" class="uploadLink__validation">
          {{errorMessage}}
        </mat-error>
      </div>

    </div>
    <div *ngIf="downloadDocument?.hasDocumentToDownload" class="uploadLink" [class.disabledLink]="disabled">
      <a [href]="getDownloadFileUrl()" class=""
        [target]="disabled ? '' : '_blank'">
        <svg class="iconImg" width="10px" height="10px" viewBox="0 0 10 10" version="1.1"
          xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
          <g id="download" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <path
              d="M9.37165775,10 L0.628342246,10 C0.280748663,10 0,9.73551637 0,9.40806045 L0,7.05289673 C0,6.72544081 0.280748663,6.46095718 0.628342246,6.46095718 C0.975935829,6.46095718 1.25668449,6.72544081 1.25668449,7.05289673 L1.25668449,8.81612091 L8.74331551,8.81612091 L8.74331551,7.05289673 C8.74331551,6.72544081 9.02406417,6.46095718 9.37165775,6.46095718 C9.71925134,6.46095718 10,6.72544081 10,7.05289673 L10,9.40806045 C9.98663102,9.73551637 9.70588235,10 9.37165775,10 Z M5.45454545,5.70528967 C5.20053476,5.94458438 4.78609626,5.94458438 4.53208556,5.70528967 L2.68716578,3.97984887 C2.43315508,3.74055416 2.43315508,3.35012594 2.68716578,3.11083123 C2.94117647,2.87153652 3.35561497,2.87153652 3.60962567,3.11083123 L4.37165775,3.82871537 L4.37165775,0.591939547 C4.37165775,0.264483627 4.65240642,2.09915418e-13 5,2.09915418e-13 C5.34759358,2.09915418e-13 5.62834225,0.264483627 5.62834225,0.591939547 L5.62834225,3.81612091 L6.39037433,3.11083123 C6.64438503,2.87153652 7.05882353,2.87153652 7.31283422,3.11083123 C7.56684492,3.35012594 7.56684492,3.74055416 7.31283422,3.97984887 L5.45454545,5.70528967 Z"
              id="Shape-Copy" fill="#056FF2" fill-rule="nonzero"></path>
          </g>
        </svg>
        <span class="uploadLink__text link  link--underLine link--semiBold">טופס מילוי</span>
      </a>
    </div>
  </div>
</ng-template>

<ng-template #deleteFile>
  <div class="tableDataInside deleteFileWrapper tableDataInside--semiBold">
    <a [href]="getDownloadUserFileUrl()" class="fileNameWrapper" target="_blank">
      <img class="iconImg " src="assets/imgs/pdf-icon.png" alt="pdf icon">
      <span class="fileTextName">{{getFileName()}}</span>
    </a>
    <button [disabled]="preventFileDelete" class="deleteFileBtn" (click)="removeFile()"
      [class.preventDelete]="preventFileDelete">
      <svg class="iconImg" xmlns="http://www.w3.org/2000/svg" width="12" height="15" viewBox="0 0 12 15">
        <g fill="#FF0048" fill-rule="nonzero">
          <path
            d="M3.818 12.857a.54.54 0 0 0 .546-.536V6.43a.54.54 0 0 0-.546-.536.54.54 0 0 0-.545.536v5.892a.54.54 0 0 0 .545.536zM6 12.857a.54.54 0 0 0 .545-.536V6.43A.54.54 0 0 0 6 5.893a.54.54 0 0 0-.545.536v5.892a.54.54 0 0 0 .545.536zM8.182 12.857a.54.54 0 0 0 .545-.536V6.43a.54.54 0 0 0-.545-.536.54.54 0 0 0-.546.536v5.892a.54.54 0 0 0 .546.536z" />
          <path
            d="M11.455 1.607H8.182v-.536C8.182.48 7.693 0 7.09 0H4.909c-.602 0-1.09.48-1.09 1.071v.536H.544A.54.54 0 0 0 0 2.143v2.143a.54.54 0 0 0 .545.535h.546v8.572c0 .887.733 1.607 1.636 1.607h6.546c.903 0 1.636-.72 1.636-1.607V4.82h.546A.54.54 0 0 0 12 4.286V2.143a.54.54 0 0 0-.545-.536zm-6.546-.536h2.182v.536H4.909v-.536zm4.91 12.322a.54.54 0 0 1-.546.536H2.727a.54.54 0 0 1-.545-.536V4.82h7.636v8.572zm1.09-9.643H1.091V2.679h9.818V3.75z" />
        </g>
      </svg>

      <span class="deleteFileBtn__text" [class.disableColor]="preventFileDelete">
        מחיקת קובץ
      </span>
    </button>
    <button *ngIf="preventFileDelete" #popBtn type="button" class="popOverImg" [class.active]="popBtn.clicked"
      placement="bottom" popoverClass="popover" [ngbPopover]="popContent">
      <app-svg svgType="popover"></app-svg>
    </button>
    <ng-template #popContent>
      <div class="popoverMessage">
        {{deleteFileTooltipText}}
      </div>
    </ng-template>
  </div>
</ng-template>
<span>{{uploadErrorMessage?uploadErrorMessage:''}}</span>
