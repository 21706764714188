import { Component, OnInit } from '@angular/core';
import { Query } from 'src/app/models/queries';
import { QueriesService } from 'src/app/services/queries.service';

@Component({
  selector: 'app-queries',
  templateUrl: './queries.component.html',
  styleUrls: ['./queries.component.scss']
})
export class QueriesComponent implements OnInit {

  queries: Query[];
  currentQuery: Query;
  inputsValues: { [name: string]: string };
  response: any;

  constructor(private queriesService: QueriesService) { }

  async ngOnInit() {
    this.queries = await this.queriesService.getQueries();
    this.queries.forEach(q => {
      q.label = q.name;
      q.command = this.onSelectQuery();
    })
    this.onSelectQuery()({ item: this.queries[0] });
  }

  onSelectQuery() {
    return (event: { item: Query }) => {
      this.currentQuery = event.item;
      this.resetInputValues();
    };
  }

  sendQuery() {
    this.response = null;
    this.queriesService.sendQuery(this.currentQuery._id, this.inputsValues).subscribe(
      res => {
        this.response = res.data;
        this.resetInputValues();
      }
    );
  }

  resetInputValues() {
    this.inputsValues = {};
  }
}