<h2 class="d-flex">
     {{ isUserAuth ? 'תנאי שימוש' : 'תקנון ותנאי השימוש באתר' }}
</h2>
<div *ngIf="termsConditions" class="d-flex flex-grow-1 flex-column overflow-auto px-3">
     <!--   (scroll)="onScroll($event)"-->
     <div [innerHTML]="termsConditions.contents"> </div>

     <div class="d-flex  align-items-center">
          <p-checkbox class="position-relative" [(ngModel)]="approvedTerms" label=" " (onChange)="approved()"
               [binary]="true"></p-checkbox> <span class="mr-2">קראתי את תקנון האתר</span>
     </div>
</div>
<div class="errorMsg errorMsg--center" *ngIf="isErrorShow">עקב תקלה זמנית אין באפשרותינו לבצע את הפעולה</div>