<div *ngIf="requestStatusData" class="employeesStatuses">
  <!-- <div class="titleContainer">
    <h2 class="subTitle subTitle--blueTextColor">סטטוס בקשות לרישיון</h2>
    <div *ngIf="requestStatusData.aggregatedRequestsData.aggregatedAwaitingWorkersAmount && !user?.hasBranchOnlyInIsrael"
      class="textContent  textContent--semiBold">
      {{ requestStatusData.aggregatedRequestsData.aggregatedAwaitingWorkersAmount }} בקשות ממתינות לאישור
    </div>
  </div>

  <ng-container *ngIf="requestStatusData && requestStatusData.requestsStatus.length && !user?.hasBranchOnlyInIsrael; then hasRequests; else noRequests">
  </ng-container>

  <ng-template #hasRequests>
    <ul class="listStatusRequest">
    <ng-container *ngFor="let licenseRequest of requestStatusData.requestsStatus; let i = index">
        <li class="bgWhite shadow listStatusRequest__item">
          <h4 class="textContent  textContent--bold listStatusRequest__reqNumber">
            פנייה מספר {{ licenseRequest.applicationID }} {{licenseRequest.applicationType == '2' ? '(ממתין לחידוש)' : ''}}
          </h4>
          <div class="listStatusRequest__statusWrapper">
            <div *ngIf="licenseRequest.awaitingWorkersAmount" class="listStatusRequest__statusTitle">
              <span class="textContent textContent--regular colorTextGray">
                ממתינים לאישור
              </span>
            </div >
            <div  class="listStatusRequest__statusTitle">
              <span *ngIf="licenseRequest.approvalWorkersAmount" class="textContent textContent--regular colorTextGray">
                עובדים אושרו
              </span>
              <span *ngIf="licenseRequest.declinedWorkersAmount" class="textContent textContent--regular colorTextGray">
                עובדים סורבו
              </span>
            </div>
          </div>
          <div class="listStatusRequest__statusWrapper">
              <div *ngIf="licenseRequest.awaitingWorkersAmount" class="textContent textContent--bold colorTextGray listStatusRequest__statusCount">
                {{ licenseRequest.awaitingWorkersAmount }}
              </div>
            <div  class="listStatusRequest__sentRequest">
              <div *ngIf="licenseRequest.approvalWorkersAmount"
                class="textContent textContent--bold colorTextBlue listStatusRequest__statusCount">
                {{ licenseRequest.approvalWorkersAmount }}
              </div>


              <div *ngIf="licenseRequest.declinedWorkersAmount"
                class="textContent textContent--bold colorTextRed listStatusRequest__statusCount">
                {{ licenseRequest.declinedWorkersAmount }}
              </div>
            </div>
            <div class="listStatusRequest__linkWrapper">
             
            </div>
          </div>
        </li>
    </ng-container>
  </ul>
  </ng-template>
  <ng-template #noRequests>
    <div class="noLicenseRequests">
      <div class="noLicenseRequests__box">
        <img src="./assets/imgs/emptyBox.png" class="emptyImg" />
        <div class="subTitle subTitle--grayTextColor">
          אין בקשות מתאימות
        </div>
        <div class="noLicenseRequests__noteText">
          במידה ובקשתך לא מופיעה כאן, ניתן ליצור קשר עם נציג שירות במספר 4943*
        </div>
      </div>
    </div>
  </ng-template>
  <div class="statusesBottom">
    <button 
      class="btn btn--sidePadding btn--blue btn--mobileWidthBtn btn--desktopWidthBtn" 
      (click)="requestStatusData && requestStatusData.requestsStatus.length ? goTo('requestsandlicenses/requests') : goTo('employmentapplication')"
      *ngIf="!user?.hasBranchOnlyInIsrael">
        {{requestStatusData && requestStatusData.requestsStatus.length ? ("צפייה בכל הבקשות") : ("הגשת בקשה לרישיונות")}}
    </button>
    <div class="lastUpdateInfo">
      מעודכן נכון לתאריך {{ date | date: "d/M/y" }}
    </div>
  </div> -->

  <div class="header-status flex-md-row flex-column d-flex justify-content-between">

    <h4 class="d-flex align-items-center"><i class="ml-3 icon-Request-status"></i>סטטוס בקשות לרשיון</h4>
    <div class="gray-div-status d-flex align-items-center">
      <i class="icon-Requests-pending ml-3"></i>
      <label> {{ requestStatusData.aggregatedRequestsData.aggregatedAwaitingWorkersAmount }} בקשות ממתינות</label>
    </div>
  </div>

  <ul class="mb-4">
  <li *ngFor="let licenseRequest of licenseRequestsList" >
  <div class="request-wrap d-flex">
    <div class="line-circle">
      <div class="circle-div"></div>
    </div>
    <div class="d-flex flex-column wrap-info">
      <div class="mb-3 number-request">בקשה מספר {{licenseRequest.applicationID}} {{licenseRequest.applicationType == '2' ? '(ממתין לחידוש)' : ''}}  </div>
    <div class="d-flex">
      <div class="d-flex flex-grow-1"> 
       <div class="row w-100">
        <div *ngIf="licenseRequest.approvalWorkersAmount" class="col-6 d-flex status-info green-status">
          <div><i class="ml-3 icon-Test-performed"></i></div>
          <div class="d-flex flex-column"> 
            <label>עובדים שאושרו</label>
            <span>{{licenseRequest.approvalWorkersAmount}}</span>
          </div>
        </div>
        <div *ngIf="licenseRequest.declinedWorkersAmount" class="col-6  d-flex status-info red-status">
            <div><i class="ml-3 icon-Test-performed"></i></div>
            <div class="d-flex flex-column"> 
              <label>עובדים שסורבו</label>
              <span>{{licenseRequest.declinedWorkersAmount}}</span>
            </div>
          </div>
          <div *ngIf="licenseRequest.awaitingWorkersAmount" class="col-6  d-flex status-info orange-status">
            <div><i class="ml-3 icon-Test-performed"></i></div>
            <div class="d-flex flex-column"> 
              <label>עובדים בבקשה</label>
              <span>{{licenseRequest.awaitingWorkersAmount}}</span>
            </div>
          </div>
         
         </div></div>
        <div class="d-flex wrap-btn-info">
          <button type="button" pButton
            class="ui-button-rounded w-100 button-matpash light-blue-border d-flex align-items-center justify-content-center fs-16"
            label="למידע נוסף">
            <i class="icon-16 mr-md-3"></i>
          </button>

        </div>
     
    </div>
  </div></div>
</li>
  
  </ul>

 <div class="row mt-auto">
   <div class="col-12 col-md-6">
  <button type="button" pButton class="ui-button-rounded  button-matpash blue-fill d-flex align-items-center  justify-content-center  end-btn" (click)="goTo('employmentapplication')" label="הגשת בקשה להעסקה">
    <i class="icon-send mr-3"></i>
  </button>

</div></div>

</div>