import { Observable, of } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "../shared/service/ApplicationHttpClient";
// import { User } from '../interfaces/user';
var LoginService = /** @class */ (function () {
    function LoginService(http) {
        this.http = http;
    }
    LoginService.prototype.getOtp = function (loginData) {
        return this.http.Post('api/auth/getotp', loginData);
    };
    LoginService.prototype.login = function (loginData) {
        return this.http.Post('api/auth/login', loginData);
    };
    LoginService.prototype.logout = function () {
        return this.http.Post('api/auth/logout', {});
    };
    LoginService.prototype.getTerms = function () {
        return this.http.Get('api/auth/terms');
    };
    LoginService.prototype.agreeTerms = function (identity) {
        return this.http.Post('api/auth/aprroveRegulations', identity);
    };
    LoginService.prototype.setUserData = function (userData) {
        // this.userDataSubject.next(userData);
        sessionStorage.setItem('userData', JSON.stringify(userData));
    };
    LoginService.prototype.getUserData = function () {
        var userData = sessionStorage.getItem('userData');
        return of(JSON.parse(userData));
        // return this.userDataSubject.asObservable();
    };
    LoginService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LoginService_Factory() { return new LoginService(i0.ɵɵinject(i1.ApplicationHttpClient)); }, token: LoginService, providedIn: "root" });
    return LoginService;
}());
export { LoginService };
