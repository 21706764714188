<div *ngIf="currentSection"  class="currentSection d-flex container-fluid w-container-box p-0">
    <div class="col-md-2">
        <div class="right-box">
            <p-menu 
               class="menu"
               [model]="[
                    { label : 'איזור ראשי' , command : selectedSection('area') , styleClass : getClassBySection('area') },
                    { label : 'איזור משנה' , command : selectedSection('subArea'), styleClass : getClassBySection('subArea') },
                    { label : 'ענפים' , command : selectedSection('branch') , styleClass : getClassBySection('branch') },
                    { label : 'תתי ענפים' , command : selectedSection('subBranch') , styleClass : getClassBySection('subBranch') },
                    { label : 'סוגי מעסיקים' , command : selectedSection('employerType') , styleClass : getClassBySection('employerType') },
                    { label : 'סטטוס מסמכים' , command : selectedSection('documentType') , styleClass : getClassBySection('documentType') },
                    { label : 'שעות עבודה' , command : selectedSection('hoursRange') , styleClass : getClassBySection('hoursRange') }
               ]" 
            ></p-menu>
        </div>
    </div>

    <div class="col-md-10">
        <div class="tabsSection left-box">

            <h1 class="mb-5">{{currentSection.pageHeader}}</h1>

            <div class="d-flex flex-wrap">

                <p-dropdown 
                   *ngIf="currentSection.showFilters.area" 
                   placeholder="איזור ראשי"
                   (onChange)="selectedFilterArea($event)"
                   [ngModel]="filters.area.value" 
                   [disabled]="!filters.area.value"
                   [options]="filters.area.options"
                   optionLabel="area.areaName"
                   class="ml-3 mb-3"
                ></p-dropdown>
                   
                <p-dropdown 
                   *ngIf="currentSection.showFilters.subArea" 
                   placeholder="איזור משנה" 
                   (onChange)="selectedFilterSubArea($event)"
                   [ngModel]="filters.subArea.value"
                   [disabled]="!filters.subArea.value"
                   [options]="filters.subArea.options"
                   optionLabel="subArea.subAreaName"
                   class="ml-3 mb-3"
                ></p-dropdown>
                   
                <p-dropdown
                   *ngIf="currentSection.showFilters.branch"
                   placeholder="ענפים" 
                   (onChange)="selectedFilterBranch($event)"
                   [ngModel]="filters.branch.value" 
                   [disabled]="!filters.branch.value"
                   [options]="filters.branch.options"
                   optionLabel="branch.name"
                   class="ml-3 mb-3"
                ></p-dropdown>
                   
                <p-dropdown
                   *ngIf="currentSection.showFilters.subBranch"
                   placeholder="תתי ענפים" 
                   (onChange)="selectedFilterSubBranch($event)"
                   [ngModel]="filters.subBranch.value" 
                   [disabled]="!filters.subBranch.value"
                   [options]="filters.subBranch.options"
                   optionLabel="subBranch.subName"
                   class="ml-3 mb-3"
                ></p-dropdown>
                   
                <p-dropdown 
                   *ngIf="currentSection.showFilters.employerType" 
                   placeholder="סוגי מעסיקים" 
                   (onChange)="selectedFilterEmployerType($event)"
                   [ngModel]="filters.employerType.value"
                   [disabled]="!filters.employerType.value"
                   [options]="filters.employerType.options"
                   optionLabel="employerType.employerTypeDesc"
                   class="ml-3 mb-3"
                ></p-dropdown>

            </div>

            <p-table
               *ngIf="showTable" 
               [columns]="currentSection.columns" 
               [value]="currentSectionMD"
               [scrollable]="true" 
               scrollHeight="400px"
            >
                <ng-template pTemplate="header">
                    <tr>
                       <th *ngFor="let col of currentSection.columns">
                           <span>{{col.header}}</span>
                      </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-doc>
                    <tr>
                        <td *ngFor="let col of currentSection.columns" >

                            <span *ngIf="!col.fromSC">{{doc[col.fieldName]}}</span>

                            <p-selectButton 
                               #sb
                               *ngIf="col.selectButton" 
                               class="selectButton-matpash"
                               [options]="[{ label: 'כן', value: true },{ label: 'לא', value: false }]"
                               [ngModel]="!!getDataFromCurrentSectionSC(doc._id, col.fieldName)"
                               (onChange)="setDataToCurrentSectionSC(doc._id, col.fieldName, sb.value)"
                               [disabled]="isCellDisabled(doc._id, col.fieldName)"
                            ></p-selectButton>
                              <div class="small-input">
                            <input pInputText
                               #in 
                               *ngIf="col.editable"
                               type="number"
                               [ngModel]="getDataFromCurrentSectionSC(doc._id, col.fieldName)"
                               (change)="setDataToCurrentSectionSC(doc._id, col.fieldName, in.value)"
                               [disabled]="isCellDisabled(doc._id, col.fieldName)"
                            ></div>  
                        </td>                        
                    </tr>
                </ng-template>
            </p-table>

            <div class="d-flex mt-5">
                <button pButton 
                    type="button" 
                    label="שמור" 
                    [disabled]="!unsavedChanges"
                    class="ui-button-rounded button-matpash light-blue-fill mr-auto"
                    (click)="updateOrCreateSystemConstitution()"
                ></button>
            </div>
        </div>
    </div>
</div>
<p-dialog [(visible)]="displayDialog" position="top" [modal]="true" [style]="{width: '33vw'}"

    [draggable]="false" [resizable]="false" (onHide)="deactivate(false)">
    <div class="p-3">
    <h4 class="fw-bold mb-5">האם ברצונך לשמור את השינויים שביצעת?</h4>
    <div class="d-flex">
            <button pButton pRipple type="button" label="שמור"  (click)="updateOrCreateSystemConstitution(); deactivate(true)"   class="ui-button-rounded button-matpash light-blue-fill ml-3"></button>
            <button pButton pRipple type="button" label="אל תשמור"  (click)="deactivate(true)"   class="ui-button-rounded button-matpash light-blue-fill ml-3"></button>
            <button pButton pRipple type="button" label="ביטול" (click)="deactivate(false)"   class="ui-button-rounded button-matpash light-blue-fill "></button>
    </div>
</div>
</p-dialog>