import { Injectable } from '@angular/core';
import {HttpResponse} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class FileService {

  constructor() { }

  ArrayBufferToBlob(bufferedFile: HttpResponse<ArrayBuffer>) :Blob{
    const blobFile = new Blob([bufferedFile.body], {
      type: "application/pdf"
    });

    // const fileName = castedBufferedFile.headers.get("Content-Type");
    return blobFile;
  }
}
