import { Component, OnInit, Input, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, FormArray } from '@angular/forms';
import { FormService } from '../../service/form.service';
import { StepComponentBase } from '../wizard-step-component-base/step-component-base';
import { StepPagesService } from '../../service/step-pages.service';
import { MatDialog } from '@angular/material';
import { MessagesModalComponent } from '../../shared/messages-modal/messages-modal.component';
import { takeUntil } from "rxjs/operators";
import regionsTypes from 'src/app/shared/util/regionTypes';

@Component({
  selector: 'app-application-summary-step',
  templateUrl: './application-summary-step.component.html',
  styleUrls: ['./application-summary-step.component.scss']
})
export class ApplicationSummaryStepComponent extends StepComponentBase
  implements OnInit {
  FormGroupRef: FormGroup = null;
  workersNum: FormArray;
  licenseRef: FormControl;
  days: string;
  isIsraelRegion: boolean = false;

  constructor(
    stepsPagesService: StepPagesService,
    dialog: MatDialog,
    private formService: FormService
  ) {
    super(stepsPagesService, dialog);
  }

  ngOnInit() {
    super.ngOnInit();
    this.FormGroupRef = this.formService.getForm();
    this.isIsraelRegion = this.formService.getFormGroupRefByName('transactionArea').get('region').value === regionsTypes.ISRAEL;
    this.formService.getFormGroupRefByName('transactionArea').get('region').valueChanges.subscribe(controlValue => {
      this.isIsraelRegion = controlValue === regionsTypes.ISRAEL;
    })
  }
  // .pipe(takeUntil(this.componentDestroyed))
  submit() {
    this.formService.submitForm().subscribe(
      applicationId => {
        console.log(applicationId),
          this.openSubmitModal(true, `נא לשים לב, תהליך זה צפוי לקחת עד 7 ימי עבודה,
      אנו ממליצים לשמור את מספר הפנייה לצורך מעקב`
            , applicationId)
      },
      fail => {
        if (fail.status === 400) {
          this.openSubmitModal(false, fail.error);
          return;
        }
        this.openSubmitModal(false, 'עקב תקלה זמנית אין באפשרותנו לבצע את הפעולה שביקשת. אנו ממליצים לנסות שוב בעוד מספר רגעים');
      });
  }

  openSubmitModal(success: boolean, msg: string, request_id?: string) {
    this.openModal(
      MessagesModalComponent,
      {
        submitSuccess: success,
        message: msg,
        requestId: request_id
      },
      { disableClose: true }
    );
  }
}
