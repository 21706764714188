import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { UserDataService } from './user-data.service';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class IsraelRedirectGuardService implements CanActivate {
    constructor(public userDataService: UserDataService, public router: Router) { }

    canActivate(): Observable<boolean> {
        return this.userDataService.getUser().pipe(
            map(user => !user.hasBranchOnlyInIsrael),
            tap(hasBranchOnlyInIsrael => {
                if (!hasBranchOnlyInIsrael) {
                    this.router.navigate(['requestsandlicenses/licences']);
                }else{
                    this.router.navigate(['requestsandlicenses/requests']);
                }
            })
        )
    }
}
