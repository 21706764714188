import { PipeTransform } from '@angular/core';
var StringShortenPipe = /** @class */ (function () {
    function StringShortenPipe() {
    }
    StringShortenPipe.prototype.transform = function (value) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        var substringLength = args[0];
        var mobileSubString = args[1];
        var dots = '...';
        if (mobileSubString != null) {
            if (window.innerWidth < 768) {
                substringLength = mobileSubString;
            }
        }
        if (value.length > substringLength) {
            return value.substring(0, substringLength - dots.length) + dots;
        }
        else {
            return value;
        }
    };
    return StringShortenPipe;
}());
export { StringShortenPipe };
