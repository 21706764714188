import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { CurrentEmployee, GeneralModalData, InvalidRenewLicense } from 'src/app/models/interfaces';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { ApplicationHttpClient } from 'src/app/shared/service/ApplicationHttpClient';
import { DialogService } from 'src/app/shared/service/dialog.service';
import { GeneralModalComponent } from 'src/app/shared/components/general-modal/general-modal.component';
import { EmployeeService } from "../../../../services/employee-service";
@Component({
  selector: "app-licenses-renew-modal",
  templateUrl: "./licenses-renew-modal.component.html",
  styleUrls: ['./licenses-renew-modal.component.scss']
})
export class LicensesRenewModalComponent implements OnInit {
  approveCheckbox: boolean = false;
  renewLicense: FormGroup;
  licenseLimitation;
  displayModal:boolean=false
  showModal:boolean=false
  title:string
  text:string
  
  constructor(
    private dialogRef: MatDialogRef<LicensesRenewModalComponent>,
     private http: ApplicationHttpClient, private dialogService: DialogService,
    private employeeService: EmployeeService,
    @Inject(MAT_DIALOG_DATA) public data: { validEmployees: CurrentEmployee[], invalidEmployees: InvalidRenewLicense[] }
  ) { }


  ngOnInit() {
    this.buildForm();
    this.showModal=true

    if (this.data.validEmployees.length > 0) {
      this.http.Post<any>(`api/license/limitaion`, { code: this.data.validEmployees[0].field.code, licenseType: this.data.validEmployees[0].isLicenseInIsrael ? 1 : 2 }).toPromise().then(res => {
        if (res) {
          this.licenseLimitation = res.licenseLimitation;
          this.renewLicense.controls.license_duration.setValidators([Validators.required, Validators.min(1), Validators.pattern("^[0-9]*$"), Validators.max(this.licenseLimitation)])
        }
      }, error => {
        let title = "תקלה...";
        let text = "שגיאה";
        const dialogData: GeneralModalData = {
          title: title,
          text: text
        };
        this.title=dialogData.title
        this.text=dialogData.text
        this.displayModal=true
        // this.dialogService.openModal(GeneralModalComponent, dialogData);
      });
    }
  }

  buildForm() {
    this.renewLicense = new FormGroup({
      license_duration: new FormControl(0,
        [Validators.required, Validators.min(1), Validators.pattern("^[0-9]*$"), Validators.max(this.licenseLimitation)])
    });
  }

  closeModal() {
    this.dialogRef.close();
  }
}
