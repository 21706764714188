import { Injectable } from '@angular/core';
import { livingCity } from '../models/Employee';
import { Area, Branch, EmployerType, Region, SubArea, SubBranch } from '../models/metadataModels';
import { ApplicationHttpClient } from '../shared/service/ApplicationHttpClient';

interface Res<T> {
  data: T[];
}

@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  constructor(private http: ApplicationHttpClient) { }

  getAreaes() {
    return this.http.Get<Res<Area>>("api/area");
  }

  getSubAreaes() {
    return this.http.Get<Res<SubArea>>("api/sub_areas");
  }

  getBranches() {
    return this.http.Get<Res<Branch>>("api/branches");
  }

  getSubBranches() {
    return this.http.Get<Res<SubBranch>>("api/sub_branches");
  }

  getRegions() {
    return this.http.Get<Res<Region>>("api/region");
  }

  getLivingCity() {
    return this.http.Get<Res<livingCity>>("api/living_city");
  }

  getEmployerTypes() {
    return this.http.Get<Res<EmployerType>>("api/employer_type");
  }
}