import * as tslib_1 from "tslib";
import { HttpClient, HttpHeaders, HttpParams, HttpHandler } from '@angular/common/http';
import { tap, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
export function applicationHttpClientCreator(httpHandler, config, loaderService) {
    return new ApplicationHttpClient(httpHandler, config, loaderService);
}
var ApplicationHttpClient = /** @class */ (function (_super) {
    tslib_1.__extends(ApplicationHttpClient, _super);
    function ApplicationHttpClient(httpHandler, config, loaderService) {
        var _this = _super.call(this, httpHandler) || this;
        _this.loaderService = loaderService;
        _this.api = '';
        _this.api = config.settings.baseUrl;
        _this.requestCount = 0;
        return _this;
    }
    ApplicationHttpClient.prototype.getAPI = function () {
        return this.api;
    };
    /**
     * GET request
     * @param {string} endPoint it doesn't need / in front of the end point
     * @param {IRequestOptions} options options of the request like headers, body, etc.
     * @param {string} api use if there is needed to send request to different back-end than the default one.
     * @returns {Observable<T>}
     */
    ApplicationHttpClient.prototype.Get = function (endPoint, options) {
        var request = this.get(this.api + endPoint, options);
        return this.handleRequest(request, options);
    };
    /**
     * POST request
     * @param {string} endPoint end point of the api
     * @param {Object} params body of the request.
     * @param {IRequestOptions} options options of the request like headers, body, etc.
     * @returns {Observable<T>}
     */
    ApplicationHttpClient.prototype.Post = function (endPoint, params, options) {
        var request = this.post(this.api + endPoint, params, options);
        return this.handleRequest(request, options);
    };
    /**
     * PUT request
     * @param {string} endPoint end point of the api
     * @param {Object} params body of the request.
     * @param {IRequestOptions} options options of the request like headers, body, etc.
     * @returns {Observable<T>}
     */
    ApplicationHttpClient.prototype.Put = function (endPoint, params, options) {
        var request = this.put(this.api + endPoint, params, options);
        return this.handleRequest(request, options);
    };
    /**
     * DELETE request
     * @param {string} endPoint end point of the api
     * @param {IRequestOptions} options options of the request like headers, body, etc.
     * @returns {Observable<T>}
     */
    ApplicationHttpClient.prototype.Delete = function (endPoint, options) {
        var request = this.delete(this.api + endPoint, options);
        return this.handleRequest(request, options);
    };
    ApplicationHttpClient.prototype.handleRequest = function (obs, options) {
        var _this = this;
        if (!options || options && !options.preventSpinner) {
            this.handleSpinnerAdd();
        }
        return obs.pipe(tap(function () {
            if (!options || options && !options.preventSpinner) {
                _this.hanldeSpinnerRemove();
            }
        }), catchError(function (err) {
            if (!options || options && !options.preventSpinner) {
                _this.hanldeSpinnerRemove();
            }
            return throwError(err);
        }));
    };
    ApplicationHttpClient.prototype.handleSpinnerAdd = function () {
        this.requestCount++;
        this.loaderService.show();
    };
    ApplicationHttpClient.prototype.hanldeSpinnerRemove = function () {
        this.requestCount--;
        if (this.requestCount === 0) {
            this.loaderService.hide();
        }
    };
    return ApplicationHttpClient;
}(HttpClient));
export { ApplicationHttpClient };
