import { Observable } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "../shared/service/ApplicationHttpClient";
var AlertMessagesService = /** @class */ (function () {
    function AlertMessagesService(http) {
        this.http = http;
    }
    AlertMessagesService.prototype.getAllMessages = function () {
        return this.http.Get("api/alert_messages/");
    };
    AlertMessagesService.prototype.updateAlertMessage = function (alertMessage) {
        return this.http.Put("api/alert_messages/" + alertMessage._id, alertMessage);
    };
    AlertMessagesService.prototype.createAlertMessages = function (alertMessage) {
        return this.http.Post("api/alert_messages/", alertMessage);
    };
    AlertMessagesService.prototype.deleteAlertMessage = function (id) {
        return this.http.Delete("api/alert_messages/" + id);
    };
    AlertMessagesService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AlertMessagesService_Factory() { return new AlertMessagesService(i0.ɵɵinject(i1.ApplicationHttpClient)); }, token: AlertMessagesService, providedIn: "root" });
    return AlertMessagesService;
}());
export { AlertMessagesService };
