<div class="stepHeadline textContent  colorTextBlack textContent--maxWidth">
  <p class="textContent--semiBold stepTopContent">נא להזין את מספרי תעודת הזהות של העובדים המבוקשים לנוחיותך,
    באפשרותך
    להזין פרטים של עובדים מרובים</p>
  <p *ngIf="fieldService.hasEmploymentLimitation()" class="textContent--bold stepBottomContent">
    <span class="attention colorTextRed">נא לשים לב </span>
    <span *ngIf="_selectedField?.currentEmployed">
          הנך מעסיק כבר {{_selectedField?.currentEmployed}}
      עובדים בענף {{_selectedField?.name}}.
    </span>
    בתחום זה, ניתן להעסיק עד {{(fieldService.employmentLimitation | async)}}
    עובדים, לכן הנך יכול לבקש רק עוד {{fieldService.getAvailableEmployeeSum()}}
   עובדים ניתן לבטל רשיונות של עובדים קיימים בעמוד
    <a *ngIf="redirectUrl" target="_blank" class="link colorTextBlue link--underLine link--semiBold inlineLink" [routerLink]="redirectUrl">צפיה בבקשות /
      רשיונות</a>
  </p>
</div>


<div class="idContainer ">
  <perfect-scrollbar class="perfectScrollBar idListSCrollbar"  [config]="config">
  <textarea  oninput='this.style.height = "";this.style.height = this.scrollHeight + "px"' [(ngModel)]="idListStr" (ngModelChange)="onInputChange($event)" (keydown)="onKeyDown($event)"
    (keyup)="onKeyUp()" (paste)="onPaste($event)" name="" id=""
    class="workersId textContent textContent--semiBold " placeholder="
נא להזין מספר תעודת זהות של העובד המבוקש
ניתן להזין פרטים של עובדים מרובים בצורה הבאה:
    
    
201053379
201559617
201897418
201897418">
</textarea>
  </perfect-scrollbar>
  <div *ngIf="errorMessage" class="errorWrapper textContent textContent--bold">
    <span class="errornote colorTextRed">{{errorMessage}}</span>
    <div>
      <span *ngFor="let invalid of invalidIdNumbers" class="colorTextRed invalidIdNumber">
        {{invalid}}
      </span>
    </div>
  </div>
  <div *ngIf="generalError" class="errorWrapper textContent textContent--bold">
    <span class="errornote colorTextRed">{{generalError}}</span>
  </div>
</div>

<div *ngIf="invalidInputError" class="errorWrapper textContent textContent--bold">
  <span class="errornote colorTextRed">{{invalidInputError}}</span>
</div>
<div class="stepsBtns  stepsBtns--inWrapper">
  <button class="btn btn--regularBtn btn--blue btn--semiBold next" type="button" [disabled]="isSubmitButtonDisabled()"
    (click)="submitIdNumbers()">טען את רשימת העובדים</button>
</div>
