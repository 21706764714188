import { BehaviorSubject } from "rxjs";
import { EmployeeApplicationResponse, Docs, RequestData, Settlement, EmployeeApplicationRequest } from "src/app/models/interfaces";
import { filter } from "rxjs/operators";
import { requestDetailMockData } from "../mockData/requestDetailMockData";
import * as i0 from "@angular/core";
import * as i1 from "../../shared/service/ApplicationHttpClient";
var WizradUserDataService = /** @class */ (function () {
    function WizradUserDataService(http) {
        this.http = http;
        // licenseAplicationsSubject = new BehaviorSubject<any>(null);
        this.initSubject = new BehaviorSubject(undefined);
        this._requestData = new BehaviorSubject(null);
        this.requestData = this._requestData.asObservable();
        this.initialHasPermit = false;
        this.useMock = false;
    }
    WizradUserDataService.prototype._getRequestDetailsStep = function () {
        var _this = this;
        this.useMock ? this.setData(requestDetailMockData) : this.http
            .Get("api/request/getRequestData")
            .subscribe(function (res) {
            _this.setData(res);
        });
    };
    WizradUserDataService.prototype.setData = function (res) {
        this._requestData.next(res);
        this.initialHasPermit = res.hasPermit;
        this.downloadDocuments = res.downloadDocuments;
        this.initSubject.next(true);
    };
    WizradUserDataService.prototype.getEmployees = function (requestData) {
        this.employeeApplicationRequest = requestData;
        return this.http.Post("api/request/runAdministrativeCheck", requestData);
    };
    WizradUserDataService.prototype.getSettlements = function (value) {
        return this.http.Get("api/settlements/get/" + value, { preventSpinner: true });
    };
    WizradUserDataService.prototype.findDisableIsrael = function () {
        return this.http.Get("api/internalOption/");
    };
    WizradUserDataService.prototype.init = function () {
        this._getRequestDetailsStep();
    };
    WizradUserDataService.prototype.isInit = function () {
        return this.initSubject.asObservable().pipe(filter(function (val) { return !!val; }));
    };
    WizradUserDataService.prototype.getDownloadDocumentByName = function (documentName) {
        if (documentName === void 0) { documentName = ""; }
        return documentName ? this.downloadDocuments[documentName] : this.downloadDocuments;
    };
    WizradUserDataService.prototype.dispose = function () {
        this._requestData.next(null);
        this.initSubject.next(false);
    };
    WizradUserDataService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function WizradUserDataService_Factory() { return new WizradUserDataService(i0.ɵɵinject(i1.ApplicationHttpClient)); }, token: WizradUserDataService, providedIn: "root" });
    return WizradUserDataService;
}());
export { WizradUserDataService };
