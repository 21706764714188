import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { ApplicationHttpClient } from '../shared/service/ApplicationHttpClient';
// import { User } from '../interfaces/user';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  constructor(private http:ApplicationHttpClient) {

  }

  getOtp(loginData): Observable<any> {
    return this.http.Post<any>('api/auth/getotp', loginData);
  }

  login(loginData): Observable<any> {
    return this.http.Post<any>('api/auth/login', loginData);
  }

  logout(): Observable<any> {
    return this.http.Post<any>('api/auth/logout', {});
  }

  getTerms(): Observable<any> {
    return this.http.Get<any>('api/auth/terms');
  }

  agreeTerms(identity): Observable<any> {
    return this.http.Post<any>('api/auth/aprroveRegulations',identity);
  }

  setUserData(userData: any) {
    // this.userDataSubject.next(userData);
    sessionStorage.setItem('userData', JSON.stringify(userData));
  }

  getUserData(): Observable<any> {
    const userData = sessionStorage.getItem('userData');
    return of(JSON.parse(userData));
    // return this.userDataSubject.asObservable();
  }
}