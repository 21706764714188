import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { WantAdModel } from '../models/WantAd';
import { ApplicationHttpClient } from '../shared/service/ApplicationHttpClient';

@Injectable({
  providedIn: 'root'
})


export class WantAdsService {
  constructor(private http:ApplicationHttpClient) { }
  
  getAllWantAds(userId : string):Observable<any>{
    return this.http.Get<any>(`api/want_ads/getByUser/${userId}`)
  }

  updateWantAd(wantAdModel : WantAdModel){
    return this.http.Put<any>(`api/want_ads/${wantAdModel._id}`,wantAdModel);
  }

  createWantAd(wantAdModel : WantAdModel){
    return this.http.Post<any>(`api/want_ads/`,wantAdModel);
  }

  deleteWantAd(id: string){
    return this.http.Delete<any>(`api/alert_messages/${id}`);
  }

 
}
