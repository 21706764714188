// import { StoreService } from 'src/app/store/service/store.service';
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { ValidateId } from 'src/app/shared/validators/id.validator';
import { LoginFormModalComponent } from 'src/app/pages/login/components/login-form-modal/login-form-modal.component';
import { LoginService } from 'src/app/services/login.service';
import { AuthStepOneService } from 'src/app/services/auth-step-one.service';
import { InstructionEmployeement } from 'src/app/models/instructionEmployeement';

export class area{
  name: string;
  ID : number;
  }
  export class options{
    title: string;
    value : number;
    icon : string
    }

  

    export class SendData {
       identity: number;
       authType: number;
       sendData()
       {
       }
      }

@Component({
  selector: 'app-auth-step-one',
  templateUrl: './auth-step-one.component.html',
  styleUrls: ['./auth-step-one.component.scss']
})

export class AuthStepOneComponent implements OnInit {

  loginForm: FormGroup;
  dataNotIsrael: any
  listInstructionEmployeement: Array<InstructionEmployeement> = new Array<InstructionEmployeement>()
  identity: number;
  @Output() goToLoginStep = new EventEmitter();
  errorFromServer = '';
  displayModal:boolean=false
  title:string
  content:string
  showContact:boolean
  img:string
  authType = 0
  instruction:InstructionEmployeement
  
  constructor(
    private dialog: MatDialog,
    private fb: FormBuilder,
    private loginService: LoginService,
    public authStepOneService: AuthStepOneService
  ) { 
    
    this.listInstructionEmployeement[0] = new InstructionEmployeement();
    this.listInstructionEmployeement[1] = new InstructionEmployeement();
  
  }

  ngOnInit() {
    this.createForm();
    this.loginForm = this.fb.group({
      text: [''],
      identity: [''],
      authType:['']
      //value3:['']
      });

      this.openInstruction(this.textToShow.ID)
  }

  createForm(): void {
    this.loginForm = this.fb.group({
      identity: [null, [Validators.required, ValidateId]],
      authType: [0, [Validators.required]],
      NgModule : []
    },{updateOn:'submit'});
  }
  display: boolean = false;
  areas : area[]=[ {"name":"התיישבות","ID" : 1},{"name":"ישראל","ID" : 2}];
  dialogTextIsrael : string = "הסבר מפורט כיצד לפתוח תיק תעסוקה בישראל"
  dialogTextSettlement : string = "הסבר מפורט כיצד לפתוח תיק תעסוקה בהתיישבות"
  textToShow : area = {"name":"ישראל","ID" : 2} ;

  openLoginFormModal() {  
   this.display = true;

  }

  openInstruction(areaId:number){
    this.authStepOneService.readOpeningFile(areaId).subscribe(data => {
      this.instruction=data.data[0]
    })
  }

  change(){
    this.openInstruction(this.textToShow.ID)
  }

  options: options[] = [{"title":"נייד","value" : 0,"icon" : 'icon-secret-code-1 ml-2'},{"title":"מייל","value" : 1,"icon" : 'icon-letter ml-2'}]
 // justifyOptions : any[] =[
 //   {icon: 'ml-3 icon-Test-performed', justify: 'נייד'},
 //   {icon: 'ml-3 icon-Test-performed', justify: 'מייל'},
//];
  setAuthMethod(evt: any) {
    this.loginForm.get('authType').setValue(parseInt(evt.value, 10));
  }

  onSubmit() {
    const sendData = new SendData();
    sendData.authType =this.authType;
    sendData.identity = this.identity;
    this.loginService.getOtp(sendData).subscribe(
      userData => {
        if (userData.authType === 0 && !userData.user.phone) {
          userData.authType = 1;
          userData.usedOtherAuthType = true;
          userData.usedOtherAuthTypeMessage = 'מספר טלפון לא נמצא במערכת , קוד נשלח למייל במקום';
          this.loginService.setUserData(userData);
          // this.storeService.setUserData(userData);
        } else if (userData.authType === 1 && !userData.user.email) {
          userData.authType = 0;
          userData.usedOtherAuthType = true;
          userData.usedOtherAuthTypeMessage = 'כתובת מייל לא נמצאה במערכת , קוד נשלח למספר טלפון במקום';
          this.loginService.setUserData(userData);
          // this.storeService.setUserData(userData);
        } else {
          this.loginService.setUserData(userData);
          // this.storeService.setUserData(userData);
        }

        this.goToLoginStep.emit({ step: 1 });
      },
      (error: any) => {
        if (error.error === 'מעסיק מנוע') {
          this.displayModal=true;
          this.title="סליחה, לא ניתן להתחבר לאתר";
          this.content=`מעסיק יקר, שימושך באתר נחסם.<br> אנא פנה לקמ"ט תעסוקה באחת מדרכי ההתקשרות הבאות`;
          this.showContact=true;
          this.img=""
        } else {
          this.loginForm.get('identity').setErrors({ 'incorrect': true })
          this.errorFromServer = error.error;
        }
      }
    );

  
    
  }
  getStatusModal(status: boolean) {
    this.displayModal = status;
  }
}
 