import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from "@angular/core";
import { AddingDocumentsStepComponent } from '../steps/adding-documents-step/adding-documents-step.component';
import { filter, takeUntil } from 'rxjs/operators';
import { BaseComponent } from "../../shared/components/base-component/base-component";
import { TransactionAreaStepComponent } from '../steps/transaction-area-step/transaction-area-step.component';
import { RequestDetailsStepComponent } from '../steps/request-details-step/request-details-step.component';
import { WantedWorkersStepComponent } from '../steps/wanted-workers-step/wanted-workers-step.component';
import { ApplicationSummaryStepComponent } from '../steps/application-summary-step/application-summary-step.component';
var WizardStepperComponent = /** @class */ (function (_super) {
    tslib_1.__extends(WizardStepperComponent, _super);
    function WizardStepperComponent(stepsPagesService, formService, wizradUserDataService, employeeService, fieldService, buildingPermitService) {
        var _this = _super.call(this) || this;
        _this.stepsPagesService = stepsPagesService;
        _this.formService = formService;
        _this.wizradUserDataService = wizradUserDataService;
        _this.employeeService = employeeService;
        _this.fieldService = fieldService;
        _this.buildingPermitService = buildingPermitService;
        _this.isFirstRequest = true;
        _this.initWizrad = false;
        _this.disposeArr = [];
        _this.initalSteps = [
            { component: TransactionAreaStepComponent, label: 'איזור העסקה', icon: "icon-Employment-area" },
            { component: RequestDetailsStepComponent, label: 'פרטי בקשה', icon: "icon-request-details" },
            { component: WantedWorkersStepComponent, label: 'עובדים מבוקשים', icon: "icon-Wanted-employees" },
            { component: ApplicationSummaryStepComponent, label: 'סיכום בקשה', icon: "icon-Summary-of-request" },
        ];
        _this.stepsPagesService.buildSteps(_this.initalSteps);
        _this.wizradUserDataService.init();
        _this.stepsPagesService.getSteps().subscribe(function (stepPages) {
            _this.steps = stepPages;
        });
        _this.stepsPagesService.getTitle().pipe(takeUntil(_this.componentDestroyed)).subscribe(function (title) { return (_this.title = title); });
        _this.createDisposables();
        return _this;
    }
    WizardStepperComponent.prototype.createDisposables = function () {
        this.disposeArr.push(this.formService.deleteForm.bind(this.formService), this.wizradUserDataService.dispose.bind(this.wizradUserDataService), this.buildingPermitService.dispose.bind(this.buildingPermitService), this.fieldService.dispose.bind(this.fieldService), this.employeeService.resetWantedWorkersEmployees.bind(this.employeeService), this.stepsPagesService.resetSteps.bind(this.stepsPagesService));
    };
    WizardStepperComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.formService.createForm();
        this.buildingPermitService.init();
        this.fieldService.init();
        this.checkForDocumetsStepByField();
        this.wizradUserDataService.isInit().pipe(takeUntil(this.componentDestroyed)).subscribe(function (init) {
            _this.initWizrad = init;
        });
    };
    WizardStepperComponent.prototype.checkForDocumetsStepByField = function () {
        var _this = this;
        this.formService.getFormGroupRefByName("requestDetails").get('fields')
            .valueChanges.pipe(takeUntil(this.componentDestroyed)).pipe(filter(function (field) { return !!field; })).subscribe(function (fields) {
            var fieldsRequestData = fields;
            var alreadyExist = _this.stepsPagesService.stepExisitsByName({ component: AddingDocumentsStepComponent });
            if (!alreadyExist && fieldsRequestData.documents.length) {
                _this.stepsPagesService.setStepAtIndex({ component: AddingDocumentsStepComponent, label: 'הוספת מסמכים', icon: "addDocumentsStep" }, 3);
            }
            else if (alreadyExist && !fieldsRequestData.documents.length) {
                _this.stepsPagesService.removeStepByComponentName({ component: AddingDocumentsStepComponent });
            }
        });
    };
    WizardStepperComponent.prototype.ngOnDestroy = function () {
        this.initDestroy();
        this.disposeArr.forEach(function (dispose) {
            if (dispose && typeof dispose === "function") {
                dispose();
            }
        });
    };
    // public ngAfterViewInit(): void {
    //   this.totalStepsCount = this.stepper._steps.length;
    // }
    WizardStepperComponent.prototype.goBack = function (stepper) {
        this.stepper.previous();
        // stepper.previous();
    };
    WizardStepperComponent.prototype.goForward = function (stepper) {
        this.stepper.next();
        // stepper.selectedIndex = 0;
    };
    WizardStepperComponent.prototype.resetForm = function () {
        this.formService.resetForm();
        this.stepsPagesService.resetSteps();
        this.stepper.reset();
        this.stepsPagesService.buildSteps(this.initalSteps);
    };
    WizardStepperComponent.prototype.saveDraft = function () {
        // console.log(this.stepper.selectedIndex);
        // console.log(this.formService.getForm());
    };
    WizardStepperComponent.prototype.stepControl = function (index) {
        return this.formService.getFormGroupRefByIndex(index);
    };
    WizardStepperComponent.prototype.startRequest = function () {
        this.isFirstRequest = false;
    };
    return WizardStepperComponent;
}(BaseComponent));
export { WizardStepperComponent };
