import * as i0 from "@angular/core";
import * as i1 from "../shared/service/ApplicationHttpClient";
var ParameterService = /** @class */ (function () {
    function ParameterService(http) {
        this.http = http;
        this.endPoint = "api/parameter";
    }
    ParameterService.prototype.getParameters = function () {
        return this.http.Get(this.endPoint);
    };
    ParameterService.prototype.updateParameter = function (_id, params) {
        return this.http.Put(this.endPoint + "/" + _id, params);
    };
    ParameterService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ParameterService_Factory() { return new ParameterService(i0.ɵɵinject(i1.ApplicationHttpClient)); }, token: ParameterService, providedIn: "root" });
    return ParameterService;
}());
export { ParameterService };
