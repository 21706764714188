import { map, tap } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "./user-data.service";
import * as i2 from "@angular/router";
var IsraelGuardService = /** @class */ (function () {
    function IsraelGuardService(userDataService, router) {
        this.userDataService = userDataService;
        this.router = router;
    }
    IsraelGuardService.prototype.canActivate = function () {
        var _this = this;
        return this.userDataService.getUser().pipe(map(function (user) { return !user.hasBranchOnlyInIsrael; }), tap(function (hasBranchOnlyInIsrael) {
            if (!hasBranchOnlyInIsrael) {
                _this.router.navigate(['home']);
            }
        }));
    };
    IsraelGuardService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function IsraelGuardService_Factory() { return new IsraelGuardService(i0.ɵɵinject(i1.UserDataService), i0.ɵɵinject(i2.Router)); }, token: IsraelGuardService, providedIn: "root" });
    return IsraelGuardService;
}());
export { IsraelGuardService };
