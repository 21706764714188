<!-- <h2 class="subTitle subTitle--blueTextColor" *ngIf="notificationsAndUpdates.length > 0">הודעות ועדכונים</h2> -->
<div class="updatesCarousel">
  <!-- <button
    (click)="slickModal.slickPrev()"
    class="carouselBtn slickPrev"
  ></button> -->

  <!--
  <p-carousel [value]="notificationsAndUpdates"   [numVisible]="3" [numScroll]="3" [page]="5"  >
    <ng-template let-notification pTemplate="item">
      <div class="car-details">
        <div class="messageBoxContainer">
          <div class="messageBox">
            <div class="alertIcon">
              <img
                src="assets/imgs/pay-attention.png"
                class="alertImg"
                *ngIf="notification.isImportant"
              />
            </div>
            <div class="messageBox__header textContent textContent--semiBold">
              <span>{{ notification.title }}</span>
              <span>{{ notification.message }}</span>
            </div>
            <button
              role="button"
              (click)="openReadMoreModal(notification)"
              class="link link--blueTextColor link--semiBold link--arrowLeft"
            >
              להמשך קריאה
            </button>
          </div>
        </div>
      </div>
    </ng-template>
</p-carousel>


<p-dialog [(visible)]="VisibleDialog" [modal]="true" [responsive]="true" [style]="{width: '350px', minWidth: '200px', maxHeight:'300px'}" [minY]="70" 
  [maximizable]="true" [baseZIndex]="10000" 
   [(visible)]="display" [closable]="false" >
   <p-header>כיצד לפתוח תיק תעסוקה</p-header>
   <td>
    <tr>יעע</tr> 
   </td>
  <p-footer>
  <button type="button"  (click)="display=false" label="Yes">אישור</button>
  </p-footer>
  </p-dialog>
-->

  <ngx-slick-carousel class="carousel-message" #slickModal="slick-carousel" [config]="slideConfig">
    <div ngxSlickItem class="card-message" *ngFor="let item of listMessages">

      <div class="alertIcon">
        <i class="icon-information1" *ngIf="item.hotJob==true"></i>
      </div>
      <div class="d-flex flex-column h-100">
        <div class="">
          <h4 [ngClass]="{'color-red' : item.isImportant}">{{ item.title }}</h4>
          <p class="messageBox__content textContent textContent--regular" [innerHTML]="item.content | stringShorten : messageBoxContentLength : mobilemessageBoxContentLength">
          
          </p>
        </div>
        <div class="d-flex justify-content-between mt-auto">
          <span class="date-span">{{ item.toDate | date: 'dd/MM/yyyy' }}<i class="icon-small-clock ml-2"></i></span>
          <button role="button" (click)="openReadMoreModal(item)" class="btn-read-more">
            קרא עוד
          </button>
        </div>
      </div>
    </div>
  </ngx-slick-carousel>


  <p-dialog [(visible)]="VisibleDialog" [modal]="true" [responsive]="true"
    [style]="{width: '350px', minWidth: '200px', maxHeight:'300px'}" [minY]="70" [maximizable]="true"
    [baseZIndex]="10000" [(visible)]="display" [closable]="false">
    <td>
      <tr>{{data}}</tr>
    </td>
    <p-footer>
      <p-button type="button" (click)="VisibleDialog=false" label="אישור"></p-button>
    </p-footer>
  </p-dialog>
</div>