/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./shared/components/loader/loader.component.ngfactory";
import * as i3 from "./shared/components/loader/loader.component";
import * as i4 from "./shared/components/loader/loader.service";
import * as i5 from "./core/layout/layout.component.ngfactory";
import * as i6 from "./core/layout/layout.component";
import * as i7 from "./app.component";
import * as i8 from "@ngx-translate/core";
import * as i9 from "@angular/router";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "main", [["class", "main"], ["role", "main"]], [[2, "loginPage", null]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-loader", [["class", "appLoader"]], null, null, null, i2.View_LoaderComponent_0, i2.RenderType_LoaderComponent)), i1.ɵdid(2, 114688, null, 0, i3.LoaderComponent, [i4.LoaderService], null, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "app-layout", [["class", "layout"]], null, null, null, i5.View_LayoutComponent_0, i5.RenderType_LayoutComponent)), i1.ɵdid(4, 114688, null, 0, i6.LayoutComponent, [], null, null)], function (_ck, _v) { _ck(_v, 2, 0); _ck(_v, 4, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isLoginPage; _ck(_v, 0, 0, currVal_0); }); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 114688, null, 0, i7.AppComponent, [i8.TranslateService, i9.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i7.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
