/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "primeng/components/button/button";
import * as i2 from "./stepper-buttons-component";
var styles_StepperButtonsComponent = [];
var RenderType_StepperButtonsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_StepperButtonsComponent, data: {} });
export { RenderType_StepperButtonsComponent as RenderType_StepperButtonsComponent };
export function View_StepperButtonsComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "div", [["class", "row d-flex justify-content-between mt-5"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "div", [["class", "col-md-4 col-6"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "button", [["class", "ui-button-rounded button-matpash no-fill-btn d-flex align-items-center  justify-content-center fw-regular"], ["label", " \u05D7\u05D6\u05E8\u05D4"], ["pButton", ""], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goBack() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(3, 4341760, null, 0, i1.ButtonDirective, [i0.ElementRef], { label: [0, "label"] }, null), (_l()(), i0.ɵeld(4, 0, null, null, 0, "i", [["class", "icon-left-arrow rotate-180 mr-3"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 3, "div", [["class", "col-md-4 col-6 pr-md-5"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 2, "button", [["class", "ui-button-rounded w-100 button-matpash light-blue-fill d-flex align-items-center  flex-row-reverse justify-content-center"], ["pButton", ""], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goForward() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(7, 4341760, null, 0, i1.ButtonDirective, [i0.ElementRef], { label: [0, "label"] }, null), (_l()(), i0.ɵeld(8, 0, null, null, 0, "i", [["class", "icon-left-arrow mr-3"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_1 = " \u05D7\u05D6\u05E8\u05D4"; _ck(_v, 3, 0, currVal_1); var currVal_3 = i0.ɵinlineInterpolate(1, " ", _co.nextButtonText, ""); _ck(_v, 7, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.hasBackStep; _ck(_v, 2, 0, currVal_0); var currVal_2 = !_co.hasNextStep; _ck(_v, 6, 0, currVal_2); }); }
export function View_StepperButtonsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "stepper-buttons-component", [], null, null, null, View_StepperButtonsComponent_0, RenderType_StepperButtonsComponent)), i0.ɵdid(1, 114688, null, 0, i2.StepperButtonsComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var StepperButtonsComponentNgFactory = i0.ɵccf("stepper-buttons-component", i2.StepperButtonsComponent, View_StepperButtonsComponent_Host_0, { hasNextStep: "hasNextStep", hasBackStep: "hasBackStep", nextButtonText: "nextButtonText" }, { onStepGoNext: "onStepGoNext", onStepGoBack: "onStepGoBack" }, []);
export { StepperButtonsComponentNgFactory as StepperButtonsComponentNgFactory };
