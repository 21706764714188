import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { StepPagesService } from '../../service/step-pages.service';

@Component({
  selector: 'stepper-buttons-component',
  templateUrl: './stepper-buttons-component.html',
})
export class StepperButtonsComponent implements OnInit {
  @Output() onStepGoNext: EventEmitter<any> = new EventEmitter();
  @Output() onStepGoBack: EventEmitter<any> = new EventEmitter();

  @Input() hasNextStep: boolean;
  @Input() hasBackStep: boolean;
  @Input() nextButtonText?:string = "המשך"

  constructor() {

  }

  ngOnInit(): void {
  }

  goForward() {
    this.onStepGoNext.emit();
  }

  goBack() {
    this.onStepGoBack.emit();
  }

}
