import { Component, OnInit } from '@angular/core';
import { MetadataForSystemConstitution } from 'src/app/models/metadataForSystemConstitutionModel';
import { SystemConstitutionService } from 'src/app/services/systemConstitution.service';
import { Subject } from 'rxjs';
import {
  SystemConstitutionModel,
  SystemConstitutionArea,
  SystemConstitutionSubArea,
  SystemConstitutionBranch,
  SystemConstitutionSubBranch,
  SystemConstitutionEmployerType
} from 'src/app/models/systemConstitutionModel';

interface Filter<T> {
  value: T;
  options: T[];
}
interface Filters {
  area: Filter<SystemConstitutionArea>;
  subArea: Filter<SystemConstitutionSubArea>;
  branch: Filter<SystemConstitutionBranch>;
  subBranch: Filter<SystemConstitutionSubBranch>;
  employerType: Filter<SystemConstitutionEmployerType>;
}
interface Column {
  header: string;
  fieldName: string;
  fromSC?: boolean;
  selectButton?: boolean;
  editable?: boolean;
}
interface Section {
  name: string;
  pageHeader: string;
  showFilters: { [key: string]: true };
  columns: Column[];
}
interface Sections {
  area: Section;
  subArea: Section;
  branch: Section;
  subBranch: Section;
  employerType: Section;
  documentType: Section;
  hoursRange: Section;
}

@Component({
  selector: 'app-system-constitution',
  templateUrl: './system-constitution.component.html',
  styleUrls: ['./system-constitution.component.scss']
})

export class SystemConstitutionComponent implements OnInit {

  metadata: MetadataForSystemConstitution;
  systemConstitution: SystemConstitutionModel;
  currentSection: Section;
  currentSectionMD: any[];
  currentSectionSC: any[];

  unsavedChanges: boolean = false;
  displayDialog: boolean = false;
  canDeactivate = new Subject<boolean>();

  filters: Filters = {
    area: { value: null, options: [] },
    subArea: { value: null, options: [] },
    branch: { value: null, options: [] },
    subBranch: { value: null, options: [] },
    employerType: { value: null, options: [] }
  };

  readonly sections: Sections = {
    area: {
      name: "area",
      pageHeader: 'רשימת איזורים ראשיים',
      showFilters: {},
      columns: [
        { header: 'קוד', fieldName: 'areaId' },
        { header: 'תיאור', fieldName: 'areaName' },
        { header: 'פעיל', fieldName: 'active', fromSC: true, selectButton: true }
      ]
    },
    subArea: {
      name: "subArea",
      pageHeader: 'רשימת אזורי משנה',
      showFilters: {
        area: true
      },
      columns: [
        { header: 'קוד', fieldName: 'subAreaId' },
        { header: 'תיאור', fieldName: 'subAreaName' },
        { header: 'פעיל', fieldName: 'active', fromSC: true, selectButton: true }
      ]
    },
    branch: {
      name: "branch",
      pageHeader: 'ענפים',
      showFilters: {
        area: true,
        subArea: true
      },
      columns: [
        { header: 'קוד', fieldName: 'code' },
        { header: 'תיאור', fieldName: 'name' },
        { header: 'נדרש מיון', fieldName: "NeedClassification", fromSC: true, selectButton: true },
        { header: 'פעיל', fieldName: 'active', fromSC: true, selectButton: true }
      ]
    },
    subBranch: {
      name: "subBranch",
      pageHeader: 'תתי ענפים',
      showFilters: {
        area: true,
        subArea: true,
        branch: true
      },
      columns: [
        { header: 'קוד', fieldName: 'subCode' },
        { header: 'תיאור', fieldName: 'subName' },
        { header: 'ברירת מחדל', fieldName: "DefaultSubBranch", fromSC: true, selectButton: true },
        { header: 'פעיל', fieldName: 'active', fromSC: true, selectButton: true }
      ]
    },
    employerType: {
      name: "employerType",
      pageHeader: 'סוגי מעסיקים',
      showFilters: {
        area: true,
        subArea: true,
        branch: true,
        subBranch: true
      },
      columns: [
        { header: 'קוד', fieldName: 'employerTypeId' },
        { header: 'תיאור', fieldName: 'employerTypeDesc' },
        { header: 'הגבלת כמות', fieldName: "employeesAmount", fromSC: true, editable: true },
        { header: 'פעיל', fieldName: 'active', fromSC: true, selectButton: true }
      ]
    },
    documentType: {
      name: "documentType",
      pageHeader: 'מסמכים',
      showFilters: {
        area: true,
        subArea: true,
        branch: true,
        subBranch: true,
        employerType: true
      },
      columns: [
        { header: 'קוד', fieldName: 'documentTypeId' },
        { header: 'תיאור', fieldName: 'documentTypeDesc' },
        { header: 'פעיל', fieldName: 'active', fromSC: true, selectButton: true }
      ]
    },
    hoursRange: {
      name: "hoursRange",
      pageHeader: 'שעות עבודה',
      showFilters: {
        area: true,
        subArea: true,
        branch: true,
        subBranch: true
      },
      columns: [
        { header: 'קוד', fieldName: 'hourRangeId' },
        { header: 'תיאור', fieldName: 'hourRange' },
        { header: 'פעיל', fieldName: 'active', fromSC: true, selectButton: true }
      ]
    },
  }

  constructor(private systemConstitutionService: SystemConstitutionService) { }

  async ngOnInit() {
    this.systemConstitution = await this.systemConstitutionService.getSystemConstitution().toPromise();
    this.metadata = await this.systemConstitutionService.getMetadata().toPromise();
    this.selectedSection(this.sections.area.name)();
  }

  selectedSection(sectionName: string): () => void {
    return (): void => {
      this.currentSection = this.sections[sectionName];

      if (sectionName != this.sections.area.name) {
        return this.setOptionsToArea();
      }
      this.currentSectionMD = this.metadata.areas;
      this.currentSectionSC = this.systemConstitution.areas = this.systemConstitution.areas || [];
    }
  }

  setOptionsToArea(): void {
    let currentFilter = this.filters.area;

    if (!currentFilter.value) {
      currentFilter.options = this.systemConstitution.areas = this.systemConstitution.areas || [];
    }

    if (currentFilter.options.length) {
      this.selectedFilterArea({ value: currentFilter.value || currentFilter.options[0] });
    }
  }
  setOptionsToSubArea(): void {
    let currentFilter = this.filters.subArea;

    if (!currentFilter.value) {
      currentFilter.options = this.filters.area.value.subAreas = this.filters.area.value.subAreas || [];
    }

    if (currentFilter.options.length) {
      this.selectedFilterSubArea({ value: currentFilter.value || currentFilter.options[0] });
    }
  }
  setOptionsToBranch(): void {
    let currentFilter = this.filters.branch;

    if (!currentFilter.value) {
      currentFilter.options = this.filters.subArea.value.branches = this.filters.subArea.value.branches || [];
    }

    if (currentFilter.options.length) {
      this.selectedFilterBranch({ value: currentFilter.value || currentFilter.options[0] });
    }
  }
  setOptionsToSubBranch(): void {
    let currentFilter = this.filters.subBranch;

    if (!currentFilter.value) {
      currentFilter.options = this.filters.branch.value.subBranches = this.filters.branch.value.subBranches || [];
    }

    if (currentFilter.options.length) {
      this.selectedFilterSubBranch({ value: currentFilter.value || currentFilter.options[0] });
    }
  }
  setOptionsToEmployerType(): void {
    let currentFilter = this.filters.employerType;

    if (!currentFilter.value) {
      currentFilter.options = this.filters.subBranch.value.employerTypes = this.filters.subBranch.value.employerTypes || [];
    }

    if (currentFilter.options.length) {
      this.selectedFilterEmployerType({ value: currentFilter.value || currentFilter.options[0] });
    }
  }

  selectedFilterArea(event: { value: SystemConstitutionArea }): void {

    if (this.filters.area.value != event.value) {
      this.filters.area.value = event.value;
      [
        this.filters.subArea,
        this.filters.branch,
        this.filters.subBranch,
        this.filters.employerType
      ].forEach(this.resetFilter);
    }

    if (this.currentSection.name == this.sections.subArea.name) {
      this.currentSectionMD = this.metadata.subAreas.filter(sa => sa.area._id == this.filters.area.value.area._id);
      this.currentSectionSC = this.filters.area.value.subAreas = this.filters.area.value.subAreas || [];
      return;
    }
    this.setOptionsToSubArea();
  }
  selectedFilterSubArea(event: { value: SystemConstitutionSubArea }): void {

    if (this.filters.subArea.value != event.value) {
      this.filters.subArea.value = event.value;
      [
        this.filters.branch,
        this.filters.subBranch,
        this.filters.employerType
      ].forEach(this.resetFilter);
    }

    if (this.currentSection.name == this.sections.branch.name) {
      this.currentSectionMD = this.metadata.branches;
      this.currentSectionSC = this.filters.subArea.value.branches = this.filters.subArea.value.branches || [];
      return;
    }
    this.setOptionsToBranch();
  }
  selectedFilterBranch(event: { value: SystemConstitutionBranch }): void {

    if (this.filters.branch.value != event.value) {
      this.filters.branch.value = event.value;
      [
        this.filters.subBranch,
        this.filters.employerType
      ].forEach(this.resetFilter);
    }

    if (this.currentSection.name == this.sections.subBranch.name) {
      this.currentSectionMD = this.metadata.subBranches.filter(sb => sb.branch._id == this.filters.branch.value.branch._id);
      this.currentSectionSC = this.filters.branch.value.subBranches = this.filters.branch.value.subBranches || [];
      return;
    }
    this.setOptionsToSubBranch();
  }
  selectedFilterSubBranch(event: { value: SystemConstitutionSubBranch }): void {

    if (this.filters.subBranch.value != event.value) {
      this.filters.subBranch.value = event.value;
      this.resetFilter(this.filters.employerType);
    }

    if (this.currentSection.name == this.sections.employerType.name) {
      this.currentSectionMD = this.metadata.employerTypes;
      this.currentSectionSC = this.filters.subBranch.value.employerTypes = this.filters.subBranch.value.employerTypes || [];
      return;
    }

    if (this.currentSection.name == this.sections.hoursRange.name) {
      this.currentSectionMD = this.metadata.hoursRanges;
      this.currentSectionSC = this.filters.subBranch.value.hoursRanges = this.filters.subBranch.value.hoursRanges || [];
      return;
    }
    this.setOptionsToEmployerType();
  }
  selectedFilterEmployerType(event: { value: SystemConstitutionEmployerType }): void {
    this.filters.employerType.value = event.value;
    this.currentSectionMD = this.metadata.documentTypes;
    this.currentSectionSC = this.filters.employerType.value.documentTypes = this.filters.employerType.value.documentTypes || [];
  }

  resetFilter<T>(filter: Filter<T>): void {
    filter.options = [];
    filter.value = null;
  }

  get showTable(): boolean {

    if (!this.currentSectionMD.length) {
      return false;
    }

    switch (this.currentSection.name) {
      case this.sections.area.name:
        return true;
      case this.sections.subArea.name:
        return !!this.filters.area.value;
      case this.sections.branch.name:
        return !!this.filters.subArea.value;
      case this.sections.subBranch.name:
        return !!this.filters.branch.value;
      case this.sections.employerType.name:
        return !!this.filters.subBranch.value;
      case this.sections.documentType.name:
        return !!this.filters.employerType.value;
      case this.sections.hoursRange.name:
        return !!this.filters.subBranch.value;
    }
  }
  getClassBySection(sectionName: string): string {
    return this.currentSection.name == sectionName ? 'active' : '';
  }

  getDataFromCurrentSectionSC(docId: string, fieldName: string): boolean | number | null {
    let doc = this.currentSectionSC.find(d => d[this.currentSection.name]._id == docId);

    if (fieldName == "active") {
      return !!doc;
    }
    return doc ? doc[fieldName] : null;
  }
  setDataToCurrentSectionSC(docId: string, fieldName: string, value: any): void {

    this.unsavedChanges = true;

    if (this.currentSection.name == this.sections.subBranch.name) {
      return this.setDataToSubBranch(docId, fieldName, value);
    }

    if (fieldName != "active") {
      return this.writeInCurrentSectionSC(docId, fieldName, value);
    }

    if (value) {
      return this.addDocToCurrentSectionSC(docId)
    }

    this.removeDocFromCurrentSectionSC(docId);
  }
  addDocToCurrentSectionSC(docId: string): void {
    if (!this.currentSectionSC.find(d => d[this.currentSection.name]._id == docId)) {
      this.currentSectionSC.push({ [this.currentSection.name]: this.currentSectionMD.find(d => d._id == docId) });
    }
  }
  removeDocFromCurrentSectionSC(docId: string): void {

    let indexDoc = this.currentSectionSC.findIndex(d => d[this.currentSection.name]._id == docId);

    if (indexDoc == -1) {
      return;
    }

    this.currentSectionSC.splice(indexDoc, 1);

    if (
      !this.filters[this.currentSection.name]
      || !this.filters[this.currentSection.name].value
      || this.filters[this.currentSection.name].value[this.currentSection.name]._id != docId
    ) {
      return;
    }

    switch (this.currentSection.name) {
      case this.sections.area.name:
        this.resetFilter(this.filters.area);
      case this.sections.subArea.name:
        this.resetFilter(this.filters.subArea);
      case this.sections.branch.name:
        this.resetFilter(this.filters.branch);
      case this.sections.subBranch.name:
        this.resetFilter(this.filters.subBranch);
      case this.sections.employerType.name:
        this.resetFilter(this.filters.employerType);
    }
  }
  writeInCurrentSectionSC(docId: string, fieldName: string, value: any): void {
    this.currentSectionSC.find(d => d[this.currentSection.name]._id == docId)[fieldName] = value;
  }
  setDataToSubBranch(docId: string, fieldName: string, value: any): void {

    switch (fieldName) {
      case "active":
        if (!value) {
          return this.removeDocFromCurrentSectionSC(docId);
        }
        this.addDocToCurrentSectionSC(docId);

        if (this.currentSectionSC.length == 1) {
          this.writeInCurrentSectionSC(docId, "DefaultSubBranch", true);
        }
        break;
      case "DefaultSubBranch":
        this.currentSectionSC.forEach(sb => sb.DefaultSubBranch = false);
        this.writeInCurrentSectionSC(docId, fieldName, value);
        break;
    }
  }
  isCellDisabled(docId: string, fieldName: string,): boolean {

    if (this.currentSection.name == this.sections.subBranch.name) {
      return this.isCellDisabledSubBranch(docId, fieldName);
    }
    return fieldName != "active" && !this.getDataFromCurrentSectionSC(docId, "active");
  }
  isCellDisabledSubBranch(docId: string, fieldName: string): boolean {

    let subBranch: SystemConstitutionSubBranch = this.currentSectionSC.find(sb => sb[this.sections.subBranch.name]._id == docId);

    switch (fieldName) {
      case "active":
        return subBranch && subBranch.DefaultSubBranch;
      case "DefaultSubBranch":
        return !subBranch || subBranch.DefaultSubBranch;
    }
  }

  showMessega(): void {
    this.displayDialog = true;
  }
  deactivate(bool: boolean): void {
    // when navigating from here to a router with children (for example: requestsandlicenses/requests), 
    // the function canDeactivate ran, if it returns true, then it runs again, 
    // then it runs the showMessage function again, 
    // so: the variable unsavedChanches becomes false in the first run of the canDeactivate
    // as a result, the showMessage function does not run in the second run of canDeactivate.
    this.unsavedChanges = !bool;
    this.canDeactivate.next(bool);
    this.displayDialog = false;
  }

  updateOrCreateSystemConstitution(): void {
    this.systemConstitutionService.updateOrCreateSystemConstitution(this.systemConstitution._id, this.systemConstitution).subscribe(
      res => {
        this.systemConstitution._id = res.data._id;
        this.unsavedChanges = false;
      }
    );
  }
}