
<div class="row d-flex justify-content-between mt-5">


  <div class="col-md-4 col-6">
  
    <button type="button" pButton  [disabled]="!hasBackStep"
    class="ui-button-rounded button-matpash no-fill-btn d-flex align-items-center  justify-content-center fw-regular"
    (click)="goBack()" label=" חזרה">
    <i class="icon-left-arrow rotate-180 mr-3"></i>
  </button>

  </div>
  <div class="col-md-4 col-6 pr-md-5">
    <button type="button" pButton
      class="ui-button-rounded w-100 button-matpash light-blue-fill d-flex align-items-center  flex-row-reverse justify-content-center"
      (click)="goForward()" label=" {{nextButtonText}}" [disabled]="!hasNextStep">
      <i class="icon-left-arrow mr-3"></i>
    </button>
  </div>
</div>

