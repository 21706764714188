<div class="wrapperInner bgWhite shadow" *ngIf="FormGroupRef">
  <div class="stepTitle subTitle subTitle--bold  colorTextBlack">
    סיכום הבקשה
  </div>

  <ul class="summeryList">
    <li
      class="summeryList__item  gridCol--md3"
      *ngIf="FormGroupRef.controls.transactionArea['controls'].region"
    >
      <b class="colorTextBlue">אזור התעסוקה</b>
      <span class=" colorTextGray">{{
        FormGroupRef.controls.transactionArea['controls'].region.value == 2
          ? "התיישבות"
          : "ישראל"
      }}</span>
    </li>
    <li
      class="summeryList__item gridCol--md3"
      *ngIf="FormGroupRef.controls.wantedWorkers['controls'].employees"
    >
      <b class="colorTextBlue">מס' עובדים מבוקשים</b>
      <span class=" colorTextGray"
        >{{
          FormGroupRef.controls.wantedWorkers['controls'].employees?.controls
            .length
        }}
        {{
          FormGroupRef.controls.wantedWorkers['controls'].employees?.controls
            .length == 1
            ? "עובד"
            : "עובדים"
        }}</span
      >
      <!-- <span class=" colorTextGray">{{}}</span> -->
    </li>

    <li
      class="summeryList__item gridCol--md3"
      *ngIf="FormGroupRef.controls.requestDetails['controls'].fields?.value"
    >
      <b class="colorTextBlue">ענף תעסוקה</b>

      <span class=" colorTextGray">{{
        FormGroupRef.controls.requestDetails['controls'].fields.value.name
      }}</span>
    </li>
    <li
      class="summeryList__item gridCol--md3"
      *ngIf="FormGroupRef.controls.requestDetails['controls'].renovating && !isIsraelRegion"
    >
      <b class="colorTextBlue">שיפוצים?</b>
      <span class=" colorTextGray">{{
        FormGroupRef.controls.requestDetails['controls'].renovating.value
          ? "כן"
          : "לא"
      }}</span>
    </li>
 
    <li
      class="summeryList__item gridCol--md3"
      *ngIf="FormGroupRef.controls.requestDetails['controls'].working_hours"
    >
      <b class="colorTextBlue">שעות עבודה</b>
      <span class=" colorTextGray"
        >{{
          FormGroupRef.controls.requestDetails['controls'].working_hours.value?.min
        }}
        -
        {{
          FormGroupRef.controls.requestDetails['controls'].working_hours.value?.max
        }}</span
      >
    </li>
    <li
      class="summeryList__item gridCol--md3"
      *ngIf="FormGroupRef.controls.requestDetails['controls'].settlements && !isIsraelRegion"
    >
      <b class="colorTextBlue">מקום עבודה מבוקש</b>
      <ng-container
        *ngFor="
          let item of FormGroupRef.controls.requestDetails['controls'].settlements
            ?.controls;
          let i = index
        "
      >
        <span class="colorTextGray" *ngIf="item">{{ item.value.name }}</span>
      </ng-container>
    </li>
    <li class="summeryList__item gridCol--md3" *ngIf="FormGroupRef.controls.requestDetails['controls'].secondaryType !== null && isIsraelRegion">
    <b class="colorTextBlue">מקום עבודה</b>
    <ng-container>
      <span class="colorTextGray">{{ FormGroupRef.controls.requestDetails['controls']?.secondaryType?.value?.name }}</span>
    </ng-container>
  </li>
  <li
  class="summeryList__item gridCol--md3"
  *ngIf="FormGroupRef.controls.requestDetails['controls'].loging !== null  && isIsraelRegion"
>
  <b class="colorTextBlue">לינה בישראל?</b>
  <span class=" colorTextGray">{{
    FormGroupRef.controls.requestDetails['controls'].loging.value
      ? "כן"
      : "לא"
  }}</span>
</li>
    <li
      class="summeryList__item gridCol--md3"
      *ngIf="FormGroupRef.controls.requestDetails['controls'].license_duration && !isIsraelRegion"
    >
      <b class="colorTextBlue">משך הרישיון המבוקש</b>
      <span class=" colorTextGray"
        >{{
          FormGroupRef.controls.requestDetails['controls'].license_duration.value
        }}
        {{
          FormGroupRef.controls.requestDetails['controls'].license_duration
            .value == 1
            ? "יום"
            : "ימים"
        }}</span
      >
    </li>
    <li
      class="summeryList__item gridCol--md3"
      *ngIf=" FormGroupRef.controls.requestDetails['controls'].employment_bureau?.value && !isIsraelRegion">
      <b class="colorTextBlue">לשכת תעסוקה מטפלת</b>
      <span class=" colorTextGray">{{
        FormGroupRef.controls.requestDetails['controls'].employment_bureau.value
          .name
      }}</span>
    </li>
  </ul>

  <div class="digitalApplicationMsg" *ngIf="isIsraelRegion">
    <div class="digitalApplicationMsg__item colorTextGray">לתקלות טכניות יש לפנות למספר <a class="colorTextBlue tel" href="tel:*4973">*4943</a>
    <br><br>
    לשאלות מקצועיות בתחום ההעסקה יש לפנות למנהל עובדים זרים ברשות האוכלוסין בטלפון <a class="colorTextBlue tel" href="tel:0747085111">074-7085111</a> (בימים א-ה בין השעות 08:00-14:00)
  </div>

  </div>

  <stepper-buttons-component
    nextButtonText="סיום"
    [hasBackStep]="hasBack()"
    [hasNextStep]="true"
    (onStepGoBack)="goToBackStep()"
    (onStepGoNext)="submit()"
  ></stepper-buttons-component>
</div>
