<div class="modalWrapper">
  <button mat-button mat-dialog-close class="closeBtn"></button>
  <h2 mat-dialog-title class="subTitle subTitle--semiBold subTitle--blueTextColor">
    {{ subtitle }}
  </h2>
  <div class="row">
  <button mat-button style="width: 40%;" class="btn btn--blue" (click)="israel()">ישראל</button>
  <button mat-button style="width: 40%;" class="btn btn--blue" (click)="notisrael()">התיישבות</button>  
  </div>
  <perfect-scrollbar class="perfectScrollBar loginScrollBar" [config]="config">
    <p class="textContent" *ngIf='ifIsrael==true' [innerHtml]="text1"></p>
    <p class="textContent" *ngIf='ifIsrael==false' [innerHtml]="text"></p>
  </perfect-scrollbar>
  <ul class="contactDetailsList">
    <li class="contactDetailsListItem">
      <img src="./assets/imgs/phone-icon.png" />
      <a href="tel:*4943" class="listItemLink textContent colorTextGray">074-7642929</a>
    </li>
    <li class="contactDetailsListItem">
      <img src="./assets/imgs/email-icon.png" />
      <a href="mailto:mnz@mgar.co.il" class="listItemLink textContent colorTextGray">mnz@mgar.co.il</a>
    </li>
  </ul>
  <mat-dialog-actions class="modalConfirm">
    <button mat-button mat-dialog-close class="btn btn--blue btn--mobileWidthBtn btn--desktopWidthBtn">אישור</button>
  </mat-dialog-actions>
</div>
