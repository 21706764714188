<div class="tabsSection pt-0">
    <h1 *ngIf="updateMode===true">עדכון הודעות</h1>
    <h1 *ngIf="updateMode===false"> הוספת הודעה</h1>

    <form class="" [formGroup]="updateAlertMessageFG" (ngSubmit)="submit()">
        <div>
            <div class="d-flex flex-wrap my-4 align-items-end px-4">

                <input class="input-matpash col-12 col-md-4 mb-4 mb-md-0 ml-0 ml-md-4" formControlName="title"
                    placeholder=" כותרת" pInputText />
                <div *ngIf="submitted===true && updateAlertMessageFG.controls.title.errors?.required"
                    class="validation-error mt-2 mb-2 d-flex align-items-center"><i class="icon-16 ml-3"></i>שדה חובה
                </div>

                <div class="d-flex flex-md-column align-items-center align-items-md-start">
                    <label class="ml-3 text-nowrap mb-0 mb-md-2">שים לב</label>
                    <p-selectButton class="selectButton-matpash w-100" [options]="hotJobOptions"
                        formControlName="hotJob" dataKey="value">
                        <ng-template let-item>
                            <div style="padding: .5em 2.5em">
                                <span>{{item.desc}}</span>
                            </div>
                        </ng-template>
                    </p-selectButton>
                </div>
            </div>

            <p-editor formControlName="content" [style]="{'height':'320px'}" placeholder=" תוכן">
            </p-editor>
            <div *ngIf="submitted && updateAlertMessageFG.controls.content.errors?.required"
                class="validation-error mt-2 mb-2 d-flex align-items-center"><i class="icon-16 ml-3"></i>שדה חובה
            </div>

            <div class="d-flex flex-wrap align-items-center my-4">
                <label class="col-12 col-md-2 color-blue mb-2 mb-md-0"> תאריכים</label>

                <p-calendar placeholder="מתאריך" dateFormat="dd/mm/yy" formControlName="fromDate"
                    class="p-calendar-matpash col-12 col-md-3 mb-4 mb-md-0" ></p-calendar>

                <p-calendar placeholder="עד תאריך" dateFormat="dd/mm/yy" formControlName="toDate"
                    (onSelect)="updateStatusesOptions()" class="p-calendar-matpash col-12 col-md-3" [minDate]="updateAlertMessageFG.controls.fromDate.value"></p-calendar>

            </div>

            <div class="d-flex flex-wrap align-items-center mb-4">
                <label class="col-12 col-md-2 color-blue mb-2 mb-md-0"> אזורים</label>

                <p-dropdown class="height-53 w-100 col-12 col-md-3 mb-4 mb-md-0" [options]="areas"
                    formControlName="areaId" optionLabel="areaName" placeholder="ראשי" dataKey="areaName"></p-dropdown>

                <p-dropdown class="height-53 w-100 col-12 col-md-3" [options]="subAreas" placeholder="משני"
                    optionLabel="subAreaName" formControlName="subAreaId" [filter]="subAreaFilter()" dataKey="subAreaName" [disabled]="!updateAlertMessageFG.controls.areaId.value">
                </p-dropdown>
            </div>

            <div class="d-flex flex-wrap align-items-center mb-4">
                <label class="col-12 col-md-2 color-blue mb-2 mb-md-0">ענפים</label>

                <p-dropdown class="col-12 col-md-3 height-53 w-100 mb-4 mb-md-0" [options]="branches"
                    placeholder="בחר ענף" optionLabel="name" formControlName="branch" dataKey="name"
                    (onChange)="BrunchesSelectionChange()">
                </p-dropdown>

                <p-dropdown class="col-12 col-md-3 height-53 w-100" [options]="subBranches" placeholder="בחר תת ענף"
                    optionLabel="subName" formControlName="subBranchId" dataKey="subName"
                    [disabled]="!updateAlertMessageFG.controls.branch.value" [filter]="subBranchFilter()">
                </p-dropdown>
            </div>

            <div class="d-flex  align-items-center mb-4">
                <label class="col-12 col-md-2 color-blue mb-2 mb-md-0">סוג מעסיק</label>
                <p-dropdown class="height-53 w-100 col-12 col-md-3" [options]="employerTypes" placeholder="בחר"
                    optionLabel="employerTypeDesc" dataKey="employerTypeDesc" formControlName="employerTypeId">
                </p-dropdown>
            </div>
            <div class="d-flex  align-items-center mb-5">
                <label class="col-12 col-md-2 color-blue mb-2 mb-md-0">סטטוס</label>
                <p-dropdown class="height-53 w-100 col-12 col-md-3" [options]="statuses" placeholder="בחר"
                    optionLabel="hebrewDesc" dataKey="hebrewDesc" formControlName="statusId">
                </p-dropdown>
            </div>



            <div class="d-flex align-items-center mb-4">
                <label *ngIf="updateMode===true" class="mr-4 color-light-blue">
                    <span class="fw-bold ml-2"> תאריך עדכון</span>
                    {{alertMessage.dateUpdated |date:'dd/MM/yyyy'}}</label>

                <div class=" d-flex col-md-6 mr-auto">
                    <button class="ui-button-rounded  button-matpash no-fill-btn ml-4 col-md-4 mr-auto"
                        (click)="deleteAlertMessage()">מחק</button>

                    <button type="submit" pButton label="{{(updateMode===true ? 'עדכן' : 'שמור')}}"
                        class=" ui-button-rounded  button-matpash light-blue-fill col-md-4">
                    </button>
                </div>
            </div>


        </div>

    </form>
</div>