
<p-dialog header="" [(visible)]="cancelLicence" 
(onHide)="sendStatusModalToParent(1)" 
[modal]="true" [autoZIndex]="true"
[responsive]="false" [blockScroll]="true" 
[draggable]="false" appendTo="body" 
[style]="{ minWidth: '86vw ,height: 90vh'}" 
[minY]="70" [baseZIndex]="10000"
>

  <div class="dilog-matpash small-dialog h-100 licenses-dialog">
    <div class="row m-0 w-100 flex-column-reverse d-flex flex-column flex-md-row h-100 flex-md-wrap flex-nowrap"
      style="z-index: 9999999999!important;">
      <div class="col-12 col-md-8 left-side-dialog d-flex h-100 flex-column">
        <h1 class="">ביטול רשיון תעסוקה</h1>

        <p class="textContent mb-0">
          {{dialogData.validEmployees.length == 1
          ? "הנך עומד לבטל את רישיון התעסוקה של העובד הבא:"
          : "הנך עומד לבטל את רישיונות התעסוקה של העובדים הבאים:"}}
        </p>

        <div class="max-list-employees">
          <div class="textContent font-weight-bold"
            *ngFor="let employee of dialogData?.validEmployees; let i = index; ">
            {{employee.fullName}} {{ employee.id}}
          </div>
        </div>


        <div class="textContent ml-4">
          סיבת ביטול רישיון:
          <p-dropdown [options]="dialogData.cancelReasons" placeholder="נא בחר סיבת ביטול רישיון"
            [(ngModel)]="cancelReason" optionLabel="name" [showClear]="true">
          </p-dropdown>
        </div>

        <div>
          <div class="d-flex align-items-center my-3 colorTextRed">
            <i class="icon-16 ml-3"></i>
            <p class="textContent ">
              שים לב, לא יהיה ניתן לבטל פעולה זו
            </p>
          </div>
          <div class="d-flex">
            <p-checkbox [(ngModel)]="approveCheckbox" class="checkboxSelect textContent textContent--semiBold"
              binary="true">
            </p-checkbox>
            אני בטוח/ה שברצוני לבטל רשיון תעסוקה זה.
          </div>
        </div>


        <div class="d-flex mt-5">
          <button type="button" pButton (click)="sendStatusModalToParent(1)" label="ביטול"
            class="ui-button-rounded button-matpash no-fill-btn ml-4">
          </button>
          <button type="button" pButton label="אישור" (click)="sendStatusModalToParent(0)"
            [disabled]="isSubmitDisabled()"
            class="ui-button-rounded  button-matpash light-blue-fill d-flex align-items-center  flex-row-reverse justify-content-center"></button>
        </div>
      </div>

      <div
        class="col-12 col-md-4 right-side-dialog d-flex flex-md-column justify-content-center justify-content-md-between flex-row-reverse">
        <img alt="" class="mt-5 mb-md-5 mb-0 px-5 img-header-mobile" src="../../../../../assets/imgs/RevocationLicense.png" />
      </div>
    </div>
  </div>
</p-dialog>