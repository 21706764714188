import { BranchModel } from "./Employee";

export class Branch {
    _id: string;
    code: number;
    name: string;
    Active: boolean;
}

export interface SubBranch {
    _id: string;
    branch: Branch;
    subCode: number;
    Active: boolean;
}

export interface Region {
    regionId: number;
    regionName: string;
}

export interface Area {
    _id: string;
    areaId: number;
    areaName: string;
    state: boolean;
}

export interface SubArea {
    _id: string;
    area: Area;
    subAreaId: number;
    subAreaName: string;
    state: boolean;
}

export interface EmployerType {
    _id: string;
    EmployerTypeId: number;
    employerTypeDesc: string;
    employeesAmount: number;
}

export interface Document {
    _id: string;
    DocumentId: number;
    DocumentName: string;
    DocuementTemplate: string;
    Active: boolean;
}

export interface EmploymentHoures {
    _id: string;
    HourRangeId: number;
    HourRange: string;
}

export interface City {
    CityId: number;
    City: string;
    RegionId: number;
}

export class Language {
    LanguageID: number;
    Language: string;
}

export interface Gender {
    GenderID: number;
    Gender: string;
}

export interface EmploymentStatus {
    EmploymentStatusID: number;
    EmploymentStatus: string;
}

export class Profession {
    ProfessionId: number;
    ProfessionName: string;
    SubBranchId: number;
}