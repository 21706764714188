import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../shared/service/ApplicationHttpClient";
var SystemConstitutionService = /** @class */ (function () {
    function SystemConstitutionService(http) {
        this.http = http;
        this.endPoint = "api/system_constitution/";
    }
    SystemConstitutionService.prototype.getSystemConstitution = function () {
        return this.http.Get(this.endPoint).pipe(map(function (res) {
            if (res.data && res.data.length) {
                return res.data[0];
            }
            return {};
        }));
    };
    SystemConstitutionService.prototype.getMetadata = function () {
        return this.http.Get(this.endPoint + "getMetadata").pipe(map(function (res) { return res.data; }));
    };
    SystemConstitutionService.prototype.updateOrCreateSystemConstitution = function (id, systenConstitution) {
        var endPoint = this.endPoint + (id || '');
        return this.http.Put(endPoint, systenConstitution);
    };
    SystemConstitutionService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SystemConstitutionService_Factory() { return new SystemConstitutionService(i0.ɵɵinject(i1.ApplicationHttpClient)); }, token: SystemConstitutionService, providedIn: "root" });
    return SystemConstitutionService;
}());
export { SystemConstitutionService };
