import { SubBranch } from "./subBranch";

export class Profession{
    constructor(
        public _id?:string,
        public professionId?:string,
        public profession?:string,
        public professionArb?:string,
        public subBranch?:SubBranch
    ){}
}