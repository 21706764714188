<section class="bannerSection w-100">
  <div class="bannerText width-main">
    <!-- <h1 class="mainTitle mainTitle--textShadow">אתר התעסוקה של המנהל האזרחי</h1>
        <h2 class="subMainTitle">חדשנות בשרות לאזרח</h2> -->
    <span *ngIf="buttonString">
      <div class="d-flex align-items-center get-back">
        <i class="icon-left-arrow ml-2"></i>
        <button>{{buttonString}}</button>
      </div>
    </span>
    <!-- <span>חזור </span> -->

    <h2 class="header-banner" [innerHTML]="title">
    </h2>
  </div>
</section>