import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  termsFooterLogin = false;
  isNotLogin = false;

  constructor(private router: Router) { }

  ngOnInit() {
    this.router.events.subscribe(e => {

      if (e instanceof NavigationEnd) {
        this.isNotLogin = e.url !== '/login';
      }
    });
  }
}
