import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import {GeneralModalData} from "../../../models/interfaces";

@Component({
  selector: 'app-general-modal',
  templateUrl: './general-modal.component.html',
  styleUrls: ['./general-modal.component.scss']
})
export class GeneralModalComponent implements OnInit {
  title: string;
  text:string;
  errorMessage:string;

  constructor(
    private dialogRef: MatDialogRef<GeneralModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: GeneralModalData
  )  {}

  ngOnInit() {
    this.title = this.data.title;
    this.text = this.data.text;
    this.errorMessage = this.data.errorMessage;
  }

  closeModal() {
    this.dialogRef.close();
  }


}
