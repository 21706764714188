export class InstructionEmployeement{
    constructor(
        public areaId?:string,
        public version?:number,
        public instruction?:string,
        public email?:string,
        public phoneNo?:string,
        public officeHours?:string,
        public status?:string,
        public publishDate?:Date,
        public userUpdated?:string,
        public dateUpdated?:Date
    ){}
}