<div class="" [formGroup]="FormGroupRef" *ngIf="FormGroupRef">

  <div class="row d-flex flex-md-row flex-column-reverse">
    <div class="col-md-6 col-12">
      <div class="header-step">אזור העסקה</div>
      <div class="color-blue mb-5 text-md-right text-center">
        נא לבחור את אזור ההעסקה המבוקש
      </div>
      <div class="row">
        <div class="col-6 w-select-btn pl-md-0" *ngFor="let region of regions">
          <!-- <button pButton (click)="onSelectionChange(region.value)"  value="region.value">{{region.lable}}</button> -->


          <!-- TODO: to active button add the class : active -->
          <button type="button" pButton #elementRef id="elementRef" [ngClass]="classAreaActive(region)"
            class="ui-button-rounded w-100 button-matpash gray-border d-flex align-items-center justify-content-center mb-5"
            (click)="onSelectionChange(region)" value="region.value" label="{{region.lable}}">
            <i class="{{region.icon}} mr-3"></i>
          </button>

        </div>

      </div>
    </div>
    <div class="col-md-6 col-12 d-flex justify-content-center">
      <!-- <img class="h-mobile-map" src="../../../../assets/imgs/map-img.svg"> -->
    </div>
  </div>

  <div *ngIf="!!showModalDisableIsrael">
    {{showModalDisableIsrael}}
  </div>
  
  <stepper-buttons-component [hasBackStep]="hasBack()" [hasNextStep]="hasNext()" (onStepGoBack)="goToBackStep()"
    (onStepGoNext)="submit()"></stepper-buttons-component>
</div>