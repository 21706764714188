import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationsAndUpdate } from 'src/app/models/interfaces';

@Component({
  selector: 'app-read-more-modal',
  templateUrl: './read-more-modal.component.html',
  styleUrls: ['./read-more-modal.component.scss']
})
export class ReadMoreModalComponent implements OnInit {
  notificationsAndUpdate: NotificationsAndUpdate;

  constructor(
    private dialogRef: MatDialogRef<ReadMoreModalComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.notificationsAndUpdate = data;
  }

  ngOnInit() {

  }

  closeModal() {
    this.dialogRef.close();
  }
}
