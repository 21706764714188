import { Injectable } from '@angular/core';
import {MatDialog, MatDialogConfig} from "@angular/material";

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  constructor( private dialog: MatDialog) { }


  openModal(component: any , modalData?: any , options?: MatDialogConfig) {
    return this.dialog.open(component, {
      disableClose: options && options.disableClose || false,
      backdropClass: '',
      panelClass: options && options.panelClass || 'modalPanel',
      role: 'dialog',
      ariaDescribedBy: '',
      ariaLabel: '',
      direction: 'rtl',
      autoFocus: true,
      maxWidth: options && options.maxWidth || 640,
      restoreFocus: true,
      data: modalData
    });
  }
}
