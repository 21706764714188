import * as i0 from "@angular/core";
import * as i1 from "./wizrad-user-data.service";
import * as i2 from "./form.service";
var BuildingPermitService = /** @class */ (function () {
    function BuildingPermitService(wizradUserDataService, formService) {
        this.wizradUserDataService = wizradUserDataService;
        this.formService = formService;
        this.selectedField = null;
        this.fieldsSubscription = null;
    }
    BuildingPermitService.prototype.getBuildingPermitRef = function () {
        return this.formService.getForm().get("buildingPermit");
    };
    BuildingPermitService.prototype.allowBuildingPermitUpload = function (preventEmployeesCheckCondition) {
        if (preventEmployeesCheckCondition === void 0) { preventEmployeesCheckCondition = false; }
        if (this.wizradUserDataService.initialHasPermit || !this.selectedField) {
            return false;
        }
        var requestDetails = this.formService.getFormGroupRefByName("requestDetails");
        var isRenovation = requestDetails.get("renovating");
        return this.selectedField.code === 4
            && !!this.selectedField.employmentLimitation
            && isRenovation && isRenovation.value === false
            && (this.selectedField.currentEmployed >= this.selectedField.employmentLimitation || preventEmployeesCheckCondition);
    };
    BuildingPermitService.prototype.hasBuildingPermitUploaded = function () {
        return !!this.getBuildingPermitRef().value;
    };
    BuildingPermitService.prototype.getBuildingPermitDownloadDocument = function () {
        return this.wizradUserDataService.getDownloadDocumentByName("buildingPermit");
    };
    BuildingPermitService.prototype.init = function () {
        var _this = this;
        this.fieldsSubscription = this.formService.getFormGroupRefByName("requestDetails").get('fields').valueChanges.subscribe(function (field) {
            _this.selectedField = field;
        });
    };
    BuildingPermitService.prototype.dispose = function () {
        this.fieldsSubscription.unsubscribe();
    };
    BuildingPermitService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BuildingPermitService_Factory() { return new BuildingPermitService(i0.ɵɵinject(i1.WizradUserDataService), i0.ɵɵinject(i2.FormService)); }, token: BuildingPermitService, providedIn: "root" });
    return BuildingPermitService;
}());
export { BuildingPermitService };
