<div class="container-fluid container-box">
    <div class="tabsSection storing-day">
        <h1>ניהול פרמטרים</h1>
        <form [formGroup]='form'>
            <div class="row my-5">
                <div class="col-md-6  mb-5 mb-md-0">
                    <div class="d-flex align-items-center "> 
                        <span class="w-50">התראה לפני סיום תקופה</span>
                        <input formControlName="daysRenewalLicense" type="number" pInputText
                            class="ml-3 input-matpash w-100">
                    </div>
                    <div class="errorMsg">{{form.get("daysRenewalLicense").errors? form.get("daysRenewalLicense").errors.msg : ''}}</div>
                </div>

                <div class="col-md-6">
                    <div class="d-flex align-items-center "> 
                    <span class="w-50">מקסימום ישובים בבקשה</span>
                    <input formControlName="amountCitiesInRequest" type="number" pInputText
                        class="ml-3 input-matpash w-100">
                    </div>
                    <div class="errorMsg">{{form.get("amountCitiesInRequest").errors? form.get("amountCitiesInRequest").errors.msg : ''}}
                    </div>
                </div>

            </div>

            <div class="row">
                <div class="col-md-6 mb-5 mb-md-0">
                    <div class="d-flex align-items-center "> 
                    <span class="w-50">מקסימום ימים לשליפת מעברים של עובד</span>
                    <input formControlName="maxDaysTransitions" type="number" pInputText
                        class="ml-3 input-matpash w-100">
                    </div>
                    <div class="errorMsg">{{form.get("maxDaysTransitions").errors? form.get("maxDaysTransitions").errors.msg : ''}}</div>
                </div>

                <div class="col-md-6 ">
                    <div class="d-flex align-items-center "> 
                    <span class="w-50">תוספת עובדים לבקשה עם היתר</span>
                    <input formControlName="extensionWorker" type="number" pInputText class="ml-3 input-matpash w-100">
                    </div>
                    <div class="errorMsg ">{{form.get("extensionWorker").errors? form.get("extensionWorker").errors.msg : ''}}</div>
                </div>

            </div>

            <div class="d-flex mt-5">
                <button pButton type="button" label="שמור" [disabled]="!form.dirty || !form.valid"
                    class=" ui-button-rounded button-matpash light-blue-fill mr-auto" (click)="onSave()">
                </button>
            </div>
        </form>
    </div>
</div>