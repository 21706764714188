import { Component, ElementRef, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Branch } from 'src/app/models/branch';
import { Candidate } from 'src/app/models/candidate';
import { Employee } from 'src/app/models/Employee';
import { Profession } from 'src/app/models/profession';
import { CandidateStatusEnum, SortingDay } from 'src/app/models/sortingDay';
import { SubBranch } from 'src/app/models/subBranch';
import { EmployeeService } from 'src/app/services/employee-service';
import { StoringDayService } from 'src/app/services/storing-day.service';

@Component({
  selector: 'app-input-day-storing',
  templateUrl: './input-day-storing.component.html',
  styleUrls: ['./input-day-storing.component.scss']
})
export class InputDayStoringComponent implements OnInit {

  @ViewChildren("check") check: QueryList<any>;
  @ViewChildren('employees') public employees: QueryList<any>;

  listBranch: Array<Branch> = new Array<Branch>();
  listSubBranch: Array<SubBranch> = new Array<SubBranch>();
  listProfession: Array<Profession> = new Array<Profession>();

  arrayBranch: Array<Branch> = new Array<Branch>();
  arraySubBranch: Array<SubBranch> = new Array<SubBranch>();
  arrayProfession: Array<Profession> = new Array<Profession>();

  listSortingDay: Array<SortingDay> = new Array<SortingDay>();
  listEmployee: Array<Employee> = new Array<Employee>();
  listEmployeeChecked: Array<Employee> = new Array<Employee>();
  listRemoveCandidate: Array<Candidate> = new Array<Candidate>();

  sortingDaySelected: SortingDay = new SortingDay();
  branch: Branch = new Branch();
  subBranch: SubBranch = new SubBranch();
  profession: Profession = new Profession();
  displayModal: boolean = false;
  showContact: boolean = false;
  title: string;
  content: string;
  img:string

  employee:Employee=new Employee();
  sortingDay:SortingDay=new SortingDay();
  candidate:Candidate=new Candidate();

  selectedSortingDay: any = null;

  constructor(public sortingDayService: StoringDayService, public employeeService: EmployeeService) { }

  ngOnInit() {
    this.sortingDayService.getAllBranches().subscribe(data => {
      this.listBranch = data.data;
    });

    this.sortingDayService.getAllSubBranches().subscribe(data => {
      this.listSubBranch = data.data;
    });

    this.sortingDayService.getAllProfession().subscribe(data => {
      this.listProfession = data.data;
    });

    this.initialTables()
  }

  initialTables() {
    this.fillTable(this.sortingDay, this.listSortingDay);

    this.fillTable(this.employee, this.listEmployee);

    let listCandidate:Candidate[]=[];
    this.sortingDaySelected.candidates = this.fillTable(this.candidate, listCandidate)
  }

  refreshTables() {
    this.sortingDayService.searchEmployees(this.profession._id).subscribe(data => {
      this.listEmployee = data.data;
      this.fillTable(this.employee, this.listEmployee)
    })
    this.sortingDayService.getCandidate(this.profession._id).subscribe(data => {
      this.listSortingDay = data.data;
      this.listSortingDay= this.sortListSortingDayByDate(data.data);
      this.fillTable(this.sortingDay, this.listSortingDay);
      this.sortingDaySelected = data.data[0];

      this.selectedSortingDay = this.listSortingDay[0];
      
      if (!!this.sortingDaySelected.allocation){
        this.fillTable(this.candidate, this.sortingDaySelected.candidates);
        return
      }
      let listCandidate:Candidate[]=[];
      this.sortingDaySelected.candidates = this.fillTable(this.candidate, listCandidate)
    });
  }

  sortListSortingDayByDate(array:SortingDay[]){
    array.sort(function(a,b){
      return Number(new Date(b.examDate)) - Number(new Date(a.examDate))
    });
    return array
  }

  fillTable(object: any, list: any[]) {
    for (let i = 0; i <= 13; i++) {
      if (list.length <= i) {
        list.push(object)
      }
    }
    return list;
  }

  amountCandidate(item:SortingDay){
    if (!item.allocation) return
    const countCandidate=item.candidates.filter((candidate)=>{
      return !!candidate.employeeId
    });
    return countCandidate.length;
  }

  checkedEmployee(event: any, employee: Employee) {
    if (event == true) {
      return this.listEmployeeChecked.push(employee);
    }
    return this.listEmployeeChecked = this.listEmployeeChecked.filter((emp) => {
      return emp !== employee
    })
  }

  checkedRemoveCandidate(check: any, candidate: Candidate) {
    if (check.checked == true) {
      return this.listRemoveCandidate.push(candidate);
    }
    return this.listRemoveCandidate = this.listRemoveCandidate.filter((candidates) => {
      return candidates !== candidate
    })
  }

  async removeCandidate() {
    if (new Date(this.sortingDaySelected.examDate) < new Date()) {
      this.displayModal = true;
      this.title = "מחיקת עובדים מיום מיונים שהתקיים";
      this.content = "לא ניתן למחוק עובדים מיום מיונים שהגיעו תוצאות לגביו";
      this.img
      return;
    }

    const employees = this.listRemoveCandidate.map((candidate) => {
      if (candidate && candidate.employeeId) {
        return candidate.employeeId._id
      }
    })
    await this.updateStatusEmployeeProfession(CandidateStatusEnum.Deleted, employees);

    const candidateRemove = this.sortingDaySelected.candidates.filter(ar => !this.listRemoveCandidate.find(rm => (rm._id === ar._id)))

    this.listRemoveCandidate = new Array<Candidate>();

    this.updateStatusCandidate(candidateRemove);
  }

  selectSortingDay(item: SortingDay) {
    this.sortingDaySelected = item;
    this.fillTable(this.candidate, this.sortingDaySelected.candidates)
  }

  setSubBranchesArray() {
    this.listEmployee = [];
    this.listSortingDay = [];
    this.sortingDaySelected={}
    this.initialTables();

    if (this.branch == null) {
      this.branch = {}
      this.subBranch = {}
      this.profession = {};
      this.arrayProfession = [];
      this.arraySubBranch = [];
      return;
    }
    this.arraySubBranch = this.listSubBranch.filter((sub) => {
      return sub.branch._id == this.branch._id
    })
  }

  setProfessionsArray() {
    this.listEmployee = [];
    this.listSortingDay = [];
    this.sortingDaySelected = {};
    this.initialTables();
    
    if (this.subBranch == null) {
      this.profession = {}
      this.arrayProfession = [];
      return;
    }
    this.arrayProfession = this.listProfession.filter((profession) => {
      return profession.subBranch._id == this.subBranch._id
    })
  }

  selectAllEmployee() {
    this.check.forEach((element) => {
      element.checked = true
    })
    this.listRemoveCandidate = this.sortingDaySelected.candidates
  }

  clear() {
    this.employees.forEach((element) => {
      element.checked = false
    })
    this.listEmployeeChecked=[]
  }

  updateStatusEmployeeProfession(status: number, list: string[]) {

    const data = {
      employee: list,
      status: status
    }
    this.sortingDayService.updateStatusEmployeeProfession(data, this.profession._id).subscribe(data => {
    })
  }

  async embedEmployees() {
    if (new Date(this.sortingDaySelected.examDate) < new Date()) {
      this.displayModal = true;
      this.title = "הוספת עובדים ליום מיונים שהתקיים";
      this.content = "לא ניתן להוסיף עובדים ליום מיונים שהגיעו תוצאות לגביו";
      this.img
      return;
    }

    const countCandidate=this.sortingDaySelected.candidates.filter((candidate)=>{
      return !!candidate.employeeId
    })
    if (this.sortingDaySelected.allocation < countCandidate.length + this.listEmployeeChecked.length) {     
      const employees = this.sortingDaySelected.allocation - this.amountCandidate(this.sortingDaySelected);
      this.displayModal = true;
      this.title = "אין באפשרותך להוסיף עובדים לרשימה";
      this.content = `מאחר ובחרת לשבץ יותר עובדים מכמות ההקצאה אינך יכול לשבץ עובדים אלו. 
      יש באפשרותך לשבץ ${employees} עובדים `;
      this.img;
      return;
    }
    
    const data = {
      employee: this.listEmployeeChecked,
      status: CandidateStatusEnum.Embedded
    }

    const employees = this.listEmployeeChecked.map((emp) => {
      return emp._id
    })

    this.listEmployeeChecked = new Array<Employee>();

    this.sortingDayService.createCandidates(data).subscribe(data => {

      this.updateStatusEmployeeProfession(CandidateStatusEnum.Embedded, employees)
      const dataCandidate = data.data as Candidate[];
      dataCandidate.map((candidate) => {
        this.sortingDaySelected.candidates.push(candidate);
      });

      this.updateStatusCandidate(this.sortingDaySelected.candidates);
    });
  }

  updateStatusCandidate(candidates: Candidate[]) {
    const currentCandidate=candidates.filter((candidate)=>{
      return !!candidate.employeeId
    })
    this.sortingDayService.embedEmployees(this.sortingDaySelected._id, currentCandidate).subscribe(data => {

      const index = this.listSortingDay.findIndex((sortingDay) => {
        return sortingDay._id === data.data._id
      })

      this.listSortingDay[index] = data.data;
      this.sortingDaySelected = data.data;

      this.fillTable(this.candidate, this.sortingDaySelected.candidates)

      this.sortingDayService.searchEmployees(this.profession._id).subscribe(data => {
        this.listEmployee = data.data;
        this.fillTable(this.employee, this.listEmployee);
      })
    });
  }

  getStatusModal(status: boolean) {
    this.displayModal = status;
  }
}