import { Area, Profession } from './metadataModels'
import { EmployeeProfession } from './employeeProfession';


export class Residence {
    RegionId: number;
    Region: string;
    CityId: number;
    City: string;
}


export class ProfessionsDetails {
    BranchId: number;
    Branch: string;
    SubBranchId: number;
    SubBranch: string;
    Professions: Profession[];
    Authorized: boolean;
    DateAuthorization: Date;


}
export class LanguageDetails {
    LanguageId: number;
    Language: string;
    Speak: number;
    Read: number;
    Write: number
}

export class EmployeeFile{
    fileContent:string;
    fileTypeId:DocumentTypeModel
}

export class DocumentTypeModel{
    documentTypeId:string;
    documentTypeDesc:string;
    documentTemplate:string;
}

export class Employee{
    _id:string;
    employeeIdentity: string;
    firstName: string;
    lastName: string;
    fullName: string;
    phoneNumber: string;
    email: string;
    workedInIsrael: boolean;
    regionName: string;
    files:EmployeeFile[];

    // TODO check about licence/area
    livingCity: livingCity;
    languages: language[];
    safetyExam: boolean;
    examDate: Date;
    //files: EmployeeFileModel[];
    professions: EmployeeProfession[];
}

export class EmployeeFromSearch {
    areaIds: [];
    birthDate: Date;
    employeeIdentity: string;
    firstName: string;
    languages: language[];
    lastName: string;
    livingCity: livingCity;
    regionName: string;
    phoneNumber: string;
    professions: professionModel[]
    files?:EmployeeFile[];
    warnings: []
    email: string;
    workedInIsrael: boolean;
    safetyExam: boolean;
    examDate: Date;
}

export class livingCity {
    _id: string
    cityId: string;
    cityName: string;
    regionId: regionId;
}

export class language {
    languageId: string;
    languageName: string;
}

export class regionId {
    _id: string
    regionId: string;
    regionName: string;
}

export class professionModel {
    _id: string
    professionId: string;
    profession: string;
    professionArb: string;
    subBranch: SubBrancModel;
    Authorized?: boolean;//TODO: Not like server
}

export class SubBrancModel {
    _id: string
    subName: string;
    subCode: string;
    allowedLicenseTypes: number[];
    branch: BranchModel;
}

export class BranchModel {
    _id: string
    code: string;
    name: string;
}


