import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class AppConfig {
  private _settings: any;
  enviornment: string;
  private config = {
    "frontend.employment.cogatapps.com": {
      "enviornment": "poduction",
      "production": "true",
      "baseUrl": "https://server.employment.cogatapps.com/",
      "accessControlAllowOrigin": "https://frontend.employment.cogatapps.com"
    },
    "frontend.employmentqa.cogatapps.com": {
      "enviornment": "pre prod",
      "production": "true",
      "baseUrl": "https://server.employmentqa.cogatapps.com/",
      "accessControlAllowOrigin": "https://frontend.employmentqa.cogatapps.com"
    },
    "matpahfe.azurewebsites.net": {
      "enviornment": "qa",
      "production": "true",
      "baseUrl": "https://matpahbe.azurewebsites.net/",
      "accessControlAllowOrigin": "https://matpahfe.azurewebsites.net"
    },
    "localhost": {
      "enviornment": "dev",
      "production": "false",
      "baseUrl": "http://localhost:4000/",
      "accessControlAllowOrigin": "http://localhost:4200"
    }
  }

  constructor() {}

  
  public load() {
    let host = window.location.host.split(/[:]/)[0];
    if(!host){
      throw "invalid URL";
    }
    if (!this.config[host]) {
      throw `app.config.ts internal error: couldn't find host '${host}' in configuration`;
    }
    this._settings = this.config[host];
    if(host==="localhost"){
      this._settings.accessControlAllowOrigin=window.location.origin;
    }
  }

  get(key: any) {
    return this._settings[key];
  }
  get settings() {
    return this._settings;
  }

}