import { Subject } from "rxjs";
import * as i0 from "@angular/core";
import * as i1 from "./ApplicationHttpClient";
var EmailService = /** @class */ (function () {
    function EmailService(http) {
        this.http = http;
        this.showEmailBtn = new Subject();
    }
    EmailService.prototype.showEmailButton = function () {
        this.showEmailBtn.next(true);
    };
    EmailService.prototype.sendEmail = function (pdf) {
        return this.http.Post("api/email/sendEmail/", pdf);
    };
    EmailService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function EmailService_Factory() { return new EmailService(i0.ɵɵinject(i1.ApplicationHttpClient)); }, token: EmailService, providedIn: "root" });
    return EmailService;
}());
export { EmailService };
