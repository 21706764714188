import { Inject, Injectable } from '@angular/core';
import {
  CurrentEmployee,
  CurrentEmployeesData,
  CancelReason,
  CancelLicense,
  CancelLicenseResponse
} from '../models/interfaces';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApplicationHttpClient } from '../shared/service/ApplicationHttpClient';
import { FileService } from "../shared/service/file.service";
import { HttpResponse } from "@angular/common/http";
import { EmployeesFilter } from '../models/EmployeesFilter'
import { Employee } from '../models/Employee';
import { Language } from '../models/metadataModels';
@Injectable({
  providedIn: 'root'
})
export class EmployeeService {
  //TODO: Create currentEmployeesData As BehaviorSubject For The Remove Of Emploees
  private currentEmployeesData: CurrentEmployeesData;
  private wantedWorkersEmployeesId: string[] = [];
  private allowRenewDays = 14;
  private cancellationReasons: CancelReason[] = [];
  private applicationsUrlMap = new Map();
  renewLicenseEmployees = new BehaviorSubject<any>(null);
  renewEmployee = new BehaviorSubject<any>(null);
  private employeesFilter: EmployeesFilter = new EmployeesFilter();
  private employeeList: Employee[] = [];

  constructor(private http: ApplicationHttpClient, private fileService: FileService) {
  }

  getCurrentEmployeesData(hardRelaod = false) {
    if (!hardRelaod && this.currentEmployeesData) {
      return of(this.currentEmployeesData);
    }

    return this.http
      .Get<CurrentEmployeesData>(
        `api/statuses/getAllCurrentWorkers`
      )
      .pipe(
        map(res => {
          this.currentEmployeesData = res;
          return res;
        })
      );
  }

  cancelEmployeesLicense(cancelLicense: any): Observable<any> {
    return this.http.Post<any>(`api/license/cancelDigitalLicence`, cancelLicense)
  }

  renewEmployeesLicense(days: number, renewLicense: String[]): Observable<any> {
    return this.http.Post<any>(`api/license/renew`, { days: days, licensesArr: renewLicense })
  }

  getCancelReasons(): Observable<CancelReason[]> {
    if (this.cancellationReasons.length) {
      return of(this.cancellationReasons);
    }

    return this.http.Get<CancelReason[]>(`api/license/cancelReasones`).pipe(map(reasons => {
      this.cancellationReasons = reasons;
      return reasons;
    }));

  }

  setWantedWorkersEmployees(employees: CurrentEmployee[]) {
    this.wantedWorkersEmployeesId = employees.map(e => e.id);
  }
  getWantedWorkersEmployees() {
    return this.wantedWorkersEmployeesId;
  }
  resetWantedWorkersEmployees() {
    this.wantedWorkersEmployeesId = [];
  }

  getEmployeeLicenseUrl(applicationNumber: string) {
    if (this.applicationsUrlMap.has(applicationNumber)) {
      return of(this.applicationsUrlMap.get(applicationNumber));
    }
    return this.http.Post(`api/license/`,
      { employeeLicenceId: applicationNumber },
      { responseType: 'arraybuffer', observe: 'response' }
    ).pipe(map(arrayBuffer => {
      const castedBufferedFile = arrayBuffer as HttpResponse<ArrayBuffer>;
      const blob = this.fileService.ArrayBufferToBlob(castedBufferedFile);
      return URL.createObjectURL(blob);
    }));
  }

  clearEmplyees() {
    this.currentEmployeesData = null;
  }

  getAllowRenewDays(): number {
    return this.allowRenewDays;
  }

  clearData() {
    this.clearEmplyees();
    this.cancellationReasons = [];
    this.applicationsUrlMap.clear();
  }


  /*employeesFilter  ={
    "Branches" : [{"Active":true, "Branch" : "בניה","BranchId" : 1},{"Active":true, "Branch" : "ניקיון","BranchId" : 2},{"Active":true, "Branch" : "נהיגה","BranchId" : 3}],
    "SubBranches":[{"Active" : true,"BranchID":1,"SubBranch":"רצף","SubBranchId":1},{"Active" : true,"BranchID":1,"SubBranch":"סייד","SubBranchId":2},{"Active" : true,"BranchID":2,"SubBranch":"מנקה","SubBranchId":3},{"Active" : true,"BranchID":2,"SubBranch":"מנהל ניקיון","SubBranchId":4},{"Active" : true,"BranchID":3,"SubBranch":"נהג","SubBranchId":5},{"Active" : true,"BranchID":3,"SubBranch":"מפקח תחבורה","SubBranchId":6}],
    "Professions" : [{"ProfessionId":1,"ProfessionName":"רצף מומחה","SubBranchId" : 1},{"ProfessionId":2,"ProfessionName":"סייד מומחה","SubBranchId" : 2},{"ProfessionId":3,"ProfessionName":"נהג משאית","SubBranchId" : 5},{"ProfessionId":4,"ProfessionName":"נהג אוטובוס","SubBranchId" : 5}],
    "Areas" : [{"Active" : true,"AreaId" : 1, "AreaName" : "התיישבות"},{"Active" : true,"AreaId" : 2, "AreaName" : "ישראל"}],
    "Regions" : [{"RegionId":1,"RegionName":"שומרון"},{"RegionId":2,"RegionName":"גליל"},{"RegionId":3,"RegionName":"ירושלים"}],
    "Cities" : [{"City" : "בית אל","CityId" : 1,"RegionId" : 1},{"City" : "אפרת","CityId" : 2,"RegionId" : 1},{"City" : "חיפה","CityId" : 3,"RegionId" : 2},{"City" : "ירושלים","CityId" :4,"RegionId" : 3}],
    "EmployerCode" : 208624403,
    "EmploymentStatus":[{"EmploymentStatus":"מועסק","EmploymentStatusID":3},{"EmploymentStatus":"לא מועסק","EmploymentStatusID":2},{"EmploymentStatus":"הכל","EmploymentStatusID":1}],
    "Genders" : [{"Gender":"נקבה", "GenderID": 2},{"Gender":"זכר", "GenderID": 1}],
    "IsFikteredByAge" : false,
    "IsSafetyTraining" : false,
    "Languages" : [{"Language" : "ערבית","LanguageID" : 1},{"Language" : "עברית","LanguageID" : 2}],
    "MaxAge" : 90,
    "MinAge" : 20,
    "PreviouslyEmployedByIsraeli":  false,
  
   
}*/

  EmployeesByFilter = [
    {
      "employeeId": 123456,
      "AuthorityTypes": [{ "AreaID": 2, "AreaName": "שומרון" }],
      "Residences": [{ RegionId: 1, Region: "גליל", "CityId": 1, City: "ירושלים" }],
      "Languages": [{ LanguageId: 2, Language: "עברית", "Speak": 3, "Read": 5, "Write": 7 }],
      "SafteyExam": false,
      "ExamDate": "11/10/2016, 11:49:36 AM",
      "Professions": [{ "BranchId": 2, "Branch": "בניה", "SubBranchId": 3, "SubBranch": "רצף", "ProfessionId": 1, "Profession": "טייח", "Authorized": 2, "DateAuthorization": "11/10/2016, 11:49:36 AM" }],
      "Recommendations": [2, 3, 4]
    }
  ]

  //get details for employee-pool Filter
  getEmployeePoolFilter(): Observable<EmployeesFilter> {
    return this.http.Get<any>(`api/search_employees/`).pipe(map(data => {
      this.employeesFilter = data.data;
      /*if(params)
      {
        if(params.Languages)
        {
          this.employeesFilter.Languages = params.Languages.map(a=>
          {return {Language : a.language, LanguageID : a.languageId}});
        }
        if(params.Branches)
        {
          this.employeesFilter.Branches = params.Branches.map(a=>
          {return {BranchId : a.code, Branch : a.name}});
        }
        if(params.SubBranches)
        {
          this.employeesFilter.SubBranches = params.SubBranches.map(a=>
          {return {BranchId : a.branch.code, SubBranch : a.subName, SubBranchId : a.subCode}});
        }
        if(params.Professions)
        {
          this.employeesFilter.Professions = params.Professions.map(a=>
          {return {ProfessionId : a.professionId,
            ProfessionName : a.profession,
            SubBranchId : a.subBranch.subCode}});
        }
        if(params.Regions)
        {
          this.employeesFilter.Regions = params.Regions.map(a=>
          {return {RegionId : a.regionId, RegionName : a.regionName}});
        }
        if(params.Cities)
        {
          this.employeesFilter.Cities = params.Cities.map(a=>
          {return { CityId : a.cityId,
            City : a.cityName,
            RegionId : a.regionId.regionId}});
        }
        this.employeesFilter.EmploymentStatus = params.EmploymentStatus;
        this.employeesFilter.Genders = params.Genders;
    }*/

      return this.employeesFilter;
    }));
  }

  searchEmployees(filter: EmployeesFilter): Observable<any> {
    return this.http.Post<any>(`api/search_employees/search/`, filter).pipe(map(data => {
      this.employeeList = data.data;
      return this.employeeList;
    }));
  }


  //getEmployeesByFilter() : Observable<Employee[]>{
  //return of(this.EmployeesByFilter) ;
  //}


  LanguagesConvert(lang: any): Language {
    const l = new Language();
    if (lang != null) {
      l.Language = lang.language;
      l.LanguageID = lang.languageId;
    }
    return l;
  }

  getAreas(): Observable<any> {
    return this.http.Get<any>(`api/area/`);
  }

  getSubAreas(): Observable<any> {
    return this.http.Get<any>(`api/sub_areas/`);
  }

  getEmployerTypes(): Observable<any> {
    return this.http.Get<any>(`api/employer_type/`);
  }
}
