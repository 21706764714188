import { OnInit } from '@angular/core';
import colorText from 'src/app/shared/util/colorText';
var CurrentEmployeeStatusComponent = /** @class */ (function () {
    function CurrentEmployeeStatusComponent(userDataService, employeeService, router) {
        this.userDataService = userDataService;
        this.employeeService = employeeService;
        this.router = router;
        this.date = new Date();
    }
    CurrentEmployeeStatusComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.userDataService.getCurrentEmployeesStatus().subscribe(function (currentEmployeesData) {
            _this.currentEmployeesData = currentEmployeesData;
        });
    };
    Object.defineProperty(CurrentEmployeeStatusComponent.prototype, "allowRenewDays", {
        get: function () {
            return this.employeeService.getAllowRenewDays();
        },
        enumerable: true,
        configurable: true
    });
    CurrentEmployeeStatusComponent.prototype.renewLicense = function (employee) {
        this.employeeService.setWantedWorkersEmployees([employee]);
        // this.router.navigateByUrl("/employmentapplication");
        this.employeeService.renewEmployee.next(employee.licenseId);
        this.router.navigateByUrl("/requestsandlicenses/licences");
    };
    CurrentEmployeeStatusComponent.prototype.getWarningClass = function (employee) {
        return employee.daysLeftToExpire <= this.allowRenewDays ? colorText.RED + " tableDataInside--bold" : "";
    };
    return CurrentEmployeeStatusComponent;
}());
export { CurrentEmployeeStatusComponent };
