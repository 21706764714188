import * as i0 from "@angular/core";
import * as i1 from "@angular/material/dialog";
var DialogService = /** @class */ (function () {
    function DialogService(dialog) {
        this.dialog = dialog;
    }
    DialogService.prototype.openModal = function (component, modalData, options) {
        return this.dialog.open(component, {
            disableClose: options && options.disableClose || false,
            backdropClass: '',
            panelClass: options && options.panelClass || 'modalPanel',
            role: 'dialog',
            ariaDescribedBy: '',
            ariaLabel: '',
            direction: 'rtl',
            autoFocus: true,
            maxWidth: options && options.maxWidth || 640,
            restoreFocus: true,
            data: modalData
        });
    };
    DialogService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DialogService_Factory() { return new DialogService(i0.ɵɵinject(i1.MatDialog)); }, token: DialogService, providedIn: "root" });
    return DialogService;
}());
export { DialogService };
