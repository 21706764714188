import { Injectable, Inject } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { filter } from 'rxjs/operators';
import { NotificationsAndUpdates, User, CurrentEmployeesData, RequestStatusData } from '../models/interfaces';
import { ApplicationHttpClient } from '../shared/service/ApplicationHttpClient';

@Injectable({
  providedIn: 'root'
})
export class UserDataService {
  notificationsAndUpdatesSubject = new BehaviorSubject<NotificationsAndUpdates>(null);
  currentEmployeesDataSubject = new BehaviorSubject<CurrentEmployeesData>(null);
  user = new BehaviorSubject<User>(null);
  licenseAplicationsSubject = new BehaviorSubject<RequestStatusData>(null);

  baseUrl: string;

  constructor(private http:ApplicationHttpClient) {
  }

  init() {
    this._getNotificationsAndUpdates();
    this._getCurrentEmployeesStatus();
    this._getLicenseAplicationsStatus();
  }

  private _getNotificationsAndUpdates() {
    this.http
      .Get<NotificationsAndUpdates>(`api/statuses/getMassages`)
      .subscribe(data => {
        this.notificationsAndUpdatesSubject.next(data);
      });
  }

  private _getCurrentEmployeesStatus() {
    this.http
      .Get<CurrentEmployeesData>(`api/statuses/getAllCurrentWorkers?limit=5`)
      .subscribe(data => {
        this.currentEmployeesDataSubject.next(data);
      });
  }

  private _getLicenseAplicationsStatus() {
    this.http
      .Get<RequestStatusData>(`api/statuses/getEmployerApplicationsStatus?limit=3`)
      .subscribe(data => {
        this.licenseAplicationsSubject.next(data);
      });
  }

  getUser = () => {
    const userData = sessionStorage.getItem('userData');

    if(userData){
      return of<User>(JSON.parse(userData))
    }
    else{
      return this.http.Get<User>(`api/account/getUser`)
    }
    
  }

  getUser1 = () => {
    const userData = sessionStorage.getItem('userData');

    if(userData){
      return of<any>(JSON.parse(userData))
    }
    else{
      return this.http.Get<any>(`api/account/getUser`)
    }
    
  }

  getNotificationsAndUpdates(): Observable<NotificationsAndUpdates> {
    return this.notificationsAndUpdatesSubject
      .asObservable()
      .pipe(filter(d => !!d));
  }

  getCurrentEmployeesStatus(): Observable<CurrentEmployeesData> {
    return this.currentEmployeesDataSubject.asObservable().pipe(filter(d => !!d));
  }

  personalInfo(updateUser): Observable<boolean> {
    return this.http.Post<any>(`api/account/applyCommunicationChanges`, updateUser);
  }

  getLicenseAplicationsStatus(): Observable<RequestStatusData> {
    return this.licenseAplicationsSubject.asObservable().pipe(filter(d => !!d));
  }

  getAllMessages():Observable<any>{
    return this.http.Get<any>(`api/alert_messages/active`);
  }
}
