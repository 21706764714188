<div
  class="wrapperInner bgWhite shadow"
  [formGroup]="FormGroupRef"
  *ngIf="FormGroupRef"
>
  <div class="stepTitle subTitle subTitle--blackTextColor">פרטי הבקשה</div>
  <div
    class="stepHeadline textContent textContent--semiBold textContent--blackTextColor"
  >
    נא להזין את פרטי הבקשה
  </div>
  <ul class="gridRow">
    <li class="gridCol gridCol--md6 stepListItem">
      <div class="input input--center stepListItem__title">
        <span class="inputTitle textContent textContent--semiBold colorTextGray">ענף</span>
        <!-- <button
        #popBtn
        (click)="popOverClicked($event , popBtn)"
        type="button"
        class="popOverImg"
        [class.active]="popBtn.clicked"
        [placement]="bottom"
        [popoverClass]="popover"
        [ngbPopover]="popContent3"
      >
        <app-svg svgType="popover"></app-svg>
      </button> -->
      </div>
      <mat-form-field class="appMatOverride" floatLabel="never">
        <mat-label>נא לבחור ענף תעסוקה</mat-label>
        <mat-select
          [disabled]="fields.length <= 1"
          disableOptionCentering="true"
          panelClass="selectPanelClass"
          formControlName="fields"
          (selectionChange)="checkField($event)">
          <mat-option *ngFor="let field of fields" [value]="field">
            {{ field.name }} - {{ field.subName }}</mat-option>
        </mat-select>
        <!-- <mat-error
          *ngIf="
            item.controls.item_category.invalid &&
            item.controls.item_category.touched
          "
          >{{ "forms.validationErrors.required" | translate }}</mat-error
        > -->
      </mat-form-field>
      <div>
        <button
          *ngIf="buildingPermitService.allowBuildingPermitUpload()"
          (click)="openBuildingPermitModal()"
          type="button"
          class="btn btn--regularBtn btn--blueTextColor btn--semiBold">
          להעלאת
          קובץ
        </button>

      </div>
      <ng-container *ngIf="FormGroupRef.get('renovating') && !isIsraelRegion">
        <div class="stepListItem__title">
          <span
            class="inputTitle textContent textContent--semiBold colorTextGray">
            האם בתחום השיפוצים?
          </span>
        </div>
        <mat-button-toggle-group
          #otpOption="matButtonToggleGroup"
          class="appMatOverride authSelect optSelect"
          (change)="setRenovatingValue($event)">

          <mat-button-toggle [value]="true">כן</mat-button-toggle>
          <mat-button-toggle [value]="false">לא</mat-button-toggle>
        </mat-button-toggle-group>
<!--        <span   class="textContent textContent&#45;&#45;semiBold colorTextRed validationError">-->
<!--        </span>-->
        <div class="appMatOverride">
          <mat-error *ngIf="FormGroupRef.get('renovating').errors?.required && submitted">
            שדה חובה
          </mat-error>
        </div>

      </ng-container>
      <ng-container *ngIf="FormGroupRef.get('loging') && isIsraelRegion">
        <div class="stepListItem__title">
          <span
            class="inputTitle textContent textContent--semiBold colorTextGray">
            לינה בישראל
          </span>
        </div>
        <mat-button-toggle-group
          #otpOption="matButtonToggleGroup"
          class="appMatOverride authSelect optSelect"
          (change)="setLogingValue($event)">

          <mat-button-toggle [value]="1">כן</mat-button-toggle>
          <mat-button-toggle [value]="0">לא</mat-button-toggle>
        </mat-button-toggle-group>
        <div class="appMatOverride">
          <mat-error *ngIf="FormGroupRef.get('loging').errors?.required && submitted">
            שדה חובה
          </mat-error>
        </div>

      </ng-container>
      <span *ngIf="showBuildingPermitError" class="textContent textContent--semiBold colorTextRed validationError">
        יש לצרף מסמך היתר בניה בבחירת ענף זה
       </span>
    </li>
    <li class="gridCol gridCol--md6 stepListItem">
      <div class="input input--center stepListItem__title">
        <span
          class="inputTitle textContent textContent--semiBold colorTextGray"
        >
          שעות עבודה מבוקשות
        </span>
      </div>
      <mat-form-field
        class="appMatOverride"
        [ngClass]="{ inputBlack: FormGroupRef.get('working_hours').value?.isDefault}"
        floatLabel="never">
        <mat-select
          disableOptionCentering="true"
          panelClass="selectPanelClass"
          formControlName="working_hours"
          (selectionChange)="checkWorkingHoures($event)"
        >
          <mat-option *ngFor="let hour of workingHours" [value]="hour">
            {{ hour.max }} - {{ hour.min }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <div class="stepListItem__fieldNote colorTextBlack"  *ngIf="FormGroupRef.get('files')" >
        אם ברצונך לשנות את ברירת המחדל, יש להוסיף קובץ אישור שעות עבודה
      </div>
      <ng-container *ngIf="FormGroupRef.get('files')">
        <div class="documentsWrapper">
          <app-upload-file
            [formControlRef]="FormGroupRef.get('files')"
            [downloadDocument]="downloadDocuments.workingHours"
            [requiredFileUpload]="showWorkingHoursFileWarning"
            (onFileUpload)="uploadFileWorkingHoursFile()"
          ></app-upload-file>
        </div>
      </ng-container>
    </li>
    <li class="gridCol gridCol--md6 stepListItem"  *ngIf="!isIsraelRegion">
      <div class="input input--center stepListItem__title">
        <span
          class="inputTitle textContent textContent--semiBold colorTextGray"
        >
          מקום עבודה מבוקש
        </span>
        <button
          #popBtn1
          type="button"
          class="popOverImg"
          [class.active]="popBtn1.clicked"
          placement="bottom"
          popoverClass="popover"
          [class.open]=""
          [ngbPopover]="popContent1"
          [autoClose]="true"
        >
          <app-svg svgType="popover"></app-svg>
        </button>
      </div>
      <mat-form-field class="appMatOverride autoCompleteField" floatLabel="never">
        <input
          type="text"
          placeholder="נא להזין מקום עבודה מבוקש"
          aria-label="הקלידו כדי לחפש מקום עבודה מבוקש ובחרו מהתוצאות המוצעות"
          matInput
          [formControl]="wantedWorkplaceSearch"
          [matAutocomplete]="auto"
        />
        <mat-autocomplete
          #auto="matAutocomplete"
          [displayWith]="displayFn"
          (optionSelected)="addWorkPlaces($event)"
          (closed)="panelClosed($event)"
        >
          <mat-option *ngIf="isLoading" class="is-loading">
            <!-- <mat-spinner diameter="50"></mat-spinner> -->
            <div class="spinner spinner--autoCompleteField"></div>
          </mat-option>
          <ng-container *ngIf="!isLoading">
            <mat-option
              *ngFor="let option of wantedWorkPlacesOptions | async"
              [value]="option"
              class="appMatOverride mat-option"
            >
              <span>{{ option.name }}</span>
              <!-- <small> | {{ option.region_name }}</small> -->
            </mat-option>
          </ng-container>
        </mat-autocomplete>
        <mat-hint *ngIf="showNotSelectedWarning">
          <mat-error>לא נבחר מקום עבודה מבוקש</mat-error>
        </mat-hint
        >
        <mat-hint *ngIf="showNoResultError && !showNotSelectedWarning">
          <mat-error>לא נמצאו תוצאות. אנא חפש שנית</mat-error>
        </mat-hint>
      </mat-form-field>
      <ng-template #popContent1>
        <div class="popoverMessage">
          בחירת מקום העבודה הראשי בו יועסק העובד ובהתאם למסמכים המצורפים.לבקשה.
        </div>
      </ng-template>
      <div class="itemsSelect">
        <div class="appMatOverride itemsSelect__checkBoxOption">
          <div class="checkBoxWrapper">
            <mat-checkbox
              class="checkboxSelect requestCheckbox"
              formControlName="outside_locality"
            >
            </mat-checkbox>
            <span
              class="textContent textContent--semiBold colorTextGray checkBoxWrapper__label"
              >מחוץ ליישוב</span
            >
            <button
              #popBtn2
              type="button"
              class="popOverImg"
              [class.active]="popBtn2.clicked"
              placement="bottom"
              popoverClass="popover"
              [ngbPopover]="popContent2"
              [autoClose]="true"
            >
              <app-svg svgType="popover"></app-svg>
            </button>
            <ng-template #popContent2>
              <div class="popoverMessage">
                העסקת תושב פלסטיני <b class="bold">מחוץ</b> לתחומי הישוב ובסמוך
                לו.
              </div>
            </ng-template>
          </div>
          <div class="checkBoxWrapper">
            <mat-checkbox
              class="checkboxSelect requestCheckbox"
              formControlName="industrial_area"
            >
            </mat-checkbox>
            <span
              class="textContent textContent--semiBold colorTextGray checkBoxWrapper__label"
              >אזור תעשייה</span
            >
            <button
              #popBtn3
              type="button"
              class="popOverImg"
              [class.active]="popBtn3.clicked"
              placement="bottom"
              popoverClass="popover"
              [ngbPopover]="popContent3"
              [autoClose]="true"
            >
              <app-svg svgType="popover"></app-svg>
            </button>
            <ng-template #popContent3>
              <div class="popoverMessage">
                העסקת תושב פלסטיני במתחם תעשייה/עסקים/מוסכים/חנויות/מעבדות וכו'
              </div>
            </ng-template>
          </div>
        </div>
        <div class="appMatOverride itemsSelect__checkBoxResult">
          <div class="bubblesWrapper">
            <div
              class="inputBubble"
              *ngFor="let settlement of FormGroupRef.get('settlements')['controls']; let i = index">
              <button
                class="deleteBtn"
                (click)="deleteSettlement(i, settlement.value)"
              ></button>
              <div class="textContent textContent--semiBold colorTextBlack">
                {{ settlement.value.name }}
              </div>
            </div>
          </div>
          <mat-error *ngIf="FormGroupRef.get('settlements').errors?.maxlength"
            >לא ניתן לבחור יותר מ 4 יישובים. ניתן למחוק יישוב שנבחר ולאחר מכן
            לבחור מחדש
          </mat-error>
        </div>
      </div>
    </li>
    <li class="gridCol gridCol--md6 stepListItem" *ngIf="!isIsraelRegion">
      <div class="input input--center stepListItem__title">
        <span
          class="inputTitle textContent textContent--semiBold colorTextGray"
        >
          משך הרשיון המבוקש
        </span>
        <button
          #popBtn4
          type="button"
          class="popOverImg"
          [class.active]="popBtn4.clicked"
          placement="bottom"
          popoverClass="popover"
          [ngbPopover]="popContent4"
          [autoClose]="true"
        >
          <app-svg svgType="popover"></app-svg>
        </button>
      </div>
      <mat-form-field
        class="appMatOverride"
        [ngClass]="{
          inputRed: FormGroupRef.get('license_duration').errors?.max
        }"
        floatLabel="never"
      >
        <mat-label>נא להזין מס׳ ימים מבוקשים</mat-label>
        <input
          matInput
          formControlName="license_duration"
          placeholder=""
          type="tel"
        />
        <mat-error *ngIf="FormGroupRef.get('license_duration').errors?.max">
          לא ניתן לבקש יותר מ {{ selectedField.licenseLimitation }} ימים
        </mat-error>
        <mat-error *ngIf="FormGroupRef.get('license_duration').errors?.pattern">
          ניתן להזין רק מספרים
        </mat-error>
        <mat-error *ngIf="FormGroupRef.get('license_duration').errors?.min">
          נדרש להזין 1 ימים או יותר
        </mat-error>
      </mat-form-field>
      <ng-template #popContent4>
        <div class="popoverMessage">
          בחירת משך זמן העסקת העובד הפלסטיני, לא יעלה על הקבוע בנהלי תעסוקה.
        </div>
      </ng-template>
    </li>
    <li class="gridCol gridCol--md6 stepListItem"  *ngIf="!isIsraelRegion">
      <div class="input input--center stepListItem__title">
        <span class="inputTitle textContent textContent--semiBold colorTextGray">לשכת תעסוקה מטפלת</span>
        <button
          #popBtn5
          type="button"
          class="popOverImg"
          [class.active]="popBtn5.clicked"
          placement="bottom"
          popoverClass="popover"
          [ngbPopover]="popContent5"
          [autoClose]="true"
        >
          <app-svg svgType="popover"></app-svg>
        </button>
      </div>
      <mat-form-field class="appMatOverride" floatLabel="never">
        <mat-label>לשכת תעסוקה מטפלת</mat-label>
        <mat-select
          [disabled]="employmentBureaus.length <= 1"
          disableOptionCentering="true"
          panelClass="selectPanelClass"
          formControlName="employment_bureau"
        >
          <mat-option
            *ngFor="let Bureau of employmentBureaus"
            [value]="Bureau"
            >{{ Bureau.name }}</mat-option
          >
        </mat-select>
        <!-- <mat-error
            *ngIf="
              item.controls.item_category.invalid &&
              item.controls.item_category.touched
            "
            >{{ "forms.validationErrors.required" | translate }}</mat-error
          > -->
      </mat-form-field>
      <ng-template #popContent5>
        <div class="popoverMessage">
          בחירת הלשכה בה תטופל הבקשה,במידה וקיימים רשיונות ממספר לשכות
        </div>
      </ng-template>
    </li>
  </ul>

  <stepper-buttons-component
    [hasBackStep]="hasBack()"
    [hasNextStep]="hasNext()"
    (onStepGoBack)="goToBackStep()"
    (onStepGoNext)="submit()"
  ></stepper-buttons-component>
</div>
