/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./maintenance.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./maintenance.component";
var styles_MaintenanceComponent = [i0.styles];
var RenderType_MaintenanceComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MaintenanceComponent, data: {} });
export { RenderType_MaintenanceComponent as RenderType_MaintenanceComponent };
export function View_MaintenanceComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "card-maintenace"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h2", [["class", "header-maintenace mb-4"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u05D4\u05D0\u05EA\u05E8 \u05DE\u05D5\u05E9\u05D1\u05EA \u05DC\u05E6\u05D5\u05E8\u05DB\u05D9 \u05EA\u05D7\u05D6\u05D5\u05E7\u05D4"])), (_l()(), i1.ɵeld(3, 0, null, null, 0, "img", [["src", "../../../assets/imgs/no-entry.svg"]], null, null, null, null, null))], null, null); }
export function View_MaintenanceComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-maintenance", [], null, null, null, View_MaintenanceComponent_0, RenderType_MaintenanceComponent)), i1.ɵdid(1, 114688, null, 0, i2.MaintenanceComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MaintenanceComponentNgFactory = i1.ɵccf("app-maintenance", i2.MaintenanceComponent, View_MaintenanceComponent_Host_0, {}, {}, []);
export { MaintenanceComponentNgFactory as MaintenanceComponentNgFactory };
