import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
var QueriesComponent = /** @class */ (function () {
    function QueriesComponent(queriesService) {
        this.queriesService = queriesService;
    }
    QueriesComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            var _this = this;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.queriesService.getQueries()];
                    case 1:
                        _a.queries = _b.sent();
                        this.queries.forEach(function (q) {
                            q.label = q.name;
                            q.command = _this.onSelectQuery();
                        });
                        this.onSelectQuery()({ item: this.queries[0] });
                        return [2 /*return*/];
                }
            });
        });
    };
    QueriesComponent.prototype.onSelectQuery = function () {
        var _this = this;
        return function (event) {
            _this.currentQuery = event.item;
            _this.resetInputValues();
        };
    };
    QueriesComponent.prototype.sendQuery = function () {
        var _this = this;
        this.response = null;
        this.queriesService.sendQuery(this.currentQuery._id, this.inputsValues).subscribe(function (res) {
            _this.response = res.data;
            _this.resetInputValues();
        });
    };
    QueriesComponent.prototype.resetInputValues = function () {
        this.inputsValues = {};
    };
    return QueriesComponent;
}());
export { QueriesComponent };
