<div class="modalWrapper">
  <button mat-button mat-dialog-close class="closeBtn"></button>
  <h2
    mat-dialog-title
    class="subTitle subTitle--semiBold subTitle--blueTextColor"
  >
    מצטערים, לא ניתן להמשיך בהגשת הבקשה
  </h2>
  <perfect-scrollbar  [config]="config"
    style="position: relative; max-width: 600px; max-height: 200px;"
  >
    <p class="textContent textContent--regular">
      הגעת למכסת העובדים ל<span
        class="textContent textContent--bold textContent--grayTextColor"
        >{{ field.name }} ({{ field.employmentLimitation }} עובדים)</span
      >. על מנת לבקש רישיונות נוספים לענף זה, עליך לבטל רישיונות של עובדים
      נוכחיים. ניתן לעשות זאת בעמוד
      <span class="textContent textContent--bold textContent--grayTextColor"
        >צפייה בבקשות/רשיונות</span
      >
      אנו ממליצים לך לשמור בקשה זו לפני יציאתך מהתהליך.
    </p>
  </perfect-scrollbar>
  <!-- <div *ngIf="showUploadBuildingPermit()"> -->
  <div *ngIf="documentRef">
    <span class="textContent textContent--regular uploadFile">
      באפשרותך להעלות מסמך היתר בנייה ולהוסיף 4 עובדים נוספים למאגר העובדים שלך
    </span>
    <app-upload-file
      [formControlRef]="documentRef"
      [downloadDocument]="downloadDocument"
    ></app-upload-file>
  </div>
  <ul class="contactDetailsList">
    <li class="contactDetailsListItem">
      <img src="./assets/imgs/phone-icon.png" />
      <!-- <a href="tel:{{ contactItem.tel }}" class="listItemLink">
        {{ contactItem.tel }}
      </a> -->
      <a
        href="tel:*4943"
        class="listItemLink textContent textContent--regular textContent--grayTextColor"
      >
        *4943
      </a>
    </li>
    <li class="contactDetailsListItem">
      <img src="./assets/imgs/email-icon.png" />
      <!-- <a href="mailto:{{ contactItem.email }}" class="listItemLink">
        {{ contactItem.email }}
      </a> -->
      <a
        href="mailto:mnz@mgar.co.il"
        class="listItemLink textContent textContent--regular textContent--grayTextColor">
      mnz@mgar.co.il
      </a>
    </li>
  </ul>
  <mat-dialog-actions class="modalConfirm">
    <button
      mat-button
      mat-dialog-close
      class="btn btn--blue btn--mobileWidthBtn btn--desktopWidthBtn"
    >
      סגירה
    </button>
  </mat-dialog-actions>
</div>
