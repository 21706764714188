/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./terms-and-conditions.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/primeng/components/checkbox/checkbox.ngfactory";
import * as i3 from "primeng/components/checkbox/checkbox";
import * as i4 from "@angular/forms";
import * as i5 from "@angular/common";
import * as i6 from "./terms-and-conditions.component";
import * as i7 from "../../services/login.service";
import * as i8 from "@angular/router";
import * as i9 from "../../services/auth.service";
import * as i10 from "../../services/license-service";
var styles_TermsAndConditionsComponent = [i0.styles];
var RenderType_TermsAndConditionsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TermsAndConditionsComponent, data: {} });
export { RenderType_TermsAndConditionsComponent as RenderType_TermsAndConditionsComponent };
function View_TermsAndConditionsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "d-flex flex-grow-1 flex-column overflow-auto px-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 8, "div", [["class", "d-flex  align-items-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 5, "p-checkbox", [["class", "position-relative"], ["label", " "]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "onChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ngModelChange" === en)) {
        var pd_0 = ((_co.approvedTerms = $event) !== false);
        ad = (pd_0 && ad);
    } if (("onChange" === en)) {
        var pd_1 = (_co.approved() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_Checkbox_0, i2.RenderType_Checkbox)), i1.ɵdid(4, 49152, null, 0, i3.Checkbox, [i1.ChangeDetectorRef], { binary: [0, "binary"], label: [1, "label"] }, { onChange: "onChange" }), i1.ɵprd(1024, null, i4.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.Checkbox]), i1.ɵdid(6, 671744, null, 0, i4.NgModel, [[8, null], [8, null], [8, null], [6, i4.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i4.NgControl, null, [i4.NgModel]), i1.ɵdid(8, 16384, null, 0, i4.NgControlStatus, [[4, i4.NgControl]], null, null), (_l()(), i1.ɵeld(9, 0, null, null, 1, "span", [["class", "mr-2"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u05E7\u05E8\u05D0\u05EA\u05D9 \u05D0\u05EA \u05EA\u05E7\u05E0\u05D5\u05DF \u05D4\u05D0\u05EA\u05E8"]))], function (_ck, _v) { var _co = _v.component; var currVal_8 = true; var currVal_9 = " "; _ck(_v, 4, 0, currVal_8, currVal_9); var currVal_10 = _co.approvedTerms; _ck(_v, 6, 0, currVal_10); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.termsConditions.contents; _ck(_v, 1, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 8).ngClassUntouched; var currVal_2 = i1.ɵnov(_v, 8).ngClassTouched; var currVal_3 = i1.ɵnov(_v, 8).ngClassPristine; var currVal_4 = i1.ɵnov(_v, 8).ngClassDirty; var currVal_5 = i1.ɵnov(_v, 8).ngClassValid; var currVal_6 = i1.ɵnov(_v, 8).ngClassInvalid; var currVal_7 = i1.ɵnov(_v, 8).ngClassPending; _ck(_v, 3, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }); }
function View_TermsAndConditionsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "errorMsg errorMsg--center"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u05E2\u05E7\u05D1 \u05EA\u05E7\u05DC\u05D4 \u05D6\u05DE\u05E0\u05D9\u05EA \u05D0\u05D9\u05DF \u05D1\u05D0\u05E4\u05E9\u05E8\u05D5\u05EA\u05D9\u05E0\u05D5 \u05DC\u05D1\u05E6\u05E2 \u05D0\u05EA \u05D4\u05E4\u05E2\u05D5\u05DC\u05D4"]))], null, null); }
export function View_TermsAndConditionsComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { confirmBtnRef: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h2", [["class", "d-flex"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", "\n"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TermsAndConditionsComponent_1)), i1.ɵdid(4, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TermsAndConditionsComponent_2)), i1.ɵdid(6, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.termsConditions; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.isErrorShow; _ck(_v, 6, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.isUserAuth ? "\u05EA\u05E0\u05D0\u05D9 \u05E9\u05D9\u05DE\u05D5\u05E9" : "\u05EA\u05E7\u05E0\u05D5\u05DF \u05D5\u05EA\u05E0\u05D0\u05D9 \u05D4\u05E9\u05D9\u05DE\u05D5\u05E9 \u05D1\u05D0\u05EA\u05E8"); _ck(_v, 2, 0, currVal_0); }); }
export function View_TermsAndConditionsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-terms-and-conditions", [], null, null, null, View_TermsAndConditionsComponent_0, RenderType_TermsAndConditionsComponent)), i1.ɵdid(1, 4308992, null, 0, i6.TermsAndConditionsComponent, [i7.LoginService, i8.Router, i9.AuthService, i10.LicenseService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TermsAndConditionsComponentNgFactory = i1.ɵccf("app-terms-and-conditions", i6.TermsAndConditionsComponent, View_TermsAndConditionsComponent_Host_0, {}, { TermsApproved: "TermsApproved" }, []);
export { TermsAndConditionsComponentNgFactory as TermsAndConditionsComponentNgFactory };
