<div class="statusDocumentsModalWrapper generalModalWrapper">
  <button mat-button mat-dialog-close class="closeBtn"></button>
  <h2 class="subTitle subTitle--semiBold subTitle--blueTextColor">{{header}}</h2>

    <p class="textContent textContent--regular colorTextGray" [innerHTML]="message"></p>

  <mat-dialog-actions class="modalConfirm stepsBtns">
    <button mat-button class="btn btn--blue btn--mobileWidthBtn btn--desktopWidthBtn " (click)="confirm()">{{ok}}</button>
    <button mat-button class="btn colorTextBlue btn--regularBtn" (click)="close()">{{cancel}}</button>
  </mat-dialog-actions>
</div>
