<div class="wrap-all-login d-flex">
  <div class="bg-login h-100 d-none d-sm-block">
    <div class="row h-100 w-100">
      <div class="col-4">
        <div class="row h-100">
          <div class="col-1 lightBlue-bg "></div>
          <div class="col-11 darkBlue-bg"></div>
        </div>
      </div>
      <div class="col-8 lightGray-bg"></div>


    </div>


  </div>
  <div class="d-flex flex-column wrap-login h-100 m-auto">
    <div class=" d-none d-sm-flex justify-content-end mt-4">
      <button class="circle-btn"> <i class="icon-accessibility"></i></button>
    </div>
    <div class="d-flex flex-grow-1 justify-content-center align-items-center">
      <div class="card-login">
        <div class="row h-100 w-100 m-0">
          <div class="col-12 col-md-6 blue-bg d-flex flex-column align-items-center right-side">
            <h1>ברוכים הבאים</h1>
            <h2>לאתר התעסוקה של מתפ"ש</h2>
            <img src="../../../assets/imgs/LogoMatpash.svg" alt="לוגו המנהל האזרחי">

          </div>
          <div class="col-12 col-md-6 left-side">
            <app-auth-step-one *ngIf="showStep" [@slideInOut] (goToLoginStep)="moveToStep($event)" class="h-100">
            </app-auth-step-one>
            <app-auth-step-two *ngIf="!showStep" [@slideInOut] (goToLoginStep)="moveToStep($event)" class="h-100">
            </app-auth-step-two>
          </div>
        </div>

      </div>
    </div>
    <div class="footer-login d-none d-sm-flex justify-content-end">

    <!--  <span>זמינים לכל בעיה *4943</span>-->&nbsp;&nbsp;|&nbsp;&nbsp;
      <a href="" target="_blank">אתר המנהל האזרחי</a>&nbsp;&nbsp;|&nbsp;&nbsp;
      <a href="" target="_blank">תנאי שימוש</a>
    </div>

  </div>
</div>