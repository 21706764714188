/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./navigation-tabs.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "@angular/material/tabs";
import * as i5 from "@angular/cdk/platform";
import * as i6 from "@angular/material/core";
import * as i7 from "@angular/cdk/a11y";
import * as i8 from "@angular/platform-browser/animations";
import * as i9 from "../../../../node_modules/@angular/material/tabs/typings/index.ngfactory";
import * as i10 from "@angular/cdk/bidi";
import * as i11 from "@angular/cdk/scrolling";
import * as i12 from "./navigation-tabs.component";
import * as i13 from "@angular/cdk/layout";
import * as i14 from "../../services/user-data.service";
var styles_NavigationTabsComponent = [i0.styles];
var RenderType_NavigationTabsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NavigationTabsComponent, data: {} });
export { RenderType_NavigationTabsComponent as RenderType_NavigationTabsComponent };
function View_NavigationTabsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "a", [["class", "d-flex flex-md-column mat-tab-link"], ["mat-tab-link", ""], ["routerLinkActive", ""]], [[1, "target", 0], [8, "href", 4], [1, "aria-current", 0], [1, "aria-disabled", 0], [1, "tabIndex", 0], [2, "mat-tab-disabled", null], [2, "mat-tab-label-active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.close_Menu() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 671744, [[3, 4]], 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵdid(2, 1720320, [["rla", 4]], 2, i2.RouterLinkActive, [i2.Router, i1.ElementRef, i1.Renderer2, [2, i2.RouterLink], [2, i2.RouterLinkWithHref]], { routerLinkActive: [0, "routerLinkActive"] }, null), i1.ɵqud(603979776, 2, { links: 1 }), i1.ɵqud(603979776, 3, { linksWithHrefs: 1 }), i1.ɵdid(5, 147456, [[1, 4]], 0, i4.MatTabLink, [i4.MatTabNav, i1.ElementRef, i1.NgZone, i5.Platform, [2, i6.MAT_RIPPLE_GLOBAL_OPTIONS], [8, null], i7.FocusMonitor, [2, i8.ANIMATION_MODULE_TYPE]], { disabled: [0, "disabled"], active: [1, "active"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 0, "i", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "span", [["class", "link link--underLineHover"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ""]))], function (_ck, _v) { var currVal_7 = _v.context.$implicit.path; _ck(_v, 1, 0, currVal_7); var currVal_8 = ""; _ck(_v, 2, 0, currVal_8); var currVal_9 = _v.context.$implicit.isDisabled; var currVal_10 = i1.ɵnov(_v, 2).isActive; _ck(_v, 5, 0, currVal_9, currVal_10); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).target; var currVal_1 = i1.ɵnov(_v, 1).href; var currVal_2 = (i1.ɵnov(_v, 5).active ? "page" : null); var currVal_3 = i1.ɵnov(_v, 5).disabled; var currVal_4 = i1.ɵnov(_v, 5).tabIndex; var currVal_5 = i1.ɵnov(_v, 5).disabled; var currVal_6 = i1.ɵnov(_v, 5).active; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_11 = i1.ɵinlineInterpolate(1, "", _v.context.$implicit.icon, " navTabIcon"); _ck(_v, 6, 0, currVal_11); var currVal_12 = _v.context.$implicit.label; _ck(_v, 8, 0, currVal_12); }); }
export function View_NavigationTabsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "section", [["class", "navTabsSection"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "navTabWrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 5, "div", [["class", "navTab"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 4, "nav", [["class", "mat-tab-nav-bar mat-tab-header"], ["mat-tab-nav-bar", ""]], [[2, "mat-tab-header-pagination-controls-enabled", null], [2, "mat-tab-header-rtl", null], [2, "mat-primary", null], [2, "mat-accent", null], [2, "mat-warn", null]], null, null, i9.View_MatTabNav_0, i9.RenderType_MatTabNav)), i1.ɵdid(4, 7520256, null, 1, i4.MatTabNav, [i1.ElementRef, [2, i10.Directionality], i1.NgZone, i1.ChangeDetectorRef, i11.ViewportRuler, [2, i5.Platform], [2, i8.ANIMATION_MODULE_TYPE]], null, null), i1.ɵqud(603979776, 1, { _items: 1 }), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_NavigationTabsComponent_1)), i1.ɵdid(7, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_5 = _co.navLinks; _ck(_v, 7, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 4)._showPaginationControls; var currVal_1 = (i1.ɵnov(_v, 4)._getLayoutDirection() == "rtl"); var currVal_2 = ((i1.ɵnov(_v, 4).color !== "warn") && (i1.ɵnov(_v, 4).color !== "accent")); var currVal_3 = (i1.ɵnov(_v, 4).color === "accent"); var currVal_4 = (i1.ɵnov(_v, 4).color === "warn"); _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }); }
export function View_NavigationTabsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-navigation-tabs", [], null, null, null, View_NavigationTabsComponent_0, RenderType_NavigationTabsComponent)), i1.ɵdid(1, 114688, null, 0, i12.NavigationTabsComponent, [i2.Router, i13.BreakpointObserver, i14.UserDataService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NavigationTabsComponentNgFactory = i1.ɵccf("app-navigation-tabs", i12.NavigationTabsComponent, View_NavigationTabsComponent_Host_0, {}, { closeTabs: "closeTabs" }, []);
export { NavigationTabsComponentNgFactory as NavigationTabsComponentNgFactory };
