<!-- <div class="wizardStep" [formGroup]="FormGroupRef" *ngIf="FormGroupRef"> -->
<section class="addDocumentsSection bgWhite shadow">
  <div class="titleContainer">
    <h2 class="stepTitle subTitle  colorTextBlack">הוספת מסמכים רלוונטיים</h2>
    <div class="stepHeadline textContent colorTextBlack">
      <p class="textContent--semiBold stepTopContent">
        יש להוסיף את המסמכים הנ"ל על מנת להמשיך בבקשה
      </p>
    </div>
  </div>
  <div class="generaltableWrapper">
    <div class="titleContainer">
      <h2 class="subTitle subTitle--semiBold colorTextBlue">
        מסמכים נדרשים לענף {{ field.name }}
      </h2>
    </div>
    <table class="generalTable documentsTable">
      <tbody>
        <tr class="tableRow bgWhite shadow" *ngFor="let doc of field.documents; let i = index">
          <td class="tableData"></td>
          <td class="tableData colorTextBlack">
            <div class="tableDataInside tableDataInside--bold">
              {{hideType(doc.name)}}
            </div>
            <div *ngIf="doc.description">{{doc.description}}</div>
          </td>
          <td class="tableData fileUploadedData">
            <app-upload-file
              [formControlRef]="FormArrayRef.at(i)"
              [downloadDocument]="doc"
              [preventFileDelete]="preventFileDelete(FormArrayRef.at(i))"
              [requiredFileUpload]="doc.required && submitted">
            </app-upload-file>
          </td>
          <td class="tableData"></td>
        </tr>
      </tbody>
    </table>
  </div>


  <stepper-buttons-component [hasBackStep]="hasBack()" [hasNextStep]="hasNext()" (onStepGoBack)="goToBackStep()"
    (onStepGoNext)="submit()"></stepper-buttons-component>


  <!-- <div class="generaltableWrapper">
    <div class="titleContainer">
      <h2 class="subTitle subTitle--semiBold colorTextBlue">
        מסמכים נדרשים לתעסוקה בישראל
      </h2>
    </div>

    <table class="generalTable documentsTable">
      <tbody>
        <tr class="tableRow  bgWhite shadow">
          <td class="tableData  "></td>
          <td class="tableData colorTextBlack">
            <div class="tableDataInside tableDataInside--bold">
              אישור משרד הביטחון
            </div>
          </td>

          <td class="tableData fileUploadedData">
            <div *ngIf="hasUploadFile">
              <ng-container *ngIf="hasUploaded; then uploadFile">
              </ng-container>
              <ng-template #uploadFile>
                <div class="uploadLinkWrapper">
                  <div class="uploadLink">
                    <input
                      type="file"
                      name="file"
                      id="file"
                      class="inputfile"
                    />
                    <label for="file" class="">
                      <img
                        class="iconImg"
                        src="assets/imgs/upload.svg"
                        alt=""
                      />
                      <span
                        class="uploadLink__text link  link--underLine link--semiBold"
                        >העלאת מסמך</span
                      >
                    </label>
                  </div>
                </div>
                <div class="uploadLinkWrapper">
                  <a href="employee?fileUrl" download class="uploadLink">
                    <img
                      class="iconImg"
                      src="assets/imgs/download.svg"
                      alt=""
                    />
                    <span
                      class="uploadLink__text link  link--underLine link--semiBold"
                      >להורדת מסמך</span
                    >
                  </a>
                </div>
              </ng-template>
            </div>
          </td>

          <td class="tableData  "></td>
        </tr>
        <tr class="tableRow bgWhite shadow">
          <td class="tableData  "></td>
          <td class="tableData colorTextBlack">
            <div class="tableDataInside  tableDataInside--bold">
              היסטוריית מעברים לכלל העובדים
            </div>
          </td>

          <td class="tableData fileUploadedData">
            <div *ngIf="hasUploadFile">
              <ng-container *ngIf="hasUploaded; then uploadFile">
              </ng-container>
              <ng-template #uploadFile>
                <div class="uploadLinkWrapper">
                  <div class="uploadLink">
                    <input
                      type="file"
                      name="file"
                      id="file"
                      class="inputfile"
                    />
                    <label for="file" class="">
                      <img
                        class="iconImg"
                        src="assets/imgs/upload.svg"
                        alt=""
                      />
                      <span
                        class="uploadLink__text link  link--underLine link--semiBold"
                        >העלאת מסמך</span
                      >
                    </label>
                  </div>
                </div>
                <div class="uploadLinkWrapper">
                  <a href="employee?fileUrl" download class="uploadLink">
                    <img
                      class="iconImg"
                      src="assets/imgs/download.svg"
                      alt=""
                    />
                    <span
                      class="uploadLink__text link  link--underLine link--semiBold"
                      >להורדת מסמך</span
                    >
                  </a>
                </div>
              </ng-template>
            </div>
          </td>

          <td class="tableData  "></td>
        </tr>
      </tbody>
    </table>
  </div> -->

</section>
