import * as i0 from "@angular/core";
import * as i1 from "../shared/service/ApplicationHttpClient";
var TransitionHistoryService = /** @class */ (function () {
    function TransitionHistoryService(http) {
        this.http = http;
    }
    TransitionHistoryService.prototype.getTransitionByEmployer = function (data) {
        data.token = sessionStorage.getItem('token');
        return this.http.Post("api/check_point/", data);
    };
    TransitionHistoryService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TransitionHistoryService_Factory() { return new TransitionHistoryService(i0.ɵɵinject(i1.ApplicationHttpClient)); }, token: TransitionHistoryService, providedIn: "root" });
    return TransitionHistoryService;
}());
export { TransitionHistoryService };
