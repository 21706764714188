import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { StepComponentBase } from '../wizard-step-component-base/step-component-base';
import { WelcomeModalComponent } from '../../shared/welcome-modal/welcome-modal.component';
import { takeUntil } from "rxjs/operators";
import regionsTypes from '../../../shared/util/regionTypes';
var TransactionAreaStepComponent = /** @class */ (function (_super) {
    tslib_1.__extends(TransactionAreaStepComponent, _super);
    function TransactionAreaStepComponent(stepsPagesService, dialog, userDataService, formService, wizardUserDateService) {
        var _this = _super.call(this, stepsPagesService, dialog) || this;
        _this.userDataService = userDataService;
        _this.formService = formService;
        _this.wizardUserDateService = wizardUserDateService;
        _this.FormGroupRef = null;
        _this.allowIsrael = false;
        _this.allowSettlements = false;
        _this.regions = [
            { lable: 'ישראל', value: regionsTypes.ISRAEL, checked: false, disabled: false, icon: 'icon-Israel' },
            { lable: 'התישבות', value: regionsTypes.SETTELMENTS, checked: false, disabled: false, icon: 'icon-Settlement' }
        ];
        _this.FormGroupRef = _this.formService.getFormGroupRefByName('transactionArea');
        return _this;
    }
    TransactionAreaStepComponent.prototype.ngOnInit = function () {
        var _this = this;
        _super.prototype.ngOnInit.call(this);
        this.userDataService.getUser().pipe(takeUntil(this.componentDestroyed)).subscribe(function (user) {
            var doc = _this.wizardUserDateService.getDownloadDocumentByName('signatureForm');
            _this.wizardUserDateService.requestData.pipe(takeUntil(_this.componentDestroyed)).subscribe(function (requestData) {
                _this.allowIsrael = requestData.allowIsrael === true && !requestData.preventionToSubmitInIsrael;
                _this.showModalDisableIsrael = requestData.preventionToSubmitInIsrael;
                _this.allowSettlements = requestData.allowSettlement;
                var regionDefaultValue = _this.allowIsrael ? regionsTypes.ISRAEL : (requestData.allowSettlement ? regionsTypes.SETTELMENTS : null);
                _this.regions = [
                    { lable: 'ישראל', value: regionsTypes.ISRAEL, checked: false, disabled: !_this.allowIsrael, icon: 'icon-Israel' },
                    { lable: 'התישבות', value: regionsTypes.SETTELMENTS, checked: false, disabled: !requestData.allowSettlement, icon: 'icon-Settlement' }
                ];
                var region = _this.regions.find(function (r) { return r.value === regionDefaultValue; });
                if (region) {
                    region.checked = true;
                }
                if (regionDefaultValue !== null) {
                    _this.FormGroupRef.get('region').setValue(regionDefaultValue);
                }
                var unavailableFieldData = {
                    setllementsFields: requestData.unAvailableSettlementsFields,
                    allowIsrael: requestData.allowIsrael,
                    allowSettlement: requestData.allowSettlement
                };
                console.log("subscribe - wizardUserDateService");
                var data = { user: user, document: doc, unavailableField: unavailableFieldData };
                _this.openModal(WelcomeModalComponent, data, {
                    disableClose: true,
                    maxWidth: 640,
                    panelClass: ['generalModal', 'bigPanel']
                });
            });
        });
    };
    TransactionAreaStepComponent.prototype.ngAfterViewInit = function () {
        // const dom: HTMLElement = this.elementRef.nativeElement;
        // const elements = dom.querySelectorAll('.active')
        // this.elementRef.forEach((domElement)=>{
        //   console.log(domElement)
        //   if (domElement){}
        // domElement.classList.add('myNewClassToAdd');
        //  })
    };
    TransactionAreaStepComponent.prototype.onSelectionChange = function (e) {
        this.resetForm();
        this.FormGroupRef.get('region').setValue(+e.value);
        var region = this.regions.find(function (a) { return a.value === e.value; });
        var notRegion = this.regions.find(function (a) { return a.value !== e.value; });
        region.checked = true;
        notRegion.checked = false;
        if (region.disabled === true) {
            return 'disabled';
        }
        if (notRegion.checked === false) {
            return 'gray-border';
        }
        return 'active';
    };
    TransactionAreaStepComponent.prototype.classAreaActive = function (region) {
        if (region.checked === true) {
            return 'active';
        }
        if (region.disabled === true) {
            return 'disabled';
        }
    };
    return TransactionAreaStepComponent;
}(StepComponentBase));
export { TransactionAreaStepComponent };
