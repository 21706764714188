<div class="d-flex container-fluid w-container-box p-0">
    <div class="tabsSection container-box">
        <h1>נא לבחור פרמטרים להפקת הדוח</h1>

        <form [formGroup]="parametersForm" (ngSubmit)="createReport()">

            <div class="p-3 my-2 bg-light">
                <label class="pl-3">דו"ח</label>
                <p-dropdown [options]="reports" optionLabel="name" placeholder="בחר מתוך הרשימה"
                    formControlName="report" (onChange)="onSelectReport()">
                </p-dropdown>
                <div *ngIf="reportControl.errors" class="validation-error">
                    <i class="icon-16 ml-3"></i>
                    {{reportControl.errors.msg}}
                </div>
            </div>

            <div *ngIf="parametersForm.get('area')" class="p-3 my-2 bg-light">
                <p><b>איזור העסקה</b></p>
                <div class="row">
                    <div class="col-4">
                        <label class="pl-3">איזור ראשי</label>
                        <p-dropdown [options]="areaControl.options" optionLabel="areaName" placeholder="בחר מתוך הרשימה"
                            (onChange)="onSelectParameterArea()" formControlName="area">
                        </p-dropdown>
                    </div>
                    <div class="col-4">
                        <label class="pl-3">איזור משנה</label>
                        <p-dropdown [options]="subAreaControl.options" optionLabel="subAreaName"
                            placeholder="בחר מתוך הרשימה" formControlName="subArea">
                        </p-dropdown>
                    </div>
                </div>
            </div>

            <div *ngIf="parametersForm.get('branch')" class="p-3 my-2 bg-light">
                <p><b>ענפים</b></p>
                <div class="row">
                    <div class="col-4">
                        <label class="pl-3">ענף</label>
                        <p-dropdown [options]="branchControl.options" optionLabel="name" placeholder="בחר מתוך הרשימה"
                            (onChange)="onSelectParameterBranch()" formControlName="branch">
                        </p-dropdown>
                    </div>
                    <div class="col-4">
                        <label class="pl-3">תת ענף</label>
                        <p-dropdown [options]="subBranchControl.options" optionLabel="subName"
                            placeholder="בחר מתוך הרשימה" formControlName="subBranch">
                        </p-dropdown>
                    </div>
                </div>
            </div>

            <div *ngIf="parametersForm.get('region')" class="p-3 my-2 bg-light">
                <p><b>מגורים</b></p>
                <div class="row">
                    <div class="col-4">
                        <label class="pl-3">נפה</label>
                        <p-dropdown [options]="regionControl.options" optionLabel="regionName"
                            (onChange)="onSelectParameterRegion()" placeholder="בחר מתוך הרשימה"
                            formControlName="region">
                        </p-dropdown>
                    </div>
                    <div class="col-4">
                        <label class="pl-3">עיר</label>
                        <p-dropdown [options]="livingCityControl.options" optionLabel="cityName"
                            placeholder="בחר מתוך הרשימה" formControlName="livingCity">
                        </p-dropdown>
                    </div>
                </div>
            </div>

            <div *ngIf="parametersForm.get('fromAge')" class="p-3 my-2 bg-light">
                <p><b>טווח גילאים</b></p>
                <div class="row">
                    <div class="col-4">
                        <label class="pl-3">מגיל</label>
                        <input pInputText class="input-matpash" type="number" formControlName="fromAge" [max]="toAgeControl.formControl.value"
                            placeholder="ללא מינימום גיל">
                    </div>
                    <div class="col-4">
                        <label class="pl-3">עד גיל</label>
                        <input pInputText class="input-matpash" type="number" formControlName="toAge" [min]="fromAgeControl.formControl.value"
                            placeholder="ללא מקסימום גיל">
                        <div *ngIf="toAgeControl.formControl.errors" class="validation-error">
                            <i class="icon-16 ml-3"></i>
                            {{toAgeControl.formControl.errors.msg}}
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="parametersForm.get('fromDate')" class="p-3 my-2 bg-light">
                <p><b>טווח תאריכים</b></p>
                <div class="row">
                    <div class="col-4">
                        <label class="pl-3">מתאריך</label>
                        <p-calendar placeholder="בחר תאריך" dateFormat="dd/mm/yy" formControlName="fromDate"
                            class="p-calendar-matpash">
                        </p-calendar>
                        <div *ngIf="fromDateControl.formControl.errors" class="validation-error">
                            <i class="icon-16 ml-3"></i>
                            {{fromDateControl.formControl.errors.msg}}
                        </div>
                    </div>
                    <div class="col-4">
                        <label class="pl-3">עד תאריך</label>
                        <p-calendar placeholder="בחר תאריך" dateFormat="dd/mm/yy" formControlName="toDate"
                            class="p-calendar-matpash">
                        </p-calendar>
                        <div *ngIf="toDateControl.formControl.errors" class="validation-error">
                            <i class="icon-16 ml-3"></i>
                            {{toDateControl.formControl.errors.msg}}
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="parametersForm.get('employerType')" class="p-3 my-2 bg-light">
                <label class="pl-3">סוג מעסיק</label>
                <p-dropdown [options]="employerTypeControl.options" optionLabel="employerTypeDesc"
                    placeholder="בחר מתוך הרשימה" formControlName="employerType">
                </p-dropdown>
            </div>

            <button pButton icon="pi-angle-double-left" label='הפק דו"ח' [disabled]="!parametersForm.valid"
                class="ui-button-rounded button-matpash light-blue-fill col-md-4 mr-auto"></button>

        </form>
    </div>
</div>