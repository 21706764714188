import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { WizradUserDataService } from '../wizard/service/wizrad-user-data.service';
import { pipe, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ResourceGuardService implements CanActivate {
  formDataInit;
  constructor(private wizardService: WizradUserDataService) {}

  canActivate(): Observable<boolean> {
    return this.wizardService.isInit();
  }
}
