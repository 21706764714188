import { BehaviorSubject, of } from 'rxjs';
import { filter } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../shared/service/ApplicationHttpClient";
var UserDataService = /** @class */ (function () {
    function UserDataService(http) {
        var _this = this;
        this.http = http;
        this.notificationsAndUpdatesSubject = new BehaviorSubject(null);
        this.currentEmployeesDataSubject = new BehaviorSubject(null);
        this.user = new BehaviorSubject(null);
        this.licenseAplicationsSubject = new BehaviorSubject(null);
        this.getUser = function () {
            var userData = sessionStorage.getItem('userData');
            if (userData) {
                return of(JSON.parse(userData));
            }
            else {
                return _this.http.Get("api/account/getUser");
            }
        };
        this.getUser1 = function () {
            var userData = sessionStorage.getItem('userData');
            if (userData) {
                return of(JSON.parse(userData));
            }
            else {
                return _this.http.Get("api/account/getUser");
            }
        };
    }
    UserDataService.prototype.init = function () {
        this._getNotificationsAndUpdates();
        this._getCurrentEmployeesStatus();
        this._getLicenseAplicationsStatus();
    };
    UserDataService.prototype._getNotificationsAndUpdates = function () {
        var _this = this;
        this.http
            .Get("api/statuses/getMassages")
            .subscribe(function (data) {
            _this.notificationsAndUpdatesSubject.next(data);
        });
    };
    UserDataService.prototype._getCurrentEmployeesStatus = function () {
        var _this = this;
        this.http
            .Get("api/statuses/getAllCurrentWorkers?limit=5")
            .subscribe(function (data) {
            _this.currentEmployeesDataSubject.next(data);
        });
    };
    UserDataService.prototype._getLicenseAplicationsStatus = function () {
        var _this = this;
        this.http
            .Get("api/statuses/getEmployerApplicationsStatus?limit=3")
            .subscribe(function (data) {
            _this.licenseAplicationsSubject.next(data);
        });
    };
    UserDataService.prototype.getNotificationsAndUpdates = function () {
        return this.notificationsAndUpdatesSubject
            .asObservable()
            .pipe(filter(function (d) { return !!d; }));
    };
    UserDataService.prototype.getCurrentEmployeesStatus = function () {
        return this.currentEmployeesDataSubject.asObservable().pipe(filter(function (d) { return !!d; }));
    };
    UserDataService.prototype.personalInfo = function (updateUser) {
        return this.http.Post("api/account/applyCommunicationChanges", updateUser);
    };
    UserDataService.prototype.getLicenseAplicationsStatus = function () {
        return this.licenseAplicationsSubject.asObservable().pipe(filter(function (d) { return !!d; }));
    };
    UserDataService.prototype.getAllMessages = function () {
        return this.http.Get("api/alert_messages/active");
    };
    UserDataService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserDataService_Factory() { return new UserDataService(i0.ɵɵinject(i1.ApplicationHttpClient)); }, token: UserDataService, providedIn: "root" });
    return UserDataService;
}());
export { UserDataService };
