import { Component, OnInit } from '@angular/core';
import { UserDataService } from 'src/app/services/user-data.service';
import {
  EmployeesLicenseRequests,
  RequestStatus,
  RequestStatusData,
  User
} from 'src/app/models/interfaces';
import { Router } from '@angular/router';

@Component({
  selector: 'app-license-application-status',
  templateUrl: './license-application-status.component.html',
  styleUrls: ['./license-application-status.component.scss']
})
export class LicenseApplicationStatusComponent implements OnInit {
  date: Date;
  user:User;
  requestStatusData: RequestStatusData = null;
  data : string;
  licenseRequestsList : RequestStatus[] = new Array<RequestStatus>();

  constructor(
    private userDataService: UserDataService,
    private router: Router,
  ) {
    this.date = new Date();
  }

  ngOnInit() {
    this.userDataService.getLicenseAplicationsStatus().subscribe(data => {
      this.licenseRequestsList =
      data.requestsStatus.length > 0 ? data.requestsStatus : null;
      this.requestStatusData = data;
    });

    this.userDataService.getUser().subscribe(user => {
      this.user = user;
    });

    //this.licenseService.getLicense().subscribe(data => {
    // this.data = data;
   // });
  }

  goTo(path: string) {
    this.router.navigate([path]);
  }
}
