import { Component, OnInit, QueryList, ViewChildren, } from "@angular/core";
import {
  EmployeeApplicationResponse,
  EmployeesApplicationList,
  EmployeeApplicationExtended,
  FieldsRequest,
  EmployeeApplicationRequest
} from "src/app/models/interfaces";
import { FormService } from "../../service/form.service";
import { WizradUserDataService } from "../../service/wizrad-user-data.service";
import { StepComponentBase } from "../wizard-step-component-base/step-component-base";
import { StepPagesService } from "../../service/step-pages.service";
import { EmployeeService } from "src/app/services/employee-service";
import { MatDialog } from "@angular/material";
import { EmployeeApplicationListComponent } from "./applications/employee-list/employee-application-list.component";
import { FormGroup, FormArray } from "@angular/forms";
import { PerfectScrollbarConfigInterface } from "ngx-perfect-scrollbar";
import { ScrollToService } from "src/app/shared/service/scrollToService";
import { unregisteredEmployeesMock, rejectedEmployeesMock, approvedEmployeesMock } from "../../mockData/wantedWorkersMockData"
import {
  filter,
  takeUntil
} from "rxjs/operators";
import regionsTypes from "src/app/shared/util/regionTypes";

@Component({
  selector: "app-wanted-workers-step",
  templateUrl: "./wanted-workers-step.component.html",
  styleUrls: ["./wanted-workers-step.component.scss"]
})
export class WantedWorkersStepComponent extends StepComponentBase implements OnInit {
  @ViewChildren(EmployeeApplicationListComponent) employeeListRef!: QueryList<EmployeeApplicationListComponent>;
  public config: PerfectScrollbarConfigInterface = {
    wheelSpeed: 0.2
  };
  isAdvancedFilter: boolean=false;
  isFilterOpen:boolean=false;
  generalError: string = "";
  useMock: boolean;
  allEmployeesResponse: EmployeesApplicationList[];
  selectedField: FieldsRequest;
  initialEmployeesStr: string = "";
  employeeArrayRef: FormArray;
  hasNoApprovedEmployees = false;
  licenseType: number;

  fullList: boolean = false


  constructor(
    stepsPagesService: StepPagesService,
    private wizradUserDataService: WizradUserDataService,
    dialog: MatDialog,
    private formService: FormService,
    private employeeService: EmployeeService,
    private scrollToService: ScrollToService,
  ) {
    super(stepsPagesService, dialog);

    this.useMock = false;
    this.allEmployeesResponse = [];
    this.employeeArrayRef = this.formService
      .getFormGroupRefByName("wantedWorkers")
      .get("employees") as FormArray;
  }

  ngOnInit() {

    super.ngOnInit();
    this.initInitialEmployees();

    //If the field was set before the component render.
    const fieldsRef = this.formService
      .getFormGroupRefByName("requestDetails")
      .get("fields");
    if (fieldsRef.value) {
      this.selectedField = fieldsRef.value;
    }

    fieldsRef.valueChanges.pipe(filter(field => !!field), takeUntil(this.componentDestroyed)).subscribe(field => {
      this.resetAll();
      this.selectedField = field;
    });

    const regionRef = this.formService.getFormGroupRefByName("transactionArea").get("region");
    if (regionRef.value) {
      this.licenseType = regionRef.value;
    }
    regionRef.valueChanges.pipe(filter(region => !!region), takeUntil(this.componentDestroyed)).subscribe(region => {
      this.licenseType = region;
    });
  }

  initInitialEmployees() {
    const employees = this.employeeService.getWantedWorkersEmployees();

    if (employees.length) {
      this.initialEmployeesStr = employees.toString().replace(/,/g, "\n");
    }
  }

  geRequestDetailsData() {
    //const licenseType: number = this.formService.getFormGroupRefByName("transactionArea").get("region").value; // 1 for israel 2 for settelments
    const requestDetailsRef = this.formService.getFormGroupRefByName("requestDetails");
    let payload = {};
    if (this.licenseType == regionsTypes.SETTELMENTS) {
      const outsideLocality: boolean = requestDetailsRef.get("outside_locality").value;
      const settlementsCodes: number[] = requestDetailsRef.get("settlements").value.map((value: any) => value.code);
      const industrialArea: boolean = requestDetailsRef.get("industrial_area").value;
      payload = { outsideLocality, settlementsCodes, industrialArea };
    }

    return payload;

  }

  submitIdNumbers(idNumbers) {
    if (!this.useMock) {
      this.clearGeneralError()

      const requestData: EmployeeApplicationRequest = {
        employees: idNumbers,
        fieldSubCode: this.selectedField.subCode,
        licenseLimitation: this.selectedField.licenseLimitation,
        licenseType: this.licenseType,
        ...this.geRequestDetailsData()
      };

      this.wizradUserDataService
        .getEmployees(requestData)
        .subscribe({ next: this.onSuccess.bind(this), error: this.onError.bind(this) })
    } else {
      this.onSuccess({});
    }

  }

  onSuccess(response: EmployeeApplicationResponse) {
    const unregisteredEmployees: EmployeesApplicationList = {
      employees: this.useMock ? unregisteredEmployeesMock : response.unregisterdEmployees,
      editable: false,
      title: "תעודות זהות אשר לא קיימות במערכת",
      subtitle: `${this.useMock ? unregisteredEmployeesMock.length : response.unregisterdEmployees.length
        } תעודות זהות`
    };

    const rejectedEmployees: EmployeesApplicationList = {
      employees: this.useMock ? rejectedEmployeesMock : response.rejectedEmployees,
      editable: false,
      title: "בקשות שנדחו",
      subtitle: `${this.useMock ? rejectedEmployeesMock.length : response.rejectedEmployees.length
        } בקשות שנדחו - שימו לב, בקשות אלו לא יתווספו לתהליך הגשת בקשת הרישיון`
    };

    const approvedEmployees: EmployeesApplicationList = {
      employees: this.useMock ? approvedEmployeesMock : response.approvedEmployees,
      editable: true,
      title: "בקשות שהתקבלו",
      subtitle: `${this.useMock ? approvedEmployeesMock.length : response.approvedEmployees.length
        } בקשות שהתקבלו`
    };

    this.allEmployeesResponse = [
      unregisteredEmployees,
      rejectedEmployees,
      approvedEmployees
    ];

    this.allEmployeesResponse.map((res) => {
      return res.employees.length == 0 ? this.fullList = true : ''
    })

    this.hasNoApprovedEmployees = response.approvedEmployees.length === 0;
    this.scrollToService.scrollTo(".employeeListSection");
  }

  clearGeneralError() {
    this.generalError = ""
  }

  onError(response) {
    this.generalError = "קיימת תקלה זמנית,אנא נסה מאוחר יותר"
  }

  goBack() {
    this.resetAll();
    if (this.hasBack()) {
      this.goToBackStep();
    }
  }
  resetAll() {
    this.allEmployeesResponse = [];
    this.employeeArrayRef.clear();
  }

  submit() {
    const employeesApplicationListRef = this.employeeListRef.find(
      l => l.employeesApplicationList.editable
    );

    if (employeesApplicationListRef) {
      const selectedEmployees = employeesApplicationListRef.selection.selected;

      if (!selectedEmployees.length) {
        return;
      }
      this.employeeArrayRef.clear();
      selectedEmployees.forEach(employee => {
        this.employeeArrayRef.push(
          this.createEmployeeGroup(employee, employeesApplicationListRef)
        );
      });

      super.submit();
    }
  }

  createEmployeeGroup(employee: EmployeeApplicationExtended, employeesApplicationListRef): FormGroup {
    let group = this.formService.createGroup();
    group.addControl("id", this.formService.createControl(employee.id));
    group.addControl("status", this.formService.createControl(employee.status));
    const file =
      employee.file &&
        employeesApplicationListRef.fileMap.has(employee.id)
        ? employeesApplicationListRef.fileMap.get(employee.id)
        : null;
    group.addControl("file", this.formService.createControl(file));

    return group;
  }

  toggleAdvancedFilter() {
    this.isAdvancedFilter = !this.isAdvancedFilter;
  }

  toggleFilter() {
    this.isFilterOpen = !this.isFilterOpen;
  }

}
