import * as i0 from "@angular/core";
var FileService = /** @class */ (function () {
    function FileService() {
    }
    FileService.prototype.ArrayBufferToBlob = function (bufferedFile) {
        var blobFile = new Blob([bufferedFile.body], {
            type: "application/pdf"
        });
        // const fileName = castedBufferedFile.headers.get("Content-Type");
        return blobFile;
    };
    FileService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FileService_Factory() { return new FileService(); }, token: FileService, providedIn: "root" });
    return FileService;
}());
export { FileService };
