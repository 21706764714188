import { FormArray, FormGroup, Validators } from "@angular/forms";
import regionsTypes from "src/app/shared/util/regionTypes";
import * as i0 from "@angular/core";
import * as i1 from "@angular/forms";
import * as i2 from "../../shared/service/ApplicationHttpClient";
import * as i3 from "./wizrad-user-data.service";
// import {BuildingPermitService} from "./building-permit.service";
var FormService = /** @class */ (function () {
    function FormService(formBuilder, http, wizardUserDataService) {
        this.formBuilder = formBuilder;
        this.http = http;
        this.wizardUserDataService = wizardUserDataService;
    }
    FormService.prototype.createForm = function () {
        this.employmentApplicationForm = this.formBuilder.group({
            transactionArea: this.formBuilder.group({
                region: [null, [Validators.required]]
            }),
            requestDetails: this.formBuilder.group({
                fields: [null, [Validators.required]],
                settlements: this.formBuilder.array([], [Validators.required, Validators.maxLength(4)]),
                license_duration: [null,
                    [Validators.required, Validators.min(1), Validators.pattern("^[0-9]*$")]],
                working_hours: [null, [Validators.required]],
                outside_locality: [false, []],
                industrial_area: [false, []],
                employment_bureau: [null, [Validators.required]],
                loging: [null, [Validators.required]],
                secondaryType: [null]
            }),
            wantedWorkers: this.formBuilder.group({
                employees: this.formBuilder.array([], [Validators.required])
            }),
            documents: this.formBuilder.array([]),
            buildingPermit: this.formBuilder.control(null, [])
        });
        // this.FormValueChangesSubscription = this.employmentApplicationForm.valueChanges.subscribe(
        //   () => {
        //           console.log(
        //     'employment Application Form value changed',
        //     this.employmentApplicationForm.value
        //   );
        //   console.log(
        //     'employment Application Form changed',
        //     this.employmentApplicationForm
        //   );
        //   }
        // );
    };
    FormService.prototype.deleteForm = function () {
        var formValueChangesSubscription = this.FormValueChangesSubscription;
        if (formValueChangesSubscription) {
            this.FormValueChangesSubscription.unsubscribe();
        }
        this.employmentApplicationForm = null;
    };
    FormService.prototype.resetForm = function () {
        this.employmentApplicationForm.reset();
        this.clearAsyncValidators(this.employmentApplicationForm);
        //TODO: CLEARE ALL FORM ERRORS!!!!!
        // this.employmentApplicationForm.clearAsyncValidators();
    };
    FormService.prototype.clearAsyncValidators = function (group) {
        var _this = this;
        Object.keys(group.controls).forEach(function (key) {
            var abstractControl = group.controls[key];
            if (abstractControl instanceof FormGroup || abstractControl instanceof FormArray) {
                _this.clearAsyncValidators(abstractControl);
            }
            else {
                abstractControl.setErrors(null);
            }
        });
    };
    FormService.prototype.getForm = function () {
        return this.employmentApplicationForm;
    };
    FormService.prototype.getFormGroupRefByName = function (ref) {
        return this.employmentApplicationForm.get(ref);
    };
    FormService.prototype.getFormGroupRefByIndex = function (indexRef) {
        var _this = this;
        var formGroupRef = null;
        Object.keys(this.employmentApplicationForm.controls).forEach(function (key, index) {
            if (index === indexRef) {
                formGroupRef = _this.employmentApplicationForm.controls[key];
                return;
            }
        });
        return formGroupRef;
    };
    FormService.prototype.createControl = function (value, validatorsOptions) {
        return this.formBuilder.control(value, validatorsOptions);
    };
    FormService.prototype.createGroup = function (validatorsOptions) {
        return this.formBuilder.group({}, validatorsOptions);
    };
    FormService.prototype.createArray = function (value, validatorsOptions) {
        return this.formBuilder.array([]);
    };
    FormService.prototype.prepareForSubmit = function () {
        var workersArrayRef = this.getFormGroupRefByName("wantedWorkers").get("employees").value;
        // workersArrayRef=workersArrayRef.map(emp=>emp.id)
        var areaCode = +this.getFormGroupRefByName("transactionArea").get("region").value;
        var settlementsArrayRef = this.getFormGroupRefByName("requestDetails").get("settlements").value;
        var documentsArrayRef = this.getFormGroupRefByName("documents").value;
        var fieldCode = +this.getFormGroupRefByName("requestDetails").get("fields").value.subCode;
        var employmentBureau = areaCode == regionsTypes.SETTELMENTS ? this.getFormGroupRefByName("requestDetails").get("employment_bureau").value ?
            +this.getFormGroupRefByName("requestDetails").get("employment_bureau").value.code : null : 8;
        var workingHoursCode = +this.getFormGroupRefByName("requestDetails").get("working_hours").value.code;
        var licenseDuration = areaCode == regionsTypes.SETTELMENTS ?
            +this.getFormGroupRefByName("requestDetails").get("license_duration").value : null;
        var outsideLocality = areaCode == regionsTypes.SETTELMENTS ? this.getFormGroupRefByName("requestDetails").get("outside_locality").value != null ?
            this.getFormGroupRefByName("requestDetails").get("outside_locality").value : false : null;
        var industrialArea = areaCode == regionsTypes.SETTELMENTS ? this.getFormGroupRefByName("requestDetails").get("industrial_area").value != null ?
            this.getFormGroupRefByName("requestDetails").get("industrial_area").value : false : null;
        var loging = areaCode != regionsTypes.SETTELMENTS ? +this.getFormGroupRefByName("requestDetails").get("loging").value : null;
        var workingHoursDocumentId = this.getFormGroupRefByName("requestDetails").get("files") ?
            this.getFormGroupRefByName("requestDetails").get("files").value.id : null;
        var licenseType = areaCode == regionsTypes.ISRAEL ? regionsTypes.ISRAEL : regionsTypes.SETTELMENTS;
        return {
            areaCode: areaCode,
            fieldCode: fieldCode,
            settlementsCodes: settlementsArrayRef.map(function (value) { return value.code; }),
            employmentBureau: employmentBureau,
            workingHoursCode: workingHoursCode,
            licenseDuration: licenseDuration,
            outsideLocality: outsideLocality,
            industrialArea: industrialArea,
            loging: loging,
            workingHoursDocumentId: workingHoursDocumentId,
            uploadedDocuments: documentsArrayRef.filter(function (d) { return d !== null; }),
            wantedEmployees: workersArrayRef,
            licenseType: licenseType,
            employeesChecked: this.wizardUserDataService.employeeApplicationRequest
        };
    };
    FormService.prototype.getValueFromArray = function (arrayOfValues, field) {
        return arrayOfValues.reduce(function (acc, current) {
            acc.push(current[field]);
            return acc;
        }, []);
    };
    FormService.prototype.changeKeysOfArray = function (arrayOfValues, srcKey, detKey) {
        return arrayOfValues.map(function (val) {
            var rObj = {};
            rObj[detKey] = val[srcKey];
            return rObj;
        });
    };
    FormService.prototype.submitForm = function () {
        if (!this.getForm().invalid) {
            return this.http.Post('api/request/saveDigitalApplication', this.prepareForSubmit());
        }
    };
    FormService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FormService_Factory() { return new FormService(i0.ɵɵinject(i1.FormBuilder), i0.ɵɵinject(i2.ApplicationHttpClient), i0.ɵɵinject(i3.WizradUserDataService)); }, token: FormService, providedIn: "root" });
    return FormService;
}());
export { FormService };
