<div class="container-fluid container-box">
    <div class="tabsSection mx-md-5">
        <h1 class="my-5 mx-3">תפריט ראשי</h1>

        <div class="d-flex flex-wrap ">

            <div class=" d-flex justify-content-center square m-2 m-md-3" (click)="navigate('/systemConstitution')">
                <div class="p-2 p-md-4 w-100 h-100 border ">
                    <i class="icon-System-Constitution align-items-center d-flex h-75 justify-content-center"></i>
                    <span class="d-flex justify-content-center text-center">חוקת מערכת</span>
                </div>
            </div>

            <div class=" d-flex justify-content-center square m-2 m-md-3">
                <div class="p-2 p-md-4 w-100 h-100 border">
                    <i class="icon-User-Management1 align-items-center d-flex h-75 justify-content-center"></i>
                    <span class="d-flex justify-content-center text-center">ניהול משתמשים</span>
                </div>
            </div>

            <div class=" d-flex justify-content-center square m-2 m-md-3" (click)="navigate('/alertMessages')">
                <div class="p-2 p-md-4 w-100 h-100 border ">
                    <i class="icon-Message-management align-items-center d-flex h-75 justify-content-center"></i>
                    <span class="d-flex justify-content-center text-center">ניהול הודעות</span>
                </div>
            </div>

            <div class=" d-flex justify-content-center square m-2 m-md-3">
                <div class="p-2 p-md-4 w-100 h-100 border ">
                    <i class="icon-Update-of-regulations align-items-center d-flex h-75 justify-content-center"></i>
                    <span class="d-flex justify-content-center text-center">עדכון הנחיות</span>
                </div>
            </div>

            <div class=" d-flex justify-content-center square m-2 m-md-3">
                <div class="p-2 p-md-4 w-100 h-100 border ">
                    <i class="icon-Update-guidelines align-items-center d-flex h-75 justify-content-center"></i>
                    <span class="d-flex justify-content-center text-center">פורטל תעסוקה עדכון תקנון</span>
                </div>
            </div>

            <div class=" d-flex justify-content-center square m-2 m-md-3">
                <div class="p-2 p-md-4 w-100 h-100 border ">
                    <i class="icon-System-Constitution align-items-center d-flex h-75 justify-content-center"></i>
                    <span class="d-flex justify-content-center text-center">עדכון תקנון מאגר עובדים</span>
                </div>
            </div>

            <div class=" d-flex justify-content-center square m-2 m-md-3">
                <div class="p-2 p-md-4 w-100 h-100 border ">
                    <i class="icon-Menu-management align-items-center d-flex h-75 justify-content-center"></i>
                    <span class="d-flex justify-content-center text-center">ניהול תפריטים</span>
                </div>
            </div>

            <div class=" d-flex justify-content-center square m-2 m-md-3" (click)="navigate('/reports')">
                <div class="p-2 p-md-4 w-100 h-100 border ">
                    <i class="icon-Reports align-items-center d-flex h-75 justify-content-center"></i>
                    <span class="d-flex justify-content-center text-center">דוחות</span>
                </div>
            </div>

            <div class=" d-flex justify-content-center square m-2 m-md-3">
                <div class="p-2 p-md-4 w-100 h-100 border ">
                    <i class="icon-Interfaces align-items-center d-flex h-75 justify-content-center"></i>
                    <span class="d-flex justify-content-center text-center">ממשקים</span>
                </div>
            </div>

            <div class=" d-flex justify-content-center square m-2 m-md-3">
                <div class="p-2 p-md-4 w-100 h-100 border ">
                    <i class="icon-Update align-items-center d-flex h-75 justify-content-center"></i>
                    <span class="d-flex justify-content-center text-center">עדכון פרמטרים</span>
                </div>
            </div>

            <div (click)="sortingDay()"  class=" d-flex justify-content-center square m-2 m-md-3">
                <div class="p-2 p-md-4 w-100 h-100 border ">
                    <i class="icon-Update align-items-center d-flex h-75 justify-content-center"></i>
                    <span class="d-flex justify-content-center text-center">ניהול יום מיונים</span>
                </div>
            </div>

            <div (click)="inputDaySorting()" class=" d-flex justify-content-center square m-2 m-md-3">
                <div class="p-2 p-md-4 w-100 h-100 border ">
                    <i class="icon-Update align-items-center d-flex h-75 justify-content-center"></i>
                    <span class="d-flex justify-content-center text-center">שיבוץ יום מיונים</span>
                </div>
            </div>






        </div>
        <button (click)="parameterManagement()">ניהול פרמטרים</button>
    </div>
</div>