import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { BreakpointObserver } from '@angular/cdk/layout';
import { UserDataService } from '../../services/user-data.service';
import { User } from 'src/app/models/interfaces';
@Component({
  selector: 'app-navigation-tabs',
  templateUrl: './navigation-tabs.component.html',
  styleUrls: ['./navigation-tabs.component.scss']
})
export class NavigationTabsComponent implements OnInit {
  @Output() closeTabs = new EventEmitter();
  navLinks: any[] = [];
  activeLinkIndex = -1;
  isMobile = false;
  navTabs = []
  constructor(private router: Router, private breakpointObserver: BreakpointObserver, private userDataService: UserDataService) {
   
  }

  ngOnInit() {
    this.router.events.subscribe(res => {
      this.activeLinkIndex = this.navLinks.indexOf(
        this.navLinks.find(tab => tab.path === '.' + this.router.url)
      );
    });

    this.breakpointObserver.observe('(min-width: 1024px)').subscribe((e) => {
      this.isMobile = !e.matches;

    });
    this.setIsDisabledTab()
  }

  setIsDisabledTab() {
    this.userDataService.getUser().subscribe(user => {
      this.buildNav(user)
    })
  }

  buildNav(user:User){
    this.navLinks = [
      {
        label: 'ראשי',
        path: './home',
        icon:'icon-main',
        index: 0,
        isDisabled: false,
      },
      {
        label: 'איתור עובדים',
        path: './poolEmployees',
        icon:'icon-Locating-employees',
        index: 1,
        isDisabled: false,       
      },
      {
        label: 'הגשת בקשות להעסקה',
        path: './employmentapplication',
        icon:'icon-Applying-for-employment',
        index: 2,
        isDisabled: user.hasBranchOnlyInIsrael,
      },
      
      {
        label: 'צפייה בבקשות/רשיונות',
        path: './requestsandlicenses',
        icon:'icon-calendar',
        index: 3,
        isDisabled: false,
      },
      {
        label: 'הצגת מעברים',
        path: './transitionsHistory',
        icon:'icon-View-transitions',
        index: 4,
        isDisabled: false,
      },
      {
        label: 'טפסים',
        path: 'passcard1',
        icon:'icon-Forms',
        index: 5,
        isDisabled: true,
      },
      {
        label: 'תחזוקה',
        path: './maintenance',
        icon:'icon-Forms',
        index: 5,
        isDisabled: false,
      },
      // {
      //   label: 'ניהול',
      //   path: './management',
      //   icon:'icon-Forms',
      //   index:6,
      //   isDisabled: false,
      // },
      // {
      //   label: ' ניהול משתמשים',
      //   path: './userManagement',
      //   icon:'icon-Forms',
      //   index:7,
      //   isDisabled: false,
      // },
      // {
      //   label: 'תפריט ניהול',
      //   path: './systemConstitution',
      //   icon:'icon-Forms',
      //   index:8,
      //   isDisabled: false,
      // }
      {
        label: 'מודעות דרושים',
        path: './wantAds',
        icon:'icon-Applying-for-employment',
        index: 6,
        isDisabled: false,
      },
    ];
  }
  close_Menu() {
    this.closeTabs.emit();
  }
}