<div class="container-fluid container-box">
    <div class="tabsSection">
        <h1 class="mb-5">ניהול משתמשים</h1>
        <div class="mr-n4">
            <div class="d-md-flex  mb-4">
                <div class=" col-12 col-md-4 mb-4 mb-md-0">
                    <input type="text" class="input-matpash w-100" placeholder="זיהוי מעסיק" pInputText />
                </div>
                <div class="p-inputgroup col-12 col-md-5 position-relative">
                    <input type="text" class="input-matpash w-100" [(ngModel)]="tz" pInputText placeholder="הכנס ת.ז">
                    <button type="button" pButton label="הצג" class="BtnInnerInput"></button>
                </div>
            </div>
            <div class="d-md-flex  mb-4">
                <div class="col-12 col-md-4 mb-4 mb-md-0">
                    <input type="text" class="input-matpash w-100" placeholder='שם מלא' pInputText />
                </div>
                <div class="col-12 col-md-4">
                    <input type="text" class="input-matpash w-100" placeholder='דוא"ל' pInputText />
                </div>
            </div>
            <div class="d-md-flex  mb-4">
                <div class="col-12 col-md-4 mb-4 mb-md-0">
                    <input type="number" class="input-matpash w-100" placeholder='טלפון' pInputText />
                </div>
                <div class="col-12 col-md-4">
                    <label for="" class="pl-4 w-25">pincode</label>
                    <input type="password" class="input-matpash w-75" placeholder='' pInputText />

                </div>

            </div>
            <div class=" mb-4">
                <div class="col-12 col-md-4">
                    <p-dropdown [options]="" placeholder="סטאטוס" optionLabel="" [showClear]="true" class="w-100"></p-dropdown>
                </div>
            </div>
            <div class="d-md-flex">
                <div class="col-12 col-md-4 mb-4 mb-md-0">
                    <div class="d-flex flex-column">
                        <input type="text" class="input-matpash w-100 mb-4" placeholder='סיבה' pInputText />
                        <p-dropdown [options]="" placeholder="תפקיד" optionLabel="" [showClear]="true" class="w-100"></p-dropdown>

                    </div>
                </div>
                <div class="col-12 col-md-4 gray-box mb-4 mb-md-0">
                    <div class="d-flex mb-5">
                        <label>מס' נסיונות כניסה</label>
                        <span class="font-weight-bold mr-3">3</span>
                    </div>
                    <div class="d-flex mb-5">
                        <label>תאריך אישור תקנון</label>
                        <span class="font-weight-bold mr-3">17/02/2020</span>
                    </div>
                    <div class="d-flex mb-5">
                        <label>כניסה אחרונה</label>
                        <span class="font-weight-bold mr-3">17/02/2020</span>
                    </div>
                </div>
                <div class="col-12 col-md-4  mt-auto  d-flex pb-4 pb-md-0">
                    <div class="mr-auto">

                        <button pButton type="button" label="חדש" class="ui-button-rounded button-matpash gray-fill">
                        </button>

                        <button pButton type="button" label="שמור"
                        class="ui-button-rounded button-matpash light-blue-fill mr-3">
                    </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>