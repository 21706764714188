<p-dialog header="" [(visible)]="visibleTermsDialog" [style]="{width: '900px', minWidth: '200px', height:'70%'}" [modal]="true" [responsive]="true"
          [minY]="70" [baseZIndex]="10000" class="regular-dialog">
    <app-terms-and-conditions (TermsApproved)="termsApproved($event)" class="d-flex flex-column flex-grow-1 overflow-auto">
    </app-terms-and-conditions>
    <div class="d-flex justify-content-end mt-4">
        <button pButton type="button" class="ui-button-rounded button-matpash no-fill-btn ml-4"
                (click)="visibleTermsDialog=false" label="חזרה לעמוד הזדהות"></button>
        <button type="button" pButton class="ui-button-rounded button-matpash light-blue-fill w-157" (click)="approveTerms()" [disabled]="disabledApproveTerms" label="אישור "></button>
    </div>


</p-dialog>
<div class="errorMsg errorMsg--center" *ngIf="isErrorShow">עקב תקלה זמנית אין באפשרותינו לבצע את הפעולה</div>

<form novalidate [formGroup]="otpForm" #otpform="ngForm" (ngSubmit)="onSubmit()" class="stepTwo  h-100">
  <div class="d-flex flex-column h-100">
    <div class="d-flex flex-column flex-grow-1 h-100">
      <h3 class="sub-header-login text-center text-md-right d-flex align-items-center"><i
          class="icon-secret-code ml-md-3"></i> קוד הזדהות</h3>

      <p class=" textContent--blackTextColor text-center text-md-right" *ngIf="userData.authType == 0">קוד הזדהות נשלח
        למספר נייד המסתיים ב
        {{ userData.user.phone | phoneMasking }}
      </p>


      <p class=" textContent--blackTextColor text-center text-md-right" *ngIf="userData.authType != 0">
        קוד הזדהות נשלח לכתובת המייל {{ userData.user.email | emailMasking }}
      </p>
      <div class="text-center text-md-right" *ngIf="userData.usedOtherAuthType">
        {{userData.usedOtherAuthTypeMessage}}
      </div>

      <button class="explanatory-link text-center text-md-right" (click)="openLoginFormModal()" type="button">
        {{
        userData.authType == 0 ? "זה לא מספר הטלפון שלי" : "זה לא כתובת המייל שלי"
      }}


      </button>
      <div class="gray-bg-to-input text-center text-md-right">
        <span>נא להזין את קוד ההזדהות:</span>
        <input type="tel" class="code-input w-100 text-center text-md-right" formControlName="otp" />

        <span class="validation-error mt-2 mb-2 d-flex align-items-center"
          *ngIf="otpForm.controls['otp'].errors?.required && otpForm.controls['otp'].touched"><i
            class="icon-16 ml-3"></i>שדה חובה</span>
        <span class="validation-error mt-2 mb-2 d-flex align-items-center"
          *ngIf="otpForm.controls['otp'].errors?.incorrect"><i class="icon-16 ml-3"></i>{{errorFromServer}}</span>


      </div>

    </div>
    <div class="row text-center text-md-right">
      <div class="col-md-6 col-12 d-flex align-items-center text-center text-md-right">
        <button *ngIf="otpResend===false" class="explanatory-link m-md-0 mx-auto mx-md-0 mb-4 " (click)="resendOtp()"
          type="button" #resendOtpBtn [disabled]="otpResend">
          לא קיבלת קוד? שלח שוב
        </button>
        <span *ngIf="otpResend===true">הקוד נשלח בשנית</span>
      </div>
      <div class="col-md-6 col-12 ">
        <button pButton type="button" label="שליחה" type="button"
          class="ui-button-rounded w-100 button-matpash light-blue-fill d-flex align-items-center m-auto  flex-row-reverse justify-content-center max-w-180"
          (click)="onSubmit()" [disabled]="!otpform.valid"> <i class="icon-left-arrow mr-3"></i> </button>

      </div>
    </div>
  </div>


</form>
<app-message-modal *ngIf='displayModal' [title]='title' [displayModal]='displayModal' [showContact]='showContact'
  [content]='content' [img]='img' (statusToEmit)="getStatusModal($event)"></app-message-modal>