import { Component, OnInit } from '@angular/core';
import { Options } from 'ng5-slider';
import { EmployeesFilter } from '../../models/EmployeesFilter'
import { EmployeeService } from '../../services/employee-service'
import { Branch, SubBranch, Profession, City } from '../../models/metadataModels'
import { EmployeeComponent } from '../employee/employee.component'
import { Employee, EmployeeFromSearch, livingCity, professionModel, SubBrancModel } from 'src/app/models/Employee';


@Component({
  selector: 'app-pool-employees',
  templateUrl: './pool-employees.component.html',
  styleUrls: ['./pool-employees.component.scss']
})

export class PoolEmployeesComponent implements OnInit {

  Branches: Branch[]
  filterObject: EmployeesFilter;
  selectedUserFilter: EmployeesFilter;
  isAdvancedFilter: boolean = true;
  isFilterOpen: boolean;
  isFavorite: boolean = false;
  isWorkerListEmpty: boolean = false;
  workerAdsBtn: boolean = true;
  resultNumber: number = 0;
  defaultValueFilter: string = "";
  subBranchesFiltered: SubBrancModel[] = [];
  allsubBranches: SubBrancModel[];
  professionsFiltered: professionModel[] = [];
  allProfessions: professionModel[];
  citiesFiltered: livingCity[] = [];
  allcities: livingCity[];
  //age range
  minValue: number = 20;
  maxValue: number = 50;
  options: Options = {
    floor: 0,
    ceil: 80
  };

  listEmployees: EmployeeFromSearch[];

  constructor(private employeeService: EmployeeService) {

  }

  initSelectedUserFilter() {
    this.Branches = [];
    this.selectedUserFilter = new EmployeesFilter();
    this.selectedUserFilter.Branches = [];
    this.selectedUserFilter.SubBranches = [];
    this.selectedUserFilter.Areas = [];
    this.selectedUserFilter.Cities = [];
    this.selectedUserFilter.EmploymentStatus = [];
    this.selectedUserFilter.Genders = [];
    this.selectedUserFilter.Languages = [];
    this.selectedUserFilter.Professions = [];
    this.selectedUserFilter.Regions = [];

  }

  SubBranchFilter() {
    var branches = this.selectedUserFilter.Branches.map(i => i.code);
    if (branches != null && this.allsubBranches != null) {
      this.subBranchesFiltered = this.allsubBranches.filter((subBrunch: SubBrancModel) => branches.includes(subBrunch.branch.code));
      this.filterObject.SubBranches = this.subBranchesFiltered;
      this.professionsFilter();
    }
  }

  professionsFilter() {
    var subBranches = this.selectedUserFilter.SubBranches.map(i => i.subCode);
    if (subBranches != null && this.allProfessions != null) {
      this.professionsFiltered = this.allProfessions.filter((profession: professionModel) => subBranches.includes(profession.subBranch.subCode));
      this.filterObject.Professions = this.professionsFiltered;
    }
  }

  reset(){
    this.selectedUserFilter.Branches=[];
    this.selectedUserFilter.Professions=[]
    this.selectedUserFilter.Regions=[];
    this.selectedUserFilter.Cities=[];
    this.selectedUserFilter.Languages=[];
    this.selectedUserFilter.EmploymentStatus=[];
    this.selectedUserFilter.Genders=[];
    this.selectedUserFilter.IsFilteredByAge=false
    this.selectedUserFilter.IsSafetyTraining=false
    this.selectedUserFilter.PreviouslyEmployedByIsraeli=false;
    this.listEmployees=[]
    // this.selectedUserFilter=new EmployeesFilter();
  }

  changeRegion(){
    this.selectedUserFilter.Cities=[]
  }

  cityFilter() {
    var regions = this.selectedUserFilter.Regions.map(i => i.regionId);
    if (regions != null && this.allcities != null) {

      this.citiesFiltered = this.allcities.filter((city: livingCity) => regions.includes(city.regionId.regionId));
      this.filterObject.Cities = this.citiesFiltered;
    }
  }

  BrunchesSelectionChange() {
    this.selectedUserFilter.SubBranches=[]
    var branches = this.selectedUserFilter.Branches.map(i => i.code);
    this.selectedUserFilter.SubBranches = this.selectedUserFilter.SubBranches.filter((subBrunch: SubBrancModel) => branches.includes(subBrunch.branch.code));
  }

  SubBrunchesSelectionChange() {
    this.selectedUserFilter.Professions=[]
    var subBranches = this.selectedUserFilter.SubBranches.map(i => i.subCode);
    this.selectedUserFilter.Professions = this.selectedUserFilter.Professions.filter((profession: professionModel) => subBranches.includes(profession.subBranch.subCode));
  }

  //  filter() {

  //  let storeId = 1;
  //  this.bookFilteredList = this.bookList
  //                            .filter((book: Book) => book.storeId === storeId);
  //   this.bookList = this.bookFilteredList; 
  //}

  ngOnInit() {
    this.initSelectedUserFilter();
    this.isFilterOpen = true;
    this.filterObject = new EmployeesFilter();
    this.filterObject.Branches = [];
    this.employeeService.getEmployeePoolFilter().subscribe(res => {
      this.filterObject = res
      this.allsubBranches = this.filterObject.SubBranches;

      this.allProfessions = this.filterObject.Professions;

      this.allcities = this.filterObject.Cities;
    })



  }

  toggleAdvancedFilter() {
    this.isAdvancedFilter = !this.isAdvancedFilter;
  }

  toggleFilter() {
    this.isFilterOpen = !this.isFilterOpen;
  }

  toggleFavorite() {

  }

  submitFilter() {
    if (window.innerWidth < 1024) {
      if (this.isFilterOpen) {
        this.isFilterOpen = !this.isFilterOpen;

      }
    }

    if (this.selectedUserFilter.Cities.length == 0 && this.selectedUserFilter.Regions.length > 0) {
      const result = this.allcities.filter(city => {
        return this.selectedUserFilter.Regions
          .some(region => region.regionId == city.regionId.regionId)
      });
      this.selectedUserFilter.Cities = result;
    }

    if (this.selectedUserFilter.Professions.length==0 && this.selectedUserFilter.SubBranches.length>0){
      const result = this.allProfessions.filter(profession => {
        return this.selectedUserFilter.SubBranches
          .some(sub => sub._id == profession.subBranch._id)
      });
      this.selectedUserFilter.Professions = result;
    }

    if (this.selectedUserFilter.SubBranches.length==0 && this.selectedUserFilter.Branches.length>0){
      const result = this.allProfessions.filter(profession => {
        return this.selectedUserFilter.Branches
          .some(branch => branch._id == profession.subBranch.branch._id)
      });
      this.selectedUserFilter.Professions = result;
    }
    if (this.selectedUserFilter.IsFilteredByAge==true){
      this.selectedUserFilter.MinAge=this.minValue;
      this.selectedUserFilter.MaxAge=this.maxValue;
    }

    if (this.selectedUserFilter.Branches.length==0){
      this.selectedUserFilter.Professions.length=0
    }

    if (this.selectedUserFilter.IsFilteredByAge==false){
      this.selectedUserFilter.MinAge=undefined;
      this.selectedUserFilter.MaxAge=undefined;
    }
   
    this.employeeService.searchEmployees(this.selectedUserFilter).subscribe(res => {
      this.listEmployees = res.map(a => { return this.regionString(a); });

    })
    this.isAdvancedFilter = false;
  }

  regionString(employeeData: EmployeeFromSearch) {
    if (employeeData && employeeData.livingCity && employeeData.livingCity.regionId) {
      employeeData.regionName = employeeData.livingCity.regionId.regionName;
    }
    else {
      employeeData.regionName = '';
    }

    return employeeData;
  }


}
