/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./footer.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./footer.component";
var styles_FooterComponent = [i0.styles];
var RenderType_FooterComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FooterComponent, data: {} });
export { RenderType_FooterComponent as RenderType_FooterComponent };
function View_FooterComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "footer", [["class", "appFooter "]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 13, "section", [["class", " container-fluid d-flex align-items-center width-main h-100"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["src", "./assets/imgs/LogoFooter.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h2", [["class", "mb-0 mx-5"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u05D0\u05EA\u05E8 \u05D4\u05EA\u05E2\u05E1\u05D5\u05E7\u05D4 \u05E9\u05DC \u05DE\u05EA\u05E4\u05F4\u05E9"])), (_l()(), i1.ɵeld(5, 0, null, null, 9, "ul", [["class", "appFooter__list mr-auto d-flex"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "li", [["class", "appFooter__item"]], [[2, "termsLogin", null]], null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "a", [["class", "appFooter__link d-flex align-items-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "li", [["class", "appFooter__item mx-5"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "a", [["class", "appFooter__link"], ["href", "https://www.gov.il/he/departments/civil_administration_in_judea_and_samaria"], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u05D0\u05EA\u05E8 \u05D4\u05DE\u05E0\u05D4\u05DC \u05D4\u05D0\u05D6\u05E8\u05D7\u05D9"])), (_l()(), i1.ɵeld(11, 0, null, null, 3, "li", [["class", "appFooter__item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 2, "a", [["class", "appFooter__link"], ["routerLink", "/terms"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 13).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(13, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["\u05EA\u05E0\u05D0\u05D9 \u05E9\u05D9\u05DE\u05D5\u05E9"]))], function (_ck, _v) { var currVal_3 = "/terms"; _ck(_v, 13, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.termsFooterLogin; _ck(_v, 6, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 13).target; var currVal_2 = i1.ɵnov(_v, 13).href; _ck(_v, 12, 0, currVal_1, currVal_2); }); }
export function View_FooterComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_FooterComponent_1)), i1.ɵdid(1, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isNotLogin; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_FooterComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-footer", [], null, null, null, View_FooterComponent_0, RenderType_FooterComponent)), i1.ɵdid(1, 114688, null, 0, i4.FooterComponent, [i2.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FooterComponentNgFactory = i1.ɵccf("app-footer", i4.FooterComponent, View_FooterComponent_Host_0, {}, {}, []);
export { FooterComponentNgFactory as FooterComponentNgFactory };
