import { NgModule } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';
import { SelectButtonModule } from 'primeng/selectbutton';
import { ReactiveFormsModule } from '@angular/forms';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { MultiSelectModule } from 'primeng/multiselect';
import { DropdownModule } from 'primeng/dropdown';
import { CheckboxModule } from 'primeng/checkbox';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { CalendarModule } from 'primeng/calendar';
import { TabViewModule } from 'primeng/tabview';
import { CardModule } from 'primeng/card';
import { FullCalendarModule } from 'primeng/fullcalendar';
import { InputMaskModule } from 'primeng/inputmask';
import { AccordionModule } from 'primeng/accordion';
import { MenuModule } from 'primeng/menu';
import { EditorModule } from 'primeng/editor';
import { RadioButtonModule } from 'primeng/radiobutton';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { InputSwitchModule } from 'primeng/inputswitch';
import { ToastModule } from 'primeng/toast';


@NgModule({
  imports: [
    ButtonModule,
    DialogModule,
    SelectButtonModule,
    ReactiveFormsModule,
    TableModule,
    TooltipModule,
    MultiSelectModule,
    DropdownModule,
    CheckboxModule,
    BrowserAnimationsModule,
    FormsModule,
    BrowserModule,
    CalendarModule,
    AccordionModule,
    TabViewModule,
    CardModule,
    FullCalendarModule,
    InputMaskModule,
    AccordionModule,
    MenuModule,
    EditorModule,
    RadioButtonModule,
    AutoCompleteModule,
    InputSwitchModule,
    ToastModule



  ],
  declarations: [

    // MessageModalComponent
  ],
  exports: [
    ButtonModule,
    InputTextModule,
    SelectButtonModule,
    DialogModule,
    ReactiveFormsModule,
    TableModule,
    TooltipModule,
    MultiSelectModule,
    DropdownModule,
    CheckboxModule,
    BrowserAnimationsModule,
    FormsModule,
    BrowserModule,
    CalendarModule,
    AccordionModule,
    TabViewModule,
    CardModule,
    FullCalendarModule,
    InputMaskModule,
    AccordionModule,
    MenuModule,
    EditorModule,
    InputSwitchModule,
    ToastModule
  ]
})
export class SharedModule { }


