import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AnonymousSubject } from 'rxjs/internal/Subject';
import { Candidate } from '../models/candidate';
import { Employee } from '../models/Employee';
import { SortingDay } from '../models/sortingDay';
import { ApplicationHttpClient } from '../shared/service/ApplicationHttpClient';

@Injectable({
  providedIn: 'root'
})
export class StoringDayService {

  constructor(public http:ApplicationHttpClient) { }

  getAllBranches():Observable<any>{
    return this.http.Get<any>(`api/branches/`)
  }

  getAllSubBranches():Observable<any>{
    return this.http.Get<any>(`api/sub_branches/`)
  }

  selectSubBranch(branchId:string):Observable<any>{
    return this.http.Get<any>(`api/sub_branches/${branchId}`)
  }

  selectProfession(subBranchId:string):Observable<any>{
    return this.http.Get<any>(`api/profession/${subBranchId}`)
  }

  getAllProfession():Observable<any>{
    return this.http.Get<any>("api/profession/")
  }

  getSortingDays(professionId:string):Observable<any>{
    return this.http.Get<any>(`api/sorting_day/${professionId}`)
  }

  updateOrCreate(sortingDay:SortingDay):Observable<any>{
    return this.http.Post<any>(`api/sorting_day/update/`, sortingDay);
  }

  deleteSortingDay(sortingDayId:string, professionId:string):Observable<any>{
    return this.http.Delete<any>(`api/sorting_day/${sortingDayId}/${professionId}`)
  }

  create(sortingDay:SortingDay):Observable<any>{
    return this.http.Post<any>(`api/sorting_day/create/`, sortingDay)
  }

  searchEmployees(professionId:string):Observable<any>{
    return this.http.Get<any>(`api/input_day_sorting/employee/${professionId}`)
  }

  getCandidate(professionId:string):Observable<any>{
    return this.http.Get<any>(`api/input_day_sorting/sortingDayByProfession/${professionId}`)
  }

  embedEmployees(sortingDayId:string, listCandidate:Array<Candidate>):Observable<any>{
    return this.http.Post<any>(`api/input_day_sorting/${sortingDayId}`, listCandidate)
  }

  getAllStatus():Observable<any>{
    return this.http.Get<any>(`api/input_day_sorting`)
  }

  createCandidates(listCandidates:any):Observable<any>{
    return this.http.Post<any>(`api/candidate/`, listCandidates)
  }
  
  updateStatusEmployeeProfession(employees:any, professionId:string):Observable<any>{
    return this.http.Post<any>(`api/employees/embedEmployees/${professionId}`, employees)
  }
}
