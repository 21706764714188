import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Parameters } from 'src/app/models/parameter';
import { TransitionHistory } from 'src/app/models/transitionHistory';
import { ParameterService } from 'src/app/services/parameter.service';
import { TransitionHistoryService } from 'src/app/services/transition-history.service';
import { ValidateId } from 'src/app/shared/validators/id.validator';

@Component({
  selector: 'app-transitions-history',
  templateUrl: './transitions-history.component.html',
  styleUrls: ['./transitions-history.component.scss']
})
export class TransitionsHistoryComponent implements OnInit {
  identityFrom: FormGroup;
  fromDate: Date = new Date();
  toDate: Date = new Date();
  minDate: Date;
  maxDate: Date = new Date();
  fromDateModal: Date;
  toDateModal: Date;
  cols: any[]
  showModalDate: boolean = false;
  parameter: Parameters = new Parameters();
  listTransitionHistory: Array<TransitionHistory> = new Array<TransitionHistory>();
  isPassage: boolean = false;
  identity: any;
  showValidateDate: boolean = false

  constructor(public fb: FormBuilder, public router: Router, public parameterService: ParameterService, public transitionHistoryService: TransitionHistoryService) {

    const navigation = this.router.getCurrentNavigation();
    const state = navigation.extras.state as {
      idEmploeeys: any[]
    };
    if (!!state) {
      this.identity = state.idEmploeeys[0].id;
    }
  }

  ngOnInit() {
    this.createFrom();
    this.getParameter();
   
    this.cols = [
      { field: 'Station', header: 'שם המעבר' },
      { field: 'PassDate', header: 'תאריך' },
      { field: 'PassHour', header: 'שעה' },
      { field: 'IsPassageAproved', header: 'הערות' }
    ];
    
    if (!!this.identity) {
      this.identityFrom.controls.identity.setValue(this.identity)
      this.showTransitionHistory();
    }
  }

  createFrom() {
    this.identityFrom = this.fb.group({
      identity: [null, [Validators.required, ValidateId]],
    })
  }

  getParameter() {
    this.parameterService.getParameters().subscribe(data => {
      this.parameter = data.data[0];
      this.fromDate = new Date(this.fromDate.setDate(this.fromDate.getDate() - this.parameter.maxDaysTransitions))
      this.minDate = this.fromDate;
    })
  }

  showModalDates() {
    this.fromDateModal = this.fromDate;
    this.toDateModal = this.toDate
    this.showModalDate = true;
  }

  validateDate() {
    this.showValidateDate = false;

    if (this.fromDateModal > this.toDateModal) {
      this.showValidateDate = true;
      return;
    }
  }

  closeModal() {
    this.showValidateDate = false;
    this.showModalDate = false;
    this.fromDate = this.fromDateModal;
    this.toDate = this.toDateModal;
    if (!this.identityFrom.controls.identity.value) return;
    this.showTransitionHistory()
  }

  showTransitionHistory() {
    this.isPassage = false
    const data = {
      user: this.identityFrom.controls.identity.value,
      fromDate: this.fromDate.setHours(0,0,0,0),
      toDate: this.toDate.setHours(23, 59, 59, 999)
    }
    this.transitionHistoryService.getTransitionByEmployer(data).subscribe(data => {
      if (!data.data) {
        this.isPassage = true;
        return;
      }
      this.listTransitionHistory= this.sortListSortingDayByDate(data.data);
    })
  }

  sortListSortingDayByDate(array:TransitionHistory[]){
    array.sort(function(a,b){
      return new Date(b.PassDate).getTime() -new Date(a.PassDate).getTime();
    });
    return array;
  }
}
