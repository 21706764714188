export var approvedFilesType = function (file) {
    var approvedFiles = [".gif", ".jpg", ".png", ".jpeg", ".pdf"];
    var index = file.name.lastIndexOf(".");
    var typeFile = file.name.slice(index).toLowerCase();
    return approvedFiles.includes(typeFile);
};
export var checkForValidFiles = function () {
    var arrayOfFiles = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        arrayOfFiles[_i] = arguments[_i];
    }
    var invalidFlag = false;
    var maxFilesSize = 10000000;
    var filesSizes = 0;
    var files = arrayOfFiles;
    if (files.length > 20) {
        return true;
    }
    files.forEach(function (element) {
        filesSizes += element.size;
        if (!approvedFilesType(element) || filesSizes > maxFilesSize) {
            invalidFlag = true;
        }
    });
    return invalidFlag;
};
export var checkForValidFile = function (file) {
    var invalidFlag = false;
    var maxFileSize = 12000000;
    var fileSize = 0;
    var files = file;
    if (files.length > 20) {
        return true;
    }
    fileSize += files.size;
    if (!approvedFilesType(files) || fileSize > maxFileSize) {
        invalidFlag = true;
    }
    return invalidFlag;
};
export function ValidateFiles(control) {
    if (!control.value) {
        return null;
    }
    if (checkForValidFiles(control.value)) {
        return { ValidateFiles: true };
    }
    return null;
}
