import { Routes } from '@angular/router';
import { LoginComponent } from '../pages/login/login.component';
import { HpComponent } from '../pages/hp/container/hp.component';
import { PersonalInfoComponent } from '../pages/personalInfo/personal-info.component';
import { TermsAndConditionsComponent } from '../pages/terms-and-conditions/terms-and-conditions.component';
import { RequestsLicensesTabsComponent } from '../pages/requests_and_licenses/container/requests-licenses-tabs.component';
import { AuthGuardService as AuthGuard } from '../services/auth-guard.service';
import { IsraelGuardService as IsraelGuard } from '../services/israel-guard.service';
import { IsraelRedirectGuardService as IsraelRedirectGuard } from '../services/israel-redirect-guard.service';
import { LoginRedirectGuardService as LoginRedirectGuard } from '../services/login-redirect-guard.service';
import { WizardStepperComponent } from '../wizard/wizard-stepper/wizard-stepper.component';
import { PoolEmployeesComponent } from '../pages/pool-employees/pool-employees.component';
import { SavedAdsComponent } from '../pages/saved-ads/saved-ads.component';
import { StatusRequestComponent } from "../pages/requests_and_licenses/components/status-request/status-request.component";
import { AllCurrentEmployeesStatusComponent } from "../pages/requests_and_licenses/components/all-current-employees-status/all-current-employees-status.component";
import { PageNotFoundComponent } from '../pages/page-not-found/page-not-found.component';
import { TransitionsHistoryComponent } from '../pages/transitions-history/transitions-history.component';
import { MaintenanceComponent } from '../pages/maintenance/maintenance.component';
import { InputDayStoringComponent } from '../pages/input-day-storing/input-day-storing.component';
import { ManagementComponent } from '../pages/management/management.component';
import { UserManagementComponent } from '../pages/user-management/user-management.component';
import { ParameterManagementComponent } from '../pages/management/parameter-management/parameter-management.component';
import { AlertMessagesComponent } from '../pages/managements/management-nav/pages/alert-messages/alert-messages.component';
import { UpdateAlertMessageComponent } from '../pages/managements/management-nav/pages/update-alert-message/update-alert-message.component';
import { SortingDayComponent } from '../pages/sorting-day/sorting-day.component';
import { WantAdsComponent } from '../pages/want-ads/want-ads.component';
import { SystemConstitutionComponent } from '../pages/managements/management-nav/pages/system-constitution/system-constitution.component';
import { UnsavedChangesGuard } from '../services/unsaved-changes.guard';
import { ReportsComponent } from '../pages/managements/management-nav/pages/reports/reports.component';
import { QueriesComponent } from '../pages/queries/queries.component';
var ɵ0 = { label: ' צפייה בבקשות / רישיונות ', index: 0, printDataName: 'requestStatus' }, ɵ1 = { label: 'עובדים נוכחיים', index: 1, printDataName: 'currentEmployees' };
var appRoutes = [
    { path: '', pathMatch: 'full', redirectTo: 'home' },
    { path: 'login', component: LoginComponent, canActivate: [LoginRedirectGuard] },
    { path: 'home', component: HpComponent, canActivate: [AuthGuard] },
    { path: 'personalinfo', component: PersonalInfoComponent, canActivate: [AuthGuard] },
    { path: 'terms', component: TermsAndConditionsComponent },
    { path: 'transitionsHistory', component: TransitionsHistoryComponent },
    { path: 'maintenance', component: MaintenanceComponent },
    { path: 'sortingDay', component: SortingDayComponent },
    { path: 'inputDaySorting', component: InputDayStoringComponent },
    { path: 'management', component: ManagementComponent },
    { path: 'userManagement', component: UserManagementComponent },
    { path: 'systemConstitution', component: SystemConstitutionComponent, canActivate: [AuthGuard], canDeactivate: [UnsavedChangesGuard] },
    { path: 'parameterManagement', component: ParameterManagementComponent },
    {
        path: 'requestsandlicenses', component: RequestsLicensesTabsComponent,
        children: [
            { path: '', children: [], pathMatch: 'full', canActivate: [IsraelRedirectGuard] },
            { path: 'requests', component: StatusRequestComponent, data: ɵ0, canActivate: [AuthGuard, IsraelGuard] },
            { path: 'licences', component: AllCurrentEmployeesStatusComponent, data: ɵ1, canActivate: [AuthGuard] },
        ],
        canActivate: [AuthGuard]
    },
    { path: 'employmentapplication', component: WizardStepperComponent, canActivate: [AuthGuard, IsraelGuard] },
    { path: 'poolEmployees', component: PoolEmployeesComponent, canActivate: [AuthGuard, IsraelGuard] },
    { path: 'savedAds', component: SavedAdsComponent, canActivate: [AuthGuard] },
    { path: 'alertMessages', component: AlertMessagesComponent },
    { path: 'updateAlertMessage', component: UpdateAlertMessageComponent },
    { path: 'wantAds', component: WantAdsComponent },
    { path: 'reports', component: ReportsComponent },
    { path: 'queries', component: QueriesComponent },
    { path: '**', component: PageNotFoundComponent }
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
export { ɵ0, ɵ1 };
