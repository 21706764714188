import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import isIsraeliIdValid from 'israeli-id-validator';
import { FormService } from 'src/app/wizard/service/form.service';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import {FieldService} from "../../../service/field.service";
import {FieldsRequest} from "../../../../models/interfaces";


@Component({
  selector: 'id-numbers-form',
  templateUrl: './id-numbers-form.component.html'
})


export class IdNumberForComponent implements OnInit {
  @Output() onSubmitIdNumbers: EventEmitter<any[]> = new EventEmitter();
  @Input() initialData?: string;
  @Input() redirectUrl?: string;
  @Input() set selectedField (field:FieldsRequest){
    this._selectedField = field;
    // this.resetAll();
  }
  
  @Input() generalError:string;
  errorMessage: string = "";
  numberRegex = new RegExp("^[0-9\\s]*$");
  keyMap: any[] = [];

  idListStr: string = "";
  validIdNumbers: string[];

  invalidInputError: string = "";
  invalidIdNumbers: string[] = [];
  _selectedField:FieldsRequest = null;
  config: PerfectScrollbarConfigInterface = {
     wheelSpeed: 0.2
  };

  
  constructor(private formService: FormService,  public fieldService: FieldService) {
  }

  ngOnInit() {
    this.idListStr = this.initialData || "";
  }

  isSubmitButtonDisabled(): boolean {
    return !this.idListStr.trim();
  }
  onInputChange(e) {
    this.resetAll();
  }

  /* #region  Submit And Validations */
  submitIdNumbers() {
    this.resetAll();

    if (!this.chainValidate(this.validateIdNumbersInput.bind(this), this.validateIdNumbers.bind(this))) {
      return;
    }

    this.onSubmitIdNumbers.emit(this.validIdNumbers);
  }

  validateIdNumbersInput() {
    const res = this.numberRegex.test(this.idListStr);

    if (!res) {
      this.errorMessage = "קלט לא תקין"
    }

    return res;
  }

  validateIdNumbers() {
    const idListStrTrimmed = this.idListStr.replace(/ /g, '');
    const idNumberList: string[] = idListStrTrimmed.split(/\r?\n/g).filter(i => i !== "");
    const invalidIds: string[] = idNumberList.filter(i => !isIsraeliIdValid(i) || i.length !== 9)


    if (invalidIds.length) {
      this.errorMessage = "ישנם מספרי תעודת זהות אשר לא הוזנו בצורה תקינה."
      this.invalidIdNumbers = invalidIds;
    }
    else {
      this.validIdNumbers = [...new Set(idNumberList)];
    }

    return !invalidIds.length;
  }
  /* #endregion */

  resetAll() {
    this.invalidIdNumbers = [];
    this.errorMessage = "";

    this.validIdNumbers = [];
    this.invalidIdNumbers = [];
  }

  /* #region Key Event Handlers  */
  onKeyDown(e) {
    const keyCode = e.keyCode;

    if (!this.keyMap.includes(keyCode)) {
      this.keyMap.push(keyCode);
    }

    if (e.keyCode > 31 && !this.isSpaceOrEnterKey(keyCode) && !this.isNumberKey(keyCode)
      && !this.isArrowsKey(keyCode) && !this.isDeltetKey(keyCode)
      && !this.isCopyOrPasteKey() && !this.isSelectAllKeys()) {
      event.preventDefault();

      this.invalidInputError = "הוכנס תו לא תקין"
      setTimeout(() => {
        this.invalidInputError = "";
      }, 650)
    }
  }

  onKeyUp() {
    // Reset the keymap for the next operation
    this.keyMap = [];

  }

  onPaste(e) {
    const clipboardData = e.clipboardData || (<any>window).clipboardData;
    const pastedData = clipboardData.getData('Text');


    if (!pastedData || !this.numberRegex.test(pastedData)) {
      e.preventDefault();
    }
  }

  isNumberKey(keyCode: number) {
    return (keyCode >= 48 && keyCode <= 57) || (keyCode >= 96 && keyCode <= 105);
  }

  isCopyOrPasteKey() {
    // ctrlKey-17, cmdKey-91, vKey-86, cKey-67

    return (this.keyMap.includes(17) || this.keyMap.includes(91))
      && (this.keyMap.includes(86) || this.keyMap.includes(67));
  }

  isSelectAllKeys() {
    //ctrlKey-17, a-65
    return this.keyMap.includes(17) && this.keyMap.includes(65)
  }

  isArrowsKey(keyCode: number) {
    return keyCode >= 35 && keyCode <= 40
  }

  isDeltetKey(keyCode: number) {
    //Delete-46, Backspace-8
    return keyCode === 46 || keyCode === 8;
  }

  isSpaceOrEnterKey(keyCode: number) {
    //Enter-13, Space-32
    return keyCode === 32 || keyCode === 13;
  }
  /* #endregion */


  //TODO: MOVE TO UTILS
  chainValidate = (...validators: any) => {
    let success = true;
    let validator = null;

    for (validator of validators) {
      if (typeof validator !== 'function') {
        continue;
      }

      success = validator();
      if (!success) {
        return false;
      }
    }

    return true;
  };

}

