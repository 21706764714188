import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import {ConfirmModalData} from "../../../models/interfaces";

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal-component.html'
})
export class ConfirmModalComponent implements OnInit {
  header: string;
  message:string;
  ok:string;
  cancel:string;

  constructor(
    private dialogRef: MatDialogRef<ConfirmModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmModalData
  )  {
  }

  ngOnInit() {
    this.header = this.data.header;
    this.message = this.data.message;
    this.ok = this.data.ok || "אישור";
    this.cancel = this.data.cancel || "ביטול";

  }

  confirm() {
    this.dialogRef.close(true);
  }

  close() {
    this.dialogRef.close(false);
  }


}
