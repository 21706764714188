<header>
  <div class="container-fluid width-main">
    <section *ngIf="isNotLogin" class="headerTop d-flex">

      <button type="button" class="hamburger" (click)="toggleTabs()" [class.active]="isTabsActive" *ngIf="isUserAuth">
        <div class="line "></div>
      </button>

      <app-navigation-tabs (closeTabs)="closeTabs()" *ngIf="(isTabsActive || !isMobile) && isUserAuth">
      </app-navigation-tabs>

      <div class="d-flex mr-auto">
      <div class="accessibility"><i class="icon-accessibility"></i></div>

      <app-main-menu *ngIf="isUserAuth" [isMainMenuOpen]="isMainMenuOpen" (toggleMainMenu)="toggleMainMenu()"
        (closeMainMenu)="closeMainMenu()" class="d-flex align-items-center "></app-main-menu>

      <img class="navLogo align-self-baseline " src="./assets/imgs/mainLogo.png" />
      </div>
    </section>
  </div>
  <app-banner *ngIf="isNotLogin" [title]="title" [buttonString]="buttonString"></app-banner>

</header>
<div class="backDrop" (click)="closeAll()" *ngIf="(isMainMenuOpen || isTabsActive) && isMobile"></div>




<!-- עותק מקורי -->
<!-- <header>
  <section *ngIf="isNotLogin" class="headerTop">
      <div class="container-fluid">
        <button
          type="button"
          class="hamburger"
          (click)="toggleTabs()"
          [class.active]="isTabsActive"
          *ngIf="isUserAuth"
        >
          <div class="line"></div>
        </button>
        <app-main-menu
          *ngIf="isUserAuth"
          [isMainMenuOpen]="isMainMenuOpen"
          (toggleMainMenu)="toggleMainMenu()"
          (closeMainMenu)="closeMainMenu()"
        ></app-main-menu>
      </div>
  </section>
  <app-banner *ngIf="isNotLogin"></app-banner>
  <app-navigation-tabs
    (closeTabs)="closeTabs()"
    *ngIf="(isTabsActive || !isMobile) && isUserAuth"
  ></app-navigation-tabs>
</header>
<div class="backDrop" (click)="closeAll()" *ngIf="(isMainMenuOpen || isTabsActive) && isMobile"></div> -->