import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ModalData } from './interface';
import { Router } from '@angular/router';

@Component({
  selector: 'app-messages-modal',
  templateUrl: './messages-modal.component.html',
  styleUrls: ['./messages-modal.component.scss']
})
export class MessagesModalComponent implements OnInit {
  modalData: ModalData;
  constructor(
    private dialogRef: MatDialogRef<MessagesModalComponent>,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.modalData = data;
  }

  ngOnInit() {}

  closeModal() {
    this.dialogRef.close();
  }

  goTo(path: string){
    this.router.navigate([path]).then(() => this.closeModal());
  }
}
