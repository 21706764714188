import { Injectable } from '@angular/core';
import {FormControl} from "@angular/forms";
import {
  Docs,
  FieldsRequest
} from "../../models/interfaces";
import {WizradUserDataService} from "./wizrad-user-data.service";
import {FormService} from "./form.service";
import {Subscription} from "rxjs";


@Injectable({
  providedIn: 'root'
})
export class BuildingPermitService {
  private selectedField:FieldsRequest = null;
  private fieldsSubscription: Subscription = null;

  constructor(private wizradUserDataService: WizradUserDataService, private formService: FormService) {

  }


  getBuildingPermitRef(): FormControl {
   return this.formService.getForm().get("buildingPermit") as FormControl;
  }


  allowBuildingPermitUpload(preventEmployeesCheckCondition = false): boolean {
    if(this.wizradUserDataService.initialHasPermit || !this.selectedField){
      return false;
    }

    const requestDetails = this.formService.getFormGroupRefByName("requestDetails");
    const isRenovation = requestDetails.get("renovating");

    return this.selectedField.code === 4
      && !!this.selectedField.employmentLimitation
      && isRenovation && isRenovation.value === false
      && (this.selectedField.currentEmployed >= this.selectedField.employmentLimitation || preventEmployeesCheckCondition)
  }


  hasBuildingPermitUploaded(){
    return !!this.getBuildingPermitRef().value;
  }

  getBuildingPermitDownloadDocument(): Docs{
   return this.wizradUserDataService.getDownloadDocumentByName("buildingPermit");
  }

  init() {
    this.fieldsSubscription = this.formService.getFormGroupRefByName("requestDetails").get('fields').valueChanges.subscribe(field => {
      this.selectedField = field
    });
  }

  dispose(){
    this.fieldsSubscription.unsubscribe();
  }
}
