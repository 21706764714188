import { Component, OnInit, Input,Output, EventEmitter } from '@angular/core';
import { AlertMessages, AlertsMessagesEnum } from 'src/app/models/alertMessages';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SubBrancModel,BranchModel } from 'src/app/models/Employee';
import { AlertsMessagesEnumModel } from 'src/app/models/AlertsMessagesEnumModel';
import { EmployeeService } from 'src/app/services/employee-service';
import { DatePipe } from '@angular/common';
import { Area,EmployerType,SubArea } from 'src/app/models/metadataModels';


@Component({
  selector: 'app-update-alert-message',
  templateUrl: './update-alert-message.component.html',
  styleUrls: ['./update-alert-message.component.scss']
})
export class UpdateAlertMessageComponent implements OnInit {

  @Input() alertMessage : AlertMessages;
  @Input() updateMode:boolean;
  @Output()
  updateAlertMessageEvent : EventEmitter<AlertMessages> = new EventEmitter<AlertMessages>();
  @Output()
  deleteAlertMessageEvent : EventEmitter<AlertMessages> = new EventEmitter<AlertMessages>();
  updateAlertMessageFG: FormGroup;
  areas:Area[] = []
  subAreas: SubArea[] 
  allsubAreas: SubArea[] 
  subAreasFiltered: SubArea[] 
  branches : BranchModel[]
  subBranches : SubBrancModel[] 
  allSubBranches:SubBrancModel[]
  subBranchesFiltered : SubBrancModel[]=[]
  statuses : AlertsMessagesEnumModel[]=[]
  allStatuses : AlertsMessagesEnumModel[]=[]
  employerTypes : EmployerType[] = []
  currentStatus : AlertsMessagesEnumModel = new AlertsMessagesEnumModel();
  hotJobOptions : any[] = [{value:true,desc:"כן"},{value:false,desc:"לא"}]
  sendData:any = {  alertMessage : null,
                    status : null,
                    delete : null
                  };
  submitted : boolean = false;
  
  

  constructor(private fb: FormBuilder,private employeeService:EmployeeService,public datePipe : DatePipe) { }

  ngOnInit() {
    this.initStatuses();
    this.createForm(); 
    this.getOptionsData()
    this.initFormControls();
    this.updateStatusesOptions();
  }
  createForm(): void {
    this.updateAlertMessageFG = this.fb.group({
      _id:[null],
      title: [null,[Validators.required]],
      content: [null,[Validators.required]],
      fromDate: [null],
      toDate: [null],
      statusId:[null],
      areaId: [null],
      subAreaId: [null],
      branch: [null],
      subBranchId: [null],
      employerTypeId:[null],
      hotJob:[null]
    });
  }

  initStatuses(){
    this.statuses = [
                      {id:AlertsMessagesEnum.Active, desc : "Active",hebrewDesc: "פעיל"},
                      {id:AlertsMessagesEnum.Draft,desc : "Draft",hebrewDesc: "מבוטל"},
                      {id:AlertsMessagesEnum.Inactive ,desc : "InActive",hebrewDesc: "לא פעיל"}
                    ]
    this.allStatuses = this.statuses;
  }

  getOptionsData() {
    this.employeeService.getEmployeePoolFilter().subscribe(res => {
      this.branches = res.Branches;
      this.subBranches = res.SubBranches;
    })

    this.employeeService.getAreas().subscribe(res=>{
      this.areas = res.data;
    })

    this.employeeService.getSubAreas().subscribe(res=>{
      this.subAreas = res.data;
      this.allsubAreas = this.subAreas;
    })

    this.employeeService.getEmployerTypes().subscribe(res=>{
      this.employerTypes = res.data;
    })
  }

  subAreaFilter() {
    if(!this.updateAlertMessageFG.controls.areaId.value || !this.allsubAreas) return;
    var areaId = this.updateAlertMessageFG.controls.areaId.value._id;
    this.subAreasFiltered = this.allsubAreas.filter((subArea : SubArea)=> subArea.area == areaId);
    this.subAreas = this.subAreasFiltered;
  }
  subBranchFilter(){
     if(!this.updateAlertMessageFG.controls.branch.value || !this.allSubBranches) return;
     var branchID = this.updateAlertMessageFG.controls.branch.value._id;
     this.subBranchesFiltered = this.allSubBranches.filter((subBranch : SubBrancModel)=> subBranch.branch._id == branchID);
     this.subBranches = this.subBranchesFiltered;
  }

  BrunchesSelectionChange(){
    this.subBranchFilter();
  }

  deleteAlertMessage(){
    if(!!this.updateAlertMessageFG.controls._id.value){
      this.sendData.delete = true ;
      this.currentStatus.desc = 'Draft'
      this.currentStatus.hebrewDesc = 'מבוטל'
      this.currentStatus.id = 2
      this.updateAlertMessageFG.get("statusId").setValue(this.currentStatus);
    }else{
      this.sendData.delete = false ;
    }
      this.submit()
  }

  initFormControls(){ 
    this.initStatusControl();
    if(this.updateMode==false || !this.alertMessage){
      this.initDatesControls();
      return;
    } 
    let fromDate = this.datePipe.transform(this.alertMessage.fromDate,'yyyy-MM-dd')
    this.alertMessage.fromDate = new Date(fromDate)
    let toDate = this.datePipe.transform(this.alertMessage.toDate,'yyyy-MM-dd')
    this.alertMessage.toDate = new Date(toDate)
    this.updateAlertMessageFG.get("_id").setValue(this.alertMessage._id);
    this.updateAlertMessageFG.get("title").setValue(this.alertMessage.title);
    this.updateAlertMessageFG.get("content").setValue(this.alertMessage.content);
    this.updateAlertMessageFG.get("fromDate").setValue(this.alertMessage.fromDate);
    this.updateAlertMessageFG.get("toDate").setValue(this.alertMessage.toDate);
    this.updateAlertMessageFG.get("areaId").setValue(!!this.alertMessage.subAreaId ? this.alertMessage.subAreaId.area:'');
    this.updateAlertMessageFG.get("subAreaId").setValue(this.alertMessage.subAreaId);
    this.updateAlertMessageFG.get("branch").setValue(this.alertMessage.subBranchId ? this.alertMessage.subBranchId.branch : '');
    this.updateAlertMessageFG.get("subBranchId").setValue(this.alertMessage.subBranchId);
    this.updateAlertMessageFG.get("employerTypeId").setValue(this.alertMessage.employerTypeId);
    if(!!this.alertMessage.hotJob){
       this.updateAlertMessageFG.get("hotJob").setValue( this.alertMessage.hotJob == true ? this.hotJobOptions[0].value : this.hotJobOptions[1].value);
    }
  }
  
  initDatesControls(){
    let fromDate = new Date();
    let toDate = new Date();
    this.updateAlertMessageFG.get("fromDate").setValue(fromDate);
    toDate.setMonth(fromDate.getMonth() + 1);
    this.updateAlertMessageFG.get("toDate").setValue(toDate);
  }

  
  initStatusControl(){
    this.currentStatus.id = !!this.alertMessage ? this.alertMessage.statusId : AlertsMessagesEnum.Active
    let statusDesc = [{desc : "Active",hebrewDesc: "פעיל"},{desc : "Draft",hebrewDesc: "מבוטל"},{desc : "InActive",hebrewDesc: "לא פעיל"}]
    this.currentStatus.desc = statusDesc[this.currentStatus.id-1].desc;
    this.currentStatus.hebrewDesc = statusDesc[this.currentStatus.id-1].hebrewDesc;
    this.updateAlertMessageFG.get("statusId").setValue(this.currentStatus);
  }

  updateStatusesOptions(){
    let toDate = new Date(this.updateAlertMessageFG.controls.toDate.value);
    let today= new Date();
      if( toDate > today){
      this.statuses = this.allStatuses
      return;
    }
    this.statuses= this.statuses.filter(s=>s.id !== AlertsMessagesEnum.Active)
  }

  submit(){
  this.submitted = true;
  if(!this.updateAlertMessageFG.valid) return;
  this.sendData.alertMessage = this.updateAlertMessageFG.value,
  this.sendData.status = this.updateAlertMessageFG.controls.statusId.value
  this.updateAlertMessageEvent.emit(this.sendData);
  this.submitted = false;
  }



}
