import { HttpClient } from "@angular/common/http";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
// import { NgxSpinnerModule } from "ngx-spinner";
// Config
import { AppConfig } from "./app-config/app.config";
import { applicationHttpClientCreator } from './shared/service/ApplicationHttpClient';
export function initializeApp(appConfig) {
    return function () { return appConfig.load(); };
}
export function HttpLoaderFactory(httpClient) {
    return new TranslateHttpLoader(httpClient);
}
export function tokenGetter() {
    return localStorage.getItem("access_token");
}
var DEFAULT_PERFECT_SCROLLBAR_CONFIG = {
    suppressScrollX: true
};
var ɵ0 = applicationHttpClientCreator, ɵ1 = DEFAULT_PERFECT_SCROLLBAR_CONFIG;
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
export { ɵ0, ɵ1 };
