import { Component, OnInit, Inject, Input, Output, EventEmitter } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { CancelLicense, CancelReason, CurrentEmployee } from "src/app/models/interfaces";
import { EmployeeService } from "src/app/services/employee-service";
@Component({
  selector: "app-licenses-cancel-modal",
  templateUrl: "./licenses-cancel-modal.component.html",
  styleUrls: ['./licenses-cancel-modal.component.scss']
})
export class LicensesCancelModalComponent implements OnInit {
  licensesToCancel: CancelLicense[] = []
  approveCheckbox: boolean = false;
  cancelReason: CancelLicense
  // showModal:boolean=false;

  @Input() dialogData: any = {};
  @Input() cancelLicence: boolean;
  @Output() statusToEmit = new EventEmitter<any>();


  constructor(
    // private dialogRef: MatDialogRef<LicensesCancelModalComponent>, public employeeService:EmployeeService,
    // @Inject(MAT_DIALOG_DATA) public data: {cancelReasons:CancelReason[],validEmployees:CurrentEmployee[], invalidEmployees:CurrentEmployee[]}
  ) { }


  ngOnInit() {
  }



  /*addCancelReason(evt: any, employee: CurrentEmployee) {
    const licenseToCancel  = this.licensesToCancel.find(l=>l.LicenseId === employee.licenseId);
    if(licenseToCancel){
      licenseToCancel.CancelLicenseReason = evt.value;
    }
  }*/

  isSubmitDisabled(): boolean {
    return !this.approveCheckbox || !this.cancelReason;
  }

  sendStatusModalToParent(close) {
    this.cancelLicence = false
    const ifClose = close == 1 ? 1 : 0;
    this.statusToEmit.emit({ cancelLicence: this.cancelLicence, data: this.cancelReason, close: ifClose })
  }

  closeModal() {
    this.cancelLicence = false
  }
}
