import * as tslib_1 from "tslib";
import { OnInit, ElementRef, OnDestroy } from '@angular/core';
import { RequestStatusData, CurrentEmployeeExtended, User } from 'src/app/models/interfaces';
import applicationtStatusTypes from 'src/app/shared/util/applicationtStatusTypes';
import { MatDialog } from '@angular/material';
import 'jspdf-autotable';
// import { EmailService } from 'src/app/shared/service/email.service';
import { Pdf } from 'src/app/models/pdf';
var StatusRequestComponent = /** @class */ (function () {
    function StatusRequestComponent(licenseService, printService, dialog, 
    // public emailService:EmailService,
    userDataService) {
        this.licenseService = licenseService;
        this.printService = printService;
        this.dialog = dialog;
        this.userDataService = userDataService;
        this.displayedColumns = [
            { header: 'מספר פנייה', field: 'applicationID' },
            { header: 'שם העובד', field: 'fullName' },
            { header: 'מספר זהות', field: 'id' },
            { header: 'סטטוס', field: 'licenseStatus.name' },
            { header: 'בקשה לאזור', field: 'isLicenseInIsrael' },
            { header: 'הערות', field: 'declineReason' },
            { icon: 'icon-warning', field: 'status' }
        ];
        this.displayModal = false;
        this.option = [{ "name": "שלי בלבד", "id": 1 }, { "name": "כלל הבקשות", "id": 2 }];
        this.textToShow = { "name": "כלל הבקשות", "id": 2 };
        this.listExcel = new Array();
        // textToShow : string;
        this.pdf = new Pdf();
        this.alredyOpen = false;
        this.config = {
            wheelSpeed: 0.2
        };
        this.date = new Date();
    }
    Object.defineProperty(StatusRequestComponent.prototype, "content1", {
        set: function (content) {
            this.sort = content;
            if (this.sort) {
                this.dataSource.sort = this.sort;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StatusRequestComponent.prototype, "applicationtStatusTypes", {
        get: function () {
            return applicationtStatusTypes;
        },
        enumerable: true,
        configurable: true
    });
    StatusRequestComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.userDataService.getUser1().subscribe(function (u) {
            _this.user1 = u.user;
        });
        this.initAll();
    };
    StatusRequestComponent.prototype.ngAfterViewInit = function () {
        this.initAll();
    };
    StatusRequestComponent.prototype.initAll = function (hardReload) {
        var _this = this;
        if (hardReload === void 0) { hardReload = false; }
        this.licenseService.getLicensesData().subscribe(function (requestStatusData) {
            var manipulatedData = _this.populateCurrentEmployeeExtended(requestStatusData);
            _this.requestStatusData = requestStatusData;
            if (manipulatedData.length > 0) {
                _this.printService.showPrintButton();
            }
            _this.dataSource = manipulatedData;
            _this.dataSource = _this.dataSource.sort(function (a1, a2) {
                return Number(a2.applicationID) - Number(a1.applicationID);
            });
            _this.excel = _this.exportExcel();
            _this.printService.insertHTMLdata('requestStatus', 'applicationTable', _this.content, _this.excel);
            _this.printService.setCurrentRawData('requestStatus');
        });
    };
    StatusRequestComponent.prototype.populateCurrentEmployeeExtended = function (requestStatusData) {
        var flattendData = [];
        requestStatusData.requestsStatus.forEach(function (r) {
            r.employees.forEach(function (employee) {
                flattendData.push(tslib_1.__assign({ applicationID: r.applicationID, createdTime: r.createdTime, applicationType: r.applicationType }, employee));
            });
        });
        return flattendData;
    };
    StatusRequestComponent.prototype.getPropertyByPath = function (data, pathString) {
        var value = pathString.split('.').reduce(function (o, i) { return o[i]; }, data);
        // if (Array.isArray(value)) {
        //   return value[0].name;
        // }
        return value;
    };
    StatusRequestComponent.prototype.getRowColor = function (row) {
        return this.licenseService.getStatusColor(row.applicationStatus ? row.applicationStatus.code : null);
    };
    StatusRequestComponent.prototype.openDocumentsErrorModal = function (row) {
        this.title = this.populateCommentTitle(row);
        this.text = row.comment;
        this.displayModal = true;
    };
    StatusRequestComponent.prototype.filterTable = function () {
        var _this = this;
        if (this.textToShow.id == 1) {
            this.dataSource = this.dataSource.filter(function (user) {
                return user.id == _this.user1.identity;
            });
            this.excel = this.exportExcel();
            this.printService.insertHTMLdata('requestStatus', 'applicationTable', this.content, this.excel);
            this.printService.setCurrentRawData('requestStatus');
        }
        else {
            this.initAll();
        }
    };
    StatusRequestComponent.prototype.populateCommentTitle = function (row) {
        var title = '';
        var applicationStatus = row.applicationStatus ? row.applicationStatus.code : null;
        switch (applicationStatus) {
            case applicationtStatusTypes.APPROVED:
                title = row.restriction ? row.restriction.name : '';
                break;
            case applicationtStatusTypes.DECLINED:
                title = row.declineReason ? row.declineReason.name : '';
                break;
        }
        return title;
    };
    StatusRequestComponent.prototype.ngOnDestroy = function () {
        this.printService.hidePrintButton();
    };
    StatusRequestComponent.prototype.exportExcel = function () {
        var _this = this;
        this.listExcel = [];
        if (this.dataSource) {
            this.dataSource.forEach(function (a) {
                // this.listExcel.push({id:a.fullName, name:a.applicationID})
                _this.listExcel.push({ apllicationId: a.applicationID, createTime: a.createdTime, fullName: a.fullName, identity: a.id,
                    status: a.applicationStatus.name, area: a.isLicenseInIsrael ? 'ישראל' : 'התיישבות', comment: a.comment });
            });
            this.alredyOpen = true;
            return this.listExcel;
        }
    };
    return StatusRequestComponent;
}());
export { StatusRequestComponent };
