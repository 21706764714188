import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from "@angular/core";
import { FormGroup, FormControl } from "@angular/forms";
// import { FileInputComponent } from "ngx-material-file-input";
import { checkForValidFiles } from "../../validators/files.validator";
import { UploadService } from '../../service/UploadService';
import {
  ConfirmModalData,
  Docs,
  UploadedFile
} from 'src/app/models/interfaces';
import {DialogService} from "../../service/dialog.service";
import {ConfirmModalComponent} from "../confirm-modal/confirm-modal-component";
import {MatDialogConfig} from "@angular/material";
@Component({
  selector: "app-upload-file",
  templateUrl: "./upload-file.component.html",
  styles: []
})

export class UploadFileComponent implements OnInit {
  @Output() onFileUpload: EventEmitter<UploadedFile> = new EventEmitter();
  @Output() onFileDelete: EventEmitter<any> = new EventEmitter();
  @Output() onDeleteConfirm: EventEmitter<any> = new EventEmitter();
  @Input() formControlRef?: FormControl;
  @Input() downloadDocument?: Docs;
  @Input() requiredFileUpload? = false;
  @Input() preventFileDelete? = false;
  @Input() beforeDeleteInfo?:ConfirmModalData = null;
  @Input() deleteFileTooltipText? = 'לא ניתן למחוק את הקובץ המצורף';
  @Input() disabled? = false;


  uploadedFile: UploadedFile = null;
  componentUid: string;
  validFile = true;
  uploadSucces = true;

  errorMessage = "*קובץ לא תקין";
  uploadErrorMessage = "";
  dialogConfig:MatDialogConfig;

  constructor(private uploadService: UploadService, private dialogService: DialogService) {
  }

  ngOnInit() {
    
    this.componentUid = 'file_' + Math.random().toString(36).substr(2, 9);

    if (this.formControlRef) {
      this.formControlRef.valueChanges.subscribe(uploadedFile => {
        this.uploadedFile = uploadedFile as UploadedFile;
      });

      if (this.formControlRef.value && this.formControlRef.value.id) {
        this.uploadedFile = this.formControlRef.value as UploadedFile;
      }
    }

    this.setDialogConfig();
  }

  setDialogConfig(){
    this.dialogConfig = {
      panelClass: "generalModal",
    };
  }
  async addFile(e) {
    const file = e.target.files[0];
    if (!this.isFileInvalid(file)) {
      this.uploadFileToServer(file);
    }
  }

  setFile(payload: UploadedFile): void {
    if (this.formControlRef) {
      this.formControlRef.setValue(payload);
    }
    this.onFileUpload.emit(payload);
  }

  uploadFileToServer(uploadFile: File): void {
    if (uploadFile) {
      this.uploadErrorMessage = "";
      this.uploadService.UploadFile(uploadFile, this.downloadDocument.code)
        .subscribe({next:this.onUploadSuccess.bind(this), error:this.onFail.bind(this)});
    }
  }

  onUploadSuccess(payload: UploadedFile): void {
    const payloadData = { ...payload };
    payloadData.fileTypeId = this.downloadDocument.code;

    this.uploadedFile = payloadData;
    this.setFile(payloadData);
  }

  removeFile(): void {
    if (!this.uploadedFile) {
      return;
    }

    if (this.beforeDeleteInfo) {
      const modalData = {
        header: this.beforeDeleteInfo.header,
        message: this.beforeDeleteInfo.message
      }as ConfirmModalData;

      const dialogRef = this.dialogService.openModal(ConfirmModalComponent, modalData, this.dialogConfig);
      dialogRef.afterClosed().subscribe(confirm => {
        if (confirm) {
          this.deleteFile();
          this.onDeleteConfirm.emit()
        }
      });
    }
    else {
      this.deleteFile();
    }
  }

  deleteFile(){
    this.uploadErrorMessage = "";
    this.uploadService.deleteFile(this.uploadedFile.id).subscribe({next:this.onDeleteSuccess.bind(this), error:this.onFail.bind(this)});
  }

  onDeleteSuccess(): void {
    this.uploadedFile = null;
    this.deleteRefFile();
  }

  getDownloadUserFileUrl(): string {
    this.uploadErrorMessage = "";
    return this.uploadedFile ? this.uploadService.generateDownloadFileOfUser(this.uploadedFile.id) : "javascript:void(0)"
  }

  getDownloadFileUrl(): string {
    this.uploadErrorMessage = "";
    return this.downloadDocument.hasDocumentToDownload && !this.disabled ? this.uploadService.generateDownloadFile(this.downloadDocument.code) : "javascript:void(0)"
  }

  onFail(payload): void {
    this.uploadErrorMessage = payload.error;
  }


  isFileInvalid(file: File): boolean {

    if (!file) {
      return;
    }

    const isInvalid = checkForValidFiles(file);

    if (isInvalid) {
      this.setErrors({ InvalidFiles: true });
      this.validFile = false;
    } else {
      this.setErrors(null);
      this.validFile = true;
    }

    return isInvalid;
  }


  deleteRefFile(): void {
    if (this.formControlRef) {
      this.formControlRef.setValue(null);
    }
    this.onFileDelete.emit();
  }

  setErrors(val): void {
    if (this.formControlRef) {
      if (val) {
        this.formControlRef.setErrors(val);
      }
      else {
        this.formControlRef.setErrors(null);
      }
    }
  }

  showError(): boolean {
    return !this.formControlRef && !this.validFile;
  }

  getFileName(){
    return this.formControlRef && this.formControlRef.value
      ? this.formControlRef.value.name
      : this.uploadedFile ? this.uploadedFile.name : "";
  }

  showUpload(): boolean {
    return (!this.formControlRef || (this.formControlRef && !this.formControlRef.value)) && !this.uploadedFile;
  }

}
