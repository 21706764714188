import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { BreakpointObserver } from '@angular/cdk/layout';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  isTabsActive = false;
  isMainMenuOpen = false;
  isNotLogin = false;
  isUserAuth: boolean = false;
  isMobile = false;
  title:string
  buttonString:string;
  

  constructor(private router: Router, public auth: AuthService, private breakpointObserver: BreakpointObserver) {
  }

  
  ngOnInit() {
    this.router.events.subscribe(e => {
      this.isMainMenuOpen = false;
      if (e instanceof NavigationEnd) {

        if (e.urlAfterRedirects=="/home"){
          this.title=`<h2> ברוכים הבאים</h2>
          <span> לאתר התעסוקה של מתפ"ש</span>
          `
        }
        if (e.urlAfterRedirects=="/poolEmployees"){
          this.title=`<h2>מודעות מחפשי </br>עבודה</h2>`
        }
        if (e.urlAfterRedirects=="/employmentapplication"){
          this.title=`<h2 class="header-employmentapplication">הגשת בקשות </br>להעסקה </h2>`
        }
        if (e.urlAfterRedirects=="/requestsandlicenses/requests"){
          this.title=`<h2> רשיונות<br/>צפייה בבקשות</h2>`
        }
        if (e.url=='/transitionsHistory'){
          this.title=`<h2>הצגת <br/>מעברים</h2>`
        }

        if (e.url=='/systemConstitution'){
          this.title=`<h2>חוקת מערכת</h2>`
        }

        if (e.url=='/management'){
          this.buttonString=`חזור`;
          this.title=`<h2>ניהול</h2>`
        }

        this.isUserAuth = this.auth.isAuthenticated()
        this.isNotLogin = e.url !== '/login';

        if(this.isMobile) {
          this.isTabsActive = false;
        }
      }
    });
    this.isTabsActive = !this.isMobile;
    this.breakpointObserver.observe('(min-width: 1024px)').subscribe((e)=> {
      this.isMobile = !e.matches;
      
    });
  }

  toggleTabs() {
    this.isMainMenuOpen = false;
    this.isTabsActive = !this.isTabsActive;
    if(this.isTabsActive) {
      document.body.classList.add('active');
    } else {
      document.body.classList.remove('active');
    }
    
    
  }

  closeTabs() {
    if (this.isMobile) {
      document.body.classList.remove('active');
      this.isTabsActive = !this.isTabsActive;
    }
  }

  closeAll() {
    this.isTabsActive = false;
    this.isMainMenuOpen = false;
    document.body.classList.remove('active');
  }

  closeMainMenu() {
    this.isMainMenuOpen = false;
  }

  toggleMainMenu = () => {
    if(this.isMobile) { 
      this.isTabsActive = false;

      if(!this.isMainMenuOpen) {
        document.body.classList.add('active');
      } else {
        document.body.classList.remove('active');
      }
    }
    this.isMainMenuOpen = !this.isMainMenuOpen;
  }
}