import { Component, OnInit } from '@angular/core';
import { Branch } from 'src/app/models/branch';
import { StoringDayService } from 'src/app/services/storing-day.service';
import { SubBranch } from 'src/app/models/subBranch';
import { Profession } from 'src/app/models/profession';
import { DatePipe } from '@angular/common';
import { CandidateStatusEnum, SortingDay } from 'src/app/models/sortingDay';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-sorting-day',
  templateUrl: './sorting-day.component.html',
  styleUrls: ['./sorting-day.component.scss']
})
export class SortingDayComponent implements OnInit {

  sortingDayForm: FormGroup;
  parameterSortingDay:FormGroup;

  listBranch: Array<Branch> = new Array<Branch>();
  listSubBranch: Array<SubBranch> = new Array<SubBranch>();
  newSub: Array<SubBranch> = new Array<SubBranch>();
  listProfession: Array<Profession> = new Array<Profession>();
  newProfession: Array<Profession> = new Array<Profession>();
  listSortingDay: Array<SortingDay> = new Array<SortingDay>();

  displayModal: boolean = false;
  displayModalEdit: boolean = false;
  displayModalDelete: boolean = false;

  showDateValid: Date = new Date();
  fromDate: Date = new Date();
  toDate: Date = new Date();
  sortingDay: SortingDay = new SortingDay();
  showSortingDay:boolean=false;

  newSortingDay: boolean = false;
  invalidFromToDates:boolean=false;
  showContact: boolean = false;
  title: string;
  content: string;
  img:string;

  constructor(public fb: FormBuilder, public storingDayService: StoringDayService, public datepipe: DatePipe, public router: Router) { }

  ngOnInit() {
    this.createForm();
    this.initFormControls();

    this.getAllBranches();
    this.getAllSubBranches();
    this.getAllProfessions();
  }

  getAllBranches() {
    this.storingDayService.getAllBranches().subscribe(data => {
      this.listBranch = data.data
    });
  }

  getAllSubBranches() {
    this.storingDayService.getAllSubBranches().subscribe(data => {
      this.listSubBranch = data.data
    });
  }

  getAllProfessions() {
    this.storingDayService.getAllProfession().subscribe(data => {
      this.listProfession = data.data
    });
  }

  createForm(): void {
    this.parameterSortingDay=this.fb.group({
      branch:[null, [Validators.required]],
      subBranch:[null, [Validators.required]],
      profession:[null, [Validators.required]],
      fromDate:[new Date(), Validators.required],
      toDate:[new Date(), Validators.required]
    })
  
    this.sortingDayForm = this.fb.group({
      branch: [null, [Validators.required]],
      subBranch: [null, [Validators.required]],
      professionId: [null, [Validators.required]],
      examDate: [null, [Validators.required]],
      fromHour: [null, [Validators.required]],
      toHour: [null, [Validators.required]],
      allocation: [null, [Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)]]
    });
  }

  initFormControls() {
    let day=new Date()
    day.setDate(day.getDate()+7)
    this.p.fromDate.setValue(new Date());
    this.p.toDate.setValue(day);
  }

  changeDate() {
    this.showSortingDay=false
    this.invalidFromToDates=new Date(this.p.fromDate.value).getTime()>new Date(this.p.toDate.value).getTime()
  }

  modalAddSortingDay() {
    this.displayModalEdit = true;
  }

  timeCorrect() {
    if (this.sortingDayForm.controls.toHour.value < this.sortingDayForm.controls.fromHour.value)
      return true;
  }

  selectProfession(){
    this.showSortingDay=false
  }

  selectSubBranchParameter() {
    this.showSortingDay=false
    if (!this.p.branch.value) {
      this.p.subBranch.setValue(new SubBranch());
      this.p.profession.setValue(new Profession());
      this.newSub = []; this.newProfession = []; this.listSortingDay = [];
      return;
    }

    this.newSub = this.listSubBranch.filter((sub) => {
      return sub.branch._id === this.p.branch.value._id;
    })
  }

  selectProfessionParameter() {
    this.showSortingDay=false;
    if (!this.p.subBranch.value) {
      this.p.profession.setValue(new Profession());
      this.listSortingDay = [];
      this.newProfession = [];
      return;
    }
    this.newProfession = this.listProfession.filter((profession) => {
      return profession.subBranch._id === this.p.subBranch.value._id;
    })
  }

  showSortingDays(item) {
    this.listSortingDay = [];
    const query = !!item.value ? item.value._id : this.sortingDayForm.controls.professionId.value._id;
    this.storingDayService.getSortingDays(query).subscribe(data => {
      this.listSortingDay = data.data;
      this.showSortingDay=true
    })
    this.showSortingDay = false;
  }

  getStatusModalEdit(status: any) {
    this.sortingDay = status.data;
    this.resetFormSortingDay()

    this.sortingDayForm.controls.branch.setValue(this.p.branch.value);
    this.sortingDayForm.controls.subBranch.setValue(this.p.subBranch.value);
    this.sortingDayForm.controls.professionId.setValue(this.p.profession.value);

    if (status.add == true) {
      let fromHour = new Date();
      let toHour = new Date();
      this.sortingDayForm.get("fromHour").setValue(new Date(fromHour.setHours(9, 0, 0, 0)));
      this.sortingDayForm.get("toHour").setValue(new Date(toHour.setHours(16, 0, 0, 0)));
      this.newSortingDay = true;
      this.displayModalEdit = true;
      return;
    }
    this.newSortingDay = false;
    let examDate = this.datepipe.transform(this.sortingDay.examDate, 'yyyy-MM-dd')
    this.sortingDay.examDate = new Date(examDate)

    this.sortingDayForm.controls.examDate.setValue(this.sortingDay.examDate);
    this.sortingDayForm.controls.fromHour.setValue(new Date(this.sortingDay.fromHour));
    this.sortingDayForm.controls.toHour.setValue(new Date(this.sortingDay.toHour));
    this.sortingDayForm.controls.allocation.setValue(this.sortingDay.allocation);

    this.displayModalEdit = status.display;
  }

  delete() {
    if (new Date(this.sortingDay.examDate) < new Date()) {
      this.title = "מחיקת יום מיונים";
      this.content = "לא ניתן למחוק יום מיונים שהגיעו תוצאות לגביו";
      this.displayModal = true;
      this.img;
      return;
    };
    this.displayModalDelete = true;
  }

  deleteSortingDay() {
    if (this.sortingDay.candidates) {
      const employees = this.sortingDay.candidates.map((emp) => {
        return emp.employeeId._id;
      });

      const data = {
        employee: employees,
        status: CandidateStatusEnum.Register
      }
      this.storingDayService.updateStatusEmployeeProfession(data, this.sortingDayForm.controls.professionId.value._id).subscribe(data => {
      })
    }
    this.showSortingDay = false;
    this.storingDayService.deleteSortingDay(this.sortingDay._id, this.sortingDay.professionId._id).subscribe(data => {
      this.displayModalDelete = false;
      this.showSortingDay = true;
      this.listSortingDay = data.data
      this.displayModalEdit = false
    })
  }

  resetFormSortingDay() {
    this.sortingDayForm.reset()
  }

  get f() { return this.sortingDayForm.controls; }
  get p() { return this.parameterSortingDay.controls; }

  continue() {
    this.showSortingDay = false;
    if (this.newSortingDay == true) {
      this.storingDayService.create(this.sortingDayForm.value).subscribe(data => {
        this.showSortingDay = true;
        this.listSortingDay = data.data;
        this.displayModalEdit = false
      });
      return;
    }

    this.sortingDayForm.value._id = this.sortingDay._id
    this.storingDayService.updateOrCreate(this.sortingDayForm.value).subscribe(data => {
      this.showSortingDay = true;
      this.listSortingDay = data.data;
      this.displayModalEdit = false;
    })
  }

  closeModal() {
    this.displayModal = false;
  }

  getStatusModal(status: boolean) {
    this.displayModal = status;
  }
}
