import { take } from 'rxjs/operators';
import * as i0 from "@angular/core";
var UnsavedChangesGuard = /** @class */ (function () {
    function UnsavedChangesGuard() {
    }
    UnsavedChangesGuard.prototype.canDeactivate = function (component, currentRoute, currentState, nextState) {
        if (!component.unsavedChanges) {
            return true;
        }
        component.showMessega();
        return component.canDeactivate.pipe(take(1));
    };
    UnsavedChangesGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UnsavedChangesGuard_Factory() { return new UnsavedChangesGuard(); }, token: UnsavedChangesGuard, providedIn: "root" });
    return UnsavedChangesGuard;
}());
export { UnsavedChangesGuard };
