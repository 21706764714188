import { of } from 'rxjs';
import applicationtStatusTypes from 'src/app/shared/util/applicationtStatusTypes';
import colorText from '../shared/util/colorText';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../shared/service/ApplicationHttpClient";
var LicenseService = /** @class */ (function () {
    function LicenseService(http) {
        this.http = http;
    }
    LicenseService.prototype.getLicensesData = function () {
        var _this = this;
        if (this.requestStatusData) {
            return of(this.requestStatusData);
        }
        return this.http.Get("api/statuses/getEmployerApplicationsStatus").pipe(map(function (res) {
            _this.requestStatusData = res;
            return res;
        }));
    };
    LicenseService.prototype.resetRequestStatusData = function () {
        this.requestStatusData = null;
    };
    LicenseService.prototype.getStatusColor = function (applicationStatusCode) {
        var color;
        switch (applicationStatusCode) {
            case applicationtStatusTypes.APPROVED:
                color = colorText.BLUE;
                break;
            case applicationtStatusTypes.PENDING:
                color = colorText.GREY;
                break;
            case applicationtStatusTypes.DECLINED:
                color = colorText.RED;
                break;
            default:
                color = colorText.GREY;
        }
        return color;
    };
    LicenseService.prototype.getLicense = function () {
        return this.http.Get("api/terms_and_conditions/active");
    };
    LicenseService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LicenseService_Factory() { return new LicenseService(i0.ɵɵinject(i1.ApplicationHttpClient)); }, token: LicenseService, providedIn: "root" });
    return LicenseService;
}());
export { LicenseService };
