<section class="navTabsSection">
    <div class="navTabWrapper">
      <div class="navTab">
       
        <nav mat-tab-nav-bar>
          <a
            mat-tab-link
            *ngFor="let link of navLinks"
            [routerLink]="link.path"
            routerLinkActive
            #rla="routerLinkActive"
            [active]="rla.isActive"
            (click)="close_Menu()"
            [disabled]="link.isDisabled"
            class="d-flex flex-md-column"
          >
          <!-- *ngIf="isMobile" -->
            <!-- <app-svg [svgType]= "link.svgType" ></app-svg> -->
            <i class="{{link.icon}} navTabIcon"></i>
            <!-- *ngIf="link.label && !link.img && !isMobile" -->
            <span  class="link link--underLineHover">{{ link.label }}</span>

            <!-- <img *ngIf="link.img && !isMobile" src="{{ link.img }}" /> -->
          </a>
        </nav>

        
      </div>
    </div>
</section>
