import { Injectable, ElementRef, ViewChild } from "@angular/core";
import { Subject } from "rxjs";
import { EmailService } from "./email.service";
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import 'jspdf-autotable';
import { Pdf } from "src/app/models/pdf";
import { User } from "src/app/models/interfaces";
import { UserDataService } from "src/app/services/user-data.service";
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';


@Injectable({
  providedIn: "root"
})
export class PrintService {
  printConfig?: { object: any; type: any; properties?: any[] };
  dataSources: any;
  currentData: any;
  showPrintBtn = new Subject<boolean>();
  pdf: Pdf = new Pdf()
  user1: User
  dataSource: any
  success:boolean=false
  @ViewChild('content', { static: false }) content: ElementRef;

   EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
   EXCEL_EXTENSION = '.xlsx';
  
  constructor(
    public emailService: EmailService,
    public userDataService: UserDataService) { }

  setCurrentRawData(propName: string) {
    this.currentData = propName;
  }

  setPrintConfig(
    printConfig: {
      object: any;
      type: any;
      properties?: any[];
      targetStyles?: any[];
      css?: any;
    },
    rawData?: any
  ) {
    this.printConfig = printConfig;
    this.printConfig.object = rawData || null;
  }

  getPrintConfig(): {
    object: any;
    type: any;
    properties?: any[];
    targetStyles?: any[];
    css?: any;
  } {
    this.printConfig.object = this.dataSources[this.currentData].rawData;
    this.dataSources[this.currentData].properties
      ? (this.printConfig.properties = this.dataSources[
        this.currentData
      ].properties)
      : null;
    return this.printConfig;
  }

  insertRawData(propName: string, data: any, model: string) {
    if (!this.dataSources) {
      this.dataSources = {};
    }
    if (!this.dataSources[propName]) {
      this.dataSources[propName] = new Object();
      this.dataSources[propName].rawData = data;
      this.dataSources[propName].properties = Object.keys(data[0]).reduce(
        (acc, curr) => {
          acc.push(curr);
          return acc;
        },
        []

      );
    }
  }

  insertHTMLdata(propName: string, elementID, con: ElementRef, list: any) {
    this.dataSource = list
    this.content = con
    if (!this.dataSources) {
      this.dataSources = {};
    }
    if (!this.dataSources[propName]) {
      this.dataSources[propName] = new Object();
      this.dataSources[propName].rawData = elementID;
    }

    return this.dataSources
  }

  hidePrintButton() {
    this.showPrintBtn.next(false);
  }

  showPrintButton() {
    this.showPrintBtn.next(true);
  }



  sendEmail() {
    this.userDataService.getUser1().subscribe(user => {
      this.user1 = user.user
    })
    let content = this.content.nativeElement;

    const doc = new jsPDF('p', 'mm', 'A4', true);
    document.getElementById('content').style.letterSpacing = '0.1px';
    html2canvas(document.getElementById('content')).then(a => {
      doc.addImage(a.toDataURL(), "pdf", 0, 0, 208, 201);

      const data = doc.output("datauristring");
      let base64Pdf = data.split(';base64,').pop();

      this.pdf.sendTo = this.user1.email
      this.pdf.base64 = base64Pdf
      this.emailService.sendEmail(this.pdf).subscribe(d => {
        this.success=d
      })
    })
    let _elementHandlers =
    {
      '#editor': function (element, renderer) {
        return true;
      }
    };

    // doc.html(content.innerHTML);  
    // doc.html(content, {
    //   callback: (doc) => {

    //     const data = doc.output("datauristring");
    //     doc.output('dataurlnewwindow');

    //     let base64Pdf = data.split(';base64,').pop();
    //     console.log('bassssse', base64Pdf)
    //     this.pdf.sendTo = this.user1.email
    //     console.log(this.pdf)
    //     this.pdf.base64 = base64Pdf

    //     this.emailService.sendEmail(this.pdf).subscribe(d => {
    //       alert('mail send!!')
    //     })

    // this.emailService.sendEmail(this.pdf)
    // }

    // });

  }


     exportAsExcelFile(excelFileName: string): void {
      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.dataSource);
      const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, excelFileName);
    }

    private saveAsExcelFile(buffer: any, fileName: string): void {
       const data: Blob = new Blob([buffer], {type: this.EXCEL_TYPE});
       FileSaver.saveAs(data, fileName + '_export_' + new  Date().getTime() + this.EXCEL_EXTENSION);
    }
}
