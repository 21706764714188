import { Component, OnInit, Inject } from '@angular/core';
import { FormService } from '../../service/form.service';
import { FormControl, FormArray, FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar/dist';
import documetTypeIds from '../../../shared/util/documetTypeIds'
import {
  FieldsRequest,
  UploadedFile
} from 'src/app/models/interfaces';

@Component({
  selector: 'app-wizard-modal',
  templateUrl: './wizard-modal.component.html',
  styleUrls: ['./wizard-modal.component.scss']
})
export class WizardModalComponent implements OnInit {
  documentRef: FormControl;
  data: any;
  downloadDocument = null;
  field:FieldsRequest = null;

  public config: PerfectScrollbarConfigInterface = {
    wheelSpeed: 0.2
  };

  constructor(private formService: FormService, @Inject(MAT_DIALOG_DATA) data) {
    this.data = data;
  }

  ngOnInit() {
    this.documentRef = this.data.documentRef;
    this.downloadDocument = this.data.downloadDocument;
    this.field = this.data.selectedField;
  }
}

