import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators
} from "@angular/forms";
import { Observable, Subscription } from "rxjs";

import { ApplicationHttpClient } from 'src/app/shared/service/ApplicationHttpClient';
import { Injectable } from "@angular/core";
import { UploadedFile } from "src/app/models/interfaces";
import regionsTypes from "src/app/shared/util/regionTypes";
import { WizradUserDataService } from "./wizrad-user-data.service";

// import {BuildingPermitService} from "./building-permit.service";

@Injectable({
  providedIn: "root"
})
export class FormService {
  employmentApplicationForm: FormGroup;
  FormValueChangesSubscription: Subscription;

  constructor(
    private formBuilder: FormBuilder,
    private http:ApplicationHttpClient,
    public wizardUserDataService:WizradUserDataService) {

  }

  createForm(): void {
    this.employmentApplicationForm = this.formBuilder.group({
      transactionArea: this.formBuilder.group({
        region: [null, [Validators.required]]
      }),
      requestDetails: this.formBuilder.group({
        fields: [null, [Validators.required]],
        settlements: this.formBuilder.array(
          [],
          [Validators.required, Validators.maxLength(4)]
        ),
        license_duration: [null,
          [Validators.required, Validators.min(1), Validators.pattern("^[0-9]*$")]],
        working_hours: [null, [Validators.required]],
        outside_locality: [false, []],
        industrial_area: [false, []],
        employment_bureau: [null, [Validators.required]],
        loging: [null, [Validators.required]],
        secondaryType: [null]
      }),
      wantedWorkers: this.formBuilder.group({
        employees: this.formBuilder.array([], [Validators.required])
      }),
      documents: this.formBuilder.array([]),
      buildingPermit:this.formBuilder.control(null, [])
    });
    
    // this.FormValueChangesSubscription = this.employmentApplicationForm.valueChanges.subscribe(
    //   () => {
    //           console.log(
    //     'employment Application Form value changed',
    //     this.employmentApplicationForm.value
    //   );
    //   console.log(
    //     'employment Application Form changed',
    //     this.employmentApplicationForm
    //   );
    //   }
    // );
  }

  deleteForm() {
    const formValueChangesSubscription = this.FormValueChangesSubscription;
    if(formValueChangesSubscription){
      this.FormValueChangesSubscription.unsubscribe();
    }
    this.employmentApplicationForm = null;
  }

  resetForm(){
    this.employmentApplicationForm.reset();

    this.clearAsyncValidators(this.employmentApplicationForm);
    //TODO: CLEARE ALL FORM ERRORS!!!!!
    // this.employmentApplicationForm.clearAsyncValidators();
  }

  clearAsyncValidators(group: FormGroup | FormArray): void {
      Object.keys(group.controls).forEach((key: string) => {
          const abstractControl = group.controls[key];
          if (abstractControl instanceof FormGroup || abstractControl instanceof FormArray) {
              this.clearAsyncValidators(abstractControl);
          } else {
              abstractControl.setErrors(null) ;
          }
      });
  }

  getForm(): FormGroup {
    return this.employmentApplicationForm;
  }

  getFormGroupRefByName(ref: string): FormGroup {
    return this.employmentApplicationForm.get(ref) as FormGroup;
  }

  getFormGroupRefByIndex(indexRef: number): FormGroup {
    let formGroupRef: FormGroup = null;
    Object.keys(this.employmentApplicationForm.controls).forEach(
      (key, index) => {
        if (index === indexRef) {
          formGroupRef = this.employmentApplicationForm.controls[
            key
          ] as FormGroup;
          return;
        }
      }
    );
    return formGroupRef;
  }

  createControl(
    value?: any,
    validatorsOptions?: ValidatorFn | ValidatorFn[]
  ): FormControl {
    return this.formBuilder.control(value, validatorsOptions);
  }

  createGroup(validatorsOptions?: ValidatorFn | ValidatorFn[]): FormGroup {
    return this.formBuilder.group({}, validatorsOptions);
  }

  createArray(
    value?: any,
    validatorsOptions?: ValidatorFn | ValidatorFn[]
  ): FormArray {
    return this.formBuilder.array([]);
  }

  prepareForSubmit(): any {
    let workersArrayRef = this.getFormGroupRefByName("wantedWorkers").get(
      "employees"
    ).value as any[];
    // workersArrayRef=workersArrayRef.map(emp=>emp.id)
    let areaCode = +this.getFormGroupRefByName("transactionArea").get("region").value;
    let settlementsArrayRef = this.getFormGroupRefByName("requestDetails").get("settlements").value as any[];
    let documentsArrayRef = this.getFormGroupRefByName("documents").value as UploadedFile[];
      let fieldCode = +this.getFormGroupRefByName("requestDetails").get("fields").value.subCode;
      let employmentBureau = areaCode == regionsTypes.SETTELMENTS ? this.getFormGroupRefByName("requestDetails").get("employment_bureau").value ?
      +this.getFormGroupRefByName("requestDetails").get("employment_bureau").value.code : null : 8;
      let workingHoursCode = +this.getFormGroupRefByName("requestDetails").get("working_hours").value.code;
      let licenseDuration = areaCode == regionsTypes.SETTELMENTS ?
      +this.getFormGroupRefByName("requestDetails").get("license_duration").value : null;
      let outsideLocality = areaCode == regionsTypes.SETTELMENTS ?  this.getFormGroupRefByName("requestDetails").get("outside_locality").value != null ?
      this.getFormGroupRefByName("requestDetails").get("outside_locality").value : false : null;
      let industrialArea = areaCode == regionsTypes.SETTELMENTS ? this.getFormGroupRefByName("requestDetails").get("industrial_area").value != null ?
      this.getFormGroupRefByName("requestDetails").get("industrial_area").value : false : null;
      let loging = areaCode != regionsTypes.SETTELMENTS ? +this.getFormGroupRefByName("requestDetails").get("loging").value : null;
      let workingHoursDocumentId = this.getFormGroupRefByName("requestDetails").get("files") ?
      this.getFormGroupRefByName("requestDetails").get("files").value.id : null
      let licenseType = areaCode == regionsTypes.ISRAEL ? regionsTypes.ISRAEL : regionsTypes.SETTELMENTS
   
      return {
      areaCode: areaCode,
      fieldCode: fieldCode,
      settlementsCodes: settlementsArrayRef.map(value => value.code),
      employmentBureau: employmentBureau,
      workingHoursCode: workingHoursCode,
      licenseDuration: licenseDuration,
      outsideLocality: outsideLocality,
      industrialArea: industrialArea,
      loging: loging,
      workingHoursDocumentId: workingHoursDocumentId,
      uploadedDocuments:documentsArrayRef.filter(d=> d !== null),//.map((value:UploadedFile) => value.id),
      wantedEmployees: workersArrayRef,
      licenseType: licenseType,
      employeesChecked:this.wizardUserDataService.employeeApplicationRequest
    };
  }

  getValueFromArray(arrayOfValues: any[], field: any): any[] {
    return arrayOfValues.reduce((acc, current) => {
      acc.push(current[field]);
      return acc;
    }, [] as any[]);
  }

  changeKeysOfArray(arrayOfValues: any[], srcKey: any, detKey: any): any[] {
    return arrayOfValues.map(val => {
      const rObj = {};
      rObj[detKey] = val[srcKey];
      return rObj;
    });
  }

  submitForm(): Observable<string> {
    if (!this.getForm().invalid) {
      return this.http.Post<any>('api/request/saveDigitalApplication', this.prepareForSubmit());
    }
  }

  // getFormGroupRefByIndex(indexRef: number): any {
  //   let formGroupRefWithName: {formGroup: FormGroup , formGroupName: string} = null;
  //   Object.keys(this.employmentApplicationForm.controls).forEach((key, index) => {
  //     if (index === indexRef) {
  //       let tempGroupRef = this.employmentApplicationForm.controls[key] as FormGroup;
  //       formGroupRefWithName = { tempGroupRef , key};
  //       return;
  //     }
  //   });
  //   return formGroupRefWithName;
  // }

  // setValue(control: AbstractControl) {}

  // setValidators(control: AbstractControl , validatorsOptions?: ValidatorFn | ValidatorFn[]) {
  //   control.setValidators(validatorsOptions);
  // }
}
