import { Injectable } from '@angular/core';
import { Observable } from 'rxjs-compat/Observable';
import { Pdf } from 'src/app/models/pdf';
import { ApplicationHttpClient } from './ApplicationHttpClient';
import { Subject } from "rxjs";


@Injectable({
  providedIn: 'root'
})
export class EmailService {
   ConvertedBlob:Blob
   showEmailBtn = new Subject<boolean>();


  constructor(public http:ApplicationHttpClient) { }

  showEmailButton() {
    this.showEmailBtn.next(true);
  }

  sendEmail(pdf:Pdf):Observable<any>{
    return  this.http.Post<any>(`api/email/sendEmail/`,pdf)
  }

}
