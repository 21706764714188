import { FormGroup } from '@angular/forms';
import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef, QueryList } from '@angular/core';
import { StepPagesService } from '../../service/step-pages.service';
import { FormService } from '../../service/form.service';
import { StepComponentBase } from '../wizard-step-component-base/step-component-base';
import { MatDialog } from '@angular/material';
import { UserDataService } from 'src/app/services/user-data.service';
import { WelcomeModalComponent } from '../../shared/welcome-modal/welcome-modal.component';
import { takeUntil } from "rxjs/operators";
import { WizradUserDataService } from '../../service/wizrad-user-data.service';
import { RequestData, UnavailableField, WelcomwDialogProps } from 'src/app/models/interfaces';
import regionsTypes from '../../../shared/util/regionTypes';
import { element } from 'protractor';
import { InternalOption } from 'src/app/models/internalOption';

export interface RegionOption {
  lable: string;
  value: number;
  checked: boolean;
  disabled: boolean;
  icon: string
}
@Component({
  selector: 'app-transaction-area-step',
  templateUrl: './transaction-area-step.component.html',
  styleUrls: ['./transaction-area-step.component.scss']
})

export class TransactionAreaStepComponent extends StepComponentBase
  implements OnInit {
  @ViewChild('elementRef', { static: false }) public elementRef: any[]
  FormGroupRef: FormGroup = null;
  allowIsrael: boolean = false;
  allowSettlements: boolean = false;
  showModalDisableIsrael: string;

  regions: RegionOption[] = [
    { lable: 'ישראל', value: regionsTypes.ISRAEL, checked: false, disabled: false, icon: 'icon-Israel' },
    { lable: 'התישבות', value: regionsTypes.SETTELMENTS, checked: false, disabled: false, icon: 'icon-Settlement' }
  ]

  constructor(
    stepsPagesService: StepPagesService,
    dialog: MatDialog,
    private userDataService: UserDataService,
    private formService: FormService,
    private wizardUserDateService: WizradUserDataService,
  ) {
    super(stepsPagesService, dialog);
    this.FormGroupRef = this.formService.getFormGroupRefByName(
      'transactionArea'
    );
  }

  ngOnInit() {
    super.ngOnInit();
    this.userDataService.getUser().pipe(takeUntil(this.componentDestroyed)).subscribe(user => {
      const doc = this.wizardUserDateService.getDownloadDocumentByName('signatureForm');
      this.wizardUserDateService.requestData.pipe(takeUntil(this.componentDestroyed)).subscribe((requestData: RequestData) => {
        this.allowIsrael = requestData.allowIsrael === true && !requestData.preventionToSubmitInIsrael;
        this.showModalDisableIsrael = requestData.preventionToSubmitInIsrael;

        this.allowSettlements = requestData.allowSettlement;
        const regionDefaultValue = this.allowIsrael ? regionsTypes.ISRAEL : (requestData.allowSettlement ? regionsTypes.SETTELMENTS : null);
        this.regions = [
          { lable: 'ישראל', value: regionsTypes.ISRAEL, checked: false, disabled: !this.allowIsrael, icon: 'icon-Israel' },
          { lable: 'התישבות', value: regionsTypes.SETTELMENTS, checked: false, disabled: !requestData.allowSettlement, icon: 'icon-Settlement' }
        ]
        const region = this.regions.find(r => r.value === regionDefaultValue);

        if (region) {
          region.checked = true;
        }



        if (regionDefaultValue !== null) {
          this.FormGroupRef.get('region').setValue(regionDefaultValue)
        }

        const unavailableFieldData: UnavailableField = {
          setllementsFields: requestData.unAvailableSettlementsFields,
          allowIsrael: requestData.allowIsrael,
          allowSettlement: requestData.allowSettlement
        }
        console.log("subscribe - wizardUserDateService")
        const data: WelcomwDialogProps = { user: user, document: doc, unavailableField: unavailableFieldData }
        this.openModal(WelcomeModalComponent, data, {
          disableClose: true,
          maxWidth: 640,
          panelClass: ['generalModal', 'bigPanel']
        });


      });
    });
  }

 
  ngAfterViewInit() {
    // const dom: HTMLElement = this.elementRef.nativeElement;
    // const elements = dom.querySelectorAll('.active')
    // this.elementRef.forEach((domElement)=>{
    //   console.log(domElement)
    //   if (domElement){}
    // domElement.classList.add('myNewClassToAdd');
    //  })
  }

  onSelectionChange(e: RegionOption) {
    this.resetForm();
    this.FormGroupRef.get('region').setValue(+e.value);
    let region = this.regions.find(a => a.value === e.value)
    let notRegion = this.regions.find(a => a.value !== e.value)
    region.checked = true;
    notRegion.checked = false;
    if (region.disabled === true) {
      return 'disabled'
    }
    if (notRegion.checked === false) {
      return 'gray-border'
    }
    return 'active'
  }

  classAreaActive(region) {
    if (region.checked === true) {
      return 'active'
    }

    if (region.disabled === true) {
      return 'disabled'
    }
  }

}
