<section class="wizardSection" *ngIf="initWizrad">
  <div class="container-fluid">
    <div class="headLineWrapper">
      <!-- <h2 class="subTitle subTitle--blueTextColor">
        {{ title }}
      </h2> -->
      <!--      <a class="draftLink" (click)="saveDraft()">-->
      <!--        <img class="draftLink__icon" src="./assets/imgs/save-button.png" />-->
      <!--        <span-->
      <!--          class="draftLink__text textContent textContent&#45;&#45;semiBold textContent&#45;&#45;blueTextColor">-->
      <!--          שמירה כטיוטא-->
      <!--        </span>-->
      <!--      </a>-->
    </div>
    <mat-vertical-stepper linear #stepper class="wizard-stepper">
      <mat-step *ngFor="let step of steps; let i = index" [stepControl]="stepControl(i)">
        <ng-template matStepLabel>
          <div class="icon-step" [ngClass]="step.icon">
          </div>
          <div>
          <span class="step-number-span">שלב {{ i + 1 }} </span>
          <div class="label-name">{{ step.label }}</div>
</div>

        </ng-template>
        <app-step-page-wrapper [item]="step.component" (onGoForward)="goForward(stepper)" (onGoBack)="goBack(stepper)"
          (onFormReset)="resetForm()"></app-step-page-wrapper>
        <!-- <app-step-page-wrapper [item]="step.component" (addOrRemoveStep)="addOrRemoveSteps()"></app-step-page-wrapper> -->
      </mat-step>
    </mat-vertical-stepper>

  

  </div>
</section>