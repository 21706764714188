<div *ngIf="isLoading" class="overlay">
  <div class="spinner">

  </div>
  <!-- <mat-progress-spinner
    class="spinner"
    [color]="color"
    [mode]="mode"
    [value]="value"
  >
 
  </mat-progress-spinner> -->
</div>
