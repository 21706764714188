import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

@Component({
  selector: 'app-login-form-modal',
  templateUrl: './login-form-modal.component.html',
  styleUrls: ['./login-form-modal.component.scss']
})
export class LoginFormModalComponent implements OnInit {
  text: string;
  text1: string
  subtitle: string;
  ifIsrael: boolean = true

  public config: PerfectScrollbarConfigInterface = {
    wheelSpeed: 0.2
  };

  constructor(
    private dialogRef: MatDialogRef<LoginFormModalComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.text = data.text;
    this.text1 = data.text1
    this.subtitle = data.title;
  }

  ngOnInit() { }

  closeModal() {
    this.dialogRef.close();
  }

  notisrael() {
    this.ifIsrael = false
  }
  israel() {
    this.ifIsrael = true
  }
}
