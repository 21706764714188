import * as i0 from "@angular/core";
import * as i1 from "../shared/service/ApplicationHttpClient";
var ReportsService = /** @class */ (function () {
    function ReportsService(http) {
        this.http = http;
    }
    ReportsService.prototype.getAreaes = function () {
        return this.http.Get("api/area");
    };
    ReportsService.prototype.getSubAreaes = function () {
        return this.http.Get("api/sub_areas");
    };
    ReportsService.prototype.getBranches = function () {
        return this.http.Get("api/branches");
    };
    ReportsService.prototype.getSubBranches = function () {
        return this.http.Get("api/sub_branches");
    };
    ReportsService.prototype.getRegions = function () {
        return this.http.Get("api/region");
    };
    ReportsService.prototype.getLivingCity = function () {
        return this.http.Get("api/living_city");
    };
    ReportsService.prototype.getEmployerTypes = function () {
        return this.http.Get("api/employer_type");
    };
    ReportsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ReportsService_Factory() { return new ReportsService(i0.ɵɵinject(i1.ApplicationHttpClient)); }, token: ReportsService, providedIn: "root" });
    return ReportsService;
}());
export { ReportsService };
