import * as i0 from "@angular/core";
import * as i1 from "../shared/service/ApplicationHttpClient";
var AuthStepOneService = /** @class */ (function () {
    function AuthStepOneService(http) {
        this.http = http;
    }
    AuthStepOneService.prototype.readOpeningFile = function (areaId) {
        return this.http.Get("api/employment_instructions/active/" + areaId);
    };
    AuthStepOneService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthStepOneService_Factory() { return new AuthStepOneService(i0.ɵɵinject(i1.ApplicationHttpClient)); }, token: AuthStepOneService, providedIn: "root" });
    return AuthStepOneService;
}());
export { AuthStepOneService };
