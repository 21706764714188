import * as i0 from "@angular/core";
var AppConfig = /** @class */ (function () {
    function AppConfig() {
        this.config = {
            "frontend.employment.cogatapps.com": {
                "enviornment": "poduction",
                "production": "true",
                "baseUrl": "https://server.employment.cogatapps.com/",
                "accessControlAllowOrigin": "https://frontend.employment.cogatapps.com"
            },
            "frontend.employmentqa.cogatapps.com": {
                "enviornment": "pre prod",
                "production": "true",
                "baseUrl": "https://server.employmentqa.cogatapps.com/",
                "accessControlAllowOrigin": "https://frontend.employmentqa.cogatapps.com"
            },
            "matpahfe.azurewebsites.net": {
                "enviornment": "qa",
                "production": "true",
                "baseUrl": "https://matpahbe.azurewebsites.net/",
                "accessControlAllowOrigin": "https://matpahfe.azurewebsites.net"
            },
            "localhost": {
                "enviornment": "dev",
                "production": "false",
                "baseUrl": "http://localhost:4000/",
                "accessControlAllowOrigin": "http://localhost:4200"
            }
        };
    }
    AppConfig.prototype.load = function () {
        var host = window.location.host.split(/[:]/)[0];
        if (!host) {
            throw "invalid URL";
        }
        if (!this.config[host]) {
            throw "app.config.ts internal error: couldn't find host '" + host + "' in configuration";
        }
        this._settings = this.config[host];
        if (host === "localhost") {
            this._settings.accessControlAllowOrigin = window.location.origin;
        }
    };
    AppConfig.prototype.get = function (key) {
        return this._settings[key];
    };
    Object.defineProperty(AppConfig.prototype, "settings", {
        get: function () {
            return this._settings;
        },
        enumerable: true,
        configurable: true
    });
    AppConfig.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AppConfig_Factory() { return new AppConfig(); }, token: AppConfig, providedIn: "root" });
    return AppConfig;
}());
export { AppConfig };
