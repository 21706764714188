import * as tslib_1 from "tslib";
import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
var StepPagesService = /** @class */ (function () {
    function StepPagesService() {
        this.arrayOfStepsSubject = new BehaviorSubject([]);
        this.wizardTitleSubject = new BehaviorSubject('הגשת בקשה לרישיונות תעסוקה');
    }
    StepPagesService.prototype.buildSteps = function (steps) {
        this.arrayOfStepsSubject.next(steps);
    };
    StepPagesService.prototype.getSteps = function () {
        return this.arrayOfStepsSubject.asObservable();
    };
    StepPagesService.prototype.getTitle = function () {
        return this.wizardTitleSubject.asObservable();
    };
    StepPagesService.prototype.setStep = function (newStep) {
        var arrayOfSteps = tslib_1.__spread(this.arrayOfStepsSubject.value);
        arrayOfSteps.push(newStep);
        this.arrayOfStepsSubject.next(arrayOfSteps);
    };
    StepPagesService.prototype.setStepAtIndex = function (newStep, index, title) {
        var arrayOfSteps = tslib_1.__spread(this.arrayOfStepsSubject.value);
        arrayOfSteps.splice(index, 0, newStep);
        this.arrayOfStepsSubject.next(arrayOfSteps);
        if (title) {
            this.wizardTitleSubject.next(title);
        }
    };
    StepPagesService.prototype.getStepIndex = function (step) {
        return this.arrayOfStepsSubject.value.findIndex(function (s) { return s.component === step; });
    };
    StepPagesService.prototype.stepExisitsByName = function (step) {
        return this.arrayOfStepsSubject.value.some(function (s) { return s.component === step.component; });
    };
    StepPagesService.prototype.removeStepAtIndex = function (stepIndex, title) {
        var arrayOfSteps = tslib_1.__spread(this.arrayOfStepsSubject.value);
        arrayOfSteps.splice(stepIndex, 1);
        this.arrayOfStepsSubject.next(arrayOfSteps);
        if (title) {
            this.wizardTitleSubject.next(title);
        }
    };
    StepPagesService.prototype.removeStepByComponentName = function (stepComponent) {
        var stepIndex = this.arrayOfStepsSubject.value.findIndex(function (step) { return step.component === stepComponent.component; });
        if (stepIndex === -1) {
            return;
        }
        var arrayOfSteps = tslib_1.__spread(this.arrayOfStepsSubject.value);
        arrayOfSteps.splice(stepIndex, 1);
        this.arrayOfStepsSubject.next(arrayOfSteps);
    };
    StepPagesService.prototype.resetSteps = function () {
        this.arrayOfStepsSubject.next([]);
    };
    StepPagesService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function StepPagesService_Factory() { return new StepPagesService(); }, token: StepPagesService, providedIn: "root" });
    return StepPagesService;
}());
export { StepPagesService };
