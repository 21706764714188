import { Branch } from "./branch";

export class SubBranch{
    constructor(
        public _id?:string,
        public subName?:string,
        public subCode?:string,
        public allowedLicenseTypes?:[],
        public branch?:Branch
    ){}
}