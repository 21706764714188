<section class="personalInfoSection  shadow-cube">

    <div class="rightCube w-100">

        <div class="imgWrapper" [class.noPic]="!imgExist">
            <img *ngIf="imgExist" src="assets/imgs/personImg.png" alt="">
            <img *ngIf="!imgExist" src="assets/imgs/person-icon.svg" alt="">
        </div>

        <div class="personalInfoContainer detailBox">
            <h3 class="">{{employeeData.firstName}} {{employeeData.lastName}}</h3>
            <div class="d-flex flex-column">

                <div class="d-flex detailBox__item ">
                    <i class="icon-district"></i>
                    <div class="personalInfo__text">
                        מחוז {{employeeData.regionName}}
                    </div>
                </div>
                <div *ngIf="employeeData.workedInIsrael" class="d-flex detailBox__item ">
                    <i class="icon-Employed-in-Israel"></i>
                    <div class="personalInfo__text">הועסק בעבר ע"י ישראלי</div>
                </div>
                <div class="d-flex detailBox__item ">
                    <i class="icon-Speaks-Hebrew"></i>
                    <div *ngIf="employeeData.languages.length>0">
                        <span> דובר </span>
                        <span class="personalInfo__text" *ngFor="let language of employeeData.languages">
                            {{language.languageId.language}}
                        </span>
                    </div>
                </div>
                <div class="d-flex detailBox__item ">
                    <i class="icon-Phone-Number"></i>
                    <div class="personalInfo__text">{{employeeData.phoneNumber}}</div>
                </div>


                <div class="d-flex detailBox__item ">
                    <i class="icon-mail-adress"></i>
                    <div class="personalInfo__text">{{employeeData.email}}</div>
                </div>
                <!--div class="d-flex detailBox__item ">
                    <i class="icon-Employee-details"></i>
                    <div class="personalInfo__text">ת.ז / ח.פ: {{employeeData.employeeIdentity}}</div>
                </div-->

            </div>

        </div>

        <div class="personalInfoContainer my-5 my-md-0">
            <h3 class="subTitle "> תחומים ומקצועות</h3>

            <div class="personalInfo">
                <div class="personalInfo__formGroup personalInfo__name">

                    <div class="personalInfo__text profession mb-2" *ngFor="let profession of employeeData.professions">
                        <span>{{profession.professionId.profession}}</span>
                        <div *ngIf="profession.Authorized">
                            <span style="color: green;">מוין</span>
                            <span pTooltip={{getToolTip(profession)}} class="btn-small">פירוט מקצועות</span>
                        </div>
                        <span *ngIf="!profession.Authorized" style="color: red;">לא מוין</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="personalInfoContainer">
            <h3 class="subTitle "> מבחן בטיחות</h3>
            <div class="personalInfo">
                <div class="personalInfo__formGroup personalInfo__name">
                    <div class="personalInfo__title"> </div>
                    <div class="personalInfo__text">
                        <span *ngIf="employeeData.safetyExam && employeeData.examDate" style="color: green;"
                            class="d-flex align-items-center">
                            <i class="icon-Test-performed ml-3"></i>
                            <span class="mr-1">עבר בתאריך:</span>
                        </span>
                        <span *ngIf="!employeeData.safetyExam && employeeData.examDate" style="color: red;"
                            class="d-flex align-items-center">
                            <i class="icon-test-no-passed ml-3"></i>
                            <span class="mr-1">לא עבר בתאריך:</span>
                        </span>
                        <span *ngIf="!employeeData.examDate">לא בוצע</span>
                        <span *ngIf="employeeData.examDate" class="mr-5"> {{employeeData.examDate | date: 'dd/MM/yyyy'}}
                        </span>
                    </div>
                </div>
            </div>

        </div>

        <div class="personalInfoContainer d-flex">
            <!-- <i class="icon-Star_fill mr-auto"></i> -->
            <i class="icon-Star  mr-auto"></i>
        </div>

        <!-- <div class="personalInfoContainer">
            <h3 class="subTitle subTitle--blueTextColor subTitle--textCenter">המלצות</h3>
            <div class="personalInfo">
                <button class="actionBtn"
                    *ngFor="let recommendation of employee.Recommendations">{{recommendation}}</button>

            </div>
        </div>


        <div class="personalInfoContainer">
            <div class="personalInfo">
                <button class="actionBtn">שמור במועדפים</button>
                <button class="actionBtn">הוספה לרשימה</button>
                <button class="actionBtn">חיפוש </button>
                <button class="actionBtn">איפוס סינון </button>
                <button class="actionBtn">מיון </button>
            </div>
        </div> -->

    </div>

    <div class="border-top ">
        <div class="d-flex my-4" *ngIf="employeeData.files.length>0">
            <div *ngFor="let item of employeeData.files">
                <!-- <button type="button" pButton
                    class="ui-button-rounded  button-matpash light-blue-border d-flex align-items-center justify-content-center"
                    label="">
                    <i class="icon-CV-file mr-md-3"></i>
                    <label class="mx-3">{{item.fileTypeId.documentTypeDesc}}</label>
                    <i class="icon-Download-file"></i>
                </button> -->


                <a [href]="getDownloadFileUrl(item)" class=""
                [target]="disabled ? '' : '_blank'">
                <svg class="iconImg" width="10px" height="10px" viewBox="0 0 10 10" version="1.1"
                  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                  <g id="download" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <path
                      d="M9.37165775,10 L0.628342246,10 C0.280748663,10 0,9.73551637 0,9.40806045 L0,7.05289673 C0,6.72544081 0.280748663,6.46095718 0.628342246,6.46095718 C0.975935829,6.46095718 1.25668449,6.72544081 1.25668449,7.05289673 L1.25668449,8.81612091 L8.74331551,8.81612091 L8.74331551,7.05289673 C8.74331551,6.72544081 9.02406417,6.46095718 9.37165775,6.46095718 C9.71925134,6.46095718 10,6.72544081 10,7.05289673 L10,9.40806045 C9.98663102,9.73551637 9.70588235,10 9.37165775,10 Z M5.45454545,5.70528967 C5.20053476,5.94458438 4.78609626,5.94458438 4.53208556,5.70528967 L2.68716578,3.97984887 C2.43315508,3.74055416 2.43315508,3.35012594 2.68716578,3.11083123 C2.94117647,2.87153652 3.35561497,2.87153652 3.60962567,3.11083123 L4.37165775,3.82871537 L4.37165775,0.591939547 C4.37165775,0.264483627 4.65240642,2.09915418e-13 5,2.09915418e-13 C5.34759358,2.09915418e-13 5.62834225,0.264483627 5.62834225,0.591939547 L5.62834225,3.81612091 L6.39037433,3.11083123 C6.64438503,2.87153652 7.05882353,2.87153652 7.31283422,3.11083123 C7.56684492,3.35012594 7.56684492,3.74055416 7.31283422,3.97984887 L5.45454545,5.70528967 Z"
                      id="Shape-Copy" fill="#056FF2" fill-rule="nonzero"></path>
                  </g>
                </svg>
                <span class="uploadLink__text link  link--underLine link--semiBold">{{item.fileTypeId.documentTypeDesc}}</span>
              </a>


            </div>

            <button pButton type="button" label="הוסף לבקשה"
                class="ui-button-rounded button-matpash blue-fill d-flex align-items-center  mr-auto justify-content-center">
                <i class="icon-Add-to-request mr-3"></i>
            </button>
        </div>
        <!--iv>
            תאריך פרסום מודעה {{date | date:'dd/MM/yyyy'}}
        </div-->
    </div>
</section>