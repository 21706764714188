<div class="currentSection d-flex container-fluid w-container-box p-0">
    <div class="col-md-3">
        <div class="right-box">
            <p-menu [model]="queries"></p-menu>
        </div>
    </div>

    <div class="col-md-9">
        <div class="tabsSection left-box">

            <h1 class="mb-5" *ngIf="currentQuery">{{currentQuery.title}}</h1>

            <div *ngFor="let inF of currentQuery?.input_fields">
                <label [for]="inF.name">{{inF.title}}:</label>
                <input #in pInputText [id]="inF.name" [type]="inF.type" [ngModel]="inF.default_value"
                    (input)="inputsValues[inF.name] = in.value" class="input-matpash">
            </div>

            <div class="d-flex mt-5">
                <button pButton type="button" label="שלח" [disabled]="!currentQuery"
                    class="ui-button-rounded button-matpash light-blue-fill mr-auto" (click)="sendQuery()"></button>
            </div>
        </div>
        <div *ngIf="response" [innerHtml]="response"></div>
    </div>
</div>