import { Component } from '@angular/core';
import { UserDataService } from 'src/app/services/user-data.service';

@Component({
  selector: 'app-hp',
  templateUrl: './hp.component.html',
})
export class HpComponent  {

  constructor(private userDataService: UserDataService) {
    this.userDataService.init();
   }

}
